import React from 'react'
import "./cardRating.css";
const CardOne = () => {
  return (
    <>
      <div className="parentCard">
        <div class="Congratulations-Your-succesfully-completed-the-job">
          <div class="text-style-1">Congratulations!</div>
          Your succesfully completed the gig
        </div>

        <div className="card-insidebox">
          <div className="card-attribute">
            <div className="card-attribute_item1"> Start date</div>
            <div className="card-attribute_item2"> 07/22/2022</div>
          </div>

          <div className="card-attribute">
            <div className="card-attribute_item1">End date</div>
            <div className="card-attribute_item2"> 08/12/2022</div>
          </div>

          <div className="card-attribute">
            <div className="card-attribute_item1">Pay package</div>
            <div className="card-attribute_item2"> USD 73/hr</div>
          </div>

          <div className="card-attribute">
            <div className="card-attribute_item1">Contract duration</div>
            <div className="card-attribute_item2"> 1 Month</div>
          </div>
        </div>

        <div className="duration-card">11:35pm</div>
      </div>
    </>
  )
}

export default CardOne