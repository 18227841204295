import React, { useEffect, useState } from "react";
import NoViewHistorySkill from "./NoViewHistorySkill";
import WorkHistory from "../../../Profile/components/WorkHistory/WorkHistory";
import { useSelector } from "react-redux";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";

const WorkHistorySkillListing = () => {
  const [addEdit, setAddEdit] = useState(false);
  // const [historyLength, setHistoryLength] = useState(0);

  const historyLength = useSelector(
    (state) => state.profileReducer.talentInfo.WorkHistory
  )?.filter((obj) => obj.Deleted === false).length;

  const loading = useSelector((state) => state.profileReducer.loadingWH);

  // useUpdateLogger("historyLength", historyLength);

  return (
    <div>
      {(loading || addEdit || historyLength > 0) && (
        <div className="workHistorySkillListing">
          <WorkHistory
            skillListingDetailPage={true}
            addEdit={addEdit}
            setAddEdit={setAddEdit}
            // setHistoryLength={setHistoryLength}
          />
        </div>
      )}
      {!loading && !addEdit && !historyLength > 0 && (
        <NoViewHistorySkill type={"Work History"} func={setAddEdit} />
      )}
    </div>
  );
};

export default WorkHistorySkillListing;
