import React, { useEffect, useState } from "react";
import SocialOptions from "./SocialOptions";
import SignUp from "./SignUp";
import { useSelector, useDispatch } from "react-redux";

import Login from "./Login";
import "./SocialOptions.css";

// Antd CSS
import "antd/dist/antd.css";
import {
  checkEmailFlow,
  isModalVisibleAuth,
  signUpLoginUpdate,
} from "../../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";

function SignUpLogin() {
  const dispatch = useDispatch();
  const [signinClick, setSigninClick] = useState(false);
  const history = useHistory();
  const initialPath = useLocation().pathname.split("/")[1];

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  // utm_source, utm_campaign, utm_medium, utm_term
  const utm_source = queryParameters.get("utm_source");
  const utm_campaign = queryParameters.get("utm_campaign");
  const utm_medium = queryParameters.get("utm_medium");
  const utm_term = queryParameters.get("utm_term");

  useEffect(() => {
    localStorage.setItem("utm_source", utm_source);
    localStorage.setItem("utm_campaign", utm_campaign);
    localStorage.setItem("utm_medium", utm_medium);
    localStorage.setItem("utm_term", utm_term);
  }, []);

  const emailForward = useSelector((state) => state.authReducer.emailCheck);
  const signUpLogin = useSelector((state) => state.authReducer.signUpLogin);

  const switchToLoginSignUp = () => {
    dispatch(checkEmailFlow(false));
    dispatch(signUpLoginUpdate(!signUpLogin));
    setSigninClick(true);
    if (initialPath == "signup") {
      history.push("/login");
    } else if (initialPath == "login") {
      history.push("/signup");
    }
    setTimeout(() => {
      dispatch(isModalVisibleAuth(true));
    }, 500);
  };

  if (initialPath == "login") {
    dispatch(signUpLoginUpdate(false));
  }

  return (
    <div className="authorization-sign-in">
      <h1 className="authorization-sign-in-top-heading">
        {signUpLogin ? "Join SkillGigs" : "Sign in to SkillGigs"}
      </h1>
      {
      // !emailForward && (
        <>
          <div>
            {/* <h1 className="authorization-sign-in-top-heading">Join Skillgigs</h1> */}
            <SocialOptions signUpLogin={signUpLogin} />
            <div className="divider-main">
              <h5 className="divider">OR</h5>
            </div>
          </div>
        </>
      // )
      }
      {signUpLogin ? (
        <>
          <SignUp signinClick={signinClick} />
        </>
      ) : (
        <Login />
      )}
      <div className="auth-divider-line"></div>
      <div className="account-allready-section">
        {signUpLogin ? (
          <>
            Already have an account?
            <span className="account-sign-in" onClick={switchToLoginSignUp}>
              {` Sign in`}
            </span>
          </>
        ) : (
          <>
            Not a member yet?
            <span className="account-sign-in" onClick={switchToLoginSignUp}>
              {` Join now`}
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default SignUpLogin;

// const mapStateToProps = (state) => ({});

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
