import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import UpgradeYourself from "./components/UpgradeYourself";
import Carousel from "./components/Carousel";
import DashboardInbox from "./components/DashboardInbox";
import DashboardProfileDetails from "./components/DashboardProfileDetails";
import SocialAccounts from "./components/SocialAccounts/SocialAccountsWrap";
import JobDetails from "./components/JobDetails";
import GigsCard from "./components/GigCard";
import { connect, useDispatch, useSelector } from "react-redux";
import ProfileDetailsSecond from "./components/ProfileDetailsComponent/ProfileDetailsSecond";
import AnalyticsFirst from "./components/AnalyticsComponents/AnalyticsFirst";
import CreateSkilllistingButton from "./components/AnalyticsComponents/CreateSkilllistingButton";
import InterviewCards from "../DashBoardFull/components/InterviewCards";
import InterviewsCard from "./components/InterviewsCard";
import {
  fetchInterview,
  getInterviewCard,
  getNotifications,
  getTalentInfo,
  postFavoriteGigCard,
} from "../../redux/actions";
import DashboardAnalytics from "./components/DashboardAnalytics";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import InterviewCard from "../Interview/InterviewCard/InterviewCard";
import { alertNotification } from "../../components/notifications/alertNotification";

const Dashboard = (props) => {
  const dispatch = useDispatch();

  const authToken = useSelector((state) => state.authReducer.user.authToken);

  const profiledata = useSelector((state) => state.profileReducer.profiledata);
  const JobDetailsSelectedData = useSelector(
    (state) => state.profileReducer.JobProfileSelecter
  );
  const interviewInfo = useSelector(
    (state) => state.profileReducer.interviewInfo
  );

  const profileDetail = useSelector(
    (state) => state.profileReducer.user.profile
  );
  const totalInterviews = useSelector(
    (state) => state.interviewReducer.success
  );
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const ResumeCompletion =
    Detail && Detail?.user && Detail?.user?.profile?.ResumeCompletion;
  
  const guestFavorite = JSON.parse(localStorage.getItem("Favorite"));
  useEffect(() => {
    if (localStorage.getItem("Favorite") && authToken) {
      alertNotification("success", "gig added to favorites");
      dispatch(
        postFavoriteGigCard(
          guestFavorite?.JobOrderId,
          "Gig",
          guestFavorite?.gig
        )
      );
      localStorage.removeItem("Favorite");
    }
  }, []);

  useUpdateLogger("detaildash", profileDetail);

  useEffect(() => {
    dispatch(getTalentInfo(authToken));
  }, [authToken]);

  useEffect(() => {
    dispatch(fetchInterview());
    dispatch(getInterviewCard());
    dispatch(getNotifications())
  }, []);
  // console.log("JobProfileSelecter", JobDetailsSelectedData);
  // console.log("profiledatdashboarda ", profiledata);

  return (
    <div
      className={`dashboard-grid ${
        !props.emailVerified && "dashboard-margintop-email-verification"
      }`}
    >
      <div className="dashboard-profile-details dashboard-left">
        <DashboardProfileDetails />
      </div>
      <div className="dashboard-analytics-button dashboard-right">
        <CreateSkilllistingButton />
      </div>
      <div className={`dashboard-analytics-${Math.floor(ResumeCompletion) === 100 ? "resume100" : "resume"} dashboard-analytics-panel dashboard-right ${profileDetail?.ProfessionCode === "IT" && "dashboard-analytics-panel-ITmargin"} `}>
        <DashboardAnalytics analyticInfo={profileDetail} />
      </div>
      <div className={`dashboard-analytics-clinical dashboard-right ${profileDetail?.ProfessionCode === "IT" && "dashboard-analytics-clinical-IT"}`}>
        <AnalyticsFirst />
      </div>
      <div className="dashboard-profile-info dashboard-left">
        {/* <ProfileDetailsSecond /> */}
        <div className="dashboard-messages-panel dashboard-left dashboard-inbox">
          <DashboardInbox />
        </div>
        <div className="dashboard-social-links dashboard-left">
          <SocialAccounts />
        </div>
      </div>
      {/* <div className="dashboard-analytics-clinical dashboard-right">
        <AnalyticsFirst />
      </div> */}
      {/* <div className="dashboard-analytics-button dashboard-right">
        <CreateSkilllistingButton />
      </div> */}
      {/* <div className="dashboard-analytics-panel dashboard-right">
        <DashboardAnalytics />
      </div> */}

      {/* <div className="dashboard-analytics-panel dashboard-right">
        <DashboardAnalytics />
      </div> */}
      <div className="dashboard-on-billing-panel dashboard-right">
        {/* <div className="Job_Detais_section">
          <JobDetails />
        </div> */}
        {/* <div className="Job_Details_GigsCard">
          {profiledata.map((data) => {
            return <GigsCard data={data} />;
          })}
        </div> */}
        <div className="dashboard-on-billing-panel1 dashboard-right">
          {interviewInfo?.body?.data?.length > 0 ? (
            <>
              <div className="interview-title">
                <div className="dashboard-interview-cards-inside">
                  Upcoming interviews
                </div>
                <div className="dashboard-interview-cards-inside-totalCount">
                  {interviewInfo?.totalResult}
                </div>
              </div>
              <div className="interviewSection">
                {" "}
                {/* <InterviewsCard />{" "} */}
                {totalInterviews &&
                  totalInterviews.length !== 0 &&
                  totalInterviews.map((interviews) => (
                    <InterviewCard
                      key={interviews._id}
                      interview={interviews}
                    />
                  ))}
              </div>
            </>
          ) : null}
        </div>
        <div className="upgradYourself">Guides for you</div>
        <div>
          <UpgradeYourself />
        </div>
        <div className="dashboard-carousel">
          <Carousel />
        </div>
      </div>

      {/* <div className="dashboard-upgrade dashboard-right">
       
      </div> */}
      {/* <div className="dashboard-carousel dashboard-right">
        
      </div> */}
    </div>
  );
};

export default Dashboard;
