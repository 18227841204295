import React from 'react'
import CardFour from './CardFour'
import CardOne from './CardOne'
import CardTwo from './CardTwo'
import CardThree from './CardThree'

const AllCard = () => {
  return (
     <>
      <div>
        <CardFour/>
        <br/>
        <CardOne />
        <br/>
       < CardTwo />
       <br/>
       <CardThree/>
       
        
      </div>
     </>
  )
}

export default AllCard