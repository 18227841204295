import React from "react";
import { FaRegSmileBeam } from "react-icons/fa";
import UpperIcon from "../../../assets/icons/UpperIcon.png";
import ChatRecruitorWhite from "../../../assets/icons/chatRecruitorWhite.svg";
import Reject_Icon from "../../../assets/icons/Reject_Icon.png";
import { Badge } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const DeclineStatus = ({
  message1,
  message2,
  message3,
  Duration,
  BidDetailData,
}) => {
  const history = useHistory();

  return (
    <div className="welcome-on-board-Rejected">
      <img src={Reject_Icon} />
      <span className="welcome-on-board-Rejected-text">{message1}</span>
      <span className="welcome-on-board-Rejected-text-Congratual">
        {message2}
      </span>
      <span className="welcome-on-board-Rejected-text-status">{message3}</span>
      <span className="welcome-on-board-Rejected-text-status-Duration">
        {Duration}
      </span>
      <span
        className="chatButton-Mobile clickable"
        onClick={() =>
          history.push({
            pathname: `/messages`,
            state: {
              openConvo: true,
              conversationId: BidDetailData?.message?.ConversationId,
            },
          })
        }
      >
        Chat with the employer
        <Badge dot={true}>
          <img src={ChatRecruitorWhite} />
        </Badge>
      </span>
      {/* <FaRegSmileBeam style={{ fontSize: "3.13rem", color: "#fff" }} /> */}
    </div>
  );
};

export default DeclineStatus;
