import React from 'react';
import {Row} from "antd";

import MyPlacedBids from "./components/MyPlacedBids/MyPlacedBids";

const Proposals = props => {
    return(
        <>
            <Row>
                <MyPlacedBids resultsFrom={1} resultsTo={4}/>
            </Row>
        </>
    )
};

export default Proposals;
