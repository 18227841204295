import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchReceivedBids,
  fetchReceivedBidsStages,
} from "../../../../../redux/actions";
import BhsCard from "./BhsCard";
import BhsFltSort from "./BhsFltSort";
import { getBidHistoryData } from "./bhsHandler";
import "./BidHistorySkillListing.css";

const BidHistorySkillListing = ({ skillId }) => {
  // const { skillId } = useParams();
  const dispatch = useDispatch();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isLocation, setIsLocation] = useState(false);
  const data = useSelector((state) => state.BidsReducer.bidStatus) || [];
  const allBids = useSelector((state) => state.BidsReducer.bids) || [];
  const bidStatus =
    data?.length &&
    data?.map((val) => ({
      [val["BidStatusName"]]: val["BidStatusId"],
    }));
  const BidHistoryData = getBidHistoryData(allBids, data);
  const [selectedFS, setSelectedFS] = useState({
    filter: "ALL",
    sort: "most_recent",
  });

  const fetchBidHistoryDetails = (filter, sort) => {
    dispatch(
      fetchReceivedBids(
        filter ? filter : selectedFS?.filter,
        skillId,
        sort ? sort : selectedFS?.sort,
        latitude, //latitude,
        longitude, //longitude,
        1, // Page
        24 // PerPage
      )
    );
  };
  useEffect(() => {
    if (isLocation && navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        // console.log("Location Latitude is :", position.coords.latitude);
        // console.log("Location Longitude is :", position.coords.longitude);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setIsLocation(false);
      });
    }
  }, [isLocation, navigator.geolocation]);

  useEffect(() => {
    dispatch(fetchReceivedBidsStages());
    fetchBidHistoryDetails();
  }, []);
  return (
    <>
      <div className="bhsContainer">
        <div className="bhsFltSortContainer">
          <BhsFltSort
            getFSData={[
              selectedFS,
              setSelectedFS,
              fetchBidHistoryDetails,
              setIsLocation,
            ]}
            bidStatus={bidStatus}
          />
        </div>
        <div className="bhsCardContainer">
          {BidHistoryData.map((data, index) => {
            return (
              <BhsCard
                data={data}
                key={index}
                apiCall={fetchBidHistoryDetails}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BidHistorySkillListing;
