import React from "react";
import CreateSkilllistingButton from "./CreateSkilllistingButton";

function AnalyticsFirst(props) {
  return (
    <React.Fragment>
      <div className="dashboard-main-examination-container">
        <div className="dashboard-main-examination-panel">
          <div className="dashboard-main-examination-panel-content">
            <div className="dashboard-main-examination-panel-content-inner">
              <div className="dashboard-main-examination-panel-txt">
                Take your clinical assesment exam
              </div>
              <div className="dashboard-main-examination-panel-subTxt">
                Relias
              </div>
            </div>
            <div
              className="dashboard-exam-starter-button"
              // onClick={() => history.push("/dummy")}
            >
              <a
                href="https://www.relias.com/product/assessments"
                target="_blank"
              >
                <div className="dashboard-start-the-exam">Start exam</div>
              </a>
            </div>
          </div>
        </div>
        {/* <CreateSkilllistingButton /> */}
        {/* <div>
            <div
              className="Frame-19924"
              onClick={() => history.push("/create-skilllisting")}
            >
              <BsPlusLg color="white" style={{ marginTop: "2.5px" }} />
              <div className="Create-a-Skill-Listing3">
                Create a Skill Listing
              </div>
            </div>
          </div> */}
      </div>
    </React.Fragment>
  );
}

export default AnalyticsFirst;
