import React from 'react';
import { Input } from "antd";

const formatNumber = (value) => new Intl.NumberFormat().format(value);

export const NumericInput = (props) => {
    const { value, onChange, flagSelection, changesflag, setcustomMessage } = props;

    const handleChange = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^[0-9\b]+$/;

        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            onChange(inputValue);
            changesflag(true);
        }
        if (inputValue?.length <= 9){
            setcustomMessage(true);
        }
    }; // '.' at the end or only '-' in the input box.

    const handleBlur = () => {
        let valueTemp = value;

        if (value.charAt(value.length - 1) === '.' || value === '-') {
            valueTemp = value.slice(0, -1);
        }

        onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    };

    const title = value ? (
        <span className="numeric-input-title">{value !== '-' ? formatNumber(Number(value)) : '-'}</span>
    ) : (
        'Input a number'
    );
    return (
        <Input
            {...props}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter your mobile number"
            maxLength={10}
            addonBefore={flagSelection}
        />
    );
};