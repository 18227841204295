import React, { useState, useEffect } from "react";
import "./BidCard.css";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import StageBar from "../../../components/StageBar/StageBar";
import { HeartFilled } from "@ant-design/icons";
import { BsStarFill } from "react-icons/bs";
import { Row, Col } from "antd";

import { Rate } from "antd";
import { getImageByStateCode } from "../../../shared/helpers";
import messageIcon from "../../../assets/icons/messageIcon.svg";
import moment from "moment";
import {
  createBidReset,
  postFavoriteGigCard,
  removeFavoriteGigCard,
  ResetBidData,
  retractBidReset,
  retractedBidUpdate,
  updateBidReset,
} from "../../../redux/actions";

import FavoriteFunction from "../FavoriteFunction/FavoriteFunction";
import { alertNotification } from "../../../components/notifications/alertNotification";
import defaultImage from "../../../assets/icons/defaultImage.svg";

const BidCard = (props) => {
  const { ProfessionCode } = useSelector((state) => state.profileReducer.user.profile) || "HC";
  const { bid, gig, BidStatuses } = props;
  const [Defaultplaceholder, setDefaultplaceholder] = useState(false);
  const [bidType, setBidType] = useState(null);
  const [RankingObject, setRankingObject] = useState(null);

  const history = useHistory();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   // console.log("ProposalStatuses", bid?.ProposalStatuses);
  //   // console.log(
  //     "raterate:",
  //     bid.Rate.$numberDecimal
  //       ? bid.Rate.$numberDecimal
  //       : bid.Rate
  //       ? bid.Rate.toFixed(2)
  //       : 0
  //   );
  // }, [bid]);

  const [bidRate, setBidRate] = useState(
    bidType == "Proposal"
      ? bid?.Rate?.$numberDecimal
        ? bid?.Rate?.$numberDecimal
        : bid?.Rate?.toLocaleString('en-US') 
        ? bid?.Rate?.toLocaleString('en-US', {minimumFractionDigits: 2,maximumFractionDigits: 2}) 
        : 0
      : bid?.ActualRate?.$numberDecimal
      ? bid?.ActualRate?.$numberDecimal
      : bid.ActualRate?.toLocaleString('en-US') 
      ? bid.ActualRate?.toLocaleString('en-US', {minimumFractionDigits: 2,maximumFractionDigits: 2}) 
      : 0
  );

  const [HighestBid, setHighestBid] = useState(
    bid.HighestBid?.$numberDecimal
      ? bid.HighestBid?.$numberDecimal
      : bid.HighestBid?.toLocaleString('en-US')
      ? bid.HighestBid?.toLocaleString('en-US', {minimumFractionDigits: 2,maximumFractionDigits: 2})
      : 0
  );

  const [placeholder, showPlaceholder] = useState(false);
  const [budgetHourly, setBudgetHourly] = useState(false);

  const [ranking, setRanking] = useState({});

  useEffect(() => {
    if (props.isReceivedBids) {
      setBidType("Bid");
      setBidRate(
        bid?.ActualRate?.$numberDecimal
          ? bid?.ActualRate?.$numberDecimal
          : bid.ActualRate?.toLocaleString('en-US')
          ? bid.ActualRate?.toLocaleString('en-US' , {minimumFractionDigits: 2,maximumFractionDigits: 2})
          : 0
      );
    } else {
      setBidType("Proposal");
      setBidRate(
        bid?.Rate?.$numberDecimal
          ? bid?.Rate?.$numberDecimal
          : bid?.Rate?.toLocaleString('en-US')
          ? bid?.Rate?.toLocaleString('en-US' ,{minimumFractionDigits: 2,maximumFractionDigits: 2})
          : 0
      );
    }

    const rankObj = BidStatuses.filter(
      (obj) => obj.BidStatusId === (bid.BidStatusId === 23 ? bid.BidStatusId - 9 : bid.BidStatusId)
      );

    setRanking(rankObj[0]);
  }, [bid, ranking?.Rank]);

  useEffect(() => {
    setRankingObject(ranking)
  }, [ranking])

  // Gig created on
  // let msec = Number(gig?.Created);
  let time = new Date(bid?.Modified);

  const locationURL = useLocation().pathname;
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );

  const addFavorite = () => {
    alertNotification("success", "bid added to favorites");
    dispatch(postFavoriteGigCard(bid?.JobOrderId, bidType));
  };

  const removeFavorite = () => {
    dispatch(removeFavoriteGigCard(bid?.JobOrderId));
    alertNotification("success", "bid removed from favorites");
  };

  // useUpdateLogger("bidddd", bid);

  const retractedBidUpdateClick = () => {
    props?.isRetractedBids
      ? history.push(`/details/${gig.JobOrderId}`)
      : history.push(`/receivedbid-detail/${gig.BidId}`);

    dispatch(retractedBidUpdate(true));
  };

  const Editbidpage = (id, Message, IdealTerms, BudgetType) => {
    history.push({
      pathname: `/edit-bid`,
      state: {
        id,
        Message,
        IdealTerms,
        BudgetType,
      },
    });
  };

  const getBidRateType = (type) =>
    type === "Week" ? "WK" : type === "Hour" ? "HRS" : type === "Year" ? "YR" : type;

  // useUpdateLogger("GigGig:", gig);
  // useUpdateLogger("Gigbid:", bid);

  return (
    <>
      <div
        className={
          ranking?.BidStatusName === "Canceled" ||
          ranking?.BidStatusName === "Terminated" ||
          ranking?.BidStatusName === "Retracted"
            ? "bid-card-disabled"
            : "bidding-cards"
        }
        onClick={() => {
          if (props?.isReceivedBids)
            history.push(`/receivedbid-detail/${bid.BidId}`);

          if (props?.isRetractedBids) retractedBidUpdateClick();
        }}
      >
        {props?.isReceivedBids && (
          <div className="bid-card-skill-title">
            {bid?.skill_listings?.Title}
          </div>
        )}
        {placeholder ? (
          <>
            <div
              onClick={() => {
                if (props.isPlaceBids)
                  history.push(`/placedbid-detail/${bid.ProposalId}`);
              }}
            >
              <img
                src={Defaultplaceholder ? getImageByStateCode() : getImageByStateCode(gig?.Regions?.Code,'state')}
                className={
                  props?.isReceivedBids
                    ? "sgigs-image-receiveBids"
                    : "bid-card-image"
                }
                onError={(e) => setDefaultplaceholder(true)}
              />
            </div>
            {/* <div>@</div> */}
          </>
        ) : (
          <>
            <div
              onClick={() => {
                if (props.isPlaceBids)
                  history.push(`/placedbid-detail/${bid.ProposalId}`);
              }}
            >
              <img
                src={
                  ProfessionCode === "IT" &&
                  gig?.WorkplacePolicy === "Fully Remote"
                    ? defaultImage
                    : getImageByStateCode(
                        // gig?.Location?.RegionCode,
                        gig?.Regions?.Code,
                        gig?.Cities?.CityName
                      )
                }
                className={
                  props?.isReceivedBids
                    ? "sgigs-image-receiveBids"
                    : "bid-card-image"
                }
                onError={(e) => showPlaceholder(true)}
              />
            </div>
          </>
        )}
        <div
          className={
            props?.isReceivedBids
              ? "edit-place-bid-icon-rb"
              : "edit-place-bid-icon"
          }
          // onClick={() => Editbidpage(gig._id)}
        >
          <FavoriteFunction
            isFavorite={bid?.IsFavorite}
            addFavorite={addFavorite}
            removeFavorite={removeFavorite}
          />
        </div>
        <div className="bid-card-detail-combined-section">
          <div
            className="bidding-title"
            onClick={() => {
              if (props.isPlaceBids)
                history.push(`/placedbid-detail/${bid.ProposalId}`);
            }}
          >
            {gig?.Title ? gig?.Title : "NA"}
          </div>
          {ProfessionCode === "IT" ? (
            <div className="bid-the-facility">
              {gig?.WorkplacePolicy ? gig?.WorkplacePolicy : "NA"}
            </div>
          ) : (
            <div className="bid-the-facility">
              {gig?.FacilityName ? gig?.FacilityName : "NA"}
            </div>
          )}
          <div
            className={`${
              ProfessionCode === "IT" ? "if_it_then_change" : "bid-information"
            }`}
          >
            {/* <Rate count={1} disabled={true} value={1} /> */}
            {/* <div className="information-left-outer">
              <div className="information-left">
                <BsStarFill className="bid-star" />
              </div>
              <div className="information-right">
                <div className="bid-rating">
                  {gig?.facilityRating?.rating
                    ? gig?.facilityRating?.rating
                    : 3.5}
                </div>
                <div className="review">
                  {gig?.facilityRating?.reviews &&
                    "(" + gig?.facilityRating?.reviews + ")"}
                </div>
              </div>
            </div> */}
            <div className="bid-review">
              <div className="bid-posted-on">{moment(time).fromNow()}</div>
            </div>
          </div>
        </div>
        {props?.isPlaceBids && (
          <div className="Place-bid-detail-status">
            <Row>
              <Col span={6}>
                <span>Total</span>&nbsp;
                <span className="place-Bid-count-status">
                  {bid.TotalBids} bids
                </span>{" "}
              </Col>
              <Col className="place-col-border" span={9}>
                <span>Desired pay</span>
                <span className="place-Bid-count-status">
                  {" "}
                  {"$" +
                    bidRate +
                    "/ " +
                    getBidRateType(bid?.BudgetType?.BudgetTypeName)}{" "}
                </span>{" "}
              </Col>
              <Col className="place-col-border" span={9}>
                <span>Highest bid</span>
                <span className="place-Bid-count-status">
                  {" "}
                  {"$" +
                    HighestBid +
                    "/ " +
                    getBidRateType(bid?.BudgetType?.BudgetTypeName)}{" "}
                </span>{" "}
              </Col>
            </Row>
          </div>
        )}
        {(props?.isReceivedBids || props?.isRetractedBids) && (
          <div className="divider-gig-card-details"></div>
        )}
        <div className="bid-details-below-divider-line">
          <div className="bid-gigcard-interested-btn">
            <div className="bid-budgets">
              {/* {isReceivedBids ? <></>:<></>} */}
              {/* {budgetHourly ? (
                <>
                  <span>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</span>
                  <span>${bidRate ? bidRate.toFixed(2) : 0}/HR</span>
                </>
              ) : (
                <>
                  <span>${gig?.BudgetMin ? gig?.BudgetMin : 0}/WK</span>
                  </>
                )} */}
              <span>
                ${bidRate}/{getBidRateType(bid?.BudgetType?.BudgetTypeName)}
              </span>
            </div>
            <div
              className="messageIcon clickable"
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: `/messages`,
                  state: {
                    openConvo: true,
                    conversationId: bid?.message?.ConversationId,
                  },
                });
              }}
            >
              <img src={messageIcon} alt="messageIcon" />
            </div>
          </div>
          <div className="bid-div1 ">
            {/* {ProfessionCode === "HC" ? "Pay Package" : "Estimated pay package"} */}
            {ProfessionCode === "HC"
              ? props?.isPlaceBids
                ? "Desired Pay"
                : "Pay Package"
              : props?.isPlaceBids
              ? "Desired Pay"
              : "Estimated pay package"}
          </div>
        </div>
        {(props?.isReceivedBids || props?.isPlaceBids) && (
          <div
            className="parent-div-stagebar"
            onClick={() => {
              if (props.isPlaceBids)
                history.push(`/placedbid-detail/${bid.ProposalId}`);
            }}
          >
            {props?.isReceivedBids ? (
              <StageBar
                length={16}
                stage={
                  props?.isReceivedBids && RankingObject?.BidStatusName === "New"
                    ? RankingObject?.Rank
                    : RankingObject?.Rank - 1
                }
                status={
                  RankingObject?.BidStatusName
                }
              />
            ) : (
              <StageBar
                length={17}
                  stage={
                    props?.isPlaceBids && RankingObject?.BidStatusName === "Interview Declined"
                      ? 8
                      : RankingObject?.Rank
                  }
                  status={
                    RankingObject?.BidStatusName
                  }
              />
            )}
          </div>
        )}
        {/* {props?.isPlaceBids && (
          <div className="parent-div-stagebar">
            <StageBar
              length={12}
              stage={ranking?.BidStatusId}
              // status={bid?.BidStatusName}
              status={ranking?.BidStatusName}
            />
          </div>
        )} */}
      </div>
      {/* ); */}
    </>
  );
};

export default BidCard;
