import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Typography,
  Tabs,
  Dropdown,
  Radio,
  Space,
  Menu,
  Badge,
  Drawer,
  Divider,
} from "antd";
import "./Messages.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchInboxData, fetchReceivedBidsStages } from "../../redux/actions";
// Private components
import MessagesReceivedTab from "./components/MessagesReceivedTab/MessagesReceivedTab";
import MessagesSentTab from "./components/MessagesSentTab/MessagesSentTab";
import { useHistory, useLocation } from "react-router-dom";
import MessageBox from "../DashBoard/components/MessageBox";
import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import MessageDetail from "./components/MessageDetailBid/MessageDetail";
import ProfileAvatar from "../../components/ProfileAvatar";
import searchbar12 from "../../assets/icons/searchBar12.svg";
import searchbar21 from "../../assets/icons/searchBar21.svg";
import notificationfilled from "../../assets/icons/notificationfilled.svg";
import notificationfilledgreen from "../../assets/icons/notificationfilledgreen.svg";
import chatfill from "../../assets/icons/chatfill.svg";
import chatfillgrey from "../../assets/icons/chatfillgrey.svg";
import ClarityWindow from "../../assets/icons/clarity_window-closeLine.svg";
import KeyBoardArrowDown from "../../assets/icons/KeyboardArrowDown.svg";
import CarbonCloseFilled from "../../assets/icons/carbon_close-filled.svg";
import GroupChatIcon from "../../assets/icons/GroupChatIcon.svg";
import claritySign from "../../assets/icons/clarity_window-closeLine.svg";
import { disable } from "workbox-navigation-preload";
import cilFilter from "../../assets/icons/cilFilter.svg";
import OnboardingMessageSend from "./components/OnboardingMessageSend/OnboardingMessageSend";

const { Title } = Typography;
const { TabPane } = Tabs;
const responsiveCardSizes = {
  xs: {
    span: 22,
    offset: 1,
  },
  md: {
    span: 20,
    offset: 2,
  },
};
const Messages = (props) => {
  const [user, setUser] = useState("");
  useEffect(() => {
    setUser(localStorage.getItem("userName"));
  }, []);
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  //const inboxData = useSelector((state) => state.messagesReducer.inboxData);
  const { inboxData } = props;
  const [messages, setMessages] = useState([]);
  const [reciepentName, setreciepentName] = useState();
  const [RecipientUserPictureUrl, setRecipientUserPictureUrl] = useState();
  // console.log("====================================");
  // console.log(messages);
  // console.log("====================================");
  const [dropVisible, setdropVisible] = useState(false);
  const [messageLength, setmessageLength] = useState(0);
  const [selected, setSelected] = useState("All conversation");
  const [showSearch, setShowSearch] = useState(true);
  const [docsSearchData, setDocsSearchData] = useState([]);
  const [searchMessage, setSearchMessage] = useState("");
  const [docsData, setDocsData] = useState([]);
  const [uploadCount, setUploadcount] = useState(0);
  const [searchSelect, setSearchSelect] = useState("");
  const [clicked, setClicked] = useState(false);
  const [SwitchWindow, setSwitchWindow] = useState("messages");
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [visible, setVisible] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [filterCount, setFilterCount] = useState(2);
  const offSearch = false;
  const stagefilter = [
    "New",
    "Accepted",
    "Declined",
    "Profile Submitted",
    "Interview",
    "Interview Accepted",
    "Interview Declined",
    "Offer",
    "Offer Accepted",
    "Offer Declined",
    "Hired",
    "On Billing",
    "Off Billing",
    "Billing Extended",
    "Cancelled",
    "Terminated",
    "Retracted",
  ];
  const [Filter1, setFilter1] = useState("All Conversations");
  const [Filter2, setFilter2] = useState("All Bids");
  const [Filter3, setFilter3] = useState(null);
  const [isOpens, setIsOpens] = useState(false);
  useEffect(() => {
    dispatch(fetchInboxData(150, 1, Filter1, Filter2, Filter3));
    dispatch(fetchReceivedBidsStages());
  }, []);
  const MessageStatus = useSelector((state) => state.BidsReducer.bidStatus);
  const messageFilter = () => {
    // console.log("messageFilter");
    dispatch(fetchInboxData(50, 1, Filter1, Filter2, Filter3));
    setdropVisible(false);
    closeFilterDrawer();
    setIsOpens(!isOpens);
    if (Filter3 !== null) {
      setFilterCount(3);
    }
  };
  // console.log("Filter3", Filter3);
  const onChange = (e) => {
    // console.log("radio checked1", e.target.value);
    setFilter1(e.target.value);
  };
  const onChangeFilterSecond = (e) => {
    // console.log("radio checked2", e.target.value);
    setFilter2(e.target.value);
  };
  const onChangeFilterThird = (e) => {
    // console.log("radio checked3", e.target.value);
    setFilter3(e.target.value);
  };
  const clearFilter = () => {
    setFilter1("All Conversations");
    setFilter2("All Bids");
    setFilter3(null);
    dispatch(fetchInboxData(50, 1, "All Conversations", "All Bids", null));
    setdropVisible(false);
    closeFilterDrawer();
    setIsOpens(!isOpens);
    setFilterCount(2);
  };
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  const onClose = () => {
    setVisible(false);
  };
  const showDrawer = () => {
    setVisible(true);
  };
  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };
  const handleFilterDrawer = (state) => {
    setFilterDrawer(state);
  };
  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);
  const handleVisibleChange = (visible) => {
    setdropVisible(visible);
  };
  const profile = useSelector((state) => state.profileReducer.user);
  //const inboxDataInfo = useSelector((state) => state.messagesReducer.inboxData);
  const messageInboxParams = useSelector(
    (state) => state.messagesReducer.inboxDataParameters
  );
  const datafilterSort = () => {
    if (inboxData?.conversations) {
      const docs = inboxData?.conversations;
      // Upload Count
      const count = inboxData?.filter((data) => data?.conversation);
      const notUploaded = inboxData?.filter((data) => !data?.conversation);
      if (count.length > 0) {
        setDocsData(count.concat(notUploaded));
      } else setDocsData(inboxData);
      // console.log("Docs list updated");
      setUploadcount(count.length);
    }
  };

  const resetDocArray = () => {
    setMessages(inboxData?.conversations);
    setSearchSelect("");
  };
  const getFilterMessage = (val) => {
    setSearchSelect(val);
    // // console.log(docsData);
    const value = val.toLowerCase();
    // // console.log(value);
    const filterData = inboxData?.conversations?.filter((data) => {
      if (data?.JobTitle) {
        const dataCheck = data?.JobTitle.toLowerCase();
        return dataCheck.indexOf(value) != -1;
      }
    });
    // console.log(filterData);
    setMessages(filterData);
  };
  useEffect(() => {
    inboxData && inboxData?.conversations?.length
      ? setMessages(inboxData?.conversations)
      : setMessages([]);
  }, [inboxData]);
  useEffect(() => {
    if (
      selected === "Unread" &&
      inboxData &&
      inboxData?.conversations?.length
    ) {
      let unread = inboxData?.conversations.filter((e) => {
        return e.countUnRead > 0;
      });
      setMessages(unread);
    } else {
      setMessages(inboxData?.conversations);
    }
  }, [selected]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpens(false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const userName = "Abhishek";
  const titlebarFilter = (
    <div className="message-mobile-titleBar">
      <span className="message-mobile-titleBar-Clear"> Clear all</span>
      <span className="message-mobile-titleBar-filter">Filter</span>
    </div>
  );
  return (
    <>
      {dimensions > 720 ? (
        <div className={"messages-container"}>
          <div className={"messages-tab-bar-container"}>
            <div className={"conversations"}>
              {showSearch ? (
                <div className="conversationFilter">
                  {/* <div className="filter">All Conversation (0)</div> */}
                  <div className="manage-bids-button">
                    <div
                      className={selected === "Unread" ? "select12" : "select"}
                    >
                      {selected} (
                      {messageInboxParams?.data?.totalResults
                        ? messageInboxParams?.data?.totalResults
                        : 0}
                      )
                    </div>
                    <div className="manage-bids-button-inside">
                      <div
                        className="selectorBox"
                        onClick={(e) => setIsOpens(!isOpens)}
                      >
                        <div className="cilFilter-messages">
                          <img src={cilFilter} alt="emailBoxIcon" />
                          <span className="cilFilter-messages-count">
                            {filterCount}
                          </span>
                        </div>
                      </div>
                      <div className="searchbar-messages">
                        <img
                          className="searchbar12"
                          src={searchbar12}
                          alt="searchbar12"
                          onClick={() => setShowSearch(false)}
                        />
                      </div>
                    </div>
                  </div>
                  {isOpens ? (
                    <div ref={wrapperRef} className="dropDownMenu">
                      <div className="FilterBidByStatus1">
                        {/* <Menu> */}
                        <Radio.Group
                          className="radioSortReceivedBidsGroup2"
                          onChange={onChange}
                          value={Filter1}
                          buttonStyle={{ color: "green" }}
                        //  onChange={onChangeSort}
                        // value={receivedBidSort}
                        >
                          <Space direction="vertical">
                            <Radio
                              // className="radioSortReceivedBids"
                              value={"All conversations"}
                            >
                              <div className="message-filter-options">
                                {" "}
                                <div>All Conversationsssss </div>
                                <div className="message-filter-counts">
                                  ({messageInboxParams?.data?.totalResults})
                                </div>{" "}
                              </div>
                            </Radio>
                            <Radio
                              // className="radioSortReceivedBids"
                              value={"Unread"}
                            >
                              <div className="message-filter-options">
                                {" "}
                                <div> Unread </div>
                                <div className="message-filter-counts">
                                  ({messageInboxParams?.data?.allCount?.UnRead}){" "}
                                </div>{" "}
                              </div>
                            </Radio>
                            <Radio
                              // className="radioSortReceivedBids"
                              value={"Favorites"}
                            >
                              <div className="message-filter-options">
                                {" "}
                                <div className="message-filter-radio-btns2">
                                  {" "}
                                  Favorites{" "}
                                </div>
                                <div className="message-filter-counts">
                                  (
                                  {
                                    messageInboxParams?.data?.allCount
                                      ?.Favorites
                                  }
                                  ){" "}
                                </div>{" "}
                              </div>
                            </Radio>
                          </Space>
                        </Radio.Group>
                        {/* </Menu> */}
                      </div>
                      <div className="FilterBidByStatus"> Bid type </div>
                      <div className="FilterBidByStatus2">
                        <Radio.Group
                          onChange={onChangeFilterSecond}
                          value={Filter2}
                          className="radioSortReceivedBidsGroup2"
                        //  onChange={onChangeSort}
                        // value={receivedBidSort}
                        >
                          <Space direction="vertical">
                            <Radio
                              // className="radioSortReceivedBids"
                              value={"All Bids"}
                            >
                              <div className="message-filter-options">
                                {" "}
                                <span className="message-filter-options2">
                                  {" "}
                                  All bids{" "}
                                </span>
                                <span className="message-filter-counts">
                                  ({messageInboxParams?.data?.allCount?.AllBids}
                                  ){" "}
                                </span>{" "}
                              </div>
                            </Radio>
                            <Radio
                              // className="radioSortReceivedBids"
                              value={"Proposal"}
                            >
                              <div className="message-filter-options">
                                {" "}
                                <span className="message-filter-options2">
                                  {" "}
                                  Placed bids{" "}
                                </span>
                                <span className="message-filter-counts">
                                  (
                                  {
                                    messageInboxParams?.data?.allCount
                                      ?.PlacedBids
                                  }
                                  ){" "}
                                </span>{" "}
                              </div>
                            </Radio>
                            <Radio
                              // className="radioSortReceivedBids"
                              value={"Bid"}
                            >
                              <div className="message-filter-options">
                                {" "}
                                <div className="message-filter-options2">
                                  {" "}
                                  Received bids{" "}
                                </div>
                                <div className="message-filter-counts">
                                  (
                                  {
                                    messageInboxParams?.data?.allCount
                                      ?.ReceivedBids
                                  }
                                  ){" "}
                                </div>{" "}
                              </div>
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </div>
                      <div className="FilterBidByStatus">
                        {" "}
                        Filter by bid status{" "}
                      </div>
                      <div className="FilterBidByStatus3">
                        <Radio.Group
                          className="radioSortReceivedBidsGroup2"
                          onChange={onChangeFilterThird}
                          //  onChange={onChangeSort}
                          // value={receivedBidSort}
                          value={Filter3}
                        >
                          <Space direction="vertical">
                            {MessageStatus?.map((Stages) => {
                              return (
                                <>
                                  <Radio value={Stages?._id}>
                                    <div className="message-filter-options">
                                      <div className="message-filter-options2">
                                        {Stages?.BidStatusName}
                                      </div>
                                      <div className="message-filter-counts">
                                        (
                                        {Stages?.BidStatusName === "New"
                                          ? messageInboxParams?.data?.allCount
                                            ?.New
                                          : Stages?.BidStatusName ===
                                            "Retracted"
                                            ? messageInboxParams?.data?.allCount
                                              ?.Retracted
                                            : Stages?.BidStatusName === "Accepted"
                                              ? messageInboxParams?.data?.allCount
                                                ?.Accepted
                                              : Stages?.BidStatusName === "Declined"
                                                ? messageInboxParams?.data?.allCount
                                                  ?.Declined
                                                : Stages?.BidStatusName ===
                                                  "Profile submitted"
                                                  ? messageInboxParams?.data?.allCount
                                                    ?.ProfileSubmitted
                                                  : Stages?.BidStatusName ===
                                                    "Interview"
                                                    ? messageInboxParams?.data?.allCount
                                                      ?.Interview
                                                    : Stages?.BidStatusName ===
                                                      "Interview accepted"
                                                      ? messageInboxParams?.data?.allCount
                                                        ?.InterviewAccepted
                                                      : Stages?.BidStatusName ===
                                                        "Interview declined"
                                                        ? messageInboxParams?.data?.allCount
                                                          ?.InterviewDeclined
                                                        : Stages?.BidStatusName === "Offer"
                                                          ? messageInboxParams?.data?.allCount
                                                            ?.Offer
                                                          : Stages?.BidStatusName ===
                                                            "Offer accepted"
                                                            ? messageInboxParams?.data?.allCount
                                                              ?.OfferAccepted
                                                            : Stages?.BidStatusName ===
                                                              "Offer declined"
                                                              ? messageInboxParams?.data?.allCount
                                                                ?.OfferDeclined
                                                              : Stages?.BidStatusName === "Hired"
                                                                ? messageInboxParams?.data?.allCount
                                                                  ?.Hired
                                                                : Stages?.BidStatusName ===
                                                                  "On billing"
                                                                  ? messageInboxParams?.data?.allCount
                                                                    ?.OnBilling
                                                                  : Stages?.BidStatusName ===
                                                                    "Off billing"
                                                                    ? messageInboxParams?.data?.allCount
                                                                      ?.OffBilling
                                                                    : Stages?.BidStatusName ===
                                                                      "Billing extend"
                                                                      ? messageInboxParams?.data?.allCount
                                                                        ?.BillingExtend
                                                                      : Stages?.BidStatusName === "Canceled"
                                                                        ? messageInboxParams?.data?.allCount
                                                                          ?.Canceled
                                                                        : Stages?.BidStatusName ===
                                                                          "Terminated"
                                                                          ? messageInboxParams?.data?.allCount
                                                                            ?.Terminated
                                                                          : null}
                                        )
                                      </div>
                                    </div>
                                  </Radio>
                                </>
                              );
                            })}
                          </Space>
                        </Radio.Group>
                      </div>
                      <div className="inner-filter-sort-btns">
                        <div
                          className="inner-filter-sort-btns1"
                          onClick={() => clearFilter()}
                          trigger={["click"]}
                        >
                          Clear all{" "}
                        </div>
                        <div
                          className="inner-filter-sort-btns2"
                          onClick={() => messageFilter()}
                          trigger={["click"]}
                        >
                          Apply{" "}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* <ProfileHeader hide={hideDrop} dropVisible={dropVisible} /> */}
                </div>
              ) : (
                <div className="inputSelectMenu">
                  {/*  search bar  */}
                  <div className="input-select-dropdown">
                    <div className="input-select">
                      {searchSelect ? null : (
                        <img
                          style={{ marginRight: "5px" }}
                          src={searchbar21}
                          alt="CarbonCloseFilled"
                        />
                      )}
                      <input
                        value={searchSelect}
                        placeholder="Find gig title"
                        onChange={(e) => getFilterMessage(e.target.value)}
                      // onKeyDown={handleKeyDown}
                      />
                      {searchSelect ? (
                        <span className="close-search">
                          {inboxData?.conversations?.length > 0 && (
                            // <MdOutlineClose
                            //    size="1.25rem"
                            //    color="#b2b2b2"
                            //    className="clickable"
                            //    onClick={resetDocArray}
                            // />
                            <img
                              src={CarbonCloseFilled}
                              alt="CarbonCloseFilled"
                              className="clickable"
                              onClick={resetDocArray}
                            />
                          )}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <img
                    className="claritySign12"
                    src={claritySign}
                    alt="claritySign"
                    onClick={() => {
                      setShowSearch(true);
                      setSearchSelect("");
                    }}
                  />
                </div>
              )}
              {!showSearch && searchSelect.length == 0 ? null : (
                <div className="conversation-threads">
                  {messages &&
                    messages.length > 0 &&
                    messages.map((data) => {
                      return (
                        <MessageBox
                          data={data}
                          search={searchSelect}
                          setreciepentName={setreciepentName}
                          setRecipientUserPictureUrl={
                            setRecipientUserPictureUrl
                          }
                          setClicked={setClicked}
                          setMessages={setMessages}
                        />
                      );
                    })}
                </div>
              )}
            </div>
            {clicked ? (
              <div className={"conversationsDetails"}>
                {/* <div className="conversationHeader">
            <Badge dot={true} status={"default"}>
              <div className="Avat">{profile?.name.charAt(0)}</div>
            </Badge>
            <ProfileAvatar proPic={profile?.reciepentName} />
          </div> */}
                <div className="conversationHeader">
                  {/* <Badge dot={true} status={"default"}>
          <div className="Avat">{profile?.name.charAt(0)}</div>
        </Badge> */}
                  <div className="Message-box-profile message-align-hgt">
                    <div className="profile-img-candidate12">
                      {RecipientUserPictureUrl ? (
                        <img
                          className="profile-img-inner-pic12"
                          src={`${process.env.REACT_APP_S3_URL}${RecipientUserPictureUrl?.charAt(0) !== '/' ? '/' : ""}${RecipientUserPictureUrl}`}
                        />
                      ) : (
                        <div className="profile-img-inner-name">
                          {reciepentName?.charAt(0)}
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        setMessages?.length > 0 ? "Ellipse-message-dot12" : null
                      }
                    ></div>
                    <div className="receipent-name">
                      <span className="name">
                        {reciepentName ? reciepentName : ""}
                      </span>
                      <span className="last-seen">Last seen 1 hour ago</span>
                    </div>
                  </div>
                  {/* <div className="receipent-name">
          <div>{props.reciepentName}</div>
          <div>Last seen</div>
        </div> */}
                </div>
                <MessageDetail
                  reciepentName={reciepentName}
                  RecipientUserPictureUrl={RecipientUserPictureUrl}
                />
                <OnboardingMessageSend />
              </div>
            ) : (
              <div className="conversation-inbox-icon">
                <img
                  className="conversation-message-image"
                  src={GroupChatIcon}
                  alt="groupChatIcon"
                />
                <div className="conversation-message">
                  {" "}
                  Select a conversation{" "}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {!clicked ? (
            <div className="message-mobile-container">
              <div className="message-mobile-inbox">
                <Row>
                  <Col
                    span={12}
                    onClick={() => setSwitchWindow("Notifications")}
                  >
                    {SwitchWindow === "Notifications" ? (
                      <img src={notificationfilledgreen} />
                    ) : (
                      <img src={notificationfilled} />
                    )}
                    <span
                      className={
                        SwitchWindow === "Notifications"
                          ? "text-color-green"
                          : null
                      }
                    >
                      {" "}
                      Notifications (0)
                    </span>
                  </Col>
                  <Col span={12} onClick={() => setSwitchWindow("messages")}>
                    {SwitchWindow === "messages" ? (
                      <img src={chatfill} />
                    ) : (
                      <img src={chatfillgrey} />
                    )}
                    <span
                      className={
                        SwitchWindow === "messages" ? "text-color-green" : null
                      }
                    >
                      Inbox (
                      {messageInboxParams?.data?.allCount?.UnRead
                        ? messageInboxParams?.data?.allCount?.UnRead
                        : 0}
                      )
                    </span>
                  </Col>
                </Row>
              </div>
              {SwitchWindow === "messages" ? (
                <div className="message-mobile-Conversation">
                  <Row>
                    <Col span={24}>
                      <div>
                        <span className="message-mobile-Conversation-span-1">
                          All conversation{" "}
                        </span>
                        &nbsp;
                        <span className="message-mobile-Conversation-span-2">
                          (
                          {messageInboxParams?.data?.totalResults
                            ? messageInboxParams?.data?.totalResults
                            : 0}
                          )
                        </span>
                      </div>
                      <div className="message-mobile-icon-section">
                        <div
                          className="message-mobile-filter"
                          onClick={() => {
                            handleFilterDrawer(!filterDrawer);
                          }}
                        >
                          <img src={cilFilter} />
                          <span>{filterCount}</span>
                        </div>
                        <div
                          className="message-mobile-searchBar"
                          onClick={showDrawer}
                        >
                          <img src={searchbar12} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    {/* {!showSearchs && searchSelect.length == 0 ? null : ( */}
                    {messages?.length > 0 ? (
                      messages &&
                      messages?.length > 0 &&
                      messages?.map((data) => {
                        return (
                          <MessageBox
                            data={data}
                            // search={searchSelect}
                            setreciepentName={setreciepentName}
                            setRecipientUserPictureUrl={
                              setRecipientUserPictureUrl
                            }
                            setClicked={setClicked}
                            setMessages={setMessages}
                          />
                        );
                      })
                    ) : (
                      <div className="message-mobile-noImage-msg">
                        <img src={GroupChatIcon} />
                        <span>No conversations</span>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>Notifications</div>
              )}
              <Drawer
                placement={"top"}
                width={500}
                height={700}
                onClose={onClose}
                visible={visible}
                className="message-mobile-Drawer messageMobileDrawer"
              >
                <div className="message-mobile-Drawer-msg">
                  <div className="inputSelectMenu message-mobile-msg-search ">
                    {/*  search bar  */}
                    <div className="input-select-dropdown">
                      <div className="input-select ">
                        {searchSelect ? null : (
                          <img
                            style={{ marginRight: "5px" }}
                            src={searchbar21}
                            alt="CarbonCloseFilled"
                          />
                        )}
                        <input
                          value={searchSelect}
                          placeholder="Find gig title"
                          onChange={(e) => getFilterMessage(e.target.value)}
                        // onKeyDown={handleKeyDown}
                        />
                        <span className="message-mobile-closeSearch">
                          {inboxData?.conversations?.length > 0 &&
                            searchSelect ? (
                            <img
                              src={CarbonCloseFilled}
                              alt="CarbonCloseFilled"
                              className="clickable"
                              onClick={resetDocArray}
                            />
                          ) : null}
                        </span>
                      </div>
                    </div>
                    <div className="closeDrowerBtn" onClick={onClose}>
                      <img src={claritySign} alt="" />
                    </div>
                  </div>
                  <Divider />
                  {!offSearch && searchSelect.length == 0 ? null : (
                    <div>
                      {messages &&
                        messages?.length > 0 &&
                        messages?.map((data) => {
                          return (
                            <MessageBox
                              data={data}
                              search={searchSelect}
                              setreciepentName={setreciepentName}
                              setRecipientUserPictureUrl={
                                setRecipientUserPictureUrl
                              }
                              setClicked={setClicked}
                              setMessages={setMessages}
                              hideFavIcon={true}
                            />
                          );
                        })}
                    </div>
                  )}
                </div>
              </Drawer>
              <Drawer
                title="Filter"
                placement="top"
                onClose={closeFilterDrawer}
                visible={filterDrawer}
                className="mobile-message-Drawer-top"
                height="40rem"
                width="100%"
              >
                <div
                  className="mobile-message-AllClearButton"
                  onClick={clearFilter}
                >
                  Clear all
                </div>
                <Radio.Group
                  className="message-mobile-radiofilterMessageGroup"
                  onChange={onChange}
                  value={Filter1}
                >
                  <Space direction="vertical">
                    <Radio
                      className="message-mobile-radiofilterMessage"
                      value={"All Conversations"}
                    >
                      <div className="message-mobile-span-text-value">
                        <span>All conversations</span>
                        <span className="message-mobile-length-count">
                          {" "}
                          ({messageInboxParams?.data?.totalResults})
                        </span>
                      </div>
                    </Radio>
                    <Radio
                      className="message-mobile-radiofilterMessage"
                      value={"Unread"}
                    >
                      <div className="message-mobile-span-text-value">
                        <span>Unread</span>
                        <span className="message-mobile-length-count">
                          {" "}
                          ({messageInboxParams?.data?.allCount?.UnRead})
                        </span>
                      </div>
                    </Radio>
                    <Radio
                      className="message-mobile-radiofilterMessage"
                      value={"Favorites"}
                    >
                      <div className="message-mobile-span-text-value">
                        <span>Favorites</span>
                        <span className="message-mobile-length-count">
                          ({messageInboxParams?.data?.allCount?.Favorites})
                        </span>
                      </div>
                    </Radio>
                  </Space>
                </Radio.Group>
                <div className="message-mobile-FilterBidByStatus">
                  {" "}
                  Bid type{" "}
                </div>
                <Radio.Group
                  className="message-mobile-radiofilterMessageGroup"
                  onChange={onChangeFilterSecond}
                  value={Filter2}
                >
                  <Space direction="vertical">
                    <Radio
                      className="message-mobile-radiofilterMessage"
                      value={"All Bids"}
                    >
                      <div className="message-mobile-span-text-value">
                        <span>All bids</span>
                        <span className="message-mobile-length-count">
                          ({messageInboxParams?.data?.allCount?.AllBids})
                        </span>
                      </div>
                    </Radio>
                    <Radio
                      className="message-mobile-radiofilterMessage"
                      value={"Placed Bids"}
                    >
                      <div className="message-mobile-span-text-value">
                        <span>Placed bids</span>
                        <span className="message-mobile-length-count">
                          ({messageInboxParams?.data?.allCount?.PlacedBids})
                        </span>
                      </div>
                    </Radio>
                    <Radio
                      className="message-mobile-radiofilterMessage"
                      value={"Received Bids"}
                    >
                      <div className="message-mobile-span-text-value">
                        <span>Received bids</span>
                        <span className="message-mobile-length-count">
                          ({messageInboxParams?.data?.allCount?.ReceivedBids})
                        </span>
                      </div>
                    </Radio>
                  </Space>
                </Radio.Group>
                <div className="message-mobile-FilterBidByStatus">
                  {" "}
                  Filter by bid status
                </div>
                <Radio.Group
                  className="message-mobile-radiofilterMessageGroup"
                  onChange={onChangeFilterThird}
                  value={Filter3}
                >
                  <Space direction="vertical">
                    {MessageStatus.map((Stages) => {
                      return (
                        <>
                          <Radio
                            className="message-mobile-radiofilterMessage"
                            value={Stages?._id}
                          >
                            <div className="message-mobile-span-text-value">
                              <span>{Stages?.BidStatusName}</span>
                              <span className="message-mobile-length-count">
                                (
                                {Stages?.BidStatusName === "New"
                                  ? messageInboxParams?.data?.allCount?.New
                                  : Stages?.BidStatusName === "Retracted"
                                    ? messageInboxParams?.data?.allCount
                                      ?.Retracted
                                    : Stages?.BidStatusName === "Accepted"
                                      ? messageInboxParams?.data?.allCount?.Accepted
                                      : Stages?.BidStatusName === "Declined"
                                        ? messageInboxParams?.data?.allCount?.Declined
                                        : Stages?.BidStatusName ===
                                          "Profile submitted"
                                          ? messageInboxParams?.data?.allCount
                                            ?.ProfileSubmitted
                                          : Stages?.BidStatusName === "Interview"
                                            ? messageInboxParams?.data?.allCount
                                              ?.Interview
                                            : Stages?.BidStatusName ===
                                              "Interview accepted"
                                              ? messageInboxParams?.data?.allCount
                                                ?.InterviewAccepted
                                              : Stages?.BidStatusName ===
                                                "Interview declined"
                                                ? messageInboxParams?.data?.allCount
                                                  ?.InterviewDeclined
                                                : Stages?.BidStatusName === "Offer"
                                                  ? messageInboxParams?.data?.allCount?.Offer
                                                  : Stages?.BidStatusName === "Offer accepted"
                                                    ? messageInboxParams?.data?.allCount
                                                      ?.OfferAccepted
                                                    : Stages?.BidStatusName === "Offer declined"
                                                      ? messageInboxParams?.data?.allCount
                                                        ?.OfferDeclined
                                                      : Stages?.BidStatusName === "Hired"
                                                        ? messageInboxParams?.data?.allCount?.Hired
                                                        : Stages?.BidStatusName === "On billing"
                                                          ? messageInboxParams?.data?.allCount
                                                            ?.OnBilling
                                                          : Stages?.BidStatusName === "Off billing"
                                                            ? messageInboxParams?.data?.allCount
                                                              ?.OffBilling
                                                            : Stages?.BidStatusName === "Billing extend"
                                                              ? messageInboxParams?.data?.allCount
                                                                ?.BillingExtend
                                                              : Stages?.BidStatusName === "Canceled"
                                                                ? messageInboxParams?.data?.allCount?.Canceled
                                                                : Stages?.BidStatusName === "Terminated"
                                                                  ? messageInboxParams?.data?.allCount
                                                                    ?.Terminated
                                                                  : null}
                                )
                              </span>
                            </div>
                          </Radio>
                        </>
                      );
                    })}
                  </Space>
                </Radio.Group>
                <div className="mobile-message-Apply-filter">
                  <div className="mobile-message-radioShowItemsParent ">
                    <div
                      className="mobile-message-radioShowItemsChild"
                      onClick={messageFilter}
                    >
                      Apply filter
                    </div>
                  </div>
                </div>
              </Drawer>
            </div>
          ) : (
            <div className={"conversationsDetails"}>
              {/* <div className="conversationHeader">
    <Badge dot={true} status={"default"}>
      <div className="Avat">{profile?.name.charAt(0)}</div>
    </Badge>
    <ProfileAvatar proPic={profile?.reciepentName} />
  </div> */}
              <div className="conversationHeader">
                {/* <Badge dot={true} status={"default"}>
  <div className="Avat">{profile?.name.charAt(0)}</div>
</Badge> */}
                <div className="Message-box-profile message-align-hgt">
                  <div className="profile-img-candidate12">
                    {RecipientUserPictureUrl ? (
                      <img
                        className="profile-img-inner-pic12"
                        src={`${process.env.REACT_APP_S3_URL}${RecipientUserPictureUrl?.charAt(0) !== '/' ? '/' : ""}${RecipientUserPictureUrl}`}
                      />
                    ) : (
                      <div className="profile-img-inner-name">
                        {reciepentName?.charAt(0)}
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      setMessages?.length > 0 ? "Ellipse-message-dot12" : null
                    }
                  ></div>
                  <div className="receipent-name">
                    <span className="name">
                      {reciepentName ? reciepentName : ""}
                    </span>
                    <span className="last-seen">Last seen 1 hour ago</span>
                  </div>
                </div>
                {/* <div className="receipent-name">
<div>{props.reciepentName}</div>
<div>Last seen</div>
</div> */}
              </div>
              <MessageDetail
                reciepentName={reciepentName}
                RecipientUserPictureUrl={RecipientUserPictureUrl}
              />
              <OnboardingMessageSend />
            </div>
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = ({ messagesReducer }) => {
  return {
    inboxData: messagesReducer.inboxData,
  };
};
export default connect(mapStateToProps, null)(Messages);
