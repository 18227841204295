import { call, put, takeEvery, select } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchSearchRegionAPI } from "../../API/API";
import { fetchRegionFailure } from "../actions";

function* searchRegionSaga(params) {
  // console.log("LOCATION in SAGA:", params);
  try {
    const response = yield call(fetchSearchRegionAPI, params); // API Backend
    // console.log(response);
    yield put({
      type: types.FETCH_SEARCH_REGION_SUCCESS,
      payload: response.data,
    });
    // if (response.data.status === "success") {
    //   const data = {
    //     key: response.data.payload,
    //     CredentialDocumentId: params.params.documentId,
    //   };
    //   yield put(uploadDocSuccess(data));
    // } else {
    //   yield put(uploadDocFailure(response.errorMessage));
    // }
  } catch (error) {
    // console.log(error);
    yield put(fetchRegionFailure(error));
  }
}

export function* watchSearchRegionSaga() {
  yield takeEvery(types.FETCH_SEARCH_REGION_INIT, searchRegionSaga);
}
