import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import "./ManageAssignments.css";
import { Avatar, Card, DatePicker, Rate, Space } from "antd";
const { RangePicker } = DatePicker;
const ManageAssignment = () => {
  let userStatus = [
    "On Billing",
    "Billing Extended",
    "Off Billing",
    "Terminated",
    "Cancelled",
    "Pending for Rating",
    "Bid Again",
  ];

  return (
    <div className="ManageAssigned-main">
      <div className="manage_tagline">Manage assignments</div> 

       <div>
        <ul className="manage_UserStatus">
          {userStatus.map((name) => (
            <li key={name}> {name} </li>
          ))}
        </ul>
        <hr className="scroll-dividerr" />
      </div>

      <div className="search_manage">
        <div className="input-select-dropdown">
          <div className="input-select">
            <span className="search-cred">
              <AiOutlineSearch color="#b2b2b2" size="1.25rem" />
            </span>
            <input placeholder="Find gig title" />
          </div>
        </div>

        <Space direction="vertical" size={12} marginTop="21px">
          <RangePicker />
        </Space>
      </div>

      <div className="manage_tagline2">Showing 1 result</div>

      <div className="infobox-main">
        <div className="infobox-inside">
          <div className="infobox-params">
            <div>Details</div>
            <div className="infobox-params2">
              Ended on
              <div>Total earnings</div>
              <div>status</div>{" "}
            </div>
          </div>
        </div>

        <div className="infobox-inside2">
          <div className="dashboard-rating-review-Container2">
            <div className="rating-review-internals">
              <Avatar size={40}> U </Avatar>
            </div>

            <div className="rating-review-internalboxx">
              <div className="rating-review-internals">
                <div className="titleJob">Gig title</div>
                <div className="employerName">Employer name</div>
              </div>
              <div className="rating-review-internals2">
                <div className="rating-review-internals3">08/12/2022</div>

                <div className="rating-review-internals4">
                  <div className="rating-review-internals3">$7000</div>
                  <div className="rating-review-internals3">Off billing</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 

 
     

    </div>

   
  );
};

export default ManageAssignment;
