import axios from "axios";
import { fetchToken } from "../API/API";

const BackendApiBaseUrl = process.env.REACT_APP_API_URL;

const downloadFilesfromChat = `${BackendApiBaseUrl}/message/getattachment`;
// const downloadFilesfromChat = "http://192.168.18.40:4000/development/message/getattachment";

const downloadChatFilesApi = async (FilePath) => {
  const headers = fetchToken();
  const result = await axios.get(`${downloadFilesfromChat}/${FilePath}`, {
    headers,
  });
  return result;
};
export default downloadChatFilesApi;
