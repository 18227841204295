import React, { useEffect, useState } from "react";
import { Col, Row, Avatar, Badge, Rate } from "antd";
import { StarFilled, ArrowLeftOutlined } from "@ant-design/icons";
import { BsCurrencyDollar, BsStarFill } from "react-icons/bs";
import "./styles.css";
import { getImage } from "../../../../shared/downloadAttachments";
import WS from "../../../../webSocket/defaultSocket";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const ChatHeader = ({
  chatDetails,
  setshowHistory,
  createdTime,
  jobDetails,
  facilityName,
  Rating,
  Reviews,
}) => {
  const [profilePic, setProfilePic] = useState();
  const [title, setTitle] = useState();
  const history = useHistory();
  const [rating, setRating] = useState(3.5);
  const [chatType, setChatType] = useState();
  const [imageError, setImageError] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  // let userProfile = JSON.parse(localStorage.getItem("userProfile"));
  let userProfile = useSelector((state) => state.profileReducer.user);
  const emailVerification = useSelector(
    (state) => state.profileReducer.user.profile.verification
  );


  useEffect(() => {
    if (emailVerification) {
      if (emailVerification.email) {
        setIsEmailVerified(emailVerification.email);
      }
    }
  }, []);

  useEffect(async () => {
    setRating(chatDetails && chatDetails[0]?.RecipientUser?.Profiles?.Rating);

    let profilePic = "";
    if (
      chatDetails?.length > 0 &&
      chatDetails[0].RecipientUser?.profile?.PictureUrl
    ) {
      profilePic = await getImage(
        chatDetails[0].RecipientUser.profile.PictureUrl
      );
    } else {
      //https://joeschmoe.io/api/v1/random
      // profilePic =
      //   "https://images.pexels.com/photos/220429/pexels-photo-220429.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";
    }

    setProfilePic(profilePic);
    getTitle();
  }, [chatDetails]);

  function getTitle() {
    if (chatDetails?.length > 0) {
      if (chatDetails[0].SourceTypeId == 1) {
        setTitle(chatDetails[0].SkillListingCard?.Title);
        setChatType("Received Bids");
      } else if (chatDetails[0].SourceTypeId == 2) {
        setTitle(chatDetails[0].GigCard?.Title);
        setChatType("Placed Bids");
      }
    }
  }

  const getProfileImage = () => {
    if (chatDetails[0]?.SourceTypeId === 2) {
      if (
        chatDetails[0]?.RecipientUser?.Profiles?.PictureUrl.charAt(0) === "/"
      ) {
        return `${process.env.REACT_APP_S3_URL}${chatDetails[0]?.RecipientUser?.Profiles?.PictureUrl}`;
      } else {
        return `${process.env.REACT_APP_S3_URL}/${chatDetails[0]?.RecipientUser?.Profiles?.PictureUrl}`;
      }
    } else {
      if (chatDetails[0]?.SenderUser?.Profiles?.PictureUrl.charAt(0) === "/") {
        return `${process.env.REACT_APP_S3_URL}${chatDetails[0]?.SenderUser?.Profiles?.PictureUrl}`;
      } else {
        return `${process.env.REACT_APP_S3_URL}/${chatDetails[0]?.SenderUser?.Profiles?.PictureUrl}`;
      }
      // return `${process.env.REACT_APP_S3_URL}${chatDetails[0]?.SenderUser?.Profiles?.PictureUrl}`
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  console.log('ChatDetails chat header>>',facilityName,Rating,Reviews)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        className="chatHeaderCont"
        aria-details={!isEmailVerified && "extra-top"}
      >
        <Row className="chatHeaderFlex">
          <Row style={{ alignItems: "center", width: "73%" }}>
            <Col>
              <ArrowLeftOutlined
                style={{ fontSize: "14px" }}
                onClick={() => {
                  if(windowDimensions.width < 768) {
                    history.goBack()
                  }
                  else { 
                    setshowHistory(false)
                    history.replace(history.location.openConvo, null)
                   }
                }}
              />
            </Col>
            <Col style={{ marginLeft: "3%" }}>
              <div className="chat-card-image">
                {(chatDetails &&
                chatDetails?.length > 0 &&
                chatDetails[0].SourceTypeId &&
                chatDetails[0].SourceTypeId == 2
                  ? chatDetails[0]?.RecipientUser?.Profiles?.PictureUrl
                  : chatDetails[0]?.SenderUser?.Profiles?.PictureUrl) &&
                !imageError ? (
                  <img
                    onError={() => setImageError(true)}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                    src={getProfileImage()}
                    alt="title"
                  />
                ) : (
                  <div className="image-container">
                    <div className="image-text">
                      {chatDetails &&
                      chatDetails?.length > 0 &&
                      chatDetails[0]?.SourceTypeId == 2
                        ? chatDetails[0]?.RecipientUser?.Profiles?.FirstName
                          ? chatDetails[0]?.RecipientUser?.Profiles?.FirstName?.split(
                              ""
                            )[0].toUpperCase()
                          : chatDetails[0]?.RecipientUser?.UserName?.split(
                              ""
                            )[0].toUpperCase()
                        : chatDetails[0]?.SenderUser?.Profiles?.FirstName
                        ? chatDetails[0]?.SenderUser?.Profiles?.FirstName?.split(
                            ""
                          )[0].toUpperCase()
                        : chatDetails[0]?.SenderUser?.UserName?.split(
                            ""
                          )[0].toUpperCase()}
                    </div>
                  </div>
                )}
              </div>
            </Col>

            <Col style={{ marginLeft: "3%", width: windowDimensions.width < 300 ? null : "70%" }}>
              <div>
                <div className="nameTitle">
                  <div>
                    {chatDetails && chatDetails[0]?.SourceTypeId == 2
                      ? chatDetails[0]?.RecipientUser?.Profiles?.FirstName
                        ? chatDetails[0]?.RecipientUser?.Profiles?.FirstName +
                          " " +
                          chatDetails[0]?.RecipientUser?.Profiles?.LastName
                        : "User Name"
                      : chatDetails[0]?.SenderUser?.Profiles?.FirstName
                      ? chatDetails[0]?.SenderUser?.Profiles?.FirstName +
                        " " +
                        chatDetails[0]?.SenderUser?.Profiles?.LastName
                      : "User Name"}
                  </div>
                </div>
                {userProfile?.profile?.ProfessionCode == "HC" ? (
                  <div>
                    {facilityName
                      ? // && chatDetails[0]?.SourceTypeId ==  2
                        facilityName
                      : "Facility Name"}{" "}
                    <span>
                      <BsStarFill
                        // className={
                        // "g-sgigs-starJob " +
                        //   (StatusJob === "Cancelled" || StatusJob === "Terminated"
                        //   ? "job-details-color-black-white"
                        //   : "")
                        //     }
                        style={{ color: "#ffba08" }}
                      />{" "}
                      <span style={{ color: "#ffba08" }}>
                        {Rating? Rating
                          : "3.5"}
                      </span>{" "}
                      <span style={{ color: "#7e7e7e" }}>
                        {Reviews ?? 0}
                      </span>
                    </span>
                  </div>
                ) : null}

                <div className="chat-header-MsgType">{chatType}</div>
              </div>
            </Col>
          </Row>
          <div style={{ color: "#7e7e7e", fontSize: windowDimensions.width < 300 ? "8px" : "10px" }}>
            {moment(createdTime).fromNow()}
          </div>
        </Row>
        <div
          className="chat-header-bottom"
          onClick={() => {
            history.push({
              pathname: `/details/${jobDetails?.JobOrderId}`,
            });
          }}
        >
          <div className="job-title-text">
            {jobDetails?.Title ?? "Gig Title"}
          </div>
        </div>
      </div>
    </>
  );
};
export default ChatHeader;
