import React, { useEffect, useState } from "react";
import { Row, Menu, Dropdown, Button, Col, Spin, Pagination } from "antd";
import "./MyPlacedBids.css";
import PlacedBidItem from "./PlacedBidItem/PlacedBidItem";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
// actions
import {
  createBidReset,
  fetchProposals,
  ResetBidData,
  retractBidReset,
  updateBidReset,
} from "../../../../redux/actions";

// Shared components
import HomeCategoryCard from "../HomeCategoryCard/HomeCategoryCard";

const bidsTypes = [
  {
    name: "All Bids",
    key: "All",
  },
  {
    name: "Pending Bids",
    key: "Pending",
  },
  {
    name: "Accepted Bids",
    key: "Accepted",
  },
  {
    name: "Declined Bids",
    key: "Declined",
  },
  {
    name: "Interview Pending Bids",
    key: "InterviewPending",
  },
  {
    name: "Interview Scheduled Bids",
    key: "InterviewScheduled",
  },
  {
    name: "Retracted Bids",
    key: "Retracted",
  },
];

const pageSize = 12;

const MyPlacedBids = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedBidType, setSelectedBidType] = useState("Pending Bids");
  const [selectedBidStatus, setSelectedBidStatus] = useState("Pending");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const proposals = useSelector((state) => state.proposalsReducer.bids);
  const loading = useSelector((state) => state.proposalsReducer.loading);
  const error = useSelector((state) => state.proposalsReducer.error);

  useEffect(() => {
    searchBids("Pending", 0);
  }, []);

  useEffect(() => {
    // console.log(proposals);
    setCount(proposals.length);
  }, [proposals]);

  const searchBids = (bidStatus, skip) => {
    dispatch(
      fetchProposals("61264e8d5ec66685869a8462", pageSize, skip, bidStatus)
    );
  };

  const onDropDownItemCLick = (bidType, key) => {
    setSelectedBidType(bidType);
    setSelectedBidStatus(key);
    setCurrentPage(1);
    searchBids(key, 0);
  };

  const onChangePage = (page) => {
    // // console.log(selectedBidType+' '+ page * (pageSize - 1))
    searchBids(selectedBidStatus, page * (pageSize - 1));
    setCurrentPage(page);
  };

  const onEditPress = (id) => {
    dispatch(retractBidReset());
    dispatch(createBidReset());
    dispatch(updateBidReset());
    dispatch(ResetBidData());
    history.push({
      pathname: `/edit-bid/${id}`,
      state: {
        id,
      },
    });
  };

  const menu = (
    <Menu>
      {bidsTypes.map((item) => (
        <Menu.Item
          key={item.key}
          className={
            item.name === selectedBidType ? "placed-bid-dropdown-item" : ""
          }
          onClick={() => onDropDownItemCLick(item.name, item.key)}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <HomeCategoryCard
        count={count}
        title={"My placed bids"}
        elementBottom={
          proposals.length ? (
            <Col span={24} className={"paginationBar"}>
              <Pagination
                defaultCurrent={1}
                current={currentPage}
                total={proposals[0].TotalRecords}
                defaultPageSize={pageSize}
                onChange={(page) => onChangePage(page)}
              />
            </Col>
          ) : null
        }
        elementRight={
          <Dropdown overlay={menu} className={"placed-bid-dropdown"}>
            <Button>
              {selectedBidType} <DownOutlined />
            </Button>
          </Dropdown>
        }
      >
        <Row>
          {loading ? (
            <div className={"bids-spin"}>
              <Spin />
            </div>
          ) : (
            proposals.map((item) => (
              <PlacedBidItem
                onEdit={() => onEditPress(item._id)}
                {...item}
                id={item._id}
              />
            ))
          )}
        </Row>
      </HomeCategoryCard>
    </>
  );
};

export default MyPlacedBids;
