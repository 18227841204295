import React, { useEffect, useState } from "react";
import "./Home.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import folderIcon from "../../assets/icons/folderCredIcon.svg";
import {
  fetchHomeDataInit,
  fetchHomeDataSuccess,
  fetchReceivedBidsStages,
  getFavoritesInfo,
  getTalentInfo,
  postFavoriteGigCard,
} from "../../redux/actions";
import Caraousel from "../DashBoard/components/Carousel";
import Carousel from "./Carousel";
import { Row, Col, Card, Divider } from "antd";
import GigsCard from "../SearchGigs/GigsCard";
import { RightOutlined } from "@ant-design/icons";
import Loader from "../../components/Loader/Loader";
import WS from "../../webSocket/defaultSocket";
import ToolTips from "../../components/ToolTip/ToolTips";
import { alertNotification } from "../../components/notifications/alertNotification";

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  const username = useSelector((state) => state.profileReducer.user);
  const ProfessionCode = useSelector((state) => state.profileReducer?.user?.profile?.ProfessionCode)

  const profileData = useSelector((state) => state.profileReducer.talentInfo);
  const homedata = useSelector((state) => state.homepageReducer.HomePageData);
  const homedatafav = useSelector((state) => state.homepageReducer.HomeFav);
  // const homedata = useSelector((state) => state.homepageReducer.loading);
  // console.log(homedata, "homedatafav");

  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [continueBrowsing_perPage, setcontinueBrowsing_perPage] = useState(10);
  const [Favorite_perPage, setFavorite_perPage] = useState(10);
  const [Suggested_perPage, setSuggested_perPage] = useState(10);
  const [Editor_perPage, setEditor_perPage] = useState(20);
  const [Most_Popular, setMost_Popular] = useState(10);

  const [continueBrowsing_perPageMob, setcontinueBrowsing_perPageMob] =
    useState(6);
  const [Favorite_perPageMob, setFavorite_perPageMob] = useState(6);
  const [Suggested_perPageMob, setSuggested_perPageMob] = useState(6);
  const [Editor_perPageMob, setEditor_perPageMob] = useState(20);
  const [Most_PopularMob, setMost_PopularMob] = useState(6);

  const [loading, setloading] = useState(true);

  // console.log("homedata", homedata);

  const guestFavorite = JSON.parse(localStorage.getItem("Favorite"));
  useEffect(() => {
    if (localStorage.getItem("Favorite") && authToken) {
      alertNotification("success", "gig added to favorites");
      dispatch(
        postFavoriteGigCard(
          guestFavorite?.JobOrderId,
          "Gig",
          guestFavorite?.gig
        )
      );
      localStorage.removeItem("Favorite");
    }
  }, []);

  useEffect(() => {
    authToken && dispatch(getTalentInfo(authToken));
    authToken && dispatch(fetchReceivedBidsStages());
    authToken && dispatch(getFavoritesInfo(1, 20));
    if(authToken) {
      dimensions > 720
        ? dispatch(
          fetchHomeDataInit(
            continueBrowsing_perPage,
            Favorite_perPage,
            Suggested_perPage,
            Editor_perPage,
            Most_Popular,
            "EditorPicks",
            "Suggested",
            "Continue Browsing",
            "Favorites",
            "Most Popular"
          )
        )
        : dispatch(
          fetchHomeDataInit(
            continueBrowsing_perPageMob,
            Favorite_perPageMob,
            Suggested_perPageMob,
            Editor_perPage,
            Most_PopularMob,
            "EditorPicks",
            "Suggested",
            "Continue Browsing",
            "Favorites",
            "Most Popular",
            "Most_PopularMob"
          )
        );
    }
  }, []);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);
  const receivedBids = useSelector(
    (state) => state.BidsReducer?.placeBidData?.body?.data
  );
  const myfavorites = useSelector(
    (state) => state.myFavoritesReducer.myFavorites
  );
  const Loading = useSelector((state) => state.myFavoritesReducer.Loading);
  const SeeAllfunctionCB = () => {
    history.push({
      pathname: "/homeSeeAll",
      state: {
        ContinueBrowsing: "ContinueBrowsing",
      },
    });
  };

  const SeeAllfunctionSFY = () => {
    history.push({
      pathname: "/homeSeeAll",
      state: {
        Suggested: "Suggested",
      },
    });
  };

  const SeeAllfunctionEP = () => {
    history.push({
      pathname: "/homeSeeAll",
      state: {
        EditorPicks: "EditorPicks",
      },
    });
  };

  const SeeAllfunctionMP = () => {
    history.push({
      pathname: "/homeSeeAll",
      state: {
        MostPopular: "MostPopular",
      },
    });
  };

  return (
    <>
    <div className="skillgigsHome">
        {authToken && ProfessionCode === "HC" && (
        <section className="completeCredentialingHome">
          <div className="iconAndCount">
            <div>
              <img src={folderIcon} alt="folderIcon" />
            </div>
            <div className="countCred">
                <span className="cred"><span>Complete credentialing</span> <span><ToolTips placement={window.innerWidth > 768 ? "bottomRight" : "bottom"} title="Go to “My Credentials” to complete your credentialing by uploading the necessary documents. Uploading your credentials assures employers of your expertise." /></span></span>
              <span className="count">{`(${
                profileData.user_doc_list > 0
                  ? String(profileData.user_doc_list).padStart(2, 0)
                  : "00"
              }/${
                profileData.total_doc_list > 0
                  ? String(profileData.total_doc_list).padStart(2, 0)
                  : "00"
              })`}</span>
            </div>
          </div>
          <div
            className="completeCred"
            onClick={() => history.push("/credentialing")}
          >
            Complete
          </div>
        </section>
      )}
    </div>
      {authToken ? (
        <div className="HomePage-main-cantainer">
          <div className="HomePage-grid-section">
            <div className="HomePage-Candidate-name">
              <span className="HomePage-Candidate-span">
                Hi{" "}
                {username?.profile?.FirstName?.charAt(0)?.toUpperCase() +
                  username?.profile?.FirstName?.slice(1) +
                  " " +
                  username?.profile?.LastName?.charAt(0)?.toUpperCase() +
                  username?.profile?.LastName?.slice(1)}
                ,
              </span>
              <span>Create a skill listing and start getting </span>
              <span>bids from employers</span>
              <div className="HomePage-add-skill-button">
                <div
                  class="HomePage-Create-a-Skill-Listing3"
                  onClick={() => {
                    history.push("/create-skilllisting");
                  }}
                >
                  Create a skill listing
                </div>
              </div>
            </div>
            <div className="HomePage-Caraousel">
              <Caraousel dotPosition="bottom" dots="true" />
            </div>
          </div>
          {Loading ? (
            <Loader />
          ) : (
            <div>
              {" "}
              <div className="HomePage-MostPopularJobs-MainCantainer">
                {homedata?.continueBrowsingTotalResult > 0 ? (
                  <>
                    <div className="HomePage-MostPopularJobs-Cantainer">
                      <span className="HomePage-MostPopularJobs-text-Mobile">
                        Continue browsing
                      </span>
                      <span className="HomePage-MostPopularJobs-text">
                        Continue browsing
                      </span>
                      {homedata?.body?.ContinueBrowsing?.length > 3 && (
                        <span
                          className="HomePage-SeeAllJobs"
                          onClick={SeeAllfunctionCB}
                        >
                          See all gigs{" "}
                          <span className="HomePage-arrow-sign">
                            {" "}
                            <RightOutlined />
                          </span>{" "}
                        </span>
                      )}
                    </div>
                    <div className="HomePage-Carousel-section">
                      <Carousel show={3}>
                        {homedata?.body?.ContinueBrowsing?.map((data, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="HomePage-Carousel-Card">
                                <GigsCard
                                  data={data}
                                  Types="ContinueBrowsing"
                                />
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </Carousel>
                    </div>
                    {dimensions < 720 ? (
                      <div className="HomePage-Carousel-section-Mobile gql-receivedGigs">
                        {homedata?.body?.ContinueBrowsing?.map((data, index) => {
                          return (
                            <React.Fragment key={index}>
                              <GigsCard data={data} Types="ContinueBrowsing" />
                            </React.Fragment>
                          );
                        })}
                        <div
                          className="HomePage-SeeAll-Mobile"
                          onClick={SeeAllfunctionCB}
                        >
                          <span
                            className="HomePage-SeeAll-Mobile-text"
                            // onClick={SeeAllfunctionCB}
                          >
                            See All
                          </span>
                          <span className="HomePage-SeeAll-Mobile-arror">
                            {" "}
                            <RightOutlined />{" "}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                {homedata?.continueBrowsingTotalResult > 0 ? (
                  <Divider className="HomePage-Divider" />
                ) : null}
                {homedata?.mostPopularTotalResult > 0 ? (
                  <>
                    <div className="HomePage-MostPopularJobs-Cantainer">
                      <span className="HomePage-MostPopularJobs-text-Mobile">
                        {" "}
                        Most popular gigs
                      </span>
                      <span className="HomePage-MostPopularJobs-text">
                        {" "}
                        Most popular gigs
                      </span>
                      {homedata?.body?.MostPopular?.length > 3 && (
                        <span
                          className="HomePage-SeeAllJobs"
                          onClick={SeeAllfunctionMP}
                        >
                          See all gigs{" "}
                          <span className="HomePage-arrow-sign">
                            {" "}
                            <RightOutlined />
                          </span>{" "}
                        </span>
                      )}
                    </div>
                    <div className="HomePage-Carousel-section">
                      <Carousel show={3}>
                        {homedata?.body?.MostPopular?.map((data, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="HomePage-Carousel-Card">
                                <GigsCard data={data} Types="MostPopular" />
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </Carousel>
                    </div>
                    {dimensions < 720 ? (
                      <div className="HomePage-Carousel-section-Mobile gql-receivedGigs">
                        {homedata?.body?.MostPopular?.map((data, index) => {
                          return (
                            <React.Fragment key={index}>
                              <GigsCard data={data} Types="MostPopular" />
                            </React.Fragment>
                          );
                        })}
                        <div
                          className="HomePage-SeeAll-Mobile"
                          onClick={SeeAllfunctionMP}
                        >
                          <span
                            className="HomePage-SeeAll-Mobile-text"
                            // onClick={SeeAllfunctionMP}
                          >
                            See all
                          </span>
                          <span className="HomePage-SeeAll-Mobile-arror">
                            {" "}
                            <RightOutlined />{" "}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                {/* <Divider className="HomePage-Divider" /> */}
                {/* <div className="HomePage-MostPopularJobs-Cantainer" >
            <span className="HomePage-MostPopularJobs-text-Mobile">Most Popular Jobs in <span>Role</span> </span>
            <span className="HomePage-MostPopularJobs-text">Most Popular Jobs in <span>Role</span></span>
            <span className="HomePage-SeeAllJobs">See all Jobs</span>
          </div>
          <div className="HomePage-Carousel-section" >
            <Carousel show={3}>
              {
                myfavorites.map((data) => {
                  return (
                    <>
                      <div className="HomePage-Carousel-Card" >
                        <GigsCard data={data?.gig} />
                      </div>
                    </>
                  )
                })
              }
            </Carousel>
          </div>
          {dimensions < 720 ? <div className="HomePage-Carousel-section-Mobile gql-receivedGigs">
            {
              myfavorites.map((data) => {
                return (
                  <>
                    <GigsCard data={data?.gig} />
                  </>
                )
              })
            }
            <div className="HomePage-SeeAll-Mobile" >
              <span className="HomePage-SeeAll-Mobile-text" >See All</span>
              <span className="HomePage-SeeAll-Mobile-arror" > <RightOutlined /> </span>
            </div>
          </div> : null} */}
                {homedata?.mostPopularTotalResult > 0 ? (
                  <Divider className="HomePage-Divider" />
                ) : null}
              </div>
              {homedata?.suggestedTotalResult > 0 ? (
                <div className="HomePage-JobsMayLike">
                  <div className="HomePage-MostPopularJobs-Cantainer">
                    <span className="HomePage-MostPopularJobs-text">
                      Suggested gigs
                    </span>
                    <span className="HomePage-MostPopularJobs-text-Mobile">
                      Suggested gigs
                    </span>
                    <span className="HomePage-SeeAllJobs"></span>
                  </div>
                  <div className="gql-receivedGigs">
                    {homedata?.body?.Suggested?.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <GigsCard data={data} Types="Suggested" />
                        </React.Fragment>
                      );
                    })}
                    <div
                      className="HomePage-SeeAll-Mobile"
                      onClick={SeeAllfunctionSFY}
                    >
                      <span
                        className="HomePage-SeeAll-Mobile-text"
                        // onClick={SeeAllfunctionSFY}
                      >
                        See all
                      </span>
                      <span className="HomePage-SeeAll-Mobile-arror">
                        {" "}
                        <RightOutlined />{" "}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
              {homedata?.suggestedTotalResult > 0 ? (
                <Divider className="HomePage-Divider" />
              ) : null}
              {myfavorites.length > 0 ? (
                <div className="HomePage-JobsMayLike">
                  <div className="HomePage-MostPopularJobs-Cantainer">
                    <span className="HomePage-MostPopularJobs-text-Mobile">
                      Recent favorite gigs
                    </span>
                    <span className="HomePage-MostPopularJobs-text">
                      Recent favorite gigs
                    </span>
                    {myfavorites?.length > 3 && (
                      <span
                        className="HomePage-SeeAllJobs"
                        onClick={() => history.push("/myfavorites")}
                      >
                        See all gigs{" "}
                        <span className="HomePage-arrow-sign">
                          <RightOutlined />
                        </span>{" "}
                      </span>
                    )}
                  </div>
                  <div className="HomePage-Carousel-section">
                    <Carousel show={3}>
                      {myfavorites?.map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="HomePage-Carousel-Card">
                              <GigsCard data={data?.gig} Types="Favorites" />
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </Carousel>
                  </div>
                  {dimensions < 720 ? (
                    <div className="HomePage-Carousel-section-Mobile gql-receivedGigs">
                      {myfavorites?.map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            <GigsCard data={data?.gig} Types="Favorites" />
                          </React.Fragment>
                        );
                      })}
                      <div
                        className="HomePage-SeeAll-Mobile"
                        onClick={() => history.push("/myfavorites")}
                      >
                        <span
                          className="HomePage-SeeAll-Mobile-text"
                          // onClick={() => history.push("/myfavorites")}
                        >
                          See all
                        </span>
                        <span className="HomePage-SeeAll-Mobile-arror">
                          {" "}
                          <RightOutlined />{" "}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {homedata?.favoritesTotalResult > 0 ? (
                <Divider className="HomePage-Divider" />
              ) : null}
              {homedata?.editorTotalResult > 0 ? (
                <div className="HomePage-EditorPicks">
                  <div className="HomePage-MostPopularJobs-Cantainer">
                    <span className="HomePage-MostPopularJobs-text-Mobile">
                        <span className="Homepage-editer-mobile" >Editor’s picks</span>
                        <span>
                          <ToolTips
                            placement="bottomLeft"
                            title="Check out our team’s top gig choices. We think you might find these gigs interesting too."
                          />
                        </span>
                    </span>
                    <span className="HomePage-MostPopularJobs-text">
                      Editor’s picks 
                      <span>
                        <ToolTips
                            placement="bottomLeft"
                            title="Check out our team’s top gig choices. We think you might find these gigs interesting too."
                        />
                      </span>
                    </span>
                    <span className="HomePage-SeeAllJobs"></span>
                  </div>
                  <div className="gql-receivedGigs">
                    {homedata?.body?.EditorPicks?.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <GigsCard data={data} Types="EditorPicks" />
                        </React.Fragment>
                      );
                    })}
                    <div className="HomePage-SeeAll-Mobile">
                      <span
                        className="HomePage-SeeAll-Mobile-text"
                        onClick={SeeAllfunctionEP}
                      >
                        See all
                      </span>
                      <span className="HomePage-SeeAll-Mobile-arror">
                        {" "}
                        <RightOutlined />{" "}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default Home;
