import React, { useState, useEffect } from "react";
import "./Credentialing2.css";

import Credentials from "./components/StepTwo";

const Credentialing2 = () => {
  const [docFileView, setDocFileView] = useState(false);

  return (
    <div className={`credentialing-user-documents`}>
      <Credentials docFileView={docFileView} setDocFileView={setDocFileView} />
    </div>
  );
};

export default Credentialing2;
