import { Pagination } from "antd";
import React from "react";
import Loader from "../../components/Loader/Loader";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchViewListing } from "../../redux/actions";
import NoViewCard from "./components/NoViewCard";
import ViewCard from "./components/ViewCard";
import "./ViewListing.css";
import { useParams } from "react-router-dom";

const ViewListing = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    loading,
    success: { viewers: viewListing, count },
  } = useSelector((state) => state.viewListingReducer) || [];
  useEffect(() => dispatch(fetchViewListing(id)), []);
  return (
    <>
      <div className="viewListingConatainer">
        <div className="viewListingInfo">
          <div className="viewListingTitle">Skill Listing Views</div>
          {!loading && (
            <>
              {count ? (
                <div className="viewListing90Days">Views on Last 90 days</div>
              ) : null}
              <div className="viewListingCount">
                {count ? count : 0} item{count > 1 ? "s" : ""}
              </div>
            </>
          )}
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {count && viewListing?.length ? (
              <>
                <div className="viewListingBox">
                  {viewListing?.map((data, i) => (
                    <ViewCard data={data} key={i} />
                  ))}
                </div>
                {viewListing?.length > (window.innerWidth > 768 ? 20 : 15) && (
                  <div className="viewListingPagination">
                    <Pagination />
                  </div>
                )}
              </>
            ) : (
              <>
                <NoViewCard />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ViewListing;
