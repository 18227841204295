import React from "react";
import "./ManageBidsMenu.css";

import { useHistory } from "react-router-dom";
import { Menu } from "antd";

const ManageBidsMenu = (props) => {
  const history = useHistory();
  return (
    <Menu>
      <section
        className="header-manage-bids-menu"
        // onClick={() => props.setDropVisibleBids(false)}
      >
        <div
          className="header-recieved-bids-text"
          onClick={() => { window.innerWidth > 1000 && props.setDropVisibleBids(false) ; history.push("/receivedBids")}}
        >
          <span>Received bids</span>
          <span className="bids-count-header">3</span>
        </div>
        <div
          className="placed-bids-header"
          onClick={() => { window.innerWidth > 1000 && props.setDropVisibleBids(false) ; history.push("/placebids")}}
        >
          Placed bids
        </div>
        {/* <div onClick={() => history.push("/retractedBids")}>Retracted Bids</div> */}
      </section>
    </Menu>
  );
};

export default ManageBidsMenu;
