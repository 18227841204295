import React from "react";
import photoCamera from "../assets/icons/photoCamera.svg";
import "./ProfileAvatar.css";
import { useUpdateLogger } from "../shared/useUpdateLogger";
import { Badge } from "antd";
import {useLocation} from "react-router"

const ProfileAvatar = (props) => {
  // console.log("props.profilePic", props);
  const location = useLocation();
  const show = location.pathname.includes("dashboard");
  console.log("useLocation", location, show);
  return (
    // <Badge
    //   // size="default"
    //   dot={true}
    //   color="#5abb7a"
    //   offset={[10, 22]}
    //   onClick={() => history.push("/notifications")}
    // >
      <div className="avatar-container" onClick={props.onClick}>
        <span
          className="avatar-badge"
          style={{ display: `${!props.showBadge && "none"}` }}
        ></span>
        {props.profilePic ? (
          <img
            src={`${process.env.REACT_APP_S3_URL}${
              props?.profilePic?.charAt(0) !== "/" ? "/" : ""
            }${props.profilePic}`}
            // className="avatar-img"
            className={`avatar-img ${
              props.userImage === "userImage" && "avatar-img2"
            } ${props.userImages === "userImages" && "avatar-img4"} ${
              props.headerImage === "headerImage" && "avatar-img5"
            }`}
          />
        ) : (
          <div
            className="resume-image-avatar"
            style={{ fontSize: props.fontSize }}
          >
            <div className="overlay">
              <img src={photoCamera} className="avatar-img" />
            </div>
            <div class="textt">{props.name?.charAt(0).toUpperCase()}</div>
          </div>
        )}
      </div>
    // {/* </Badge> */}
  );
};

export default ProfileAvatar;
