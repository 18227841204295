import React, { useState, useEffect } from 'react';
import { Input, Button, Col, Alert, DatePicker, Row, Spin, Modal,Form } from "antd";
import { CalendarOutlined, ClockCircleOutlined,CheckCircleFilled } from "@ant-design/icons";
import './TimeCorrection.css';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { createTimeSheetCorrection } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import CalendarIcon from "../../../assets/icons/CalendarIcon.svg";
import ClockIcon from "../../../assets/icons/ClockIcon.svg";
import {FiClock} from "react-icons/fi"

const TimeCorrection = () => {
    const success = useSelector(state => state.createTimeSheetCorrectionReducer.success);
    const loading = useSelector(state => state.createTimeSheetCorrectionReducer.loading);
    const request = useSelector(state => state.createTimeSheetCorrectionReducer.request);
    const location = useLocation();
    const dispatch = useDispatch();
    const [date, setdate] = useState(location.state.date)
    const [startTime, setstartTime] = useState(location.state.startTime)
    const [endTime, setendTime] = useState(location.state.endTime)
    const [reason, setreason] = useState('')
    const [visible, setVisible] = useState(true);
    const [form] = Form.useForm();
    let initialValues;

    useEffect(() => {
        // console.log("sate: ", location.state)
        initialValues = {
            Date: moment.utc(location.state.date),
            StartTime: moment.utc(location.state.startTime),
            EndTime: moment.utc(location.state.endTime),
        };
        form.setFieldsValue(initialValues);

    }, [])

    const onFinish = (values) => {
        // // console.log('Success:', values);
        submitRequest()
      };
    
    const onFinishFailed = (errorInfo) => {
    // // console.log('Failed:', errorInfo);
    };


    const submitRequest = () => {
        dispatch(createTimeSheetCorrection(
            location.state.id,
            date,
            startTime,
            endTime,
            reason,
            "Pending"
        ))
        if (!loading) {

        }
        setAlertDisplay('block');
        setTimeout(()=>setAlertDisplay('none'),5000)
    }
    const { TextArea } = Input;
    const reasonSelector = e => {
        // console.log('Change:', e.target.value);
        setreason(e.target.value)
    };
    const dateSelector = (e) => {
        setdate(e)
    }
    const startTimeSelector = (e) => {
        setstartTime(e)
        document.getElementById("basic_StartTime").setAttribute("style", 'color : #1e1e1e');
    }
    const endTimeSelector = (e) => {
        setendTime(e);
        document.getElementById("basic_EndTime").setAttribute("style", 'color : #1e1e1e');
    }
    const [alertDisplay,setAlertDisplay]= useState('none');
    const modalStyle={
        display:alertDisplay,
        position:'fixed',
        width:'100%',
        height:'50px',
        left:'0',
        color:'#fff',
        zIndex:'2000',
        top:'0',
        margin: 'auto',
    }
    const closeAlert=()=>{
        setAlertDisplay('none');
    }
    return (
        <div className='time-correction-main-div'>
            {
                loading ?
                    <div className={'bids-spin'}><Spin /></div>
                    :
                    <>
                    {request && visible ?
                                //    <Alert message="Request sent successfully" type="success" showIcon closable afterClose={handleClose} />
                                <div class="modal" style={modalStyle}>
                                    <div class="modal-content">
                                        <div className="close" onClick={closeAlert}>&times;</div>
                                        <Row>
                                        <div className={'alert-msg'}><CheckCircleFilled style={{paddingRight:'5px'}}/>Request sent successfully</div>
                                        </Row>
                                    </div>
                                </div>
                                : ''}
                        <Col xs={24} sm={24} md={20} lg={14} className={'RequestForm-container'}>
                            <h2 className="request-correction-h2">Request correction</h2>
                            <Form
                                name="basic"
                                form={form}
                                // initialValues={initialValues}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                preserve={false}
                            >
                            <div className="form-request-correction" style={{padding:'1.25rem'}}>    
                            <Row>
                                <h4 className="request-correction-h4">Date</h4>
                                <div className="important-star"><b>*</b></div>
                            </Row>
                            <Col xs={24} sm={24} md={12} lg={9} xl={9} >
                                <Row >
                                    {/* <Col className=" Time-icon" span={3}>
                                        <CalendarOutlined style={{ fontSize: '16px', padding: '10px', color: 'gray', }} />
                                    </Col> */}
                                    <Col span={24}>
                                        <Form.Item 
                                            name="Date"
                                            rules={[
                                                {
                                                required: true,
                                                message: 'Please select date!',
                                                },
                                            ]}
                                        >
                                            {/* <DatePicker style={{ width: '90%' }} */}
                                            <DatePicker 
                                                name="Date"
                                                className="datepicker"
                                                dropdownClassName ="request-correction-datepicker"
                                                style={{ width: '100%', borderRadius: '4px',  border: 'solid 1px #d4d4d4' }}
                                                size="large"
                                                format={["DD/MM/YYYY", "DD/MM/YY"]}
                                                defaultValue={moment(location.state.date)}
                                                onChange={dateSelector}
                                                suffixIcon={<img className='datepicker-icon' src={CalendarIcon} alt="Calender" style={{ color: '#9e9e9e' }} />}
                                                disabled
                                            // defaultValue={success.length!==0 ? moment(success.Date,'YYYY/MM/DD'):// console.log("no value")}
                                            //defaultValue={success.length!==0 ? moment("11/1/1111",'') : ''}
                                            //value={"11/1/1111"}
                                            //placeholder="Select an ideal start date.."
                                            // picker={}
                                            // suffixIcon={<CalendarFilled/>}
                                            // dateRender={current => {
                                            //     const style = {};
                                            //     if (current.date() === Date.now()) {
                                            //         style.border = '1px solid #1890FF';
                                            //         style.borderRadius = '50%';
                                            //     }
                                            //     return (
                                            //         <div className="ant-picker-cell-inner" style={style}>
                                            //             {current.date()}
                                            //         </div>
                                            //     );
                                            // }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Row className={""}>
                                <Col xs={12} sm={12} md={12} lg={9}>
                                    <Row>
                                        <h4 className="request-correction-h4">Start time</h4>
                                        <div className="important-star"><b>*</b></div> 
                                    </Row>
                                    <Row>
                                        {/* <Col className="Time-icon" xs={6} sm={6} md={4} lg={3}>
                                            <ClockCircleOutlined style={{ fontSize: '16px', padding: '10px', color: 'gray' }} />
                                        </Col> */}
                                       <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item 
                                                name="StartTime"
                                                rules={[
                                                    {
                                                    required: true,
                                                    message: 'Please select time!',
                                                    },
                                                ]}
                                            >
                                            <DatePicker 
                                                name="StartTime"
                                                className="datepicker"
                                                dropdownClassName ="request-correction-datepicker"
                                                style={{ width: '96%', borderRadius: '4px',  border: 'solid 1px #d4d4d4' }}
                                                picker="time" size="large" format={'h.mm A'}
                                                // suffixIcon={false}
                                                // suffixIcon={<img src={ClockIcon} alt="Clock" style={{color:"#9e9e9e"}} />}
                                                suffixIcon={
                                                    <FiClock className='datetimepicker-icon' style={{color:"#9e9e9e"}} />
                                                }
                                                // defaultValue={success.length!==0 ? moment(success.StartTime,'h:mm a'):''}
                                                defaultValue={moment.utc(location.state.startTime)}
                                                onChange={startTimeSelector}
                                            />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={9}>
                                    <Row>
                                        <h4 className="request-correction-h4">End time</h4>
                                        <div className="important-star"><b>*</b></div> </Row>
                                    <Row>
                                        {/* <Col className="Time-icon" xs={6} sm={6} md={4} lg={3}>
                                            <ClockCircleOutlined style={{ fontSize: '16px', padding: '10px', color: 'gray' }} />
                                        </Col> */}
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item 
                                                name="EndTime"
                                                rules={[
                                                    {
                                                    required: true,
                                                    message: 'Please select time!',
                                                    },
                                                ]}
                                            >
                                            <DatePicker 
                                                name="EndTime"
                                                className='datepicker'
                                                dropdownClassName ="request-correction-datepicker"
                                                style={{ width: '100%', borderRadius: '4px',  border: 'solid 1px #d4d4d4' }}
                                                picker="time" size="large" format={'h.mm A'}
                                                // suffixIcon={false}
                                                // suffixIcon={<img src={ClockIcon} alt="Clock"  style={{color:"#9e9e9e"}} />}
                                                // defaultValue={success.length!==0 ? moment(success.EndTime,'h:mm a'):''}
                                                // defaultValue={moment()}
                                                defaultValue={moment.utc(location.state.endTime)}
                                                onChange={endTimeSelector}
                                                suffixIcon={
                                                    <FiClock className='datetimepicker-icon' style={{color:"#9e9e9e"}} />
                                                }
                                            />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Col className={""}>
                                <Row>
                                    <h4 className="request-correction-h4">Reason for correction</h4>
                                  //  {success.length !== 0 ?  console.log("success console", success.Date) : ''}
                                    <div className="important-star"><b>*</b></div>
                                </Row>
                                <Form.Item 
                                    className="correction-reason-conatiner"
                                    name="Reason"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please enter your reason!',
                                        },
                                    ]}
                                > 
                                    <TextArea
                                        name="Reason"
                                        className="reason"
                                        style={{color:'#7e7e7e'}}
                                        showCount maxLength={150} onChange={reasonSelector} allowClear={true}
                                        rows={5} placeholder="Please add your reason"
                                        value={reason}
                                    // autoSize={true}
                                    />
                                </Form.Item>
                            </Col>
                            </div>
                            <div style={{padding:'1.25rem 1.875rem',borderTop:'.06rem solid #e4e4e4'}}>
                            <Col style={{ display: 'flex', justifyContent: 'end', color:"#e4e4e4" }}>
                                <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                <Form.Item>
                                    <Button size="large" style={{fontWeight: 600, marginTop:"1rem"}} className={'submit-btn'} htmlType="submit">Send Request</Button>
                                </Form.Item>
                                </Col>
                            </Col>
                            </div>
                            </Form>
                        </Col>
                    </>
            }
        </div>
    );
}
export default TimeCorrection;