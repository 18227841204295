export const types = {
  SIGNUP_LOGIN_UPDATE: "SIGNUP_LOGIN_UPDATE",
  EMAIL_FLOW_UPDATE: "EMAIL_FLOW_UPDATE",
  IS_MODAL_VISIBLE: "IS_MODAL_VISIBLE",
  IS_MODAL_VISIBLE_RESETPASSWORD: "IS_MODAL_VISIBLE_RESETPASSWORD",
  SEND_REQUEST: "SEND_REQUEST",
  SEND_REQUEST_INIT: "SEND_REQUEST_INIT",
  SEND_REQUEST_SUCCESS: "SEND_REQUEST_SUCCESS",
  SEND_REQUEST_FAILURE: "SEND_REQUEST_FAILURE",
  UPDATE_USER_INFO_SUCCESS: "UPDATE_USER_INFO_SUCCESS",
  UPDATE_USER_INFO_INIT: "UPDATE_USER_INFO_INIT",
  UPLOAD_DOCUMENT_INIT: "UPLOAD_DOCUMENT_INIT",
  UPLOAD_DOCUMENT_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENT_FAILURE: "UPLOAD_DOCUMENT_FAILURE",
  REMOVE_DOCUMENT_INIT: "REMOVE_DOCUMENT_INIT",
  REMOVE_DOCUMENT_SUCCESS: "REMOVE_DOCUMENT_SUCCESS",
  REMOVE_DOCUMENT_FAILURE: "REMOVE_DOCUMENT_FAILURE",
  FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
  FETCH_PROFILE_INIT: "FETCH_PROFILE_INIT",
  FETCH_PROFILE_REQUEST_SUCCESS: "FETCH_PROFILE_REQUEST_SUCCESS",
  FETCH_PROFILE_REQUEST_FAILURE: "FETCH_PROFILE_REQUEST_FAILURE",
  TALENT_PROFILE_API_DATA: "TALENT_PROFILE_API_DATA",
  UPDATE_PROFILE_INIT: "UPDATE_PROFILE_INIT",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  FETCH_ROLE_DISCIPLINE_INIT: "FETCH_ROLE_DISCIPLINE_INIT",
  FETCH_ROLE_DISCIPLINE_SUCCESS: "FETCH_ROLE_DISCIPLINE_SUCCESS",
  UPLOAD_PROFILE_PIC_INIT: "UPLOAD_PROFILE_PIC_INIT",
  UPLOAD_PROFILE_PIC_SUCCESS: "UPLOAD_PROFILE_PIC_SUCCESS",
  UPLOAD_PROFILE_PIC_FAILURE: "UPLOAD_PROFILE_PIC_FAILURE",
  UPLOAD_RESUME_INIT: "UPLOAD_RESUME_INIT",
  UPLOAD_RESUME_SUCCESS: "UPLOAD_RESUME_SUCCESS",
  UPLOAD_RESUME_FAILURE: "UPLOAD_RESUME_FAILURE",
  DELETE_RESUME_INIT: "DELETE_RESUME_INIT",
  DELETE_RESUME_SUCCESS: "DELETE_RESUME_SUCCESS",
  DELETE_RESUME_FAILURE: "DELETE_RESUME_FAILURE",
  LINKEDIN_TOKEN_INIT: "LINKEDIN_TOKEN_INIT",
  LINKEDIN_TOKEN_SUCCESS: "LINKEDIN_TOKEN_SUCCESS",
  LINKEDIN_TOKEN_FAIL: "LINKEDIN_TOKEN_FAIL",
  SEARCH_GIGS_INIT: "SEARCH_GIGS_INIT",
  SEARCH_GIGS_SUCCESS: "SEARCH_GIGS_SUCCESS",
  SEARCH_GIGS_FAILURE: "SEARCH_GIGS_FAILURE",
  SEARCH_VALUE: "SEARCH_VALUE",
  CHECK_EXISTING_USER_INIT: "CHECK_EXISTING_USER_INIT",
  CHECK_EXISTING_USER_SUCCESS: "CHECK_EXISTING_USER_SUCCESS",
  CHECK_EXISTING_USER_FAILURE: "CHECK_EXISTING_USER_FAILURE",
  FETCH_SKILLS_INIT: "FETCH_SKILLS_INIT",
  FETCH_SKILLS_SUCCESS: "FETCH_SKILLS_SUCCESS",
  CREATE_SKILL_LISTING_INIT: "CREATE_SKILL_LISTING_INIT",
  UPDATE_SKILL_LISTING_INIT: "UPDATE_SKILL_LISTING_INIT",
  UPDATE_SKILL_LISTING_SUCCESS: "UPDATE_SKILL_LISTING_SUCCESS",
  UPDATE_SKILL_LISTING_SAVE: "UPDATE_SKILL_LISTING_SAVE",
  DELETE_SKILL_LISTING: "DELETE_SKILL_LISTING",
  FETCH_USER_SKILLISTING_INIT: "FETCH_USER_SKILLISTING_INIT",
  FETCH_USER_SKILLISTING_SUCCESS: "FETCH_USER_SKILLISTING_SUCCESS",
  FETCH_USER_SKILLISTING_SUCCESS_ID: "FETCH_USER_SKILLISTING_SUCCESS_ID",
  // Pagination
  SET_PUBFILTER_SKILLISTING: "SET_PUBFILTER_SKILLISTING",
  SET_PAGE_SKILLISTING: "SET_PAGE_SKILLISTING",
  STEP_ONE: "STEP_ONE",
  SKILLISTING: "SKILLISTING",
  FETCH_LOCATION_SAVE: "FETCH_LOCATION_SAVE",
  FETCH_SEARCH_LOCATION_INIT: "FETCH_SEARCH_LOCATION_INIT",
  FETCH_SEARCH_LOCATION_SUCCESS: "FETCH_SEARCH_LOCATION_SUCCESS",
  FETCH_SEARCH_LOCATION_FAILURE: "FETCH_SEARCH_LOCATION_FAILURE",
  FETCH_SEARCH_LOCATION_SAVE: "FETCH_SEARCH_LOCATION_SAVE",
  FETCH_COUNTRY_SAVE: "FETCH_COUNTRY_SAVE",
  FETCH_SEARCH_COUNTRY_FAILURE: "FETCH_SEARCH_COUNTRY_FAILURE",
  FETCH_SEARCH_COUNTRY_INIT: "FETCH_SEARCH_COUNTRY_INIT",
  FETCH_SEARCH_COUNTRY_SUCCESS: "FETCH_SEARCH_COUNTRY_SUCCESS",
  FETCH_REGION_SAVE: "FETCH_REGION_SAVE",
  FETCH_SEARCH_REGION_FAILURE: "FETCH_SEARCH_REGION_FAILURE",
  FETCH_SEARCH_REGION_INIT: "FETCH_SEARCH_REGION_INIT",
  FETCH_SEARCH_REGION_SUCCESS: "FETCH_SEARCH_REGION_SUCCESS",
  FETCH_CITY_SAVE: "FETCH_CITY_SAVE",
  FETCH_SEARCH_CITY_FAILURE: "FETCH_SEARCH_CITY_FAILURE",
  FETCH_SEARCH_CITY_INIT: "FETCH_SEARCH_CITY_INIT",
  FETCH_SEARCH_CITY_SUCCESS: "FETCH_SEARCH_CITY_SUCCESS",
  FETCH_EXPERINCE_LEVEL_FAILURE: "FETCH_EXPERINCE_LEVEL_FAILURE",
  FETCH_EXPERINCE_LEVEL_INIT: "FETCH_EXPERINCE_LEVEL_INIT",
  FETCH_EXPERINCE_LEVEL_SUCCESS: "FETCH_EXPERINCE_LEVEL_SUCCESS",
  FETCH_SEARCH_DISCIPLINE_INIT: "FETCH_SEARCH_DISCIPLINE_INIT",
  FETCH_SEARCH_DISCIPLINE_SUCCESS: "FETCH_SEARCH_DISCIPLINE_SUCCESS",
  SEARCH_DISCIPLINE_SAVE: "SEARCH_DISCIPLINE_SAVE",
  DISPLAY_TITLE_SAVE: "DISPLAY_TITLE_SAVE",
  SKILLLISTING_FAILURE: "SKILLLISTING_FAILURE",
  FETCH_PROFILE_EXPERIENCE_INIT: "FETCH_PROFILE_EXPERIENCE_INIT",
  FETCH_PROFILE_EXPERIENCE_SUCCESS: "FETCH_PROFILE_EXPERIENCE_SUCCESS",
  FETCH_EXPERIENCE_SAVE: "FETCH_EXPERIENCE_SAVE",
  FETCH_PROPOSALS_REQUESTED: "GET_PROPOSALS_REQUESTED",
  FETCH_PROPOSALS_SUCCESS: "GET_PROPOSALS_SUCCESS",
  FETCH_PROPOSALS_FAILURE: "GET_PROPOSALS_FAILURE",
  CREATE_BID_REQUESTED: "POST_BID_REQUESTED",
  CREATE_BID_SUCCESS: "POST_BID_SUCCESS",
  CREATE_BID_FAILURE: "POST_BID_FAILURE",
  CREATE_BID_RESET: "CREATE_BID_RESET",
  UPDATE_BID_REQUESTED: "UPDATE_BID_REQUESTED",
  UPDATE_BID_SUCCESS: "UPDATE_BID_SUCCESS",
  UPDATE_BID_FAILURE: "UPDATE_BID_FAILURE",

  UPDATE_GIG_REQUESTED: "UPDATE_GIG_REQUESTED",
  UPDATE_GIG_SUCCESS: "UPDATE_GIG_SUCCESS",
  UPDATE_GIG_FAILURE: "UPDATE_GIG_FAILURE",

  UPDATE_BID_RESET: "UPDATE_BID_RESET",
  FETCH_MESSAGES_REQUESTED: "FETCH_MESSAGES_REQUESTED",
  FETCH_MESSAGES_SUCCESS: "FETCH_MESSAGES_SUCCESS",
  FETCH_MESSAGES_FAILURE: "FETCH_MESSAGES_FAILURE",
  FETCH_BIDDATA_REQUESTED: "GET_BIDDATA_REQUESTED",
  FETCH_BIDDATA_SUCCESS: "GET_BIDDATA_SUCCESS",
  FETCH_BIDDATA_FAILURE: "GET_BIDDATA_FAILURE",
  FETCH_BIDDATA_RESET: "GET_BIDDATA_RESET",
  RETRACT_BID_REQUESTED: "RETRACT_BID_REQUESTED",
  RETRACT_BID_SUCCESS: "RETRACT_BID_SUCCESS",
  RETRACT_BID_FAILURE: "RETRACT_BID_FAILURE",
  RETRACT_BID_RESET: "RETRACT_BID_RESET",
  SEND_MESSAGE_REQUESTED: "SEND_MESSAGE_REQUESTED",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",
  SEND_MESSAGE_ATTACHMENT_REQUESTED: "SEND_MESSAGE_ATTACHMENT_REQUESTED",
  FETCH_CHAT_REQUESTED: "GET_CHAT_REQUESTED",
  FETCH_CHAT_SUCCESS: "GET_CHAT_SUCCESS",
  FETCH_CHAT_FAILURE: "GET_CHAT_FAILURE",
  FETCH_RECEIVEDBIDS_STATUS_REQUESTED: "FETCH_RECEIVEDBIDS_STATUS_REQUESTED",
  FETCH_RECEIVEDBIDS_STATUS_SUCCESS: "FETCH_RECEIVEDBIDS_STATUS_SUCCESS",
  FETCH_RECEIVEDBIDS_REQUESTED: "GET_RECEIVED-BIDS_REQUESTED",
  FETCH_RECEIVEDBIDS_SUCCESS: "GET_RECEIVED-BIDS_SUCCESS",
  FETCH_RECEIVEDBIDS_FAILURE: "GET_RECEIVED-BIDS_FAILURE",
  FETCH_UPDATEBIDS_REQUESTED: "GET_UPDATEB-BIDS_REQUESTED",
  FETCH_UPDATEBIDS_SUCCESS: "GET_UPDATE-BIDS_SUCCESS",
  FETCH_UPDATEBIDS_FAILURE: "GET_UPDATE-BIDS_FAILURE",
  UPDATEBIDS_TALENT_REQUESTED: "UPDATEBIDS_TALENT_REQUESTED",
  UPDATEBIDS_TALENT_SUCCESS: "UPDATEBIDS_TALENT_SUCCESS",
  UPDATEBIDS_TALENT_FAILURE: "UPDATEBIDS_TALENT_FAILURE",
  MESSAGE_RECEIVED: "MESSAGE_RECEIVED",
  MODAL_CLOSE_FUNCTION: "Modalclosefunction",
  // meassages
  FETCH_INBOX_DATA_REQUEST: "FETCH_INBOX_DATA_REQUEST",
  FETCH_INBOX_DATA_SUCCESS: "FETCH_INBOX_DATA_SUCCESS",
  // FETCH_CHAT_FILES_REQUESTED:'GET_CHAT_FILES_REQUESTED',
  // FETCH_CHAT_FILES_SUCCESS:'GET_CHAT_FILES_SUCCESS',
  // FETCH_CHAT_FILES_FAILURE:'GET_CHAT_FILES_FAILURE'

  UPDATE_USER_ONLINE_STATUS: "UPDATE_USER_ONLINE_STATUS",
  ADD_USERS_ONLINE_STATUS: "ADD_USERS_ONLINE_STATUS",

  GET_GRAPH_DATA_REQUESTED: "GRAPH_DATA_REQUESTED",
  GET_GRAPH_DATA_SUCCESS: "GRAPH_DATA_SUCCESS",
  GET_GRAPH_DATA_FAILURE: "GRAPH_DATA_FAILURE",
  FETCH_TIMESHEETLIST_REQUESTED: "FETCH_TIMESHEETLIST_REQUESTED",
  FETCH_TIMESHEETLIST_SUCCESS: "FETCH_TIMESHEETLIST_SUCCESS",
  FETCH_TIMESHEETLIST_FAILURE: "FETCH_TIMESHEETLIST_FAILURE",
  CREATE_TIMESHEET_CORRECTION_REQUESTED:
    "CREATE_TIMESHEET_CORRECTION_REQUESTED",
  CREATE_TIMESHEET_CORRECTION_SUCCESS: "CREATE_TIMESHEET_CORRECTION_SUCCESS",
  CREATE_TIMESHEET_CORRECTION_FAILURE: "CREATE_TIMESHEET_CORRECTION_FAILURE",
  CREATE_TIMESHEET_DATA_REQUESTED: "CREATE_TIMESHEET_DATA_REQUESTED",
  CREATE_TIMESHEET_DATA_SUCCESS: "CREATE_TIMESHEET_DATA_SUCCESS",
  CREATE_TIMESHEET_DATA_FAILURE: "CREATE_TIMESHEET_DATA_FAILURE",
  UPDATE_TIMESHEET_DATA_REQUESTED: "UPDATE_TIMESHEET_DATA_REQUESTED",
  UPDATE_TIMESHEET_DATA_SUCCESS: "UPDATE_TIMESHEET_DATA_SUCCESS",
  UPDATE_TIMESHEET_DATA_FAILURE: "UPDATE_TIMESHEET_DATA_FAILURE",
  USERASSIGNMENT_REQUESTED: "USERASSIGNMENT_REQUESTED",
  USERASSIGNMENT_SUCCESS: "USERASSIGNMENT_SUCCESS",
  USERASSIGNMENT_FAILURE: "USERASSIGNMENT_FAILURE",
  GET_EXISTING_LIST_ITEM_REQUESTED: "GET_EXISTING_LIST_ITEM_REQUESTED",
  GET_EXISTING_LIST_ITEM_SUCCESS: "GET_EXISTING_LIST_ITEM_SUCCESS",
  GET_EXISTING_LIST_ITEM_FAILURE: "GET_EXISTING_LIST_ITEM_FAILURE",
  FETCH_SEARCH_SUGGESTIONS_INIT: "FETCH_SEARCH_SUGGESTIONS_INIT",
  FETCH_SEARCH_SUGGESTIONS_SUCCESS: "FETCH_SEARCH_SUGGESTIONS_SUCCESS",
  FETCH_SEARCH_SUGGESTIONS_FAILURE: "FETCH_SEARCH_SUGGESTIONS_FAILURE",
  FETCH_SEARCH_SUGGESTIONS_SAVE: "FETCH_SEARCH_SUGGESTIONS_SAVE",
  SEARCH_FILTER_UPDATE: "SEARCH_FILTER_UPDATE",
  GET_TALENT_INFO_REQUEST: "GET_TALENT_INFO_REQUEST",
  GET_TALENT_INFO_SUCCESS: "GET_TALENT_INFO_SUCCESS",
  GET_TALENT_INFO_FAILURE: "GET_TALENT_INFO_FAILURE",
  GET_ANALYTIC_INFO_REQUEST: "GET_ANALYTIC_INFO_REQUEST",
  GET_ANALYTIC_INFO_SUCCESS: "GET_ANALYTIC_INFO_SUCCESS",
  GET_ANALYTIC_INFO_FAILURE: "GET_ANALYTIC_INFO_FAILURE",
  JOB_DETAILS_PROFILE_REQUESTED: "JOB_DETAILS_PROFILE_REQUESTED",
  JOB_DETAILS_PROFILE_SUCCESS: "JOB_DETAILS_PROFILE_SUCCESS",
  JOB_DETAILS_PROFILE_FAILURE: "JOB_DETAILS_PROFILE_FAILURE",
  JOB_DETAILS_PROFILE_SELECTER: "JOB_DETAILS_PROFILE_SELECTER",
  JOB_DETAILS_PROFILE_COMPLETED_REQUESTED:
    "JOB_DETAILS_PROFILE_COMPLETED_REQUESTED",
  JOB_DETAILS_PROFILE_COMPLETED_SUCCESS:
    "JOB_DETAILS_PROFILE_COMPLETED_SUCCESS",
  JOB_DETAILS_PROFILE_COMPLETED_FAILURE:
    "JOB_DETAILS_PROFILE_COMPLETED_FAILURE",
  JOB_DETAILS_PROFILE_ON_BILLING_REQUESTED:
    "JOB_DETAILS_PROFILE_ON_BILLING_REQUESTED",
  JOB_DETAILS_PROFILE_ON_BILLING_SUCCESS:
    "JOB_DETAILS_PROFILE_ON_BILLING_SUCCESS",
  JOB_DETAILS_PROFILE_ON_BILLING_FAILURE:
    "JOB_DETAILS_PROFILE_ON_BILLING_FAILURE",
  JOB_DETAILS_PROFILE_TERMINATED_REQUESTED:
    "JOB_DETAILS_PROFILE_TERMINATED_REQUESTED",
  JOB_DETAILS_PROFILE_TERMINATED_SUCCESS:
    "JOB_DETAILS_PROFILE_TERMINATED_SUCCESS",
  JOB_DETAILS_PROFILE_TERMINATED_FAILURE:
    "JOB_DETAILS_PROFILE_TERMINATED_FAILURE",
  JOB_DETAILS_PROFILE_CANCELED_REQUESTED:
    "JOB_DETAILS_PROFILE_CANCELED_REQUESTED",
  JOB_DETAILS_PROFILE_CANCELED_SUCCESS: "JOB_DETAILS_PROFILE_CANCELED_SUCCESS",
  JOB_DETAILS_PROFILE_CANCELED_FAILURE: "JOB_DETAILS_PROFILE_CANCELED_FAILURE",
  GET_FAVORITES_INFO_REQUEST: "GET_FAVORITES_INFO_REQUEST",
  GET_FAVORITES_INFO_SUCCESS: "GET_FAVORITES_INFO_SUCCESS",
  GET_FAVORITES_INFO_FAILURE: "GET_FAVORITES_INFO_FAILURE",
  FAVORITE_GIG_CARD: "FAVORITE_GIG_CARD",
  FAVORITE_GIG_CARD_SUCCESS: "FAVORITE_GIG_CARD_SUCCESS",
  FAVORITE_GIG_CARD_FAILURE: "FAVORITE_GIG_CARD_FAILURE",
  FAVORITE_FUNCTION_DETAIL: "FAVORITE_FUNCTION_DETAIL",
  FAVORITE_BID_UPDATE: "FAVORITE_BID_UPDATE",
  REMOVE_FAVORITE_GIG_CARD: "REMOVE_FAVORITE_GIG_CARD",
  REMOVE_FAVORITE_GIG_CARD_SUCCESS: "REMOVE_FAVORITE_GIG_CARD_SUCCESS",
  REMOVE_FAVORITE_GIG_CARD_FAILURE: "REMOVE_FAVORITE_GIG_CARD_FAILURE",
  REMOVE_FAVORITE_GIG_CARD_REDUCER: "REMOVE_FAVORITE_GIG_CARD_REDUCER",
  SET_TIMER_TIMESHEET: "SET_TIMER_TIMESHEET",
  FETCH_COLLEGE_MASTER_INIT: "FETCH_COLLEGE_MASTER_INIT",
  FETCH_COLLEGE_MASTER_SUCCESS: "FETCH_COLLEGE_MASTER_SUCCESS",
  FETCH_COLLEGE_MASTER_FAILURE: "FETCH_COLLEGE_MASTER_FAILURE",
  FETCH_COURSE_MASTER_INIT: "FETCH_COURSE_MASTER_INIT",
  FETCH_COURSE_MASTER_SUCCESS: "FETCH_COURSE_MASTER_SUCCESS",
  FETCH_COURSE_MASTER_FAILURE: "FETCH_COURSE_MASTER_FAILURE",
  FETCH_DEGREE_MASTER_INIT: "FETCH_DEGREE_MASTER_INIT",
  FETCH_DEGREE_MASTER_SUCCESS: "FETCH_DEGREE_MASTER_SUCCESS",
  FETCH_DEGREE_MASTER_FAILURE: "FETCH_DEGREE_MASTER_FAILURE",
  POST_EDUCATION_HISTORY_INIT: "POST_EDUCATION_HISTORY_INIT",
  POST_EDUCATION_HISTORY_SUCCESS: "POST_EDUCATION_HISTORY_SUCCESS",
  POST_EDUCATION_HISTORY_FAILURE: "POST_EDUCATION_HISTORY_FAILURE",
  UPDATE_EDUCATION_HISTORY_INIT: "UPDATE_EDUCATION_HISTORY_INIT",
  UPDATE_EDUCATION_HISTORY_SUCCESS: "UPDATE_EDUCATION_HISTORY_SUCCESS",
  UPDATE_EDUCATION_HISTORY_FAILURE: "UPDATE_EDUCATION_HISTORY_FAILURE",
  // GET_COMPANY_WORK_HISTORY_INIT: "GET_COMPANY_WORK_HISTORY_INIT",
  // GET_COMPANY_WORK_HISTORY_SUCCESS: "GET_COMPANY_WORK_HISTORY_SUCCESS",
  // GET_COMPANY_WORK_HISTORY_FAILURE: "GET_COMPANY_WORK_HISTORY_FAILURE",
  GET_JOBTITLE_WORK_HISTORY_INIT: "GET_JOBTITLE_WORK_HISTORY_INIT",
  GET_JOBTITLE_WORK_HISTORY_SUCCESS: "GET_JOBTITLE_WORK_HISTORY_SUCCESS",
  GET_JOBTITLE_WORK_HISTORY_FAILURE: "GET_JOBTITLE_WORK_HISTORY_FAILURE",
  GET_INDUSTRY_WORK_HISTORY_INIT: "GET_INDUSTRY_WORK_HISTORY_INIT",
  GET_INDUSTRY_WORK_HISTORY_SUCCESS: "GET_INDUSTRY_WORK_HISTORY_SUCCESS",
  GET_INDUSTRY_WORK_HISTORY_FAILURE: "GET_INDUSTRY_WORK_HISTORY_FAILURE",
  GET_LOCATION_WORK_HISTORY_INIT: "GET_LOCATION_WORK_HISTORY_INIT",
  GET_LOCATION_WORK_HISTORY_SUCCESS: "GET_LOCATION_WORK_HISTORY_SUCCESS",
  GET_LOCATION_WORK_HISTORY_FAILURE: "GET_LOCATION_WORK_HISTORY_FAILURE",
  GET_SKILLS_WORK_HISTORY_INIT: "GET_SKILLS_WORK_HISTORY_INIT",
  GET_SKILLS_WORK_HISTORY_SUCCESS: "GET_SKILLS_WORK_HISTORY_SUCCESS",
  GET_SKILLS_WORK_HISTORY_FAILURE: "GET_SKILLS_WORK_HISTORY_FAILURE",
  POST_WORK_HISTORY_INIT: "POST_WORK_HISTORY_INIT",
  POST_WORK_HISTORY_SUCCESS: "POST_WORK_HISTORY_SUCCESS",
  POST_WORK_HISTORY_FAILURE: "POST_WORK_HISTORY_FAILURE",
  UPDATE_WORK_HISTORY_INIT: "UPDATE_WORK_HISTORY_INIT",
  UPDATE_WORK_HISTORY_SUCCESS: "UPDATE_WORK_HISTORY_SUCCESS",
  UPDATE_WORK_HISTORY_FAILURE: "UPDATE_WORK_HISTORY_FAILURE",
  POST_CERTIFICATES_AND_AWARDS_INIT: "POST_CERTIFICATES_AND_AWARDS_INIT",
  POST_CERTIFICATES_AND_AWARDS_SUCCESS: "POST_CERTIFICATES_AND_AWARDS_SUCCESS",
  POST_CERTIFICATES_AND_AWARDS_FAILURE: "POST_CERTIFICATES_AND_AWARDS_FAILURE",
  UPDATE_CERTIFICATES_AND_AWARDS_INIT: "UPDATE_CERTIFICATES_AND_AWARDS_INIT",
  UPDATE_CERTIFICATES_AND_AWARDS_SUCCESS:
    "UPDATE_CERTIFICATES_AND_AWARDS_SUCCESS",
  UPDATE_CERTIFICATES_AND_AWARDS_FAILURE:
    "UPDATE_CERTIFICATES_AND_AWARDS_FAILURE",
  // FETCH_RETRACTEDBIDS_STATUS_REQUESTED: "FETCH_RETRACTEDBIDS_STATUS_REQUESTED",
  // FETCH_RETRACTEDBIDS_STATUS_SUCCESS: "FETCH_RETRACTEDBIDS_STATUS_SUCCESS",
  FETCH_RETRACTEDBIDS_REQUESTED: "GET_RETRACTED-BIDS_REQUESTED",
  FETCH_RETRACTEDBIDS_SUCCESS: "GET_RETRACTED-BIDS_SUCCESS",
  FETCH_RETRACTEDBIDS_FAILURE: "GET_RETRACTED-BIDS_FAILURE",
  USER_LOGOUT: "USER_LOGOUT",
  FETCH_RETRACTED_BID_REQUESTED: "FETCH_RETRACTED_BID_REQUESTED",
  FETCH_RETRACTED_BID_SUCCESS: "FETCH_RETRACTED_BID_SUCCESS",
  FETCH_RETRACTED_BID_FAILURE: "FETCH_RETRACTED_BID_FAILURE",
  RETRACTED_BID_UPDATE: "RETRACTED_BID_UPDATE",
  FETCH_PLACE_BID_REQUESTED: "FETCH_PLACE_BID_REQUESTED",
  FETCH_PLACE_BID_SUCCESS: "FETCH_PLACE_BID_SUCCESS",
  FETCH_PLACE_BID_FAILURE: "FETCH_PLACE_BID_FAILURE",
  FETCH_DETAIL_PLACE_BID_INIT: "FETCH_DETAIL_PLACE_BID_INIT",
  FETCH_DETAIL_PLACE_BID_SUCCESS: "FETCH_DETAIL_PLACE_BID_SUCCESS",
  FETCH_DETAIL_PLACE_BID_FAILURE: "FETCH_DETAIL_PLACE_BID_FAILURE",
  FETCH_DETAIL_RECEIVED_BID_INIT: "FETCH_DETAIL_RECEIVED_BID_INIT",
  FETCH_DETAIL_RECEIVED_BID_SUCCESS: "FETCH_DETAIL_RECEIVED_BID_SUCCESS",
  FETCH_DETAIL_RECEIVED_BID_FAILURE: "FETCH_DETAIL_RECEIVED_BID_FAILURE",
  GET_INTERVIEW_CARD_REQUEST: "GET_INTERVIEW_CARD_REQUEST",
  GET_INTERVIEW_CARD_SUCCESS: "GET_INTERVIEW_CARD_SUCCESS",
  GET_INTERVIEW_CARD_FAILURE: "GET_INTERVIEW_CARD_FAILURE",

  FETCH_OFFER_REQUESTED: "FETCH_OFFER_REQUESTED",
  FETCH_OFFER_SUCCESS: "FETCH_OFFER_SUCCESS",
  FETCH_OFFER_FAILURE: "FETCH_OFFER_FAILURE",

  ADD_MESSAGE_TO_FAVOURITE_REQUESTED: "ADD_MESSAGE_TO_FAVOURITE_REQUESTED",
  ADD_MESSAGE_TO_FAVOURITE_SUCCESS: "ADD_MESSAGE_TO_FAVOURITE_SUCCESS",
  ADD_MESSAGE_TO_FAVOURITE_FAILURE: "ADD_MESSAGE_TO_FAVOURITE_FAILURE",
  FAVOURITE_SUCCESS: "FAVOURITE_SUCCESS",
  REMOVE_FAV_SUCCESS: "REMOVE_FAV_SUCCESS",

  REMOVE_MESSAGE_TO_FAVOURITE_REQUESTED:
    "REMOVE_MESSAGE_TO_FAVOURITE_REQUESTED",
  REMOVE_MESSAGE_TO_FAVOURITE_SUCCESS: "REMOVE_MESSAGE_TO_FAVOURITE_SUCCESS",
  REMOVE_MESSAGE_TO_FAVOURITE_FAILURE: "REMOVE_MESSAGE_TO_FAVOURITE_FAILURE",

  FETCH_INTERVIEW_INIT: "FETCH_INTERVIEW_INIT",
  FETCH_INTERVIEW_SUCCESS: "FETCH_INTERVIEW_SUCCESS",
  FETCH_INTERVIEW_FAILURE: "FETCH_INTERVIEW_FAILURE",
  FETCH_DECLINED_REASONS_BIDS_INIT: "FETCH_DECLINED_REASONS_BIDS_INIT",
  FETCH_DECLINED_REASONS_BIDS_SUCCESS: "FETCH_DECLINED_REASONS_BIDS_SUCCESS",
  FETCH_DECLINED_REASONS_BIDS_FAILURE: "FETCH_DECLINED_REASONS_BIDS_FAILURE",
  UPDATE_DECLINED_REASONS_BIDS_INIT: "UPDATE_DECLINED_REASONS_BIDS_INIT",
  UPDATE_DECLINED_REASONS_BIDS_SUCCESS: "UPDATE_DECLINED_REASONS_BIDS_SUCCESS",
  UPDATE_DECLINED_REASONS_BIDS_FAILURE: "UPDATE_DECLINED_REASONS_BIDS_FAILURE",
  // 3D RESUME TYPES //
  FETCH_RESUME_GET_FOLLOWERS_INIT: "FETCH_RESUME_GET_FOLLOWERS_INIT",
  FETCH_RESUME_GET_FOLLOWERS_SUCCESS: "FETCG_RESUME_SUCCESS",
  FETCH_RESUME_GET_FOLLOWERS_FAILURE: "FETCH_RESUME_FAILURE",
  FETCH_RESUME_GET_VIEWS_INIT: "FETCH_RESUME_GET_VIEWS_INIT",
  FETCH_RESUME_GET_VIEWS_SUCCESS: "FETCH_RESUME_GET_VIEWS_SUCCESS",
  FETCH_RESUME_GET_VIEWS_FAILURE: "FETCH_RESUME_GET_VIEWS_FAILURE",
  FETCH_RESUME_DETAIL_INIT: "FETCH_RESUME_DETAIL_INIT",
  FETCH_RESUME_DETAIL_SUCCESS: "FETCH_RESUME_DETAIL_SUCCESS",
  FETCH_RESUME_DETAIL_FAILURE: "FETCH_RESUME_DETAIL_FAILURE",
  SHOW_PUBLIC_VIEW: "SHOW_PUBLIC_VIEW",
  // Home Page Type
  FETCH_HOME_PAGE_DATA_INIT: "FETCH_HOME_PAGE_DATA_INIT",
  FETCH_HOME_PAGE_DATA_SUCCESS: "FETCH_HOME_PAGE_DATA_SUCCESS",
  FETCH_HOME_PAGE_DATA_FAILURE: "FETCH_HOME_PAGE_DATA_FAILURE",
  HOME_PAGE_FAVORITE_REMOVE: "HOME_PAGE_FAVORITE_REMOVE",
  HOME_PAGE_FAVORITE: "HOME_PAGE_FAVORITE",
  HOME_PAGE_FAVORITE_UPDATE: "HOME_PAGE_FAVORITE_UPDATE",
  // Notification Types //
  UPDATE_NOTIFICATION_INIT: "UPDATE_NOTIFICATION_INIT",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_FAILURE: "FETCH_NOTIFICATION_FAILURE",

  GET_NOTIFICATION_INIT: "GET_NOTIFICATION_INIT",
  GET_NOTIFICATIN_SUCCESS: "GET_NOTIFICATIN_SUCCESS",
  GET_NOTIFICATIN_FAILURE: "GET_NOTIFICATIN_FAILURE",

  READ_NOTIFICATION: "READ_NOTIFICATION",
  READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
  READ_NOTIFICATION_FAILURE: "READ_NOTIFICATION_FAILURE",
  // Interview Accepted//
  INTERVIEW_ACCEPTED_INIT: "INTERVIEW_ACCEPTED_INIT",
  INTERVIEW_ACCEPTED_SUCCESS: "INTERVIEW_ACCEPTED_SUCCESS",
  INTERVIEW_ACCEPTED_FAILURE: "INTERVIEW_ACCEPTED_FAILURE",

  CLEAR_FILTER_INIT: "CLEAR_FILTER_INIT",

  //Retracted Bid Update
  RETRACTED_BID_UPDATE_STAGES_INIT: "RETRACTED_BID_UPDATE_STAGES_INIT",
  RETRACTED_BID_UPDATE_STAGES_SUCCESS: "RETRACTED_BID_UPDATE_STAGES_SUCCESS",
  RETRACTED_BID_UPDATE_STAGES_FAILURE: "RETRACTED_BID_UPDATE_STAGES_FAILURE",

  SEARCH_SUGGESTION_INPUT: "SEARCH_SUGGESTION_INPUT",
  IS_LOCATION_DISABLE: "IS_LOCATION_DISABLE",

  CREATE_RATING_INIT: "CREATE_RATING_INIT",
  CREATE_RATING_SUCCESS: "CREATE_RATING_SUCCESS",
  CREATE_RATING_FAILURE: "CREATE_RATING_FAILURE",

  UPDATE_RATING_INIT: "UPDATE_RATING_INIT",
  UPDATE_RATING_SUCCESS: "UPDATE_RATING_SUCCESS",
  UPDATE_RATING_FAILURE: "UPDATE_RATING_FAILURE",

  DELETE_RATING_INIT: "DELETE_RATING_INIT",
  DELETE_RATING_SUCCESS: "DELETE_RATING_SUCCESS",
  DELETE_RATING_FAILURE: "DELETE_RATING_FAILURE",
  IS_LOCATION_DISABLE: "IS_LOCATION_DISABLE",
  DOWNLOAD_RESUME_INIT: "DOWNLOAD_RESUME_INIT",
  DOWNLOAD_RESUME_LINK_SUCCESSFUL: "DOWNLOAD_RESUME_LINK_SUCCESSFUL",

  CLEAR_SEARCH_INIT: "CLEAR_SEARCH_INIT",
  CLEAR_SEARCH_VALUE_INIT: "CLEAR_SEARCH_VALUE_INIT",
  CLEAR_SEARCH_ICON_INIT: "CLEAR_SEARCH_ICON_INIT",

  // UPDATE_IMPERSONATION_ID_INIT: "UPDATE_IMPERSONATION_ID_INIT",
  // UPDATE_IMPERSONATION_ID_SUCCESS: "UPDATE_IMPERSONATION_ID_SUCCESS",
  // UPDATE_IMPERSONATION_ID_FAILURE: "UPDATE_IMPERSONATION_ID_FAILURE",
  UPDATE_IMPERSONATION_ID: "UPDATE_IMPERSONATION_ID",

  GET_MESSAGES_INBOX_REQUESTED: "GET_MESSAGES_INBOX_REQUESTED",
  GET_MESSAGES_INBOX_SUCCESS: "GET_MESSAGES_INBOX_SUCCESS",
  GET_MESSAGES_INBOX_FAILURE: "GET_MESSAGES_INBOX_FAILURE",

  DASHBOARD_MESSAGES_INBOX_REQUESTED: "DASHBOARD_MESSAGES_INBOX_REQUESTED",
  DASHBOARD_MESSAGES_INBOX_SUCCESS: "DASHBOARD_MESSAGES_INBOX_SUCCESS",
  DASHBOARD_MESSAGES_INBOX_FAILURE: "DASHBOARD_MESSAGES_INBOX_FAILURE",

  GET_MESSAGES_INBOX_REQUESTED_HEADER: "GET_MESSAGES_INBOX_REQUESTED_HEADER",
  GET_MESSAGES_INBOX_SUCCESS_HEADER: "GET_MESSAGES_INBOX_SUCCESS_HEADER",
  GET_MESSAGES_INBOX_FAILURE_HEADER: "GET_MESSAGES_INBOX_FAILURE_HEADER",

  SEND_MESSAGE_ATTACHMENT_REQUESTED: "SEND_MESSAGE_ATTACHMENT_REQUESTED",
  SEND_MESSAGE_ATTACHMENT_SUCCESS: "SEND_MESSAGE_ATTACHMENT_SUCCESS",
  SEND_MESSAGE_ATTACHMENT_FAILURE: "SEND_MESSAGE_ATTACHMENT_FAILURE",

  MESSAGES_RECEIVED: "MESSAGES_RECEIVED",

  SEND_RESCHEDULE_INTERVIEW: "SEND_RESCHEDULE_INTERVIEW",
  SEND_RESCHEDULE_FAILURE: "SEND_RESCHEDULE_FAILURE",
  SEND_RESCHEDULE_SUCCESS: "SEND_RESCHEDULE_SUCCESS",

  TALENT_STAGES_FILTER_RETAIN: "TALENT_STAGES_FILTER_RETAIN",

  GET_NOTIFICATION_REQUESTED: "GET_NOTIFICATION_REQUESTED",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILURE: "GET_NOTIFICATION_FAILURE",
  CHECK_USERNAME_UNIQUE: "CHECK_USERNAME_UNIQUE",
  CHECK_USERNAME_UNIQUE_SUCCESS: "CHECK_USERNAME_UNIQUE_SUCCESS",
  CHECK_USERNAME_UNIQUE_FAIL: "CHECK_USERNAME_UNIQUE_FAIL",

  SEND_EMAIL_VERIFICATION_INIT: "SEND_EMAIL_VERIFICATION_INIT",
  SEND_EMAIL_VERIFICATION_SUCCESS: "SEND_EMAIL_VERIFICATION_SUCCESS",
  SEND_EMAIL_VERIFICATION_FAILURE: "SEND_EMAIL_VERIFICATION_FAILURE",

  FETCH_VIEWLISTING_INIT: "FETCH_VIEWLISTING_INIT",
  FETCH_VIEWLISTING_SUCCESS: "FETCH_VIEWLISTING_SUCCESS",
  FETCH_VIEWLISTING_FAILURE: "FETCH_VIEWLISTING_FAILURE",

  FETCH_COMPANYPROFILE_INIT: "FETCH_COMPANYPROFILE_INIT",
  FETCH_COMPANYPROFILE_SUCCESS: "FETCH_COMPANYPROFILE_SUCCESS",
  FETCH_COMPANYPROFILE_FAILURE: "FETCH_COMPANYPROFILE_FAILURE",

  GET_MESSAGES_COUNTS_INIT: "GET_MESSAGES_COUNTS_INIT",
  GET_MESSAGES_COUNTS_FAILURE: "GET_MESSAGES_COUNTS_FAILURE",
  GET_MESSAGES_COUNTS_SUCCESS: "GET_MESSAGES_COUNTS_SUCCESS",

  FETCH_ONE_SKILLLISTING_INIT: "FETCH_ONE_SKILLLISTING_INIT",
  FETCH_ONE_SKILLLISTING_SUCCESS: "FETCH_ONE_SKILLLISTING_SUCCESS",
  FETCH_ONE_SKILLLISTING_FAILURE: "FETCH_ONE_SKILLLISTING_FAILURE",

  FETCH_RECEIVEDBID_COUNT_REQUESTED: "FETCH_RECEIVEDBID_COUNT_REQUESTED",
  FETCH_RECEIVEDBID_COUNT_SUCCESS: "FETCH_RECEIVEDBID_COUNT_SUCCESS",
  FETCH_RECEIVEDBID_COUNT_FAILURE: "FETCH_RECEIVEDBID_COUNT_FAILURE",

  FETCH_ONE_SKILLLISTING_ID: "FETCH_ONE_SKILLLISTING_ID",
  REMOVE_ONE_SKILLLISTING_ID: "REMOVE_ONE_SKILLLISTING_ID",

  MANAGE_BID_COUNT_FETCH: "MANAGE_BID_COUNT_FETCH",

  ADD_REMOVE_EMPLOYER_FOLLOW_INIT: "ADD_REMOVE_EMPLOYER_FOLLOW_INIT",
  ADD_REMOVE_EMPLOYER_FOLLOW_SUCCESS: "ADD_REMOVE_EMPLOYER_FOLLOW_SUCCESS",
  ADD_REMOVE_EMPLOYER_FOLLOW_FAILURE: "ADD_REMOVE_EMPLOYER_FOLLOW_FAILURE",

  GET_INBOX_JOB_DETAILS: "GET_INBOX_JOB_DETAILS",
  UPDATE_RECENTSEARCH_INIT: "UPDATE_RECENTSEARCH_INIT",

  CATCH_ERROR: "CATCH_ERROR",
  CATCH_ERROR_FIX: "CATCH_ERROR_FIX",
};
