import React from 'react';

import { PageHeader, Typography, Col } from 'antd';
import './BidtoApply.css';
import BidForm from './BidForm-new';
import FormBtn from './BidFormButtons';
const BidtoApply = () => {
    return (
        <div className='bid-to-apply'>
            {/* <Col lg={24} md={24} sm={24} xs={24}>
                <BidHeader
                    BidApply="Bid to Apply"
                    bidspan="We're glad you found your dream gig - now let's get your bid submitted." />
            </Col> */}
            <BidForm method="post">
                <FormBtn />
            </BidForm>
        </div>
    )
}

export default BidtoApply;