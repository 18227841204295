import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchBidDataApi } from "../../API/API";

function* fetchBidDataSaga(action) {
    // console.log("in saga");
    try {
        const { _id } = action.payload;
        const response = yield call(() => fetchBidDataApi(_id));
        yield put({ type: types.FETCH_BIDDATA_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: types.FETCH_BIDDATA_FAILURE, error });
    }
}

export function* watchFetchBidDataSaga() {
    yield takeEvery(types.FETCH_BIDDATA_REQUESTED, fetchBidDataSaga);
}
