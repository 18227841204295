import { Modal, Input, Button } from "antd";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteRatingreviews } from "../../../../../../../redux/actions";

const DeleteRatingsModal = ({
  visible,
  setDelModalVisible,
  Ratings_data,
  BidId,
  bidType,
}) => {
  const { TextArea } = Input;
  const dispatch = useDispatch();

  const [reason, setReason] = useState();

  function onCancelHandler() {
    setDelModalVisible(false);
  }

  const onChange = (e) => {
    // console.log("Change:", e.target.value);
    setReason(e.target.value);
  };

  function deleteRatings(params) {
    // console.log("rating data in delete rating", reason);
    dispatch(
      deleteRatingreviews({
        type: bidType,
        DeleteReason: reason,
        Ratings_id: BidId,
      })
    );
    setDelModalVisible(false);
  }

  return (
    <Modal
      title={
        <h3>
          <b>Delete rating?</b>
        </h3>
      }
      visible={visible}
      onCancel={onCancelHandler}
      okText="Submit Reason"
      centered
      footer={[
        <button
          key="submit"
          onClick={deleteRatings}
          style={{
            backgroundColor: "#118936",
            color: "#fff",
            width: "250px",
            cursor: "pointer",
            height: "42px",
            border: "none",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "4px",
          }}
        >
          Submit Reason
        </button>,
      ]}
    >
      <label>
        Please provide a reason for deleting this rating{" "}
        <b style={{ color: "red" }}>*</b>
      </label>
      <TextArea
        style={{ marginTop: "5px" }}
        rows={5}
        autoSize={false}
        showCount
        maxLength={5000}
        onChange={onChange}
      />
    </Modal>
  );
};
export default DeleteRatingsModal;
