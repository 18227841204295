import { types } from "../action-types";

const initialState = {
    loading: false,
    timeList: [],
    error: false,
    userassignment:[],
    timer: 0
};

const timeSheetListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_TIMESHEETLIST_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.FETCH_TIMESHEETLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                timeList: action.payload,
                error: false,
            };
        case types.FETCH_TIMESHEETLIST_FAILURE:
            return {
                ...state,
                loading: false,
                timeList: [],
                error: true,
            };
        case types.GET_EXISTING_LIST_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                userassignment: action.payload,
                error: true,
            };

        case types.CREATE_TIMESHEET_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.CREATE_TIMESHEET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                userassignment: action.payload,
                error: false,
            };
        case types.CREATE_TIMESHEET_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                userassignment:[],
                error: true,
            };
        case types.UPDATE_TIMESHEET_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_TIMESHEET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                userassignment: action.payload,
                error: false,
            };
        case types.UPDATE_TIMESHEET_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                userassignment:[],
                error: true,
            };
        case types.FETCH_TIMESHEETLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                timeList: action.payload,
                error: false,
            };

        case types.SET_TIMER_TIMESHEET:
            return {
                ...state,
                loading: false,
                timer: action.payload,
                error: false,
            };
        
        default:
            return state;
    }
};

export default timeSheetListReducer;
