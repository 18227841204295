import { types } from "../action-types";

import initialState from './appReducer';

const fetchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.error,
      };
    default:
      return state;
  }
};

export default fetchDataReducer;
