import { types } from "../action-types";

const initialState = {
    loading: false,
    success: [],
    error: false,
};

const UserAssignmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.USERASSIGNMENT_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.USERASSIGNMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                success:action.payload,
                error: false,
            };
        case types.USERASSIGNMENT_FAILURE:
            return {
                ...state,
                loading: false,
                success:[],
                error: true,
            };
        // case types.UPDATE_BID_RESET:
        //     return {
        //         ...state,
        //         loading: false,
        //         success:false,
        //         error: false,
        //     };
        default:
            return state;
    }
};
export default UserAssignmentReducer;
