import { Rate } from "antd";
import React from "react";
import "./cardRating.css";
const CardTwo = () => {
  return (
    <>
      <div className="parentCard">
        <div class="Congratulations-Your-succesfully-completed-the-job">
          <div class="text-style-1">Thankyou!</div>
          You left employer name 5 star review
        </div>
        <div className="card-insidebox">
          <div className="g-star-rating">
            <Rate
              allowHalf
              // defaultValue={gigData?.Rating ? Number(gigData?.Rating) : 0}
              defaultValue={5}
              disabled={true}
            />
          </div>
          <div className="card-attribute_item22">
            {" "}
            The employer was very responsive and has a friendly demeanor! Thank
            you!!
          </div>
        </div>
        <div className="duration-card">11:35pm</div>
      </div>
    </>
  );
};

export default CardTwo;
