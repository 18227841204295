import { types } from "../action-types";

const initialState = {
  loading: false,
  user: {
    userDocs: [],
  },
  error: {},
};

const docReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_REQUEST_SUCCESS:
      action.payload.sort((a, b) => {
        return a.CredentialDocumentName > b.CredentialDocumentName ? 1 : -1;
      });
      return {
        ...state,
        loading: false,
        user: { ...state.user, userDocs: action.payload },
        error: {},
      };
    case types.SEND_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.error,
      };

    case types.REMOVE_DOCUMENT_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.REMOVE_DOCUMENT_SUCCESS:
      // console.log("reducerparams", action);
      const docRemoveItem = state.user.userDocs.find(
        (item) => item._id == action.params
      );
      docRemoveItem["userDoc"] = {};
      return {
        ...state,
        loading: false,
        user: { ...state.user },
        error: {},
      };

    case types.REMOVE_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case types.UPLOAD_DOCUMENT_SUCCESS:
      // console.log("PAYLOAD IN REDUCER:", action.payload);
      const docItem = state.user.userDocs.find(
        (item) => item._id == action.payload.CredentialDocumentId
      );
      docItem["userDoc"] = {
        s3FileKey: action.payload.key,
        FileName: action.payload.fileName,
        FileSize: action.payload.fileSize,
      };
      return {
        ...state,
        loading: false,
        user: { ...state.user },
        error: {},
      };
    case types.UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.error,
      };
    default:
      return state;
  }
};

export default docReducer;
