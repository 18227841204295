import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import {
  approveLinkedInUserAPI,
  fetchToken,
  sendEmailVerificationStatusAPI,
} from "../../API/API";
import { getCookie, setCookie } from "../../shared/helpers";
import { linkedInTokenFail, updateImpersonationIdSuccess } from "../actions";

function* authSaga(action) {
  // // console.log("SAGA TOKEN HIT");
  yield put({ type: types.UPDATE_USER_INFO_SUCCESS, payload: action.payload });
}

// catches the action before going to the reducer and run the saga attached to it
export function* watchAuthSaga() {
  yield takeEvery(types.UPDATE_USER_INFO_INIT, authSaga);
}

// Linked In Saga

function* linkedInSaga(action) {
  // console.log("Saga Token: ", action.payload);
  try {
    const response = yield call(() => approveLinkedInUserAPI(action.payload)); // API Backend
    // console.log("API RESPONSE LinkedIn: ", response);
    setCookie("talentToken", response.data.token, true);
    yield put({
      type: types.UPDATE_USER_INFO_SUCCESS,
      payload: response.data.token,
    });
  } catch (error) {
    // console.log("LINK ERROR RESPONSE", error.response);
    yield put(linkedInTokenFail(error.response.data));
  }
  // yield put({ type: types.LINKEDIN_TOKEN_FETCH, payload: action.payload });
}
export function* watchLinkedInSaga() {
  yield takeEvery(types.LINKEDIN_TOKEN_INIT, linkedInSaga);
}

// email verification saga
function* sendEmailVerificationSaga(action) {
  // console.log("Saga emailVerification: ", action.payload);
  try {
    const response = yield call(() =>
      sendEmailVerificationStatusAPI(action.payload)
    ); // API Backend
    // console.log("emailVerification API RESPONSE: ", response);
    // yield put(getEmailVerificationStatusSuccess(response?.data?.data));
  } catch (error) {
    // console.log("emailVerification ERROR RESPONSE", error.response);
    // yield put(getEmailVerificationStatusFailure(response?.data?.data));
  }
  // yield put({ type: types.LINKEDIN_TOKEN_FETCH, payload: action.payload });
}

export function* watchSendEmailVerificationSaga() {
  yield takeEvery(
    types.SEND_EMAIL_VERIFICATION_INIT,
    sendEmailVerificationSaga
  );
}

// IMPERSONATION SAGA
// function* impersonationSaga(action) {
//   // console.log("impersonationSaga Token: ", action.payload);

//   yield put(updateImpersonationIdSuccess(error.response.data));

//   // yield put({ type: types.LINKEDIN_TOKEN_FETCH, payload: action.payload });
// }

// export function* watchImpersonationSaga() {
//   yield takeEvery(types.UPDATE_IMPERSONATION_ID_INIT, impersonationSaga);
// }
