import { types } from "../action-types";

const initialState = {
    setJobsInfo:[]
};

const searchSuggestionReducer = (state = initialState, action) => {
    // // console.log("setjobsss" , action.payload)
    switch (action.type) {
        case types.SEARCH_SUGGESTION_INPUT:
            state.setJobsInfo = action.payload
            // console.log("setjobsss1" , state)
        return state
            
        default:
            return state;
    }
};

export default searchSuggestionReducer;
