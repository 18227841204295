import React from "react";
import { useHistory } from "react-router-dom";
import "./UpgradeYourself.css";
import { connect } from "react-redux";
import img_2 from "../../../assets/img/img_2.png";
import img_3 from "../../../assets/img/img_3.png";
import img_1 from "../../../assets/img/img_1.png";

const UpgradeYourself = (props) => {
  // const history = useHistory();
  // history.push('/login');
  const PDFfile = "http://www.africau.edu/images/default/sample.pdf";
  return (
    <div className="upgradeYourself-container">
      <div className="upgradeYourself-container-parent-Div ">
        <img src={img_1} className="upgradeYourself-container-images" />
        <div className="upgradeYourself-cardcontent">
          <span className="upgradeYourself-cardcontent-heading">
            Interview preparation guide
          </span>
          <span className="upgradeYourself-cardcontent-subHeading">
            Big interview coming up? Put your best foot forward by using our
            Interview Prep Guide, including information about the interview
            process from start to finish. Click here to download.
          </span>
          {props.authToken ? (
            <div className="upgradeYourself-primary-button">
              <span
                onClick={() =>
                  window.open(
                    "https://skillgigs.com/guides/interview-preparation-guide/"
                  )
                }
                class="upgradeYourself-primaryButton-content"
              >
                Download now
              </span>
            </div>
          ) : (
            <div className="upgradeYourself-primary-button">
              <span class="upgradeYourself-primaryButton-content">
                Download now
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="upgradeYourself-container-parent-Div ">
        <img src={img_2} className="upgradeYourself-container-images" />
        <div className="upgradeYourself-cardcontent">
          <span className="upgradeYourself-cardcontent-heading">
            Ultimate resume guide
          </span>
          <span className="upgradeYourself-cardcontent-subHeading">
            Your skills are in demand. You have all the right to get the best
            bid and set the path you want for your career. To begin, give your
            resume and how you pitch yourself an overhaul. Learn how to make
            your resume stand out amongst the crowd and those pesky applicant
            tracking systems. Download here.
          </span>
          {props.authToken ? (
            <div className="upgradeYourself-primary-button">
              <span
                onClick={() =>
                  window.open("https://skillgigs.com/guides/resume-guide/")
                }
                class="upgradeYourself-primaryButton-content"
              >
                Download now
              </span>
            </div>
          ) : (
            <div className="upgradeYourself-primary-button">
              <span class="upgradeYourself-primaryButton-content">
                Download now
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="upgradeYourself-container-parent-Div ">
        <img src={img_3} className="upgradeYourself-container-images" />
        <div className="upgradeYourself-cardcontent">
          <span class="upgradeYourself-cardcontent-heading">
            What is a 3D resume?
          </span>
          <span className="upgradeYourself-cardcontent-subHeading">
            {/* Download the free resume guide to help you build the... */}
            The patented 3D Resume is a 360 view of the talent based on their
            skills and expertise. It is our predictive hiring technology. It is
            also used as a mapping tool used to map a company’s best talent to
            match to prospective candidates on our platform and use our AI
            matching system. Click here to learn more how SkillGigs works.
          </span>
          {props.authToken ? (
            <div className="upgradeYourself-primary-button upgradeYourself-primary-button-learn-more">
              <span
                onClick={() =>
                  window.open("https://skillgigs.com/how-it-works/")
                }
                class="upgradeYourself-primaryButton-content"
              >
                Learn more
              </span>
            </div>
          ) : (
            <div className="upgradeYourself-primary-button upgradeYourself-primary-button-learn-more">
              <span class="upgradeYourself-primaryButton-content">
                Learn more
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.user.authToken,
  };
};

export default connect(mapStateToProps)(UpgradeYourself);
