import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import AuthSuccess from "../pages/Auth/components/AuthSuccess";

export const PublicRoute = (props) => <Route {...props} />;

export const LoginRoute = (props) => {
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  return !authToken ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
        state: { from: props.location },
      }}
    />
  );
};

export const ValidateRoute = (props) => {
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  const { ExperienceLevelName, WizardFlow } = useSelector(
    (state) => state.profileReducer.user.profile
  );
  // console.log("ExperienceLevelName", ExperienceLevelName, WizardFlow);
  return authToken && WizardFlow !== 6 ? <AuthSuccess /> : <Route {...props} />;
};

export const SpecialRoute = (props) => {
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  return authToken === null ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
        state: { from: props.location },
      }}
    />
  );
};

export const ProtectedRoute = (props) => {
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  if(authToken == null){
    localStorage.setItem("redirectUrl", props.location.pathname);
  }
  return authToken ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    />
  );
};
