import { put, call, takeEvery } from "@redux-saga/core/effects";
import {
  addRemoveEmployerFollowAPI,
  fetchCompanyProfileAPI,
  fetchViewListingAPI,
} from "../../API/API";
import { types } from "../action-types";
import {
  addRemoveEmployerFollowFailure,
  addRemoveEmployerFollowSuccess,
  catchError,
  fetchCompanyProfileFailure,
  fetchCompanyProfileSuccess,
  fetchViewListingFailure,
  fetchViewListingSuccess,
} from "../actions";
function* fetchViewListingSaga({ payload: params }) {
  try {
    const response = yield call(() => fetchViewListingAPI(params)); // API Backend
    if (response.status == 200) {
      yield put(fetchViewListingSuccess(response?.data?.data?.body?.data));
    } else {
      yield put(fetchViewListingFailure(response));
    }
  } catch (error) {
    yield put(fetchViewListingFailure(error));
    yield put(catchError(error));
  }
}

function* fetchCompanyProfileSaga({ payload: params }) {
  try {
    const response = yield call(() => fetchCompanyProfileAPI(params)); // API Backend
    if (response.status == 200) {
      yield put(fetchCompanyProfileSuccess(response?.data));
    } else {
      yield put(fetchCompanyProfileFailure(response));
    }
  } catch (error) {
    yield put(fetchCompanyProfileFailure(error));
    yield put(catchError(error));
  }
}

function* addRemoveEmployerFollowSaga({ payload }) {
  try {
    const response = yield call(() => addRemoveEmployerFollowAPI(payload)); // API Backend
    if (response.status == 200) {
      yield put(addRemoveEmployerFollowSuccess(response?.data));
    } else {
      yield put(addRemoveEmployerFollowFailure(response));
    }
  } catch (error) {
    yield put(addRemoveEmployerFollowFailure(error));
  }
}

export function* watchCompanyProfileSaga() {
  yield takeEvery(types.FETCH_COMPANYPROFILE_INIT, fetchCompanyProfileSaga);
}

export function* watchViewListingSaga() {
  yield takeEvery(types.FETCH_VIEWLISTING_INIT, fetchViewListingSaga);
}

export function* watchEmployerFollowSaga() {
  yield takeEvery(
    types.ADD_REMOVE_EMPLOYER_FOLLOW_INIT,
    addRemoveEmployerFollowSaga
  );
}
