import React from "react";

import LostPassword from "./components/LostPassword";
import "./components/LostPassword.css"

const ForgetPassword = () =>{
    return(
        <>
        <LostPassword/>
        </>
    )
}

export default ForgetPassword;