import {
  put,
  call,
  takeEvery,
  takeLatest,
  takeLeading,
} from "@redux-saga/core/effects";
import {
  getNotificationsAPi,
  UpdateNotificationAPI,
  getReadNotificationApi,
} from "../../API/API";
import { types } from "../action-types";
import {
  UpdateNotificationFailure,
  UpdateNotificationSuccess,
} from "../actions";

function* UpdateNotificationSaga(action) {
  //  console.log(
  //   "Notification Saga==========================================================================>",
  //   action.payload
  // );
  try {
    // console.log("Notification Saga Is working");
    const response = yield call(() => UpdateNotificationAPI(action.payload));
    //  console.log(
    //   "Notification Response ===========================================>",
    //   response
    // );
    if (response.status === 200) {
      yield put(UpdateNotificationSuccess(response.profile));
    } else {
      yield put(UpdateNotificationFailure(response));
    }
  } catch (error) {
    yield put(UpdateNotificationFailure(error));
  }
}
function* GetNotificationSaga(action) {
  try {
    const response = yield call(() => getNotificationsAPi(action));

    if (response.status === 200) {
      yield put({
        type: types.GET_NOTIFICATION_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: types.GET_NOTIFICATION_FAILURE,
        payload: response.profile,
      });
    }
  } catch (error) {
    yield put(UpdateNotificationFailure(error));
  }
}

function* getReadNotificationsSaga(action) {
  try {
    const { id } = action.payload;
    const response = yield call(() => getReadNotificationApi(id));
    yield put({
      type: types.READ_NOTIFICATION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.READ_NOTIFICATION_FAILURE, error });
  }
}

export function* watchNotificationSaga() {
  yield takeEvery(types.UPDATE_NOTIFICATION_INIT, UpdateNotificationSaga);
}
export function* watchGetNotificationSaga() {
  yield takeEvery(types.GET_NOTIFICATION_REQUESTED, GetNotificationSaga);
}
export function* watchGetReadNotifications() {
  yield takeLeading(types.READ_NOTIFICATION, getReadNotificationsSaga);
}
