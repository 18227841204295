import React, { useEffect, useState } from "react";
import "./NoViewHistorySkill.css";

import { BsPlusLg } from "react-icons/bs";
import emptyBox from "../../../../assets/img/emptybox.svg";

const NoViewHistorySkill = (props) => {
  const { type, func } = props;

  const [dimensions, setDimensions] = useState(window.innerWidth);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  return (
    <div className="noViewHistorySkill">
      {dimensions > 768 && (
        <div className="title-noViewHistorySkill">{type}</div>
      )}
      <div className="emptyBox-img">
        <img src={emptyBox} alt="emptyBox" />
      </div>
      <div>
        {dimensions > 768 ? (
          <div className="addhistory-skillListing" onClick={() => func(true)}>
            <BsPlusLg color="white" style={{ marginTop: "2.5px" }} />
            Add {type}
          </div>
        ) : (
          <div className="nohistoryEX">{`No ${type}!`}</div>
        )}
      </div>
    </div>
  );
};

export default NoViewHistorySkill;
