import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import {fetchTimeSheetListApi,createTimeSheetItemsApi,updateTimeSheetApi,getExistingListemItemAPi} from "../../API/API"


function* fetchTimeSheetListSaga(action) {
    
    try {
        const {limit, skip,type,start,end} = action.payload;
        
        // console.log("saga payload", JSON.stringify(action.payload))
        const response = yield call(() => fetchTimeSheetListApi(limit, skip,type,start,end));
        // console.log("Saga response payload: ",response.data)
        yield put({type: types.FETCH_TIMESHEETLIST_SUCCESS, payload: response.data});
    } catch (error) {
        yield put({type: types.FETCH_TIMESHEETLIST_FAILURE, error});
    }
}

function* fetchTimeSheetSaga(params) {
    // console.log("sheet param:",JSON.stringify(params));
    try {   
        const {Status,Date,UserAssignmentId,SpentTime,OverTime} = params.payload;
        // console.log("params:",Status,Date,UserAssignmentId,SpentTime,OverTime)
        const response = yield call(createTimeSheetItemsApi, params); 
        
        // API Backend
        // console.log("Saga response payload: ",response.data)
        window.timesheetId=response.data._id
        // console.log("id is",window.timesheetId);
        yield put({type: types.CREATE_TIMESHEET_DATA_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.CREATE_TIMESHEET_DATA_FAILURE, error});
    }
}

function* updateTimeSheetSaga(params) {
    // console.log("param:",JSON.stringify(params));
    try {
        // const {Status} = params.payload;
        // // console.log("status is",Status);
        const response = yield call(updateTimeSheetApi, params); 
        yield put({type: types.UPDATE_TIMESHEET_DATA_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.UPDATE_TIMESHEET_DATA_FAILURE, error});
    }
}
function* getExistingListItem() {
    // console.log("GET EXISTING LIST ITEM SAGA");
    try {
        const response = yield call(getExistingListemItemAPi); 
        yield put({type: types.GET_EXISTING_LIST_ITEM_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.GET_EXISTING_LIST_ITEM_FAILURE, error});
    }
}

export function* watchFetchTimesheetListSaga() {
    yield takeEvery(types.FETCH_TIMESHEETLIST_REQUESTED, fetchTimeSheetListSaga);
}


export function* watchFetchTimesheetSaga() {
    yield takeEvery(types.CREATE_TIMESHEET_DATA_REQUESTED, fetchTimeSheetSaga);
}

export function* watchUpdateTimesheetSaga() {
    yield takeEvery(types.UPDATE_TIMESHEET_DATA_REQUESTED, updateTimeSheetSaga);
}

export function* watchExisitingListItemSaga() {
    yield takeEvery(types.GET_EXISTING_LIST_ITEM_REQUESTED, getExistingListItem);
}