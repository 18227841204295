import React from 'react';
import { Button, Tooltip } from 'antd';
import "./ToolTips.css";
import infoIcon from '../../assets/icons/info.svg'

const ToolTips = (props) => {
    const { placement, title , className = "", zIndex = 10050 } = props;
    return (
        <div onClick={(e) => { e.stopPropagation(); }} className='ToolTipsInnerStyle' >
            <Tooltip
                placement={placement}
                title={title}
                color="#272727"
                overlayStyle={{
                    color: "#fff",
                    fontSize: "11px",
                    right: "20px",
                }}
                overlayClassName={className}
                trigger={window.innerWidth > 768 ? "hover" : "click"}
                zIndex={zIndex}
            >
                <Button type="link" ><img src={infoIcon} /></Button>
            </Tooltip>
        </div>
    );
};

export default ToolTips;


export const ToolTipsAlert = (props) => {
    const { title, width } = props;
    return (
        <div className={`ToolTipsAlert-Main ${width}`}>
            <span className='ToolTipsAlert-Img'><img src={infoIcon} /></span>
            <div className='ToolTipsAlert-Text'>{title}</div>
        </div>
    );
};
