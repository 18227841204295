import {
  AlertTwoTone,
  RightOutlined,
  HeartFilled,
  SearchOutlined,
  TwitterCircleFilled,
} from "@ant-design/icons";
import { Rate, Divider, Input, Modal, Row, Col } from "antd";
import { BsStarFill } from "react-icons/bs";
import { React, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
  useLazyQuery,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import {
  catchError,
  fetchGigs,
  isModalVisibleAuth,
  postFavoriteGigCard,
  removeFavoriteGigCard,
  removeFavoriteGigCardReducer,
  SearchResponse,
  SearchResponseRequested,
  SearchValue,
  ResetBidData
} from "../../../../redux/actions";
import { HttpLink } from "apollo-link-http";
import { getImageByStateCode } from "../../../../shared/helpers";
import "./GuestGigDetails.css";
import { useParams, useHistory } from "react-router-dom";

import { toInteger } from "lodash";
import { FaFacebook, FaRegHospital, FaTwitter } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
import { connect } from "react-redux";
import { debounce } from "../../../../shared/helpers";
import HeartIcon from "../../../../assets/icons/heart-filled.svg";
import Share from "../../../../assets/icons/cishare.svg";
import HospitalIcon from "../../../../assets/icons/hospital-outline.svg";
import SearchBarMobile from "../../components/SearchBarMobile";
import { useLocation } from "react-router-dom";
import { alertNotification } from "../../../../components/notifications/alertNotification";
import { AiFillLinkedin } from "react-icons/ai";
import copyLink from "../../../../assets/icons/copyLink.svg";
import twitter from "../../../../assets/icons/twitter.svg";
import facebook from "../../../../assets/icons/facebook.svg";
import copyLinks2 from "../../../../assets/icons/copyLinks2.svg";
import linkedinn from "../../../../assets/icons/linkedinn.svg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import MetaTags from "react-meta-tags";
import Loader from "../../../../components/Loader/Loader";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";
import GigDetailAboutSection from "./GigDetailAboutSection";
import OpenPositions from "./IT-UserComponent/OpenPositionsIT";
import GigExpiresIT from "./IT-UserComponent/GigExpiresIT";
import StatsJobDetailsIT from "./IT-UserComponent/StatsJobDetailsIT";
import GigJobDetailsITUser from "./IT-UserComponent/GigJobDetailsITUser";
import SkillDensityPieChart from "./IT-UserComponent/SkillDensityPieChart";
import CreateBidButtonIT from "./IT-UserComponent/CreateBidButtonIT";
import ExpiredGigs from "./IT-UserComponent/ExpiredGigs";

function PieChartStructure(props, key) {
  return {
    [key]: [props],
  };
}

function GuestGigDetails(props) {
  const history = useHistory();
  const { state } = useLocation();
  const { isModalVisibleAuth, setIsModalVisibleAuth } = props;
  const [placeholder, showPlaceholder] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [Isfav, setIsfav] = useState();
  const [gig, setGig] = useState();
  const [IsFavoriteCheck, setIsFavoriteCheck] = useState(false);
  const [budget, setBudget] = useState();
  const locationURL = useLocation().pathname;
  const [isModalVisibleAuth2, setIsModalVisibleAuth2] = useState(false);
  const [copied, setCopied] = useState(false);
  const [Defaultplaceholder, setDefaultplaceholder] = useState(false);
  const [FloatingApplyButton, setFloatingApplyButton] = useState(false);
  const [isExpiredGigs, setExpiredGigs] = useState(false);
  const showModal = () => {
    setIsModalVisibleAuth2(true);
  };

  const modalCancel = () => {
    setIsModalVisibleAuth2(false);
    setCopied(false);
  };

  const copy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };

  const budgetTypeQuery = useSelector(
    (state) => state.searchGigsReducer.budgetTypeQuery
  );
  const [budgetType, setBudgetType] = useState("WK");
  // console.log("budget: ", budget, budgetTypeQuery);

  const dispatch = useDispatch();
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );
  // console.log("budget: ", budget);
  let { jobid } = useParams();
  // console.log("jobid ", jobid);
  const Skillgigs = () => {
    history.push("/search");
  };
  const Healthcare = () => {
    alert("Heathcare Page");
  };

  const retractedBidClick = () => {
    history.push("/RetractedBids");
  };

  const detailQuery = gql`
    query {
      gig(id: ${jobid},
      ShowRateIn : ${budgetType}
      ) {
        _id
		   JobOrderId
		   ProfileId
		   Title
       AccountRole
		   Description
		   ZipCode
		   OnSite
		   FixedPrice
		   Rate
		   ExpiryDate
		   Created
		   CreatedBy
		   Modified
		   ModifiedBy
		   IsActive
		   Budget
		   IdealTermsId
		   BudgetRangeId
		   ReasonId
		   BudgetMin
		   BudgetMax
		   VisaStatusId
		   SuggestionsFrequency
		   SuggestionsSent
		   InternalTitleId
		   ImportSource
		   ExternalGigId
		   ImportProcessStatus
		   FacilityName
		   AutoLoaded
		   SourceName
		   SourceId
		   CityName
		   RegionCode
		   Specialty
		   Shift
		   WorkplacePolicy
		   VMSLink
		   Duration
       maxBidJob
       companyName
       totalBidCount
       totalProposalCount
       WorkAuthorization
       Position
       Modality
       StartDate
       SubModality
       MinExperienceRequired
       NewProposals {
        _id
        JobOrderId
        ProposalId
        BidStatusId
        ProfileId
       }
       views {
        ViewerProfileId
        viewCount
        Created
        }
       Industry {
        IndustryName
        IndustryPercent
      }
       JobOrders_Skills{
        Id
        SkillPercent
        SkillName
        Skills{
         SkillId
         SkillCategoryId
         SkillName
         SkillCode
         SkillNameLowerCase
        }
      }
      JobOrders_VisaStatuses {
        VisaStatusId
      }
			InternalGigTypes {
					InternalGigTypeId
					InternalGigTypeCode
					InternalGigTypeName
					InternalGigTypeDescription
					Created
					CreatedBy
					Modified
					ModifiedBy
			}
			IdealContractLength {
					IdealContractLengthId
					IdealContractLengthName
					IdealContractLengthValue
					Created
					CreatedBy
					Modified
					ModifiedBy
			}
			Professions {
					ProfessionId
					ProfessionCode
					ProfessionName
					ProfessionDescription
					Created
					CreatedBy
					Modified
					ModifiedBy
			}
			ExperienceLevels {
					ExperienceLevelId
					ExperienceLevelCode
					ExperienceLevelName
					MinVal
					MaxVal
					SortOrder
					Created
					CreatedBy
					Modified
					ModifiedBy
			}
			JobOrderTypes {
					JobOrderTypeId
					JobOrderTypeName
					JobOrderTypeCode
					Created
					CreatedBy
					Modified
					ModifiedBy
			}
			Countries {
					CountryId
					CountryName
					FIPS104
					ISO2
					ISO3
					ISON
					Internet
					Capital
					MapReference
					NationalitySingular
					NationalityPlural
					Currency
					CurrencyCode
					Population
					Title
					Comment
			}
			Regions {
					RegionId
					CountryId
					RegionName
					Code
					ADM1Code
					TimeZone
			}
			Cities {
					CityId
					CountryId
					RegionId
					CityName
					Longitude
					Latitude
					TimeZone
					CityCode
			}
			BudgetTypes {
					BudgetTypeId
					BudgetTypeName
					BudgetTypeCode
					Created
					CreatedBy
					Modified
					ModifiedBy
					Order
			}
			JobOrderStatuses {
					JobOrderStatusId
					JobOrderStatusName
					JobOrderStatusCode
					Created
					CreatedBy
					Modified
					ModifiedBy
			}
			IsFavorite
      facilityRating {
        rating
        reviews
      }
			Skills {
					SkillName
					SkillPercent
			}
        IsFavorite
        Location {
          CityId
          CityName
          RegionId
          RegionCode
          LocationLong
          CountryId
          Location
          LocationWithCountry
          Latitude
          Longitude
          RegionName
          CountryName
        }
      }
    }
  `;

  // console.log("abhi", props.isRetractedBids);
  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
  const [hitQuery, { loading, error, data }] = useLazyQuery(detailQuery);

  useEffect(() => {
    error && dispatch(catchError(error));
  }, [error]);

  useEffect(() => {
    hitQuery();
  }, [jobid, budgetType]);
  useEffect(() => {
    // console.log("data", data?.gig);
    setGig(data?.gig);
  }, [data, []]);
  const ProfessionCode = gig?.Professions?.ProfessionCode;
  let budgetraw = gig?.BudgetMin;
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    if (gig?.BudgetType == "Hour") {
      // console.log("budgett");
      let bgt = budgetraw * 40;
      setBudget(bgt);
    } else {
      setBudget(budgetraw);
    }
  }, [data]);
  useEffect(() => {
    setIsFavoriteCheck(data?.gig?.IsFavorite);
  }, [data]);

  useEffect(() => {
    // BUDGET CHECK
    if (budgetTypeQuery === "Hourly") {
      // console.log("Budget Query", budgetTypeQuery);
      setBudgetType("HR");
    }
    if (budgetTypeQuery === "Weekly") {
      setBudgetType("WK");
    }
  }, [budgetTypeQuery]);
  // CHECK FOR MOBILE
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  const myRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setFloatingApplyButton(false);
      }
      else{
        setFloatingApplyButton("floating-bottom-button-details");
      }
    });

    if (myRef.current) {
      observer.observe(myRef.current);
    }

    return () => {
      if (myRef.current) {
        observer.unobserve(myRef.current);
      }
    };
  });

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
    if (authTokenValue) {
      // Clear localStorage after signup of guestuser(redirect url to jobDetail page)
      localStorage.removeItem("JobDetailpageUrl");
      localStorage.removeItem("ProfessionsCode");
    }
  }, []);
  // const Budget = () => {
  //   if (gig?.BudgetType == "Hour") {
  //     // console.log("budgett");
  //     let bgt = budgetraw * 40;
  //     setBudget(bgt);
  //   } else {
  //     setBudget(budgetraw);
  //   }
  // };
  // Gig created on
  let msec = Number(gig?.Created);
  let time = new Date(msec);
  let year = time.getFullYear();
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = months[time.getMonth()];
  let date = time.getDate();
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let day = days[time.getDay()];
  const dates = { day, date, month, year }.toString();

  // Gig Expires on
  let nowDate = Date.now();
  let expmsec = Number(gig?.ExpiryDate);
  let exptime = new Date(expmsec);
  let expyear = exptime.getFullYear();
  let expmonth = months[exptime.getMonth()];
  let expdate = exptime.getDate();
  let expday = days[exptime.getDay()];
  const expdates = { expday, expdate, expmonth, expyear }.toString();
  const cardImage = placeholder
    ? getImageByStateCode(
        gig?.Regions?.Code
        // gig?.Location?.CityName
      )
    : getImageByStateCode(gig?.Regions?.Code, gig?.Cities?.CityName);
  // console.log("img", getImageByStateCode(cardImage));

  useEffect(() => {
    setExpiredGigs(nowDate > expmsec);
  }, [data, []]);

  const onSearch = (e) => {
    props.searchValue(e.target.value);
    // if (e.target.value.length > 0) {
    // }
    if (e.target.value.length == 0) {
      history.push("/search");
    }
  };
  const handleApply = (gig) => {
    // console.log({ gig });
    let location = {
      CityName: gig.Cities.CityName,
      Location: gig.Cities.CityName + ", " + gig.Regions.Code,
      CountryName: gig.Countries.CountryName,
      RegionCode: gig.Regions.RegionName,
      Latitude: gig.Cities.Latitude,
      Longitude: gig.Cities.Longitude,
      LocationLong: gig.Cities.CityName + ", " + gig.Regions.RegionName,
    };

    localStorage.setItem(
      "image",
      getImageByStateCode(gig?.Regions?.Code, gig?.Cities?.CityName)
    );
    if (authTokenValue === null) {
      // Logic to redirect Guest user after login/signup (jobDetail page)
      localStorage.setItem("JobDetailpageUrl", `/details/${gig.JobOrderId}`);
      localStorage.setItem("ProfessionsCode", gig?.Professions?.ProfessionCode);
      if (dimensions < 769) {
        history.push("/login");
      } else {
        setIsModalVisibleAuth(true);
      }
    } else {
      if (gig?.NewProposals?.[0]?.JobOrderId == jobid && gig?.NewProposals?.[0]?.BidStatusId == 1) {
        let newProposalBidId = gig.NewProposals[0].ProposalId;
        let id = gig.NewProposals[0]._id;
        history.push({
          pathname: `/edit-bid/${newProposalBidId}`,
          state: {
            id: id,
            jobOrder: newProposalBidId,
            JobType: gig?.JobOrderTypes?.JobOrderTypeName
          },
        });
      }
      else {
        dispatch(ResetBidData())
        history.push({
          pathname: `/create-bid`,
          state: {
            title: gig.Title,
            FacilityName: gig.FacilityName,
            RegionName: location,
            budget: gig.BudgetMin,
            id: gig._id,
            JobOrderId: gig?.JobOrderId,
            professionGig: gig?.Professions?.ProfessionCode,
            JobType: gig?.JobOrderTypes?.JobOrderTypeName,
            AccountRole: gig?.AccountRole
          },
        });
      }
    }
  };

  // useEffect(()=>{
  //   if (authTokenValue) {
  //     localStorage.removeItem("JobDetailpageUrl");
  //   }
  // }, [authTokenValue])

  const onRateClick = () => {
    if (dimensions < 769) {
      history.push("/login");
    } else {
      setIsModalVisibleAuth(true);
    }
  };

  const titleDrawerlogi = <div></div>;

  const onHeartClick = (e) => {
    if (dimensions < 769) {
      e.stopPropagation();
      history.push("/login");
    } else {
      e.stopPropagation();
      setIsModalVisibleAuth(true);
    }
    localStorage.setItem(
      "Favorite",
      JSON.stringify({ JobOrderId: gig?.JobOrderId, gig })
    );
  };
  const IsFavLocalvalueUpdate = (e) => {
    setIsFavoriteCheck(!IsFavoriteCheck);
    AfterLoginonHeartClick(e);
  };

  const AfterLoginonHeartClick = (e) => {
    if (IsFavoriteCheck === false) {
      e.stopPropagation();
      alertNotification("success", "gig added to favorites");
      dispatch(postFavoriteGigCard(gig?.JobOrderId, "Gig"));
    } else if (IsFavoriteCheck === true) {
      e.stopPropagation();
      alertNotification("success", "gig removed from favorites");
      dispatch(removeFavoriteGigCard(gig?.JobOrderId));
      // dispatch(removeFavoriteGigCardReducer(gig?._id));
    }
  };

  const retractedBid12 = useSelector(
    (state) => state.BidsReducer?.isRetractedBids
  );

  const currentUrl = window.location.href;

  useEffect(() => {
    // console.log("isRetractedBid12", retractedBid12);
  }, [retractedBid12]);

  const location =
    gig?.Cities && gig?.Cities["CityName"]
      ? `${gig?.Cities["CityName"]}${
          gig?.Regions && gig?.Regions["Code"]
            ? `, ${gig?.Regions["Code"]}`
            : ""
        }`
      : "NA";

  return (
    <>
      {/* <>
        <MetaTags>
          <title>{gig?.Title}</title>
          <meta name="description" content="Some description." />
          <meta
            property="og:title"
            content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Labore incidunt adipisci maiores maxime sequi ex voluptatum odio
            illo optio placeat nostrum accusantium quibusdam, inventore nesciunt
            a voluptate deserunt qui quod?"
          />
        </MetaTags>
      </> */}
      {/* MOBILE */}
      {dimensions < 769 ? (
        <>
          {gig ? (
            <>
              <div className="gig-detail-container">
                <div className="search">
                  {/* <Input
                placeholder="Find Jobs"
                prefix={<SearchOutlined className="site-form-item-icon" />}
                onPressEnter={(e) => onSearch(e)}
                // style={{ width: 200 }}
              /> */}
                  {/* <SearchBarMobile /> */}
                </div>
                {/* <div className="divider-header-mobile">
              <Divider />
            </div> */}
                <div className="header-container-mobile">
                  {/* <div className="g-details-header">
                    {retractedBid12 ? (
                      <div onClick={Healthcare} className="header-text3">
                        Healthcare
                      </div>
                    ) : (
                      <div
                        type="text"
                        onClick={() => history.goBack()}
                        className="header-text1"
                      >
                        {" "}
                        {gig?.Title ? gig?.Title?.substring(0, 15) : " "}...
                      </div>
                    )}
                    <div className="g-details-arrow-mobile">
                      <RightOutlined className="arrow-mobile" />
                    </div>
                    {retractedBid12 ? (
                      <div onClick={retractedBidClick} className="header-text2">
                        Retracted bids
                      </div>
                    ) : (
                      <div className="header-text22">Detail</div>
                    )}
                  </div> */}
                </div>
                <div className="content-container-mobile">
                  <div
                    className="ractangle-left"
                    // style={{ background: `url(${cardImage})` }}
                  >
                   {!isExpiredGigs  && <div className="g-details-facility-right">
                      {/* <div className="div2">
                     <HiShare onClick={onRateClick} />
                    <img
                      src={Share}
                      alt=""
                      className="share-mobile"
                      onClick={onRateClick}
                    />
                  </div> */}
                      <div
                        className="div1-mobile"
                        onClick={
                          authTokenValue === null
                            ? (e) => onHeartClick(e)
                            : (e) => IsFavLocalvalueUpdate(e)
                        }
                      >
                        <Rate
                          character={
                            <HeartFilled
                              className={
                                IsFavoriteCheck ? "IsFavoriteCheck" : ""
                              }
                            />
                          }
                          className="heart"
                          count={1}
                          allowClear
                          disabled={authTokenValue === null ? true : false}
                          defaultValue={IsFavoriteCheck ? 1 : 0}
                          style={{
                            // margin: "0px 2px 0px",
                            position: "absolute",
                            fontSize: "0.875rem",
                            color: "#ff4343",
                            marginLeft: "0.124rem",
                            top: "0.45rem",
                          }}
                        />
                      </div>
                    </div>}
                    <div className="bid-details-mobile-container">
                      {/* <div className="bid-details"> */}

                      {/* <div>
                      ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                    </div> */}

                      {/* {budgetTypeQuery === "Hourly" ? (
                          <>
                            <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</div>
                          </>
                        ) : (
                          <>
                            <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/WK</div>
                          </>
                        )}
                        <div>
                          {ProfessionCode === "HC"
                            ? "Pay Package"
                            : "Estimated pay package"}
                        </div>
                      </div> */}
                    </div>
                    <div className="location-details">
                      <div className="location-text">{location}</div>
                    </div>
                    {placeholder ? (
                      <img
                        src={
                          Defaultplaceholder
                            ? getImageByStateCode()
                            : getImageByStateCode(gig?.Regions?.Code)
                        }
                        className="city-image"
                        onError={(e) => setDefaultplaceholder(true)}
                      />
                    ) : (
                      <img
                        src={getImageByStateCode(
                          gig?.Regions?.Code,
                          gig?.Cities?.CityName
                        )}
                        className="city-image"
                        onError={(e) => showPlaceholder(true)}
                      />
                    )}
                  </div>
                  {/* Job Details */}
                  <div className="g-details-facility-container">
                    <div className="job-details">{gig?.Title}</div>
                    <div className="g-details-facility-name">
                      {/* {ProfessionCode === "HC" && ( */}
                      <div className="g-hospitadl-icon">
                        <FaRegHospital className="g-hospital-icon" />{" "}
                      </div>
                      {/* )} */}
                      <div>
                        {ProfessionCode === "HC"
                          ? gig?.FacilityName
                          : gig?.companyName}
                      </div>
                    </div>
                    {/* {ProfessionCode === "HC" && ( */}
                    <div className="rating-review">
                      <div className="g-star-rating">
                        <Rate
                          allowHalf
                          value={
                            gig?.facilityRating?.rating
                              ? Number(gig?.facilityRating?.rating)
                              : 3.5
                          }
                          disabled={true}
                        />
                      </div>
                      <div
                        style={{ color: "#fadb14", margin: "0.3px 0 0 5px" }}
                      >
                        {gig?.facilityRating?.rating
                          ? Number(gig?.facilityRating?.rating)
                          : 3.5}
                      </div>
                      <div>
                        <div style={{ color: "#7e7e7e", margin: "0 0 0 5px" }}>
                          {gig?.facilityRating?.reviews &&
                            "(" + gig?.facilityRating?.reviews + ")"}
                        </div>
                      </div>
                    </div>
                    {/* )} */}
                    {/* <div className="g-details-facility-right">
                  <div className="div1">
                    <Rate
                      character={<HeartFilled />}
                      count={1}
                      allowClear
                      style={{ margin: "-9px 0px 0px -3px" }}
                    />
                  </div>
                  <div className="div2">
                    <HiShare />
                  </div>
                </div> */}
                  </div>
                  {/* <Divider /> */}
                  {/* <div style={{}}></div> */}
                  {isExpiredGigs ? (
                    <ExpiredGigs
                      expday={expday}
                      expmonth={expmonth}
                      expdate={expdate}
                      expyear={expyear}
                    />
                  ) : (
                    <div style={{ padding: "0 0.938rem" }}>
                      <Divider className="divider-mobile" />
                    </div>
                  )}
                  <GigDetailAboutSection
                    gig={gig}
                    location={location}
                    ProfessionCode={ProfessionCode}
                    expday={expday}
                    expmonth={expmonth}
                    expdate={expdate}
                    expyear={expyear}
                    day={day}
                    month={month}
                    date={date}
                    year={year}
                    dimensions={dimensions}
                    handleApply={handleApply}
                  />
                  {/* <Divider className="divider" /> */}

                  {/* {ProfessionCode === "IT" && <GigJobDetailsITUser Description={gig?.Description} />} */}
                  {
                    <div className="Gigdetails-IT-Users-Mobile">
                      {ProfessionCode === "IT" ? (
                        <GigJobDetailsITUser Description={gig?.Description} />
                      ) : null}
                      <OpenPositions gig={gig} />
                      <GigExpiresIT gig={gig} />
                      {/* <StatsJobDetailsIT gig={gig} /> */}
                      {gig?.JobOrders_Skills &&
                      gig?.JobOrders_Skills?.length > 0 ? (
                        <SkillDensityPieChart
                          jobdetailsIdentifer="jobdetailsIdentifer"
                          jobdetails={PieChartStructure(
                            gig?.JobOrders_Skills,
                            "jobdetails"
                          )}
                        />
                      ) : null}
                      {gig?.Industry && gig?.Industry?.length > 0 ? (
                        <SkillDensityPieChart
                          IndustryIdentifer="IndustryIdentifer"
                          jobdetails={PieChartStructure(
                            gig?.Industry,
                            "jobdetails"
                          )}
                        />
                      ): null}
                      <div ref={myRef} />
                    </div>
                  }
                  <Divider className="divider" />
                  <div className={`bottom-details ${FloatingApplyButton}`}>
                    {/* <div className="job-details-bid">
                      <div className="package-rate">
                        {budgetTypeQuery === "Hourly" ? (
                          <>
                            <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</div>
                          </>
                        ) : (
                          <>
                            <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/WK</div>
                          </>
                        )}
                      </div>
                      <div className="pay package-text">
                        {ProfessionCode === "HC"
                          ? "Pay package"
                          : "Estimated pay package"}
                      </div>
                    </div> */}
                    <div
                      className={`bid-details-apply ${
                        isExpiredGigs ? "is-expired-gigs" : ""
                      } `}
                      onClick={() => {
                        if (!isExpiredGigs) {
                          handleApply(gig);
                        }
                      }}
                    >
                      <div>{gig?.NewProposals?.[0]?.JobOrderId == jobid && gig?.NewProposals?.[0]?.BidStatusId == 1 && !isExpiredGigs ? "Edit bid" : "Place a bid"}</div>
                    </div>
                  </div>
                  <Divider className="divider" />
                </div>
              </div>
            </>
          ) : (
            <Loader hieght="40vw" />
          )}
        </>
      ) : null}
      {/* DESKTOP */}
      {dimensions > 769 ? (
        <>
          {gig ? (
            <>
              <div className="gig-detail-container">
                <div className="header-container">
                  {/* <div className="g-details-header">
                    {retractedBid12 ? (
                      <div onClick={Healthcare} className="header-text">
                        Healthcare
                      </div>
                    ) : (
                      <div
                        onClick={() => history.goBack()}
                        className="header-text23"
                      >
                        {" "}
                        {gig?.Title}
                      </div>
                    )}
                    <div className="g-details-arrow">
                      <RightOutlined className="arrow" />
                    </div>
                    {retractedBid12 ? (
                      <div onClick={retractedBidClick} className="header-text">
                        Retracted bids
                      </div>
                    ) : (
                      <div className="header-text"> Gig detail</div>
                    )}
                    <div className="g-detail-jb-title"></div>
                  </div> */}
                  <div
                    className={
                      ProfessionCode !== "IT" ? "job-title" : "job-title"
                    }
                  >
                    {gig?.Title}
                  </div>
                  <div className="g-details-facility-container">
                    <div className="g-details-facility-left">
                      <div className="g-details-facility-name">
                        {/* {ProfessionCode === "HC" && ( */}
                        <div className="g-hospitadl-icon">
                          <FaRegHospital className="g-hospital-icon" />{" "}
                        </div>
                        {/* )} */}
                        <div>
                          {ProfessionCode !== "IT"
                            ? gig?.FacilityName
                            : gig?.companyName}
                        </div>
                      </div>
                      {/* {ProfessionCode === "HC" && ( */}
                      <div className="right">
                        <div className="g-star-rating">
                          <Rate
                            allowHalf
                            value={
                              gig?.facilityRating?.rating
                                ? Number(gig?.facilityRating?.rating)
                                : 3.5
                            }
                            disabled={true}
                          />
                        </div>
                        <div style={{ color: "#fadb14" }}>
                          {gig?.facilityRating?.rating
                            ? Number(gig?.facilityRating?.rating)
                            : 3.5}
                        </div>
                        <div>
                          {/* <div>({gig?.Review})</div> */}
                          <div style={{ color: "#7e7e7e" }}>
                            {gig?.facilityRating?.reviews &&
                              "(" + gig?.facilityRating?.reviews + ")"}
                          </div>
                          {/* <div>{moment(time).fromNow()}</div> */}
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                    <div
                      className={
                        ProfessionCode !== "IT" || ProfessionCode === "HC"
                          ? "g-details-facility-right"
                          : "g-details-facility-right g-details-facility-right-it"
                      }
                    >
                      {!isExpiredGigs && <div
                        className="div1"
                        onClick={
                          authTokenValue === null
                            ? (e) => onHeartClick(e)
                            : (e) => IsFavLocalvalueUpdate(e)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Rate
                          character={
                            <HeartFilled
                              className={
                                IsFavoriteCheck ? "IsFavoriteCheck" : ""
                              }
                            />
                          }
                          className="heart"
                          count={1}
                          allowClear
                          disabled={authTokenValue === null ? true : false}
                          value={IsFavoriteCheck ? 1 : 0}
                          style={{
                            margin: "-6px 0px 0px 0px",
                            fontSize: "16px",
                            color: "#ff4343",
                          }}
                        />
                       
                      </div>}
                      <div className="div2">
                        <img
                          src={Share}
                          alt=""
                          className="share"
                          onClick={showModal}
                          // onClick={onRateClick}
                        />
                        {/* <Share onClick={onRateClick} /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  // className={
                  //   ProfessionCode === "IT" || ProfessionCode === "HC"
                  //     ? "GigDetail-containerIT"
                  //     : "GigDetail-containerHC"
                  // }
                  className="GigDetail-containerIT"
                >
                  <div
                    // className={`content-container ${
                    //   ProfessionCode === "IT" || ProfessionCode === "HC" && "content-containerIT"
                    // }`}
                    className="content-container content-containerIT"
                  >
                    <div
                      className="ractangle-left"
                      // style={{ background: `url(${cardImage})` }}
                    >
                      <div className="bid-details-container">
                        {/* <div className="bid-details"> */}
                        {/* <div>
                      ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                    </div> */}

                        {/* {budgetTypeQuery === "Hourly" ? (
                          <>
                            <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</div>
                          </>
                        ) : (
                          <>
                            <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/WK</div>
                          </>
                        )}
                        <div>
                          {ProfessionCode === "HC"
                            ? "Pay package"
                            : "Estimated pay package"}
                        </div>
                      </div> */}
                        <div className="location-details">
                          <div>{location}</div>
                        </div>
                      </div>
                      {/* <div className="bid-details">
                  <div>
                    ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                  </div>
                  <div>Pay Package</div>
                </div>
                <div className="location-details">
                  <div>{gig?.Location?.LocationLong}</div>
                </div> */}
                      {placeholder ? (
                        <img
                          // src={getImageByStateCode(
                          //   gig?.Countries?.CountryName !== "United States"
                          //     ? "TX"
                          //     : gig?.Regions?.Code
                          //   // gig?.Cities?.CityName
                          // )}
                          src={
                            Defaultplaceholder
                              ? getImageByStateCode(gig?.Regions?.Code,'state')
                              : getImageByStateCode(gig?.Regions?.Code,'state')
                          }
                          className="city-image"
                          onError={(e) => setDefaultplaceholder(true)}
                        />
                      ) : (
                        <img
                          src={getImageByStateCode(gig?.Regions?.Code,'state')}
                          className="city-image"
                          onError={(e) => showPlaceholder(true)}
                        />
                      )}
                    </div>
                    <GigDetailAboutSection
                      gig={gig}
                      location={location}
                      ProfessionCode={ProfessionCode}
                      expday={expday}
                      expmonth={expmonth}
                      expdate={expdate}
                      expyear={expyear}
                      day={day}
                      month={month}
                      date={date}
                      year={year}
                      dimensions={dimensions}
                      handleApply={handleApply}
                    />
                    {ProfessionCode === "IT" ? (
                      <GigJobDetailsITUser Description={gig?.Description} />
                    ) : null}
                  </div>
                  {
                    <div className="Gigdetails-IT-User">
                      {isExpiredGigs && (
                        <ExpiredGigs
                          expday={expday}
                          expmonth={expmonth}
                          expdate={expdate}
                          expyear={expyear}
                        />
                      )}
                      <OpenPositions gig={gig} />
                      <GigExpiresIT gig={gig} />
                      {/* <StatsJobDetailsIT gig={gig} /> */}
                      {gig?.JobOrders_Skills ? (
                        <SkillDensityPieChart
                          jobdetailsIdentifer="jobdetailsIdentifer"
                          jobdetails={PieChartStructure(
                            gig?.JobOrders_Skills,
                            "jobdetails"
                          )}
                        />
                      ) : null}
                      {gig?.Industry && gig?.Industry?.length ? (
                        <SkillDensityPieChart
                          IndustryIdentifer="IndustryIdentifer"
                          jobdetails={PieChartStructure(
                            gig?.Industry,
                            "jobdetails"
                          )}
                        />
                      ) : null}
                      <CreateBidButtonIT
                        gig={gig}
                        handleApply={handleApply}
                        isExpiredGigs={isExpiredGigs}
                        isEdit={gig?.NewProposals?.[0]?.JobOrderId == jobid && gig?.NewProposals?.[0]?.BidStatusId == 1}
                      />
                    </div>
                  }
                </div>
                {/* <div
              className="bid-details-apply"
              onClick={() => history.push("/create-bid")}
            >
              Bid to Apply
            </div> */}
              </div>

              {isModalVisibleAuth2 && gig && (
                <Modal
                  className="share-bid-model23"
                  width={800}
                  visible={isModalVisibleAuth2}
                  footer={null}
                  // style={{ top: 45, padding: 0 }}
                  onCancel={modalCancel}
                  centered={true}
                >
                  <div className="PopOver-on-shareBid">
                    <div className="PopOver-on-shareBid-job">
                      Share this gig
                    </div>
                    <div className="PopOver-on-shareBid-content">
                      Increase your chances of connecting with the best
                      candidate for your gig
                    </div>
                    <div className="PopOver-on-shareBid-main">
                      <div className="PopOver-on-shareBid-box-internal">
                        <FacebookShareButton
                          url={window.location.href}
                          quote="Join me on SkillGigs and get 10% off! I'll get a special
                          discount too, so it's a win-win!"
                          hashtag={"#skillgigs"}
                          className="shareButton"
                        >
                          <img
                            src={facebook}
                            alt="facebook"
                            className="PopOver-on-shareBid-inside-image"
                          />
                          <div className="PopOver-on-shareBid-inside-content">
                            Facebook
                          </div>
                        </FacebookShareButton>
                      </div>
                      <div className="PopOver-on-shareBid-box-internal">
                        <LinkedinShareButton
                          url={window.location.href}
                          title={gig?.Title ? gig.Title : "NA"}
                          summary={gig?.Description ? gig.Description : "NA"}
                          source={window.location.href}
                          className="shareButton"
                        >
                          <img
                            src={linkedinn}
                            alt="linkedin"
                            className="PopOver-on-shareBid-inside-image"
                            // className="play_button"
                          />
                          <div className="PopOver-on-shareBid-inside-content">
                            LinkedIn
                          </div>
                        </LinkedinShareButton>
                      </div>
                      <div className="PopOver-on-shareBid-box-internal">
                        <TwitterShareButton
                          title={gig?.Title ? gig.Title : "NA"}
                          url={window.location.href}
                          hashtag={["skillgigs"]}
                          className="shareButton"
                        >
                          <img
                            src={twitter}
                            alt="twitter"
                            className="PopOver-on-shareBid-inside-image"
                          />
                          <div className="PopOver-on-shareBid-inside-content">
                            Twitter
                          </div>
                        </TwitterShareButton>
                      </div>
                      <div
                        className={`PopOver-on-shareBid-box-internal shareButton ${
                          copied ? "copyLink" : ""
                        }`}
                        onClick={copy}
                      >
                        {!copied ? (
                          <>
                            <img
                              src={copyLink}
                              alt="copyLink"
                              className="PopOver-on-shareBid-inside-image"
                            />
                            <div className="PopOver-on-shareBid-inside-content">
                              Copy link
                            </div>
                          </>
                        ) : (
                          <>
                            <img
                              src={copyLinks2}
                              alt="play_button2"
                              // className="play_button"
                              className="PopOver-on-shareBid-inside-image"
                            />
                            <div className="PopOver-on-shareBid-inside-content2">
                              Link copied
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </>
          ) : (
            <Loader hieght="40vw" />
          )}
        </>
      ) : null}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    // GetGigs: (params) => {
    //   dispatch(fetchGigs(params)); // action dispatched
    // },
    // SearchValue
    searchValue: (params) => {
      dispatch(SearchValue(params)); // action dispatched
    },
    setIsModalVisibleAuth: (params) => {
      dispatch(isModalVisibleAuth(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(GuestGigDetails);

// export default GuestGigDetails;
