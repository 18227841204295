import React, { useState, useEffect } from "react";

import { Form, Select, Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCollegeMasterEducationHistory,
  fetchCourseMasterEducationHistory,
  fetchDegreeMasterEducationHistory,
  postEducationHistory,
  updateEducationHistory,
} from "../../../../redux/actions";
import { debounce } from "../../../../shared/helpers";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";

const EducationHistoryForm = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id, SchoolName, CourseType, CourseName, YearCompleted } = props;
  const { formSubmitRef, addState, setAddState, skillListingDetailPage } =
    props;
  const { Option } = Select;

  useUpdateLogger("ref", formSubmitRef);

  const [university, setUniversity] = useState({});
  const [noDataViewUniversity, setNoDataViewUniversity] = useState("");

  const [degree, setDegree] = useState("");
  const [noDataViewDegree, setNoDataViewDegree] = useState("");

  const [course, setCourse] = useState("");
  const [noDataViewCourse, setNoDataViewCourse] = useState("");

  const [yearsData, setYearsData] = useState([]);
  const [year, setYear] = useState(null);

  const [checkUpdate, setCheckUpdate] = useState(false);

  const [dimensions, setDimensions] = useState(window.innerWidth);

  const loading = useSelector((state) => state.profileReducer.loading);

  const schoolColleges = useSelector(
    (state) => state.profileReducer.schoolEducationHistory
  );

  const degreeEducationHistory = useSelector(
    (state) => state.profileReducer.degreeEducationHistory
  );

  const courseEducationHistory = useSelector(
    (state) => state.profileReducer.courseEducationHistory
  );

  const onUniversityChange = (val) => {
    const obj = JSON.parse(val);
    setUniversity(obj);
  };

  const onDegreeChange = (val) => {
    const obj = JSON.parse(val);
    setDegree(obj);
  };

  const onCourseChange = (val) => {
    const obj = JSON.parse(val);
    setCourse(obj);
  };

  const onFinish = () => {
    if (id) {
      const updatedData = {
        Schools: university?._id ? university : SchoolName,
        Courses: degree?._id ? degree : CourseName,
        CourseTypes: course?._id ? course : CourseType,
        YearCompleted: year || YearCompleted,
      };
      dispatch(updateEducationHistory(updatedData, id));
      return;
    }

    const data = {
      Schools: university,
      CourseTypes: course,
      Courses: degree,
      YearCompleted: year,
    };
    dispatch(postEducationHistory(data));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    // disable and enable the submit button
    if (id && (university || degree || course || year)) {
      setCheckUpdate(true);
    }

    if (
      id &&
      (university || SchoolName) &&
      (degree || CourseName) &&
      (course || CourseType) &&
      (year || YearCompleted) &&
      checkUpdate
    ) {
      return setAddState(true);
    }

    if (university && degree && course && year) {
      return setAddState(true);
    }

    setAddState(false);
  }, [university, degree, course, year, checkUpdate]);

  const onSearchSchool = (val) => {
    setNoDataViewUniversity(val);
    dispatch(fetchCollegeMasterEducationHistory(val));
  };

  const onSearchDegree = (val) => {
    setNoDataViewDegree(val);
    dispatch(fetchDegreeMasterEducationHistory(val));
  };

  const onSearchCourse = (val) => {
    setNoDataViewCourse(val);
    dispatch(fetchCourseMasterEducationHistory(val));
  };

  const generateArrayOfYears = () => {
    const max = new Date().getFullYear();
    const min = 1970;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    setYearsData(years.reverse());
  };

  useEffect(() => {
    generateArrayOfYears();
  }, []);

  let defaultValues;

  useEffect(() => {
    defaultValues = {
      School: SchoolName?.SchoolName,
      Degree: CourseName?.CourseName,
      Course: CourseType?.CourseTypeName,
      Year: YearCompleted,
    };

    // console.log("edu:defaultValues", defaultValues);

    form.setFieldsValue(defaultValues);
  }, [id]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={defaultValues}
        autoComplete="off"
      >
        {skillListingDetailPage && dimensions > 768 ? (
          <>
            <Form.Item>
              <Form.Item name="School">
                <Select
                  notFoundContent={
                    noDataViewUniversity?.length > 0 ? (
                      <div>
                        {loading || schoolColleges?.length > 0 ? (
                          <Spin size="small" />
                        ) : (
                          "No data found!"
                        )}
                      </div>
                    ) : null
                  }
                  name="School"
                  className="selectEducationHistory"
                  placeholder="School/University Name"
                  onSearch={debounce((val) => onSearchSchool(val))}
                  onChange={onUniversityChange}
                  showArrow={false}
                  showSearch
                >
                  {schoolColleges?.length > 0 &&
                    schoolColleges.map((school) => (
                      <Option
                        value={JSON.stringify(school)}
                        key={school.SchoolId}
                      >
                        {school._id}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="Degree">
                <Select
                  notFoundContent={
                    noDataViewDegree?.length > 0 ? (
                      <div>
                        {loading || degreeEducationHistory?.length > 0 ? (
                          <Spin size="small" />
                        ) : (
                          "No data found!"
                        )}
                      </div>
                    ) : null
                  }
                  name="Degree"
                  className="selectEducationHistory"
                  placeholder="Degree"
                  onSearch={debounce((val) => onSearchDegree(val))}
                  onChange={onDegreeChange}
                  showArrow={false}
                  showSearch
                >
                  {degreeEducationHistory?.length > 0 &&
                    degreeEducationHistory.map((degree) => (
                      <Option
                        value={JSON.stringify(degree)}
                        key={degree?.CourseId}
                      >
                        {degree?._id}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item name="Course">
                <Select
                  notFoundContent={
                    noDataViewCourse?.length > 0 ? (
                      <div>
                        {loading || courseEducationHistory?.length > 0 ? (
                          <Spin size="small" />
                        ) : (
                          "No data found!"
                        )}
                      </div>
                    ) : null
                  }
                  name="Course"
                  className="selectEducationHistory"
                  placeholder="Course/Major"
                  onSearch={debounce((val) => onSearchCourse(val))}
                  onChange={onCourseChange}
                  showArrow={false}
                  showSearch
                >
                  {courseEducationHistory?.length > 0 &&
                    courseEducationHistory.map((course) => (
                      <Option value={JSON.stringify(course)} key={course}>
                        {course._id}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="Year">
                <Select
                  name="Year"
                  className="selectEducationHistory"
                  placeholder="Year completed"
                  onChange={(val) => setYear(val)}
                >
                  {yearsData?.length > 0 &&
                    yearsData.map((year) => <Option key={year}>{year}</Option>)}
                </Select>
              </Form.Item>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item name="School">
              <Select
                notFoundContent={
                  noDataViewUniversity?.length > 0 ? (
                    <div>
                      {loading || schoolColleges?.length > 0 ? (
                        <Spin size="small" />
                      ) : (
                        "No data found!"
                      )}
                    </div>
                  ) : null
                }
                name="School"
                className="selectEducationHistory"
                placeholder="School/University Name"
                onSearch={debounce((val) => onSearchSchool(val))}
                onChange={onUniversityChange}
                showArrow={false}
                showSearch
              >
                {schoolColleges?.length > 0 &&
                  schoolColleges.map((school) => (
                    <Option
                      value={JSON.stringify(school)}
                      key={school.SchoolId}
                    >
                      {school._id}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item name="Degree">
              <Select
                notFoundContent={
                  noDataViewDegree?.length > 0 ? (
                    <div>
                      {loading || degreeEducationHistory?.length > 0 ? (
                        <Spin size="small" />
                      ) : (
                        "No data found!"
                      )}
                    </div>
                  ) : null
                }
                name="Degree"
                className="selectEducationHistory"
                placeholder="Degree"
                onSearch={debounce((val) => onSearchDegree(val))}
                onChange={onDegreeChange}
                showArrow={false}
                showSearch
              >
                {degreeEducationHistory?.length > 0 &&
                  degreeEducationHistory.map((degree) => (
                    <Option
                      value={JSON.stringify(degree)}
                      key={degree?.CourseId}
                    >
                      {degree?._id}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item name="Course">
              <Select
                notFoundContent={
                  noDataViewCourse?.length > 0 ? (
                    <div>
                      {loading || courseEducationHistory?.length > 0 ? (
                        <Spin size="small" />
                      ) : (
                        "No data found!"
                      )}
                    </div>
                  ) : null
                }
                name="Course"
                className="selectEducationHistory"
                placeholder="Course/Major"
                onSearch={debounce((val) => onSearchCourse(val))}
                onChange={onCourseChange}
                showArrow={false}
                showSearch
              >
                {courseEducationHistory?.length > 0 &&
                  courseEducationHistory.map((course) => (
                    <Option value={JSON.stringify(course)} key={course}>
                      {course._id}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item name="Year">
              <Select
                name="Year"
                className="selectEducationHistory"
                placeholder="Year completed"
                onChange={(val) => setYear(val)}
              >
                {yearsData?.length > 0 &&
                  yearsData.map((year) => <Option key={year}>{year}</Option>)}
              </Select>
            </Form.Item>
          </>
        )}

        <Form.Item style={{ display: "none" }}>
          <Button
            ref={formSubmitRef}
            type="primary"
            htmlType="submit"
            disabled={!addState}
          >
            submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EducationHistoryForm;
