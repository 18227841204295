import React, { useState, useEffect } from "react";
import { Input, Button, Col, Alert, DatePicker, Row, Spin } from "antd";
import { FileSyncOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TimeSheet from "../TimeSheet";
import "./Emptylist.css";
import browser from "../../../assets/img/browser.svg";
const Emptylist = (props) => {
  // const [list,setList]= useState(props.list);
  return (
    <Col lg={20} xs={24} sm={22} className={"empty-container"}>
      <h3>
        <strong>Your Timesheet is Empty</strong>
      </h3>
      <span>
        Oops! Looks like your Timesheet is Empty!
        <br />
        Start your day to Continue to the Timesheet page
      </span>
      <br />
      {/* <FileSyncOutlined className={'empty'}/> */}
      <img src={browser} alt="Browser Logo"></img>
    </Col>
  );
};
export default Emptylist;
