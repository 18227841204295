import React, { useState, useEffect, useRef } from "react";
// antd
import {
  Menu,
  Select,
  Space,
  Dropdown,
  Button,
  message,
  Radio,
  Tag,
  Switch,
  Pagination,
  Input,
  InputNumber,
  Spin,
  Divider,
  Form,
  Checkbox,
  Drawer,
} from "antd";
// APPOLO CLIENT
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
  useLazyQuery,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { AiOutlineClose } from "react-icons/ai";
// ACTIONS
import {
  fetchGigs,
  SearchResponse,
  SearchResponseRequested,
  SearchValue,
  fetchSearchSkill,
  saveSearchLocation,
  SearchSuggestions,
  UpdateSearchFilter,
  onClearFilterInit,
  updateProfile,
  fecthIsLocation,
  catchError,
  fetchKeySearchCountry,
  fetchKeySearchRegion,
  fetchKeySearchCity,
  fetchKeyExperinceLevels,
} from "../../redux/actions";
import { HttpLink } from "apollo-link-http";
// ICONS
import {
  DownOutlined,
  SearchOutlined,
  UserAddOutlined,
  UserOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";

// CSS
import "./SearchResults.css";

// ICONS
import { ImPlus } from "react-icons/im";
import { FaBolt } from "react-icons/fa";
import { GiRoundStar } from "react-icons/gi";
import { ImDisplay } from "react-icons/im";
import { AiFillFile } from "react-icons/ai";
import { FaCoffee } from "react-icons/fa";
import { AiOutlineDollar } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import { BsArrowDownUp } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import { debounce, timeout } from "../../shared/helpers";

// ANTD
import { Progress } from "antd";

import GigsCard from "./GigsCard";
import SearchGigs from "./SearchGigs";

import { connect, useDispatch, useSelector } from "react-redux";

// redux Actions
import {
  fetchUserSkillListing,
  saveSearchSkill,
  fetchKeySearchLocation,
} from "../../redux/actions";
import MediaQuery from "react-responsive";
import SearchBar from "./SearchBar";
import { useHistory, useParams } from "react-router-dom";
import { radio } from "aws-amplify";
import MobileFilters from "./components/MobileFilters";
import GuestNoSearchResult from "./GuestUser/GuestNoSearchResult";
import Loader from "../../components/Loader/Loader";
import { GetQueryParams } from "../../assets/helpers";

export function decodeURIComponentSafe(s) {
  if (!s) {
    return s;
  }
  return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, "%25"));
}

const SearchResults = (props) => {
  const dispatch = useDispatch();
  const [gigs, setGigs] = useState(null);
  const [gigSuggestions, setGigSuggestions] = useState({});
  const search = props.value;
  const history = useHistory();
  // const [search, setSearch] = useState(props.value);

  const [noDataView, setNoDataView] = useState("");
  const [jobValue, setJobValue] = useState([]);
  const [ProfessionValue, setProfessionValue] = useState("All");
  const [expLevelValue, setExpLevelValue] = useState("");
  const [WorkplacePolicyValue, setWorkplacePolicyValue] = useState();
  const [skillsinput, setSkillsInput] = useState();
  const [locationValue, setLocationValue] = useState();
  const [skillValue, setSkillValue] = useState();
  const [sortValueMobile, setSortValueMobile] = useState("Most Relevant");
  const sortValue = props.sortValue;
  const [budgetMin, setBudgetMin] = useState(props.minbudgetQuery);
  const [budgetMax, setBudgetMax] = useState(props.maxbudgetQuery);
  const [budgetType, setBudgetType] = useState();
  const [dimensions, setDimensions] = useState(window.innerWidth);
  // VISIBLE
  const [visibleJobMenu, setVisibleJobMenu] = useState(false);
  const [visibleSkillMenu, setVisibleSkillMenu] = useState(false);
  const [visibleLocationMenu, setVisibleLocationMenu] = useState(false);
  const [visibleCountryMenu, setVisibleCountryMenu] = useState(false);
  const [visibleRegionMenu, setVisibleRegionMenu] = useState(false);
  const [visibleCityMenu, setVisibleCityMenu] = useState(false);
  const [visibleBudgetMenu, setVisibleBudgetMenu] = useState(false);
  const [visibleExpLevelMenu, setVisibleExpLevelMenu] = useState(false);
  const [visibleProfessionMenu, setVisibleProfessionMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  const [citiesInput, setCitiesInput] = useState([]);
  const [regionsInput, setRegionsInput] = useState([]);
  const [countriesInput, setCountriesInput] = useState([]);
  const [HCtotalCount, setHCtotalCount] = useState(0);
  const [ITtotalCount, setITtotalCount] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  // const [filterDrawer, setFilterDrawer] = useState(false);
  // const [sortDrawer, setSortDrawer] = useState(false);

  // FILTER VALUE FOR QUERY
  const [location, setLocation] = useState([]);
  // const [jobQuery, setjobQuery] = useState();
  const { jobQuery } = props;
  const skillQuery = props.skillQuery;
  const locationQuery = props.locationQuery;
  const countryQuery = props.countryQuery;
  const regionQuery = props.regionQuery;
  const cityQuery = props.cityQuery;
  const explevelQuery = props.explevelQuery;
  const minbudgetQuery = props.minbudgetQuery;
  const maxbudgetQuery = props.maxbudgetQuery;
  const budgetQuery = props.budgetQuery;
  const budgetTypeQuery = props.budgetTypeQuery;
  const pageNo = props.pageNo;
  const filterDrawer = props.filterDrawer;
  const sortDrawer = props.sortDrawer;
  const activeFilters = props.activeFilters;
  const WorkplacePolicy = props.WorkplacePolicy;
  const TrendingLocSearch = props.TrendingLocSearch;
  const TrendingPlaceName = props.TrendingPlaceName;
  const ProfessionCodeValue = props.ProfessionCodeValue;
  const ProfessionCodeFilterCount = props.ProfessionCodeFilterCount;
  const [sortingQuery, setsortingQuery] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [resultCount, setresultCount] = useState(0);
  const [myLocationQuery, setMyLocationQuery] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [validation, setValidation] = useState(false);
  const inputref = useRef(0);
  const [isClear, setIsClear] = useState(0);
  const [search_value, setSearch_value] = useState("");
  const [QueryValue, setQueryValue] = useState();
  const [QueryValuePC, setQueryValuePC] = useState();
  const [TrendingLocationQuery, setTrendingLocationQuery] = useState(null);
  let { searchValue } = useParams();
  if (!searchValue) {
    searchValue = " ";
  }
  const ProfessionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );
  const query = gql`
    query (
      $_sort: _SORTING
      $_page: Int
      $_perPage: Int
      $BudgetMax: String
      $BudgetMin: String
      $all: String
      $ProfessionCode: String
      $Skills: [String]
      $JobOrderTypeName: [String]
      $NearBy: NearByInput
      $ShowRateIn: _SHOW_RATE
      $FilterBudget: _SHOW_RATE
      $WorkplacePolicy: WorkplacePolicyInput
    ) {
      gigs(
        _sort: $_sort
        _page: $_page
        _perPage: $_perPage
        BudgetMax: $BudgetMax
        BudgetMin: $BudgetMin
        all: $all
        Skills: $Skills
        JobOrderTypeName: $JobOrderTypeName
        NearBy: $NearBy
        ProfessionCode: $ProfessionCode
        ShowRateIn: $ShowRateIn
        FilterBudget: $FilterBudget
        WorkplacePolicy: $WorkplacePolicy
      ) {
        aggregate {
          count
          totalCount
          professionCodeCount {
            code
            count
          }
        }
        suggestion
        data {
          _id
          JobOrderId
          ProfileId
          Title
          Description
          ZipCode
          OnSite
          FixedPrice
          Rate
          ExpiryDate
          Created
          CreatedBy
          Modified
          ModifiedBy
          IsActive
          Budget
          IdealTermsId
          BudgetRangeId
          ReasonId
          BudgetMin
          BudgetMax
          VisaStatusId
          SuggestionsFrequency
          SuggestionsSent
          InternalTitleId
          ImportSource
          ExternalGigId
          ImportProcessStatus
          FacilityName
          AutoLoaded
          SourceName
          SourceId
          CityName
          RegionCode
          score
          Specialty
          Shift
          WorkplacePolicy
          VMSLink
          Duration
          companyName
          JobOrders_Skills {
            Id
            SkillPercent
            Skills {
              SkillId
              SkillCategoryId
              SkillName
              SkillCode
              SkillNameLowerCase
            }
          }
          JobOrders_VisaStatuses {
            VisaStatusId
          }
          InternalGigTypes {
            InternalGigTypeId
            InternalGigTypeCode
            InternalGigTypeName
            InternalGigTypeDescription
            Created
            CreatedBy
            Modified
            ModifiedBy
          }
          IdealContractLength {
            IdealContractLengthId
            IdealContractLengthName
            IdealContractLengthValue
            Created
            CreatedBy
            Modified
            ModifiedBy
          }
          Professions {
            ProfessionId
            ProfessionCode
            ProfessionName
            ProfessionDescription
            Created
            CreatedBy
            Modified
            ModifiedBy
          }
          ExperienceLevels {
            ExperienceLevelId
            ExperienceLevelCode
            ExperienceLevelName
            MinVal
            MaxVal
            SortOrder
            Created
            CreatedBy
            Modified
            ModifiedBy
          }
          JobOrderTypes {
            JobOrderTypeId
            JobOrderTypeName
            JobOrderTypeCode
            Created
            CreatedBy
            Modified
            ModifiedBy
          }
          Countries {
            CountryId
            CountryName
            FIPS104
            ISO2
            ISO3
            ISON
            Internet
            Capital
            MapReference
            NationalitySingular
            NationalityPlural
            Currency
            CurrencyCode
            Population
            Title
            Comment
          }
          Regions {
            RegionId
            CountryId
            RegionName
            Code
            ADM1Code
            TimeZone
          }
          Cities {
            CityId
            CountryId
            RegionId
            CityName
            Longitude
            Latitude
            TimeZone
            CityCode
          }
          BudgetTypes {
            BudgetTypeId
            BudgetTypeName
            BudgetTypeCode
            Created
            CreatedBy
            Modified
            ModifiedBy
            Order
          }
          JobOrderStatuses {
            JobOrderStatusId
            JobOrderStatusName
            JobOrderStatusCode
            Created
            CreatedBy
            Modified
            ModifiedBy
          }
          IsFavorite
          facilityRating {
            rating
            reviews
          }
          Skills {
            SkillName
            SkillPercent
          }
          Location {
            CityId
            CityName
            RegionId
            RegionCode
            LocationLong
            CountryId
            Location
            LocationWithCountry
            Latitude
            Longitude
            RegionName
            CountryName
          }
        }
      }
    }
  `;
  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
  let searchquery = {};
  if (jobQuery) {
    searchquery.JobOrderTypeName = jobQuery;
  }
  if (WorkplacePolicy) {
    searchquery.WorkplacePolicy = WorkplacePolicy;
  }
  if (skillQuery) {
    searchquery.Skills = [skillQuery];
  }
  if (locationQuery) {
    searchquery.CityId = locationQuery?.CityId;
  }
  if (countryQuery?.length) {
    searchquery.countries = countryQuery;
  }
  if (regionQuery?.length) {
    searchquery.regions = regionQuery;
  }
  if (cityQuery?.length) {
    searchquery.cities = cityQuery;
  }
  if (explevelQuery) {
    searchquery.explevel = explevelQuery;
  }
  if (TrendingLocSearch) {
    searchquery.CityId = TrendingLocSearch;
  }
  if (ProfessionCodeValue) {
    searchquery.ProfessionCode = ProfessionCodeValue;
  }
  if (minbudgetQuery) {
    searchquery.BudgetMin = minbudgetQuery;
  }
  if (maxbudgetQuery) {
    searchquery.BudgetMax = maxbudgetQuery;
  }
  if (sortValue === "Most Relevant") {
    searchquery._sort = "RELEVANT";
  }
  if (sortValue === "Most Recent") {
    searchquery._sort = "MOST_RECENT";
  }
  // if (sortValue === "Pay Package High to Low") {
  //   searchquery._sort = "PAY_HI_TO_LOW";
  // }
  // if (sortValue === "Pay Package Low to High") {
  //   searchquery._sort = "PAY_LOW_TO_HI";
  // }
  if (sortValue === "Ending Soon") {
    searchquery._sort = "ENDING";
  }
  if (myLocationQuery) {
    searchquery.NearBy = {
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      maxDistance: 50,
    };
  }
  if (budgetTypeQuery === "Hourly") {
    // console.log("Budget Query", budgetTypeQuery);
    searchquery.ShowRateIn = "HR";
    searchquery.FilterBudget = "HR";
  }
  if (budgetTypeQuery === "Weekly") {
    searchquery.ShowRateIn = "WK";
    searchquery.FilterBudget = "WK";
    // console.log("Budget Query", budgetTypeQuery);
  }
  if (pageNo) {
    searchquery._page = pageNo;
  }
  searchquery._perPage = 24;
  if (window.innerWidth < 768) {
    searchquery._perPage = 20;
  }
  // searchquery.BudgetType = "Hour";

  // if (!props.value) {
  //   searchquery.all = "rn";
  //   hitQuery();
  // }
  searchquery.all = props.value ? props.value : searchValue;
  // searchquery.all = "HI";
  // searchquery.all = searchValue;
  // searchquery.ShowRateIn = "WK";
  // searchquery.FilterBudget = "WK";

  const [hitQuery, { loading, error, data }] = useLazyQuery(query, {
    variables: searchquery,
    fetchPolicy: "no-cache",
  });
  const httpLink = new HttpLink({
    uri: `${BackendApiBaseUrl}/query`,
  });

  const client = new ApolloClient({
    // uri: `${BackendApiBaseUrl}/query`,
    cache: new InMemoryCache(),
    link: ApolloLink.from([httpLink]),
  });

  // DIMENTIONS
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  const getCheckedStatus = (value, filter) => {
    if (filter === "city") {
      return citiesInput.indexOf(value) > -1 ? true : false;
    } else if (filter === "region") {
      return regionsInput.indexOf(value) > -1 ? true : false;
    } else if (filter === "country") {
      return countriesInput.indexOf(value) > -1 ? true : false;
    }
    return false;
  };
  const updateFilterEntryValue = (isChecked, value, filter) => {
    if (filter === "city") {
      const entries = new Set(citiesInput);
      if (isChecked) {
        entries.add(value);
      } else {
        entries.delete(value);
      }
      setCitiesInput([...entries]);
    } else if (filter === "region") {
      const entries = new Set(regionsInput);
      if (isChecked) {
        entries.add(value);
      } else {
        entries.delete(value);
      }
      setRegionsInput([...entries]);
    } else if (filter === "country") {
      const entries = new Set(countriesInput);
      if (isChecked) {
        entries.add(value);
      } else {
        entries.delete(value);
      }
      setCountriesInput([...entries]);
    }
  };
  useEffect(() => {
    error && dispatch(catchError(error));
  }, [error]);

  useEffect(() => {
    setQueryValue(GetQueryParams());
    window.addEventListener("resize", checkDimensions);
    if (props.value > 0) {
      debounce(hitQuery());
    }
    // if (props.value.length > 0) {
    //   props.SearchResponseRequested(loading);
    // }
  }, []);
  useEffect(() => {
    {
      QueryValue?.jt && onApply([QueryValue?.jt], "jobMenu");
    }
    {
      QueryValue?.sl && onApply([QueryValue?.sl], "skillMenu");
    }
    if (QueryValue?.loc) {
      setLocationValue(QueryValue?.loc?.split(",")?.join(", "));
      onLocationSearch(
        QueryValue?.loc ? QueryValue?.loc?.split(",")?.join(",") : "A"
      );
    }
    if (QueryValue?.sl) {
      onSkillSearch(QueryValue?.sl);
      setSkillValue(QueryValue?.sl);
    }
    if (QueryValue?.pc) {
      if (QueryValue?.pc?.toUpperCase() === "HC") {
        props.UpdateSearchFilter("ProfessionCodeValue", "HC");
        setQueryValuePC("HC");
        setProfessionValue("HC - Healthcare");
      } else if (QueryValue?.pc?.toUpperCase() === "IT") {
        setQueryValuePC("IT");
        setProfessionValue("IT - Information Technology");
        props.UpdateSearchFilter("ProfessionCodeValue", "IT");
      }
    }
    if (QueryValue?.countries) {
      setCountriesInput(QueryValue?.countries?.replace(/\+/g, " ")?.split(","));
      onApply(
        QueryValue?.countries?.replace(/\+/g, " ")?.split(","),
        "countryMenu"
      );
    }
    if (QueryValue?.regions) {
      setRegionsInput(QueryValue?.regions?.replace(/\+/g, " ")?.split(","));
      onApply(
        QueryValue?.regions?.replace(/\+/g, " ")?.split(","),
        "regionMenu"
      );
    }
    if (QueryValue?.cities) {
      setCitiesInput(QueryValue?.cities?.replace(/\+/g, " ")?.split("~"));
      onApply(QueryValue?.cities?.replace(/\+/g, " ")?.split("~"), "cityMenu");
    }
    if (QueryValue?.explevel) {
      setExpLevelValue(QueryValue?.explevel);
      onApply(QueryValue?.explevel, "expMenu");
    }
    console.log("iii", QueryValue);
  }, [QueryValue]);

  useEffect(() => {
    {
      props.searchedLocation[0]?.Location ===
        QueryValue?.loc?.toString()?.replace(/\+/g, " ") &&
        QueryValue?.loc &&
        onApply(props.searchedLocation[0], "locationMenu");
    }
  }, [props.searchedLocation[0]?.Location, QueryValue?.loc]);

  useEffect(() => {
    if (QueryValue?.tj) {
      props.UpdateSearchFilter("TrendingLocSearch", parseInt(QueryValue?.tj));
    }
    if (QueryValue?.tl) {
      props.UpdateSearchFilter(
        "TrendingPlaceName",
        QueryValue?.tl?.tl?.toString()?.replace(/\+/g, " ")
      );
    }
  }, [QueryValue?.tj, QueryValue?.tl]);

  useEffect(() => {
    if (GetQueryParams()?.tl) {
      setTrendingLocationQuery(
        GetQueryParams()?.tl?.toString()?.replace(/\+/g, " ")
      );
    } else if (TrendingPlaceName) {
      setTrendingLocationQuery(TrendingPlaceName);
    }
  }, [TrendingLocSearch, GetQueryParams()?.tl, TrendingPlaceName]);

  useEffect(() => {
    if (!visibleJobMenu && !jobQuery) {
      setValidation();
      setJobValue([]);
    }
    if (!visibleSkillMenu && !skillQuery) {
      setValidation();
      setSkillValue();
    }
    if (!visibleLocationMenu && !locationQuery) {
      setValidation();
      setLocationValue();
    }
    if (!visibleExpLevelMenu && !expLevelValue) {
      setValidation();
      setExpLevelValue();
    }
    if (!visibleCityMenu && !citiesInput) {
      setValidation();
      setCitiesInput();
    }
    if (!visibleRegionMenu && !regionsInput) {
      setValidation();
      setCitiesInput();
    }
    if (!visibleCountryMenu && !countriesInput) {
      setValidation();
      setCountriesInput();
    }
    if (
      !visibleBudgetMenu &&
      !budgetTypeQuery &&
      !minbudgetQuery &&
      !maxbudgetQuery
    ) {
      setValidation();
      setBudgetType(null);
      setBudgetMin(null);
      setBudgetMax(null);
    }
  }, [
    visibleBudgetMenu,
    visibleJobMenu,
    visibleSkillMenu,
    visibleLocationMenu,
    visibleRegionMenu,
    visibleCityMenu,
  ]);

  useEffect(() => {
    props.searchResponse(data);
    // // console.log("Gigs: ", user);
  }, [data, props.value, loading]);

  useEffect(() => {
    // console.log("Gigs", props.gigs?.gigs?.gigs?.suggestion);
    setGigSuggestions(props.gigs?.gigs?.gigs?.suggestion);
    setresultCount(resultCount + 1);
  }, [props.gigs]);

  useEffect(() => {
    // console.log("validation", validation);
  }, [validation]);
  // useEffect(() => {
  //   debounce(hitQuery());
  //   // saving loading state to reducer from graphQL
  //   if (props.value.length > 0) {
  //     props.SearchResponseRequested(loading);
  //   }
  //   // else if (search.length === 0) {
  //   //   history.go(-1);
  //   // }
  //   // console.log("Search Value from Saga: ", props.value);
  // }, [props.value]);
  useEffect(() => {
    hitQuery();
  }, [props.IsFavoritesValue]);

  useEffect(() => {
    // console.log("ClearAllClearAll3", locationQuery);
    debounce(hitQuery());
    if (props.value.length > 0) {
      props.SearchResponseRequested(loading);
    }
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        // console.log("Location Latitude is :", position.coords.latitude);
        // console.log("Location Longitude is :", position.coords.longitude);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        // setIsLocation(false);
        dispatch(
          fecthIsLocation({
            IsLocation: false,
            latitude: position.coords.latitude,
          })
        );
      });
    }

    // COUNT APPLIED FILTERS
    if (
      jobQuery ||
      skillQuery ||
      locationQuery ||
      (budgetTypeQuery &&
        (minbudgetQuery > 0 ||
          maxbudgetQuery > 0 ||
          (minbudgetQuery > 0 &&
            maxbudgetQuery > 0 &&
            maxbudgetQuery > minbudgetQuery)))
    ) {
      props.UpdateSearchFilter("activeFilters", 1);
    }
    if (
      (jobQuery &&
        (locationQuery ||
          skillQuery ||
          (budgetTypeQuery && (minbudgetQuery || maxbudgetQuery)) ||
          (budgetTypeQuery && minbudgetQuery && maxbudgetQuery))) ||
      (skillQuery &&
        (locationQuery ||
          jobQuery ||
          (budgetTypeQuery && (minbudgetQuery || maxbudgetQuery)) ||
          (budgetTypeQuery && minbudgetQuery && maxbudgetQuery)))
    ) {
      props.UpdateSearchFilter("activeFilters", 2);
    }
    if (
      (jobQuery && skillQuery && locationQuery) ||
      (jobQuery &&
        skillQuery &&
        budgetQuery &&
        (minbudgetQuery ||
          maxbudgetQuery ||
          (minbudgetQuery && maxbudgetQuery))) ||
      (jobQuery &&
        locationQuery &&
        budgetQuery &&
        (minbudgetQuery ||
          maxbudgetQuery ||
          (minbudgetQuery && maxbudgetQuery)))
    ) {
      props.UpdateSearchFilter("activeFilters", 3);
    }
    if (
      jobQuery &&
      budgetQuery &&
      skillQuery &&
      locationQuery &&
      (minbudgetQuery || maxbudgetQuery || (minbudgetQuery && maxbudgetQuery))
    ) {
      props.UpdateSearchFilter("activeFilters", 4);
    }
    if (ProfessionCodeValue) {
      if (ProfessionCode === "All") return;
      if (ProfessionCodeFilterCount) return;
      props.UpdateSearchFilter("activeFilters", activeFilters + 1);
      props.UpdateSearchFilter("ProfessionCodeFilterCount", true);
    }
  }, [
    props.value,
    locationQuery,
    myLocationQuery,
    skillQuery,
    jobQuery,
    minbudgetQuery,
    maxbudgetQuery,
    budgetTypeQuery,
    sortValue,
    pageNo,
    WorkplacePolicy,
    ProfessionCodeValue,
  ]);
  // console.log(props.IsFavoritesValue, "props.IsFavoritesValue");
  //// Search Gigs End//////////

  useEffect(() => {
    setGigs(
      props.gigs?.gigs?.gigs.data?.length > 0 ? props.gigs?.gigs?.gigs.data : []
    );
    // console.log(props.gigs);
  }, [props.gigs]);

  const getStateAfterAddRemoveFavorite = (JobOrderId, IsFavorite) => {
    setGigs((prev) =>
      prev.map((gig) =>
        gig.JobOrderId === JobOrderId ? { ...gig, IsFavorite } : gig
      )
    );
  };

  useEffect(() => {
    // console.log("gigs", gigs);
    // console.log("gigs props", props.gigs?.gigs?.gigs.aggregate?.totalCount);
    // console.log("gigs ", gigSuggestions);
  }, [gigs]);
  // console.log(props.gigs);
  useEffect(() => {}, [props.value]);

  function handleButtonClick(e) {
    message.info("Click on left button.");
    // console.log("click left button", e);
  }

  function handleMenuClick(e) {
    message.info("Click on menu item.");
    // console.log("click", e);
  }

  const { Option } = Select;

  function handleChange(value) {
    // console.log(`selected ${value}`);
  }
  const addQuery = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    history.push({
      search: searchParams.toString(),
    });
  };

  const removeQuery = (key) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    history.push({
      search: searchParams.toString(),
    });
  };

  const onApply = (value, filter) => {
    console.log("data", validation);
    if (jobValue && filter === "jobMenu" && value?.length) {
      setVisibleJobMenu(false);
      setVisibleBudgetMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      setIsFilter(true);
      props.UpdateSearchFilter("jobQuery", value);
      // console.log("query value", value);
      props.UpdateSearchFilter("pageNo", "1");
      setValidation();
      addQuery("jt", value[0]);
    }
    if (filter === "jobMenu" && !value?.length) {
      setValidation("job");
      // setTimeout(() => setValidation(false), 6000);
    }
    if (filter === "skillMenu" && value) {
      setVisibleSkillMenu(false);
      setVisibleJobMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      props.UpdateSearchFilter(
        "skillQuery",
        value?.toString()?.replace(/\+/g, " ")
      );
      setIsFilter(true);
      // console.log("query value", value);
      props.UpdateSearchFilter("pageNo", "1");
      setValidation();
      addQuery("sl", value);
    }

    if (filter === "expMenu" && !value) {
      setValidation("explevel");
    }
    if (filter === "countryMenu" && value?.length === 0) {
      setValidation("countryMenu");
    }
    if (filter === "cityMenu" && value?.length === 0) {
      setValidation("cityMenu");
    }
    if (filter === "regionMenu" && value?.length === 0) {
      setValidation("regionMenu");
    }

    if (filter === "expMenu" && value) {
      setVisibleSkillMenu(false);
      setVisibleJobMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      props.UpdateSearchFilter("explevelQuery", value);
      setIsFilter(true);
      // console.log("query value", value);
      props.UpdateSearchFilter("pageNo", "1");
      setValidation("explevel");
      addQuery("explevel", value);
    }
    if (filter === "skillMenu" && !value) {
      setValidation("skill");
      // setTimeout(() => setValidation(false), 4000);
    }
    if (filter === "expMenu" && !value) {
      setValidation("experinceLevel");
    }
    if (filter === "cityMenu" && !value.length) {
      setValidation("cities");
    }
    if (filter === "regionMenu" && !value.length) {
      setValidation("regions");
    }
    if (filter === "countryMenu" && !value.length) {
      setValidation("countries");
    }
    if (filter === "locationMenu" && value) {
      setVisibleLocationMenu(false);
      setVisibleJobMenu(false);
      setVisibleBudgetMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      props.UpdateSearchFilter("locationQuery", value);
      setIsFilter(true);
      // console.log("query value", value);
      props.UpdateSearchFilter("pageNo", "1");
      addQuery("loc", value?.Location);
      setValidation();
    }
    if (filter === "locationMenu" && !value) {
      setValidation("location");
      // setTimeout(() => setValidation(false), 4000);
    }
    if (filter === "countryMenu" && value && value?.length) {
      setVisibleLocationMenu(false);
      setVisibleJobMenu(false);
      setVisibleBudgetMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      props.UpdateSearchFilter("countryQuery", value);
      setIsFilter(true);
      // console.log("query value", value);
      props.UpdateSearchFilter("pageNo", "1");
      addQuery("countries", value.join(","));
      setValidation();
    }
    if (filter === "regionMenu" && value && value?.length) {
      setVisibleLocationMenu(false);
      setVisibleJobMenu(false);
      setVisibleBudgetMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      props.UpdateSearchFilter("regionQuery", value);
      setIsFilter(true);
      // console.log("query value", value);
      props.UpdateSearchFilter("pageNo", "1");
      addQuery("regions", value.join(","));
      setValidation();
    }
    if (filter === "cityMenu" && value && value?.length) {
      setVisibleLocationMenu(false);
      setVisibleJobMenu(false);
      setVisibleBudgetMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      props.UpdateSearchFilter("cityQuery", value);
      setIsFilter(true);
      // console.log("query value", value);
      props.UpdateSearchFilter("pageNo", "1");
      addQuery("cities", value.join("~"));
      setValidation();
    }
    if (
      (budgetType && budgetMin >= 0 && !budgetMax) ||
      (budgetType && budgetMax >= 0 && !budgetMin && filter === "budgetMenu")
    ) {
      setVisibleBudgetMenu(false);
      setVisibleJobMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      props.UpdateSearchFilter("minbudgetQuery", budgetMin);
      props.UpdateSearchFilter("maxbudgetQuery", budgetMax);
      props.UpdateSearchFilter("budgetTypeQuery", budgetType);
      props.UpdateSearchFilter("budgetQuery", true);
      setIsFilter(true);
      // console.log("query value", budgetMin);
      // console.log("query value", budgetMax);
      props.UpdateSearchFilter("pageNo", "1");
      setValidation();
    }
    if (
      budgetType &&
      Number(budgetMin) >= 0 &&
      Number(budgetMax) >= 0 &&
      Number(budgetMin) < Number(budgetMax) &&
      filter === "budgetMenu"
    ) {
      setVisibleBudgetMenu(false);
      setVisibleJobMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      props.UpdateSearchFilter("minbudgetQuery", budgetMin);
      props.UpdateSearchFilter("maxbudgetQuery", budgetMax);
      props.UpdateSearchFilter("budgetTypeQuery", budgetType);
      props.UpdateSearchFilter("budgetQuery", true);
      setIsFilter(true);
      // console.log("query value", budgetMin);
      // console.log("query value", budgetMax);
      props.UpdateSearchFilter("pageNo", "1");
      setValidation();
    }
    if (filter === "budgetMenu" && !value) {
      setValidation("budget");
    }
    if (
      (filter === "budgetMenu" &&
        Number(budgetMin) >= 0 &&
        Number(budgetMax) >= 0 &&
        Number(budgetMin) >= Number(budgetMax)) ||
      (filter === "budgetMenu" &&
        Number(budgetMin) <= 0 &&
        Number(budgetMax) <= 0)
    ) {
      setValidation("budget");
    }
    if (WorkplacePolicyValue && filter === "WorkplaceMenu") {
      setVisibleJobMenu(false);
      setVisibleBudgetMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      setVisibleCountryMenu(false);
      setVisibleRegionMenu(false);
      setVisibleCityMenu(false);
      setVisibleExpLevelMenu(false);
      setIsFilter(true);
      props.UpdateSearchFilter("WorkplacePolicy", value);
      setValidation();
    }
    if (filter === "WorkplaceMenu" && !value) {
      setValidation("workplace");
      // setTimeout(() => setValidation(false), 6000);
    }
    if (filter === "ProfessionCode") {
      props.UpdateSearchFilter(
        "ProfessionCodeValue",
        ProfessionValue === "All" ? null : ProfessionValue?.substring(0, 2)
      );
      setVisibleProfessionMenu(false);
      if (ProfessionValue == "All") {
        removeQuery("pc");
        return;
      }
      addQuery("pc", ProfessionValue?.substring(0, 2));
    }
  };

  // console.log("props.value.length", props.value.length);
  // console.log("!loading", !loading);
  // console.log("gigs?.length", gigs?.length);
  // console.log("gigs", gigs);

  const onClear = (filter) => {
    // console.log("filter value", filter);
    if (filter === "jobMenu") {
      // setVisibleJobMenu(false);
      setJobValue([]);
      props.UpdateSearchFilter("jobQuery", null);
      setIsFilter(false);
      setValidation();
      removeQuery("jt");
    }
    if (filter === "skillMenu") {
      // setVisibleSkillMenu(false);
      props.UpdateSearchFilter("skillQuery", null);
      setSkillValue();
      setIsFilter(false);
      setValidation();
      removeQuery("sl");
    }
    if (filter === "expMenu") {
      // setVisibleSkillMenu(false);
      props.UpdateSearchFilter("explevelQuery", null);
      setExpLevelValue();
      setIsFilter(false);
      setValidation();
      removeQuery("explevel");
    }
    if (filter === "locationMenu") {
      // setVisibleLocationMenu(false);
      props.UpdateSearchFilter("locationQuery", null);
      setLocationValue();
      setIsFilter(false);
      setValidation();
      removeQuery("loc");
      // console.log(locationQuery, "locationQuerylocationQuery");
    }
    if (filter === "countryMenu") {
      // setVisibleLocationMenu(false);
      props.UpdateSearchFilter("countryQuery", []);
      setCountriesInput([]);
      setIsFilter(false);
      setValidation();
      removeQuery("countries");
      // console.log(locationQuery, "locationQuerylocationQuery");
    }
    if (filter === "regionMenu") {
      // setVisibleLocationMenu(false);
      props.UpdateSearchFilter("regionQuery", []);
      setRegionsInput([]);
      setIsFilter(false);
      setValidation();
      removeQuery("regions");
      // console.log(locationQuery, "locationQuerylocationQuery");
    }
    if (filter === "cityMenu") {
      // setVisibleLocationMenu(false);
      props.UpdateSearchFilter("cityQuery", []);
      setCitiesInput([]);
      setIsFilter(false);
      setValidation();
      removeQuery("cities");
      // console.log(locationQuery, "locationQuerylocationQuery");
    }
    if (filter === "budgetMenu") {
      // setVisibleBudgetMenu(false);
      setBudgetType(null);
      setBudgetMin(null);
      setBudgetMax(null);
      props.UpdateSearchFilter("minbudgetQuery", null);
      props.UpdateSearchFilter("maxbudgetQuery", null);
      props.UpdateSearchFilter("budgetTypeQuery", null);
      props.UpdateSearchFilter("budgetQuery", false);
      setIsFilter(false);
      setValidation();
    }
    if (filter === "WorkplaceMenu") {
      setWorkplacePolicyValue();
      props.UpdateSearchFilter("WorkplacePolicy", null);
      setIsFilter(false);
      setValidation();
    }
    if (filter === "ProfessionCode") {
      props.UpdateSearchFilter("ProfessionCodeValue", null);
      setProfessionValue();
      setIsFilter(false);
      setValidation();
      setVisibleProfessionMenu(false);
      removeQuery("pc");
    }
    props.UpdateSearchFilter("pageNo", "1");
    props.UpdateSearchFilter("sortValue", "Most Relevant");
  };

  const clearAllMobile = (checkProfession) => {
    setIsClear(isClear + 1);
    // document.getElementById("mobile-filter").reset();
    props.UpdateSearchFilter("jobQuery", null);
    props.UpdateSearchFilter("skillQuery", null);
    props.UpdateSearchFilter("locationQuery", null);
    props.UpdateSearchFilter("sortValue", "Most Relevant");
    props.UpdateSearchFilter("minbudgetQuery", null);
    props.UpdateSearchFilter("maxbudgetQuery", null);
    props.UpdateSearchFilter("budgetQuery", false);
    props.UpdateSearchFilter("budgetTypeQuery", null);
    // props.UpdateSearchFilter("filterDrawer", false);
    props.UpdateSearchFilter("sortDrawer", false);
    props.UpdateSearchFilter("isBudgetContract", false);
    props.UpdateSearchFilter("isBudgetPermanent", false);
    props.UpdateSearchFilter("activeFilters", null);
    props.UpdateSearchFilter("WorkplacePolicy", null);
    props.UpdateSearchFilter("countryQuery", []);
    props.UpdateSearchFilter("regionQuery", []);
    props.UpdateSearchFilter("explevelQuery", null);
    props.UpdateSearchFilter("cityQuery", []);
    if (checkProfession) {
      props.UpdateSearchFilter("ProfessionCodeValue", null);
      removeQuery("pc");
    }
    removeQuery("loc");
    removeQuery("sl");
    removeQuery("jt");
    closeFilterDrawer();
  };

  useEffect(() => {
    dispatch(onClearFilterInit({ onClear, clearAllMobile, setCitiesInput }));
  }, []);
  // dispatch(onClearFilterInit(onClear))

  useEffect(() => {
    const decodeUrl = decodeURIComponentSafe(searchValue);
    console.log(decodeUrl, "decodeUrl", searchValue);
    props.searchValue(decodeUrl);
    setSearch_value(decodeUrl);
  }, [searchValue]);

  const ClearAll = ({ filter, value }) => {
    // console.log(value, filter, "ClearAllClearAll");
    return (
      <div className="clearAll">
        <a href="#" className="clear-text" onClick={() => onClear(filter)}>
          Clear all
        </a>
        <div
          className="clear-all-button"
          onClick={() => onApply(value, filter)}
        >
          Apply
        </div>
      </div>
    );
  };

  const MobileSortResults = () => {
    props.UpdateSearchFilter("sortValue", sortValueMobile);
    props.UpdateSearchFilter("sortDrawer", false);
    props.UpdateSearchFilter("pageNo", "1");
  };

  useEffect(() => {
    props.sendKeyLocation("A");
    props.sendKeyCountry("A");
    props.sendKeyRegion("A");
    props.sendKeyCity("A");
    props.sendKeyExperinceLevels();
    if (!ProfessionCode && !QueryValue?.pc) {
      props.searchSkills({  profCode: ProfessionCode });
    }
  }, []);

  useEffect(() => {
    if (ProfessionCode) {
      props.searchSkills({ profCode: ProfessionCode });
    } else if (QueryValue?.pc) {
      props.searchSkills({ profCode: QueryValue?.pc?.toUpperCase() });
    }
  }, [ProfessionCode, QueryValue?.pc]);

  // Filters
  const onChangeSkill = (e) => {
    // console.log("radio checked", e.target.value);
    setSkillValue(e.target.value);
  };
  const onChangeWorkplace_Policy = (e) => {
    // console.log("onChangeWorkplace_Policy ", e.target.value);
    setWorkplacePolicyValue(e.target.value);
  };
  const WorkplaceStage = ["Fully remote", "Hybrid", "On-premise"];
  const Workplace_Policy = (
    <Menu
      onClick={null}
      className={
        validation === "workplace" ? "workplace-invalid" : "workplace-type-menu"
      }
    >
      {WorkplaceStage.map((data) => {
        return (
          <Menu>
            <Menu.Item onClick={null} onSelect={null}>
              <div className="sortItem-bids">
                <span>
                  <Radio.Group
                    onChange={onChangeWorkplace_Policy}
                    value={WorkplacePolicyValue}
                    defaultValue={WorkplacePolicy}
                  >
                    <Radio value={data} className="Search-jobs">
                      {data}
                    </Radio>
                  </Radio.Group>
                </span>
              </div>
            </Menu.Item>
          </Menu>
        );
      })}
      {validation === "workplace" ? (
        <div className="search-results-filter-validation-text">
          Please select workplace policy
        </div>
      ) : null}
      <ClearAll value={WorkplacePolicyValue} filter={"WorkplaceMenu"} />
    </Menu>
  );
  const onChangeJob = (e) => {
    setJobValue([]);
    if (e.target.value === "Contract") {
      setJobValue(["Contract", "Per Diem"]);
    } else {
      setJobValue(["Permanent"]);
    }
  };
  const onChangeExpLevel = (e) => {
    setExpLevelValue(e.target.value);
  };

  const jobs = ["Contract", "Permanent"];

  const JobMenu = (
    <Menu
      onClick={null}
      className={
        validation === "job" ? "job-type-menu-invalid" : "job-type-menu"
      }
    >
      {jobs.map((data) => {
        return (
          <Menu>
            <Menu.Item onClick={null} onSelect={null}>
              <div className="sortItem-bids">
                <span
                // className={skill === receivedBidSort ? "greenTextRB" : null}
                >
                  <Radio.Group
                    onChange={onChangeJob}
                    value={jobValue ? jobValue[0] : null}
                    // value = {radioValue}
                    defaultValue={jobQuery ? jobQuery[0] : null}
                  >
                    <Radio value={data} className="Search-jobs">
                      {data}
                    </Radio>
                  </Radio.Group>
                </span>
              </div>
            </Menu.Item>
          </Menu>
        );
      })}
      {validation === "job" ? (
        <div className="search-results-filter-validation-text">
          Please select any of the gig type
        </div>
      ) : null}
      <ClearAll value={jobValue} filter={"jobMenu"} />
    </Menu>
  );

  const onChangeProfession = (e) => {
    setProfessionValue(e.target.value);
  };

  const ProfessionList = [
    "All",
    "HC - Healthcare",
    "IT - Information Technology",
  ];

  useEffect(() => {
    setITtotalCount(0);
    setHCtotalCount(0);
    props.gigs?.gigs?.gigs?.aggregate?.professionCodeCount?.filter((data) => {
      if (data?.code === "HC") {
        setHCtotalCount(data?.count);
      } else if (data?.code === "IT") {
        setITtotalCount(data?.count);
      }
    });
    settotalCount(
      props.gigs?.gigs?.gigs?.aggregate?.totalCount
        ? props.gigs?.gigs?.gigs?.aggregate?.totalCount
        : 0
    );
  }, [
    props.gigs?.gigs?.gigs?.aggregate?.professionCodeCount[1]?.count,
    props.gigs?.gigs?.gigs?.aggregate?.professionCodeCount[0]?.count,
  ]);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (event.target.closest(".sortItem-bids") !== null) {
        return;
      }

      if (event.target.closest(".skill-search-closer-ref") !== null) {
        return;
      }

      if (event.target.closest(".clearAll") !== null) {
        return;
      }

      if (event.target.closest(".skill-search") !== null) {
        return;
      }

      if (!menuRef.current.contains(event.target)) {
        filterWrapperClick(event);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const ProfessionMenu = (
    <Menu
      onClick={null}
      className={
        validation === "Profession"
          ? "Profession-type-menu-invalid"
          : "Profession-type-menu"
      }
    >
      {ProfessionList.map((data) => {
        return (
          <Menu>
            <Menu.Item onClick={null} onSelect={null}>
              <div className="sortItem-bids">
                <span
                // className={skill === receivedBidSort ? "greenTextRB" : null}
                >
                  <Radio.Group
                    onChange={onChangeProfession}
                    value={ProfessionValue}
                    // value = {radioValue}
                    defaultValue={ProfessionList ? ProfessionList[0] : null}
                  >
                    <Radio value={data} className="Search-jobs">
                      {data}
                      {data === "All"
                        ? ` (${totalCount})`
                        : data === "HC - Healthcare"
                        ? ` (${HCtotalCount})`
                        : ` (${ITtotalCount})`}
                    </Radio>
                  </Radio.Group>
                </span>
              </div>
            </Menu.Item>
          </Menu>
        );
      })}
      {validation === "Profession" ? (
        <div className="search-results-filter-validation-text">
          Please select any of the Profession type
        </div>
      ) : null}
      <ClearAll value={ProfessionValue} filter={"ProfessionCode"} />
    </Menu>
  );

  const expMenu = (
    <Menu
      onClick={null}
      className={
        validation === "experinceLevel"
          ? "job-type-menu-invalid"
          : "job-type-menu"
      }
    >
      {props.experinceLevels &&
        props.experinceLevels.map((data) => {
          return (
            <Menu>
              <Menu.Item onClick={null} onSelect={null}>
                <div className="sortItem-bids">
                  <span
                  // className={skill === receivedBidSort ? "greenTextRB" : null}
                  >
                    <Radio.Group
                      onChange={onChangeExpLevel}
                      value={expLevelValue}
                      // value = {radioValue}
                      defaultValue={expLevelValue}
                    >
                      <Radio
                        value={data?.ExperienceLevelCode}
                        className="Search-jobs"
                      >
                        {data?.ExperienceLevelName}
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            </Menu>
          );
        })}
      {validation === "experinceLevel" ? (
        <div className="search-results-filter-validation-text">
          Please select any experience level
        </div>
      ) : null}
      <ClearAll value={expLevelValue} filter={"expMenu"} />
    </Menu>
  );

  const onChangeBudget = (e) => {
    setBudgetType(e.target.value);
    // console.log("Budget type", e.target.value);
  };

  const budgetMenu = (
    <Menu
      onClick={handleMenuClick}
      className={
        validation === "budget" ? "budget-menu-invalid" : "budget-menu"
      }
    >
      <div className="options"></div>
      <Radio.Group
        onChange={onChangeBudget}
        value={budgetType}
        defaultValue={budgetTypeQuery}
      >
        <Radio value={"Hourly"} className="Search-jobs">
          Hourly
        </Radio>
        <Radio value={"Weekly"} className="Search-jobs">
          Weekly
        </Radio>
      </Radio.Group>
      <div className="input-container">
        <Input
          prefix="$"
          className="input-left"
          onChange={(e) => setBudgetMin(handleNumberInput(e.target.value))}
          value={budgetMin}
          // placeholder="50"
        />
        <div className="dash">-</div>
        <Input
          prefix="$"
          className="input-right"
          onChange={(e) => setBudgetMax(handleNumberInput(e.target.value))}
          value={budgetMax}
          // placeholder="60"
        />
      </div>
      {validation === "budget" ? (
        <div className="search-results-filter-validation-text-budget">
          Please enter budget
        </div>
      ) : null}
      <ClearAll
        value={budgetMin ? budgetMin : budgetMax}
        filter={"budgetMenu"}
      />
    </Menu>
  );

  const onchangeSkill = (val) => {
    debounce((val) => onSkillSearch(val));
  };

  const onSkillSearch = (val) => {
    // console.log("value", val);
    setNoDataView(val);
    props.searchSkills({ val: val, profCode: ProfessionCode });
    setSkillsInput(val);
    // console.log("skill", skill);
  };

  const getSearchSkill = (value) => {
    if (value) {
      props.saveSearchSkill(value);
    }
  };
  const skill = (
    <Menu
      onClick={null}
      className={validation === "skill" ? "s-result-invalid" : "s-result"}
    >
      <Input
        placeholder="Find a skill"
        // value={skillValue}
        prefix={<SearchOutlined />}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onSkillSearch(e.target.value))}
      />

      <Menu>
        {props.skills &&
          props.skills.map((skill) => {
            return (
              <Menu.Item onClick={(val) => getSearchSkill(val)}>
                <div className="skill-search-closer-ref">
                  <span
                  // className={skill === receivedBidSort ? "greenTextRB" : null}
                  >
                    <Radio.Group onChange={onChangeSkill} value={skillValue}>
                      <Radio value={skill?.SkillName} className="Search-jobs">
                        {skill?.SkillName}
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
      {validation === "skill" ? (
        <div className="search-results-filter-validation-text-location">
          Please select skill
        </div>
      ) : null}
      <ClearAll value={skillValue} filter={"skillMenu"} />
    </Menu>
  );
  const onChange = (e) => {
    // console.log("locationQuery", e.target.value);
    setLocationValue(e.target.value);
  };

  function onLocationSearch(val) {
    // console.log(props.searchedLocation);
    // console.log("search:", val);
    // // console.log("location", city);
    setNoDataView(val);
    props.sendKeyLocation(val);
  }

  function onCountrySearch(val) {
    // console.log(props.searchedLocation);
    // console.log("search:", val);
    // // console.log("location", city);
    setNoDataView(val);
    props.sendKeyCountry(val);
  }
  function onRegionSearch(val) {
    // console.log(props.searchedLocation);
    // console.log("search:", val);
    // // console.log("location", city);
    setNoDataView(val);
    props.sendKeyRegion(val);
  }

  function onCitySearch(val) {
    // console.log(props.searchedLocation);
    // console.log("search:", val);
    // // console.log("location", city);
    setNoDataView(val);
    props.sendKeyCity(val);
  }

  const getSearchLocation = (value) => {
    // console.log("value", value);
    if (value) {
      props.saveSearchLocation(value);
    }
  };

  const locationMenu = (
    <Menu
      onClick={null}
      className={
        validation === "location" ? "s-result-invalid-location" : "s-result"
      }
    >
      <Input
        placeholder="Find city / state"
        prefix={<SearchOutlined />}
        // value={locationValue}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onLocationSearch(e.target.value))}
      />

      <Menu>
        {props.searchedLocation?.length > 0 &&
          props.searchedLocation?.map((city) => {
            return (
              <Menu.Item
                onClick={(e) =>
                  getSearchLocation(e.domEvent.target.defaultValue)
                }
              >
                <div className="sortItem-bids">
                  <span
                  // className={skill === receivedBidSort ? "greenTextRB" : null}
                  >
                    <Radio.Group onChange={onChange} value={locationValue}>
                      <Radio value={city} className="Search-jobs">
                        {city?.Location}
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
      {validation === "location" ? (
        <div className="search-results-filter-validation-text-location">
          Please select location
        </div>
      ) : null}
      <ClearAll value={locationValue} filter={"locationMenu"} />
    </Menu>
  );

  const countryMenu = (
    <Menu
      onClick={null}
      className={
        validation === "countries" ? "s-result-invalid-location" : "s-result"
      }
    >
      <Input
        placeholder="Find country"
        prefix={<SearchOutlined />}
        // value={locationValue}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onCountrySearch(e.target.value))}
      />

      <div
        className={`location-menu-container ${
          props.searchCountry?.length > 5 ? "show-scrollbar" : ""
        }`}
      >
        <Menu>
          {props.searchCountry?.length > 0 &&
            // props.searchCountry?.map((city) => {

            props.searchCountry
            .sort((a, b) => a.CountryName.localeCompare(b.CountryName)) 
            .map((city) => {
                // console.log("country****", city);

              return (
                <Menu.Item
                  key={city?._id}
                  onClick={(e) =>
                    getSearchLocation(e.domEvent.target.defaultValue)
                  }
                >
                  <div className="sortItem-bids">
                    <span
                    // className={skill === receivedBidSort ? "greenTextRB" : null}
                    >
                      <Checkbox
                        checked={getCheckedStatus(city?.CountryName, "country")}
                        onChange={(e) =>
                          updateFilterEntryValue(
                            e.target.checked,
                            e.target.value,
                            "country"
                          )
                        }
                        value={city?.CountryName}
                      >
                        {" "}
                        {city?.CountryName}
                      </Checkbox>
                    </span>
                  </div>
                </Menu.Item>
              );
            })}
        </Menu>
      </div>
      {validation === "countries" ? (
        <div className="search-results-filter-validation-text-location">
          Please select country
        </div>
      ) : null}
      <ClearAll value={countriesInput} filter={"countryMenu"} />
    </Menu>
  );

  const regionMenu = (
    <Menu
      onClick={null}
      className={
        validation === "regions" ? "s-result-invalid-location" : "s-result"
      }
    >
      <Input
        placeholder="Find state"
        prefix={<SearchOutlined />}
        // value={locationValue}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onRegionSearch(e.target.value))}
      />

      <div
        className={`location-menu-container ${
          props.searchedRegion?.length > 5 ? "show-scrollbar" : ""
        }`}
      >
        <Menu>
          {props.searchedRegion?.length > 0 &&
            // props.searchedRegion?.map((city) => {

                props.searchedRegion
                .sort((a, b) => a.RegionName.localeCompare(b.RegionName)) 
                .map((city) => {
                    // console.log("region****", city);


              return (
                <Menu.Item
                  key={city?._id}
                  onClick={(e) =>
                    getSearchLocation(e.domEvent.target.defaultValue)
                  }
                >
                  <div className="sortItem-bids">
                    <span
                    // className={skill === receivedBidSort ? "greenTextRB" : null}
                    >
                      <Checkbox
                        checked={getCheckedStatus(city?.RegionName, "region")}
                        onChange={(e) =>
                          updateFilterEntryValue(
                            e.target.checked,
                            e.target.value,
                            "region"
                          )
                        }
                        value={city?.RegionName}
                      >
                        {" "}
                        {city?.RegionName}
                      </Checkbox>
                    </span>
                  </div>
                </Menu.Item>
              );
            })}
        </Menu>
      </div>
      {validation === "regions" ? (
        <div className="search-results-filter-validation-text-location">
          Please select state
        </div>
      ) : null}
      <ClearAll value={regionsInput} filter={"regionMenu"} />
    </Menu>
  );

  const cityMenu = (
    <Menu
      onClick={null}
      className={
        validation === "cities" ? "s-result-invalid-location" : "s-result"
      }
    >
      <Input
        placeholder="Find city"
        prefix={<SearchOutlined />}
        // value={locationValue}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onCitySearch(e.target.value))}
      />

      <div
        className={`location-menu-container ${
          props.searchedCity?.length > 5 ? "show-scrollbar" : ""
        }`}
      >
        <Menu>
          {props.searchedCity?.length > 0 &&
            
            // props.searchedCity?.map((city) => {
              props.searchedCity
               .sort((a, b) => a.CityName.localeCompare(b.CityName)) 
               .map((city) => {
                // console.log("cityyyyy", city);

              return (
                <Menu.Item
                  key={city?._id}
                  onClick={(e) =>
                    getSearchLocation(e.domEvent.target.defaultValue)
                  }
                >
                  <div className="sortItem-bids">
                    <span
                    // className={skill === receivedBidSort ? "greenTextRB" : null}
                    >
                      {/* <Radio.Group onChange={onChange} value={locationValue}>
                        <Radio value={city} className="Search-jobs">
                          {city?.CityName}
                        </Radio>
                      </Radio.Group> */}
                      <Checkbox
                        checked={getCheckedStatus(city?.LocationWithCountry, "city")}
                        onChange={(e) =>
                          updateFilterEntryValue(
                            e.target.checked,
                            e.target.value,
                            "city"
                          )
                        }
                        value={city?.LocationWithCountry}
                      >
                        {" "}
                        {city?.LocationWithCountry}
                      </Checkbox>
                    </span>
                  </div>
                </Menu.Item>
              );
            })}
        </Menu>
      </div>
      {validation === "cities" ? (
        <div className="search-results-filter-validation-text-location">
          Please select city
        </div>
      ) : null}
      <ClearAll value={citiesInput} filter={"cityMenu"} />
    </Menu>
  );

  // SORTING
  const sortOnClick = (event) => {
    const sortArr = [
      "Most Relevant",
      "Most Recent",
      // "Pay Package High to Low",
      // "Pay Package Low to High",
      "Ending Soon",
    ];
    const value =
      sortArr[sortArr.findIndex((x) => x.match(new RegExp(event, "i")))];
    props.UpdateSearchFilter("sortValue", value);
    props.UpdateSearchFilter("pageNo", "1");
  };
  const sortMenu = (
    <Menu onClick={(e) => sortOnClick(e.domEvent.target.innerText)}>
      <Menu.Item
        key="1"
        className={
          sortValue == "Most Relevant"
            ? "sort-values-style-select"
            : "sort-values-style"
        }
      >
        Most relevant
      </Menu.Item>
      <Menu.Item
        key="2"
        className={
          sortValue === "Most Recent"
            ? "sort-values-style-select"
            : "sort-values-style"
        }
      >
        Most recent
      </Menu.Item>
      {/* {ProfessionCode !== "IT" ? (
        <>
          <Menu.Item
            key="3"
            className={
              sortValue === "Pay Package High to Low"
                ? "sort-values-style-select"
                : "sort-values-style"
            }
          >
            Pay package high to low
          </Menu.Item>
          <Menu.Item
            key="4"
            className={
              sortValue === "Pay Package Low to High"
                ? "sort-values-style-select"
                : "sort-values-style"
            }
          >
            Pay package low to high
          </Menu.Item>
        </>
      ) : null} */}
      <Menu.Item
        key={ProfessionCode !== "IT" ? 5 : 3}
        className={
          sortValue === "Ending Soon"
            ? "sort-values-style-select"
            : "sort-values-style"
        }
      >
        Ending soon
      </Menu.Item>
    </Menu>
  );

  const CountDetail = () => {
    return (
      <p style={{ height: "auto", fontSize: "1rem" }}>
        Showing {props.gigs?.gigs?.gigs.aggregate?.totalCount} results {props.value === " " || props.value === "" ? null : "for"}{" "}
        <strong style={{ textTransform: "capitalize" }}>
          {" "}
          {props.value === " " || props.value === ""  ? null : `"${props.value}"`}
        </strong>
      </p>
    );
  };
  const { Search } = Input;
  const onSearch = (e) => {
    props.searchValue(e.target.value);
    // if (e.target.value.length > 0) {
    // }
    if (e.target.value.length == 0) {
      history.push("/search");
    }
  };
  const mylocation = () => {
    setMyLocationQuery(!myLocationQuery);
    if (navigator.geolocation) {
      // setIsLocation(false);
      navigator.geolocation.watchPosition(function (position) {
        // console.log("Location Latitude is :", position.coords.latitude);
        // console.log("Location Longitude is :", position.coords.longitude);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
    // if (navigator.geolocation) {
    //   navigator.permissions
    //     .query({ name: "geolocation" })
    //     .then(function (position) {
    //       if (position.state === "granted") {
    //         // console.log(position.state);
    //         //If granted then you can directly call your function here
    //         // console.log("Location Latitude is :", position);
    //         // console.log("Location Longitude is :", position);
    //         // setLatitude(position.coords.latitude);
    //         // setLongitude(position.coords.longitude);
    //       } else if (position.state === "prompt") {
    //         // console.log(position.state);
    //       } else if (position.state === "denied") {
    //         alert("Permission denied can't show nearby location");
    //         //If denied then you have to show instructions to enable location
    //       }
    //       position.onchange = function () {
    //         // console.log(position.state);
    //       };
    //     });
    // } else {
    //   alert("Sorry Not available!");
    // }
  };

  // MOBILE FILTER AND SORT
  const mobileFilter = () => {
    if (window.innerWidth < 768) handleFilterDrawer(!filterDrawer);
    setVisible(true);
  };

  const mobileSort = () => {
    if (window.innerWidth < 768) {
      handleSortDrawer(!sortDrawer);
    }
  };
  const [jobInfo, setJobInfo] = useSelector(
    (state) => state.searchSuggestionReducer.setJobsInfo
  );
  // console.log("anurag", jobInfo, setJobInfo);
  const Islocation = useSelector((state) => state.IsLocationReducer.IsLocation);
  // console.log("Islocation", Islocation);

  let prevLocation;

  history.listen((nextLocation) => {
    // console.log(prevLocation, "prevLocation");
    prevLocation = nextLocation;
  });

  const onClickSuggestion = (e) => {
    const encodeURl = encodeURIComponent(e);
    history.push({ pathname: `/search/${encodeURl}` });
    props.searchValue(e);
    setJobInfo((prev) => {
      if (!jobInfo.includes(e)) {
        // console.log("jobss", !jobInfo.includes(e));
        return [e, ...prev];
      } else {
        return [...prev];
      }
    });
    dispatch(updateProfile({ profile: { RecentJobSearch: e } }));
  };
  const handleFilterDrawer = (state) => {
    props.UpdateSearchFilter("filterDrawer", state);
    setVisible(false);
  };

  const closeFilterDrawer = () => {
    props.UpdateSearchFilter("filterDrawer", false);
    if (activeFilters == 0) {
      activeFilters();
    }
  };
  const handleSortDrawer = (state) => {
    props.UpdateSearchFilter("sortDrawer", state);
  };

  const closeSortDrawer = () => {
    props.UpdateSearchFilter("sortDrawer", false);
    setSortValueMobile("Most Relevant");
  };
  const filterMenu = (
    <Menu id="mobile-filter">
      {/* {receivedBidStatus.map((stage) => { */}
      {/* return ( */}
      <Menu.Item
        // key={stage.BidStatusName}
        placement="bottomLeft"
        // onClick={() => handleFilterStage(stage.BidStatusName)}
      >
        <div className={visible ? "greenTextRB" : null}>
          {/* {stage.BidStatusName} */}
          <MobileFilters
            removeQuery={removeQuery}
            onClearAll={isClear}
            addQuery={addQuery}
            ITtotalCount={ITtotalCount}
            HCtotalCount={HCtotalCount}
            totalCount={totalCount}
          />
        </div>
      </Menu.Item>
      {/* ); */}
      {/* })} */}
    </Menu>
  );
  const sortArrayRB = [
    { "Most Recent": "most_recent" },
    // { "Pay Package High to Low": "pay_hi_to_low" },
    // { "Pay Package Low to High": "pay_low_to_hi" },
    { "Nearest to you": "nearby_location" },
  ];

  const handleSortRB = (sort) => {
    // console.log(sort);
    // // console.log(isLocation);
    // setReceivedBidSort(sort);
    // if (sort === "nearby_location") {
    //   setIsLocation(true);
    // } else setIsLocation(false);
  };
  const onChangeSort = (e) => {
    if (window.innerWidth > 768) {
      props.UpdateSearchFilter("sortValue", e.target.value);
    }

    if (window.innerWidth < 768) {
      setSortValueMobile(e.target.value);
    }
    // if (e.target.value === "nearby_location") {
    //   setIsLocation(true);
    // } else setIsLocation(false);
  };

  const sortMenuMobile = (
    <Menu>
      {sortArrayRB.map((sort) => {
        return (
          <Menu.Item onClick={() => handleSortRB(Object.values(sort)[0])}>
            <div className="sortItem-bids">
              <span
                className={
                  Object.values(sort)[0] === sortValue ? "greenTextRB" : null
                }
              >
                {Object.keys(sort)[0]}
              </span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const filterWrapperClick = (e) => {
    // e.stopPropagation();
    setVisibleJobMenu(false);
    setVisibleLocationMenu(false);
    setVisibleBudgetMenu(false);
    setVisibleSkillMenu(false);
    setVisibleCountryMenu(false);
    setVisibleRegionMenu(false);
    setVisibleCityMenu(false);
    setVisibleProfessionMenu(false);
    setVisibleExpLevelMenu(false);
    setVisibleCityMenu(false);
  };

  const handleNumberInput = (value) => value.replace(/\D/g, "");

  // RETURN
  return (
    <React.Fragment>
      <MediaQuery maxWidth={768}>
        <div className="search-results-divider">{/* <Divider /> */}</div>
      </MediaQuery>
      <main className="gql-dashBoardFull">
        <div
          ref={menuRef}
          className="gql-countCheck"
          onClick={() => {
            setVisibleSkillMenu(false);
            setVisibleLocationMenu(false);
            setVisibleJobMenu(false);
            setVisibleBudgetMenu(false);
            setVisibleCountryMenu(false);
            setVisibleRegionMenu(false);
            setVisibleCityMenu(false);
            setVisibleProfessionMenu(false);
          }}
        >
          {resultCount > 0 && props.value.length > 0 ? (
            <CountDetail gigs={gigs?.length} value={props.value} />
          ) : null}
        </div>
        {/* //// Tags//// */}
        {/* https://ant.design/components/tag/ */}

        {/* {props.value.length > 0 && gigs?.length !== 0 && (
          <>
            <div
              className={
                gigSuggestions?.length > 0
                  ? "suggestion-mainBox"
                  : "suggestion-mainBox-empty"
              }
            >
              <div
                className={
                  dimensions < 769
                    ? "suggestion-wrdapper-mobile"
                    : "suggestion-wrdapper"
                }
                onClick={() => {
                  setVisibleSkillMenu(false);
                  setVisibleLocationMenu(false);
                  setVisibleJobMenu(false);
                  setVisibleBudgetMenu(false);
                  setVisibleCountryMenu(false);
                  setVisibleRegionMenu(false);
                  setVisibleCityMenu(false);
                  setVisibleExpLevelMenu(false);
                  setVisibleProfessionMenu(false);
                }}
              >
                {gigs && gigs.length > 0 && props.value.length > 0 ? (
                  <>
                    {gigSuggestions.slice(0, 7).map((suggestion) => {
                      return (
                        <Tag
                          color="default"
                          className="tags"
                          onClick={(e) => onClickSuggestion(suggestion)}
                        >
                          {suggestion}
                        </Tag>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </div>
            {window.innerWidth < 768 ? <hr className="scroll-divider" /> : null}
          </>
        )} */}
        {/* ////// Dropdowns //// */}
        {/* https://ant.design/components/dropdown/ */}
        {/* {dimensions > 769 && props.value.length > 0 && gigs?.length !== 0 ? ( */}
        <>
          <div class="filters-wrapper">
            {dimensions > 769 && resultCount > 0 && props.value.length > 0 ? (
              <>
                <Space wrap className="space">
                  {!props.talentToken && (
                    <Dropdown
                      overlayClassName={"DropdownSearchResult"}
                      // className="dropdown"
                      // overlayClassName={"DropdownSearchResult"}
                      className={
                        ProfessionCodeValue ? "dropdown-location" : "dropdown"
                      }
                      overlay={ProfessionMenu}
                      trigger="click"
                      destroyPopupOnHide={true}
                      onClick={() => {
                        setVisibleLocationMenu(false);
                        setVisibleSkillMenu(false);
                        setVisibleJobMenu(false);
                        setVisibleBudgetMenu(false);
                        setVisibleProfessionMenu(!visibleProfessionMenu);
                        setVisibleCountryMenu(false);
                        setVisibleRegionMenu(false);
                        setVisibleCityMenu(false);
                        setVisibleExpLevelMenu(false);
                      }}
                      visible={visibleProfessionMenu}
                    >
                      <Button className="button">
                        Profession <DownOutlined />
                      </Button>
                    </Dropdown>
                  )}
                  <Dropdown
                    overlayClassName={"DropdownSearchResult"}
                    className={jobQuery ? "dropdown-job" : "dropdown"}
                    // className="dropdown-job"
                    overlay={JobMenu}
                    trigger="click"
                    destroyPopupOnHide={true}
                    onClick={() => {
                      setVisibleJobMenu(!visibleJobMenu);
                      setVisibleLocationMenu(false);
                      setVisibleBudgetMenu(false);
                      setVisibleSkillMenu(false);
                      setVisibleCountryMenu(false);
                      setVisibleRegionMenu(false);
                      setVisibleCityMenu(false);
                      setVisibleExpLevelMenu(false);
                      setVisibleProfessionMenu(false);
                    }}
                    visible={visibleJobMenu}
                    // destroyOnClose={true}
                  >
                    <Button
                      className="button"
                      onVisibleChange={() => onClear("jobMenu")}
                    >
                      Gig type <DownOutlined />
                    </Button>
                  </Dropdown>
                  <Dropdown
                    overlayClassName={"DropdownSearchResult"}
                    // className="dropdown"
                    // overlayClassName={"DropdownSearchResult"}
                    className={skillQuery ? "dropdown-skill" : "dropdown"}
                    overlay={skill}
                    trigger="click"
                    destroyPopupOnHide={true}
                    onClick={() => {
                      setVisibleSkillMenu(!visibleSkillMenu);
                      setVisibleLocationMenu(false);
                      setVisibleJobMenu(false);
                      setVisibleBudgetMenu(false);
                      setVisibleCountryMenu(false);
                      setVisibleRegionMenu(false);
                      setVisibleCityMenu(false);
                      setVisibleExpLevelMenu(false);
                      setVisibleProfessionMenu(false);
                    }}
                    visible={visibleSkillMenu}
                  >
                    <Button className="button">
                      Skill <DownOutlined />
                    </Button>
                  </Dropdown>
                  {/* <Dropdown
                    overlayClassName={"DropdownSearchResult"}
                    // className="dropdown"
                    // overlayClassName={"DropdownSearchResult"}
                    className={locationQuery ? "dropdown-location" : "dropdown"}
                    overlay={locationMenu}
                    trigger="click"
                    destroyPopupOnHide={true}
                    onClick={() => {
                      setVisibleLocationMenu(!visibleLocationMenu);
                      setVisibleSkillMenu(false);
                      setVisibleJobMenu(false);
                      setVisibleBudgetMenu(false);
                      setVisibleCountryMenu(false);
                      setVisibleRegionMenu(false);
                      setVisibleCityMenu(false);
                      setVisibleExpLevelMenu(false);
                      setVisibleProfessionMenu(false);
                    }}
                    visible={visibleLocationMenu}
                  >
                    <Button className="button">
                      Location <DownOutlined />
                    </Button>
                  </Dropdown> */}

                  <Dropdown
                    overlayClassName={"DropdownSearchResult"}
                    // className="dropdown"
                    // overlayClassName={"DropdownSearchResult"}
                    className={
                      cityQuery.length ? "dropdown-location" : "dropdown"
                    }
                    overlay={cityMenu}
                    trigger="click"
                    destroyPopupOnHide={true}
                    onClick={() => {
                      setVisibleCountryMenu(false);
                      setVisibleLocationMenu(false);
                      setVisibleSkillMenu(false);
                      setVisibleJobMenu(false);
                      setVisibleBudgetMenu(false);
                      setVisibleRegionMenu(false);
                      setVisibleCityMenu(!visibleCityMenu);
                      setVisibleExpLevelMenu(false);
                      setVisibleProfessionMenu(false);
                    }}
                    visible={visibleCityMenu}
                  >
                    <Button className="button">
                      City <DownOutlined />
                    </Button>
                  </Dropdown>

                  <Dropdown
                    overlayClassName={"DropdownSearchResult"}
                    // className="dropdown"
                    // overlayClassName={"DropdownSearchResult"}
                    className={
                      regionQuery.length ? "dropdown-location" : "dropdown"
                    }
                    overlay={regionMenu}
                    trigger="click"
                    destroyPopupOnHide={true}
                    onClick={() => {
                      setVisibleCountryMenu(false);
                      setVisibleLocationMenu(false);
                      setVisibleSkillMenu(false);
                      setVisibleJobMenu(false);
                      setVisibleBudgetMenu(false);
                      setVisibleRegionMenu(!visibleRegionMenu);
                      setVisibleCityMenu(false);
                      setVisibleExpLevelMenu(false);
                      setVisibleProfessionMenu(false);
                    }}
                    visible={visibleRegionMenu}
                  >
                    <Button className="button">
                      State <DownOutlined />
                    </Button>
                  </Dropdown>

                  <Dropdown
                    overlayClassName={"DropdownSearchResult"}
                    // className="dropdown"
                    // overlayClassName={"DropdownSearchResult"}
                    className={
                      countryQuery?.length ? "dropdown-location" : "dropdown"
                    }
                    overlay={countryMenu}
                    trigger="click"
                    destroyPopupOnHide={true}
                    onClick={() => {
                      setVisibleCountryMenu(!visibleCountryMenu);
                      setVisibleLocationMenu(false);
                      setVisibleSkillMenu(false);
                      setVisibleJobMenu(false);
                      setVisibleBudgetMenu(false);
                      setVisibleRegionMenu(false);
                      setVisibleCityMenu(false);
                      setVisibleExpLevelMenu(false);
                      setVisibleProfessionMenu(false);
                    }}
                    visible={visibleCountryMenu}
                  >
                    <Button className="button">
                      Country <DownOutlined />
                    </Button>
                  </Dropdown>
                  <Dropdown
                    overlayClassName={"DropdownSearchResult"}
                    // className="dropdown"
                    // overlayClassName={"DropdownSearchResult"}
                    className={explevelQuery ? "dropdown-location" : "dropdown"}
                    overlay={expMenu}
                    trigger="click"
                    destroyPopupOnHide={true}
                    onClick={() => {
                      setVisibleCountryMenu(false);
                      setVisibleLocationMenu(false);
                      setVisibleSkillMenu(false);
                      setVisibleJobMenu(false);
                      setVisibleBudgetMenu(false);
                      setVisibleRegionMenu(false);
                      setVisibleCityMenu(false);
                      setVisibleExpLevelMenu(!visibleExpLevelMenu);
                      setVisibleProfessionMenu(false);
                    }}
                    visible={visibleExpLevelMenu}
                  >
                    <Button className="button">
                      Experience Levels <DownOutlined />
                    </Button>
                  </Dropdown>
                  {/* <Dropdown
                    overlayClassName={"DropdownSearchResult"}
                    // className="dropdown"
                    // overlayClassName={"DropdownSearchResult"}
                    className={
                      budgetQuery || WorkplacePolicy
                        ? "dropdown-budget"
                        : "dropdown"
                    }
                    overlay={
                      ProfessionCode === "IT" ||
                      QueryValue?.pc?.toUpperCase() === "IT"
                        ? Workplace_Policy
                        : budgetMenu
                    }
                    trigger="click"
                    destroyPopupOnHide={true}
                    onClick={() => {
                      setVisibleBudgetMenu(!visibleBudgetMenu);
                      setVisibleSkillMenu(false);
                      setVisibleLocationMenu(false);
                      setVisibleJobMenu(false);
                    }}
                    visible={visibleBudgetMenu}
                  >
                    <Button className="button">
                      {ProfessionCode === "IT" ||
                      QueryValue?.pc?.toUpperCase() === "IT"
                        ? "Workplace Policy"
                        : "Budget"}{" "}
                      <DownOutlined />
                    </Button>
                  </Dropdown> */}
                </Space>
                <div
                  className="blank-space-div"
                  onClick={(e) => filterWrapperClick(e)}
                ></div>
              </>
            ) : null}
          </div>
          <div
            className="applied-filter Applied-filter-wrap"
            onClick={() => {
              setVisibleJobMenu(false);
              setVisibleLocationMenu(false);
              setVisibleBudgetMenu(false);
              setVisibleSkillMenu(false);
              setWorkplacePolicyValue(false);
            }}
          >
            {dimensions > 769 && ProfessionCodeValue ? (
              <>
                <Tag
                  closable
                  onClose={() => onClear("ProfessionCode")}
                  className="tag1"
                >
                  {ProfessionCodeValue === "HC"
                    ? "HC - Healthcare"
                    : "IT - Information Technology"}
                </Tag>
              </>
            ) : null}

            {dimensions > 769 && jobQuery ? (
              <>
                <Tag
                  closable
                  onClose={() => onClear("jobMenu")}
                  className="tag1"
                >
                  {jobQuery ? jobQuery[0] : null}
                </Tag>
              </>
            ) : null}
            {dimensions > 769 && skillQuery ? (
              <>
                <Tag
                  closable
                  onClose={() => onClear("skillMenu")}
                  className="tag1"
                >
                  {skillQuery?.toString()?.replace(/\+/g, " ")}
                </Tag>
              </>
            ) : null}
            {dimensions > 769 && locationQuery ? (
              <>
                <Tag
                  closable
                  onClose={() => onClear("locationMenu")}
                  className="tag1"
                >
                  {locationQuery?.Location
                    ? locationQuery?.Location
                    : locationValue}
                </Tag>
              </>
            ) : null}
            {dimensions > 769 &&
            budgetQuery &&
            minbudgetQuery &&
            maxbudgetQuery ? (
              <>
                <Tag
                  closable
                  onClose={() => onClear("budgetMenu")}
                  className="tag1"
                >
                  ${minbudgetQuery}-${maxbudgetQuery}
                </Tag>
              </>
            ) : null}
            {budgetQuery && minbudgetQuery && !maxbudgetQuery ? (
              <>
                <Tag
                  closable
                  onClose={() => onClear("budgetMenu")}
                  className="tag1"
                >
                  over ${minbudgetQuery}
                </Tag>
              </>
            ) : null}
            {budgetQuery && maxbudgetQuery && !minbudgetQuery ? (
              <>
                <Tag
                  closable
                  onClose={() => onClear("budgetMenu")}
                  className="tag1"
                >
                  under ${maxbudgetQuery}
                </Tag>
              </>
            ) : null}
            {dimensions > 769 && WorkplacePolicy ? (
              <>
                <Tag
                  closable
                  onClose={() => onClear("WorkplaceMenu")}
                  className="tag1"
                >
                  {WorkplacePolicy}
                </Tag>
              </>
            ) : null}

            {dimensions > 769 && countryQuery && countryQuery.length ? (
              <>
                {countryQuery?.map((value) => {
                  return (
                    <Tag
                      //  closable
                      //  onClose={() => {

                      //  }}
                      className="tag1"
                    >
                      {value}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (countryQuery.length > 1) {
                            const uv = countryQuery.filter((v) => v !== value);
                            setCountriesInput(uv);
                            onApply(uv, "countryMenu");
                          } else {
                            onClear("countryMenu");
                          }
                        }}
                      >
                        {" "}
                        <AiOutlineClose />{" "}
                      </span>
                    </Tag>
                  );
                })}
              </>
            ) : null}
            {dimensions > 769 && explevelQuery ? (
              <>
                <Tag
                  closable
                  onClose={() => onClear("expMenu")}
                  className="tag1"
                >
                  {
                    props?.experinceLevels?.filter(
                      (v) => v.ExperienceLevelCode == explevelQuery
                    )[0]?.ExperienceLevelName
                  }
                </Tag>
              </>
            ) : null}

            {dimensions > 769 && regionQuery && regionQuery.length ? (
              <>
                {regionQuery?.map((value) => {
                  return (
                    <Tag className="tag1">
                      {value}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (regionQuery.length > 1) {
                            const uv = regionQuery.filter((v) => v !== value);
                            setRegionsInput(uv);
                            onApply(uv, "regionMenu");
                          } else {
                            onClear("regionMenu");
                          }
                        }}
                      >
                        {" "}
                        <AiOutlineClose />{" "}
                      </span>
                    </Tag>
                  );
                })}
              </>
            ) : null}

            {dimensions > 769 && cityQuery && cityQuery.length ? (
              <>
                {cityQuery?.map((value) => {
                  return (
                    <Tag className="tag1">
                      {value}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (cityQuery.length > 1) {
                            const uv = cityQuery.filter((v) => v !== value);
                            setCitiesInput(uv);
                            onApply(uv, "cityMenu");
                          } else {
                            onClear("cityMenu");
                          }
                        }}
                      >
                        {" "}
                        <AiOutlineClose />{" "}
                      </span>
                    </Tag>
                  );
                })}
              </>
            ) : null}
          </div>
        </>
        {/* //  ) : null} */}
        {/* {dimensions > 769 && props.value.length > 0 && gigs?.length !== 0 ? ( */}
        <>
          {dimensions > 769 && resultCount > 0 && props.value.length > 0 ? (
            <>
              <div
                className="sort-filter"
                onClick={() => {
                  setVisibleSkillMenu(false);
                  setVisibleLocationMenu(false);
                  setVisibleJobMenu(false);
                  setVisibleBudgetMenu(false);
                  setVisibleCountryMenu(false);
                  setVisibleRegionMenu(false);
                  setVisibleCityMenu(false);
                  setVisibleExpLevelMenu(false);
                  setVisibleProfessionMenu(false);
                }}
              >
                <Dropdown
                  overlayClassName={"DropdownSearchResult"}
                  overlay={sortMenu}
                  trigger="click"
                  // overlayClassName={"DropdownSearchResult"}
                  className="sort-filter-dropdown"
                >
                  <div
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="sortby-text">{sortValue}</span>{" "}
                    <DownOutlined />
                  </div>
                </Dropdown>
                <div className="sortby">Sort by</div>
              </div>
            </>
          ) : null}
        </>
        {/* //  ) : null} */}
        {/* MOBILE */}
        {/* {dimensions <= 769 && props.value.length > 0 && gigs?.length !== 0 ? ( */}
        <>
          {dimensions <= 769 && resultCount > 0 && props.value.length > 0 ? (
            <>
              <div className="search-mobile-filter">
                <div className="left" onClick={mobileFilter}>
                  <div className="search-filter-container">
                    <BiFilterAlt className="icon" />

                    <div className="text">
                      Filter{" "}
                      {props.activeFilters ? `(${props.activeFilters})` : null}
                    </div>
                  </div>
                  {/* <BiFilterAlt /> */}
                </div>
                <div className="left" onClick={mobileSort}>
                  <div className="search-filter-container">
                    <BsArrowDownUp className="icon" />

                    <div className="text">Sort</div>
                  </div>
                </div>
              </div>
              <div className="mobile-location-button">
                {/* https://ant.design/components/switch/ */}
                {/* <div className="location-text">Nearby gigs</div>
                <div className="mobile-switch">
                  <Switch onChange={mylocation} disabled={Islocation} />
                </div> */}
              </div>
            </>
          ) : null}
        </>
        {/* ) : null}  */}

        <div
          className={`${props.value.length == 0 && "gql-receivedGigs"} `}
          onClick={() => {
            setVisibleJobMenu(false);
            setVisibleLocationMenu(false);
            setVisibleBudgetMenu(false);
            setVisibleSkillMenu(false);
          }}
        >
          {props.value.length > 0 && !props.loading ? (
            <div className="gql-receivedGigs" Pagination={true}>
              {gigs && gigs.length > 0
                ? gigs.map((data) => {
                    // // console.log('gig data: ',data);
                    return (
                      <GigsCard
                        Type="SearchCard"
                        data={data}
                        budgetType={budgetTypeQuery}
                        setFavorite={getStateAfterAddRemoveFavorite}
                      />
                    );
                  })
                : null}
            </div>
          ) : (
            <div className="gql-message">
              {props.value.length === 0 && `Search gigs`}
            </div>
          )}
          {/* <LoadingOutlined /> */}
          {props.value.length > 0 && loading ? (
            <>
              <div className="gql-loader">
                {/* {props.value.length > 0 && props.loading && <LoadingOutlined />} */}
                <Loader />
              </div>
            </>
          ) : null}
          {props.value.length > 0 && !loading && gigs?.length === 0 ? (
            <>
              <GuestNoSearchResult />
            </>
          ) : null}

          {props.value.length > 0 && gigs?.length !== 0 && (
            <div className="gql-pagination">
              {props.value.length > 0 &&
                gigs &&
                props.gigs?.gigs?.gigs.aggregate?.totalCount > 20 && (
                  <Pagination
                    defaultCurrent={pageNo ? pageNo : 1}
                    total={props.gigs?.gigs?.gigs.aggregate?.totalCount}
                    pageSize={24}
                    onChange={(page) => {
                      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                      props.UpdateSearchFilter("pageNo", page);
                    }}
                  />
                )}
            </div>
          )}
          {/* DRAWER FOR MOBILE FILTER */}
          <div className="mobile-filter">
            {/* <Space direction="vertical">
                <Space wrap> */}
            <Drawer
              title="Filter"
              placement="top"
              onClose={closeFilterDrawer}
              visible={filterDrawer}
              className="SearchfilterDrawer Search-filter-MobileView"
              height="40rem"
              width="100%"
              destroyOnClose={true}
            >
              {filterMenu}
              <div
                style={{
                  color: "#9e9e9e",
                  position: "absolute",
                  top: "15px",
                  left: "15px",
                }}
                onClick={() => clearAllMobile(true)}
              >
                Clear all
              </div>
            </Drawer>
          </div>
          <Drawer
            title="Sort gigs by"
            placement="top"
            onClose={closeSortDrawer}
            visible={sortDrawer}
            className="sortReceivedBidsDrawer-mobile Mobile-Detail-Sort"
            height="40rem"
            width="100%"
            destroyOnClose={true}
            closable={false}
          >
            <CloseOutlined
              className="close-btn-icon"
              onClick={closeSortDrawer}
            />
            <Radio.Group
              className="radioSortReceivedBidsGroup"
              onChange={(e) => onChangeSort(e)}
              defaultValue={sortValue}
            >
              <Space direction="vertical">
                <Radio
                  className="radioSortReceivedBids"
                  value={"Most Relevant"}
                >
                  <span style={{ color: "#3e3e3e" }}>Most relevant</span>
                </Radio>
                <Radio className="radioSortReceivedBids" value={"Most Recent"}>
                  <span style={{ color: "#3e3e3e" }}>Most recent</span>
                </Radio>
                {/* {ProfessionCode === "HC" ? (
                  <>
                    <Radio
                      className="radioSortReceivedBids"
                      value={"Pay Package High to Low"}
                    >
                      <span style={{ color: "#3e3e3e" }}>
                        Pay package high to Low
                      </span>
                    </Radio>
                    <Radio
                      className="radioSortReceivedBids"
                      value={"Pay Package Low to High"}
                    >
                      <span style={{ color: "#3e3e3e" }}>
                        Pay package low to high
                      </span>
                    </Radio>
                  </>
                ) : null} */}
                <Radio className="radioSortReceivedBids" value={"Ending Soon"}>
                  <span style={{ color: "#3e3e3e" }}>Ending soon</span>
                </Radio>
              </Space>
            </Radio.Group>
            <div className="filter_search_button">
              <div className="apply-button-mobile-filter">
                <div className="apply-button-text" onClick={MobileSortResults}>
                  Show results
                </div>
              </div>
            </div>
            {/* <div className="radioSortShowItemsParent">
              <div className="radioSortShowItemsChild" onClick={showItemsSort}>
                Show Items
              </div>
            </div> */}
          </Drawer>
          {/* <div>
            <Drawer
              title="Basic Drawer"
              placement="top"
              closable={false}
              onClose={() => setVisible(false)}
              visible={visible}
              key="top"
              style={{ height: "400px" }}
              height={556}
              extra={
                <div>
                  <p>hey</p>
                </div>
              }
            >
              <p>Some contents...</p>
              <p>Some contents...</p>
              <p>Some contents...</p>
            </Drawer>
          </div> */}
          {/* <LoadingOutlined /> */}
          {/* {gigs && gigs.length > 0
            ? gigs.map((data) => {
                return <GigsCard data={data} />;
              })
            : "no gigs found "} */}
        </div>
      </main>
      {/* </div> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    jobQuery: state.searchGigsReducer.jobQuery,
    skillQuery: state.searchGigsReducer.skillQuery,
    locationQuery: state.searchGigsReducer.locationQuery,
    countryQuery: state.searchGigsReducer.countryQuery,
    regionQuery: state.searchGigsReducer.regionQuery,
    cityQuery: state.searchGigsReducer.cityQuery,
    explevelQuery: state.searchGigsReducer.explevelQuery,
    minbudgetQuery: state.searchGigsReducer.minbudgetQuery,
    budgetQuery: state.searchGigsReducer.budgetQuery,
    maxbudgetQuery: state.searchGigsReducer.maxbudgetQuery,
    budgetTypeQuery: state.searchGigsReducer.budgetTypeQuery,
    sortValue: state.searchGigsReducer.sortValue,
    pageNo: state.searchGigsReducer.pageNo,
    activeFilters: state.searchGigsReducer.activeFilters,
    filterDrawer: state.searchGigsReducer.filterDrawer,
    sortDrawer: state.searchGigsReducer.sortDrawer,
    loading: state.searchGigsReducer.loading,
    skills: state.skillListingReducer.skills,
    currentSkillListing: state.skillListingReducer.currentSkillListing,
    gigs: state.searchGigsReducer.gigs,
    value: state.searchGigsReducer.searchvalue,
    error: state.searchGigsReducer,
    talentToken: state.authReducer.user?.authToken,
    searchedLocation: state.credentialingReducer.searchedLocation,
    searchCountry: state.credentialingReducer.searchCountry,
    searchedRegion: state.credentialingReducer.searchedRegion,
    searchedCity: state.credentialingReducer.searchedCity,
    experinceLevels: state.credentialingReducer.experinceLevels,
    WorkplacePolicy: state.searchGigsReducer.WorkplacePolicy,
    IsFavoritesValue: state.searchGigsReducer.IsFavoritesValue,
    TrendingLocSearch: state.searchGigsReducer.TrendingLocSearch,
    TrendingPlaceName: state.searchGigsReducer.TrendingPlaceName,
    ProfessionCodeValue: state.searchGigsReducer.ProfessionCodeValue,
    ProfessionCodeFilterCount:
      state.searchGigsReducer.ProfessionCodeFilterCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // GetGigs: (params) => {
    //   dispatch(fetchGigs(params)); // action dispatched
    // },
    // SearchValue
    SearchResponseRequested: (params) => {
      dispatch(SearchResponseRequested(params)); // action dispatched
    },
    searchResponse: (params) => {
      dispatch(SearchResponse(params)); // action dispatched
    },
    searchValue: (params) => {
      dispatch(SearchValue(params)); // action dispatched
    },
    saveSearchSkill: (value) => {
      dispatch(saveSearchSkill(value));
    },
    searchSkills: (params) => {
      dispatch(fetchSearchSkill(params));
    },
    sendKeyLocation: (e) => {
      dispatch(fetchKeySearchLocation(e));
    },
    sendKeyCountry: (e) => {
      dispatch(fetchKeySearchCountry(e));
    },
    sendKeyRegion: (e) => {
      dispatch(fetchKeySearchRegion(e));
    },
    sendKeyCity: (e) => {
      dispatch(fetchKeySearchCity(e));
    },
    sendKeyExperinceLevels: () => {
      dispatch(fetchKeyExperinceLevels());
    },
    saveSearchLocation: (value) => {
      dispatch(saveSearchLocation(value));
    },
    UpdateSearchFilter: (key, params) => {
      // console.log("filter reducer key", key);
      dispatch(UpdateSearchFilter(key, params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
