import { types } from "../action-types";

const initialState = true;

const showPublicViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_PUBLIC_VIEW:
      return !state;

    default:
      return state;
  }
};

export default showPublicViewReducer;
