import { Row } from "antd";
import moment from "moment";
import React from "react";
import "./styles.css";

export function CandidateCancelledCard({ data, status }) {
  // console.log('inside candidate cancel card at the time of cancelled =======================>', status)
  return (
    <>
    {/* status == "Candidate Cancelled" || status == "Candidate Terminated" && */}
      {/* { ( */}
        <>
          <div>
            <strong>Sorry!</strong>
            <p>Your employer moved you to {status == "Candidate Cancelled" ? 'canceled' : 'terminated'}.</p>
          </div>
          <div className="onBilling-card-info-div">
            <Row className="info-style">
              <strong>Start date</strong>
              <div>
                {moment(data?.OfferCard?.FromDate).format("MMM DD , YYYY")}
              </div>
            </Row>
            <Row className="info-style">
              <strong>End date</strong>
              <div>{moment(data?.OfferCard?.ToDate).format("MMM DD , YYYY")}</div>
            </Row>
            <Row className="info-style">
              <strong>Estimated pay package</strong>
              <div>
                {`${data?.OfferCard?.Currency ?? 'N/A'} ${data?.OfferCard?.Rate ?? 'N/A'}/${data?.OfferCard?.BudgetType ?? 'N/A'}`}{" "}
              </div>
            </Row>
            <Row className="info-style">
              <strong>Contract duration</strong>
              <div>{data?.OfferCard?.Duration ?? 'N/A'}</div>
            </Row>
          </div>
        </>
      {/* )} */}
    </>
  );
}
