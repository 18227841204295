import bmp from "../../assets/icons/bmp.svg";
import csv from "../../assets/icons/csv.svg";
import doc from "../../assets/icons/doc.svg";
import docx from "../../assets/icons/docx.svg";
import gif from "../../assets/icons/gif.svg";
import jpeg from "../../assets/icons/jpeg.svg";
import jpg from "../../assets/icons/jpg.svg";
import pdf from "../../assets/icons/pdf.svg";
import png from "../../assets/icons/png.svg";
import xlsx from "../../assets/icons/xlsx.svg";
import { alertNotification } from "../notifications/alertNotification";

const getFileSizeInfo = ({ name, size }) => {
  if (size > 10485760) {
    alertNotification("warning", `File size bigger than 10 MB`);
    return false;
  } else {
    return true;
  }
};

const getFileFormat = (fileName) => {
  const behideDot = fileName?.lastIndexOf(".") + 1;
  const fileLength = fileName?.length;
  const fileFormat = fileName?.slice(behideDot, fileLength);
  return fileFormat;
};

const getFileIcon = (fileType) => {
  switch (fileType) {
    case "bmp":
      return bmp;
    case "csv":
      return csv;
    case "doc":
      return doc;
    case "docx":
      return docx;
    case "gif":
      return gif;
    case "jpeg":
      return jpeg;
    case "JPEG":
      return jpeg;
    case "jpg":
      return jpg;
    case "pdf":
      return pdf;
    case "png":
      return png;
    case "PNG":
      return png;
    case "xlsx":
      return xlsx;

    default:
      alertNotification("warning", `File format not supported`);
      return false;
  }
};

export { getFileIcon, getFileFormat, getFileSizeInfo };
