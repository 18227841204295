import {
  addUserOnlineStatus,
  fetchInboxMessages,
  getNotifications,
  getTalentInfo,
  receivedMessages,
  updateProfile,
  updateUserOnlineStatus,
} from "../redux/actions";
import { connect, useSelector } from "react-redux";
import store from "../redux/store";
import { useEffect } from "react";
const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
const websocketURL = process.env.REACT_APP_WEB_SOCKET_URL;
class WS {
  // initialize socket connection and sending a join request
  static init(UserId, objId) {
    this.ws = new WebSocket(
      //WebSocket Url
      // `ws://192.168.18.101:4001?UserId=${UserId}`
      // `wss://3el03f9ofa.execute-api.us-east-2.amazonaws.com/production?UserId=${UserId}`,
      // `wss://rde98i1kyk.execute-api.us-east-2.amazonaws.com/staging?UserId=${UserId}`
      `${websocketURL}?UserId=${UserId}`
    );

    this.ws.onopen = () => {
      // console.log("opened websocket");
      this.ws.send(
        JSON.stringify({
          action: "join",
          user: UserId,
        })
      );

      store.dispatch(updateProfile({ IsOnline: true }));
    };

    const authToken = store.getState().authReducer.user.authToken;

    this.ws.onmessage = ({ data }) => {
      // console.log("data aaaaa---->", JSON.parse(data));
      const messageOnNetwork = JSON.parse(data);
      console.log("messageOnNetwork:", messageOnNetwork);

      // parsed resume update
      if (messageOnNetwork?.type == "parsed-resume") {
        store.dispatch(getTalentInfo(authToken));
      }

      // online/offline statuses
      if (messageOnNetwork?.type == "online-users") {
        const { userId, IsUserOnline } = messageOnNetwork.data;
        const existingUser = store
          .getState()
          .getChatHistoryReducer.onlineUsers?.find((u) => u.userId === userId);

        if (existingUser) {
          store.dispatch(updateUserOnlineStatus(userId, IsUserOnline));
        } else {
          store.dispatch(addUserOnlineStatus([{ userId, IsUserOnline }]));
        }

        return;
      }

      // maintain connection replying a ping with a ping
      store.dispatch(
        receivedMessages(
          { ...JSON.parse(data) },
          { SecondarySourceTypeName: "Bid" }
        )
      );
      store.dispatch(getNotifications());
      store.dispatch(
        fetchInboxMessages("all_bids", "all_conversations", null, 1, 1)
      );
      // this.props.saveMessage(
      //   { ...JSON.parse(data) }, { SecondarySourceTypeName: 'Bid' }
      // )

      if (JSON.parse(data).action === "ping" && this.ws.readyState === 1) {
        try {
          setTimeout(() => {
            try {
              this.ws.send(JSON.stringify({ action: "ping" }));
            } catch (e) {
              // console.log(e);
            }
          }, 5000);
        } catch (e) {
          // console.log(e);
        }
      }
    };

    this.ws.onerror = (event) => {
      // console.log("error websocket");
      // console.log(event);
    };
    this.ws.onclose = (event) => {
      // console.log("closed websocket");
      if (UserId) {
        setTimeout(() => {
          this.init(UserId);
          this.onMessage();
        }, 1000);
      }
      // else
      //   store.dispatch(
      //     updateProfile({ IsOnline: false, LastActive: Date.now() })
      //   );

      // console.log(event);
    };
  }
  //close sockets
  static onClose(handler) {
    // console.log("WebSocket closed: ", event);
    this.ws.close();
  }
  //Receive Msg
  static onMessage(handler) {
    this.ws.addEventListener("message", handler);
    // store.dispatch(
    //   updateProfile({ IsOnline: false, LastActive: Date.now() })
    // );
    // // console.log(JSON.stringify(handler));
  }
  static sendMessage(message) {
    // You can have some transformers here.
    // Object to JSON or something else...

    console.log("sending msg:", JSON.stringify(message));
    this.ws.send(message);
  }
}

// class WS {
//   static init(UserId, msg, users) {
//     this.ws = new WebSocket(`ws://localhost:3001?UserId=172901`);
//     this.ws.onopen = () => {
//       this.ws.send(
//         JSON.stringify({
//           action: "join",
//           user: UserId,
//         })
//       );
//       this.sendPing();
//     };

//     this.ws.onmessage = (event) => {
//       const { data } = event;
//       console.log("Received message: ", JSON.parse(data));

//       store.dispatch(
//         receivedMessages(
//           { ...JSON.parse(data) },
//           { SecondarySourceTypeName: "Bid" }
//         )
//       );
//       store.dispatch(getNotifications());
//       store.dispatch(
//         fetchInboxMessages("null", "all_conversations", null, 1, 1)
//       );
//     };

//     this.ws.onerror = (event) => {
//       console.log("WebSocket error: ", event);
//     };

//     this.ws.onclose = (event) => {
//       console.log("WebSocket closed: ", event);
//       this.reconnect(UserId);
//     };
//   }

//   static sendPing() {
//     setInterval(() => {
//       try {
//         this.ws.send(JSON.stringify({ action: "ping" }));
//       } catch (e) {
//         console.log("WebSocket error while sending ping: ", e);
//       }
//     }, 5000);
//   }

//   static onClose() {
//     this.ws.close();
//   }

//   static onMessage(handler) {
//     this.ws.addEventListener("message", handler);
//   }

//   static sendMessage(message) {
//     console.log("Sending message: ", message);
//     this.ws.send(message);
//   }

//   static reconnect(UserId) {
//     console.log("Attempting to reconnect WebSocket...");
//     setTimeout(() => {
//       console.log("Reconnecting WebSocket...");
//       this.init(UserId);
//     }, this.getReconnectDelay());
//   }

//   static getReconnectDelay() {
//     if (!this.reconnectDelay) {
//       this.reconnectDelay = 1000;
//     } else {
//       this.reconnectDelay *= 2;
//     }
//     return this.reconnectDelay;
//   }
// }

const mapDispatchToProps = (dispatch) => {
  return {
    saveMessage: (params) => {
      dispatch(receivedMessages(params)); // action dispatched
    },
  };
};

export default connect(mapDispatchToProps)(WS);
