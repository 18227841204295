import React, { useState, useEffect, useRef } from "react";
import "./WorkHistory.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import AddEditBox from "../../../../components/AddEditBox/AddEditBox";
import WorkHistoryForm from "./WorkHistoryForm";
import Loader from "../../../../components/Loader/Loader";

import { useUpdateLogger } from "../../../../shared/useUpdateLogger";

import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { updateWorkHistory } from "../../../../redux/actions";
import { getText } from "../../../../shared/helpers";

const WorkHistory = (props) => {
  const { skillListingDetailPage, addEdit, setAddEdit } = props;
  const dispatch = useDispatch();

  const [addState, setAddState] = useState(false);

  const [totalPercent, setTotalPercent] = useState(0);
  const [error, setError] = useState(false);

  const [updateWork, setUpdateWork] = useState(null);

  const formSubmitRef = useRef();
  const refList = [];

  const [styleContact, setStyleContact] = useState({ display: "none" });
  const [dimensions, setDimensions] = useState(window.innerWidth);

  const loading = useSelector((state) => state.profileReducer.loadingWH);
  const WorkHistory = useSelector(
    (state) => state.profileReducer.talentInfo.WorkHistory
  )?.filter((obj) => obj.Deleted === false);

  const deleteWorkHistory = (body, id) => {
    dispatch(updateWorkHistory(body, id));
    // setAddEdit(false);
  };

  // useUpdateLogger("WorkHistory", WorkHistory);

  return (
    <div
      className="workHistory"
      // onMouseEnter={(e) => {
      //   if (dimensions < 768) return;
      //   setStyleContact({
      //     display: "inline",
      //     // paddingTop: "5px",
      //   });
      // }}
      // onMouseLeave={(e) => {
      //   setStyleContact({ display: "none" });
      // }}
    >
      <section className="titleWorkHistory">
        <div className="title">Work history</div>
        {(!skillListingDetailPage || WorkHistory.length > 0) &&
          // dimensions > 768 &&
          !addEdit && (
            <div
              className="add-title"
              onClick={() => {
                setAddEdit(true);
                setUpdateWork(null);
              }}
            >
              Add new
            </div>
          )}
      </section>
      {skillListingDetailPage && <div className="dividerSkillListing"></div>}
      <section>
        {addEdit && (
          <>
            <AddEditBox
              input={
                <WorkHistoryForm
                  formSubmitRef={formSubmitRef}
                  setAddState={setAddState}
                  addState={addState}
                  setFormVisiblity={setAddEdit}
                  WorkHistoryForm={true}
                  totalPercent={totalPercent}
                  setTotalPercent={setTotalPercent}
                  error={error}
                  setError={setError}
                />
              }
              add={() => {
                if (totalPercent !== 100) {
                  setError(true);
                }
                // if (addState) {
                formSubmitRef.current.click();
                // }
              }}
              cancel={() => setAddEdit(false)}
              addState={addState}
            />
            <div style={{ marginBottom: "20px" }}></div>
          </>
        )}
        {loading ? (
          <Loader height="max-content" scrolling={false} />
        ) : (
          <>
            {WorkHistory?.length > 0 ? (
              <>
                {WorkHistory?.map((history, i) => {
                  refList.push(React.createRef());
                  return (
                    <>
                      {updateWork === history._id ? (
                        <>
                          <AddEditBox
                            input={
                              <WorkHistoryForm
                                formSubmitRef={refList[i]}
                                setUpdateWork={setUpdateWork}
                                setAddState={setAddState}
                                addState={addState}
                                idData={history._id}
                                TitleData={history.Title}
                                DescriptionData={history.Description}
                                CompanyNameData={history.CompanyName}
                                StartDateData={history.StartDate}
                                EndDateData={history.EndDate}
                                CurrentJobData={history.CurrentJob}
                                LocationData={history.Location}
                                ResponsibilitiesData={
                                  history.Responsibilities
                                    ? history.Responsibilities
                                    : history?.Consultants_CareerRecords_Responsibilities?.map(
                                        (resp) => `${resp.Description} `
                                      )
                                }
                                IndustryNameData={history.IndustryName}
                                SkillsData={history.Skills}
                                WorkHistoryForm={true}
                                totalPercent={totalPercent}
                                setTotalPercent={setTotalPercent}
                                error={error}
                                setError={setError}
                              />
                            }
                            add={() => {
                              // checking the code is reflect
                              if (totalPercent !== 100) {
                                setError(true);
                              }
                              // if (addState) {
                              refList[i].current.click();
                              // }
                            }}
                            cancel={() => {
                              setUpdateWork(null);
                            }}
                            addState={addState}
                            addUpdate={true}
                          />
                          <div style={{ marginBottom: "20px" }}></div>
                        </>
                      ) : (
                        <section className="workHistory-Data">
                          <div className="company-WH">
                            <div className="companyTitleEditDelete">
                              <span className="titleWH">
                                {history.CompanyName}
                              </span>
                              <div
                                className="editDelete"
                                // style={styleContact}
                              >
                                <span
                                  onClick={() => {
                                    setUpdateWork(history._id);
                                    setAddEdit(false);
                                  }}
                                >
                                  <AiOutlineEdit className="iconColorED clickable" />
                                </span>
                                <span
                                  onClick={() =>
                                    deleteWorkHistory(
                                      {
                                        Title: history.Title,
                                        Description: history.Description,
                                        CompanyName: history.CompanyName,
                                        StartDate: history.StartDate,
                                        EndDate: history.EndDate,
                                        CurrentJob: history.CurrentJob,
                                        Location: history.Location,
                                        Responsibilities:
                                          history.Responsibilities,
                                        IndustryName: history.IndustryName,
                                        Skills: history.Skills,
                                        Deleted: true,
                                      },
                                      history._id
                                    )
                                  }
                                >
                                  <RiDeleteBin5Fill
                                    className="iconColorED clickable"
                                    style={{ marginLeft: "10px" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <span>{history.Title}</span>
                            <span>{`${moment(history.StartDate).format(
                              "LL"
                            )} - ${
                              history.EndDate === null
                                ? "Current"
                                : moment(history.EndDate).format("LL")
                            }`}</span>
                            <span>{`Located in ${history.Location}`}</span>
                          </div>
                          <div className="skills-panel-WH">
                            <div className="skillTitle-WH">Skills</div>
                            <div className="skillsPercent">
                              {history.Skills.map((skill) => {
                                return (
                                  <div className="skill-WH">{`${skill.SkillName} - ${skill.SkillPercent}%`}</div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="responsibilities-WH">
                            <span className="res-title-WH">
                              Responsibilities given
                            </span>
                            <div
                              className="res-para-WH"
                              dangerouslySetInnerHTML={{
                                __html: getText(
                                  history.Responsibilities
                                    ? history.Responsibilities
                                    : history?.Consultants_CareerRecords_Responsibilities?.map(
                                        (resp) => `${resp.Description} `
                                      )
                                ),
                              }}
                            ></div>
                          </div>
                        </section>
                      )}
                    </>
                  );
                })}
              </>
            ) : (
              <>
                {!addEdit && (
                  <p className="add-your-work">Add your work experience</p>
                )}
              </>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default WorkHistory;
