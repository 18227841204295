import { Empty } from "antd";
import { types } from "../action-types";

const initialState = {
  loading: false,
  bidStatus: [],
  bids: [],
  totalCount:0,
  loadingBidRequest:false,
  error: {},
  declinedReasonOffer:[],
  retractedBidData: [],
  placeBidData: [],
  isRetractedBids: false,
  detailPlacedBid: {},
  detailReceivedBid: {},
  ManageBidCountPlaceBid:"",
  ManageBidCountRecivedBid:"",
  declinedReasonsBid: [],
  Filter: {
    placeBidFilter: [
      {
        Id: undefined,
        name: "All",
      },
    ],
    placeBidSort: [
      {
        bidSort: "most_recent",
      },
    ],
    placeBidPage: [
      {
        bidPage: 1,
      },
    ],

    recivedBidFilter: [
      {
        Id: undefined,
        name: "All",
      },
    ],
    recivedBidSorts: [
      {
        bidSort: "most_recent",
      },
    ],
    recivedPage: [
      {
        bidPage: 1,
      },
    ],
  },
};

const BidsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TALENT_STAGES_FILTER_RETAIN:
      // console.log(action.payload, "placedBidStageplacedBidStage");
      return {
        ...state,
        Filter: {
          ...state.Filter,
          [action.payload.BidType]: action.payload,
        },
      };

    case types.FETCH_PLACE_BID_REQUESTED:
      return {
        ...state,
        loading: true,
        loadingBidRequest:true,
      };

    case types.FETCH_PLACE_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingBidRequest:false,
        bids: action.payload?.body?.data,
        totalCount: action.payload?.totalResult,
        placeBidData: action.payload,
      };
      case types.FETCH_PLACE_BID_FAILURE:
        return{
          ...state,
          loading:false,
          loadingBidRequest:false,
          bids:[],
          totalCount:0,
          placeBidData:[],
        }

    case types.FETCH_RECEIVEDBIDS_REQUESTED:
      return {
        ...state,
        loading: true,
        loadingBidRequest:true,
      };

    case types.FETCH_RECEIVEDBIDS_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        bidStatus: action.payload,
      };

    case types.FETCH_RECEIVEDBIDS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingBidRequest:false,
        bids: action.payload?.body?.data,
        totalCount:action.payload?.totalCount,
        error: {},
      };
    case types.FETCH_RECEIVEDBIDS_FAILURE:
      return {
        ...state,
        loading: false,
        bids: [],
        error: action.error,
      };

      case types.FETCH_RECEIVEDBID_COUNT_REQUESTED:
        return {
          ...state,
          loading: true,
          loadingBidRequest:true,
        };
  
      case types.FETCH_RECEIVEDBID_COUNT_SUCCESS:
        return {
          ...state,
          loading: false,
          loadingBidRequest:false,
          totalCount:action.payload?.totalCount,
          error: {},
        };
      case types.FETCH_RECEIVEDBID_COUNT_FAILURE:
        return {
          ...state,
          loading: false,
          bids: [],
          error: action.error,
        };

    case types.FETCH_RETRACTED_BID_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_RETRACTED_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        retractedBidData: action.payload,
      };

    case types.RETRACTED_BID_UPDATE:
      return {
        ...state,
        loading: false,
        isRetractedBids: action.payload,
      };

    case types.FETCH_DETAIL_PLACE_BID_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_DETAIL_PLACE_BID_SUCCESS:
      // // console.log("FETCH_DETAIL_PLACE_BID_SUCCESS", action.payload);
      // debugger;
      return {
        ...state,
        loading: false,
        detailPlacedBid: action.payload,
      };

    case types.FETCH_DETAIL_PLACE_BID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FETCH_DETAIL_RECEIVED_BID_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_DETAIL_RECEIVED_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        detailReceivedBid: action.payload,
      };

    case types.FETCH_DETAIL_RECEIVED_BID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FAVORITE_BID_UPDATE:
      const obj = action.payload;

      if (obj?.type === "Gig" || obj.stage !== "Favorites") return;

      const filtereData = state.bids.body.data.filter(
        (item) => item._id !== obj?.id
      );

      return {
        ...state,
        bids: {
          ...state.bids,
          body: { ...state.bids.body, data: filtereData },
        },
      };

    case types.FETCH_DECLINED_REASONS_BIDS_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_DECLINED_REASONS_BIDS_SUCCESS:
      if(action.payload.type == "bid" ){
        return {
          ...state,
          loading: false,
          declinedReasonsBid: action.payload.data,
          declinedReasonOffer: action.payload.data
        };
      }
      else
      return {
        ...state,
        loading: false,
        declinedReasonsBid: action.payload.data,
      };

    case types.FETCH_DECLINED_REASONS_BIDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.UPDATE_DECLINED_REASONS_BIDS_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_DECLINED_REASONS_BIDS_SUCCESS:
      return {
        ...state,
        loading: false,
        detailReceivedBid: {
          ...state.detailReceivedBid,
          BidStatusName: action.payload.BidStatusName,
          DeclineReason: action.payload.DeclineReason,
          ranking: {
            ...state.ranking,
            BidStatusName: action.payload.BidStatusName,
            BidStatusId: action.payload.BidStatusId,
          },
        },
      };

    case types.UPDATE_DECLINED_REASONS_BIDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    
    case types.MANAGE_BID_COUNT_FETCH:
      return {
        ...state,
        ManageBidCountPlaceBid: action?.payload?.totalPlacedBid?.count ? action?.payload?.totalPlacedBid?.count : 0,
        ManageBidCountRecivedBid: action.payload.totalRecivedBid?.count ? action.payload.totalRecivedBid?.count : 0,
      };

    default:
      return state;
  }
};

export default BidsReducer;
