import React from "react";
import { useLocation } from "react-router-dom";
import BidHeader from "./BidHeader";
import BidForm from "./BidForm-new";
import EditFormBtns from "./EditFormbtns";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const EditBid = () => {
  const { state } = useLocation();
  const { id, jobOrder } = state;

  return (
    <div>
      {/* <BidHeader
                BidApply="Edit A Bid"
                bidspan="We're glad you found your dream gig - now let's get your bid submitted." /> */}
      <div>
        <BidForm method="put" id={id} jobOrder={jobOrder}>
          <EditFormBtns retractbtn="retract" id={id} />
        </BidForm>
      </div>
    </div>
  );
};
export default EditBid;
