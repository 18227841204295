import React from "react";
import { notification } from "antd";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IoAlertCircle } from "react-icons/io5";
import { AiFillWarning } from "react-icons/ai";
import closeIcon from "../../assets/icons/notifiClose.svg";
import ChatIconGreen from "../../assets/icons/ChatIconGreen.png";

import "./alertNotification.css";
import { FaGrinBeam, FaRegSmileBeam, FaSmileBeam } from "react-icons/fa";

// types success, info, warning, error
// define CSS of the type that is not in the .css file
// also import icon and set as per type if not present
export const alertNotification = (type, message, Stages, messageRoute) => {
  let icon = null;
  if (type === "success") {
    icon = <BsFillCheckCircleFill style={{ color: "#fff" }} />;
  }
  if (type === "warning") {
    icon = <IoAlertCircle style={{ color: "#fff" }} />;
  }
  if (type === "error") {
    icon = <AiFillWarning style={{ color: "#fff" }} />;
  }
  if (type === "success" && Stages) {
    icon = <FaRegSmileBeam style={{ color: "#fff" }} size={40} />;
  }

  return (
    <div className="alertNotificationAppWrap">
      {notification[type]({
        message: message ? message : type,
        duration: 4,
        className: `${
          Stages ? "Stages-alert" : "alertNotificationApp"
        }  ${type}`,
        top: 0,
        icon,
        maxCount: 4,
        btn: (
          <>
            <span className="chatButton-Mobile" onClick={messageRoute}>
              <span>Chat with the employer</span>
              <img src={ChatIconGreen} />
            </span>
          </>
        ),
        closeIcon: <img src={closeIcon} alt="close" />,
      })}
    </div>
  );
};
