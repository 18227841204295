import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../assets/icons/Logo.svg";
import burgerMenu from "../../assets/icons/ic_round-menu.svg";
import avatar from "../../assets/img/avatar.png";
import SkillGigss from "../../assets/icons/SkillGigss.svg";
import { connect } from "react-redux";

import "./Header.css";
import Sidebar from "../../pages/Credentialing/components/Sidebar";

import {
  ConsoleSqlOutlined,
  LockOutlined,
  MailOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { MdEmail } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdOutlineMessage } from "react-icons/md";
import { BsBell } from "react-icons/bs";
import { CgBell } from "react-icons/cg";
import { IoIosStar } from "react-icons/io";

import { GiHamburgerMenu } from "react-icons/gi";
// import Message from "./../../assets/icons/Message.svg"

import {
  Modal,
  Tooltip,
  Popover,
  Badge,
  Drawer,
  Layout,
  Menu,
  Dropdown,
} from "antd";

import ProfileHeader from "./components/ProfileHeader";
import { Link, Switch, useHistory } from "react-router-dom";
import GuestSearchGigs from "../../pages/SearchGigs/GuestUser/GuestSearchGigs";
import { useLocation, useRouteMatch } from "react-router-dom";
import { sectionHeader } from "@aws-amplify/ui";
import {
  checkEmailFlow,
  fetchMessagesCounts,
  fetchUserProfile,
  getNotifications,
  isModalVisibleAuth,
  SearchValue,
  signUpLoginUpdate,
  fetchInboxMessages,
} from "../../redux/actions";
import { getCookie, setCookie } from "../../shared/helpers";
import SignUpLogin from "../../pages/Auth/components/SignUpLogin";
import SearchBar from "../../pages/SearchGigs/SearchBar";
import SearchBarMobile from "../../pages/SearchGigs/components/SearchBarMobile";
import ManageBidsMenu from "./components/ManageBidsMenu";
// import ProfileAvatar from "../ProfileAvatar";
import ProfileAvatar from "../../pages/NewDashboard/Components/ProfileAvatar/ProfileAvatar";
import Timer from "../Timer";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import Notifications from "../notifications/notifications";
import { BaseUrl } from "../Footer";
import messageIcon from "../../assets/icons/Group 28192.svg";
import notificationIcon from "../../assets/icons/Group 28193.svg";
import { NewProfileHeader } from "./components/NewProfileHeader";
import ModalEdit from "../../pages/Manage_SkillListing/ModalEdit";
import RoleExperienceNew from "../../pages/WelcomeWizard/RoleExperienceNew";

const Header = (props) => {
  const { id, emailVerified, WizardFlow, EmailVerification } = props;
  const [loggedInSearchBar, setloggedInSearchBar] = useState(false);
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const initialPath = useLocation().pathname.split("/")[1];
  const subrout = useLocation().pathname.split("/")[2];
  const [sbarVisible, setsbarVisible] = useState(false);
  const [proImg, setProImg] = useState(null);
  const [userName, setUserName] = useState("");
  const [profession, setProfession] = useState("");
  const [isNotification, setisNotification] = useState("");
  const [dropVisibleNotifications, setDropVisibleNotifications] =
    useState(false);

  const signUpLogin = useSelector((state) => state.authReducer.signUpLogin);
  const notificationUnreadCount = useSelector(
    (state) => state.NotificationReducer.notificationUnreadCount
  );
  const isModalVisibleAuthRestPassword = useSelector(
    (state) => state.authReducer.isModalVisibleAuthRestPassword
  );

  // console.log(isModalVisibleAuthRestPassword, "isModalVisibleAuthRestPassword");

  const counts = useSelector((state) => state.getChatHistoryReducer.counts);
  const [isCountVisible, setIsCountVisible] = useState({
    message: true,
    notification: true,
  });
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;
  const urlCheck = useLocation();
  const skillListing = useSelector(
    (state) => state.skillListingReducer.skillListings
  );

  const history = useHistory();

  // const [isModalVisibleAuth, setIsModalVisibleAuth] = useState(false);
  const { isModalVisibleAuth, setIsModalVisibleAuth } = props;

  const [editModalState, setEditModalState] = useState(false);
  const [oneSkillListing, setOneSkillListing] = useState();
  const [skillId, setSkillId] = useState();

  const { authToken } = props;

  const showModal = () => {
    setIsModalVisibleAuth(true);
  };

  const modalCancel = () => {
    setIsModalVisibleAuth(false);
  };

  const [check, setCheck] = useState("");
  const [header, setHeader] = useState("");
  let headerType = "";

  const [drawerState, setDrawerState] = useState({
    visible: false,
    placement: "left",
  });

  const [drawerStates, setDrawerStates] = useState({
    visibles: false,
    placements: "left",
  });

  const [sgLogo, setSgLogo] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);

  const [dropVisible, setDropVisible] = useState(false);
  const [dropDisable, setDropDisable] = useState(false);

  const [dropVisibleBids, setDropVisibleBids] = useState(false);

  const handleVisibleChange = (visible) => {
    setDropVisible(visible);
  };

  const handleVisibleChangeBids = (visible) => {
    setDropVisibleBids(visible);
  };

  const hideDrop = () => {
    setDropVisible(false);
  };

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  const [IsOnline, setIsOnline] = useState(true);
  const onlineUsers = useSelector(
    (state) => state.getChatHistoryReducer?.onlineUsers
  );

  useEffect(() => {
    function checkNetworkStatus() {
      // Network is down
      // Set user as offline/online
      setIsOnline(navigator.onLine);
    }

    // Check network status periodically
    setInterval(checkNetworkStatus, 5000);
  }, []);

  // useEffect(() => {
  //   const isUserActive =
  //     onlineUsers?.length &&
  //     onlineUsers?.filter(
  //       (i) => Number(i?.userId) === props?.profileImg?.AccountId
  //     )?.[0]?.IsUserOnline;
  //   setIsOnline(props?.profileImg?.IsOnline || isUserActive);
  // }, [props?.profileImg?.IsOnline, onlineUsers]);

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
    if (
      getCookie("talentToken") !== null &&
      getCookie("talentToken").length > 0
    ) {
      const talentToken = getCookie("talentToken");
      props.profileDetails(talentToken);
    }
  }, []);

  useEffect(() => {
    if (authToken != null) {
      dispatch(getNotifications());
      dispatch(fetchMessagesCounts());
      dispatch(fetchInboxMessages("all_bids", "all_conversations", "null"));
    }
  }, [authToken]);

  useEffect(() => {
    if (dimensions <= 1000) {
      setSgLogo(true);
    } else setSgLogo(false);
  }, [dimensions]);

  const clicked = () => {
    setsbarVisible(!sbarVisible);
  };

  const signInUser = () => {
    if (window.innerWidth < 1000) {
      window.location.replace("/login");
      setDrawerStates({ visibles: false });
    } else {
      showModal();
    }
    dispatch(checkEmailFlow(false));
    dispatch(signUpLoginUpdate(false));
  };

  const joinUser = () => {
    if (window.innerWidth < 1000) {
      dispatch(SearchValue(""));
      history.push(`/${initialPath == "signup" ? "login" : "signup"}`);
      setDrawerStates({ visibles: false });
    } else {
      showModal();
    }
    dispatch(signUpLoginUpdate(true));
  };

  useEffect(() => {
    modalCancel();
  }, [urlCheck]);

  useEffect(() => {
    setSkillId(skillListing?.data?.[0]?.SkillListingId);
    setOneSkillListing(skillListing?.data?.[0]?._id);
  }, []);

  let headerContent = (
    <span>
      <LockOutlined /> Secured connection
    </span>
  );

  const showDrawer = () => {
    setDrawerState({ visible: true });
  };

  const onClose = () => {
    setDrawerState({ visible: false });
  };

  const showDrawers = () => {
    setDrawerStates({ visibles: true });
  };

  const onCloses = () => {
    setDrawerStates({ visibles: false });
  };

  useEffect(() => {
    if (props.profileImg) {
      setProImg(props.profileImg.PictureUrl);
    }
    const firstName = props?.profileImg?.FirstName
      ? props.profileImg?.FirstName
      : "";
    const lastName = props?.profileImg?.LastName
      ? props.profileImg?.LastName
      : "";
    const Name = firstName + " " + lastName;
    if (Name) {
      setUserName(Name);
    }
    // Displayed User Name
    // if (props?.profile?.length > 10) {
    //   const name = props?.profile?.split(" ")[0];
    //   setUserName(name);
    // } else {
    //   setUserName(props.profile);
    // }

    const pro = props?.firstUser?.profile?.ProfessionCode;
    if (pro === "IT") {
      setProfession("IT");
    } else if (pro === "FL") {
      setProfession("IT Freelancer");
    } else {
      setProfession("Health Care");
    }

    // console.log("Profile:", props.firstUser.profile.ProfessionCode);
  }, [props.profile, props?.profileImg]);

  const openMessages = () => {
    history.push({
      pathname: "/messages",
      state: {
        user: props.profile,
      },
    });
  };
  localStorage.setItem("userName", props.profile);

  const homepage = () => {
    window.location.replace("/");
    setDrawerStates({ visibles: false });
  };

  const searchedDeta = useSelector(
    (state) => state.onClearSearchReducer.Searchinfo
  );
  // console.log("serch", searchedDeta);

  const [search, setSearch] = searchedDeta;
  // console.log("serch23", searchedDeta, search);
  const searchedValueDeta = useSelector(
    (state) => state.onClearSearchReducer.searchValueInfo
  );
  // console.log("serchtwice", searchedValueDeta);

  const [isOpened, setisOpened] = searchedValueDeta;
  // console.log("serchab", searchedValueDeta[0], isOpened);

  const searchedCloseIconDeta = useSelector(
    (state) => state.onClearSearchReducer.searchCloseIconInfo
  );
  // console.log("visibleclose", searchedCloseIconDeta);

  const [isVisibleCloseIcon, setIsVisibleCloseIcon] = searchedCloseIconDeta;
  const [editModalStateRL, setEditModalStateRL] = useState(false);
  const [theLoading, setTheLoading] = useState(false);

  // useEffect for the Profile Edit Modal
  useEffect(() => {
    setTheLoading(false);
  }, [props.firstUser.profile]);

  useEffect(() => {}, [search, isOpened, isVisibleCloseIcon]);
  const clearSearchdata = () => {
    setSearch("");
    setisOpened(false);
    setIsVisibleCloseIcon(false);
    if (authToken) {
      // history.push("/");
      WizardFlow === 6 && history.push("/dashboard");
    } else {
      window.location.replace(`${BaseUrl}/`);
    }
  };

  useEffect(() => {
    setIsModalVisibleAuth(
      (initialPath == "login" || initialPath == "signup") &&
        window.innerWidth > 1000
    );
  }, []);

  const noti = (
    <Menu>
      <Menu.Item>hello</Menu.Item>
    </Menu>
  );
  // window.location.reload();

  // const ManageBidsMenu = (
  //   <Menu>
  //     <section className="header-manage-bids-menu">
  //       <div className="header-recieved-bids-text">
  //         <span>Received Bids</span>
  //         <span className="bids-count-header">3</span>
  //       </div>
  //       <div className="placed-bids-header">Placed Bids</div>
  //       <div>Retracted Bids</div>
  //     </section>
  //   </Menu>
  // );
  const handleVisibleNotifications = (visible) => {
    setDropVisibleNotifications(visible);
  };

  let mobileViewHeader = window.innerWidth < 1000 && (
    <nav
      className={
        authToken && loggedInSearchBar
          ? "search-header-mobile-view searchHeaderMobileViewLoggedIn"
          : `search-header-mobile-view`
        // ${
        //     id &&
        //     !emailVerified &&
        //     WizardFlow > 5 &&
        //     "extra-drop-not-verified-search"
        //   }`
      }
    >
      <SearchBar
        loggedInSearchBar={loggedInSearchBar}
        setloggedInSearchBar={setloggedInSearchBar}
      />
    </nav>
  );

  const commonHeader = (
    <div className="wizard-head-dashboard" style={{ color: "white" }}>
      <div className="bars" onClick={showDrawer}>
        {/* <GiHamburgerMenu /> */}
        <img src={burgerMenu} alt="logo" className="logo" />
      </div>
      {/* <div style={{ flex: 1 }}></div> */}
      {window.innerWidth > 1000 && <SearchBar />}
      {/* <div style={{ flex: 1 }}></div> */}
      {window.innerWidth < 1000 && (
        <img src={logo} alt="logo" className="logo" onClick={clearSearchdata} />
      )}
      <div className="headerMenuRight">
        <Link className="headerBtn" to="/">
          <div className="inbox">Home</div>
        </Link>
        <Link className="headerBtn" to="/dashboard">
          <div className="dashboard">Dashboard</div>
        </Link>
        <Link className="headerBtn" to="/manageskilllisting">
          <div className="skillListing">
            {profile?.Skilllistings > 1 ? "Skill listings" : "Skill listing"}
          </div>
        </Link>
        <div
          className="headerBtn headerBtnIcon"
          onClick={() => {
            setDropVisibleNotifications(true);
            setisNotification(true);
            setIsCountVisible((prev) => ({ ...prev, notification: true }));
          }}
        >
          <Dropdown
            overlayClassName={`notification-dropdown ${
              !isNotification ? "notification-dropdown-message" : ""
            }`}
            overlay={
              <Notifications
                visible={setDropVisibleNotifications}
                isTabSwitch={isNotification}
                setIsTabSwitch={setisNotification}
              />
            }
            onVisibleChange={() =>
              handleVisibleNotifications(!dropVisibleNotifications)
            }
            placement="bottom"
            trigger={["click"]}
            visible={dropVisibleNotifications}
            // disabled={dropDisable}
            arrow
            // autoFocus={true}
          >
            <Tooltip title="Notification">
              <Badge
                offset={[-4, 8]}
                size="small"
                count={
                  isCountVisible.notification ? notificationUnreadCount : 0
                }
                color="#fbdf50"
              >
                <img src={notificationIcon} alt="" />
                {/* <CgBell color="white" fontSize={25} /> */}
              </Badge>
            </Tooltip>
          </Dropdown>
        </div>
        <div
          className="headerBtn headerBtnIcon"
          onClick={(e) => {
            setDropVisibleNotifications(true);
            setisNotification(false);
            setIsCountVisible((prev) => ({ ...prev, message: true }));
          }}
        >
          <Tooltip title="Message">
            <Badge
              offset={[-4, 8]}
              size="small"
              count={
                counts?.Unread && isCountVisible.message ? counts?.Unread : 0
              }
              color="db2828"
              // onClick={() => history.push("/messages")}
            >
              <img src={messageIcon} alt="" />
              {/* <MailOutlined
                style={{ color: "white", fontSize: 25 }}
                fontSize={25}
              /> */}
            </Badge>
          </Tooltip>
        </div>
      </div>
      {window.innerWidth < 1000 && (
        <div className="mobileDefaultButtons">
          <div className="bellIcon">
            <Badge
              offset={[-4, 14]}
              size="small"
              count={isCountVisible.notification ? notificationUnreadCount : 0}
              color="#fbdf50"
              onClick={() => {
                // history.push("/notifications");
                setIsCountVisible((prev) => ({ ...prev, notification: true }));
                history.push({
                  pathname: `/notifications`,
                  state: {
                    fromMobileIsTabSwitch: true,
                  },
                });
              }}
            >
              <img src={notificationIcon} alt="" />
              {/* <BsBell color="white" fontSize={21} /> */}
            </Badge>
          </div>
          <div className="messageIconHeader">
            <Badge
              offset={[-4, 14]}
              size="small"
              count={
                counts?.Unread && isCountVisible.message ? counts?.Unread : 0
              }
              color="db2828"
              onClick={() => {
                // history.push("/notifications");
                setIsCountVisible((prev) => ({ ...prev, message: true }));
                history.push({
                  pathname: `/notifications`,
                  state: {
                    fromMobileIsTabSwitch: false,
                  },
                });
              }}
            >
              <img src={messageIcon} alt="" />
              {/* <MdOutlineMessage color="white" fontSize={23} /> */}
            </Badge>
          </div>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (initialPath == "credentialing") {
      setCheck(subrout);
    } else if (initialPath == "login") {
      setCheck(initialPath);
    } else if (initialPath == "search") {
      setCheck(initialPath);
    } else if (initialPath == "terms") {
      setCheck(initialPath);
    } else if (initialPath == "details") {
      setCheck(initialPath);
    } else if (initialPath == "forgot") {
      setCheck(initialPath);
    } else if (initialPath == "") {
      setCheck(initialPath);
    } else if (initialPath == "dashboard") {
      setCheck(initialPath);
    } else {
      setCheck(props.stage);
    }
    if (headerType == "default") {
      setHeader("black-header");
    }
    // console.log("Initial path", initialPath);
    // console.log("props stage", props.stage);

    // console.log("check", check);

    // dropdown disable state on pages
    if (
      initialPath === "talentemployer" ||
      initialPath === "profession" ||
      initialPath === "read-rules" ||
      initialPath === "profile-instruction" ||
      initialPath === "effective-profile-info" ||
      initialPath === "role-experience" ||
      initialPath === "resume-upload" ||
      initialPath === "basic-profile-success" ||
      initialPath === "create-skilllisting-signup" 
    ) {
      setDropDisable(true);
    } else setDropDisable(false);
  }, [initialPath, props.stage]);

  if (authToken != null) {
    headerContent = (
      <React.Fragment>
        <div className="wizard-head-dashboard" style={{ color: "white" }}>
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="Avatar">
          <div className="Avat">{userName?.charAt(0).toUpperCase()}</div>
        </div>
      </React.Fragment>
    );
  }

  switch (initialPath) {
    case "talentemployer":
    case "profession":
    case "role-experience":
    case "resume-upload":
    case "basic-profile-success":
    case "create-skilllisting-signup":
    case "read-rules":
    case "profile-instruction":
    case "effective-profile-info":
      headerContent = (
        <>
          {window.innerWidth < 1000 && (
            <div
              className="wizard-head-dashboard-guest"
              style={{ color: "white" }}
            >
              <img src={SkillGigss} alt="logo" className="logo-guest" />
            </div>
          )}
        </>
      );
      mobileViewHeader = <></>;
      break;

    default:
      headerType = "default";
      if (!authToken) {
        headerContent = (
          <div className="wizard-head-dashboard" style={{ color: "white" }}>
            <div className="bars" onClick={showDrawers}>
              <GiHamburgerMenu />
            </div>
            <img
              src={logo}
              alt="logo"
              className="logo"
              onClick={clearSearchdata}
            />
            {window.innerWidth > 1000 && <SearchBar />}
            <section className="logout-buttons-header">
              <div className="btn_home" onClick={homepage}>
                Home
              </div>
              {/* <div className="btn_sign_In" onClick={()=>{history.push('/login')}}> */}
              <div className="btn_sign_In" onClick={signInUser}>
                Sign in
              </div>
              <div className="theAlign">
                <div className="clickSkillListing" onClick={joinUser}>
                  {window.innerWidth > 1000
                    ? "Join"
                    : initialPath == "signup"
                    ? "Sign in"
                    : "Join"}
                </div>
              </div>
            </section>
          </div>
        );
      } else if (authToken && WizardFlow === 6) {
        headerContent = commonHeader;
      } else {
        headerContent = (
          <>
            {window.innerWidth < 1000 && (
              <div
                className="wizard-head-dashboard-guest"
                style={{ color: "white" }}
              >
                <img src={logo} alt="logo" className="logo-guest" />
              </div>
            )}
          </>
        );
      }
      break;
  }

  const { placement, visible } = drawerState;
  const { placements, visibles } = drawerStates;

  const handleUpdateSkillListing = (state) => {
    setEditModalState(state);
  };

  const titleDrawer = (
    <div style={{ backgroupColor: "rgba(17, 137, 54, 0.2)" }}>
      <div className="title-drawer-mobileView">
        {/* <div className="Avat">{userName.charAt(0).toUpperCase()}</div> */}
        {/* <Badge dot={true} status={"success" || "default"}>
          <div className="circle-wrap-p">
            <ProfileAvatar
              name={userName}
              location="Header"
              // profilePic={props.profileImg.PictureUrl}
              fontSize="1.25rem"
              onClick={() => {
                history.push("/profile");
                setDrawerState(false);
              }}
              headerImage="headerImage"
            />
          </div>
        </Badge> */}
        <div
          className={
            IsOnline ? "profileAvatarDesktop" : "profileAvatarDesktopOffline"
          }
          onClick={() => {
            history.push("/profile");
            setDrawerState({ ...drawerState, visible: false });
          }}
        >
          <ProfileAvatar
            name={userName}
            isOnline={IsOnline}
            size={60}
            offset={[-8, 0]}
            src={`${process.env.REACT_APP_S3_URL}/${props.profileImg.PictureUrl}`}
          />
        </div>
        <div className="title-data">
          <div className="username-header">
            <span>
              {props?.profile
                ? props.profileImg?.FirstName?.charAt(0).toUpperCase() +
                  props.profileImg?.FirstName?.slice(1).toLowerCase() +
                  " " +
                  props.profileImg?.LastName?.charAt(0).toUpperCase() +
                  props.profileImg?.LastName?.slice(1).toLowerCase()
                : ""}
            </span>
            <span className="star-title-BM">
              <IoIosStar color="#ffba08" style={{ fontSize: "1.13rem" }} />
            </span>
            <span className="rating-title-BM">
              {props.firstUser.Rating || 3.5}
            </span>
          </div>
          <div className="hired">{props.profileImg.Discipline}</div>
          <div className="profession-change">
            {/* <span className="profession-title">{profession}</span> */}
            <span
              className="change-title"
              onClick={() => {
                history.push("/profile");
                setDrawerState({ ...drawerState, visible });
              }}
            >
              <u>Change</u>
            </span>
          </div>
        </div>
      </div>
      <Timer />
    </div>
  );

  const titleDrawerlogin = (
    <div>
      <div className="DrowerHeader">
        <img src={avatar} />
        <div>
          <div className="Guestuserfont">Guest User</div>
          <div className="welcomefont">Welcome to SkillGigs</div>
        </div>
      </div>
    </div>
  );

  // useUpdateLogger("props hey", props);

  return (
    <React.Fragment>
      {/* {!emailVerified && id && WizardFlow > 5 && authToken && (
        <EmailVerification />
      )} */}
      {sbarVisible && (
        <Sidebar changeStage={props.changeStage} hide={clicked} />
      )}
      {window.innerWidth > 1000 ? (
        <Modal
          bodyStyle={{ padding: 0 }}
          width="24.37rem"
          // visible={isModalVisibleAuth}
          visible={
            (initialPath == "login" && !isModalVisibleAuthRestPassword) ||
            initialPath == "signup"
              ? true
              : isModalVisibleAuth
          }
          closable={false}
          footer={null}
          // style={{ top: 45, padding: 0 }}
          onCancel={modalCancel}
          maskClosable={
            initialPath == "login" || initialPath == "signup" ? false : true
          }
          centered={true}
          // destroyOnClose={true}
        >
          <SignUpLogin />
        </Modal>
      ) : (
        <></>
      )}
      <div
        className={`header ${headerType === "default" && "black-header"}`}
        // className={`header ${headerType === "default" && "black-header"} ${
        //   id &&
        //   !emailVerified &&
        //   WizardFlow > 5 &&
        //   authToken &&
        //   "extra-drop-not-verified"
        // } `}
      >
        {!sgLogo ? (
          <img
            src={logo}
            alt="logo"
            className="logo"
            onClick={clearSearchdata}
          />
        ) : (
          <>
            <Drawer
              // title={titleDrawer}
              placement="left"
              closable={false}
              onClose={onClose}
              visible={visible}
              className="burgerMenuDrawerMobileView"
              width="18.125rem"
              // key={placement}
            >
              <NewProfileHeader
                hide={onClose}
                setEditModalState={setEditModalState}
                visible={visible}
                setEditModalStateRL={setEditModalStateRL}
              />
            </Drawer>
            {window.innerWidth < 1000 && (
              <ModalEdit
                editModalState={editModalState}
                setEditModalState={setEditModalState}
                handleUpdateSkillListing={handleUpdateSkillListing}
                id={oneSkillListing || profile?.SkillListings?.[0]}
                idSkill={skillId}
              />
            )}
            {!theLoading && (
              <Modal
                centered
                footer={false}
                width="47.50rem"
                visible={editModalStateRL}
                destroyOnClose={true}
                wrapClassName="modalEditSkillListing profileModelRoleExperience"
                onCancel={() => setEditModalStateRL(false)}
                title={
                  <div className="createSkillistingTitle">Edit details</div>
                }
              >
                <RoleExperienceNew
                  setEditModalStateRL={setEditModalStateRL}
                  talentProfile={true}
                  setTheLoading={setTheLoading}
                />
              </Modal>
            )}
          </>
        )}
        {headerContent}
        {authToken &&
         window.innerWidth > 1000 &&
         initialPath !== "effective-profile-info" &&
         initialPath !== "profile-instruction" &&
         initialPath !== "read-rules" && (
          <>
            <Dropdown
              overlayClassName="header-dropdown"
              overlay={
                <NewProfileHeader
                  hide={hideDrop}
                  visible={dropVisible}
                  setEditModalStateRL={setEditModalStateRL}
                />
              }
              onVisibleChange={() => handleVisibleChange(!dropVisible)}
              placement="bottomRight"
              trigger={["click"]}
              visible={dropVisible}
              disabled={dropDisable}
              // arrow
            >
              <div
                className={
                  IsOnline
                    ? "profileAvatarDesktop"
                    : "profileAvatarDesktopOffline"
                }
              >
                <ProfileAvatar
                  name={userName}
                  isOnline={IsOnline}
                  src={`${process.env.REACT_APP_S3_URL}/${props.profileImg.PictureUrl}`}
                />
              </div>
            </Dropdown>
            {!theLoading && (
              <Modal
                centered
                footer={false}
                width="47.50rem"
                visible={editModalStateRL}
                destroyOnClose={true}
                wrapClassName="modalEditSkillListing profileModelRoleExperience"
                onCancel={() => setEditModalStateRL(false)}
                title={
                  <div className="createSkillistingTitle">Edit details</div>
                }
              >
                <RoleExperienceNew
                  setEditModalStateRL={setEditModalStateRL}
                  talentProfile={true}
                  setTheLoading={setTheLoading}
                />
              </Modal>
            )}
          </>
        )}

        <Drawer
          title={titleDrawerlogin}
          placement="left"
          closable={false}
          onClose={onCloses}
          visible={visibles}
          key={placements}
          className="burgerMenuDrawerMobileView"
        >
          <div className="clickSkillListinglogin" onClick={joinUser}>
            Join SkillGigs
          </div>
          <br></br>
          <div className="Mob_Btn">
            <span className="signIN" onClick={signInUser}>
              Sign in
            </span>
            <br></br>
            <span className="General_btn">General</span>
            <div class="Line-109"></div>
            <span className="homeBtn" onClick={homepage}>
              Home
            </span>
          </div>
        </Drawer>
      </div>
      {mobileViewHeader}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.user.name,
    profileImg: state.profileReducer.user.profile,
    firstUser: state.profileReducer.user,
    error: state.profileReducer.error,
    isModalVisibleAuth: state.authReducer.isModalVisibleAuth,
    authToken: state.authReducer.user.authToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    profileDetails: (talentToken) => {
      dispatch(fetchUserProfile(talentToken)); // action dispatched
    },
    setIsModalVisibleAuth: (params) => {
      dispatch(isModalVisibleAuth(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
