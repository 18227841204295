import React, { useEffect, useRef, useState } from "react";

import { FiPaperclip } from "react-icons/fi";
import { useSelector } from "react-redux";
import downloadChatFilesApi from "../../../shared/downloadFiles";

const OfferLetter = ({ isPlacedBidsStatus }) => {
  const refOffer = useRef();

  const [DetailPageData, setDetailPageData] = useState();

  const BidDetailData = useSelector(
    (state) => state.BidsReducer.detailReceivedBid
  );

  const PlacedBidDetailData = useSelector(
    (state) => state.BidsReducer.detailPlacedBid
  );

  const offer = DetailPageData?.offers?.Attachments[0];

  const handleDownload = async (filePath) => {
    // console.log("handle download", filePath);
    await downloadChatFilesApi(filePath);
  };

  useEffect(() => {
    {
      isPlacedBidsStatus
        ? setDetailPageData(PlacedBidDetailData)
        : setDetailPageData(BidDetailData);
    }
  }, [BidDetailData, isPlacedBidsStatus]);

  return (
    <section className="offerLetter-bid-details">
      <div className="viewOffer">View offer</div>
      <a
        ref={refOffer}
        href={`${process.env.REACT_APP_S3_URL}${
          offer?.FilePath?.charAt(0) != "/" ? "/" : ""
        }${offer?.FilePath}`}
        target="_self"
        style={{ display: "none" }}
      ></a>
      <div
        className={`offerletter ${offer ? "clickable" : null}`}
        onClick={() => refOffer.current.click()}
      >
        <FiPaperclip className="iconFontSize-stages1" />
        {offer?.FileName}
      </div>
    </section>
  );
};

export default OfferLetter;
