import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { DownloadOutlined, FileTextTwoTone } from "@ant-design/icons";
import downloadChatFilesApi from "../../../shared/downloadFiles";
import { useSelector, useDispatch } from "react-redux";
import { receivedMessage } from "../../../redux/actions";

const MessageContainer = (props) => {
  const success = useSelector((state) => state.getChatHistoryReducer.chats);
  const message = props.msgs.MessageText;

  // const [files,setFiles] = useState;
  const dispatch = useDispatch();
  const downloadFiles = async (filepath) => {
    try {
      const path = filepath.split("/");
      const para = await downloadChatFilesApi(path[2]);
      // // console.log(para.data.downloadURL);
      window.open(para.data.downloadURL);
    } catch {}
  };

  return (
    <>
      <div className="para">
        {success.length ? (
          <div dangerouslySetInnerHTML={{ __html: message }}></div>
        ) : (
          ""
        )}
        {/* dangerouslySetInnerHTML={{__html:success.length ? success[0].MessageText : ''}  */}
        {props.msgs.Attachments
          ? props.msgs.Attachments.map((files) => {
              // setFiles(files);
              return (
                <div>
                  <Col
                    md={15}
                    sm={12}
                    lg={12}
                    xs={23}
                    className={"msg-attachment-container"}
                    key={props.msgs._id}
                  >
                    <FileTextTwoTone style={{ marginRight: "5px" }} />
                    <a onClick={() => downloadFiles(files.FilePath)}>
                      {props.msgs.Attachments[0] ? files.FileName : ""}
                    </a>{" "}
                    <br />
                    <span className="span">
                      ({files ? files.FileSize : ""})bytes
                    </span>{" "}
                    <br />
                    <DownloadOutlined
                      className={"download-icon"}
                      onClick={() => downloadFiles(files.FilePath)}
                    />
                  </Col>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
};
export default MessageContainer;
