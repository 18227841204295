import { types } from "../action-types";

const initialState = {
  loading: false,
  notification: [],
  error: false,
};

const fetchNotificationReducer = (state = initialState, action) => {
  switch (action.types) {
    case types.GET_NOTIFICATION_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.GET_NOTIFICATION_SUCCESS:
      // const data = action.payload
      // const isarray = Array.isArray(notification)
      // const notifications = isarray ? notification?.filter(n => n != data) : notification
      // // console.log('notification success', action.payload, notification)
      
      return {
        ...state,
        loading: false,
        notification: action.payload,
        error: false,
      };
    case types.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        notification: [],
        error: true,
      };
    default:
      return state;
  }
};

export default fetchNotificationReducer;