import { call, put, takeEvery, select, all } from "redux-saga/effects";
import { types } from "../action-types";
import {
  fetchSearchSkillSuccess,
  updateSkillListingSuccess,
  gotSkillListingFailure,
  fetchUserSkillListingSuccess,
  fetchUserSkillListing,
  fetchUserSkillListingSuccessId,
  fetchOneSkillListingSuccess,
  fetchOneSkillListingFailure,
  fetchOneSkillListing,
  saveOneSkillListingId,
  catchError,
  fetchResumeDetailInit,
} from "../actions";
import {
  fetchSkillsApi,
  createSkillListingApi,
  updateSkillListingApi,
  fetchSearchDisciplineAPI,
  fetchUserSkillListingAPI,
  deleteSkillListingApi,
  fetchOneSkillListingAPI,
} from "../../API/API";
import { alertNotification } from "../../components/notifications/alertNotification";

function* fetchSkillSaga(request) {
  // console.log("Skill PAYLOAD IN SAGA:", request);
  try {
    const response = yield call(() => fetchSkillsApi(request)); // API Backend
    // console.log("SKILLISTING RESPONSE: ", response);
    if (response.status === 200) {
      yield put(fetchSearchSkillSuccess(response.data));
    } else {
      yield put(gotSkillListingFailure(response.data.error));
    }
  } catch (error) {
    // console.log(error);
    yield put(gotSkillListingFailure(error));
  }
}

function* fetchOneSkillListingSaga(request) {
  // console.log("fetchOneSkillListingSaga", request);
  try {
    const response = yield call(() => fetchOneSkillListingAPI(request.payload)); // API Backend
    // console.log(
    //  "fetchOneSkillListingSaga:SKILLISTING RESPONSE: ",
    //  response.data.data
    // );
    if (response.status === 200) {
      yield put(fetchOneSkillListingSuccess(response.data.data));
    } else {
      yield put(fetchOneSkillListingFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(fetchOneSkillListingFailure(error));
    yield put(catchError(error));
  }
}

function* createSkillListingSaga(params) {
  try {
    const response = yield call(() => createSkillListingApi(params)); // API Backend
    // console.log("UPDATE Create RESPONSE:", response);
    if (response.status == 200) {
      yield put(saveOneSkillListingId(response.data.SkillListingId));
      yield put(updateSkillListingSuccess(response.data));
      yield put(fetchResumeDetailInit());
    } else {
      yield put(gotSkillListingFailure(response.error));
    }
  } catch (error) {
    // console.log(error);
    yield put(gotSkillListingFailure(error));
  }
}

function* updateSkillListingSaga(params) {
  // debugger;
  // console.log("UPDATE SKILLISTING RESPONSE");
  try {
    const response = yield call(() => updateSkillListingApi(params)); // API Backend
    // // console.log("UPDATE SKILLLISTING RESPONSE:", response);
    if (response.status == 200) {
      // yield put(fetchUserSkillListing()); // New Data
      yield put(fetchOneSkillListing(response.data.data.SkillListingId)); // New Data

      yield put(updateSkillListingSuccess(response.data.data));
    } else {
      yield put(gotSkillListingFailure(response.error));
    }
  } catch (error) {
    // console.log(error);
    yield put(gotSkillListingFailure(error));
  }
}

function* deleteSkillListingSaga(id) {
  try {
    const response = yield call(() => deleteSkillListingApi(id)); // API Backend
    // console.log("Delete PROFILE RESPONSE:", response);
    // yield put(fetchUserSkillListing()); // New Data
    alertNotification("success", "Skill Listing deleted successfully");
    if (response.data.success) {
      yield put(updateSkillListingSuccess(response.data.data));
      yield put(fetchResumeDetailInit());
    } else {
      yield put(gotSkillListingFailure(response.error));
    }
  } catch (error) {
    // console.log(error);
    yield put(gotSkillListingFailure(error));
  }
}

function* fetchKeySearchDiscipline(params) {
  // console.log("DISCIPLINE in SAGA:", params);
  try {
    const response = yield call(fetchSearchDisciplineAPI, params); // API Backend
    // console.log("DISCIPLINE in SAGA response ", response);
    yield put({
      type: types.FETCH_SEARCH_DISCIPLINE_SUCCESS,
      payload: response.data,
    });
    // if (response.data.status === "success") {
    //   const data = {
    //     key: response.data.payload,
    //     CredentialDocumentId: params.params.documentId,
    //   };
    //   yield put(uploadDocSuccess(data));
    // } else {
    //   yield put(uploadDocFailure(response.errorMessage));
    // }
  } catch (error) {
    // console.log(error);
    yield put(gotSkillListingFailure(error));
  }
}

function* fetchUserSkillListingSaga(params) {
  // const [type, page, perPage] = params.payload;
  // console.log("SKILLLISTNG in SAGA:", params);
  // // console.log("SKILLLISTNG in SAGA:", type, page, perPage);
  try {
    const response = yield call(() => fetchUserSkillListingAPI(params.payload)); // API Backend
    // // console.log(response);
    // console.log("SKILLLISTING RESPONSE:", response.data.data);
    if (response.status == 200) {
      yield all([
        put(fetchUserSkillListingSuccess(response.data.data.body)),
        put(fetchUserSkillListingSuccessId(response.data.data.body)),
      ]);
    } else {
      yield put(gotSkillListingFailure(response.error));
    }
    // yield put({
    //   type: types.FETCH_USER_SKILLISTING_SUCCESS,
    //   payload: response.data.data.body,
    // });
    // }
  } catch (error) {
    // // console.log(error);
    yield put(gotSkillListingFailure(error));
    yield put(catchError(error));
  }
}

export function* watchFetchSkillSaga() {
  yield takeEvery(types.FETCH_SKILLS_INIT, fetchSkillSaga);
}

export function* watchCreateSkillListingSaga() {
  yield takeEvery(types.CREATE_SKILL_LISTING_INIT, createSkillListingSaga);
}

export function* watchUpdateSkillListingSaga() {
  yield takeEvery(types.UPDATE_SKILL_LISTING_INIT, updateSkillListingSaga);
}

export function* watchDeleteSkillListingSaga() {
  yield takeEvery(types.DELETE_SKILL_LISTING, deleteSkillListingSaga);
}
export function* watchKeySearchDiscipline() {
  yield takeEvery(types.FETCH_SEARCH_DISCIPLINE_INIT, fetchKeySearchDiscipline);
}

export function* watchFetchUserSkillListing() {
  yield takeEvery(types.FETCH_USER_SKILLISTING_INIT, fetchUserSkillListingSaga);
}

export function* watchFetchOneSkillListing() {
  yield takeEvery(types.FETCH_ONE_SKILLLISTING_INIT, fetchOneSkillListingSaga);
}
