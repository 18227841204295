import { call, put, takeEvery, select } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchSearchSuggestionsAPI } from "../../API/API";
import { fetchSearchSuggestionsFailure } from "../actions";


function* SearchSuggestionsSaga(params) {
  // console.log("Suggestions in SAGA:", params);
  try {
    const response = yield call(fetchSearchSuggestionsAPI, params); // API Backend
    // console.log(response);
    yield put({
      type: types.FETCH_SEARCH_SUGGESTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // console.log(error);
    yield put(fetchSearchSuggestionsFailure(error));
  }
}

export function* watchSearchSuggestionsSaga() {
  yield takeEvery(types.FETCH_SEARCH_SUGGESTIONS_INIT, SearchSuggestionsSaga);
}
