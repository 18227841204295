import { types } from "../action-types";

const initialState = {
    IsLocation:true,
    latitude:'',
};

const IsLocationReducer = (state = initialState, action) => {    
    switch (action.type) {
        case types.IS_LOCATION_DISABLE:
            state.IsLocation=action.payload.IsLocation;
            state.latitude=action.payload.latitude
        return state
        default:
            return state;
    }
};

export default IsLocationReducer;
