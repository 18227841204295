import React, { useEffect, useState } from "react";
import "./ConversationCard.css";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { Badge, Avatar, Tooltip } from "antd";
import moment from "moment";
import { getImage } from "../../../../shared/downloadAttachments";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessageToFavourite,
  removeMessageFromFavourite,
  getNotifications,
} from "../../../../redux/actions";
import { useHistory } from "react-router-dom";

export default function ConversationCard({
  records,
  setLoadChat,
  setshowHistory,
  setCreatedTime,
  selectedChat,
  SourceName,
  setSelectedChat,
  SourceId,
  Index,
}) {
  const dispatch = useDispatch();
  const [isFavorite, setIsFavorite] = useState(false);
  let userProfile = useSelector((state) => state.profileReducer.user.id);
  let isFav = useSelector((state) => state?.getChatHistoryReducer?.inbox?.data?.data?.body?.conversations[Index]?.IsFavorite);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const history = useHistory();

  //STATES***
  const [imageError, setImageError] = useState(false);
  const [unRead, setunRead] = useState(false);

  useEffect(() => {
    setunRead(false);
  }, [records]);



  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(()=> {
    setIsFavorite(isFav)
  },[isFav])

  var cache = {},
    character,
    e = document.createElement("div");

  const msgtxt = (html) => {
    return html?.replace(/([&][^&; ]+[;])/g, function (entity) {
      character = cache[entity];
      if (!character) {
        e.innerHTML = entity;
        if (e.childNodes[0])
          character = cache[entity] = e.childNodes[0].nodeValue;
        else character = "";
      }
      return character;
    });
  };

  const handleFavourite = (e) => {

    e.stopPropagation();
    if(isFavorite){
      dispatch(
        removeMessageFromFavourite({
          SourceId,
          SourceName,
          Index,
        })
        )
    }
    else{
      dispatch(
        addMessageToFavourite({
          SourceName,
          SourceId,
          Index
        })
      )
    }

    setIsFavorite(!isFavorite);
  };

  function encodeURIComponentSafe(s) {
    if (!s) {
      return s;
    }
    return encodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
  }

  return (
    <div
      className="conversation-card"
      onClick={() => {
        setCreatedTime(records?.Created);
        setunRead(true);
        if (setLoadChat) setLoadChat(true);
        setshowHistory(true);
        let recordData = {...records,IsFavorite: isFav}
        const encodeID = encodeURIComponentSafe(records._id);
        if(windowDimensions.width > 768) {
          if(history.location.pathname.includes('messages')) {
            history.replace({
              pathname: `/messages/${encodeID}`,
              state: {
                openConvo: true,
                conversationId: records._id,
                records: recordData,
                SourceName: SourceName,
                SourceId: SourceId,
                Index:Index,
                createdTime: records?.Created,
              },
            });
          } else {
            history.push({
              pathname: `/messages/${encodeID}`,
              state: {
                openConvo: true,
                conversationId: records._id,
                records: recordData,
                SourceName: SourceName,
                SourceId: SourceId,
                Index:Index,
                createdTime: records?.Created,
              },
            });
          }
        } else {
          history.push({
            pathname: `/messages/${encodeID}`,
            state: {
              openConvo: true,
              conversationId: records._id,
              records: recordData,
              SourceName: SourceName,
              SourceId: SourceId,
              Index:Index,
              loadChat: true,
            createdTime: records?.Created,
            },
          });
        } 
        
      }}
      style={{ backgroundColor: selectedChat == records._id && "#e4ffec" }}
      key={records._id}
    >
      <div className="conversation-card-star" onClick={(e) => handleFavourite(e)}>
        {!isFavorite ? (
          <StarOutlined style={{ fontSize: "22px", color: "#aaaaaa" }} />
        ) : (
          <StarFilled style={{ fontSize: "22px", color: "#ffba08" }} />
        )}
      </div>
      <div className="conversation-card-image">
        {records?.SenderUserPictureUrl && !imageError ? (
          <img
            onError={() => {
              setImageError(true);
            }}
            style={{ height: "100%", borderRadius: "50%", width: "inherit" }} //width: "100%",
            src={`${process.env.REACT_APP_S3_URL}${
              records?.SenderUserPictureUrl?.charAt(0) !== "/" ? "/" : ""
            }${records?.SenderUserPictureUrl}`}
          />
        ) : (
          <div className="image-container">
            <div className="image-text">
              {records?.SenderUserName?.split("")[0]?.toUpperCase()}
            </div>
          </div>
        )}
      </div>
      <div className="conversation-card-contentContainer">
        <Tooltip
          title={
            records?.SourceTypeId == 1
              ? records.SkillListing?.Title
              : records?.SourceTypeId == 2
              ? records.GigCard?.Title
              : ""
          }
        >
          <div
            style={{
              fontSize: "15px",
              color: "black",
              fontWeight: "500",
              fontStretch: "normal",
              textTransform: "capitalize",
            }}
          >
            {records?.SenderUserName}
          </div>
          <div className="conversation-card-contentContainer-title">
            {records?.JobTitle}
          </div>
        </Tooltip>
        <div className="conversation-card-bid-msg-content">
          {(records?.SourceTypeId === 1 && records?.MessageText) ===
          "Bid is placed"
            ? "Bid is Received"
            : msgtxt(records?.MessageText)}
        </div>
      </div>
      <div className="conversation-card-extraContent">
        <div className="conversation-card-extraContent-time">
          {moment(records?.Created).fromNow()}
        </div>
        {!unRead &&
          records?.countUnRead > 0 &&
          selectedChat !== records?._id && (
            <div className="conversation-card-extraContent-count">
              {records?.countUnRead}
            </div>
          )}
      </div>
    </div>
  );
}
