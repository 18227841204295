import { types } from "../action-types";

const initialState = {
  started: false,
  step: 0,
  step1: {
    profilePicture: null,
    resume: null,
    email: null,
    mobileNumber: null,
    cityState: [],
    zipCode: null,
    workExperience: [],
  },
  step2: {
    certificates: [
      {
        name: "State Certificate Verification",
        date: null,
        front: null,
        back: null,
      },
      {
        name: "PALS",
        date: null,
        front: null,
        back: null,
      },
      {
        name: "Certificate of Health",
        date: null,
        front: null,
        back: null,
      },
      {
        name: "BLS",
        date: null,
        front: null,
        back: null,
      },
      {
        name: "Nursys Verification",
        date: null,
        front: null,
        back: null,
      },
    ],

    healthRecords: [
      {
        name: "MMR(2 doses)",
        date: null,
        front: null,
        back: null,
      },
      {
        name: "Tdap",
        date: null,
        front: null,
        back: null,
      },
      {
        name: "Varicella",
        date: null,
        front: null,
        back: null,
      },
      {
        name: "Hep B or Signed Hep B Waiver",
        date: null,
        front: null,
        back: null,
      },
      {
        name: "Health Questionaire",
        date: null,
        front: null,
        back: null,
      },
    ],

    backgroundChecks: [
      {
        name: "Sams Check",
        date: null,
        front: null,
        back: null,
      },
      {
        name: "OIG Search",
        date: null,
        front: null,
        back: null,
      },
    ],
  },
  step3: {
    // needVisa: null,
    // stealthFromWebsites: null,
    // licensingIssues: null,
    jobType: null,
    DisplayTitle: null,
    roleDiscipline: "",
    salary: null,
    availableToStart: null,
  },
  step4: {
    desiredLocation: [],
    addYourSkill: [],
  },
  searchedLocation: [],
  searchLoading: false,
};

const credentialingReducer = (state = initialState, action) => {
  // // console.log("KEY", action.key);
  // if (action.key === "workExperience") {
  //   action.payload = JSON.parse(action.payload);
  // } else action.payload;
  switch (action.type) {
    case types.STEP_ONE: // // console.log(action.payloademail);
      // const check = { ...state.step1, email: action.payloademail };
      return {
        ...state,
        [action.stage]: {
          ...state[action.stage],
          [action.key]:
            action.key === "workExperience"
              ? JSON.parse(action.payload)
              : action.payload,
        },
      };
    case "CHANGE_TWO":
      // console.log(state[action.stage]);
      // console.log("ACTION:", action);
      // console.log("ARRAY:", state[action.stage][action.panel]);
      const newArr = [...state[action.stage][action.panel]];
      newArr[action.index] = {
        ...state[action.stage][action.panel][action.index],
        [action.item]: action.payload,
      };
      // // console.log("NEW ARRAY:", newArr);
      return {
        //step2.certificates[action.key]
        ...state,
        [action.stage]: {
          ...state[action.stage],
          [action.panel]: newArr,
        },
      };
    case types.SKILLISTING:
      // // console.log(state);
      // if (action.key === "desiredSalary") {
      //   return {
      //     ...state,
      //     [action.stage]: {
      //       ...state[action.stage],
      //       desiredSalary: {
      //         ...state[action.stage].desiredSalary,
      //         salary: action.payload,
      //       },
      //     },
      //   };
      // } else
      return {
        ...state,
        [action.stage]: {
          ...state[action.stage],
          [action.key]: action.payload,
        },
      };
    // case types.FETCH_SEARCH_LOCATION_SUCCESS:
    //     return {
    //       ...state,
    //       searchedLocation: action.payload,
    //       },

    case types.FETCH_SEARCH_LOCATION_INIT:
      return {
        ...state,
        searchLoading: true,
      };
    case types.FETCH_SEARCH_COUNTRY_INIT:
      return {
        ...state,
        searchCountryLoading: true,
      };
    case types.FETCH_SEARCH_LOCATION_SUCCESS:
      return {
        ...state,
        searchedLocation: action.payload,
        searchLoading: false,
      };
    case types.FETCH_SEARCH_COUNTRY_SUCCESS:
      return {
        ...state,
        searchCountry: action.payload,
        searchCountryLoading: false,
      }

    case types.FETCH_SEARCH_REGION_INIT:
      return {
        ...state,
        searchRegionLoading: true,
      };
    case types.FETCH_SEARCH_REGION_SUCCESS:
      return {
        ...state,
        searchedRegion: action.payload,
        searchRegionLoading: false,
      };

    case types.FETCH_SEARCH_CITY_INIT:
      return {
        ...state,
        searchCityLoading: true,
      };
    case types.FETCH_SEARCH_CITY_SUCCESS:
      return {
        ...state,
        searchedCity: action.payload,
        searchCityLoading: false,
      };
    case types.FETCH_EXPERINCE_LEVEL_INIT:
      return {
        ...state,
        experinceLevelLoading: true,
      };
    case types.FETCH_EXPERINCE_LEVEL_SUCCESS:
      return {
        ...state,
        experinceLevels: action.payload,
        experinceLevelLoading: false,
      };

    case types.FETCH_EXPERIENCE_SAVE:
      const workExperience = action.payload;
      return {
        ...state,
        [action.stage]: {
          ...state[action.stage],
          workExperience,
        },
      };

    case types.SEARCH_DISCIPLINE_SAVE:
      const roleDiscipline = action.payload;
      return {
        ...state,
        [action.stage]: {
          ...state[action.stage],
          roleDiscipline,
        },
      };

    case types.DISPLAY_TITLE_SAVE:
      const DisplayTitle = action.payload;
      return {
        ...state,
        [action.stage]: {
          ...state[action.stage],
          DisplayTitle,
        },
      };

    case types.FETCH_SEARCH_LOCATION_SAVE:
      const desiredLocation = action.payload;
      return {
        ...state,
        // searchedLocation: [...state?.searchedLocation, action.payload],
        [action.stage]: {
          ...state[action.stage],
          desiredLocation,
          // [action.key]: [...state[action.stage][action.key], action.payload],
        },
      };
    case types.FETCH_COUNTRY_SAVE:
      const desiredCountry = action.payload;
      return {
        ...state,
        // searchedLocation: [...state?.searchedLocation, action.payload],
        [action.stage]: {
          ...state[action.stage],
          desiredCountry,
          // [action.key]: [...state[action.stage][action.key], action.payload],
        },
      };

    case types.UPDATE_SKILL_LISTING_SAVE:
      const addYourSkill = action.payload;
      return {
        ...state,
        // searchedLocation: [...state?.searchedLocation, action.payload],
        [action.stage]: {
          ...state[action.stage],
          addYourSkill,
          // [action.key]: [...state[action.stage][action.key], action.payload],
        },
      };

    default:
      return state;
  }
};

export default credentialingReducer;
