import React from "react";

import { Link } from "react-router-dom";
import "./ReceivedBids.css";
import createimg from "../../../assets/icons/Create.svg";
import noReceivedimg from "../../../assets/icons/nobids.png"

const NoReceivedBidsView = () => {
  return (
    <div className="noSkillListingView">
      <div className="Frame-20343Nobids">
        <img src={noReceivedimg} alt="create" className="CreateImgNobids" />
        <span className="nodata">
          {/* Create new skill listings and have more bids */}
          No data found
        </span>
        {/* <Link to="/create-skilllisting" className="clickSkillListinggreen">
          <img src={plussign} alt="plus" className="plussign" />
          <div className="Create-a-Skill-Listing">Create a skill listing</div>
        </Link> */}
      </div>
    </div>
  );
};

export default NoReceivedBidsView;