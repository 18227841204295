import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Modal } from 'antd';
import './BidtoApply.css';
import { useDispatch, useSelector } from 'react-redux';
import {retractBid, retractBidReset} from '../../../redux/actions';
import { useHistory } from "react-router-dom";

const EditFormBtns = (props) => {
    // console.log(props.id);
    const dispatch = useDispatch();
    const rSuccess = useSelector(state => state.retractBidReducers.success);
    var history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        dispatch(retractBid('Retracted', props.id,"Bid Retracted",'Bid Retracted',"Bid was Retracted by the user"));
    };

    useEffect(() => {
        if (rSuccess) {
            history.push('/dashboard');
            dispatch(retractBidReset());
        }
    });

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Modal title="Retract Your Bid?" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Do you want to retract your bid?</p>
            </Modal>
            <Row className="Ebuttons-row">
                <Col lg={{ span: 7 }} xs={7} md={7}>
                    <Button type="default" className="cancel-btn">CANCEL</Button>
                </Col>
                <Col lg={1} md={1} sm={1} xs={1} ></Col>
                <Col lg={8} md={8} xs={8}>
                    <Button type="default" htmlType="submit" className="bid-btn">UPDATE BID</Button>
                </Col>
                <Col lg={1} md={1} sm={1} xs={1}></Col>
                <Col lg={{ span: 7 }} xs={7} md={7} >
                    <Button type="default" className="retract-btn" onClick={showModal}><strong>RETRACT BID</strong></Button>
                </Col>
            </Row>
        </>
    );
}
export default EditFormBtns;
