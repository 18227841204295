import React from 'react';
import './MessageItem.css';
import {Row, Col, Avatar, Typography, Tag, Skeleton} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import moment from 'moment';

const {Text} = Typography;
const MessageItem = props => {
    const date = new Date(props.lastMessageTimeStamp)
    // console.log(date.getDate());
    return(
        <div className={'message-item'} onClick={props.onClick}>
            {
                props.loading
                    ? <Row style={{width: '100%'}}>
                        <Skeleton avatar paragraph={false} active loading={true}/>
                      </Row>
                    : <Row style={{width: '100%'}}>
                        <Col xs={12} md={8} className={'message-item-grid-item'}>
                            <Avatar icon={<UserOutlined />} />
                            <Text
                                className={'message-item-text-normal message-item-title'}>
                                {props.recipientName} ({props.totalMessages})
                            </Text>
                        </Col>
                        <Col xs={12} md={12} className={'message-item-grid-item'}>
                            <Text className={'message-item-text-normal'}>{props.gigName}</Text>
                            <Tag color="#e8e8e8" className={"message-item-tag"}>{props.bidStatus}</Tag>
                        </Col>
                        <Col xs={24} md={4} className={'message-item-grid-item'}>
                            <div className={'message-item-time-elapsed-container'}>
                                <Text className={'message-item-time-elapsed-text'}>
                                    {moment(props.lastMessageTimeStamp).fromNow()}
                                </Text>
                            </div>
                        </Col>
                      </Row>
            }
        </div>

    )
}

export default MessageItem;
