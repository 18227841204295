import React, { useEffect, useRef, useState } from "react";

import ChatWithRecruitor from "../../../assets/icons/ChatWithRecruitor.svg";
import ChatRecruitorWhite from "../../../assets/icons/chatRecruitorWhite.svg";
import { useHistory } from "react-router-dom";
import { BiCalendar } from "react-icons/bi";
import moment from "moment";
import { FiPaperclip } from "react-icons/fi";
import RadioButtonInterview from "./RadioButtonInterview";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "antd";

const ActionButtonMobile = ({
  colorSetter,
  AcceptButton,
  iconcal,
  icon,
  disabled,
  disabledyellow,
  yes,
  no,
  yesText,
  noText,
  screeningdisplay,
  OpenModalBoxStages,
  stagename,
  message1,
  message2,
  screeningDate,
  submitDeclinedReason,
  Interviewselecter,
  CalendraModal,
  lowerText,
  Reschedule,
  setInterviewTime,
  isPlacedBidsStatus,
  RescheduledAccepted,
  disabledAccepted,
  errorMessage,
  ExtraBar,
  MobileDisable
}) => {
  const history = useHistory();
  const [DetailPageData, setDetailPageData] = useState();
  const [ErrorMsgCheck, setErrorMsgCheck] = useState(null);
  const [showError, setshowError] = useState(false);
  const refOffer = useRef();
  const ErrorMsg = "Choose an available interview date or request to reschedule another time"

  const BidDetailData = useSelector(
    (state) => state.BidsReducer.detailReceivedBid
  );

  const PlacedBidDetailData = useSelector(
    (state) => state.BidsReducer.detailPlacedBid
  );

  const AccountId = useSelector(
    (state) => state.profileReducer.user.profile.AccountId
  );

  const onChangeRadio = (event) => {
    setInterviewTime(event?.target?.value);
    setErrorMsgCheck(event?.target?.value)
  };

  useEffect(() => {
    {
      isPlacedBidsStatus
        ? setDetailPageData(PlacedBidDetailData)
        : setDetailPageData(BidDetailData);
    }
  }, [BidDetailData, isPlacedBidsStatus]);

  useEffect(() => {
    if (ErrorMsgCheck){
      setshowError(false)
    }
  }, [ErrorMsgCheck])
  

  return (
    <>
      {Interviewselecter ? (
        <RadioButtonInterview
          BidDetailData={
            isPlacedBidsStatus ? PlacedBidDetailData : BidDetailData
          }
          onChangeRadio={onChangeRadio}
        />
      ) : null}
      <div className="ErrorMessage">{showError && ErrorMsg}</div>
      {ExtraBar ? <div className="offerLetter-bid-details placebid-talentStage">
        <div className="viewOffer">{lowerText}</div>
      </div> : null}
      {screeningdisplay ? (
        <div
          className={
            disabledyellow
              ? `screening-status-disable Show-${colorSetter}`
              : "screening-status"
          }
        >
          <a
            ref={refOffer}
            href={`${process.env.REACT_APP_S3_URL}${DetailPageData?.offers?.Attachments[0]?.FilePath?.charAt(0) != "/"
                ? "/"
                : ""
              }${DetailPageData?.offers?.Attachments[0]?.FilePath}`}
            target="_self"
            style={{ display: "none" }}
          ></a>
          <span className="spanOne">{message1}</span>
          <span 
            className="spanTwo"
            onClick={() => {
              if (icon) refOffer.current.click();
            }}
          >
            {message2 ? (
              <>
                {icon ? (
                  <FiPaperclip className="iconFontSize-stages1" />
                ) : iconcal ? (
                  <BiCalendar className="iconFontSize-stages" />
                ) : null}{" "}
                {moment(message2).format("dddd , MMMM DD , YYYY")}
              </>
            ) : (
              <>
                {icon && <FiPaperclip className="iconFontSize-stages1" />}
                {lowerText}
              </>
            )}
          </span>
        </div>
      ) : null}
      <section className="edit-retract-row">
        {DetailPageData?.interview?.ModifiedBy === AccountId &&
        DetailPageData?.interview?.InterviewStatuses?.InterviewStatusName ===
        "Rescheduled" ? (
          <button
            className={disabled ? "editButton-bid-disabled" : "editButton-bid"}
            onClick={CalendraModal}
          >
            Request to reschedule
          </button>
        ) : (
          <button
            className={
              disabled || disabledAccepted
                ? "editButton-bid-disabled"
                : "editButton-bid"
            }
            onClick={() => {
              if (stagename === "Interview"){
                ErrorMsgCheck && AcceptButton(stagename);
                !ErrorMsgCheck && setshowError(true)
              }
              else{
                AcceptButton(stagename);
              }
            }}
          >
            {yesText}
          </button>
        )}
        <button
          className={
            disabled ? "retractedButton-bid-disabled" : "retractedButton-bid"
          }
          onClick={() => {
            OpenModalBoxStages(stagename);
          }}
        >
          {noText}
        </button>
        <button
          className={
            disabled || disabledAccepted
              ? "chatbutton-bid-green"
              : "chatbutton-bid"
          }
          onClick={() => history.push("/messages")}
        >
          <Badge dot={true}>
            <img
              src={
                disabled || disabledAccepted
                  ? ChatRecruitorWhite
                  : ChatWithRecruitor
              }
              alt="chat"
            />
          </Badge>
        </button>
      </section>
      {RescheduledAccepted ||
        (Reschedule && DetailPageData?.interview?.ModifiedBy !== AccountId) ? (
          <div className={`notInterested-about-job-mobile ${MobileDisable}`} onClick={CalendraModal}>
          Request to reschedule
        </div>
      ) : null}
    </>
  );
};

export default ActionButtonMobile;
