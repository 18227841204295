import React, { useEffect, useState } from "react";
import "./GigsCards.css";
import { Card, Avatar, Col, Row, Rate, Popover } from "antd";
import { BsCurrencyDollar, BsStarFill } from "react-icons/bs";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  HeartFilled,
  StarOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { getImageByStateCode } from "../../../shared/helpers";
import { useHistory } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import JobDetailChartIcon from "../../../assets/img/JobDetailChartIcon.png";
import { Modal, Button } from "antd";

const { Meta } = Card;
const SkillListingTitle = (string, limit) => {
  var dots = "...";
  if (string?.length > limit) {
    // you can also use substr instead of substring
    string = string.substring(0, limit) + dots;
  }

  return string;
}

const GigsCards = ({ data }) => {
  const gig = data?.proposal_gig ? data?.proposal_gig : data?.bid_gig;
  const StatusJob = data?.offers?.Status;
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  let status;
  let RatingSection = "";
  let DetailDuration = "";
  const [placeholder, showPlaceholder] = useState(false);
  // let Dates = new Date(StartDateRaw);
  // console.log("detaialGigcard", data, data?._id);
  // console.log("detaialGigcard", gig);
  switch (data?.offers?.Status) {
    case "On Billing":
      status = <span className="gig-card-status-Active">ON BILLING</span>;
      break;
    case "Cancelled":
      status = <span className="gig-card-status-Cancelled">CANCELED</span>;
      break;
    case "Terminated":
      status = <span className="gig-card-status-Terminated">TERMINATED</span>;
      break;
    case "Off Billing":
      status = <span className="gig-card-status-Completed">GIG COMPLETED</span>;
      break;
    default:
      status = <span className="gig-card-status-Active">ON BILLING</span>;
      break;
  }

  if (StatusJob === "Cancelled" || StatusJob === "Terminated") {
    DetailDuration = (
      <div
        className={
          "g-Duration g-Duration-Cancelled-Terminated " +
          (StatusJob === "Cancelled" || StatusJob === "Terminated"
            ? "g-Duration-color-black-white"
            : "g-Duration-color")
        }
      >
        <div className="JobDetail-padding-left JobDetail-itemCenter">
          <span>{StatusJob}</span> <br></br>
        </div>
        <div className="JobDetail-padding-Right">
          <span className="Duration_Title-Cancelled">
            {" "}
            <FaCalendarAlt /> Date
          </span>{" "}
          <br></br>
          <span>
            {gig?.ExpiryDate?.slice(0, 10)
              ?.split("-")
              ?.reverse()
              ?.join(".")}
          </span>
        </div>
      </div>
    );
  } else {
    DetailDuration = (
      <div
        className={
          "g-Duration " +
          (StatusJob === "Cancelled" || StatusJob === "Terminated"
            ? "g-Duration-color-black-white"
            : "g-Duration-color")
        }
      >
        {/* <div className="JobDetail-padding-left JobDetails-textColor">
          <span className="Project_earning_font">Estimated earnings</span>{" "}
          <br></br>
          <span className="Doller_font">$ 50000</span>
          <span className="Doller_font">$ {data?.SGFees}</span>
        </div> */}
        <div className="JobDetail-padding-Right JobDetails-textColor">
          <span className="Duration_Title">
            {" "}
            <FaCalendarAlt /> Duration
          </span>{" "}
          <br></br>
          <span className="Doller_font">
            {data?.offers?.FromDate?.slice(0, 10)
              ?.split("-")
              ?.reverse()
              ?.join(".")}{" "}
            -{" "}
            {data?.offers?.ToDate?.slice(0, 10)
              ?.split("-")
              ?.reverse()
              ?.join(".")}
          </span>
        </div>
      </div>
    );
  }

  const DetailsPageJob = () => {
    history.push(`/jobDetails/${gig?._id}`);
  };
  const messagePusher = (e) => {
    e.stopPropagation();
    history.push("/messages");
  };
  const Modalboxstatus = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  if (StatusJob === "Off Billing") {
    RatingSection = (
      <div className="Rating-Section-Job-Details">
        <Modal
          // title="Modal 1000px width"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
          height={200}
        >
          <h1 className="Gigcard-Dummy-text">
            {" "}
            It will be taken care in future task.
          </h1>
        </Modal>
        <div className="Rating-inner-div1">
          <span className="Rating_section">Rating for you</span>
          <Rate style={{ color: "#ffba08" }} disabled defaultValue={2} />
        </div>
        <div className="Rating-inner-div2">
          <span className="Rating_section">Rating for employer</span>
          <Rate style={{ color: "#ffba08" }} disabled defaultValue={2} />
        </div>
        <div className="Job-Detail-See-Detailed-Ratings">
          <span className="GigCard-section-padding" onClick={Modalboxstatus}>
            See detailed ratings
          </span>
          <span className="GigArrow">
            <ArrowRightOutlined />
          </span>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="Job_Details_cantainer">
        <span
          className={
            "Skill-Listing-Title Skill-Listing-Title-Dashboard" +
            (StatusJob === "Cancelled" || StatusJob === "Terminated"
              ? "job-details-color-black-white Skill-Listing-Title-Dashboard"
              : "")
          }
        >
          {gig?.Title}
        </span>
        <div className="g-sgigs-thecards" onClick={DetailsPageJob}>
          {/* <img
            src={getImageByStateCode(
                        gig?.Regions?.Code,
                        gig?.Cities?.CityName)}
            className={
              "g-sgigs-image " +
              (StatusJob === "Cancelled" || StatusJob === "Terminated"
                ? "Job_Details_black_white"
                : "")
            }
            
          /> */}
          {placeholder ? (
                  <img
                    src={getImageByStateCode(gig?.Regions?.Code,'state')}
                    className={
              "g-sgigs-image " +
              (StatusJob === "Cancelled" || StatusJob === "Terminated"
                ? "Job_Details_black_white"
                : "")
            }
                  />
                ) : (
                  <img
                    src={getImageByStateCode(gig?.Regions?.Code,'state')}
                    className={
              "g-sgigs-image " +
              (StatusJob === "Cancelled" || StatusJob === "Terminated"
                ? "Job_Details_black_white"
                : "")
            }
                    onError={(e) => showPlaceholder(true)}
                  />
                )}
          <div className="g-sgigs-upAbove">
            <div
              className={
                "g-sgigs-theTitleJob " +
                (StatusJob === "Cancelled" || StatusJob === "Terminated"
                  ? "job-details-color-black-white"
                  : "g-sgigs-theTitleColor")
              }
            >
              {/* <Row>
                <Col span={12}>3D - REHAB RN Acute Rehab 3D </Col>
                <Col span={12}>col-12</Col>
              </Row> */}
              {/* {data?.gig?.Title} */}
              {gig?.Professions?.ProfessionCode === "HC" ? <div>{data?.skill_listing?.Title}</div> :
                ((data?.skill_listing?.Title)?.length < 25 ? <div>{data?.skill_listing?.Title}</div> :
                  <Popover content={data?.skill_listing?.Title} trigger="hover">{SkillListingTitle(data?.skill_listing?.Title, 21)}</Popover>)}
              {gig?.Professions?.ProfessionCode === "IT" ? <div className="g-sgigs-reviewJob-IT Job_Details_Status">
                {status}
              </div> : null}
            </div>
            <div
              className={
                "g-sgigs-theFacilityJob " +
                (StatusJob === "Cancelled" || StatusJob === "Terminated"
                  ? "job-details-color-black-white"
                  : "")
              }
            >
              {gig?.Professions?.ProfessionCode === "HC" ? gig?.FacilityName : gig?.WorkplacePolicy}
            </div>
            {gig?.Professions?.ProfessionCode === "HC" ? <div className="g-sgigs-information job_Details_card ">
              {/* <Rate count={1} disabled={true} value={1} /> */}
              <BsStarFill
                className={
                  "g-sgigs-starJob " +
                  (StatusJob === "Cancelled" || StatusJob === "Terminated"
                    ? "job-details-color-black-white"
                    : "")
                }
              />
              <span
                className={
                  "s-sgigs-ratingJob " +
                  (StatusJob === "Cancelled" || StatusJob === "Terminated"
                    ? "job-details-color-black-white"
                    : "")
                }
              >
                {/* {data?.Rate} */}
                4.5
              </span>
              <span className="Num_rating_color">(90)</span>
              <span className="g-sgigs-reviewJob Job_Details_Status">
                {status}
              </span>
            </div> : null
            }
            <div
              className={gig?.Professions?.ProfessionCode === "HC" ? "GigCard-status-section" : "GigCard-status-section-IT"}
            ></div>
            <div className="g-sgigs-gigcard-interestedBtn">
              <div
                className={
                  (gig?.Professions?.ProfessionCode === "HC" ? "g-sgigs-Budgets " : "g-sgigs-Budgets-IT") +
                  (StatusJob === "Cancelled" || StatusJob === "Terminated"
                    ? "Job_Details_black_white"
                    : "")
                }
              >
                {/* <span style={{ fontWeight: "600" }}>
                  <BsCurrencyDollar />
                </span> */}
                {gig?.Professions?.ProfessionCode === "HC" ? (<span
                  className="GigCard-Budget-span"
                >
                  {/* ${gig?.BudgetMax}/WK */}
                  ${data?.ActualRate}/WK
                </span>) : <span className="GigCard-Budget-span-IT" >{gig?.ExperienceLevels?.ExperienceLevelName}</span>}
              </div>
              {/* <Rate character={<HeartFilled />} count={1} allowClear /> */}
              <img
                src={JobDetailChartIcon}
                onClick={messagePusher}
                style={{ cursor: "pointer" }}
                className={
                  "" +
                  (StatusJob === "Cancelled" || StatusJob === "Terminated"
                    ? "Job_Details_black_white"
                    : "")
                }
              />
            </div>
            {gig?.Professions?.ProfessionCode === "HC" ? <div className="g-sgigs-Dashbord">Pay package</div> : null}
          </div>
          {DetailDuration}
        </div>
        {RatingSection}
      </div>
    </React.Fragment>
  );
};
export default GigsCards;
