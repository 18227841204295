export default {
    'Bid is accepted':' Your bid ',
    'Bid is declined':' Alert: ',
    'Interview is accepted':' Interview Scheduled: You have a scheduled interview ',
    'Offer is accepted':' your offer got accepted from ',
    'Bid is placed':' You got a Bid from ',
    'Profile is submitted':' Your profile got submitted by ',
    'Interview is proposed':' Interview Request: ',
    'Interview is scheduled': ' Interview Request: ',
    'Offer is made':' Congratulations! You have a job offer ',
    'Candidate is hired':` Congrats SkillGigster you've been hired `,
    'Candidate is moved to on billing':' Important: Your new contract with ',
    'Candidate is cancelled':' You have been moved to canceled by ',
    'Candidate is terminated':' You have been moved to terminated by ',
    'Job is completed':' Alert: Your contract with ',
    'Moved to Rating':' Congratulations on completing the gig',
    'Interview is canceled':' Your interview request ',
    'Rating is created':' You have received a rating from ',
    'Interview is rescheduled': ' has proposed new interview times ',
    'Candidate is rejected': ' You have rejected ',
    'Skilllisting Viewed by employer': ' Your Skill Listing ',
    'Resume Viewed by employer': ' Your 3-D Resume was Viewed by ',
    'Your bid has been retracted' : ' Alert: ',
    'bid updated by employer' : ` Important: You have received an updated bid from `,
    'Welcome to SkillGigs' : ' Welcome to SkillGigs. Set up your \n 3D Resume and start your journey today!',
    'skilllisting added to matches' : ' Alert! Your Profile has been matched ',
    'Resume Uploaded' : 'Your resume was successfully uploaded '
};

//(company Name). Click here for more details on the gig: <Link to the details of the gig matched for