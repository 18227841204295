import moment from "moment";
import { types } from "../action-types";

const initialState = {
  loading: false,
  notifications: [],
  NotifictionCount: 0,
  error: {},
  notificationUnreadCount: 0,
  updateRequested: false,
};

export const NotificationReducer = (state = initialState, action) => {
  let notiCount = state.NotifictionCount;
  let notiPrev = state.notifications;
  switch (action.type) {
    case types.GET_NOTIFICATION_REQUESTED:
      if(action.payload?.fetchMore){
        return {
          ...state,
          loading: true,
        };
      }
      else
      return {
        ...state,
          loading: false,
          notifications: notiPrev,
          NotifictionCount: notiCount
      };
    case types.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        notifications: [],
        error: action.error,
      };
      case types.GET_NOTIFICATION_SUCCESS:
      let combineArr = state.notifications.concat(action.payload.notification).reverse()
      combineArr.sort((a, b) => moment(b.Created) - moment(a.Created));
      return {
        ...state,
        loading: false,
        notifications: [...new Set(combineArr.map(item => item._id))].map(id => {
          return combineArr.find(item => item._id === id)
        }),
        NotifictionCount: action.payload.NotifictionCount,
        currentCount:action.payload.notification.length || 0,
        error: {},
        notificationUnreadCount: action.payload.NotifictionUnreadCount,
      };
      case types.READ_NOTIFICATION:
        return {
          ...state,
          updateRequested: true,
          // loading: true,
        };
      case types.READ_NOTIFICATION_SUCCESS:      
        return {
          ...state,        
          updateRequested: false,
          error: false,
        };
      case types.READ_NOTIFICATION_FAILURE:
        return {
          ...state,
          loading: false,
          //notification: [],
          error: true,
        };
    default:
      return state;
  }
};
