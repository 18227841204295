import React, { useState, useEffect } from "react";

import { Button, Modal } from "antd";

import "./Auth.css";

import { useHistory } from "react-router-dom";
import { getCookie } from "../../shared/helpers";
import SignUpLogin from "./components/SignUpLogin";

const Auth = () => {
  const history = useHistory();
  if (getCookie("RememberMe") == "true") {
    history.push("/auth");
  }

  const [dimensions, setDimensions] = useState(window.innerWidth);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  return (
    <div className="authorizationModal" style={{ backgroundColor: "#fafafa" }}>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button>
      <Modal
        bodyStyle={{ padding: 0 }}
        width="24.37rem"
        visible={isModalVisible}
        closable={false}
        footer={null}
        style={{ top: 45 }}
      >
        <SignUpLogin /> */}
      {/* </Modal> */}
      {dimensions < 1000 && <SignUpLogin />}
    </div>
  );
};

export default Auth;
