import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import "./SignUpLogin.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Tabs,
  Tooltip,
  message,
  Alert,
  Divider,
} from "antd";
import "antd/dist/antd.css";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Auth } from "../../../config/aws-exports";
import {
  checkEmailFlow,
  checkUsernameUnique,
  fetchExistingtUser,
} from "../../../redux/actions";
import Infomessage from "../../../components/Infomessage";

import GreenCheck from "../../../assets/icons/typcn_tick.svg";
import { GoAlert } from "react-icons/go";
import { debounce, setCookie } from "../../../shared/helpers";
import { BaseUrl } from "../../../components/Footer";


const SignUp = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const getRememberMe = JSON.parse(localStorage.getItem("RememberMe"));
  let InitialValue = !getRememberMe ? false : getRememberMe;

  // const [passwordValidation, setPasswordValidation] = useState(false);

  const signUpLogin = useSelector((state) => state.authReducer.signUpLogin);
  const [userEmail, setUserEmail] = useState(null);
  const [count, setCount] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [check, setCheck] = useState(false);
  const [greenCheck, setGreenCheck] = useState(false);
  const [greenCheckUserName, setGreenCheckUserName] = useState(false);
  const [greenCheckPassword, setGreenCheckPassword] = useState(false);
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [showCheckIconUserName, setShowCheckIconUserName] = useState(false);
  const [showCheckIconEmail, setShowCheckIconEmail] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const emailValidation = useSelector((state) => state.chekckUserReducer.user);
  const [clickCount, setClickCont] = useState(1);
  const [emailss, setEmailss] = useState("");

  const [isremember, setIsRemember] = useState(InitialValue);

  // Controlled Values
  const [email, setEmail] = useState(null);

  // const [count, setCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPassChanged, setIsPassChanged] = useState(false);
  const [errorPass, setErrorPass] = useState("");
  // const [messagetype, setMessageType] = useState("container-error");
  const clicked = () => {
    setCheck(!check);
    // // console.log(check);
  };

  const textemail = <span>example: example@mail.com</span>;
  const textpass = <span>Minimum 8 characters required</span>;
  const buttonWidth = 70;

  const history = useHistory();
  const [mode, setMode] = useState("Login");
  const SwitchMode = (key) => {
    setMode(key);
  };

  const checkUserName = useSelector(
    (state) => state.checkUserNameReducer.valid
  );
  const validateEmail = (e) => {
    const email = e.target.value;
    setCount(false);
    setEmail(email);
    // console.log(email);
    const v2 = /^\S*$/;
    const valid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.length > 0) {
      setShowCheckIconEmail(true);
    }
    if (valid.test(email) && v2.test(email)) {
      setGreenCheck(true);
    } else {
      setGreenCheck(false);
    }
  };

  const validateUserName = (e) => {
    const name = e.target.value;
    const valid = /^.{3,20}$/;
    const userName = name.toLowerCase();
    dispatch(checkUsernameUnique(userName));
    // const valid = /^([a-zA-Z]{3,})+\s+([a-zA-Z]{3,})+$/;
    if (name.length > 0) {
      setShowCheckIconUserName(true);
    }
    if (valid.test(name)) {
      setGreenCheckUserName(true);
    } else {
      setGreenCheckUserName(false);
    }
  };

  const validatePassword = (e) => {
    const password = e.target.value;
    const v2 = /^\S{8,}$/;
    const valid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

    if (password.length > 0) {
      setShowCheckPassword(true);
    }
    if (valid.test(password) && v2.test(password)) {
      setGreenCheckPassword(true);
    } else {
      setGreenCheckPassword(false);
    }
  };

  const onLogin = async ({ email, password }) => {
    localStorage.setItem("Email", email);
    // localStorage.setItem("password", password);

    setCount(false);
    setLoading(true);
    // // console.log("Received values of form: ", email);

    try {
      const response = await Auth.signIn({ username: email, password });
      // console.log(response);
      // https://serverless-idm.awssecworkshops.com/01-user-auth/#validate-sign-up-and-sign-in
      const session = await Auth.currentSession();
      // // console.log('Cognito User Access Token:', session.getAccessToken().getJwtToken());
      //  console.log(
      //   "Cognito User Identity Token:",
      //   session.getIdToken().getJwtToken()
      // );
      if (isremember) {
        // console.log("Clicked inside if");
        const result = await Auth.rememberDevice();
        // console.log("Remember me: ", result);

        if (result == "SUCCESS") {
          setCookie("RememberMe", "true");
        }
      }
      // // console.log('Cognito User Refresh Token', session.getRefreshToken().getToken());
      setLoading(false);
      history.push("/auth");
    } catch (error) {
      setLoading(false);
      setCount(true);
      // message.error(error.message);
      // setPasswordValidation(true);
      setTimeout(() => {
        setCount(false);
      }, 4000);
      // console.log("error signing in", error);
      if (isremember) {
        // console.log("Error remembering device", error);
      }
    }
  };

  const emailSave = ({ email,password }) => {
    var premail = email.toString().toLowerCase();
    // console.log("EMAILss: ", premail);
    // dispatch(checkEmailFlow(true));
    dispatch(fetchExistingtUser(premail));
    setUserEmail(premail);
    onSignup(email,password)
  };
  useEffect(() => {
    setClickCont(clickCount + 1);
    //  console.log(
    //   "signup email check",
    //   emailValidation?.userExist?.cognitoTriggerSource,
    //   emailValidation
    // );
    if (
      emailValidation?.userExist?.cognitoTriggerSource ===
        "PreSignUp_ExternalProvider" &&
      emailValidation?.userExist?.valid === true
    ) {
      // setErrorMessage("Email already exists using social login");
      setErrorMessage(
        "Sorry, this email already registered please try new one"
      );
      setCount(true);
      setGreenCheck(false);
      setShowCheckIconEmail(true);
      setTimeout(() => {
        // setCount(false);
        // setGreenCheck(false);
        // setShowCheckIconEmail(false);
        dispatch(checkEmailFlow(false));
      }, 3000);
    } else if (emailValidation?.userExist?.valid === true) {
      setErrorMessage(
        "Sorry, this email already registered please try new one"
      );
      setCount(true);
      dispatch(checkEmailFlow(false));
      setGreenCheck(false);
      setShowCheckIconEmail(true);
      setTimeout(() => {
        // setCount(false);
      }, 3000);
    } else if (
      emailValidation?.userExist?.response?.data?.valid === false &&
      userEmail
    ) {
      dispatch(checkEmailFlow(true));
      setCheckEmail(true);
    }
  }, [emailValidation]);
  useEffect(() => {
    setCount(false);
  }, [signUpLogin]);
  useEffect(() => {
    // if (Number(clickCount) > 1 && emailValidation?.userExist?.valid) {
    //   dispatch(checkEmailFlow(false));
    // }
    if (props.signinClick) {
      // dispatch(checkEmailFlow(false));
      setCheckEmail(false);
      setUserEmail();
    }
  }, [props.signinClick]);

  const onSignup = async (email, password) => {
    // // console.log("Received values of form: ", fullname, email);

    // const email = userEmail;
    setLoading(true);
    // setCount(false);
    try {
      if (!count) {
        console.log('Ruuning IF>>>' , email , password );
        const response = await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            name: "",
            // "custom:account_name": fullname,
            "custom:account_name": null,
            // name: fullname,
            // optional
            // optional - E.164 number convention
            // other custom attributes
          },
        });
        // console.log(response);
        let a = response.userSub;
        // // console.log(a!=null);
        // const session = await Auth.currentSession();
        // // console.log('Cognito User Access Token:', session.getAccessToken().getJwtToken());
        // // console.log(
        //   "Cognito User Identity Token:",
        //   session.getIdToken().getJwtToken()
        // );
        setLoading(false);
        // history.push("/auth");
        // console.log("Loading", loading);

        onLogin({ email, password });

        // if (a != null) {
        //   // setCount(true);
        //   // setMessageType("container-success");
        //   // setErrorMessage("Please check your email to complete the signup");
        //   message.success("Please check your email to complete the signup");
        //   setTimeout(() => {
        //     // setCount(false);
        //   }, 5000);
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 6000);
        //   // // console.log("message type", messagetype);
        //   // // console.log("errormessage", errormessage);
        // }
      } else {
        // If the username variable is true, execute the catch block
        setLoading(false);
        console.log('Ruuning ELSE>>>');
        // console.log("Username already exists");
        // message.error(
        //   "Username is already taken, please enter a new username."
        // );
        // setErrorMessage(
        //   "Username is already taken, please enter a new username."
        // );
        setCount(true);
        setTimeout(() => {
          setCount(false);
          form.resetFields();
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      // setCount(true);
      // setMessageType("container-error");
      // console.log("error signing up:", error);
      // message.error(error.message);
      // setErrorMessage(
      //   "Username is already taken, please enter a new username."
      // );
      setErrorMessage(
        "Email already exists"
      );
      setCount(true);
      setTimeout(() => {
        setCount(false);
        form.resetFields();
      }, 3000);

      // alert(error.message);
    }
  };

  // useEffect(() => {
  //   const el = document.getElementsByClassName("ant-input");
  //   for (let i = 0; i < el.length; i++) {
  //     el[i].setAttribute("autocomplete", "false");
  //   }
  // }, []);

  const btnSubmit = () => {
    if (!isPassChanged) {
      setErrorPass("notValidColor");
    }
  };

  return (
    <div className="signup-form">
      <div className="container-auth">
        
          <>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: false,
              }}
              onFinish={emailSave}
            >
              {/* <div className="form-label">Email</div> */}
              <Tooltip placement="topRight" title={textemail} trigger="click">
                <Form.Item
                  name="email"
                  // noStyle={true}
                  rules={[
                    {
                      type: "email",
                      message: "Looks like this email is incomplete",
                    },
                    {
                      required: true,
                      message: "Please add your email address",
                    },
                  ]}
                >
                  <Input
                    defaultValue={email}
                    // type="email"
                    onChange={(e) => validateEmail(e)}
                    className={`emailInputSignUp 
                    ${
                      greenCheck
                        ? "validInputCSS"
                        : `${showCheckIconEmail ? "notValidInput" : null}`
                    }
                    `}
                    placeholder="Email address"
                    suffix={
                      greenCheck ? (
                        <img src={GreenCheck} alt="greenCheck" />
                      ) : (
                        <>
                          {showCheckIconEmail ? (
                            <GoAlert
                              style={{
                                color: "#db2828",
                                marginRight: "0.4rem",
                              }}
                            />
                          ) : null}
                        </>
                      )
                    }
                  />
                </Form.Item>
                {count && (
                  <Infomessage message={errormessage} class="container-error" />
                )}
              </Tooltip>

              <Form.Item
                name="password"
                // autoComplete="off"
                extra={
                  <div
                    className={` ${errorPass}  passwordHelp ${
                      greenCheckPassword
                        ? `${null}`
                        : `${showCheckPassword ? "notValidColor" : null}`
                    }`}
                  >
                    Password must be min 8 characters. Combine numbers, upper
                    and lowercase letters.
                  </div>
                }
                rules={[
                  // {
                  //   pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                  // },
                  // {
                  //   required: true,
                  // },
                  {
                    validator: async (_, value) => {
                      if(value == undefined){
                        console.log('pass val>>>',value)
                        // setGreenCheckPassword(false);
                        // setShowCheckPassword(true)
                        return Promise.reject(new Error("Please add your password"));
                        // return Promise.reject(new Error("Please enter password"));
                      }
                      if (value.length < 1 || value == undefined) {
                        return Promise.reject(new Error("Please add your password"));
                      }
                      else {
                        return Promise.resolve();
                      }
                    }
                  }
                ]}
                // help={false}
              >
                <Input.Password
                  autocomplete="new-password"
                  onChange={validatePassword}
                  onKeyDown={(e) => e.which === 32 && e.preventDefault()}
                  // type="password"
                  placeholder="Add a Password"
                  className={`passwordSignUp ${
                    greenCheckPassword
                      ? "validInputCSS"
                      : `${showCheckPassword ? "notValidInput" : null}`
                  }`}
                  iconRender={(visible) =>
                    visible ? (
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "right",
                          color: "#4054b2",
                        }}
                      >
                        Hide
                      </span>
                    ) : (
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "right",
                          color: "#4054b2",
                        }}
                      >
                        Show
                      </span>
                    )
                  }
                />
              </Form.Item>
              {/* <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={btnSubmit}
                  // disabled={loading || !check}
                  // disabled={loading}
                >
                  <span style={{ fontSize: "1rem" }}>Join</span>
                </Button>
              </Form.Item> */}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  // disabled={loading || !check}
                  // style={{ fontFamily: "SFProText-Bold" }}
                  // disabled={loading}
                >
                  <span style={{ fontSize: "1rem" }}>{loading ? <LoadingOutlined/> :'Join SkillGigs'}</span>
                </Button>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "2rem",
                    margin: "1vh 0",
                    color: "#e73959",
                  }}
                >
                  {loading && <LoadingOutlined />}
                </div> */}
              </Form.Item>
            </Form>
          </>
        
        <div className="terms-and-services">
          {`By joining, you agree to SkillGigs`}
          <a
            className="subTerm"
            onClick={() => {
              window.location.replace(`${BaseUrl}/terms-of-service`);
            }}
          >
            <u>Terms of services</u>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
