import { Divider } from "antd";
import React, { useEffect } from "react";
import "./AboutJobExtraSection.css";

const AboutJobExtraSection = (props) => {
  const { WorkplacePolicy, WorkAuthorization } = props?.gig;
  const ProfessionCode = props?.gig?.Professions?.ProfessionCode;
  return (
          <>
            <Divider />
            <div className="AboutJobExtraSection-Cantainer">
              <div className="AboutJobExtraSection-title">
                <div className="AboutJobExtraSection-SubTitle">
                  Work authorization
                </div>
                <div className="AboutJobExtraSection-value">
                  {WorkAuthorization ? WorkAuthorization?.join(", ") : "Not disclosed"}
                </div>
              </div>
              {ProfessionCode === "IT" && (
                <div className="AboutJobExtraSection-title">
                  <div className="AboutJobExtraSection-SubTitle">
                    Workplace policy
                  </div>
                  <div className="AboutJobExtraSection-value">
                    {WorkplacePolicy ? WorkplacePolicy : "Not disclosed"}
                  </div>
                </div>
              )}
              {/* <div className='AboutJobExtraSection-title'>
                    <div className='AboutJobExtraSection-SubTitle'>Certifications</div>
                    <div className='AboutJobExtraSection-value'>MSFT PowerBI</div>
                </div> */}
            </div>
          </>
  );
};

export default AboutJobExtraSection;
