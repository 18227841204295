import React from "react";
import { FaRegSmileBeam } from "react-icons/fa";
import UpperIcon from "../../../assets/icons/UpperIcon.png";
import ChatRecruitorWhite from "../../../assets/icons/chatRecruitorWhite.svg";
import DeclineStatus from "./DeclineStatus";
import CompleteCredentialing from "./CompleteCredentialing";
import Interested_NotInterested from "../ReceivedBids/components/Interested_NotInterested";
import ScreeningAndSchedule from "./ScreeningAndSchedule";
import AcceptDeclineMobile from "../components/ActionButtonMobile";
import OfferLetter from "./OfferLetter";
import { Badge } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const WelcomeOnboard = ({
  message1,
  message2,
  message3,
  Duration,
  image,
  BidDetailData,
}) => {
  const history = useHistory();

  return (
    <>
      <div className="welcome-on-board">
        <img src={image} />
        <span className="welcome-on-board-text">{message1}</span>
        <span className="welcome-on-board-text-Congratual">{message2}</span>
        <span className="welcome-on-board-text-status">{message3}</span>
        <span className="welcome-on-board-text-status-Duration">
          {Duration}
        </span>
        <span
          className="chatButton-Mobile clickable"
          onClick={() =>
            history.push({
              pathname: `/messages`,
              // pathname: `/messages/${bidData?.message?.ConversationId}`,
              state: {
                openConvo: true,
                conversationId: BidDetailData?.message?.ConversationId,
              },
            })
          }
        >
          Chat with the employer
          <Badge dot={true}>
            <img className="Chat-with-employer-icon" src={ChatRecruitorWhite} />
          </Badge>
        </span>
      </div>
      {/* <DeclineStatus />
      <Interested_NotInterested
        // no={() => setModalVisibleBidDeclined(true)}
        yesText="Accept"
        noText="Decline"
      />
      <ScreeningAndSchedule
        uppertext="Declined"
        // lowerText={BidDetailData?.DeclineReason}
        // dimensions={dimensions}
        firstButton="Accept"
        secondButton="Declined"
      />
      <AcceptDeclineMobile
        yesText="Accept"
        noText="Decline"
      // no={() => setModalVisibleBidDeclined(true)}
      />
      <OfferLetter /> */}
    </>
  );
};

export default WelcomeOnboard;
