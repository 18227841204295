import React, { useEffect, useState } from 'react';
import './SkillDensityPieChart.css'
import { PieChart } from "@opd/g2plot-react";
import { Col, Popover, Row } from 'antd';

const MemoizedG2PlotComponent = React.memo(PieChart);

export function truncate(str, Len, truncateword) {
    return str?.length > Len ? str?.substring(0, truncateword) + " ..." : str;
}

const SkillDensityPieChart = (props) => {
    const [dimensions, setDimensions] = useState(window.innerWidth);
    const [truncateLen, settruncateLen] = useState(20);
    const checkDimensions = () => {
        setDimensions(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", checkDimensions);
    }, []);

    useEffect(() => {
        settruncateLen(dimensions > 720 ? 20 : 15)
    }, [dimensions])

    const { jobdetails, IndustryIdentifer, jobdetailsIdentifer } = props;

    var color = ["#64cfcc", "#edcd6d", "#4a9eb7", "#cecece", "#be5e30"];
    const [piePlot, setpiePlot] = useState();

    useEffect(() => {
        var dataArray = [];
        if (jobdetails) {
            if (
                jobdetails?.length !== 0 &&
                jobdetails[0]?.JobOrders_Skills?.length !== 0
            ) {
                var dataArrayTemp = jobdetails?.jobdetails[0];
                // Create a copy of dataArrayTemp
                var dataArrayTempCopy = dataArrayTemp.map((item) => ({ ...item }));

                for (let i = 0; i < dataArrayTempCopy.length; i++) {
                    if (
                        dataArrayTempCopy[i].SkillName === null &&
                        dataArrayTempCopy[i].Skills?.SkillName === null
                    ) {
                        dataArrayTempCopy[i].Skills = {
                            SkillName: "Others",
                            SkillPercent: dataArrayTempCopy[i].SkillPercent,
                        };
                    }
                }

                // Calculate the sum of SkillPercent values
                let sum = 0;
                let count = 0;

                for (let i = 0; i < dataArrayTempCopy.length; i++) {
                    if (dataArrayTempCopy[i].SkillPercent === null) {
                        sum += dataArrayTempCopy[i].SkillPercent;
                        count++;
                    }
                }

                // Check if the sum is less than or not equal to 100
                if (sum !== 100 && count > 0) {
                    // Calculate the remaining value needed to reach 100
                    const remaining = 100 - sum;

                    // Calculate the proportion to distribute among SkillPercent values
                    const proportion = remaining / count;

                    // Distribute the remaining value proportionally among SkillPercent values
                    for (let i = 0; i < dataArrayTempCopy.length; i++) {
                        if (dataArrayTempCopy[i].SkillPercent === null) {
                            dataArrayTempCopy[i].SkillPercent += proportion;
                        }
                    }
                }

                // Assign the updated array back to dataArrayTemp
                dataArrayTemp = dataArrayTempCopy;                                                
                dataArray = dataArrayTemp?.map((item) => {
                    if (jobdetailsIdentifer) {
                        return {
                            type: item?.Skills?.SkillName ?? item?.SkillName,
                            value: item?.SkillPercent ? item?.SkillPercent : item?.SkillPercent
                        };
                    }
                    else if (IndustryIdentifer && item?.IndustryName) {
                        return {
                            type: item?.IndustryName ? item?.IndustryName : item?.IndustryName,
                            value: item?.IndustryPercent ? item?.IndustryPercent : item?.IndustryPercent
                        };
                    }
                });
            }
        }

        var piePlotObj = {
            appendPadding: 5,
            data: dataArray,
            angleField: "value",
            colorField: "type",
            radius: 1.0,
            height: 120,
            legend: false,
            color: color,
            label: {
                type: "inner",
                offset: "-30%",
                content: ({ percent }) => { },
                style: {
                    fontSize: 14,
                    textAlign: "center",
                },
            },
            interactions: [{ type: "element-active" }],
        };
        setpiePlot(piePlotObj);

    }, []);

    return (
        <div className='SkillDensityPieChart-Cantainer'>
            <div className='Skill-PieChart-Component'>
                <div>
                    <div className='SkillDensityPieChart-Density'>{jobdetailsIdentifer ? "Skill Density" : "Industry Density"}</div>
                </div>
                <div className='SkillDensityPieChart-Digram'>
                    <Row>
                        <Col span={12}>
                            <div className='SkillDensityPieChart-Digram-plot' >
                                {piePlot && <MemoizedG2PlotComponent {...piePlot} />}
                            </div>
                        </Col>
                        <Col span={18}>
                            <div>
                                {
                                    jobdetails?.length !== 0 && jobdetails?.jobdetails[0]?.map((item, index) => {
                                        return (
                                            <>
                                                <div className='SkillDensityPieChart-Density-name' >
                                                    <Row>
                                                        <Col flex={2}>
                                                            <div
                                                                style={{
                                                                    background: color[index % color.length],
                                                                    width: "1.875rem",
                                                                    height: ".625rem",
                                                                }}
                                                            ></div>
                                                        </Col>
                                                        <Col flex={3}>
                                                            <div className='SkillDensityPieChart-Density-color' >
                                                                {/* <Popover content={item?.Skills?.SkillName ? item?.Skills?.SkillName : item?.SkillName} trigger={"hover"}> */}
                                                                    {item?.Skills?.SkillName ? item?.Skills?.SkillName : item?.SkillName}
                                                                {/* </Popover> */}
                                                                {/* <Popover content={item?.IndustryName && IndustryIdentifer ? item?.IndustryName : null} trigger="hover"> */}
                                                                    {item?.IndustryName && IndustryIdentifer ? item?.IndustryName: null}
                                                                {/* </Popover> */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default SkillDensityPieChart;
