import React, { useState } from "react";
import { Document, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { alertNotification } from "../../../components/notifications/alertNotification";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CredValidation = ({
  loadFile: [file, fileRef, setIsSelected, onLoadUpload],
}) => {
  const [pdfFile, setPdfFile] = useState(null);
  const handleCorruptFile = () => {
    alertNotification(
      "warning",
      "Cannot open the this file. The file may be corrupt, or in an unsupported format."
    );
    fileRef.current.value = "";
    setIsSelected(false);
  };

  const reader = new FileReader();
  reader.onload = () => {
    if (file.type === "application/pdf") {
      setPdfFile(reader.result);
    }
  };
  reader.readAsDataURL(file);

  return (
    <div style={{ display: "none" }}>
      {file.type === "application/pdf" && pdfFile ? (
        <Document
          file={pdfFile}
          onLoadError={handleCorruptFile}
          onLoadSuccess={() => {
            onLoadUpload();
            setIsSelected(false);
          }}
        ></Document>
      ) : (
        <img
          src={URL.createObjectURL(file)}
          alt="document"
          onError={handleCorruptFile}
          onLoad={() => {
            onLoadUpload();
            setIsSelected(false);
          }}
        />
      )}
    </div>
  );
};

export default CredValidation;
