import React from "react";
import "./CreateBidButtonIT.css";

const CreateBidButtonIT = (props) => {
  const { handleApply, gig, isExpiredGigs,isEdit } = props;
  return (
    <div className="CreateBidButtonIT-button">
      <div className="bottom-details IT-bottom-details">
        <div
          className={`bid-details-apply IT-bid-details-apply ${
            isExpiredGigs ? "is-expired-gigs" : ""
          }`}
          onClick={() => {
            if (!isExpiredGigs) {
              handleApply(gig);
            }
          }}
        >
          <div>{isEdit && !isExpiredGigs ? "Edit bid" : "Place a bid"}</div>
        </div>
      </div>
    </div>
  );
};

export default CreateBidButtonIT;
