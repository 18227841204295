import { types } from "../action-types";
const initialState = {
  loading: false,
  success: [],
  error: {},
  loadingCP: false,
  companyInfo: [],
  errorCP: {},
  loadingFollow: false,
  follow: false,
};

const viewListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_VIEWLISTING_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_VIEWLISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: {},
      };

    case types.FETCH_VIEWLISTING_FAILURE:
      return {
        ...state,
        loading: false,
        success: [],
        error: action.error,
      };

    case types.FETCH_COMPANYPROFILE_INIT:
      return {
        ...state,
        loadingCP: true,
      };

    case types.FETCH_COMPANYPROFILE_SUCCESS:
      return {
        ...state,
        loadingCP: false,
        companyInfo: action.payload,
        errorCP: {},
      };

    case types.FETCH_COMPANYPROFILE_FAILURE:
      return {
        ...state,
        loadingCP: false,
        companyInfo: [],
        errorCP: action.error,
      };
    case types.ADD_REMOVE_EMPLOYER_FOLLOW_INIT:
      return {
        ...state,
        loadingFollow: true,
      };
    case types.ADD_REMOVE_EMPLOYER_FOLLOW_SUCCESS:
      return {
        ...state,
        loadingFollow: false,
        follow: true,
      };
    case types.ADD_REMOVE_EMPLOYER_FOLLOW_FAILURE:
      return {
        ...state,
        loadingFollow: false,
      };

    default:
      return state;
  }
};

export default viewListingReducer;
