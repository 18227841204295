import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import photoCamera from "../../../src/assets/icons/photoCamera.svg";
import { alertNotification } from "../notifications/alertNotification";
import "./ProfileAvtar.css";
import { uploadProfilePic } from "../../redux/actions";
import Loader from "../Loader/Loader";
import { FaCamera } from "react-icons/fa";

export const ProfileAvtar = (props) => {
  const [myFile, setMyFile] = useState(
    `${process.env.REACT_APP_S3_URL}${
      props?.profilePic?.charAt(0) !== "/" ? "/" : ""
    }${props.profilePic}`
  );
  const [isError, setIsError] = useState();
  const [count, setCount] = useState(0);
  const [files, setFIles] = useState();
  // console.log("props", props);
  const imageErrorHandler = () => {
    alertNotification(
      "warning",

      "The Uploaded Image is Corrupted and cannot be uploaded"
    );

    setIsError(true);
  };
  const fileRef = useRef();
  const dispatch = useDispatch();
  const imageHandler = () => {
    setMyFile(null);
    dispatch(uploadProfilePic({ type: "profilePic", file: files }));
  };
  const loadFile = (e) => {
    const file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    setMyFile(fileUrl);
    setFIles(e.target.files[0]);

    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      alertNotification("warning", "File format not supported");
      e.target.value = ""; // reset onchange so it can be trigger over a same file
      return;
    }
    if (file.size >= 10590617) {
      alertNotification("warning", "File size bigger than 10 MB");
      e.target.value = ""; // reset onchange so it can be trigger over a same file
      return;
    }
    // console.log("FILE ON PAGE:", file);
    setCount(count + 1);
    setIsError(false);
  };

  return (
    <>
      {props.loading ? (
        <Loader color={"black"} />
      ) : (
        <>
          <label className="profilePicture" htmlFor="Profile-Picture">
            {props?.skillListingDetailPage ? (
              <>
                {props.profilePic ? (
                  <img
                    src={`${process.env.REACT_APP_S3_URL}${
                      props?.profilePic?.charAt(0) !== "/" ? "/" : ""
                    }${props.profilePic}`}
                    className="candidate-picture-skill"
                    alt=""
                  />
                ) : (
                  <div className="candidate-name-skill">
                    {props.name?.charAt(0).toUpperCase()}
                  </div>
                )}
              </>
            ) : (
              <>
                {props.profilePic ? (
                  <>
                    <img
                      src={`${process.env.REACT_APP_S3_URL}${
                        props?.profilePic?.charAt(0) !== "/" ? "/" : ""
                      }${props.profilePic}`}
                      className="candidate-picture"
                      alt=""
                    />
                    {window.innerWidth < 768 && (
                      <div className="edit-profile-camera-mobile">
                        <FaCamera fontSize="1.25rem" color="black" />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="candidate-name">
                    {props.name?.charAt(0).toUpperCase()}
                    {window.innerWidth < 768 && (
                      <div className="edit-profile-camera-mobile">
                        <FaCamera fontSize="1.25rem" color="black" />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            {window.innerWidth > 768 && !props?.skillListingDetailPage && (
              <img className="on-hover" src={photoCamera} alt="" />
            )}
          </label>
          {!props?.skillListingDetailPage && (
            <input
              ref={fileRef}
              id="Profile-Picture"
              name="Profile-Picture"
              type="file"
              onChange={loadFile}
              style={{ display: "none" }}
            />
          )}

          {count > 0 && (
            <img
              src={myFile}
              className="candidate-picture-dummy"
              alt=""
              onError={imageErrorHandler}
              onLoad={imageHandler}
            />
          )}
        </>
      )}
    </>
  );
};
