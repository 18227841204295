import React, { useRef, useState, useEffect } from "react";
import "./OnboardingMessageSend.css";
import { CgAttachment } from "react-icons/cg";
import {
  getFileIcon,
  getFileSizeInfo,
} from "../../../../components/fileInformation";
import closeIcon from "../../../../assets/icons/ic_sharp-cancel.svg";
import { sendMessage } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const OnboardingMessageSend = () => {
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [textArea, setTextArea] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState(null);
  const inputAttachmentBox = useRef(null);
  const inputTextArea = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  // console.log("id.....", id);

  // from intput field (file type attachment) - onClick()
  const onChangeFile = ({ target: { files } }) => {
    // console.log("event.target.files", files);
    let flag = false;
    const length = files.length;
    for (let i = 0; i < length; i++) {
      const fileIcon = getFileIcon(getFileFormat(files[i].name));
      if (fileIcon) {
        const fileSize = getFileSizeInfo(files[i]);
        if (fileSize) {
          setAttachmentFile((prevState) => {
            return [...prevState, files[i]];
          });
        }
        if (i === 0 && length === 1 && !fileSize) flag = true;
      }
      if (i === 0 && length === 1 && !fileIcon) flag = true;
    }
    flag ? getDispayBlock() : getDispayNone("fromFileAttachment");
  };

  // from textarea field (height adjustment) - onClick()
  const textAreaAdjust = (element) => {
    // console.log(element.target.value);
    const val = element.target.value;
    if (val !== "") {
      element.target.style.height = -2 + element.target.scrollHeight + "px";
    } else {
      element.target.style.height = "40px";
      //   if (window.innerWidth < 768) element.target.style.height = "53px";
    }
    setTextArea(element);
    setTextAreaValue(element.target.value);
  };

  // get file format - (from AttachmentDiv)
  const getFileFormat = (fileName) => {
    const behideDot = fileName?.lastIndexOf(".") + 1;
    const fileLength = fileName?.length;
    const fileFormat = fileName?.slice(behideDot, fileLength);
    // console.log("fileFormat", fileFormat);
    return fileFormat;
  };

  const handleOnCLickTextAreaFocus = () => {
    getDispayBlock("fromTextWithAttachment");
  };

  // remove attachment - (from AttachmentDiv) - onClick()
  const onRemoveAttachment = (fileName, e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    setAttachmentFile(() => {
      return attachmentFile.filter((file) => {
        return file.name !== fileName;
      });
    });
    // console.log("onRemoveAttachment", fileName);
    getDispayNone("onRemoveAttachment");
  };

  // attachment component here
  const AttachmentDiv = ({ file: { name: fileName } }) => {
    return (
      <>
        <div className="attachment-container">
          <div className="attachment-content">
            <img
              src={getFileIcon(getFileFormat(fileName))}
              alt={fileName}
              className="fileIcon"
            />
            <div className="attachment-content-info">
              <h3>{fileName}</h3>
              <p>{getFileFormat(fileName)}</p>
            </div>
          </div>
          <img
            src={closeIcon}
            onClick={(e) => onRemoveAttachment(fileName, e)}
            className="attachment-container-close-btn"
          />
        </div>
      </>
    );
  };

  const getMarginInputAttachmentBox = (str) => {
    switch (str) {
      case "marginTop_0px":
        inputAttachmentBox.current.style.marginTop = "0px";
        break;

      case "marginTop-15px":
        inputAttachmentBox.current.style.marginTop = "-15px";
        break;

      default:
        inputAttachmentBox.current.style.margin = "0px";
        break;
    }
  };

  const getDispayNone = (str) => {
    switch (str) {
      case "fromFileAttachment":
        !textArea?.target?.value
          ? getDispayNone()
          : getDispayBlock("fromFileAttachment");
        break;

      case "onRemoveAttachment":
        !textArea?.target?.value
          ? getDispayNone()
          : getDispayBlock("onRemoveAttachment");
        break;

      default:
        inputTextArea.current.style.display = "none";
        getMarginInputAttachmentBox();
        break;
    }
  };

  const getDispayBlock = (str) => {
    switch (str) {
      case "fromFileAttachment":
        getMarginInputAttachmentBox("marginTop-15px");
        getDispayBlock();
        break;

      case "onRemoveAttachment":
        getMarginInputAttachmentBox("marginTop-15px");
        getDispayBlock();
        if (textArea?.target?.value && attachmentFile.length === 1) {
          getMarginInputAttachmentBox(" marginTop_0px");
        }
        break;

      case "fromTextWithAttachment":
        if (attachmentFile.length !== 0) {
          getMarginInputAttachmentBox("marginTop-15px");
          getDispayBlock();
          inputTextArea.current.focus();
        }
        break;

      default:
        inputTextArea.current.style.display = "block";
        break;
    }
  };

  const onBlurTextArea = () => {
    !textArea?.target?.value && attachmentFile.length !== 0
      ? getDispayNone()
      : getDispayBlock();
  };

  const onFocusTextArea = () => {
    if (attachmentFile.length !== 0) {
      getMarginInputAttachmentBox("marginTop-15px");
    }
  };

  const onSubmitHandler = (e) => {
    //  console.log(
    //   { messageContent: textArea?.target?.value },
    //   {
    //     attachmentFile,
    //   }
    // );

    dispatch(
      sendMessage(
        id, // ConversationId
        "name", // name
        "RecipientUserId", // RecipientUserId
        textArea?.target?.value, // MessageText
        "ProfilePic", // ProfilePic
        attachmentFile // Attachments
      )
    );
    e.preventDefault();
    setAttachmentFile([]);
    setTextAreaValue("");
    getMarginInputAttachmentBox("marginTop_0px");

    // if (window.innerWidth < 768) inputTextArea.current.style.height = "53px";
    // else inputTextArea.current.style.height = "40px";
    
    inputTextArea.current.style.height = "40px";
  };

  useEffect(() => {
    if (!textArea?.target?.value && attachmentFile.length === 0) {
      getDispayBlock();
    }
  }, [attachmentFile, textArea]);

  return (
    <>
      <div className="send-message-container">
        <div className="text-with-attachment ant-input">
          <textarea
            name=""
            id=""
            placeholder="Type your message here..."
            className="teaxArea-content ant-input"
            onChange={(e) => textAreaAdjust(e)}
            ref={inputTextArea}
            onBlur={onBlurTextArea}
            onFocus={onFocusTextArea}
            value={textAreaValue}
          ></textarea>
          <div
            className="attachment-box"
            ref={inputAttachmentBox}
            onClick={handleOnCLickTextAreaFocus}
          >
            {attachmentFile.map((file, i) => (
              <AttachmentDiv key={i} file={file} />
            ))}
          </div>
        </div>
        <div className="message-bottom">
          <div className="conversation-icon">
            <label htmlFor="file">
              <CgAttachment className="conversation-icon" />
            </label>
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={(e) => onChangeFile(e)}
              multiple
            />
          </div>
          <a className="message-send-btn" onClick={(e) => onSubmitHandler(e)}>
            Send
          </a>
        </div>
      </div>
    </>
  );
};

export default OnboardingMessageSend;
