import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { fetchToken } from "../../../../../API/API";

export const bidStatusOptions = [
  {
    New: 1,
  },
  // {
  //   Retracted: 4,
  // },
  {
    Accepted: 2,
  },
  {
    Declined: 3,
  },
  {
    "Profile Submitted": 13,
  },
  // {
  //   Scheduled: 24,
  // },
  {
    Interview: 6,
  },
  {
    "Interview Accepted": 7,
  },
  {
    "Interview Declined": 14,
  },
  {
    Offer: 12,
  },
  {
    "Offer Accepted": 25,
  },
  {
    "Offer Declined": 26,
  },
  {
    Hired: 11,
  },
  {
    "On Billing": 15,
  },
  {
    "Off Billing": 18,
  },
  {
    "Billing Extend": 16,
  },
  {
    Canceled: 8,
  },
  {
    Terminated: 22,
  },
];

export const sortOptions = [
  { "Most Recent": "most_recent" },
  { "Pay Package High to Low": "pay_hi_to_low" },
  { "Pay Package Low to High": "pay_low_to_hi" },
  { "Near By Location": "nearby_location" },
];

export const getDeclineReasonOnFrontEnd = {
  "Decline this Bid?": [
    "On Assignment",
    "Not Interested in the location",
    "Estimated pay rate is too low",
    "Skill not a match",
  ],
  "Decline this Interview?": [
    "Accepted another offer",
    "Not interested in this Gig",
    "Estimated pay rate is too low",
    "I would like to reshedule",
  ],
  "Decline this Offer?": [
    "Accepted another offer",
    "Not interested in this Gig",
    "Estimated pay rate is too low",
  ],
};

export const getDeclineReason = (type, Id) => {
  const Bid = [
    {
      ReasonId: 3,
      Reason: "Not interested in the location",
      Created: "2014-03-17T18:56:49.600Z",
      CreatedBy: 0,
      ModifiedBy: 0,
      _id: "63ae878a60f52c72dbca8344",
      Modified: "2014-03-17T18:56:49.600Z",
      SortOrder: null,
      Visible: 1,
    },
    {
      ReasonId: 2,
      Reason: "On Assignment",
      Created: "2014-03-17T18:56:49.600Z",
      CreatedBy: 0,
      ModifiedBy: 0,
      _id: "63ae878a60f52c72dbca8343",
      Modified: "2014-03-17T18:56:49.600Z",
      SortOrder: null,
      Visible: 1,
    },
    {
      ReasonId: 4,
      Reason: "Estimated pay rate is too low",
      Created: "2014-03-17T18:56:49.600Z",
      CreatedBy: 0,
      ModifiedBy: 0,
      _id: "63ae878a60f52c72dbca8345",
      Modified: "2014-03-17T18:56:49.600Z",
      SortOrder: null,
      Visible: 1,
    },
    {
      ReasonId: 5,
      Reason: "Skills not a match",
      Created: "2014-03-17T18:56:49.600Z",
      CreatedBy: 0,
      ModifiedBy: 0,
      _id: "63ae878a60f52c72dbca8346",
      Modified: "2014-03-17T18:56:49.600Z",
      SortOrder: null,
      Visible: 1,
    },
  ];
  const Interview = [
    {
      ReasonId: 4,
      Reason: "I would like to reschedule",
      Created: "2018-05-02T21:13:23.000Z",
      CreatedBy: null,
      ModifiedBy: null,
      _id: "63ae878a60f52c72dbca833c",
      Modified: "2018-05-02T21:13:23.000Z",
      SortOrder: null,
      Visible: 1,
    },
    {
      ReasonId: 3,
      Reason: "Not interested in this Gig",
      Created: "2018-05-02T21:13:23.000Z",
      CreatedBy: null,
      ModifiedBy: null,
      _id: "63ae878a60f52c72dbca833b",
      Modified: "2018-05-02T21:13:23.000Z",
      SortOrder: null,
      Visible: 1,
    },
    {
      ReasonId: 1,
      Reason: "Accepted another offer",
      Created: "2015-03-02T00:23:15.053Z",
      CreatedBy: null,
      ModifiedBy: null,
      _id: "63ae878a60f52c72dbca8339",
      Modified: "2015-03-02T00:23:15.053Z",
      SortOrder: null,
      Visible: 1,
    },
  ];
  const declineReasonsPlaced = [
    {
      ReasonId: 1,
      Reason: "Accepted another candidate",
    },
    {
      ReasonId: 2,
      Reason: "Not interested in the candidate",
    },
    {
      ReasonId: 3,
      Reason: "Not interested in this Gig",
    },
    {
      ReasonId: 4,
      Reason: "Asking payrate is too high",
    },
  ];
  if (type === "Bid") {
    return Bid.filter(({ ReasonId }) => ReasonId === Id)?.[0]?.Reason;
  }
  if (type === "Interview") {
    return Interview.filter(({ ReasonId }) => ReasonId === Id)?.[0]?.Reason;
  }
  if (type === "declineReasonsPlaced") {
    return declineReasonsPlaced.filter(({ ReasonId }) => ReasonId === Id)?.[0]
      ?.Reason;
  }
};

export const useBlurEffect = (inputRef, callbackFunction) => {
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        callbackFunction(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [inputRef]);
};

export const getProposedInterviewDateAndTime = (time) => {
  const timedate = moment(time).format("dddd , MMMM DD , YYYY , h:mm A");
  const tdiff = moment(time.setHours(time.getHours() + 1)).format("LT");
  let TimeArr = timedate.split(", ");
  TimeArr.push(tdiff);
  return TimeArr;
};

export const handlingfalsyValue = (val) =>
  val ? val : typeof val === "number" ? val : "NA";

export const getBidHistoryData = (bids, BidStatuses) => {
  return bids.map((data, index) => {
    const bidData = data;
    const gigData = data?.gigs;
    const BudgetTypeData = data?.BudgetType;
    const interviewData = data?.interview;
    const offersData = data?.offers;
    const Employer = data?.Employer;
    const skill_listings = data?.skill_listings;
    const rankingData = BidStatuses.filter((obj) =>
      bidData?.BidStatusId === 23
        ? obj.BidStatusId === 14
        : obj.BidStatusId === bidData?.BidStatusId
    )[0];

    return {
      key: index,
      id: handlingfalsyValue(bidData?.BidId),
      UserId: bidData?.ModifiedBy,
      BidId: handlingfalsyValue(bidData?.BidId),
      ConversationId: handlingfalsyValue(bidData?.message?.ConversationId),
      EmployerName:
        handlingfalsyValue(Employer?.Profiles?.FirstName) +
        " " +
        handlingfalsyValue(Employer?.Profiles?.LastName),
      Rate: handlingfalsyValue(bidData?.Rate),
      BudgetTypeName: handlingfalsyValue(BudgetTypeData?.BudgetTypeName),
      Location: handlingfalsyValue(
        skill_listings?.SkillListings_Cities?.[0]?.Cities?.Location
      ),
      IsFavorite: handlingfalsyValue(bidData?.IsFavorite),
      GigId: handlingfalsyValue(bidData?.GigId),
      Title: handlingfalsyValue(gigData?.Title),
      Created: handlingfalsyValue(bidData?.Created),
      BidStage: {
        Status: handlingfalsyValue(rankingData?.BidStatusName),
        Modified: handlingfalsyValue(bidData?.Modified),
        // Created: "2022-02-10T00:00:00+05:30",
        DeclineReason: handlingfalsyValue(
          rankingData?.Rank === 11
            ? offersData?.Reason
            : rankingData?.Rank === 8
            ? getDeclineReason("Interview", interviewData?.DeclineReasonId)
            : getDeclineReason("Bid", bidData?.DeclineReasonId)
        ),
        Interview: {
          InterviewStatusName:
            interviewData?.InterviewStatuses?.InterviewStatusName,
          InterviewId: interviewData?.InterviewId,
          AvailableIT:
            interviewData?.InterviewStatuses?.InterviewStatusName === "Accepted"
              ? [interviewData?.ApprovedScheduledTiming]
              : interviewData?.InterviewStatuses?.InterviewStatusName ===
                "Rescheduled"
              ? interviewData?.ReScheduledTimings[
                  interviewData?.ReScheduledTimings?.length - 1
                ]
              : interviewData?.Interviews_ScheduledTimings,
        },
        Offer: {
          FileName: handlingfalsyValue(offersData?.Attachments?.[0]?.FileName),
          FileSize: handlingfalsyValue(offersData?.Attachments?.[0]?.FileSize),
          FilePath: handlingfalsyValue(offersData?.Attachments?.[0]?.FilePath),
        },
        Rank: handlingfalsyValue(rankingData?.Rank),
      },
    };
  });
};

export const getS3BucketUrl = async (file) => {
  const headers = await fetchToken();
  const uploadpayload = {
    type: "msgAttachment",
    contentType: file.type,
  };
  const getUploadUrl = `${process.env.REACT_APP_API_URL}/upload`;
  const data = await axios.post(getUploadUrl, uploadpayload, { headers });
  const response = data.data;
  return response;
};

export const uploadFileWithS3BucketUrl = async (fileUrl, file) => {
  return await axios.put(fileUrl, file);
};
