import React, { useState, useEffect, useRef } from "react";
import "./MobileFilters.css";
import { Button, Radio, Input, Menu, Drawer, Divider, Space } from "antd";
import { debounce, timeout } from "../../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import {
  fetchKeySearchLocation,
  fetchSearchSkill,
  saveSearchLocation,
  saveSearchSkill,
  UpdateSearchFilter,
} from "../../../redux/actions";
import { state } from "@antv/g2plot/lib/adaptor/common";

const MobileFilters = (props) => {
  const { removeQuery, addQuery, HCtotalCount, ITtotalCount, totalCount } = props;
  const jobQuery = useSelector((state) => state.searchGigsReducer.jobQuery);
  const skillQuery = useSelector((state) => state.searchGigsReducer.skillQuery);
  const locationQuery = useSelector(
    (state) => state.searchGigsReducer.locationQuery
  );
  const minbudgetQuery = useSelector(
    (state) => state.searchGigsReducer.minbudgetQuery
  );
  const maxbudgetQuery = useSelector(
    (state) => state.searchGigsReducer.maxbudgetQuery
  );
  const budgetQuery = useSelector(
    (state) => state.searchGigsReducer.budgetQuery
  );
  const budgetTypeQuery = useSelector(
    (state) => state.searchGigsReducer.budgetTypeQuery
  );
  const ProfessionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );
  const WorkplacePolicy = useSelector(
    (state) => state.searchGigsReducer.WorkplacePolicy
  );
  const authToken = useSelector(
    (state) => state.authReducer.user?.authToken,
  );
  const pageNo = useSelector((state) => state.searchGigsReducer.pageNo);
  const [jobValue, setJobValue] = useState(jobQuery);
  const [budgetMin, setBudgetMin] = useState(minbudgetQuery);
  const [budgetMax, setBudgetMax] = useState(maxbudgetQuery);
  const [budgetType, setBudgetType] = useState(budgetTypeQuery);
  const [WorkplacePolicyItem, setWorkplacePolicyItem] =
    useState(WorkplacePolicy);
  const [skillsinput, setSkillsInput] = useState(skillQuery);
  const [locationinput, setLocationInput] = useState(locationQuery);
  const reducerSkill = useSelector(
    (state) => state.skillListingReducer.skillQuery
  );
  const reducerLocation = useSelector(
    (state) => state.skillListingReducer.LocationQuery
  );
  const [skillValue, setSkillValue] = useState(reducerSkill);
  const [locationValue, setLocationValue] = useState(reducerLocation);
  const [professionValue, setprofessionValue] = useState("All");
  const [noDataView, setNoDataView] = useState("");
  const [visible, setVisible] = useState(false);
  const [filterDrawerSkill, setFilterDrawerSkill] = useState(false);
  const [filterDrawerLocation, setFilterDrawerLocation] = useState(false);
  const [filterDrawerProfession, setfilterDrawerProfession] = useState(false);
  const skills = useSelector((state) => state.skillListingReducer.skills);
  const locations = useSelector(
    (state) => state.credentialingReducer.searchedLocation
  );
  const [isBudgetType1, setIsBudgetType1] = useState(false);
  const [isBudgetType2, setIsBudgetType2] = useState(false);
  const [validation, setValidation] = useState(false);
  const isBudgetContract = useSelector(
    (state) => state.searchGigsReducer.isBudgetContract
  );
  const isBudgetPermanent = useSelector(
    (state) => state.searchGigsReducer.isBudgetPermanent
  );
  const ProfessionCodeValue = useSelector(
    (state) => state.searchGigsReducer.ProfessionCodeValue
  );
  const dispatch = useDispatch();
  const notInitialRender = useRef(false);
  const minBudgetRef = useRef(null);
  const maxBudgetRef = useRef(null);

  // useEffect(() => {
  //   setSkillValue(skillQuery);
  //   setLocationValue(locationQuery);
  //   setBudgetMin(minbudgetQuery);
  //   setBudgetMax(maxbudgetQuery);
  //   setBudgetType(budgetTypeQuery);
  // }, [
  //   skillQuery,
  //   locationQuery,
  //   minbudgetQuery,
  //   maxbudgetQuery,
  //   budgetTypeQuery,
  // ]);

  useEffect(() => {
    if (notInitialRender.current) {
      setBudgetMin(null);
      setBudgetMax(null);
      setBudgetType(null);
      setSkillValue(null);
      setLocationValue(null);
      setJobValue(null);
      setValidation(false);
      setWorkplacePolicyItem(null);
      // minBudgetRef.current.focus();
      // minBudgetRef.current.background = "red";
      // maxBudgetRef.current.value = "A";
      // // console.log("props.oncloearall", props.onClearAll);
    } else {
      notInitialRender.current = true;
    }
  }, [props.onClearAll]);

  useEffect(() => {
    setprofessionValue(ProfessionCodeValue)
  }, [ProfessionCodeValue])

  const onChangeSkill = (e) => {
    // console.log("radio checked", e.target.value);
    // dispatch(UpdateSearchFilter("skillQuery", e.target.value));
    setSkillValue(e.target.value);
    setFilterDrawerSkill(false);
    // dispatch(UpdateSearchFilter("filterDrawer", false));
  };
  const onChangeLocation = (e) => {
    // console.log("radio checked", e.target.value);
    // dispatch(UpdateSearchFilter("locationQuery", e.target.value));
    setLocationValue(e.target.value);
    setFilterDrawerLocation(false);
    // dispatch(UpdateSearchFilter("filterDrawer", false));
  };
  const onChangeProfession = (e) => {
    // console.log("radio checked", e.target.value);
    // dispatch(UpdateSearchFilter("locationQuery", e.target.value));
    setprofessionValue(e.target.value);
    setfilterDrawerProfession(false);
    // dispatch(UpdateSearchFilter("filterDrawer", false));
  };

  const onApply = (value, filter) => {
    if (
      jobValue &&
      !locationValue &&
      !skillValue &&
      !budgetType &&
      !budgetMin &&
      !budgetMax
    ) {
      // console.log("applied");
      // setjobQuery(value);
      dispatch(UpdateSearchFilter("jobQuery", jobValue));
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
      if (jobValue === "Contract") {
        dispatch(UpdateSearchFilter("isBudgetContract", true));
        dispatch(UpdateSearchFilter("isBudgetPermanent", false));
      }
      if (jobValue === "Permanent") {
        dispatch(UpdateSearchFilter("isBudgetContract", false));
        dispatch(UpdateSearchFilter("isBudgetPermanent", true));
      }
      addQuery("jt", jobValue[0])
    }
    if (
      skillValue &&
      !jobValue &&
      !locationValue &&
      !budgetType &&
      !budgetMin &&
      !budgetMax
    ) {
      // console.log("applied");
      // setskillQuery(value);
      // props.UpdateSearchFilter("skillQuery", value);
      dispatch(UpdateSearchFilter("skillQuery", skillValue));
      // console.log("query value", skillValue);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
      addQuery("sl", skillValue)
    }
    if (
      locationValue &&
      !jobValue &&
      !skillValue &&
      !budgetType &&
      !budgetMin &&
      !budgetMax
    ) {
      // console.log("applied");
      // setlocationQuery(value);
      dispatch(UpdateSearchFilter("locationQuery", locationValue));
      // console.log("query value", locationValue);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
      addQuery("loc", locationValue)
    }
    if (
      (budgetType &&
        budgetMin > 0 &&
        !budgetMax &&
        !jobValue &&
        !locationValue &&
        !skillValue) ||
      (budgetType &&
        budgetMax > 0 &&
        !budgetMin &&
        !jobValue &&
        !locationValue &&
        !skillValue)
    ) {
      // console.log("applied");
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      // setBudgetQuery(true);
      // console.log("query value", budgetMin);
      // console.log("query value", budgetMax);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      budgetType &&
      budgetMin > 0 &&
      budgetMax > 0 &&
      budgetMin < budgetMax &&
      !jobValue &&
      !locationValue &&
      !skillValue
    ) {
      // console.log("applied");
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      // console.log("query value", budgetMin);
      // console.log("query value", budgetMax);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    // if (
    //   jobValue ||
    //   skillValue ||
    //   locationValue ||
    //   (budgetType && (budgetMin > 0 || budgetMax > 0)) ||
    //   (budgetType &&
    //     budgetMin > 0 &&
    //     budgetMax > 0 &&
    //     budgetMax > 0 > budgetMin > 0)
    // ) {
    //   // console.log("applied");
    //   if (jobValue) {
    //     // setjobQuery(value);
    //     dispatch(UpdateSearchFilter("jobQuery", jobValue));
    //     // setPageNo("1");
    //     dispatch(UpdateSearchFilter("pageNo", "1"));
    //     dispatch(UpdateSearchFilter("filterDrawer", false));
    //     if (jobValue === "Contract") {
    //       dispatch(UpdateSearchFilter("isBudgetContract", true));
    //       dispatch(UpdateSearchFilter("isBudgetPermanent", false));
    //     }
    //     if (jobValue === "Permanent") {
    //       dispatch(UpdateSearchFilter("isBudgetContract", false));
    //       dispatch(UpdateSearchFilter("isBudgetPermanent", true));
    //     }
    //   }
    //   if (skillValue) {
    //     // setskillQuery(value);
    //     // props.UpdateSearchFilter("skillQuery", value);
    //     dispatch(UpdateSearchFilter("skillQuery", skillValue));
    //     // console.log("query value", skillValue);
    //     // setPageNo("1");
    //     dispatch(UpdateSearchFilter("pageNo", "1"));
    //     dispatch(UpdateSearchFilter("filterDrawer", false));
    //   }
    //   if (locationValue) {
    //     // setlocationQuery(value);
    //     dispatch(UpdateSearchFilter("locationQuery", locationValue));
    //     // console.log("query value", locationValue);
    //     // setPageNo("1");
    //     dispatch(UpdateSearchFilter("pageNo", "1"));
    //     dispatch(UpdateSearchFilter("filterDrawer", false));
    //   }
    //   dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
    //   dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
    //   dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
    //   dispatch(UpdateSearchFilter("budgetQuery", true));
    //   // console.log("query value", budgetMin);
    //   // console.log("query value", budgetMax);
    //   // setPageNo("1");
    //   dispatch(UpdateSearchFilter("pageNo", "1"));
    //   dispatch(UpdateSearchFilter("filterDrawer", false));
    // }
    if (
      (jobValue && (locationValue || skillValue)) ||
      (budgetType && (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin) ||
      (skillValue && (locationValue || jobValue)) ||
      (budgetType && (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin)
    ) {
      // console.log("applied");
      if (jobValue) {
        // setjobQuery(value);
        dispatch(UpdateSearchFilter("jobQuery", jobValue));
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        if (jobValue === "Contract") {
          dispatch(UpdateSearchFilter("isBudgetContract", true));
          dispatch(UpdateSearchFilter("isBudgetPermanent", false));
        }
        if (jobValue === "Permanent") {
          dispatch(UpdateSearchFilter("isBudgetContract", false));
          dispatch(UpdateSearchFilter("isBudgetPermanent", true));
        }
      }
      if (skillValue) {
        // setskillQuery(value);
        // props.UpdateSearchFilter("skillQuery", value);
        dispatch(UpdateSearchFilter("skillQuery", skillValue));
        // console.log("query value", skillValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("sl", skillValue)
      }
      if (locationValue) {
        // setlocationQuery(value);
        dispatch(UpdateSearchFilter("locationQuery", locationValue));
        // console.log("query value", locationValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("loc", locationValue);
      }
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      // console.log("query value", budgetMin);
      // console.log("query value", budgetMax);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      (jobValue && skillValue && locationValue) ||
      (jobValue &&
        skillValue &&
        budgetType &&
        (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin) ||
      (jobValue &&
        locationValue &&
        budgetType &&
        (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin)
    ) {
      // console.log("applied");
      if (jobValue) {
        // setjobQuery(value);
        dispatch(UpdateSearchFilter("jobQuery", jobValue));
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        if (jobValue === "Contract") {
          dispatch(UpdateSearchFilter("isBudgetContract", true));
          dispatch(UpdateSearchFilter("isBudgetPermanent", false));
        }
        if (jobValue === "Permanent") {
          dispatch(UpdateSearchFilter("isBudgetContract", false));
          dispatch(UpdateSearchFilter("isBudgetPermanent", true));
        }
      }
      if (skillValue) {
        // setskillQuery(value);
        // props.UpdateSearchFilter("skillQuery", value);
        dispatch(UpdateSearchFilter("skillQuery", skillValue));
        // console.log("query value", skillValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("sl", skillValue)
      }
      if (locationValue) {
        // setlocationQuery(value);
        dispatch(UpdateSearchFilter("locationQuery", locationValue));
        // console.log("query value", locationValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("loc", locationValue)
      }
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      // console.log("query value", budgetMin);
      // console.log("query value", budgetMax);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      (jobValue &&
        budgetType &&
        skillValue &&
        locationValue &&
        (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin)
    ) {
      // console.log("applied");
      if (jobValue) {
        // setjobQuery(value);
        dispatch(UpdateSearchFilter("jobQuery", jobValue));
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        if (jobValue === "Contract") {
          dispatch(UpdateSearchFilter("isBudgetContract", true));
          dispatch(UpdateSearchFilter("isBudgetPermanent", false));
        }
        if (jobValue === "Permanent") {
          dispatch(UpdateSearchFilter("isBudgetContract", false));
          dispatch(UpdateSearchFilter("isBudgetPermanent", true));
        }
      }
      if (skillValue) {
        dispatch(UpdateSearchFilter("skillQuery", skillValue));
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("sl", skillValue)
      }
      if (locationValue) {
        dispatch(UpdateSearchFilter("locationQuery", locationValue));
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("loc", locationValue)
      }
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (WorkplacePolicyItem) {
      dispatch(UpdateSearchFilter("WorkplacePolicy", WorkplacePolicyItem));
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    } else {
      setValidation(true);
    }
    if (professionValue) {
      dispatch(UpdateSearchFilter("filterDrawer", false));
      setValidation(false);
      dispatch(UpdateSearchFilter("ProfessionCodeValue", professionValue === "All" ? null : professionValue?.substring(0, 2)));
      dispatch(UpdateSearchFilter("pageNo", "1"));
      if (professionValue == "All") {
        removeQuery("pc");
        return;
      }
      addQuery("pc", professionValue?.substring(0, 2));
    }
  };

  // GET SKILLS
  const onSkillSearch = (val) => {
    setNoDataView(val);
    dispatch(fetchSearchSkill({ val: val, profCode: ProfessionCode}));
    setSkillsInput(val);
  };
  const getSearchSkill = (value) => {
    if (value) {
      dispatch(saveSearchSkill(value));
    }
  };

  // GET LOCATIONS
  function onLocationSearch(val) {
    setNoDataView(val);
    dispatch(fetchKeySearchLocation(val));
  }

  const getSearchLocation = (value) => {
    if (value) {
      dispatch(saveSearchLocation(value));
    }
  };

  // Filter Drawer
  const handleFilterDrawerSkill = (state) => {
    setFilterDrawerSkill(state);
    setVisible(false);
  };
  const handleFilterDrawerprofession = (state) => {
    setfilterDrawerProfession(state);
    setVisible(false);
  };
  const handleFilterDrawerLocation = (state) => {
    setFilterDrawerLocation(state);
    setVisible(false);
  };
  const mobileFilterProfession = () => {
    if (window.innerWidth < 768) handleFilterDrawerprofession(!filterDrawerProfession);
    setVisible(true);
  };
  const mobileFilterSkill = () => {
    if (window.innerWidth < 768) handleFilterDrawerSkill(!filterDrawerSkill);
    setVisible(true);
  };
  const mobileFilterLocation = () => {
    if (window.innerWidth < 768)
      handleFilterDrawerLocation(!filterDrawerLocation);
    setVisible(true);
  };
  const closeFilterDrawer = () => {
    setFilterDrawerSkill(false);
    setFilterDrawerLocation(false);
    setfilterDrawerProfession(false);
  };
  const skillMenu = (
    <Menu onClick={null} className="s-result-mobile">
      <Input
        placeholder="Find a skill"
        prefix={<SearchOutlined />}
        defaultValue={skillValue}
        // style={{ width: 200 }}
        className="skill-search-mobile"
        onChange={debounce((e) => onSkillSearch(e.target.value))}
      />

      <Menu>
        {skills &&
          skills.map((skill) => {
            return (
              <Menu.Item onClick={(val) => getSearchLocation(val)}>
                <div>
                  <span
                  // className={skill === receivedBidSort ? "greenTextRB" : null}
                  >
                    <Radio.Group onChange={onChangeSkill} value={skillValue}>
                      <Radio value={skill?.SkillName} className="Search-jobs">
                        <span style={{ color: "#3e3e3e" }}>
                          {skill?.SkillName}
                        </span>
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
    </Menu>
  );
  const locationMenu = (
    <Menu onClick={null} className="s-result-mobile">
      <Input
        placeholder="Select Location"
        prefix={<SearchOutlined />}
        defaultValue={locationValue}
        // style={{ width: 200 }}
        className="skill-search-mobile"
        onChange={debounce((e) => onLocationSearch(e.target.value))}
      />
      <div className={`location-lists-container ${locations?.length > 9 ? 'show-scrollbar' : ''}`}>
        <Menu>
          {locations &&
            locations.map((city) => {
              return (
                <Menu.Item onClick={(val) => getSearchSkill(val)}>
                  <div>
                    <span
                    // className={skill === receivedBidSort ? "greenTextRB" : null}
                    >
                      <Radio.Group
                        onChange={onChangeLocation}
                        value={locationValue}
                      >
                        <Radio value={city?.Location} className="Search-jobs">
                          <span style={{ color: "#3e3e3e" }}>
                            {city?.Location}
                          </span>
                        </Radio>
                      </Radio.Group>
                    </span>
                  </div>
                </Menu.Item>
              );
            })}
        </Menu>
      </div>
    </Menu>
  );
  const ProfessionList = ["All", "HC - Healthcare", "IT - Information Technology"];
  const ProfessionMenu = (
    <Menu onClick={null} className="s-result-mobile">
      <Menu>
        {ProfessionList &&
          ProfessionList.map((data) => {
            return (
              <Menu.Item onClick={null}>
                <div>
                  <span
                  >
                    <Radio.Group
                      onChange={onChangeProfession}
                      value={
                        professionValue ?
                          professionValue === "HC"
                            ? ProfessionList[1] :
                            professionValue === "IT" ?
                              ProfessionList[2] :
                              professionValue :
                          "All"
                      }
                    >
                      <Radio value={data} className="Search-jobs">
                        <span style={{ color: "#3e3e3e" }}>
                          {data}
                          {data === "All" ? ` (${totalCount})` : data === "HC - Healthcare" ? ` (${HCtotalCount})` : ` (${ITtotalCount})`}
                        </span>
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
    </Menu>
  );
  const budgetTypeClick1 = () => {
    setJobValue(["Contract", "Per Diem"]);
    setValidation(false);
  };
  const budgetTypeClick2 = () => {
    dispatch(UpdateSearchFilter("isBudgetContract", false));
    setJobValue(["Permanent"]);
    setValidation(false);
  };
  const onChangeBudget = (e) => {
    setBudgetType(e.target.value);
  };
  const onChangeWorkplace = (e) => {
    setWorkplacePolicyItem(e.target.value);
  };
  const ITFilter = ["Fully remote", "Hybrid", "On-premise"];
  const handleNumberInput = (value) => value.replace(/\D/g, "");
  return (
    <div className="Mobile-filter-container">
      <div className="job-type">Gig type</div>
      <div className="mobile-filter">
        <div
          className={
            validation
              ? "left-invalid"
              : jobValue && jobValue[0] === "Contract"
                ? "left-active"
                : "left"
          }
          onClick={budgetTypeClick1}
        >
          <div className="text" onClick={budgetTypeClick1}>
            Contract
          </div>
        </div>
        <div
          className={
            validation
              ? "left-invalid"
              : jobValue && jobValue[0] === "Permanent"
                ? // ||isBudgetPermanent ||
                //   jobQuery === "Permanent"
                "left-active"
                : "left"
          }
          onClick={budgetTypeClick2}
        >
          <div className="text">Permanent</div>
        </div>
      </div>
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select any of the gig type
        </div>
      ) : null}

      {/* {ProfessionCode !== "IT" ? (
        <>
          <div className="job-type">Budget</div>
          {jobValue || isBudgetContract || isBudgetPermanent || jobQuery ? (
            <>
              <Radio.Group
                onChange={onChangeBudget}
                value={budgetType}
                defaultValue={budgetTypeQuery}
              >
                <Radio value={"Hourly"} className="Search-jobs">
                  Hourly
                </Radio>
                <Radio value={"Weekly"} className="Search-jobs">
                  Weekly
                </Radio>
              </Radio.Group>
            </>
          ) : null}
          <div className="input-container">
            <Input
              prefix="$"
              className={validation ? "input-left-invalid" : "input-left"}
              // placeholder="50"
              onChange={(e) => setBudgetMin(handleNumberInput(e.target.value))}
              // value={minbudgetQuery}
              ref={minBudgetRef}
              value={budgetMin}
            />
            <div className="dash">-</div>
            <Input
              prefix="$"
              className={validation ? "input-right-invalid" : "input-right"}
              // placeholder="60"
              onChange={(e) => setBudgetMax(handleNumberInput(e.target.value))}
              ref={maxBudgetRef}
              value={budgetMax}
            />
          </div>
          {validation ? (
            <div className="search-results-filter-mobile-validation-text">
              Please enter budget
            </div>
          ) : null}
        </>
      ) : null} */}

      {/* LOCATION AND SEARCH INPUTS */}
      {!authToken && <><div className="job-type">Profession</div>
        <Input
          placeholder="Find a skill"
          value={
            professionValue ?
              professionValue === "HC"
                ? ProfessionList[1] :
                professionValue === "IT" ?
                  ProfessionList[2] :
                  professionValue :
              "All"
          }
          // style={{ width: 200 }}
          className={
            validation ? "skill-search-mobile" : "skill-search-mobile"
          }
          // onChange={debounce((e) => onSkillSearch(e.target.value))}
          onClick={mobileFilterProfession}
        /></>}
      <div className="job-type">Skill</div>
      <Input
        placeholder="Find a skill"
        value={skillQuery ? skillQuery : skillValue}
        // style={{ width: 200 }}
        className={
          validation ? "skill-search-mobile-invalid" : "skill-search-mobile"
        }
        // onChange={debounce((e) => onSkillSearch(e.target.value))}
        onClick={mobileFilterSkill}
      />
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select skill
        </div>
      ) : null}
      <div className="job-type">Location</div>
      <Input
        placeholder="Add your preferred location"
        value={locationQuery ? locationQuery?.Location : locationValue}
        // style={{ width: 200 }}
        className={
          validation ? "skill-search-mobile-invalid" : "skill-search-mobile"
        }
        // onChange={debounce((e) => onSkillSearch(e.target.value))}
        onClick={mobileFilterLocation}
      />
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select location
        </div>
      ) : null}
      {ProfessionCode === "IT" ? (
        <>
          <div className="job-type">Workplace policy</div>
          <Radio.Group
            className="radioSortReceivedBidsGroupFilter"
            onChange={(e) => onChangeWorkplace(e)}
            value={WorkplacePolicyItem}
            defaultValue={WorkplacePolicy}
          >
            <Space direction="vertical">
              {ITFilter.map((dataIT) => {
                return (
                  <>
                    <Radio
                      className={
                        "radioSortReceivedBidsFilter " +
                        (validation
                          ? "MobileFilter-Search-input-left-invalid"
                          : null)
                      }
                      value={dataIT}
                    >
                      <span className="SearchRadioButtonColor">{dataIT}</span>
                    </Radio>
                  </>
                );
              })}
            </Space>
          </Radio.Group>
        </>
      ) : null}
      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Find a skill"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerSkill}
          className="SearchfilterDrawer-mobile Skill-Selecter-filter"
          height="40rem"
          width="100%"
        >
          {skillMenu}
        </Drawer>
      </div>
      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Select location"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerLocation}
          className="SearchfilterDrawer-mobile Skill-Selecter-filter"
          height="40rem"
          width="100%"
        // footer="Hello"
        >
          {locationMenu}
        </Drawer>
      </div>
      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Select Profession"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerProfession}
          className="SearchfilterDrawer-mobile Skill-Selecter-filter"
          height="40rem"
          width="100%"
        // footer="Hello"
        >
          {ProfessionMenu}
        </Drawer>
      </div>
      {/* <div>
        <Divider />
      </div> */}
      <div className="apply_button_main">
        <div className="apply-button-mobile-filter">
          <div className="apply-button-text" onClick={onApply}>
            Apply filter
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFilters;
