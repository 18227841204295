import React from "react";
import "./ExpiredGigs.css";

export const ExpiredGigs = (props) => {
  const { expday, expmonth, expyear, expdate } = props;
  return (
    <div className="expired-gigs-container">
      <div className="expired-gigs-items expired-gigs-items-text">
        This gig has expired
      </div>
      <div className="expired-gigs-items">
        Date : {expday}, {expmonth} {expdate}, {expyear}
      </div>
    </div>
  );
};

export default ExpiredGigs;
