import React from "react";
import "./SocialAccounts.css";
import SocialAccounts from "./SocialAccounts";

const SocialAccountsWrap = () => {
  return (
    <div className="socialAccountsBox">
      <SocialAccounts />
    </div>
  );
};

export default SocialAccountsWrap;
