
import {initializeApp} from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyDbDd-PJEXdaSOAGtZqwx7NJON_N2M2Nyg",
    authDomain: "skillgigs-p3.firebaseapp.com",
    projectId: "skillgigs-p3",
    storageBucket: "skillgigs-p3.appspot.com",
    messagingSenderId: "280644363877",
    appId: "1:280644363877:web:5d64cd66d4e04c0096488c",
    measurementId: "G-8DPTQS55H3"
  };
  

// Initialize Firebase
initializeApp(firebaseConfig);
