import { types } from "../action-types";
const initialState = {
  valid: false,
  msg: "",
  loading: false,
};

const checkUserNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_USERNAME_UNIQUE:
      return {
        ...state,
        loading: true,
      };
    case types.CHECK_USERNAME_UNIQUE_SUCCESS:
      return {
        ...state,
        valid: action.payload.valid.valid,
        msg:action.payload.valid.msg,
        loading: false,
      };
    case types.CHECK_USERNAME_UNIQUE_FAIL:
      return initialState;
        
    default:
      return state;
  }
};

export default checkUserNameReducer;
