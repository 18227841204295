import { types } from "../action-types";

const initialState = {
  loading: false,
  offers: [],
  error: {},
};

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case types.ADD_MESSAGE_TO_FAVOURITE_SUCCESS:
      // console.log("ADD_MESSAGE_TO_FAVOURITE >>SUCCESS", action.payload);
      
      return {
        ...state,
        loading: false,
        offers: action.payload,
        error: {},
      };
      case types.ADD_MESSAGE_TO_FAVOURITE_REQUESTED:
        // console.log("ADD_MESSAGE_TO_FAVOURITE >>REQUESTED");
        return {
          ...state,
          loading: true,
        };
    case types.ADD_MESSAGE_TO_FAVOURITE_FAILURE:
      // console.log("ADD_MESSAGE_TO_FAVOURITE >>FAILURE >>",action.error);
      return {
        ...state,
        loading: false,
        offers: [],
        error: action.error,
      };

    case types.REMOVE_MESSAGE_TO_FAVOURITE_REQUESTED:
      // console.log("message reducer got clicked");
      return {
        ...state,
        loading: true,
      };

    case types.REMOVE_MESSAGE_TO_FAVOURITE_SUCCESS:
      return {
        ...state,
        loading: false,
        offers: action.payload,
        error: {},
      };
    case types.REMOVE_MESSAGE_TO_FAVOURITE_FAILURE:
      return {
        ...state,
        loading: false,
        offers: [],
        error: action.error,
      };
    default:
      return { ...state, loading: false };
  }
};

export default offerReducer;
