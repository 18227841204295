import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Divider,
  Input,
  Rate,
  Badge,
  Radio,
  Space,
  Modal,
  Button,
  DatePicker,
} from "antd";
import { FaRegHospital } from "react-icons/fa";
import {
  fetchChatHistory,
  fetchDeclinedReasonsBids,
  InterviewScheduleAcceptedInit,
  postFavoriteGigCard,
  removeFavoriteGigCard,
  RetractBidUpdateStageInit,
  sendMessage,
  updateDeclinedReasonsReceivedBids,
} from "../../../../redux/actions";
import CalendarIcon from "../../../../assets/icons/CalendarIcon.svg";
import { getImageByStateCode } from "../../../../shared/helpers";
import "./MessageDetail.css";
import { GroupChatIcon } from "../../../../assets/icons/GroupChatIcon.svg";
import Chat from "../../../Chat/Chat";
import ProfileAvatar from "../../../../components/ProfileAvatar";
import { CgAttachment } from "react-icons/cg";
import { sectionBody } from "aws-amplify";
import FavoriteFunction from "../../../Bid/FavoriteFunction/FavoriteFunction";
import { RiCheckDoubleFill } from "react-icons/ri";
import moment from "moment";
import closeIcon from "../../../../assets/icons/ic_sharp-cancel.svg";
import acceptedIcon from "../../../../assets/icons/Group 19971.svg";
import clipBoardIcon from "../../../../assets/icons/Group 19971 (1).svg";
import phoneIcon from "../../../../assets/icons/Group 19971 (2).svg";
import offerIcon from "../../../../assets/icons/Group 19971 (3).svg";
import rocketOutIcon from "../../../../assets/icons/Group 19971 (4).svg";
import rocketInIcon from "../../../../assets/icons/Group 19971 (5).svg";
import TerminatedIcon from "../../../../assets/icons/Group 19971 (6).svg";
import RejectedIcon from "../../../../assets/icons/Group 19971.png";
import MediaQuery from "react-responsive";
import {
  getFileIcon,
  getFileSizeInfo,
} from "../../../../components/fileInformation";
import { BiCopy } from "react-icons/bi";
import { AiOutlineUpload } from "react-icons/ai";
const MessageDetail = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const chats = useSelector((state) => state.getChatHistoryReducer.chats);
  const profile = useSelector((state) => state.profileReducer.user);
  const declineReasons = useSelector(
    (state) => state.BidsReducer.declinedReasonsBid
  );
  const BidDetailData = useSelector(
    (state) => state.BidsReducer.detailReceivedBid
  );
  const dispatch = useDispatch();
  const [placeholder, showPlaceholder] = useState(false);
  const [showmore, setShowmore] = useState(false);
  const [gig, setGig] = useState();
  const [bid, setBid] = useState();
  const [interview, setInterview] = useState();
  const [proposal, setProposal] = useState();
  const [skillListing, setSkillListing] = useState();
  const [interested, setInterested] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [rescheduleFlag, setRescheduleFlag] = useState(false);
  const [decline, setDecline] = useState(false);
  const [declineFlag, setDeclineFlag] = useState(false);
  const [retract, setRetract] = useState(false);
  const [isRetract, setIsRetract] = useState(false);
  const [isProposalAccept, setIsProposalAccept] = useState(false);
  const [offerInterested, setOfferInterested] = useState(false);
  const [clicked, setClicked] = useState();
  const [bidType, setBidType] = useState(null);
  const [conversations, setConversations] = useState();
  const messagesEndRef = useRef(null);
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [textArea, setTextArea] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState(null);
  const inputAttachmentBox = useRef(null);
  const inputTextArea = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectDeclineInput, setSelectDeclineInput] = useState(null);
  const [selectedDeclineValue, setSelectedDeclineValue] = useState(null);
  const [ranking, setRanking] = useState(null);
  const [isPlaceBid, setIsPlaceBid] = useState(null);
  const [interviewCard, setInterviewCard] = useState(null);
  // const [declinedReason, setDeclinedReason] = useState("abc");
  const [seeMoreFlag, setSeeMoreFlag] = useState(false);
  const [declinedReason, setDeclinedReason] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [value, setValue] = useState(1);
  // DatePicker
  const [DateValueStage, setDateValueStage] = useState();
  const [DateValueStage1, setDateValueStage1] = useState();
  const [DateValueStage2, setDateValueStage2] = useState();
  const [Endtime, setEndtime] = useState();
  const [Endtime1, setEndtime1] = useState();
  const [Endtime2, setEndtime2] = useState();
  const [interviewTime, setInterviewTime] = useState(null);
  // console.log("====================", id, ranking);

  const dateFormatList = ["MMMM d, YYYY", "MMMM d, YYYY"];
  const [dateValue, setDateValue] = useState();
  var ctime = new Date(dateValue);
  // console.log("bid", bid, "gig", gig);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    hour12: true,
  };

  // // console.log("message conversations", conversations);
  // onsole.log("message conversations", conversations?.BidStatus);
  // console.log("date picker", DateValueStage, DateValueStage1, DateValueStage2);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const modelTitle = () => {
    if (reschedule) {
      return "Reschedule interview";
    }
    if (decline === "decline-bid") {
      return "Not interested in this bid?";
    }
    if (decline === "decline-interview") {
      return "Decline this interview?";
    }
    if (decline === "decline-offer") {
      return "Decline this offer?";
    }
    if (offerInterested) {
      return "Accept offer";
    }
    if (clicked === "retract-bid") {
      return "Retract your bid?";
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleOkRetract = () => {
    setIsModalVisible(false);
    setInterested(true);
    setIsRetract(true);
    dispatch(RetractBidUpdateStageInit(proposal?._id));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [gig, bid, proposal, interested]);
  useEffect(() => {
    // dispatch(fetchDeclinedReasonsBids("interview"));
    // dispatch(
    //   updateDeclinedReasonsReceivedBids({
    //     id: bid?._id,
    //     body: {
    //       Message: "Update Bid by talent",
    //       BidStatusName: "Not Interested",
    //       DeclineReason: "Price too low",
    //     },
    //   })
    // );
    //////// Interview schedule API
    // dispatch(InterviewScheduleAcceptedInit({
    //   id: BidDetailData?.interview?._id,
    //   body: {
    //     "ReScheduledTimings": [{
    //       "StartTime": "2022-06-23T17:45:00.000+00:00",
    //       "EndTime": "2022-06-23T18:45:00.000+00:00"
    //     },
    //     {
    //       "StartTime": "2022-06-22T17:45:00.000+00:00",
    //       "EndTime": "2022-06-22T18:45:00.000+00:00"
    //     },
    //     ],
    //     "InterviewStatus": "Rescheduled",
    //     "Message": "You have Rescheduledthe interview"
    //   }
    // })
    // )
  }, []);

  // from intput field (file type attachment) - onClick()
  const onChangeFile = ({ target: { files } }) => {
    // console.log("event.target.files", files);
    let flag = false;
    const length = files.length;
    for (let i = 0; i < length; i++) {
      const fileIcon = getFileIcon(getFileFormat(files[i].name));
      if (fileIcon) {
        const fileSize = getFileSizeInfo(files[i]);
        if (fileSize) {
          setAttachmentFile((prevState) => {
            return [...prevState, files[i]];
          });
        }
        if (i === 0 && length === 1 && !fileSize) flag = true;
      }
      if (i === 0 && length === 1 && !fileIcon) flag = true;
    }
    flag ? getDispayBlock() : getDispayNone("fromFileAttachment");
  };

  // from textarea field (height adjustment) - onClick()
  const textAreaAdjust = (element) => {
    // console.log(element.target.value);
    const val = element.target.value;
    if (val !== "") {
      element.target.style.height = -2 + element.target.scrollHeight + "px";
    } else {
      element.target.style.height = "40px";
      if (window.innerWidth < 768) element.target.style.height = "53px";
    }
    setTextArea(element);
    setTextAreaValue(element.target.value);
  };

  // get file format - (from AttachmentDiv)
  const getFileFormat = (fileName) => {
    const behideDot = fileName?.lastIndexOf(".") + 1;
    const fileLength = fileName?.length;
    const fileFormat = fileName?.slice(behideDot, fileLength);
    // console.log("fileFormat", fileFormat);
    return fileFormat;
  };

  const handleOnCLickTextAreaFocus = () => {
    getDispayBlock("fromTextWithAttachment");
  };

  useEffect(() => {
    if (isProposalAccept === true) {
      dispatch(
        InterviewScheduleAcceptedInit({
          id: interview?._id,
          body: {
            ApprovedScheduledTiming: {
              StartTime: interviewTime?.StartTime,
              EndTime: interviewTime?.EndTime,
            },
            InterviewStatus: "Accepted",
            Message: "You have accepted the interview",
          },
        })
      );
    } else if (isProposalAccept === false) {
      // seterrorMessage("Please select one date from above");
      // // console.log(errorMessage, "Error");
    }
    // console.log(interviewTime, "interviewTime");
  }, [isProposalAccept, interviewTime]);

  // remove attachment - (from AttachmentDiv) - onClick()
  const onRemoveAttachment = (fileName, e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    setAttachmentFile(() => {
      return attachmentFile.filter((file) => {
        return file.name !== fileName;
      });
    });
    // console.log("onRemoveAttachment", fileName);
    getDispayNone("onRemoveAttachment");
  };
  const handleReschedule = () => {
    setReschedule(true);
    showModal();
  };
  const handleDecline = async () => {
    // setDecline("decline-bid");
    // showModal();
    // const data = await declineReasons;
    // if (decline === "decline-interview" && declineReasons?.length > 0 && data) {
    //   showModal();
    // }
  };

  useEffect(() => {
    if (isProposalAccept === true) {
      dispatch(
        InterviewScheduleAcceptedInit({
          id: interview?._id,
          body: {
            ApprovedScheduledTiming: {
              StartTime: interviewTime?.StartTime,
              EndTime: interviewTime?.EndTime,
            },
            InterviewStatus: "Accepted",
            Message: "You have accepted the interview",
          },
        })
      );
    } else if (isProposalAccept === false) {
      // seterrorMessage("Please select one date from above");
      // // console.log(errorMessage, "Error");
    }
    // console.log(interviewTime, "interviewTime");
  }, [isProposalAccept, interviewTime]);

  useEffect(() => {
    if (decline === "decline-interview" && declineReasons?.length > 0) {
      showModal();
    }
    if (decline === "decline-bid" && declineReasons?.length > 0) {
      showModal();
    }
  }, [declineReasons]);
  useEffect(() => {
    if (!textArea?.target?.value && attachmentFile.length === 0) {
      getDispayBlock();
    }
  }, [attachmentFile, textArea]);
  useEffect(() => {
    if (props.isReceivedBids) {
      setBidType("Bid");
    } else {
      setBidType("Proposal");
    }
  }, [bid]);
  useEffect(() => {
    // dispatch(fetchChatHistory(id));
    setDecline(false);
    setInterested(false);
  }, [id]);
  useEffect(() => {
    setRetract(false);
    setOfferInterested(false);
    setDecline(false);
    setReschedule(false);
    setDeclinedReason(false);

    setIsRetract(false);
    setDeclineFlag(false);
    setRescheduleFlag(false);
    setInterviewCard(null);
    chats?.data?.body?.gigCard
      ? setGig(chats?.data?.body?.gigCard?.gig)
      : setGig(chats?.data?.body?.placedBidCard?.gig);
    chats?.data?.body?.gigCard
      ? setRanking(chats?.data?.body?.gigCard?.ranking)
      : setRanking(chats?.data?.body?.placedBidCard?.ranking);
    chats?.data?.body?.gigCard ? setIsPlaceBid(false) : setIsPlaceBid(true);
    chats?.data?.body?.interviewCard?.interview &&
      setInterviewCard(chats?.data?.body?.interviewCard?.interview);
    setBid(chats?.data?.body?.gigCard?.bid);
    setProposal(chats?.data?.body?.placedBidCard?.proposal);
    setSkillListing(chats?.data?.body?.gigCard?.skillListing);
    setInterview(chats?.data?.body?.interviewCard?.interview);
    // console.log("interview card", chats?.data?.body?.interviewCard?.interview);
    // console.log("interview card", interview ? interview._id : null);
    // {chats?.data?.body?.conversations.map((data) => // console.log("conversations",data))}
    setConversations(chats?.data?.body?.conversations);
    !interviewTime &&
      setInterviewTime(
        chats?.data?.body?.interviewCard?.interview?.ScheduledTimings[1]
      );
  }, [
    chats?.data?.body?.gigCard?.gig,
    chats?.data?.body?.placedBidCard?.proposal,
    chats?.data?.body?.gigCard?.skillListing,
    chats?.data?.body?.placedBidCard?.interviewCard,
    chats?.data?.body?.conversations,
    interviewCard,
    ranking,
  ]);

  // useEffect(() => {
  //   ranking?.BidStatusName === "Retracted" && setIsRetract(true);
  // }, isRetract);

  // useEffect(() => {
  //   {
  //     conversations?.map((data) => {
  //       data.BidStatus && data.BidStatus === true
  //         ? // console.log("message conversations", data.BidStatus)
  //         : null;
  //     });
  //   }
  // }, [conversations]);
  const handleRetract = () => {
    showModal();
    setRetract(true);
    setClicked("retract-bid");
  };

  const ProposedInterviewHandle = () => {
    setIsProposalAccept(true);
  };

  const handleOffer = () => {
    setInterested(true);
    setOfferInterested(true);
    showModal();
  };

  const onchangeRadio = (e) => {
    setValue(e.target.value);
    // console.log("group change radio", e);
  };
  const onchangeRadio1 = (e, key, time) => {
    if (key === 0) {
      setValue(0);
    } else if (key === 1) {
      setValue(1);
    } else {
      setValue(2);
    }
    // console.log("change radio1", e);
    // console.log("change radio1", e.target?.parentElement, key, time);
    setInterviewTime(time);
  };

  const declineReasonHandler = (e) => {
    setIsSelected(!isSelected);
    // console.log("decline reason", e.target.innerText);
    setDeclinedReason(e.target.innerText);
  };

  const handleReasonSubmit = () => {
    // alert("hi");
    if (isSelected && decline === "decline-interview") {
      dispatch(
        InterviewScheduleAcceptedInit({
          // id: "jasdkflj64657987987",
          id: interview?._id,
          body: {
            // ReScheduledTimings: [
            //   {
            //     StartTime: "2022-06-23T17:45:00.000+00:00",
            //     EndTime: "2022-06-23T18:45:00.000+00:00",
            //   },
            //   {
            //     StartTime: "2022-06-22T17:45:00.000+00:00",
            //     EndTime: "2022-06-22T18:45:00.000+00:00",
            //   },
            // ],
            InterviewStatus: "Rejected",
            Reason: declinedReason,
          },
        })
      );
    } else if (isSelected && decline === "decline-bid") {
      dispatch(
        updateDeclinedReasonsReceivedBids({
          id: bid?._id,
          body: {
            Message: "Update bid by talent",
            BidStatusName: "Declined",
            DeclineReason: declinedReason,
          },
        })
      );
    }
    handleOk();
    setDeclineFlag(true);
  };

  const handleAcceptedReceivedBide = () => {
    dispatch(
      updateDeclinedReasonsReceivedBids({
        id: bid?._id,
        body: {
          Message: "Update Bid by talent",
          BidStatusName: "Accepted",
        },
      })
    );
  };

  const InterViewCard = () => {
    return (
      <>
        <div className="chat-profile-submit" ref={messagesEndRef}>
          <div className="user-threat">
            <MediaQuery maxWidth={768}>
              <div className="Clip_Board_Icon">
                <img src={clipBoardIcon} alt="" />
              </div>
              <div className="Profile_Submitted">Profile submitted</div>
            </MediaQuery>
            {/* <div className="congratulation">Congratulations!</div> */}
            <div className="accept">
              Good news! We submitted your profile to the employer at
            </div>
            <div className="datepicker">
              <div className="date">Thursday, January 3, 2022</div>
              <div className="time">9:00 am</div>
            </div>
            <div className="accept">
              You'll set up an interview next. We'll notify you of proposed
              interview times.
            </div>
            <div className="time-stamp">
              <div className="time-text">1:48 PM</div>
              <div className="tick-icon">
                <RiCheckDoubleFill />
              </div>
            </div>
          </div>
        </div>
        <div className="proposed-buttons">
          <div className="accet-and-decline">
            <div
              className="accept"
              onClick={() => {
                setReschedule(true);
                setDecline(false);
                handleReschedule();
              }}
            >
              Request to reschedule
            </div>
            <div
              className="decline"
              onClick={() => {
                setDecline(true);
                setReschedule(false);
                // handleDecline();
              }}
            >
              Decline
            </div>
          </div>
        </div>
      </>
    );
  };

  const Options = (data) => {
    return (
      <div
        className={
          isSelected ? "bid-decline-option-selected" : "bid-decline-option"
        }
        key={data.Reason}
        onClick={(e) => declineReasonHandler(e)}
      >
        {data.Reason}
      </div>
    );
  };

  const OfferCard = () => {
    return (
      <div className="Card-container" ref={messagesEndRef}>
        <div className="gig-detail-container employer-offer">
          <MediaQuery maxWidth={768}>
            <div className="Accepted_Icon">
              <img src={offerIcon} alt="" />
            </div>
            <div className="Accepted">Offer</div>
          </MediaQuery>
          <div className="congratulation">Congrats!</div>
          <div className="accept">The employer sent you an offer</div>
          <div className="content-container-mobile">
            {/* Job Details */}
            <div className="ractangle-right ractangle-right-employer-offer ractangle_right_mobile">
              <div className="details offer-details">
                <div>Start date</div>
                <div className="values">
                  {moment(`${bid?.IdealStartDate}`).format("MMM DD , YYYY")}
                </div>
              </div>
              <div className="details offer-details">
                <div>End date</div>
                <div className="values">
                  {/* {gig?.JobOrderTypeName ? gig?.JobOrderTypeName : "NA"} */}
                  {gig?.IdealContractLengthName
                    ? gig?.IdealContractLengthName
                    : "NA"}
                </div>
              </div>
              {gig?.ProfessionCode === "HC" ? (
                <div className="details offer-details">
                  <div>Pay package</div>
                  {/* <div>
              {gig?.IdealContractLengthName
                ? gig?.IdealContractLengthName
                : "NA"}
             </div> */}
                  <div className="values">
                    ${gig?.BudgetMin ? gig?.BudgetMin : "NA"}/Wk
                  </div>
                </div>
              ) : null}
              <div className="offer-details">Offer letter</div>
              <div
                className="about-job-text offer-message-text offer_msg_txt_mobile"
                // dangerouslySetInnerHTML={{
                //   __html: bid?.Comments,
                // }}
              >
                {/* {bid?.Comments} */}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries, but also
                the leap into electronic typesetting, remaining.
              </div>
            </div>
          </div>
          <div className="open-document">
            Open the document to digitally sign it then upload your signed offer
            to accept:
          </div>
          <div className="offercard-document">
            <img
              src={getFileIcon(getFileFormat("pdf"))}
              // alt={fileName}
              className="fileIcon"
            />
            <div className="document-details">
              <div className="file-name">Offer.pdf</div>
              <div className="file-size">100kb</div>
            </div>
          </div>
          <div className="time-stamp">
            <div className="time-text-employer">1:48 PM</div>
            {/* <div className="tick-icon">
              <RiCheckDoubleFill />
            </div> */}
          </div>
        </div>
        <div style={{ color: "red", width: "2px" }}>
          <Divider
            className="divider-mobile"
            style={{ backgroundColor: "red", width: "2px" }}
          />
        </div>
        <div className="buttons">
          <div
            className={
              decline === "decline-offer" || interested
                ? "interested-decline"
                : "interested"
            }
            onClick={() => {
              setDecline("decline-offer");
              setReschedule(false);
              showModal();
            }}
          >
            Decline
          </div>
          <div
            className={
              interested || decline === "decline-offer"
                ? "interested-decline"
                : "interested"
            }
            onClick={() => {
              handleOffer();
            }}
            disabled={decline === "decline-offer" || interested}
          >
            {interested ? "Accepted" : "Accept"}
          </div>
        </div>
      </div>
    );
  };

  const AddtoCalanderCard = () => {
    return (
      <>
        <div className="chat-profile-submit" ref={messagesEndRef}>
          <div className="user-threat user-threat-placed-bid">
            <MediaQuery maxWidth={768}>
              <div className="Accepted_Icon">
                <img src={phoneIcon} alt="" />
              </div>
              <div className="Accepted">Interview scheduled</div>
            </MediaQuery>
            {/* <div className="congratulation">Congratulations!</div> */}
            <div className="accept">
              Good news! Your interview is scheduled. Here are your interview
              details.
            </div>
            <div>When</div>
            <div className="datepicker">
              <div className="date">Thursday, January 3, 2022</div>
              <div className="time">9:00 AM - 10:00 AM</div>
            </div>
            <div>Where</div>
            <div className="zoom-link-container">
              <div className="zoom-link">https://teams.microsoft.com.....</div>

              <div className="copy-icon">
                <BiCopy />
              </div>
            </div>
            <div className="add-to-calander">Add to my calendar</div>
            <div className="time-stamp">
              <div className="time-text-employer">1:48 PM</div>
              {/* <div className="tick-icon">
                <RiCheckDoubleFill />
              </div> */}
            </div>
          </div>
        </div>
        <div className="proposed-buttons">
          <div className="accet-and-decline">
            <div
              className="accept"
              onClick={() => {
                setReschedule(true);
                setDecline(false);
                handleReschedule();
              }}
            >
              Request to reschedule
            </div>
            <div
              className="decline"
              onClick={() => {
                showModal();
                setReschedule(false);
                setDecline("decline-interview");
              }}
            >
              Decline
            </div>
          </div>
        </div>
      </>
    );
  };

  const getProposedInterviewDateAndTime = (time) => {
    const timedate = moment(time).format("dddd , MMMM DD , YYYY, h:mm A");
    const tdiff = moment(time.setHours(time.getHours() + 1)).format("LT");
    let TimeArr = timedate.split(", ");
    TimeArr.push(tdiff);
    // console.log(timedate, "timedate");
    // console.log(TimeArr, "timedate");
    // console.log(interviewTime, time, "timedate");
    return TimeArr;
  };

  const ProposedInterview = (props) => {
    // console.log("interview props", props);
    return (
      <>
        <div
          className={
            props.type === "reschedule"
              ? "chat-profile-submit chat-profile-proposed"
              : "chat-profile-submit"
          }
          ref={messagesEndRef}
        >
          <div className="user-threat">
            <div className="accept proposed-interview-font">
              Available interview times
            </div>
            <Radio.Group onChange={(e) => onchangeRadio(e)} value={value}>
              {props.date?.map((date, i) => {
                const time = new Date(date?.StartTime);
                const TimeArr = getProposedInterviewDateAndTime(time);

                return (
                  <Space direction="vertical">
                    <div className="datepicker datepicker-radio-options">
                      <div className="proposal-radio-button">
                        <Radio value={i}></Radio>
                      </div>
                      <div
                        onClick={(e, key, time) =>
                          onchangeRadio1(e, (key = i), (time = date))
                        }
                        value={value}
                      >
                        <div className="date">
                          {TimeArr[0] + ", " + TimeArr[1] + ", " + TimeArr[2]}
                        </div>
                        <div className="time">
                          {TimeArr[3]} - {TimeArr[4]}
                        </div>
                      </div>
                    </div>
                  </Space>
                );
              })}
              {/* <Space direction="vertical">
                <div className="datepicker datepicker-radio-options">
                  <div className="proposal-radio-button">
                    <Radio value={1}></Radio>
                  </div>
                  <div
                    onClick={(e, key) => onchangeRadio1(e, (key = 1))}
                    value={value}
                  >
                    <div className="date">Thursday, 3rd January, 2022</div>
                    <div className="time">9:00 AM - 10:00 AM</div>
                  </div>
                </div>
              </Space>

              <Space direction="vertical">
                <div className="datepicker datepicker-radio-options">
                  <div className="proposal-radio-button">
                    <Radio value={2} checked={true}></Radio>
                  </div>
                  <div
                    onClick={(e, key) => onchangeRadio1(e, (key = 2))}
                    value={value}
                  >
                    <div className="date">Thursday, 3rd January, 2022</div>
                    <div className="time">9:00 AM - 10:00 AM</div>
                  </div>
                </div>
              </Space>

              <Space direction="vertical">
                <div className="datepicker datepicker-radio-options">
                  <div className="proposal-radio-button">
                    <Radio value={3}></Radio>
                  </div>
                  <div
                    onClick={(e, key) => onchangeRadio1(e, (key = 3))}
                    value={value}
                  >
                    <div className="date">Thursday, 3rd January, 2022</div>
                    <div className="time">9:00 AM - 10:00 AM</div>
                  </div>
                </div>
              </Space> */}
            </Radio.Group>

            <div className="time-stamp">
              <div className="time-text-employer">1:48 PM</div>
              {props.type === "reschedule" ? (
                <>
                  <div className="tick-icon">
                    <RiCheckDoubleFill />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={
            props.type === "reschedule"
              ? "proposed-buttons-rescheduled"
              : "null"
          }
          // style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="proposed-buttons">
            {props.type === "reschedule" ? null : (
              <>
                <div className="accet-and-decline">
                  <div
                    className={`decline ${
                      (ranking?.Rank > 6 ||
                        isProposalAccept ||
                        declineFlag ||
                        rescheduleFlag) &&
                      " interested-decline"
                    }`}
                    onClick={() => {
                      // showModal();
                      // setReschedule(false);
                      if (
                        !(
                          ranking?.Rank > 6 ||
                          isProposalAccept ||
                          declineFlag ||
                          rescheduleFlag
                        )
                      ) {
                        dispatch(fetchDeclinedReasonsBids("interview"));
                        setDecline("decline-interview");
                        setReschedule(false);
                        handleDecline();
                      }
                      // declineReasons.then(() => {
                      //   showModal();
                      //   setReschedule(false);
                      // });
                    }}
                  >
                    {ranking?.Rank === 8 || declineFlag
                      ? "Declined"
                      : "Decline"}
                  </div>
                  <div
                    className={` accept  ${
                      (ranking?.Rank > 6 ||
                        isProposalAccept ||
                        declineFlag ||
                        rescheduleFlag) &&
                      " interested-decline"
                    }`}
                    onClick={() => {
                      if (
                        !(
                          ranking?.Rank > 6 ||
                          isProposalAccept ||
                          declineFlag ||
                          rescheduleFlag
                        )
                      ) {
                        ProposedInterviewHandle();
                      }
                    }}
                  >
                    {(ranking?.Rank > 6 && !(ranking?.Rank === 8)) ||
                    isProposalAccept ||
                    rescheduleFlag
                      ? "Accepted"
                      : "Accept"}
                  </div>
                </div>
              </>
            )}
            <div
              className={`reschedule ${
                (ranking?.Rank > 6 ||
                  isProposalAccept ||
                  declineFlag ||
                  rescheduleFlag) &&
                " interested-decline"
              }`}
              onClick={() => {
                if (
                  !(
                    ranking?.Rank > 6 ||
                    isProposalAccept ||
                    declineFlag ||
                    rescheduleFlag
                  )
                ) {
                  setReschedule(true);
                  setDecline(false);
                  handleReschedule();
                }
              }}
            >
              Request to reschedule
            </div>
          </div>
        </div>
      </>
    );
  };

  ////DECLINE /////
  const DeclineBid = () => {
    return (
      <>
        <div className="chat-profile-submit user-sender" ref={messagesEndRef}>
          <div className="user-threat retract-bid-card">
            <MediaQuery maxWidth={768}>
              <div className="Accepted_Icon">
                <img src={RejectedIcon} alt="" />
              </div>
              <div className="Accepted Terminated">Offer declined</div>
            </MediaQuery>
            <div className="congratulation">
              Declined {window.innerWidth > 768 && "!"}
            </div>
            <div className="accept">Your declined the offer</div>
            <div className="datepicker">
              <div className="date">Reason</div>
              <div className="time">Accepted another offer</div>
            </div>
            <div className="time-stamp">
              <div className="time-text">1:48 PM</div>
              <div className="tick-icon">
                <RiCheckDoubleFill />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const AcceptedOffer = () => {
    return (
      <>
        <div className="chat-profile-submit user-sender" ref={messagesEndRef}>
          <div className="user-threat">
            <MediaQuery maxWidth={768}>
              <div className="Accepted_Icon">
                <img src={acceptedIcon} alt="" />
              </div>
              <div className="Accepted">Offer accepted</div>
            </MediaQuery>
            {/* <div className="congratulation">Congratulations!</div> */}
            <div className="accept">
              Congrats on accepting the employer’s offer! Here’s a copy of your
              signed offer document:
            </div>
            <div className="offercard-document offercard-document-accept">
              <img
                src={getFileIcon(getFileFormat("pdf"))}
                // alt={fileName}
                className="fileIcon"
              />
              <div className="document-details">
                <div className="file-name">Offer.pdf</div>
                <div className="file-size">100kb</div>
              </div>
            </div>
            <div className="datepicker offer_accepted_datepicker">
              <div className="date">Thursday, January 3, 2022</div>
              <div className="time">9:00 am</div>
            </div>
            <div className="time-stamp">
              <div className="time-text">1:48 PM</div>
              <div className="tick-icon">
                <RiCheckDoubleFill />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const EmployerMessage = () => {
    return (
      <>
        <div className="chat-profile-submit" ref={messagesEndRef}>
          <div className="user-threat general-message general_message_mobile_receive">
            <div>
              Hi candidate name
              <br />
              <br />
              Please share the resume
              <br />
              <br />
              Thanks
              <br />
              HRM's name
              <br />
              SkillGigs, Inc
            </div>
            <div className="time-stamp">
              <div className="time-text-employer">1:48 PM</div>
              {/* <div className="tick-icon">
                <RiCheckDoubleFill />
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };
  const UserMessage = () => {
    return (
      <>
        <div className="chat-profile-submit user-sender" ref={messagesEndRef}>
          <div className="user-threat general-message general_message_mobile_send">
            <div>
              Hi CHi HRM name,
              <br />
              <br />
              Please find the document
              <br />
            </div>
            <div className="time-stamp">
              <div className="time-text">1:48 PM</div>
              {/* <div className="tick-icon">
                <RiCheckDoubleFill />
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };
  const UserDocument = () => {
    return (
      <>
        <div className="chat-profile-submit user-sender" ref={messagesEndRef}>
          <div className="user-threat general_message_mobile_send">
            {/* <div className="congratulation">Congratulations!</div> */}
            <div className="accept">Resume</div>
            <div className="offercard-document">
              <img
                src={getFileIcon(getFileFormat("pdf"))}
                // alt={fileName}
                className="fileIcon"
              />
              <div className="document-details">
                <div className="file-name">Resume.pdf</div>
                <div className="file-size">100kb</div>
              </div>
            </div>
            <div className="time-stamp">
              <div className="time-text">1:48 PM</div>
              <div className="tick-icon">
                <RiCheckDoubleFill />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SimpleCongratulations = () => {
    return (
      <>
        <div className="chat-container1 employer-sender" ref={messagesEndRef}>
          <div className="sender-threat"></div>
          <div className="user-threat">
            <MediaQuery maxWidth={768}>
              <div className="Accepted_Icon">
                <img src={acceptedIcon} alt="" />
              </div>
              <div className="Accepted"> You're Hired!</div>
            </MediaQuery>
            {/* <div className="congratulation">Congratulations!</div> */}
            <div className="accept">
              You're successfully hired by the employer. Congrats on landing the
              new gig!
            </div>
            <div className="time-stamp">
              <div className="time-text-employer">1:48 PM</div>
              {/* <div className="tick-icon">
                <RiCheckDoubleFill />
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };
  const OnBilling = () => {
    return (
      <div className="Card-container" ref={messagesEndRef}>
        <div className="gig-detail-container employer-offer onbilling_offer">
          <MediaQuery maxWidth={768}>
            <div className="Accepted_Icon">
              <img src={rocketOutIcon} alt="" />
            </div>
            <div className="Accepted">On billing</div>
          </MediaQuery>
          <div className="congratulation">Congratulations!</div>
          <div className="accept">You have moved to the On billing</div>
          <div className="content-container-mobile">
            {/* Job Details */}
            <div className="ractangle-right ractangle-right-employer-offer">
              <div className="details offer-details">
                <div>Start date</div>
                <div className="values">
                  {moment(`${bid?.IdealStartDate}`).format("MMM DD , YYYY")}
                </div>
              </div>
              <div className="details offer-details">
                <div>End date</div>
                <div className="values">
                  {/* {gig?.JobOrderTypeName ? gig?.JobOrderTypeName : "NA"} */}
                  {gig?.IdealContractLengthName
                    ? gig?.IdealContractLengthName
                    : "NA"}
                </div>
              </div>
              {gig?.ProfessionCode === "HC" ? (
                <div className="details offer-details">
                  <div>Pay package</div>
                  {/* <div>
              {gig?.IdealContractLengthName
                ? gig?.IdealContractLengthName
                : "NA"}
             </div> */}
                  <div className="values">
                    ${gig?.BudgetMin ? gig?.BudgetMin : "NA"}/Wk
                  </div>
                </div>
              ) : null}
              <div className="details offer-details">
                <div>Contract duration</div>
                <div className="values">
                  {/* {gig?.JobOrderTypeName ? gig?.JobOrderTypeName : "NA"} */}
                  {/* {gig?.IdealContractLengthName
                    ? gig?.IdealContractLengthName
                    : "NA"} */}
                  13 months
                </div>
              </div>
            </div>
          </div>
          <div className="time-stamp">
            <div className="time-text-employer">1:48 PM</div>
            {/* <div className="tick-icon">
                <RiCheckDoubleFill />
              </div> */}
          </div>
        </div>
      </div>
    );
  };
  const EmployerTerminated = (props) => {
    return (
      <div className="Card-container" ref={messagesEndRef}>
        <div className="gig-detail-container employer-terminated">
          <MediaQuery maxWidth={768}>
            <div className="Accepted_Icon">
              <img
                src={props.type === "rejected" ? RejectedIcon : TerminatedIcon}
                alt=""
              />
            </div>
            <div className="Accepted Terminated">
              {props.type === "rejected" ? "Interview Declined" : "Terminated"}
            </div>
          </MediaQuery>
          <div className="congratulation">
            {props.type === "rejected" ? "Interview Declined" : "Sorry!"}
          </div>
          <div className="accept">
            {props.type === "rejected"
              ? "Employer rejected you"
              : "Your employer moved you to terminated."}
          </div>
          <div className="content-container-mobile">
            {/* Job Details */}
            {props.type !== "rejected" && (
              <div className="ractangle-right ractangle-right-employer-offer">
                <div className="details offer-details">
                  <div>Start date</div>
                  <div className="values">
                    {moment(`${bid?.IdealStartDate}`).format("MMM DD , YYYY")}
                  </div>
                </div>
                <div className="details offer-details">
                  <div>End date</div>
                  <div className="values">
                    {/* {gig?.JobOrderTypeName ? gig?.JobOrderTypeName : "NA"} */}
                    {gig?.IdealContractLengthName
                      ? gig?.IdealContractLengthName
                      : "NA"}
                  </div>
                </div>
                {gig?.ProfessionCode === "HC" ? (
                  <div className="details offer-details">
                    <div>Pay package</div>
                    {/* <div>
              {gig?.IdealContractLengthName
                ? gig?.IdealContractLengthName
                : "NA"}
             </div> */}
                    <div className="values">
                      ${gig?.BudgetMin ? gig?.BudgetMin : "NA"}/Wk
                    </div>
                  </div>
                ) : null}
                <div className="details offer-details">
                  <div>Contract duration</div>
                  <div className="values">
                    {/* {gig?.JobOrderTypeName ? gig?.JobOrderTypeName : "NA"} */}
                    {/* {gig?.IdealContractLengthName
                    ? gig?.IdealContractLengthName
                    : "NA"} */}
                    13 months
                  </div>
                </div>
              </div>
            )}
            {props.type === "rejected" && (
              <div className="chat-profile-submit" ref={messagesEndRef}>
                <div className="user-threat terminated-threat">
                  <div className="datepicker">
                    <div className="date">Reason</div>
                    <div className="time">Desired pay is over budget</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="time-stamp">
            <div className="time-text-employer">1:48 PM</div>
            {/* <div className="tick-icon">
                <RiCheckDoubleFill />
              </div> */}
          </div>
        </div>
        {props.type !== "rejected" && (
          <div className="rate-employer-button">
            <div
              className="rate-employer"
              onClick={() => {
                setReschedule(true);
                setDecline(false);
                handleReschedule();
              }}
            >
              Rate the Employer
            </div>
          </div>
        )}
      </div>
    );
  };

  // attachment component here
  const AttachmentDiv = ({ file: { name: fileName } }) => {
    return (
      <>
        <div className="attachment-container">
          <div className="attachment-content">
            <img
              src={getFileIcon(getFileFormat(fileName))}
              alt={fileName}
              className="fileIcon"
            />
            <div className="attachment-content-info">
              <h3>{fileName}</h3>
              <p>{getFileFormat(fileName)}</p>
            </div>
          </div>
          <img
            src={closeIcon}
            onClick={(e) => onRemoveAttachment(fileName, e)}
            className="attachment-container-close-btn"
          />
        </div>
      </>
    );
  };

  const getMarginInputAttachmentBox = (str) => {
    switch (str) {
      case "marginTop_0px":
        inputAttachmentBox.current.style.marginTop = "0px";
        break;

      case "marginTop-15px":
        inputAttachmentBox.current.style.marginTop = "-15px";
        break;

      default:
        inputAttachmentBox.current.style.margin = "0px";
        break;
    }
  };

  const getDispayNone = (str) => {
    switch (str) {
      case "fromFileAttachment":
        !textArea?.target?.value
          ? getDispayNone()
          : getDispayBlock("fromFileAttachment");
        break;

      case "onRemoveAttachment":
        !textArea?.target?.value
          ? getDispayNone()
          : getDispayBlock("onRemoveAttachment");
        break;

      default:
        inputTextArea.current.style.display = "none";
        getMarginInputAttachmentBox();
        break;
    }
  };

  const getDispayBlock = (str) => {
    switch (str) {
      case "fromFileAttachment":
        getMarginInputAttachmentBox("marginTop-15px");
        getDispayBlock();
        break;

      case "onRemoveAttachment":
        getMarginInputAttachmentBox("marginTop-15px");
        getDispayBlock();
        if (textArea?.target?.value && attachmentFile.length === 1) {
          getMarginInputAttachmentBox(" marginTop_0px");
        }
        break;

      case "fromTextWithAttachment":
        if (attachmentFile.length !== 0) {
          getMarginInputAttachmentBox("marginTop-15px");
          getDispayBlock();
          inputTextArea.current.focus();
        }
        break;

      default:
        inputTextArea.current.style.display = "block";
        break;
    }
  };

  const onBlurTextArea = () => {
    !textArea?.target?.value && attachmentFile.length !== 0
      ? getDispayNone()
      : getDispayBlock();
  };

  const onFocusTextArea = () => {
    if (attachmentFile.length !== 0) {
      getMarginInputAttachmentBox("marginTop-15px");
    }
  };

  const onSubmitHandler = (e) => {
    //  console.log(
    //   { messageContent: textArea?.target?.value },
    //   {
    //     attachmentFile,
    //   }
    // );

    dispatch(
      sendMessage(
        id, // ConversationId
        "name", // name
        "RecipientUserId", // RecipientUserId
        textArea?.target?.value, // MessageText
        "ProfilePic", // ProfilePic
        attachmentFile // Attachments
      )
    );
    e.preventDefault();
    setAttachmentFile([]);
    setTextAreaValue("");
    getMarginInputAttachmentBox("marginTop_0px");

    if (window.innerWidth < 768) inputTextArea.current.style.height = "53px";
    else inputTextArea.current.style.height = "40px";
  };

  const editBidHandler = () => {
    history.push({
      pathname: `/edit-bid/${gig?._id}`,
      state: {
        title: gig?.Title,
        FacilityName: gig?.FacilityName,
        RegionName: gig?.Location?.Location,
        budget: gig?.BudgetMin,
        id: gig?._id,
      },
    });
    // history.push("/edit-bid");
  };
  // console.log("moment", moment(`${bid?.IdealStartDate}`).format("d MMMM YYYY"));
  // console.log("gig", gig?.Created);
  // console.log("gig proposal", proposal?.Created);
  let msec = Number(chats?.data?.body?.gigCard?.gig?.Created);
  let time = new Date(msec);
  let date = time.getDate();
  let hours = time?.getDate();
  let minute = time?.getMinutes();
  const dates = { hours, minute }.toString();
  // console.log("log", time);
  // console.log("recepient name", props.reciepentName);

  // useEffect(() => {
  //   // console.log("log", msec);
  // }, [msec]);

  const addRemoveFavorite = () => {
    if (!bid?.IsFavorite) {
      dispatch(postFavoriteGigCard(bid?._id), bidType);
    } else {
      dispatch(removeFavoriteGigCard(bid?._id));
    }
  };
  // console.log("props", chats, id, props);
  const dateHandler = (e) => {
    var ctime = new Date(e._d);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValue(ctime?.toLocaleDateString("en-us", options));
  };

  const dateHandlerStages = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage(ctime.toISOString());
    setEndtime(date.toISOString());
  };

  const dateHandlerStages1 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage1(ctime.toISOString());
    setEndtime1(date.toISOString());
  };

  const dateHandlerStages2 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage2(ctime.toISOString());
    setEndtime2(date.toISOString());
  };

  const rescheduleInterview = () => {
    // console.log("Submitted", interview?._id);
    dispatch(
      InterviewScheduleAcceptedInit({
        id: interview?._id,
        body: {
          ReScheduledTimings: [
            {
              StartTime: DateValueStage,
              EndTime: Endtime,
            },
            {
              StartTime: DateValueStage1,
              EndTime: Endtime1,
            },
            {
              StartTime: DateValueStage2,
              EndTime: Endtime2,
            },
          ],
          InterviewStatus: "Rescheduled",
          Message: "You have rescheduledthe interview",
        },
      })
    );
    // console.log("Submitted");
    setRescheduleFlag(true);
    handleOk();
  };

  // Message Component Return
  return (
    <div className="Message-Detial" ref={messagesEndRef}>
      {/* /// Bid Start //// */}
      {(ranking?.BidStatusName === "New" ||
        ranking?.BidStatusName === "Retracted" ||
        ranking?.Rank >= 1 ||
        ranking?.Rank >= 2) && (
        <>
          {chats?.data?.body?.gigCard ? (
            ////// Received Bid Start /////
            !(
              ranking?.BidStatusName === "Retracted" || ranking?.Rank === 2
            ) && (
              <div className="Card-container" ref={messagesEndRef}>
                <div className="gig-detail-container">
                  <div className="message-header">
                    <div className="text1-container">
                      <div className="text1">{skillListing?.Title}</div>
                      <FavoriteFunction
                        isFavorite={
                          !isPlaceBid && chats?.data?.body?.gigCard?.IsFavorite
                        }
                        addRemoveFavorite={addRemoveFavorite}
                      />
                    </div>
                    <div className="text2">{gig?.Title}</div>

                    {gig?.ProfessionCode === "HC" ? (
                      <div className="skill-text">{gig?.FacilityName}</div>
                    ) : (
                      <div className="skill-text">{gig?.WorkplacePolicy}</div>
                    )}
                  </div>
                  <div className="content-container-mobile">
                    {/* Job Details */}
                    {gig?.ProfessionCode === "HC" ? (
                      <div className="g-details-facility-container">
                        <div className="rating-review">
                          <div className="g-star-rating">
                            <Rate
                              allowHalf
                              value={gig?.Rating ? Number(gig?.Rating) : 0}
                              disabled={true}
                            />
                          </div>
                          <div
                            style={{
                              color: "#ffba08",
                              margin: "0.3px 0 0 5px",
                              backgroundColor: "#e7f3eb",
                            }}
                          >
                            {gig?.Rating ? Number(gig?.Rating) : 0}
                          </div>
                          <div>
                            <div
                              style={{
                                color: "#7e7e7e",
                                margin: "0 0 0 5px",
                                backgroundColor: "#e7f3eb",
                              }}
                            >
                              ({gig?.Review ? gig?.Review : 0})
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div>
                      <Divider className="divider-mobile" />
                    </div>
                    <div className="ractangle-right">
                      {/* <div className="text1-container"> */}
                      <div className="text1">
                        Pay package ${gig?.BudgetMin}/wk
                      </div>
                      <div className="details">
                        <div>Ideal start date</div>
                        <div>
                          {moment(`${bid?.IdealStartDate}`).format(
                            "MMM DD, YYYY"
                          )}
                        </div>
                      </div>
                      <div className="details">
                        <div>Contract length</div>
                        <div>
                          {gig?.IdealContractLengthName
                            ? gig?.IdealContractLengthName
                            : "NA"}
                        </div>
                      </div>
                      {gig?.ProfessionCode === "HC" ? (
                        <div className="details">
                          <div>Gig Rate</div>
                          <div>
                            ${gig?.BudgetMin ? gig?.BudgetMin : "NA"}/Wk
                          </div>
                        </div>
                      ) : null}
                      <div className="about-job">Cover letter</div>
                      <div className="about-job-text">
                        {bid?.Comments?.length < 120 ? (
                          bid?.Comments
                        ) : (
                          <>
                            {seeMoreFlag
                              ? bid?.Comments
                              : bid?.Comments.slice(0, 120)}
                            <span
                              className="see_more"
                              onClick={() => setSeeMoreFlag(true)}
                            >
                              {" "}
                              {!seeMoreFlag ? "See More..." : ""}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="time-stamp">
                        <div className="time-text">1:48 PM</div>
                        <div className="tick-icon">
                          <RiCheckDoubleFill />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ color: "red", width: "2px" }}>
                  <Divider
                    className="divider-mobile"
                    style={{ backgroundColor: "red", width: "2px" }}
                  />
                </div>
                <div className="buttons">
                  <div
                    className={
                      ranking?.Rank >= 2 || declineFlag || interested
                        ? "interested-decline"
                        : "interested"
                    }
                    onClick={() => {
                      if (!(ranking?.Rank >= 2 || declineFlag || interested)) {
                        setDecline("decline-bid");
                        dispatch(fetchDeclinedReasonsBids("bid"));
                      }
                    }}
                  >
                    {ranking?.Rank === 4 || declineFlag
                      ? "Declined"
                      : "Decline"}
                  </div>
                  <div
                    className={
                      ranking?.Rank >= 2 ||
                      interested ||
                      declineFlag ||
                      (conversations?.BidStatus &&
                        conversations?.BidStatus === "Accepted")
                        ? "interested-decline"
                        : "interested"
                    }
                    onClick={() => {
                      if (
                        !(
                          (ranking?.Rank >= 2 && !(ranking?.Rank === 4)) ||
                          declineFlag ||
                          interested
                        )
                      ) {
                        setInterested(true);
                        handleAcceptedReceivedBide();
                      }
                    }}
                    disabled={decline === "decline-bid" || interested}
                  >
                    {(ranking?.Rank >= 2 && !(ranking?.Rank === 4)) ||
                    interested
                      ? "Accepted"
                      : "Accept"}
                  </div>
                </div>
              </div>
            )
          ) : (
            /// PLACED BID////
            <div className="Card-container chat-right">
              <div
                className={`gig-detail-container ${
                  ranking?.BidStatusName === "Retracted" ||
                  ranking?.Rank === 2 ||
                  (isRetract && ranking?.BidStatusName === "New")
                    ? "interested-decline-bg"
                    : ""
                }`}
              >
                <div className="message-header">
                  <div class="text1-container">
                    <div className="text1">{gig?.Title}</div>
                    <FavoriteFunction
                      isFavorite={
                        isPlaceBid &&
                        chats?.data?.body?.placedBidCard?.IsFavorite
                      }
                      addRemoveFavorite={addRemoveFavorite}
                    />
                  </div>
                  {gig?.ProfessionCode === "HC" ? (
                    <div className="skill-text">{gig?.FacilityName}</div>
                  ) : (
                    <div className="skill-text">{gig?.WorkplacePolicy}</div>
                  )}
                </div>
                <div className="content-container-mobile">
                  {/* Job Details */}
                  {gig?.ProfessionCode === "HC" ? (
                    <div className="g-details-facility-container">
                      <div className="rating-review">
                        <div className="g-star-rating">
                          <Rate
                            allowHalf
                            value={gig?.Rating ? Number(gig?.Rating) : 0}
                            disabled={true}
                          />
                        </div>
                        <div
                          style={{
                            color: "#fadb14",
                            margin: "0.6px 0 0 5px",
                            backgroundColor: "#e7f3eb",
                          }}
                        >
                          {gig?.Rating ? Number(gig?.Rating) : 0}
                        </div>
                        <div>
                          <div
                            style={{
                              color: "#7e7e7e",
                              margin: "0 0 0 5px",
                              backgroundColor: "#e7f3eb",
                            }}
                          >
                            ({gig?.Review ? gig?.Review : 0})
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <Divider className="divider-mobile" />
                  </div>
                  <div
                    className={`ractangle-right ${
                      interested ||
                      ranking?.BidStatusName === "Retracted" ||
                      ranking?.Rank === 2 ||
                      (isRetract && ranking?.BidStatusName === "New")
                        ? "interested-decline-bg"
                        : ""
                    }`}
                  >
                    <div className="text1">
                      Pay Package ${proposal?.ActualRate}/wk
                    </div>
                    <div className="details">
                      <div>Location</div>
                      <div>
                        {gig?.Location?.LocationLong
                          ? gig?.Location?.LocationLong
                          : "NA"}
                      </div>
                    </div>
                    <div className="details">
                      <div>Tax Term</div>
                      <div>
                        {proposal?.IdealTerms ? proposal?.IdealTerms : "NA"}
                      </div>
                    </div>
                    {gig?.ProfessionCode === "HC" ? (
                      <div className="details">
                        <div>Gig Rate</div>
                        <div>${gig?.BudgetMin ? gig?.BudgetMin : "NA"}/Wk</div>
                      </div>
                    ) : null}
                    <div className="about-job">Cover letter</div>
                    <div
                      className="about-job-text"
                      dangerouslySetInnerHTML={{
                        __html: proposal?.Message,
                      }}
                    ></div>
                    <div className="time-stamp">
                      <div className="time-text">1:48 PM</div>
                      <div className="tick-icon">
                        <RiCheckDoubleFill />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ color: "red", width: "2px" }}>
                <Divider
                  className="divider-mobile"
                  style={{ backgroundColor: "red", width: "2px" }}
                />
              </div>
              <div className="buttons">
                <div
                  className={
                    interested ||
                    ranking?.BidStatusName === "Retracted" ||
                    ranking?.Rank >= 2 ||
                    (isRetract && ranking?.BidStatusName === "New")
                      ? "interested-decline"
                      : "interested"
                  }
                  onClick={() => {
                    !(
                      interested ||
                      ranking?.BidStatusName === "Retracted" ||
                      ranking?.Rank >= 2 ||
                      (isRetract && ranking?.BidStatusName === "New")
                    ) && editBidHandler();
                  }}
                >
                  Edit bid
                </div>
                <div
                  className={
                    interested ||
                    ranking?.BidStatusName === "Retracted" ||
                    ranking?.Rank >= 2 ||
                    (isRetract && ranking?.BidStatusName === "New")
                      ? "interested-decline"
                      : "interested"
                  }
                  onClick={() => {
                    !(
                      interested ||
                      ranking?.BidStatusName === "Retracted" ||
                      ranking?.Rank >= 2 ||
                      (isRetract && ranking?.BidStatusName === "New")
                    ) && handleRetract();
                  }}
                >
                  {interested ||
                  ranking?.BidStatusName === "Retracted" ||
                  ranking?.Rank === 2 ||
                  (isRetract && ranking?.BidStatusName === "New")
                    ? "Bid Retracted"
                    : "Retract bidss"}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* /// Bid End //// */}
      {/* Accepted Card Start */}
      {(ranking?.BidStatusName === "Accepted" ||
        (ranking?.Rank >= 3 && !(ranking?.BidStatusName === "Declined"))) && (
        <>
          <div
            className={`chat-container1 mt15px ${
              isPlaceBid && "chat-container-receivedbid"
            }`}
            ref={messagesEndRef}
          >
            <div className="sender-threat"></div>
            <div className="user-threat">
              <MediaQuery maxWidth={768}>
                <div className="Accepted_Icon">
                  <img src={acceptedIcon} alt="" />
                </div>
                <div className="Accepted">Bid Accepted</div>
              </MediaQuery>
              <div className="congratulation">Congrats!</div>
              <div className="accept">
                {isPlaceBid
                  ? "Your bid got accepted by the employer."
                  : "You accepted the employer’s bid. We’ll notify you once we submit your profile to the employer."}
              </div>
              <div className="time-stamp">
                <div className="time-text-employer">1:48 PM</div>
                {/* <div className="tick-icon">
                  <RiCheckDoubleFill />
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
      {/* Accepted Card End */}

      {/* Declined Card Start */}
      {ranking?.BidStatusName === "Declined" && ranking?.Rank === 4 && (
        <>
          <div className="chat-profile-submit mt15px" ref={messagesEndRef}>
            <div className="user-threat retract-bid-card">
              <MediaQuery maxWidth={768}>
                <div className="Accepted_Icon">
                  <img src={RejectedIcon} alt="" />
                </div>
                <div className="Accepted Terminated">
                  {isPlaceBid ? "Offer declined" : "Bid declined"}
                </div>
              </MediaQuery>
              <div className="congratulation">
                Declined {window.innerWidth > 768 && "!"}
              </div>
              <div className="accept">
                {isPlaceBid
                  ? "Your bid has been declined by the candidate"
                  : "Offer has been declined by you"}
              </div>
              <div className="datepicker">
                <div className="date">Reason</div>
                <div className="time">Not enough overall experience</div>
              </div>
              <div className="time-stamp">
                <div className="time-text">1:48 PM</div>
                <div className="tick-icon">
                  <RiCheckDoubleFill />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Declined Card End */}

      {/* Profile Submitted Start */}
      {(ranking?.BidStatusName === "Profile Submitted" ||
        ranking?.Rank >= 5) && (
        <>
          <div className="chat-profile-submit" ref={messagesEndRef}>
            <div className="user-threat">
              <MediaQuery maxWidth={768}>
                <div className="Clip_Board_Icon">
                  <img src={clipBoardIcon} alt="" />
                </div>
                <div className="Profile_Submitted">Profile submitted</div>
              </MediaQuery>
              {/* <div className="congratulation">Congratulations!</div> */}
              <div className="accept">
                Good news! We submitted your profile to the employer at
              </div>
              <div className="datepicker">
                <div className="date">Thursday,January 3, 2022</div>
                <div className="time">9:00 am</div>
              </div>
              <div className="accept">
                You'll set up an interview next. We'll notify you of proposed
                interview times.
              </div>
              <div className="time-stamp">
                <div className="time-text-employer">1:48 PM</div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Profile Submitted End */}

      {/* Interview Start */}
      {(ranking?.BidStatusName === "Interview" || ranking?.Rank >= 6) &&
        interviewCard && (
          <>
            <ProposedInterview
              message={interviewCard?.MessageText}
              date={interviewCard?.ScheduledTimings}
            />
          </>
        )}
      {/* Interview End */}

      {/* Interview Accepted Start */}
      {(ranking?.BidStatusName === "Interview Accepted" ||
        (ranking?.Rank >= 7 &&
          !(ranking?.BidStatusName === "Interview Declined"))) &&
        interviewCard && (
          <>
            <div
              className="chat-profile-submit mt15px user-sender"
              ref={messagesEndRef}
            >
              <div className="user-threat">
                <MediaQuery maxWidth={768}>
                  <div className="Clip_Board_Icon">
                    <img src={phoneIcon} alt="" />
                  </div>
                  <div className="Profile_Submitted">Interview accepted</div>
                </MediaQuery>
                {/* <div className="congratulation">Congratulations!</div> */}
                <div className="accept">
                  Congrats! You’ve accepted an interview time for:
                </div>
                <div className="datepicker">
                  <div className="date">Thursday,January 3, 2022</div>
                  <div className="time">9:00 AM - 10:00 AM</div>
                </div>
                <div className="accept">
                  The employer will share the interview details shortly.
                </div>
                <div className="time-stamp">
                  <div className="time-text">1:48 PM</div>
                  <div className="tick-icon">
                    <RiCheckDoubleFill />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      {/* Interview Accepted End */}

      {/* Interview Declined Card Start */}
      {ranking?.BidStatusName === "Interview Declined" &&
        ranking?.Rank === 8 &&
        interviewCard && (
          <>
            <div
              className="chat-profile-submit mt15px interview-declined-card"
              ref={messagesEndRef}
            >
              <div className="user-threat retract-bid-card">
                <MediaQuery maxWidth={768}>
                  <div className="Accepted_Icon">
                    <img src={RejectedIcon} alt="" />
                  </div>
                  <div className="Accepted Terminated">Interview declined</div>
                </MediaQuery>
                <div className="congratulation">
                  Interview Declined {window.innerWidth > 768 && "!"}
                </div>
                <div className="accept">
                  {window.innerWidth > 768
                    ? "Interview canceled by the candidate"
                    : "You declined the interview"}
                </div>
                <div className="datepicker">
                  <div className="date">Reason</div>
                  <div className="time">{interviewCard.Reason}</div>
                </div>
                <div className="time-stamp">
                  <div className="time-text">1:48 PM</div>
                  <div className="tick-icon">
                    <RiCheckDoubleFill />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      {/* Interview Declined Card End */}

      {/* Offer Card Start */}
      {ranking?.BidStatusName === "Offer" ||
        (ranking?.Rank >= 9 && <OfferCard />)}

      {/* Offer Card End */}
      {/* Offer Accepted Card Start */}
      {ranking?.BidStatusName === "Offer Accepted" ||
        (ranking?.Rank >= 10 && <AcceptedOffer />)}

      {/* Offer Accepted Card End */}
      {/* Offer Declined Card Start */}
      {ranking?.BidStatusName === "Offer Declined" ||
        (ranking?.Rank === 11 && <DeclineBid />)}
      {/* Offer Declined Card End */}
      {ranking?.Rank >= 12 &&
      proposal &&
      chats?.data?.body?.placedBidCard &&
      !isRetract ? (
        <EmployerMessage />
      ) : null}
      {ranking?.Rank >= 12 &&
      proposal &&
      chats?.data?.body?.placedBidCard &&
      !isRetract ? (
        <UserMessage />
      ) : null}
      {ranking?.Rank >= 12 &&
      proposal &&
      chats?.data?.body?.placedBidCard &&
      !isRetract ? (
        <UserDocument />
      ) : null}
      {/* Hired Card Start */}
      {ranking?.BidStatusName === "Hired" ||
        (ranking?.Rank >= 12 && <SimpleCongratulations />)}

      {/* Hired Card End */}
      {/* On Billing Card Start */}
      {ranking?.BidStatusName === "On Billing" ||
        (ranking?.Rank >= 13 && <OnBilling />)}

      {/* On Billing Card End */}
      {/* Off Billing Card Start */}
      {ranking?.BidStatusName === "Off Billing" || ranking?.Rank >= 14}
      {/* Off Billing Card End */}
      {/* Billing Extend Card Start */}
      {ranking?.BidStatusName === "Billing Extend" || ranking?.Rank >= 15}
      {/* Billing Extend Card End */}
      {/* Canceled Card Start */}
      {ranking?.BidStatusName === "Canceled" || ranking?.Rank >= 16}
      {/* Canceled Card End */}
      {/* Terminated Card Start */}
      {ranking?.BidStatusName === "Terminated" || ranking?.Rank >= 17}
      {/* Terminated Card End */}
      {/* Onboarding Message Chat Start */}
      <div className="send-message-container">
        <div className="text-with-attachment ant-input">
          <textarea
            name=""
            id=""
            placeholder="Type your message here..."
            className="teaxArea-content ant-input"
            onChange={(e) => textAreaAdjust(e)}
            ref={inputTextArea}
            onBlur={onBlurTextArea}
            onFocus={onFocusTextArea}
            value={textAreaValue}
          ></textarea>
          <div
            className="attachment-box"
            ref={inputAttachmentBox}
            onClick={handleOnCLickTextAreaFocus}
          >
            {attachmentFile.map((file, i) => (
              <AttachmentDiv key={i} file={file} />
            ))}
          </div>
        </div>
        <div className="message-bottom">
          <div className="conversation-icon">
            <label htmlFor="file">
              <CgAttachment className="conversation-icon" />
            </label>
            <input
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={(e) => onChangeFile(e)}
              multiple
            />
          </div>
          <a className="message-send-btn" onClick={(e) => onSubmitHandler(e)}>
            Send
          </a>
        </div>
      </div>
      {/* Onboarding Message Chat End */}
      {/* Model Start */}
      <Modal
        bodyStyle={{ padding: 0 }}
        width={window.innerWidth < 768 ? "20.625rem" : "22.5rem"}
        title={
          modelTitle()
          // reschedule ? "Reschedule Interview" : "Not Interested in this Bid?"
        }
        className="message-details-model"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        closable={clicked === "retract-bid" ? false : true}
      >
        <div className="Bid-decline-model">
          {offerInterested ? (
            <>
              <div className="bid-decline-option-upload">
                <div className="bid-file-upload-icon">
                  <AiOutlineUpload />
                </div>
                Upload digital signature offer
              </div>
              <div className="bid-decline-button">Accept offer</div>
            </>
          ) : null}
          {decline === "decline-bid" && declineReasons ? (
            <>
              <div className="bid-decline-headline">
                Please select a reason for not interested
              </div>
              {declineReasons?.map((data) => (
                <div
                  className={
                    data.Reason === declinedReason
                      ? "bid-decline-option-selected"
                      : "bid-decline-option"
                  }
                  onClick={(e) => declineReasonHandler(e)}
                >
                  {data.Reason}
                </div>
              ))}
              <div
                className="bid-decline-button"
                onClick={() => handleReasonSubmit()}
              >
                Submit Reason
              </div>
            </>
          ) : null}
          {decline === "decline-interview" && declineReasons ? (
            <>
              <div className="bid-decline-headline decline_interview_content">
                Let us know why you’re declining this bid:
              </div>
              {declineReasons?.map((data) => (
                <div
                  className={
                    data.Reason === declinedReason
                      ? "bid-decline-option-selected"
                      : "bid-decline-option"
                  }
                  onClick={(e) => declineReasonHandler(e)}
                >
                  {data.Reason}
                </div>
                // <Options key={data.Reason} date={data} />
              ))}
              <div
                className="bid-decline-button decline_interview_button"
                onClick={() => handleReasonSubmit()}
              >
                Submit reason
              </div>
            </>
          ) : (
            <></>
          )}
          {decline === "decline-offer" ? (
            <>
              <div className="bid-decline-headline bid_decline_offer">
                Let the employer know the reason why you're declining their
                offer:
              </div>
              {[
                "Accepted another offer",
                "No longer interested in the gig",
                "Estimated pay rate is too low",
                "I’d like to consider another time",
              ].map((data, i) => (
                <div
                  className={`bid-decline-option ${
                    selectDeclineInput === i
                      ? "selected-bid-decline-option"
                      : ""
                  }`}
                  key={i}
                  onClick={(e) => {
                    setSelectedDeclineValue(e.target.innerHTML);
                    setSelectDeclineInput(i);
                  }}
                >
                  {data}
                </div>
              ))}
              {/* <div className="bid-decline-option">Accept another offer</div>
              <div className="bid-decline-option">
                Not Interested in the Gig
              </div>
              <div className="bid-decline-option">
                Estimated pay rate is too low
              </div>
              <div className="bid-decline-option">
                Need to reschedule the joining date
              </div> */}
              <div
                className="bid-decline-button declineThisOffer"
                onClick={(e) => {
                  // console.log("selectedDeclineValue", selectedDeclineValue);
                  // dispatch function here
                  setSelectedDeclineValue(null);
                  setSelectDeclineInput(null);
                  handleCancel();
                }}
              >
                Decline offer
              </div>
            </>
          ) : null}
          {retract ? (
            <>
              <div className="bid-decline-headline retract-bid">
                Do you want to retract your{" "}
                {window.innerWidth > 768 && "Placed"} Bid?
              </div>
              <div className="bid-retract-container">
                <div className="bid-retract-no" onClick={() => handleCancel()}>
                  No, go back
                </div>
                <div
                  className="bid-retract-yes"
                  onClick={() => handleOkRetract()}
                >
                  Yes
                </div>
              </div>
            </>
          ) : null}
          {reschedule ? (
            <>
              <div className="bid-decline-headline reshedule_interview_title">
                Let's find a better time to set up your interview.
              </div>
              <div className="bid-decline-headline bid-decline-headline-rechedule bid-rechedule-text reshedule_interview_content">
                <span style={{ fontWeight: "500" }}>Interview timings</span>
                (Propose up to 3 of your available interview times).
              </div>
              <div className="bid-decline-options">
                <DatePicker
                  placeholder="Choose a date and time"
                  // format="dddd, MMMM d, YYYY HH:mm A"
                  suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                  className="bid-reschedule-date-picker"
                  // style={{
                  //   width: "100%",
                  //   marginBottom: "0.635rem",
                  //   borderRadius: "0.25rem",
                  //   border: "1px solid #e4e4e4",
                  // }}
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }}
                  onOk={(e) => dateHandlerStages(e)}
                />
              </div>
              <div className="bid-decline-options">
                <DatePicker
                  placeholder="Choose a date and time"
                  // format="dddd, MMMM d, YYYY HH:mm A"
                  suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                  className="bid-reschedule-date-picker"
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }}
                  onOk={(e) => dateHandlerStages1(e)}
                />
              </div>
              <div className="bid-decline-options">
                <DatePicker
                  placeholder="Choose a date and time"
                  // format="dddd, MMMM d, YYYY HH:mm A"
                  suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                  className="bid-reschedule-date-picker"
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }}
                  onOk={(e) => dateHandlerStages2(e)}
                />
              </div>
              <div
                className="bid-decline-button bid-rechedule-button"
                onClick={rescheduleInterview}
              >
                Send reschedule request
              </div>
            </>
          ) : null}
        </div>
      </Modal>
      {/* Model End */}
    </div>
  );
};
export default MessageDetail;
