import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import Home from "../pages/Home/Home";
import TalentEmployer from "../pages/WelcomeWizard/TalentEmployer";
import Profession from "../pages/WelcomeWizard/Profession";
import RoleExperience from "../pages/WelcomeWizard/RoleExperience";
import SuccessPage from "../pages/WelcomeWizard/SuccessPage";
import ResumeUpload from "../pages/WelcomeWizard/ResumeUpload";

import Credentialing from "../pages/Credentialing/Credentialing2";
import AuthSuccess from "../pages/Auth/components/AuthSuccess";
import DashBoard from "../pages/DashBoard/DashBoard";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import LinkedInCheck from "../pages/Auth/components/LinkedInCheck";
import SearchResults from "../pages/SearchGigs/SearchResults";
import TermsOfServices from "../pages/Auth/components/TermsOfServices";

import ReceivedBidDetailPage from "../pages/Bid/ReceivedBids/ReceivedBidDetailPage";
import PlacedBidsDetailPage from "../pages/Bid/PlaceBids/PlaceBidsDetailPage";
import Proposals from "../pages/Proposals/Proposals";
import EditBid from "../pages/Bid/components/EditBid";
import BidtoApply from "../pages/Bid/components/BidToApply";
import Messages from "../pages/Messages/Messages";
import Success from "../pages/Bid/components/successBid";
import Chat from "../pages/Chat/Chat";
import ChatHistory from "../pages/Chat/components/ChatHistory";
import ReceivedBids from "../pages/Bid/ReceivedBids/ReceviedBids2";
import ManageSkillListing from "../pages/Manage_SkillListing/ManageSkillListingOne";
import SkillListng from "../pages/SkillListing/SkillListingPage";
import DeclineBids from "../pages/Bid/DeclineBids";
import GuestGidDetailProvider from "../pages/SearchGigs/GuestUser/components/GuestGidDetailProvider";
import TimeSheet from "../pages/TimeSheet/TimeSheet";
import TimeCorrection from "../pages/TimeSheet/TimeCorrection/TimeCorrection";

import Header from "../components/Header/Header";

import history from "../config/history";
import SearchGigs from "../pages/SearchGigs/SearchGigs";
import Profile from "../pages/Profile/Profile";
import Loader from "../components/Loader/Loader";
import JobDetailsPageProvider from "../pages/DashBoard/components/JobDetailsPageProvider";
import DummyContent from "../pages/DashBoard/components/DummyContent";
import MyFavorites from "../pages/MyFavorites/MyFavorites";
import RetractedBid from "../pages/Bid/components/RetractedBid";
import { retractBid } from "../redux/actions";
import PlaceBids from "../pages/Bid/PlaceBids/PlaceBids";
import Interview from "../pages/Interview/Interview";
import _3D_Resume from "../pages/_3D_Resume/_3D_Resume";
import HomeSearchResults from "../pages/Home/HomeSearchResults";
import HomeSeeAllpage from "../pages/Home/HomeSeeAllpage";
import {
  LoginRoute,
  ProtectedRoute,
  PublicRoute,
  SpecialRoute,
  ValidateRoute,
} from "./routes";
import MessageDetail from "../pages/Messages/components/MessageDetailBid/MessageDetail";
import Footer from "../components/Footer";
import RoleExperienceNew from "../pages/WelcomeWizard/RoleExperienceNew";
import { RatingForm } from "../pages/Profile/components/RatingReviews/RatingForm";
import RatingformTwo from "../pages/Profile/components/RatingReviews/RatingformTwo";
import AllCard from "../pages/Profile/components/RatingReviews/Ratingcards/AllCard";
import ManageAssignment from "../pages/Manage_Assignments/ManageAssignment";
import Conversation from "../pages/Conversations/Conversations";
import ScrollToTop from "../components/ScrollToTop";
import NotificationPage from "../pages/NotificationScreen";
import EmailVerification from "../components/EmailVerification/EmailVerification";

import ViewListing from "../pages/ViewListing/ViewListing";
import SkillListingDetailPage from "../pages/Manage_SkillListing/SkillListingDetailPage/SkillListingDetailPage";
import CompanyProfile from "../pages/CompanyProfile/CompanyProfile";
import Error from "../pages/Error/Error";
// import Dashboard from "../pages/NewDashboard/Dashboard";
import Dashboard from "../pages/DashBoard/DashBoard";
import ReadRules from "../pages/WelcomeWizard/ReadRules";
import SuccessProfileInfo from "../pages/WelcomeWizard/SuccessProfileInfo";
import EffectiveProfileInfo from "../pages/WelcomeWizard/EffectiveProfileInfo";

export const AppRouter = (props) => {
  const emailVerified = props.isEmailVerified;
  const { id } = props.userProfile;
  const { WizardFlow } = props.userProfile.profile;
  const { location, width } = props
  console.log("IDIDID:", width);

  // // console.log("IDIDID:", WizardFlow);

  return (
    // <Router  basename={'/app'}>
    <>
      <ScrollToTop />
      {/* <Suspense fallback={<Loader />}> */}
      <div className="content-wrap">
        <div
          className="headerSpaceRouter"
          // className={` ${
          //   !id || WizardFlow <= 5 || emailVerified
          //     ? "headerSpaceRouter"
          //     : "headerSpaceRouter-the-drop"
          // }`}
        >
          {/* {!emailVerified && id && WizardFlow > 5 && <EmailVerification />} */}
          <Header
            changeStage={props.shareStage}
            stage={props.stage}
            id={id}
            emailVerified={emailVerified}
            WizardFlow={WizardFlow}
            EmailVerification={EmailVerification}
          />
          <Switch>
            {/* <PublicRoute exact path="/">
            <Home />
          </PublicRoute> */}
            <ValidateRoute exact path="/">
              <Home />
            </ValidateRoute>
            <PublicRoute exact path="/auth">
              <AuthSuccess />
            </PublicRoute>
            <LoginRoute path="/login">
              <Auth />
            </LoginRoute>
            <LoginRoute path="/signup">
              <Auth />
            </LoginRoute>
            <PublicRoute exact path="/linkcheck">
              <LinkedInCheck />
            </PublicRoute>
            <PublicRoute exact path="/search">
              <SearchGigs />
            </PublicRoute>
            <PublicRoute exact path="/search/:searchValue">
              <SearchGigs />
            </PublicRoute>
            <PublicRoute exact path="/details/:jobid">
              <GuestGidDetailProvider />
            </PublicRoute>
            <PublicRoute path="/create-bid">
              <BidtoApply />
            </PublicRoute>
            <PublicRoute path="/error">
              <Error />
            </PublicRoute>
            <ProtectedRoute path="/receivedBids">
              <ReceivedBids
                emailVerified={emailVerified}
                id={id}
                WizardFlow={WizardFlow}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/receivedbid-detail/:bidId"
              component={ReceivedBidDetailPage}
            />
            <ProtectedRoute path="/placebids">
              <PlaceBids
                emailVerified={emailVerified}
                id={id}
                WizardFlow={WizardFlow}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/placedbid-detail/:bidId"
              component={PlacedBidsDetailPage}
            />
            <ProtectedRoute path="/talentemployer" component={TalentEmployer} />
            <ProtectedRoute path="/profession" component={Profession} />
            <ProtectedRoute path="/read-rules" component={ReadRules} />
            <ProtectedRoute path="/profile-instruction" component={SuccessProfileInfo} />
            <ProtectedRoute path="/effective-profile-info" component={EffectiveProfileInfo} />
            <ProtectedRoute
              path="/basic-profile-success"
              component={SuccessPage}
            />
            <ProtectedRoute
              path="/create-skilllisting"
              component={SkillListng}
            />
            <ProtectedRoute
              path="/create-skilllisting-signup"
              component={SkillListng}
            />
            <ProtectedRoute
              exact
              path="/role-experience"
              component={RoleExperienceNew}
            />
            <ProtectedRoute path="/credentialing" component={Credentialing} />
            <ProtectedRoute path="/manageskilllisting">
              <ManageSkillListing />
            </ProtectedRoute>
            <ProtectedRoute path="/skill-listing-detail-page/:skillId">
              <SkillListingDetailPage />
            </ProtectedRoute>
            <ProtectedRoute path="/view-listing/:id">
              <ViewListing />
            </ProtectedRoute>
            <ProtectedRoute path="/view-listing">
              <ViewListing />
            </ProtectedRoute>
            <ProtectedRoute path="/company-profile/:id">
              <CompanyProfile />
            </ProtectedRoute>
            <ProtectedRoute path="/dummy">
              <DummyContent />
            </ProtectedRoute>
            <ProtectedRoute path="/myfavorites">
              <MyFavorites />
            </ProtectedRoute>
            <ProtectedRoute path="/dashboard">
              {/* <DashBoard
                shareStage={props.shareStage}
                emailVerified={emailVerified}
              /> */}
              <Dashboard/>
            </ProtectedRoute>
            <ProtectedRoute path={"/proposals"}>
              <Proposals />
            </ProtectedRoute>
            <ProtectedRoute path="/bidsuccess">
              <Success />
            </ProtectedRoute>
            <ProtectedRoute path="/edit-bid/:id">
              <EditBid />
            </ProtectedRoute>
            <ProtectedRoute exact path="/messages/:id?">
              <Conversation />
            </ProtectedRoute>
            <ProtectedRoute exact path="/chats">
              <Conversation />
            </ProtectedRoute>
            <ProtectedRoute exact path="/notifications">
              <NotificationPage />
            </ProtectedRoute>
            
            { // commenting this out as it is a dublicate route for another component if it is not useable please remove.
              /* <ProtectedRoute path="/messages/:id">
              {window.innerWidth < 768 ? <MessageDetail /> : <Messages />}
            </ProtectedRoute> */
            }
            <ProtectedRoute path="/chat/history">
              <ChatHistory />
            </ProtectedRoute>
            <ProtectedRoute path="/DeclineBids">
              <DeclineBids />
            </ProtectedRoute>
            <ProtectedRoute exact path="/timesheet">
              <TimeSheet />
            </ProtectedRoute>
            <ProtectedRoute exact path="/profile">
              <Profile />
            </ProtectedRoute>
            <ProtectedRoute exact path="/requestcorrection">
              <TimeCorrection />
            </ProtectedRoute>
            <ProtectedRoute exact path="/resume-upload">
              <ResumeUpload />
            </ProtectedRoute>
            <ProtectedRoute path="/interview">
              <Interview />
            </ProtectedRoute>
            <ProtectedRoute path="/3d_resume">
              <_3D_Resume />
            </ProtectedRoute>
            <ProtectedRoute path="/RetractedBids">
              <RetractedBid />
            </ProtectedRoute>
            <ProtectedRoute path="/HomeResult">
              <HomeSearchResults />
            </ProtectedRoute>
            <ProtectedRoute path="/homeSeeAll">
              <HomeSeeAllpage />
            </ProtectedRoute>
            <ProtectedRoute path="/manage_assignments">
              <ManageAssignment />
            </ProtectedRoute>
            <ProtectedRoute path="/rating_form">
              <RatingForm />
            </ProtectedRoute>
            <ProtectedRoute path="/rating_form2">
              <RatingformTwo />
            </ProtectedRoute>
            <ProtectedRoute path="/all_rate_card">
              <AllCard />
            </ProtectedRoute>
            <SpecialRoute path="/forgot">
              <ForgetPassword />
            </SpecialRoute>
            <SpecialRoute path="/terms">
              <TermsOfServices />
            </SpecialRoute>
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
      {location.includes("messages") && width <= 768 ? null : <Footer />}
      {/* && width <= 768 */}
    </>
  );
};
