import { types } from "../action-types";

const initialState = {
    loading: false,
    success: [],
    error: false,
    ratingSuccess: false,
    request: false,
};

const ratingReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_RATING_INIT:
            return {
                ...state,
                ratingSuccess: false,
                loading: true,
            };

        case types.CREATE_RATING_SUCCESS:
            return {
                ...state,
                loading: false,
                ratingSuccess: true,
                success:action.payload,
                error: false,
                request:true,
            };
        case types.CREATE_RATING_FAILURE:
            return {
                ...state,
                loading: false,
                success:[],
                error: true,
                request:false,
            };

            case types.UPDATE_RATING_INIT:
                return {
                  ...state,
                  loading: true,
                  ratingSuccess: false,
                };
          
              case types.UPDATE_RATING_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  ratingSuccess: true,
                //   detailReceivedBid: {
                //     ...state.detailReceivedBid,
                //     BidStatusName: action.payload.BidStatusName,
                //     DeclineReason: action.payload.DeclineReason,
                //     ranking: {
                //       ...state.ranking,
                //       BidStatusName: action.payload.BidStatusName,
                //       BidStatusId: action.payload.BidStatusId,
                //     },
                //   },
                };
          
              case types.UPDATE_RATING_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error,
                };

                case types.DELETE_RATING_INIT:
                    return {
                      ...state,
                      loading: true,
                    };
              
                  case types.DELETE_RATING_SUCCESS:
                    // console.log("reducerparams", action);
                    // const docRemoveItem = state.user.userDocs.find(
                    //   (item) => item._id == action.params
                    // );
                    // docRemoveItem["userDoc"] = {};
                    // return {
                    //   ...state,
                    //   loading: false,
                    //   user: { ...state.user },
                    //   error: {},
                    // };
              
                  case types.DELETE_RATING_FAILURE:
                    return {
                      ...state,
                      loading: false,
                    };
              
        default:
            return state;
    }
};
export default ratingReducer;
