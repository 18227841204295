import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";

import { Auth } from "../../../config/aws-exports";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchUserSkillListing,
  updateImpersonationIdId,
  updateProfile,
  updateUserInfo,
} from "../../../redux/actions";
import { fetchUserProfile } from "../../../redux/actions";
import { createBid } from "../../../redux/actions";
import {
  getCookie,
  getUserDeviceDetails,
  setCookie,
} from "../../../shared/helpers";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { addUpdateUserDeviceDeatils } from "../../../API/API";

const AuthSuccess = (props) => {
  const history = useHistory();
  const location = useLocation().search;

  const dispatch = useDispatch();

  const userId = new URLSearchParams(location).get("userId");
  const adminToken = new URLSearchParams(location).get("token");

  // console.log("paramsUrl:", userId, adminToken);

  let myInterval;

  useEffect(() => {
    async function AuthCheck() {
      let session = null;

      // debugger;
      try {
        if (userId && adminToken) {
          // superadmin impersonating user
          // console.log("User Impersonation", userId, adminToken);
          setCookie("talentToken", adminToken, true);
          setCookie("impersonationid", userId);
          props.updateImpersonationId(userId);
          props.sendAuthToken(adminToken);
          props.profileDetails({
            talentToken: adminToken,
            impersonationId: userId,
          });
        } else if (
          getCookie("talentToken") !== null &&
          getCookie("talentToken").length > 0
        ) {
          const talentToken = getCookie("talentToken");

          // console.log("talentToken:cookie", talentToken);

          // setCookie("talentToken", talentToken, true);
          // props.sendAuthToken(talentToken);
          props.profileDetails(talentToken);

          /////////////////////////////////////////////////////////////////////////////////////////////

          // const cognitoUser = await Auth.currentAuthenticatedUser();
          // const currentSession = await Auth.currentSession();
          // cognitoUser.refreshSession(
          //   currentSession.refreshToken,
          //   (err, session) => {
          //     // console.log("session", err, session);
          //     const talentToken = session.idToken.jwtToken;
          //     // do whatever you want to do now :)
          //     // console.log("authToken", talentToken);
          //     setCookie("talentToken", talentToken, true);
          //     props.sendAuthToken(talentToken);
          //     props.profileDetails(talentToken);
          //   }
          // );

          ////////////////////////////////////////////////////////////////////////////////////////////

          // const talentToken = await Auth.currentSession().getRefreshToken().getToken();
          // // console.log("TOKEN IN AUTH12:", talentToken);
          // props.profileDetails(talentToken);
          // setCookie("talentToken", talentToken, true);
          // const talentToken = getCookie("talentToken");
          // // console.log("TOKEN IN AUTH:", talentToken);
          // props.profileDetails(talentToken);
          // props.sendAuthToken(talentToken);  linked talentToken already save at this point in reducer
        } else if ((session = await Auth.currentSession())) {
          // const session = await Auth.currentSession();
          // console.log("SESSION:", session);
          const talentToken = session.getIdToken().getJwtToken();
          setCookie("talentToken", talentToken, true);
          // console.log("TOKEN IN AUTH:", talentToken);
          props.sendAuthToken(talentToken);
          myInterval = setInterval(props.profileDetails(talentToken), 1000);
          // props.profileDetails(talentToken);
        } else {
          // console.log("talentToken not found");
          window.location.replace("/login");
        }
      } catch (err) {
        // console.log("ERROR IN VERIFYING SESSION:", err);
        window.location.replace("/login");
      }
    }
    AuthCheck();
  }, []);

  useEffect(() => {
    // console.log("user:impersonationId", props.impersonationId);
  }, [props.impersonationId]);

  {console.log('auth token in auth success', props)}
  useEffect(() => {
    //used for redirecting guest user flow

    const profile = props.user.profile;
    // console.log("hELLo: ", props.user);
    // setState(data);

    {console.log('auth token in auth success', props, profile)}
    if (props.user.email) {
      if (!adminToken) {
        dispatch(
          updateProfile({
            LastLoginDate: new Date(),
            LastSuccessfulLoginDate: new Date(),
          })
        );
        addUpdateUserDeviceDeatils(getUserDeviceDetails());
      }

      clearInterval(myInterval);
      if (profile.WizardFlow === 3) {
        history.push("/create-skilllisting-signup");
      } else if (profile.WizardFlow === 1) {
        history.push("/profession");
      } else if (profile.WizardFlow === 2) {
        history.push("/role-experience");
      } else if (profile.WizardFlow === 0) {
        {console.log('auth token in auth success wizard flow', props, profile.WizardFlow)}
        history.push("/talentemployer");
      } else if (profile.WizardFlow === 4) {
        history.push("/resume-upload");
      } else if (profile.WizardFlow === 5) {
        history.push("/basic-profile-success");
      }
      //else if redirectTo exists in local storage redirectTo
      // else if (localStorage.getItem("redirectTo")) {
      //   history.push(localStorage.getItem("redirectTo"));
      // } else history.push("/");
      else if (localStorage.getItem("JobDetailpageUrl")) {
        history.push(localStorage.getItem("JobDetailpageUrl"));
      } else if (localStorage.getItem("redirectUrl")) {
        history.push(localStorage.getItem("redirectUrl"));
        localStorage.removeItem("redirectUrl");
      } else history.push("/dashboard");
    }
  }, [props.user.profile]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: "2.5rem",
        margin: "40vh 0",
        color: "#118936",
      }}
    >
      <LoadingOutlined />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.profileReducer.user,
    error: state.authReducer.error,
    talentToken: state.authReducer.user.authToken,
    impersonationId: state.authReducer.impersonationId,
    // userProfile: state.profileReducer.user.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
    },
    profileDetails: (params) => {
      dispatch(fetchUserProfile(params)); // action dispatched
    },
    updateImpersonationId: (talentToken) => {
      dispatch(updateImpersonationIdId(talentToken)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthSuccess);
