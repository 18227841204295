import { call, put, takeEvery, select } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchSearchCountryAPI } from "../../API/API";
import { fetchCountryFailure } from "../actions";

function* searchCountrySaga(params) {
  // console.log("LOCATION in SAGA:", params);
  try {
    const response = yield call(fetchSearchCountryAPI, params); // API Backend
    // console.log(response);
    yield put({
      type: types.FETCH_SEARCH_COUNTRY_SUCCESS,
      payload: response.data,
    });
    // if (response.data.status === "success") {
    //   const data = {
    //     key: response.data.payload,
    //     CredentialDocumentId: params.params.documentId,
    //   };
    //   yield put(uploadDocSuccess(data));
    // } else {
    //   yield put(uploadDocFailure(response.errorMessage));
    // }
  } catch (error) {
    // console.log(error);
    yield put(fetchCountryFailure(error));
  }
}

export function* watchSearchCountrySaga() {
  yield takeEvery(types.FETCH_SEARCH_COUNTRY_INIT, searchCountrySaga);
}
