import React from "react";
import "./ReadRules.css";
import { useHistory } from "react-router";
import image from ".././../assets/img/image 431.png";
import imageIT from "../../assets/img/image 422.png";
import iconOne from "../../assets/img/icon-1.png";
import icontwo from "../../assets/icons/noun-skill-5643793 1.svg";
import icontwoIT from "../../assets/icons/Group (3).svg";
import iconThired from "../../assets/icons/search.svg";
import iconFour from "../../assets/img/icon-3.png";
import iconFive from "../../assets/icons/help-circle.svg";
import { useSelector } from "react-redux";
import NursePicOne from "../../assets/img/Healthcare/NursePicOne.svg";
import NursePicOneMob from "../../assets/img/Healthcare/NursePicOneMob.svg";
import ItPicOne from "../../assets/img/Healthcare/ItPicOne.svg";
import ItPicOneMob from "../../assets/img/Healthcare/ItPicOneMob.svg";
export const ReadRules = () => {
  const history = useHistory();
  const user = useSelector((state) => state.profileReducer.user);

  return (
    <React.Fragment>
      <div className="main-div-read-rules">
        <div className="read-rules-container">
          <div className="read-rules-main">
            <div className="read-rules-left-section">
              <div className="read-rules-left-section-heading">
                How to use SkillGigs?
              </div>

              {window.innerWidth < 1000 &&
                (user?.profile?.ProfessionCode == "HC" ? (
                  <img
                    className="mobile-view-img"
                    style={{ borderRadius: "8px" }}
                    src={NursePicOneMob}
                    alt=""
                  />
                ) : (
                  <img
                    className="mobile-view-img"
                    style={{ borderRadius: "8px" }}
                    src={ItPicOneMob}
                    alt=""
                  />
                ))}

              <div className="read-rules-left-section-div">
                <div className="read-rules-icons-section">
                  <img className="read-rules-icons" src={iconOne} alt="" />
                </div>
                <div className="read-rules-left-section-text">
                  {user?.profile?.ProfessionCode == "HC"
                    ? " Set up your profile, make sure your resume and all your credentials are uploaded so that you get high visibility among the top employers."
                    : "Set up your profile, make sure to upload a resume and add your education and work history so that you get high visibility among the top employers."}
                </div>
              </div>
              <div className="read-rules-left-section-div">
                <div className="read-rules-icons-section">
                  {user?.profile?.ProfessionCode == "HC" ? (
                    <img className="read-rules-icons" src={icontwo} alt="" />
                  ) : (
                    <img className="read-rules-icons" src={icontwoIT} alt="" />
                  )}
                </div>
                <div className="read-rules-left-section-text">
                  {user?.profile?.ProfessionCode == "HC"
                    ? "Create a skill listing by choosing the gig type - perm, per diem, or contract/travel. The skill listing you create opens up the opportunities to bid on gigs with similar skill match."
                    : "Create a skill listing by choosing the gig type - perm and contract. The skill listing you create opens up the opportunities to bid on gigs with similar skill match."}
                </div>
              </div>
              <div className="read-rules-left-section-div">
                <div className="read-rules-icons-section">
                  <img className="read-rules-icons" src={iconThired} alt="" />
                </div>

                <div className="read-rules-left-section-text">
                  Search for gigs based on your expertise and place bids on
                  them. Multiple bids means multiple chances of securing the
                  kind of job you want.
                </div>
              </div>
              <div className="read-rules-left-section-div">
                <div className="read-rules-icons-section">
                  <img className="read-rules-icons" src={iconFour} alt="" />
                </div>
                <div className="read-rules-left-section-text">
                  {user?.profile?.ProfessionCode == "HC"
                    ? "You will also receive bids from employers as well as various marketplace representatives (ie. relationship managers and market managers). These are hot gigs, respond in a timely manner to get the best results and land the job."
                    : "You will also receive bids from employers as well as various marketplace representatives (ie. customer success managers). These are hot gigs, respond in a timely manner to get the best results and land the job."}
                </div>
              </div>
              <div className="read-rules-left-section-div">
                <div className="read-rules-icons-section">
                  <img className="read-rules-icons" src={iconFive} alt="" />
                </div>
                <div className="read-rules-left-section-text">
                  You will have support at every step with SkillGigs from
                  automatic reach outs, email reminders, career advice, and
                  platform assistance. When in doubt, submit a help{" "}
                  <span
                    className="underlineWithGapReadRule"
                    style={{ color: "#4054B2", cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://skillgigs.com/contact-us/")
                    }
                  >
                    form
                  </span>{" "}
                  or{" "}
                  <a
                    href="mailto:info@skillgigs.com"
                    className="underlineWithGapReadRule"
                    style={{ color: "#4054B2", cursor: "pointer" }}
                  >
                    email
                  </a>
                </div>
              </div>
              <div className="read-rules-btn-section">
                <div className="btns-section">
                  <div
                    onClick={() => history.push("/profile-instruction")}
                    className="btn-senctin-continue"
                  >
                    <span className="continue-btn-read-rules">Continue</span>
                  </div>
                  <div
                    onClick={() => history.push("/profession")}
                    className="back-btn"
                  >
                    Back
                  </div>
                </div>
              </div>
            </div>
            <div className="read-rules-img">
              {user?.profile?.ProfessionCode == "HC" ? (
                <img
                  className="mobile-view-img"
                  style={{ borderRadius: "8px" }}
                  src={NursePicOne}
                  alt=""
                />
              ) : (
                <img
                  className="mobile-view-img"
                  style={{ borderRadius: "8px" }}
                  src={ItPicOne}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReadRules;
