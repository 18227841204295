import React from "react";
import './style.css'

export const HyperlinkParagraph = ({ text }) => {

  const paragraph = text;

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;

  // Function to replace matched URLs with anchor tags
  const replaceURLsWithLinks = (text) =>
    text?.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);

  // Function to replace matched emails with mailto linksS
  const replaceEmailsWithLinks = (text) =>
    text?.replace(emailRegex, (email) => `<a href="mailto:${email}">${email}</a>`);

  // Function to format the paragraph with links and emails
  const formatParagraph = (text) => {
    // Add line breaks before opening tags to ensure proper formatting
    const formattedText = text?.replace(/</g, '\n<');
    const paragraphWithLinks = replaceURLsWithLinks(formattedText);
    const paragraphWithLinksAndEmails = replaceEmailsWithLinks(paragraphWithLinks);

    const paragraphWithoutLineBreaks = paragraphWithLinksAndEmails?.replace(/\n/g, ' ');

    return <div className="formatted-main-div" dangerouslySetInnerHTML={{ __html: paragraphWithoutLineBreaks }} />;
  };

  const formattedParagraph = formatParagraph(paragraph);

  return <div style={{wordBreak:'break-word'}}>{formattedParagraph}</div>;

};
