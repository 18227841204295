import { types } from "../action-types";

const initialState = {
    loading: false,
    offers: [],
    error: {},
};

const offerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_OFFER_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.FETCH_OFFER_SUCCESS:
            return {
                ...state,
                loading: false,
                offers: action.payload,
                error: {},
            };
        case types.FETCH_OFFER_FAILURE:
            return {
                ...state,
                loading: false,
                offers: [],
                error: action.error,
            };
        default:
            return {...state,loading:false};
    }
};

export default offerReducer;
