import React from "react";
import illustrationIcon from "../../../assets/icons/Create.svg";
import "./NoInterview.css"

const NoInterview = () => {
  // console.log("No Interview");
  return (
    <>
      <div className="no-interview-container">
        <img src={illustrationIcon} className="illustration-icon" />
        <span class="no-interviews">No interviews</span>
      </div>
    </>
  );
};

export default NoInterview;
