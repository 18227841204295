import React, { useState, useEffect } from "react";
import "./SocialAccounts.css";

import { Modal } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// Redux Actions
import { updateProfile } from "../../../../redux/actions";

// Icons
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineGoogle } from "react-icons/ai";
import { AiFillApple } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { TiSocialLinkedin } from "react-icons/ti";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import Loader from "../../../../components/Loader/Loader";

import AddEditBox from "../../../../components/AddEditBox/AddEditBox";

const SocialAccounts = () => {
  const dispatch = useDispatch();
  const path = useLocation().pathname;

  const EditIcon = <AiOutlineEdit className="iconColorED" />;
  const DeleteIcon = <RiDeleteBin5Fill className="iconColorED" />;

  const user = useSelector((state) => state.profileReducer.user);
  const [isSocialLinkValid, setIsSocialLinkValid] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [socialType, setSocialType] = useState(null);
  const [socialLink, setSocialLink] = useState("");
  const [loadingFacebook, setloadingFacebook] = useState(false);
  const [loadingGoogle, setloadingGoogle] = useState(false);
  const [loadingApple, setloadingApple] = useState(false);
  const [loadingLinkedIn, setloadingLinkedIn] = useState(false);

  const [facebookLink, setFacebookLink] = useState("");
  const [linkedInLink, setLinkedInLink] = useState("");
  const [addStateFacebook, setAddStateFacebook] = useState(false);
  const [addStateLinkedIn, setAddStateLinkedIn] = useState(false);
  const [addUpdateStateFacebook, setAddUpdateStateFacebook] = useState(false);
  const [addUpdateStateLinkedIn, setAddUpdateStateLinkedIn] = useState(false);
  const [facebookEditBox, setFacebookEditBox] = useState(false);
  const [linkedInEditBox, setLinkedInEditBox] = useState(false);

  const [dimensions, setDimensions] = useState(window.innerWidth);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  // console.log("addStateFacebook", addStateFacebook);

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  const showModal = (social) => {
    setSocialType(social);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setSocialLink("");
    setSocialType("");
    setIsModalVisible(false);
  };

  const socialLinkInput = (link) => {
    setSocialLink(link);
  };

  const loadingCondition = (social, condition) => {
    if (social === "Facebook") setloadingFacebook(condition);
    if (social === "LinkedIn") setloadingLinkedIn(condition);
  };

  const updateSocialLinks = () => {
    setIsModalVisible(false);
    if (socialLink === user.profile.Facebook) return;
    // console.log("HIT3");

    if (socialLink === user.profile.LinkedIn) return;
    // console.log("HIT4");

    dispatch(
      updateProfile({
        userId: user.id,
        Profiles: {
          [socialType]:
            socialLink === "" ? null : socialLinkPathname(socialLink),
        },
      })
    );
    setSocialLink("");
    setSocialType("");

    loadingCondition(socialType, true);
  };

  function socialLinkPathname(props) {
    const url = new URL(props);
    return url?.pathname?.substring(1);
  }

  const updateSocialLinksOnProfile = (link, type) => {
    dispatch(
      updateProfile({
        userId: user.id,
        Profiles: {
          [type]: socialLinkPathname(link),
        },
      })
    );
    loadingCondition(type, true);
  };

  const deleteLink = (type, link) => {
    dispatch(
      updateProfile({
        userId: user.id,
        Profiles: {
          [type]: socialLinkPathname(link),
          Deleted: true,
        },
      })
    );
    loadingCondition(type, true);
  };

  useEffect(() => {
    if (socialType === "Facebook") {
      setSocialLink(user.profile.Facebook || "");
    } else setSocialLink(user.profile.LinkedIn || "");
  }, [socialType]);

  useEffect(() => {
    loadingCondition("Facebook", false);
  }, [user.profile.Facebook]);

  useEffect(() => {
    loadingCondition("LinkedIn", false);
  }, [user.profile.LinkedIn]);

  // below logic is for /profile Linked Accounts
  const showAddCancelBox = (social, setBoxState) => {
    setSocialType(social);
    setBoxState(true);
  };

  const facebookLinkInput = (
    <input
      type="text"
      value={facebookLink}
      placeholder="Add facebook url"
      onChange={(e) => {
        const val = e.target.value;
        // console.log("length", val.length);
        setFacebookLink(val);
        if (val.length === 0) setAddStateFacebook(false);
      }}
      className="socialLinkInputs"
    />
  );

  const linkedLinkInput = (
    <input
      type="text"
      value={linkedInLink}
      placeholder="Add linkedin url"
      onChange={(e) => {
        const val = e.target.value;
        setLinkedInLink(val);
        if (val.length === 0) setAddStateLinkedIn(false);
      }}
      className="socialLinkInputs"
    />
  );

  useEffect(() => {
    setFacebookLink(user.profile.Facebook || "");

    // add update text state of button
    if (user.profile.Facebook?.length > 0) {
      setAddUpdateStateFacebook(true);
    } else setAddUpdateStateFacebook(false);
  }, [user.profile.Facebook]);
  // console.log(user.profile.Facebook, "user.profile.Facebook");

  useEffect(() => {
    setLinkedInLink(user.profile.LinkedIn || "");

    // add update text state of button
    if (user.profile.LinkedIn?.length > 0) {
      setAddUpdateStateLinkedIn(true);
    } else setAddUpdateStateLinkedIn(false);
  }, [user.profile.LinkedIn]);

  useEffect(() => {
    const validFacebook =
      /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/;

    const validLinkedIn =
      /(^((https?:\/\/)?((www|\w\w)\.)?)linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;

    if (
      validFacebook.test(facebookLink) &&
      facebookLink != "https://www.facebook.com/" &&
      !facebookLink.includes(" ")
    ) {
      setAddStateFacebook(true);
    } else setAddStateFacebook(false);

    if (
      validLinkedIn.test(linkedInLink) &&
      linkedInLink != "https://www.linkedin.com/" &&
      !linkedInLink.includes(" ")
    ) {
      setAddStateLinkedIn(true);
    } else setAddStateLinkedIn(false);

    if (user.profile.Facebook === facebookLink) {
      setAddStateFacebook(false);
    }

    if (user.profile.LinkedIn === linkedInLink) {
      setAddStateLinkedIn(false);
    }

    setIsSocialLinkValid(
      (validFacebook.test(socialLink) &&
        user.profile.Facebook !== socialLink &&
        socialLink != "https://www.facebook.com/" &&
        !socialLink.includes(" ")) ||
        (validLinkedIn.test(socialLink) &&
          user.profile.LinkedIn !== socialLink &&
          socialLink != "https://www.linkedin.com/" &&
          !socialLink.includes(" "))
    );
    // console.log(socialLink,"socialLinksocialLink")
  }, [
    user.profile.Facebook,
    user.profile.LinkedIn,
    facebookLink,
    linkedInLink,
    socialLink,
    isSocialLinkValid,
  ]);

  return (
    <>
      <section className="linkedAccountsTitle">
        <div>Linked accounts</div>
      </section>
      <section className="linkedAccounts">
        {loadingFacebook ? (
          <Loader size="1.2rem" height="0" scrolling={false} />
        ) : (
          <>
            {user.profile.Facebook !== null ? (
              <div className="socialAccountWrap">
                <a href={user.profile.Facebook} target="_blank">
                  <div className="socialActive">
                    <BsFacebook fontSize="1.25rem" />
                    <span>Facebook</span>
                  </div>
                </a>
                <div className="editDelete">
                  <span
                    onClick={() => {
                      if (path === "/profile")
                        return showAddCancelBox("Facebook", setFacebookEditBox);
                      showModal("Facebook");
                    }}
                    className="clickable"
                  >
                    {EditIcon}
                  </span>
                  <span
                    onClick={() => {
                      setFacebookEditBox(false);
                      deleteLink("Facebook", facebookLink);
                    }}
                    className="clickable"
                  >
                    {DeleteIcon}
                  </span>
                </div>
              </div>
            ) : (
              <div
                className="socialA"
                onClick={() => {
                  // if (dimensions < 768) return;
                  if (path === "/profile")
                    return showAddCancelBox("Facebook", setFacebookEditBox);
                  showModal("Facebook");
                }}
              >
                <AiOutlinePlus fontSize="1.2rem" />
                <span>Facebook</span>
              </div>
            )}
          </>
        )}
        {facebookEditBox && (
          <AddEditBox
            input={facebookLinkInput}
            add={() => {
              if (!facebookLink.length > 0 || !addStateFacebook) return;
              setFacebookEditBox(false);
              updateSocialLinksOnProfile(facebookLink, "Facebook");
            }}
            cancel={() => {
              setFacebookLink(user.profile.Facebook);
              setFacebookEditBox(false);
            }}
            addState={addStateFacebook}
            addUpdate={addUpdateStateFacebook}
          />
        )}
        {loadingLinkedIn ? (
          <Loader size="1.2rem" height="0" scrolling={false} />
        ) : (
          <>
            {user.profile.LinkedIn !== null ? (
              <div className="socialAccountWrap">
                <a href={user.profile.LinkedIn} target="_blank">
                  <div className="socialActive">
                    <div className="linkedIN">
                      <TiSocialLinkedin />
                    </div>
                    <span>LinkedIn</span>
                  </div>
                </a>
                <div className="editDelete">
                  <span
                    onClick={() => {
                      if (path === "/profile")
                        return showAddCancelBox("LinkedIn", setLinkedInEditBox);
                      showModal("LinkedIn");
                    }}
                    className="clickable"
                  >
                    {EditIcon}
                  </span>
                  <span
                    onClick={() => {
                      setLinkedInEditBox(false);
                      deleteLink("LinkedIn", linkedInLink);
                    }}
                    className="clickable"
                  >
                    {DeleteIcon}
                  </span>
                </div>
              </div>
            ) : (
              <div
                className="socialA"
                onClick={() => {
                  // if (dimensions < 768) return;
                  if (path === "/profile")
                    return showAddCancelBox("LinkedIn", setLinkedInEditBox);
                  showModal("LinkedIn");
                }}
              >
                <AiOutlinePlus fontSize="1.2rem" />
                <span>LinkedIn</span>
              </div>
            )}
          </>
        )}
        {linkedInEditBox && (
          <AddEditBox
            input={linkedLinkInput}
            add={() => {
              if (!linkedInLink.length > 0 || !addStateLinkedIn) return;
              setLinkedInEditBox(false);
              updateSocialLinksOnProfile(linkedInLink, "LinkedIn");
            }}
            cancel={() => {
              setLinkedInLink(user.profile.LinkedIn);
              setLinkedInEditBox(false);
            }}
            addState={addStateLinkedIn}
            addUpdate={addUpdateStateLinkedIn}
          />
        )}
      </section>
      <Modal
        visible={isModalVisible}
        footer={false}
        closable={false}
        onCancel={handleCancel}
        className="linkedAccountsModal"
        centered
      >
        <div className="linkedAccountsModalContent">
          <div className="add-link">Add link</div>
          <input
            type="text"
            value={socialLink}
            placeholder="Write or paste link"
            onChange={(e) => socialLinkInput(e.target.value)}
          />
          <div className="save">
            <button
              onClick={updateSocialLinks}
              disabled={isSocialLinkValid ? false : true}
              className={isSocialLinkValid ? null : "disableSocialAcc"}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SocialAccounts;
