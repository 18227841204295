import { types } from "../action-types";
import { getCookie } from "../../shared/helpers";

const talentToken = getCookie("talentToken");
const impersonationId = getCookie("impersonationid");

const initialState = {
  loading: false,
  user: {
    authToken: talentToken ? talentToken : null,
  },
  impersonationId: impersonationId ? impersonationId : null,
  error: {},
  notLogged: false,
  emailCheck: false,
  signUpLogin: true,
  isModalVisibleAuth: false,
  isModalVisibleAuthRestPassword: false,
};

const authReducer = (state = initialState, action) => {
  // debugger;
  switch (action.type) {
    case types.IS_MODAL_VISIBLE:
      return {
        ...state,
        isModalVisibleAuth: action.payload,
      };

    case types.IS_MODAL_VISIBLE_RESETPASSWORD:
      return {
        ...state,
        isModalVisibleAuthRestPassword: action.payload,
        isModalVisibleAuth: !action.payload
      };

    case types.SIGNUP_LOGIN_UPDATE:
      return {
        ...state,
        signUpLogin: action.payload,
      };

    case types.EMAIL_FLOW_UPDATE:
      return {
        ...state,
        emailCheck: action.payload,
      };

    case types.UPDATE_USER_INFO_SUCCESS:
      // // console.log("Reducer Token: ", action.payload);
      // debugger;
      return {
        ...state,
        loading: false,
        user: { ...state.user, authToken: action.payload },
        error: {},
      };
    case types.LINKEDIN_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.payload,
        notLogged: true,
      };
    case types.SEND_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.error,
      };

    case types.UPDATE_IMPERSONATION_ID:
      return {
        ...state,
        loading: false,
        impersonationId: action.payload,
      };

    // case types.UPDATE_IMPERSONATION_ID_INIT:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case types.UPDATE_IMPERSONATION_ID_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error,
    //   };

    default:
      return state;
  }
};

// export const getUserToken = (state) => {
//   // console.log(state);
//   return state;
//   // authToken: state.authReducer.user.authToken,
// };

export default authReducer;
