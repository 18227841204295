import React, { useState, useEffect } from "react";
import "./GuestSearchBar.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
  useLazyQuery,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { connect } from "react-redux";
import {
  fetchGigs,
  SearchResponse,
  SearchResponseRequested,
  SearchValue,
} from "../../../redux/actions";
import { HttpLink } from "apollo-link-http";
import history from "../../../config/history";
import { set } from "lodash";
import { debounce, timeout } from "../../../shared/helpers";
import { useLocation } from "react-router";

const GuestSearchBar = (props) => {
  const url = useLocation();

  const [search, setSearch] = useState("");
  // console.log(search);
  const [count, setCount] = useState(0);

  // const [checkQuery, setCheckQuery] = useState(gql`query `);

  const query = gql`
    query {
      gigs(
        # _sort: PAY_HI_TO_LOW
        # _sort : PAY_LOW_TO_HI
        # _sort : ENDING
        _sort: PAY_HI_TO_LOW
        _page: 1
        _perPage: 20
        BudgetMax: "5000"
        BudgetMin: "0"
        all: "${search}"
        # Skills: ["Rehab", "ICU"]
        # JobOrderTypeName: "Permanent"
        # Location: {
        #  Location : "Houma, LA"
        # }
      ) {
        aggregate {
          count
          totalCount
        }
        data {
          _id
          JobOrderId
          ProfileId
          Title
          score
          BudgetType
          BudgetMin
          Location {
            CityId
            CityName
            RegionId
            RegionCode
            LocationLong
            CountryId
            Location
            LocationWithCountry
            Latitude
            Longitude
            RegionName
            CountryName
          }
          JobOrderStatusName
          JobOrderTypeName
          CreatedBy
          Created
          Modified
          ModifiedBy
          IsActive
          Rating
          Review
          IdealContractLengthId
          IdealContractLengthName
          Description
          SuggestionsFrequency
          SuggestionsSent
          ExperienceLevelName
          InternalGigTypeCode
          ImportSource
          ExternalGigId
          ProfessionCode
          ImportProcessStatus
          FacilityName
          AutoLoaded
          SourceName
          SourceId
          Shift
          Skills {
            SkillName
            SkillPercent
          }
          IsFavorite
        }
      }
    }
  `;
  const [hitQuery, { loading, error, data }] = useLazyQuery(query);
  // const { loading, error, data } = useQuery(query);

  // // console.log(data);
  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
  const [gigset, setGigSet] = useState();

  // const httpLink = new HttpLink({
  //   uri: `${BackendApiBaseUrl}/query`,
  // });

  //   const client = new ApolloClient({
  //     // uri: `${BackendApiBaseUrl}/query`,
  //     cache: new InMemoryCache(),
  //     link: ApolloLink.from([httpLink]),
  //   });

  // const handleFilter = () => {
  //   debounce(() => hitQuery());
  // };

  useEffect(() => {
    // // console.log("Loader: ", loading);
    // // console.log("Query: ", query);
    // // console.log(data);
    props.searchResponse(data);
    // // console.log("Gigs: ", user);
  }, [data, search, loading]);

  useEffect(() => {
    // console.log("Gigs", props.gigs);
  }, [props.gigs]);

  useEffect(() => {
    // saving loading state to reducer from graphQL
    if (search.length > 0) {
      props.SearchResponseRequested(loading);
    }

    if (search.length > 0) {
      history.push("/search");
    }
    // else if (search.length === 0) {
    //   history.go(-1);
    // }
    props.searchValue(search);
    // console.log("Search Value from Saga: ", props.value);
  }, [search]);

  //   useEffect(() => {
  //     return () => {
  //       // console.log({search});
  //     };
  //   }, [search])

  // if (loading) return "Loading...";
  // if (error) return `Error! ${error.message}`;

  const onChangeSearch = (e) => {
    setSearch(e.target.value);

    // if (e.target.value?.length > 3) {
    // hitQuery({ variables: { all: e.target.value } });
    debounce(hitQuery());
    // }
  };

  const Query = () => {
    // console.log("hitted");
    // props.GetGigs(query);

    // const GetGigs = props.user.Gigs;
    // // console.log(GetGigs);
    // if (loading) return "Loading...";
    // if (error) return `Error! ${error.message}`;
  };

  return (
    <div className="searchInputDashboard">
      <div className="search-gigs">
        <input
          style={{ color: "initial" }}
          type="text"
          placeholder="Find gigs"
          value={search}
          onChange={(e) => onChangeSearch(e)}
        />
      </div>
    </div>
    // <div className="container-search">
    //   <input
    //     type="text"
    //     placeholder="Search Gigs..."
    //     value={search}
    //     className="searchbox-search"
    //     onChange={(e) => {
    //       setSearch(e.target.value);
    //       // // console.log("Changed");
    //       // console.log({ search });
    //       // Query();
    //     }}
    //   />
    // </div>
  );
};
const mapStateToProps = (state) => {
  return {
    gigs: state.searchGigsReducer.gigs,
    value: state.searchGigsReducer.searchvalue,
    error: state.searchGigsReducer,
    talentToken: state.authReducer.user?.authToken,
    // userProfile: state.profileReducer.user.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // GetGigs: (params) => {
    //   dispatch(fetchGigs(params)); // action dispatched
    // },
    // SearchValue
    SearchResponseRequested: (params) => {
      dispatch(SearchResponseRequested(params)); // action dispatched
    },
    searchResponse: (params) => {
      dispatch(SearchResponse(params)); // action dispatched
    },
    searchValue: (params) => {
      dispatch(SearchValue(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestSearchBar);
// export default SearchBar;
