import React, { useState, useEffect } from "react";
import carbonFlash from "../../../assets/img/carbonFlash.png";
import bigPlus from "../../../assets/img/bigPlus.png";
import "./DashboardAnalytics.css";
import { Progress } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchResumeDetailInit, getAnalyticInfo } from "../../../redux/actions";
import { BsPlusLg } from "react-icons/bs";
import { useHistory } from "react-router";
import CreateSkilllistingButton from "./AnalyticsComponents/CreateSkilllistingButton";
import AnalyticsFirst from "./AnalyticsComponents/AnalyticsFirst";
import { AiTwotoneThunderbolt } from "react-icons/ai";

const DashboardAnalytics = ({ analyticInfo }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;
  useEffect(() => {
    dispatch(fetchResumeDetailInit());
  }, []);

  // useEffect(() => {
  //   dispatch(getAnalyticInfo());
  //   // console.log("ticks", analyticInfo);
  // }, []);

  // useEffect(() => {
  //   // console.log("ticks", analyticInfo);
  // }, [analyticInfo]);

  const user = useSelector((state) => state.profileReducer.user);

  const isProfileCompletes = () => {
    let completeProfile = true;
    if (
      user?.profile?.PictureUrl === null ||
      user?.profile?.Location === null ||
      user?.profile?.ExperienceLevelName === null ||
      user?.profile?.Facebook === null ||
      user?.profile?.LinkedIn === null ||
      //  user?.profile?.CareerManagerId === null
      // user?.profile?.Discipline === null ||
      // user?.profile?.TagLine === null ||
      user?.profile?.WorkHistory === null ||
      user?.profile?.EducationHistory === null
    ) {
      completeProfile = false;
    }

    return completeProfile;
  };

  return (
    <>
      {/* <div className="dashboard-main-examination-container">
          <div className="dashboard-main-examination-panel">
            <div className="dashboard-main-examination-panel-content">
              <div className="dashboard-main-examination-panel-content-inner">
                <div className="dashboard-main-examination-panel-txt">
                  Take your Clinical Assesment Exam
                </div>
                <div className="dashboard-main-examination-panel-subTxt">
                  Relias
                </div>
              </div>
              <div
                className="dashboard-exam-starter-button"
                // onClick={() => history.push("/dummy")}
              >
                <a
                  href="https://www.relias.com/product/assessments"
                  target="_blank"
                >
                  <div className="dashboard-start-the-exam">Start Exam</div>
                </a>
              </div>
            </div>
          </div>
          <div>
            <div
              className="Frame-19924"
              onClick={() => history.push("/create-skilllisting")}
            >
              <BsPlusLg color="white" style={{ marginTop: "2.5px" }} />
              <div className="Create-a-Skill-Listing3">
                Create a Skill Listing
              </div>
            </div>
          </div>
        </div> */}
      {/* <AnalyticsFirst /> */}

      <div
        className={
          !isProfileCompletes()
            ? "dashboard-main-analytics-container"
            : " dashboard-main-analytics-container-class-2"
        }
      >
        <div
          className="dashboard-analytics-components"
          onClick={() => history.push("/manageSkillListing")}
        >
          <div className="dashboard-analytics-components-name">
            {profile?.Skilllistings ? profile?.Skilllistings : 0}
          </div>
          <div className="dashboard-analytics-components-value">
            Skill listings
          </div>
        </div>

        <div
          className="dashboard-analytics-components"
          onClick={() => history.push("/3d_resume")}
        >
          <div className="analytics-resumeCompletion-value">
            <Progress
              type="circle"
              strokeColor="#4054b2"
              strokeWidth={10}
              percent={
                Math.floor(profile?.ResumeCompletion)
                  ? Math.floor(profile?.ResumeCompletion)
                  : 0
              }
              width={36}
            />
          </div>
          <div className="dashboard-analytics-components-value-2">
            3d resume completion
          </div>
        </div>

        <div
          className="dashboard-analytics-components"
          onClick={() => history.push("/view-listing")}
        >
          <div className="dashboard-analytics-components-name">
            {profile?.Views > 0 ? profile?.Views : 0}
          </div>
          <div className="dashboard-analytics-components-value">
            Skill listing views
          </div>
        </div>

        {/* <div
          className="dashboard-analytics-components"
          // onClick={() => history.push("/dummy")}
        >
          <div className="dashboard-analytics-components-name">
            <AiTwotoneThunderbolt />
          </div>
          <div className="dashboard-analytics-components-value">Get my RMV</div>
        </div> */}
      </div>
    </>
  );
};

export default DashboardAnalytics;
