import React from "react";
import "./styles.css";
import Notifications from '../../components/notifications/notifications'
export default function NotificationPage() {
  return (
    <div className="NotificationScreen">
      <div className="cont">
        <Notifications />
      </div>
    </div>
  );
}