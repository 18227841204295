import { types } from "../action-types";

const initialState = {
  Searchinfo: [],
  searchValueInfo: [],
  searchCloseIconInfo: [],
};

const onClearSearchReducer = (state = initialState, action) => {
  // // console.log("setjobsss" , action.payload)
  switch (action.type) {
    case types.CLEAR_SEARCH_INIT:
      state.Searchinfo = action.payload;
      // console.log("setSearchh1", action.payload);
      return state;
    case types.CLEAR_SEARCH_VALUE_INIT:
      state.searchValueInfo = action.payload;
      // console.log("setSearchh2", action.payload);
      return state;
    case types.CLEAR_SEARCH_ICON_INIT:
      state.searchCloseIconInfo = action.payload;
      // console.log("setSearchh3", action.payload);
      return state;

    default:
      return state;
  }
};

export default onClearSearchReducer;
