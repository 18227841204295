import React, { useState, useRef } from "react";
import "./EducationHistory.css";
import AddEditBox from "../../../../components/AddEditBox/AddEditBox";
import EducationHistoryForm from "./EducationHistoryForm";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { updateEducationHistory } from "../../../../redux/actions";

import Loader from "../../../../components/Loader/Loader";

const EducationHistory = (props) => {
  const { skillListingDetailPage, addEditEd, setAddEditEd } = props;

  const dispatch = useDispatch();
  const [addState, setAddState] = useState(false);
  const [updateEducation, setUpdateEducation] = useState(false);
  const [educationId, setEducationId] = useState(null);
  const [styleContact, setStyleContact] = useState({ display: "none" });
  const [dimensions, setDimensions] = useState(window.innerWidth);

  const formSubmitRef = useRef();
  const refList = [];

  const loading = useSelector((state) => state.profileReducer.loadingED);

  const EducationHistory = useSelector(
    (state) => state.profileReducer.talentInfo.EducationHistory
  )
    ?.filter((obj) => obj.Deleted === false)
    .sort((a, b) => a.YearCompleted - b.YearCompleted);

  const deleteEducationHistory = (body, id) => {
    dispatch(updateEducationHistory(body, id));
  };
  useUpdateLogger("EducationHistory", EducationHistory);

  return (
    <div
      className="education-history"
      // onMouseEnter={(e) => {
      //   if (dimensions < 768) return;
      //   setStyleContact({
      //     display: "inline",
      //     // paddingTop: "5px",
      //   });
      // }}
      // onMouseLeave={(e) => {
      //   setStyleContact({ display: "none" });
      // }}
    >
      <section className="title-education-history">
        <div className="title">Education History</div>
        {(!skillListingDetailPage || EducationHistory.length > 0) &&
          // dimensions > 768 &&
          !addEditEd && (
            <div
              className="add-title"
              onClick={() => {
                setAddEditEd(true);
                setUpdateEducation(false);
              }}
            >
              Add new
            </div>
          )}
      </section>
      <section>
        {addEditEd && (
          <>
            <AddEditBox
              input={
                <EducationHistoryForm
                  formSubmitRef={formSubmitRef}
                  setAddState={setAddState}
                  addState={addState}
                  skillListingDetailPage={skillListingDetailPage}
                />
              }
              add={() => {
                if (addState) {
                  formSubmitRef.current.click();
                  setAddEditEd(false);
                }
              }}
              cancel={() => {
                setAddEditEd(false);
                setAddState(false);
              }}
              addState={addState}
            />
            <div style={{ marginBottom: "20px" }}></div>
          </>
        )}
        <>
          {loading ? (
            <Loader height="max-content" scrolling={false} />
          ) : (
            <>
              {EducationHistory?.length > 0 ? (
                <>
                  {EducationHistory.map((history, i) => {
                    refList.push(React.createRef());
                    return (
                      <section key={history._id}>
                        {updateEducation && history._id === educationId ? (
                          <>
                            <AddEditBox
                              input={
                                <EducationHistoryForm
                                  id={history._id}
                                  SchoolName={history.Schools}
                                  CourseType={history.CourseType}
                                  CourseName={history.Courses}
                                  YearCompleted={history.YearCompleted}
                                  formSubmitRef={refList[i]}
                                  setAddState={setAddState}
                                  addState={addState}
                                  skillListingDetailPage={
                                    skillListingDetailPage
                                  }
                                />
                              }
                              add={() => {
                                if (addState) {
                                  refList[i].current.click();
                                  setUpdateEducation(false);
                                }
                              }}
                              cancel={() => {
                                setUpdateEducation(false);
                              }}
                              addState={addState}
                              addUpdate={updateEducation}
                            />
                            <div style={{ marginBottom: "20px" }}></div>
                          </>
                        ) : (
                          <section className="educationHistory-data">
                            <div className="school">
                              <div className="top">
                                <span className="courseType-Name">
                                  {`${
                                    history?.Courses
                                      ? history.Courses?.CourseName
                                      : "N/A"
                                  }  -  ${
                                    history?.CourseType
                                      ? history.CourseType?.CourseTypeName
                                      : "N/A"
                                  }`}
                                </span>
                              </div>
                              <div
                                className="editDelete"
                                //  style={styleContact}
                              >
                                <span
                                  onClick={() => {
                                    setAddEditEd(false);
                                    setUpdateEducation(true);
                                    setEducationId(history._id);
                                  }}
                                >
                                  <AiOutlineEdit className="iconColorED clickable" />
                                </span>
                                <span
                                  onClick={() =>
                                    deleteEducationHistory(
                                      {
                                        YearCompleted: history.YearCompleted,
                                        CourseType: history.CourseType,
                                        Courses: history.Courses,
                                        Schools: history.Schools,
                                        Deleted: true,
                                      },
                                      history._id
                                    )
                                  }
                                >
                                  <RiDeleteBin5Fill
                                    className="iconColorED clickable"
                                    style={{ marginLeft: "10px" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="sub-font-ED schoolName">
                              {history.Schools
                                ? history.Schools?.SchoolName
                                : "N/A"}
                            </div>
                            <div className="sub-font-ED yearCompleted">
                              {`Graduated ${
                                history.YearCompleted
                                  ? history.YearCompleted
                                  : "N/A"
                              }`}
                            </div>
                            {skillListingDetailPage &&
                              EducationHistory.length > 1 &&
                              EducationHistory.length - 1 !== i && (
                                <div className="divider-skill"></div>
                              )}
                          </section>
                        )}
                      </section>
                    );
                  })}
                </>
              ) : (
                <>
                  {!addEditEd && (
                    <p className="add-your-education">Add your education</p>
                  )}
                </>
              )}
            </>
          )}
        </>
      </section>
    </div>
  );
};

export default EducationHistory;
