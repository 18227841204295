import { types } from "../action-types";

const initialState = {
    loading: false,
    success: [],
    error: false,
    request: false,
};

const createTimeSheetCorrectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_TIMESHEET_CORRECTION_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.CREATE_TIMESHEET_CORRECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                success:action.payload,
                error: false,
                request:true,
            };
        case types.CREATE_TIMESHEET_CORRECTION_FAILURE:
            return {
                ...state,
                loading: false,
                success:[],
                error: true,
                request:false,
            };

        default:
            return state;
    }
};
export default createTimeSheetCorrectionReducer;
