import { types } from "../action-types";
import moment from "moment";
const initialState = {
  loading: false,
  // chats: [],
  // chat:{},
  error: false,

  //new states
  inboxLoading: false,
  sentItemsLoading: false,
  sendMessageLoading: false,
  inbox: { data: { data: { body: { conversations: [] } } } },
  sentItems: [],
  inboxError: {},
  sentItemsError: {},
  sendMessageError: {},
  historyLoading: false,
  chats: [],
  bidStatus: [],
  error: false,
  uploadFileRes: "",
  editOfferRes: false,
  editOfferLoading: false,
  counts: {},
  newMsgInSocket: false,
  hasMorechats: true,
  page: 3,
  jobDetail: [],
};

const getChatHistoryReducerHeader = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CHAT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        chats: action.payload,
        error: false,
        bidStatus: action.payload.data.body.map((items) => {
          return items.Status;
        }),
      };
    case types.FETCH_CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        chats: [],
        error: true,
      };
    case types.MESSAGES_RECEIVED: {
      if (
        state.chats?.data?.body[0]?.ConversationId ===
        action?.payload?.message?.data?.ConversationId
      ) 
        if (action.payload.message.edited) {
          let messages = [...state.chats.data.body];
          const index = messages.findIndex(
            (obj) => obj._id == action.payload.message.data._id
          );
          messages[index] = action.payload.message.data;
          return {
            ...state,
            chats: {
              data: {
                body: messages,
              },
            },
          };
        }
      else
      {
        return {
          ...state,
          chats: {
            data: {
              body: [
                ...state?.chats?.data?.body,
                action.payload.message.data,
                // filterData,
              ],
            },
          },
          bidStatus: [...state.bidStatus, action.payload.message.data.Status],
          notification: action.payload,
        };
      } else {
        return { ...state };
      }
    }
    
    case types.FAVOURITE_SUCCESS: {
      let favIndex = action.payload;
      let oldChat = state?.inbox?.data?.data?.body?.conversations;


      if(oldChat[favIndex]){
        oldChat[favIndex].IsFavorite = true;
      }
      let updatedChat = oldChat
      return {
        ...state,
        inbox: {
          ...state?.inbox,
          data: {
            ...state?.inbox?.data,
            data: {
              ...state?.inbox?.data?.data,
              body: {
                ...state?.inbox?.data?.data?.body,
                conversations: updatedChat,
              },
            },
          },
        },
      };
    }
    
    case types.REMOVE_FAV_SUCCESS: {
      let favIndex = action.payload;
      let oldChat = state?.inbox?.data?.data?.body?.conversations;
      
      oldChat[favIndex].IsFavorite = false;

      let updatedChat = oldChat
      return {
        ...state,
        inbox: {
          ...state?.inbox,
          data: {
            ...state?.inbox?.data,
            data: {
              ...state?.inbox?.data?.data,
              body: {
                ...state?.inbox?.data?.data?.body,
                conversations: updatedChat,
              },
            },
          },
        },
      };
    }
    
    case types.SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        chats: [
          {
            ...action.payload.data,
            SenderUser: [
              {
                name: action.payload.name,
                profile: { PictureUrl: action.payload.ProfilePic },
              },
            ],
          },
          ...state.chats,
        ],
      };
    }
    case types.GET_MESSAGES_INBOX_REQUESTED_HEADER:
      if (action.payload.filter) {
        return {
          ...state,
          inboxLoading: true,
          inbox: { data: { data: { body: { conversations: [] } } } },
          page: 3,
        };
      }
      return {
        ...state,
        inboxLoading: true,
      };
    case types.GET_MESSAGES_INBOX_SUCCESS_HEADER:
      let conversationData = action?.payload?.data?.data?.body?.conversations;
      let inboxData = state?.inbox?.data?.data?.body?.conversations;
      let conversations = [...inboxData, ...conversationData].reverse();
      conversations = [...new Set(conversations.map((item) => item._id))].map(
        (id) => {
          return conversations.find((item) => item._id === id);
        }
      );
      let tempPage = state.page;
      if (action?.payload?.fetchMore) tempPage = tempPage + 1;
      conversations.sort((a, b) => moment(b.Created) - moment(a.Created));
      return {
        ...state,
        inbox: {
          ...state?.inbox,
          data: {
            ...state?.inbox?.data,
            data: {
              ...state?.inbox?.data?.data,
              body: {
                ...state?.inbox?.data?.data?.body,
                conversations: conversations,
              },
            },
          },
        },
        inboxLoading: false,
        hasMorechats:
          action.payload.data.data.body.conversations.length > 0 ? true : false,
        page: tempPage,
        newMsgInSocket: action.payload.fromSocket,
      };
    case types.GET_MESSAGES_INBOX_FAILURE_HEADER:
      return {
        ...state,
        inbox: "no messages found",
        inboxLoading: false,
      };
    case types.GET_INBOX_JOB_DETAILS:
      return {
        ...state,
        jobDetail: action.payload,
      };
    case types.GET_MESSAGES_COUNTS_SUCCESS:
      return {
        ...state,
        counts: action.payload,
      };
    default:
      return state;
  }
};

export default getChatHistoryReducerHeader;
