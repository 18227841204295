import { types } from "../action-types";

const initialState = {
  isError: false,
  message: "",
};

const catchErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CATCH_ERROR:
      return { isError: true, message: action.error?.message };
    case types.CATCH_ERROR_FIX:
      return initialState;
    default:
      return state;
  }
};

export default catchErrorReducer;
