import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { userAssignmentApi } from "../../API/API";

function* userAssignmentSaga() {
  // console.log("user assignment SAGA");
  try {
      const response = yield call(userAssignmentApi); 
      // console.log('user assignment response:',response);
      yield put({type: types.USERASSIGNMENT_SUCCESS, payload: response.data});
    }
    catch (error) {
      yield put({type: types.USERASSIGNMENT_FAILURE, error});
  }
}

export function* watchUserAssignmentSaga() {
  yield takeEvery(types.USERASSIGNMENT_REQUESTED, userAssignmentSaga);
}
