import React, { useState, useEffect } from "react";
import "./assets/css/common.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppRouter } from "./routes/router";
import {
  fetchData,
  fetchInboxMessages,
  fetchInterview,
  fetchReceivedBidsStages,
  fetchResumeDetailInit,
  placeBidFetch,
  updateUserInfo,
} from "./redux/actions";
import "./firebaseConfig";
import { useClearCache } from "react-clear-cache";
import { Auth } from "./config/aws-exports";
import { getCookie, setCookie } from "./shared/helpers";
import WS from "./webSocket/defaultSocket";
import { useLocation } from 'react-router-dom'
import Loader from "./components/Loader/Loader";
import Header from "./components/Header/Header";

function App(props) {
  const dispatch = useDispatch();
  const location = useLocation()

  const [stage, seStage] = useState("");

  const userProfile = useSelector((state) => state.profileReducer.user);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/sw.js`)
      .then(() => {});
  }

  const receiveStage = (stage) => {
    seStage(stage);
  };

  const pageSize = 20;

  // might stop the zoom in behavior for other reasons on iPhone other than 16px on input
  useEffect(() => {
    if (navigator.userAgent.indexOf("iPhone") > -1) {
      document
        .querySelector("[name=viewport]")
        .setAttribute(
          "content",
          "width=device-width, initial-scale=1, maximum-scale=1"
        );
    }
  }, []);
  
  useEffect(() => {
    const accountType = props?.user?.profile?.AccountTypeCode;

    if (accountType === "EMP" && props?.authToken) {
      window.location.replace("/employer");
    }
  }, [props.user]);

  useEffect(() => {
    if (userProfile?.profile?.AccountId) {
      WS.init(userProfile?.profile?.AccountId);
      WS.onMessage(({ data }) => {
        dispatch(
          fetchInboxMessages("all_bids", "all_conversations", "null", 1, 1)
        );
      });
    }
  }, [userProfile?.profile?.AccountId]);



  const theClick = () => {
    WS.sendMessage(
      JSON.stringify({
        action: "ping", // whatever route we define it'll trigger it
      })
    );
  };


  function checkTabFocused() {
    if (document.visibilityState === "visible") {
      const cognitoUser = Auth.currentAuthenticatedUser();
      cognitoUser
        .then((user) => {})
        .catch((err) => {
          if (getCookie("talentToken") === null) {
            props.sendAuthToken(null);
          }
        });
    } else {
    }
  }
  if (props?.authToken) {
    document.addEventListener("visibilitychange", checkTabFocused);
  }
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  if (!isLatestVersion) {
    emptyCacheStorage();
  }

  useEffect(() => {
    (function () {
      window.__insp = window.__insp || [];
      window.__insp.push(["wid", process.env.REACT_APP_INSPECTLET_ID]);
      var ldinsp = function () {
        if (typeof window.__inspld != "undefined") return;
        window.__inspld = 1;
        var insp = document.createElement("script");
        insp.type = "text/javascript";
        insp.async = true;
        insp.id = "inspsync";
        insp.src =
          ("https:" == document.location.protocol ? "https" : "http") +
          `://cdn.inspectlet.com/inspectlet.js?wid=${process.env.REACT_APP_INSPECTLET_ID}&r=` +
          Math.floor(new Date().getTime() / 3600000);
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(insp, x);
      };
      setTimeout(ldinsp, 0);
    })();
  }, []);

  useEffect(() => {
    {console.log('auth token in app js', props)}
    dispatch(fetchReceivedBidsStages());
    dispatch(fetchInterview());
    dispatch(fetchResumeDetailInit());
    dispatch(placeBidFetch("most_recent", null, null, 1, 24));
  }, []);

  {console.log('auth token in app js', props)}
  return (
    <React.Fragment>
      {/* <StrictMode> */}
      <div className="page-container">
        {/* <EmailVerification /> */}
        {/* <ErrorModel> */}
        {props?.loading && !props?.authToken ? (
          <>
        <Header />
        <Loader size="2.5rem" height="45vh" />
          </>
        )
         : <AppRouter
         authToken={props.authToken}
         shareStage={receiveStage}
         stage={stage}
         userProfile={userProfile}
         isEmailVerified={false}
         location={location.pathname}
         width = {screenWidth}
       /> }
        {/* </ErrorModel> */}
      </div>
      {/* </StrictMode> */}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.user.authToken,
    loading: state.profileReducer.loading,
    user: state.profileReducer.user,
    error: state.profileReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (params) => {
      dispatch(fetchData(params));
    },
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);