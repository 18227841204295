import React, { useState } from 'react';
import { Col, Select, Tooltip, Button, Typography, Input, Row } from 'antd';
import { CheckCircleTwoTone, CloseCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateReceivedBids } from '../../redux/actions';
import { useLocation } from 'react-router-dom';
import './DeclineBids.css';

const DeclineBids = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { id } = state;
    const [reason, setReason] = useState('');
    const { Title, Text } = Typography;
    const { Option } = Select;
    function onChange(value) {
        // console.log(`selected ${value}`);
    }

    function onBlur() {
        // console.log('blur');
    }

    function onFocus() {
        // console.log('focus');
    }

    function onSearch(val) {
        // console.log('search:', val);
    }
    function onChange(e) {
        setReason(e);
    }
    const declineBid=()=>{
        dispatch(updateReceivedBids(id,'Not Interested',`Bid was declined by the user - ${reason}`,'Bid Declined','Bid Declined'));
    }
    return (
        <>
            <div className={'DeclinePageContainer'}>
                <div className="page-body">
                    <Col className={"title-div"} lg={24} md={24} sm={22} xs={20}>
                        <Title level={2} className="Title">Decline this bid</Title>
                        <span className="title-span"><Text>Please select a reason for declining this bid:</Text></span>
                    </Col>
                    <Col className="Select-div" lg={12} md={22} sm={24} xs={24}>
                        <Row>
                            <Col lg={22} md={22} sm={21} xs={20}>
                                <Select
                                    showSearch
                                    style={{ width: "100%", textAlign: 'left' }}
                                    placeholder="Select..."
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    // onChange={(e) => onChange(e)}
                                >
                                    <Option value="On Assignment">On assignment</Option>
                                    <Option value="Not intersted in the location">Not intersted in the location</Option>
                                    <Option value="Estimated pay rate is too low">Estimated pay rate is too low</Option>
                                    <Option value="Skills not a match">Skills not a match</Option>
                                </Select>
                            </Col>
                            <Col lg={2} md={2} sm={3} xs={4}>
                                <div className="icon-div">{reason ? <CheckCircleTwoTone /> : <CloseCircleFilled />}</div>
                            </Col>
                        </Row>
                    </Col>
                </div>
                <Row className={'Btns-row'}>
                    {/* <Col lg={1}  sm={1}></Col> */}
                    <Col lg={11} xs={24} md={22} className={'cancel-btn-div'}>
                        <Button className="cancel-btn">CANCEL</Button>
                    </Col>
                    {/* <Col lg={1}></Col> */}
                    <Col lg={11} xs={24} md={22} className={'decline-btn-div'}>
                        <Button className="decline-btn" onClick={declineBid}>DECLINE</Button>
                    </Col>
                </Row>
            </div>
        </>
    );
}
export default DeclineBids;
