import React, { useState, useEffect } from "react";
import "./FavoriteFunction.css";

import { IoIosStar } from "react-icons/io";

const FavoriteFunction = ({
  isFavorite = false,
  addFavorite,
  removeFavorite,
}) => {
  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  return (
    <div
      className="favorite-bid-background"
      onClick={(e) => {
        e.stopPropagation();
        setFavorite(!favorite);
        if (!favorite) {
          addFavorite();
        } else {
          removeFavorite();
        }
      }}
    >
      <IoIosStar
        className="iconStar-fav"
        // style={{ marginTop: "1.5px", fontSize: "15px", marginLeft: "1px" }}
        color={favorite ? "#ffba08" : "#9e9e9e"}
      />
    </div>
  );
};

export default FavoriteFunction;
