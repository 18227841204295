import React, { useEffect, useState } from "react";
import BidDetail from "../BidDetail/BidDetail";
import { DatePicker, Radio, Space } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptOffer,
  fetchBidData,
  fetchDeclinedReasonsBids,
  fetchDetailPlaceBid,
  InterviewScheduleAcceptedInit,
  updateDeclinedReasonsReceivedBids,
} from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";
import DownIcon from "../../../assets/icons/DownIcon.png";
import upperIconDesktop from "../../../assets/icons/upperIconDesktop.png";
import CalendarIcon from "../../../assets/icons/CalendarIcon.svg";
import moment from "moment";

import ScreeningAndSchedule from "../components/ScreeningAndSchedule";
import OfferLetter from "../components/OfferLetter";
import CompleteCredentialing from "../components/CompleteCredentialing";
import WelcomeOnboard from "../components/WelcomeOnboard";
import EditBid from "./components/EditBidButton";
import BidDeclined from "./components/BidDeclined";
import Loader from "../../../components/Loader/Loader";
import EditRetractMobile from "../components/ActionButtonMobile";
import ChatButtonMobile from "../components/ChatButtonMobile";
import StatusCardBid from "../components/StatusCardBid";
import NewPlacedBid from "../components/NewPlaceBid";
import AcceptedPlacedBid from "../components/AcceptedPlacedBid";
import DeclineStatus from "../components/DeclineStatus";
import { Modal } from "antd";
import UploadFile from "../components/UploadFile";
import RetractedModal from "../components/RetractedModal";
import NewPlacedBidMobile from "../components/NewPlacedBidMobile";
import { getDeclineReason } from "../../Manage_SkillListing/SkillListingDetailPage/components/BidHistorySkillListing/bhsHandler";

const PlacedBidsDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { bidId } = useParams();
  const [ReasonToDecline, setReasonToDecline] = useState(null);

  const [Interviewmsg, setInterviewmsg] = useState(null);
  const [PlacedBidSendRequest, setPlacedBidSendRequest] = useState(false);
  const [aboutUI, setAboutUI] = useState(null);
  const [actionButtonMobile, setActionButtonMobile] = useState(null);
  const [extraSection, setExtraSection] = useState(null);
  const [
    isModalVisibleBidDeclinedPlacedBid,
    setModalVisibleBidDeclinedPlacedBid,
  ] = useState(false);
  const [isModalVisibleBidCalender, setModalVisibleBidCalender] =
    useState(false);
  const modalCancelCalender = () => {
    setModalVisibleBidCalender(false);
  };
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [talentstagemodal, settalentstagemodal] = useState();
  const [disableStatevalue, setdisableStatevalue] = useState(false);
  const [AcceptButtonModal, setAcceptButtonModal] = useState();
  const [declinedReason, setDeclinedReason] = useState(null);
  const [Modaltitle, setModaltitle] = useState("");
  const [DateValueStage, setDateValueStage] = useState();
  const [DateValueStage1, setDateValueStage1] = useState();
  const [DateValueStage2, setDateValueStage2] = useState();
  const [interviewTime, setInterviewTime] = useState(null);
  const [InterviewSendRequest, setInterviewSendRequest] = useState(false);
  const [Endtime, setEndtime] = useState();
  const [Endtime1, setEndtime1] = useState();
  const [Endtime2, setEndtime2] = useState();
  const [ValidationMessage, setValidationMessage] = useState();
  const [ErrorMessagePlaceBid, setErrorMessagePlaceBid] = useState();
  const initialState = {
    Time: "",
  };
  const [InterviewTimeStart, setInterviewTimeStart] = useState(initialState);
  // console.log(InterviewTimeStart, "InterviewTimeStart");

  useEffect(() => {
    // console.log("Fetching bid details:::");
    dispatch(fetchDetailPlaceBid(bidId));
  }, []);

  function OpenModalBoxStages(data) {
    if (data === "Interview") {
      // console.log(data);
      settalentstagemodal(data);
      setModalVisibleBidDeclinedPlacedBid(true);
      setModaltitle("interview");
    } else if (data === "Interview Accepted") {
      settalentstagemodal(data);
      setModalVisibleBidDeclinedPlacedBid(true);
      setModaltitle("interview");
    } else if (data === "New") {
      settalentstagemodal(data);
      setModalVisibleBidDeclinedPlacedBid(true);
      // setModaltitle("bid")
    } else if (data === "Offer") {
      settalentstagemodal(data);
      setModalVisibleBidDeclinedPlacedBid(true);
      setModaltitle("Offer");
      setAcceptButtonModal("");
    }
  }

  function AcceptButton(data) {
    if (data === "New") {
      // console.log("hello New");
      submitDeclinedReason();
    } else if (data === "Interview") {
      // console.log("hello Interview");
      AcceptInterview();
    } else if (data === "Offer") {
      setAcceptButtonModal("Offer");
      settalentstagemodal("");
      setModalVisibleBidDeclinedPlacedBid(true);
    }
  }

  useEffect(() => {
    if (interviewTime) {
      setValidationMessage(true);
      setErrorMessagePlaceBid("");
      // console.log(ValidationMessage, "InterviewMessage", ErrorMessagePlaceBid);
    } else {
      setValidationMessage(false);
      // console.log(ValidationMessage, "InterviewMessage", ErrorMessagePlaceBid);
    }
  }, [interviewTime]);

  // console.log(ValidationMessage, "InterviewMessage", ErrorMessagePlaceBid);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  const modalCancelPlacedBid = () => {
    setModalVisibleBidDeclinedPlacedBid(false);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, [dimensions]);

  const SendRequestDetailPlacedBid = () => {
    dispatch(fetchDetailPlaceBid(bidId));
  };

  const declinedReasonsBid = useSelector(
    (state) => state.BidsReducer.declinedReasonsBid
  );

  const BidDetailData = useSelector(
    (state) => state.BidsReducer.detailPlacedBid
  );

  // useUpdateLogger("declinedReasonsBid", declinedReasonsBid);

  // const BidDetailData = {
  //   ...BidDetailData01,

  //   ranking: {
  //     ...BidDetailData01?.ranking,
  //     BidStatusName: "Terminated",
  //     Rank: 17,
  //   },
  // };

  useEffect(() => {
    dispatch(fetchDeclinedReasonsBids("interview"));
    // // console.log("declinedReasonsBiddeclinedReasonsBid");
  }, [Modaltitle === "interview"]);

  useEffect(() => {
    dispatch(fetchDeclinedReasonsBids("interview"));
    // // console.log("declinedReasonsBiddeclinedReasonsBid");
  }, [Modaltitle === "offer"]);

  // useEffect(() => {
  //   dispatch(fetchDeclinedReasonsBids("bid"));
  // }, [Modaltitle === "bid"]);

  useEffect(() => {
    if (InterviewSendRequest === true && ValidationMessage === true) {
      dispatch(
        InterviewScheduleAcceptedInit({
          id: BidDetailData?.interview?.InterviewId,
          reschedule: true,
          idSearch: bidId,
          body: {
            ApprovedScheduledTiming: {
              StartTime: interviewTime?.StartTime,
              EndTime: interviewTime?.EndTime,
            },
            InterviewStatus: "Accepted",
            Message: "You have accepted the interview",
            // checking
            // idSearch: BidDetailData?._id,
          },
        })
      );
    } else if (ValidationMessage === false) {
      setErrorMessagePlaceBid(
        "Choose an available interview date or request to reschedule another time"
      );
    }
    setInterviewSendRequest(false);
  }, [InterviewSendRequest]);

  const dateHandlerStages = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage(ctime.toISOString());
    setEndtime(date.toISOString());
  };

  const dateHandlerStages1 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage1(ctime.toISOString());
    setEndtime1(date.toISOString());
  };

  const dateHandlerStages2 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage2(ctime.toISOString());
    setEndtime2(date.toISOString());
  };

  // console.log(DateValueStage, Endtime, "twotime");

  // console.log(BidDetailData, "BidDetailData");
  const loading = useSelector((state) => state.BidsReducer.loading);
  const interviewloader = useSelector((state) => state.interviewReducer.loading)

  const BidGotAccepted = () => (
    <div className="gotAccepted-bid">
      Your Bid got accepted by the employer.
    </div>
  );

  function editBidRoute() {
    history.push({
      pathname: `/edit-bid/${bidId}`,
      state: {
        id: BidDetailData?._id,
        jobOrder: bidId,
        JobType: BidDetailData?.gigs?.JobOrderTypes?.JobOrderTypeName
      },
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (BidDetailData?.ranking?.BidStatusName === "New") {
      setAboutUI(
        <NewPlacedBid
          yesText="Edit Bid"
          noText="Retract Bid"
          editBidRoute={editBidRoute}
          OpenModalBoxStages={OpenModalBoxStages}
          stagename="New"
        />
      );
      setActionButtonMobile(
        <NewPlacedBidMobile
          yesText="Edit Bid"
          noText="Retract Bid"
          OpenModalBoxStages={OpenModalBoxStages}
          stagename="New"
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Retracted") {
      setAboutUI(
        <NewPlacedBid yesText="Edit Bid" noText="Bid Retracted" disableState />
      );
      setActionButtonMobile(
        <EditRetractMobile
          disabled={true}
          yesText="Edit Bid"
          noText="Retracted Bid"
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Accepted") {
      setAboutUI(
        <AcceptedPlacedBid
          yesText="Edit Bid"
          noText="Retract Bid"
          disableState="true"
          Reason={"Your Bid got accepted by the employer."}
          stage
          ExtraBar
        />
      );
      setActionButtonMobile(
        <EditRetractMobile
          disabled={true}
          ExtraBar
          yesText="Edit Bid"
          noText="Retract Bid"
          disabledyellow
          lowerText="Your Bid got accepted by the employer."
        />
      );
    }
    if (BidDetailData?.ranking?.BidStatusName === "Declined") {
      setAboutUI(
        <AcceptedPlacedBid
          yesText="Edit Bid"
          noText="Retract Bid"
          disableState="true"
          ExtraBar
          SecondStage
          Reason="Declined"
          secondReason={getDeclineReason("declineReasonsPlaced",BidDetailData?.DeclineReasonId)}
        />
      );
      setActionButtonMobile(
        <EditRetractMobile
          screeningdisplay="True"
          disabled={true}
          disabledyellow
          message1="Declined"
          yesText="Edit Bid"
          noText="Retract Bid"
          lowerText={getDeclineReason("declineReasonsPlaced", BidDetailData?.DeclineReasonId)}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Interview") {
      setAboutUI(
        <ScreeningAndSchedule
          dimensions={dimensions}
          firstButton="Accept"
          secondButton="Decline"
          ThirdButton="Request to reschedule"
          Reschedule
          OpenModalBoxStages={OpenModalBoxStages}
          setInterviewTime={setInterviewTime}
          stagename="Interview"
          Interviewselecter
          AcceptButton={AcceptButton}
          CalendraModal={() => setModalVisibleBidCalender(true)}
          isPlacedBidsStatus
          errorMessage={ErrorMessagePlaceBid}
        />
      );
      setActionButtonMobile(
        <EditRetractMobile
          yesText="Accept"
          noText="Decline"
          Interviewselecter
          AcceptInterview={AcceptInterview}
          stagename="Interview"
          setInterviewTime={setInterviewTime}
          OpenModalBoxStages={OpenModalBoxStages}
          AcceptButton={AcceptButton}
          CalendraModal={() => setModalVisibleBidCalender(true)}
          isPlacedBidsStatus
          Reschedule
          errorMessage={ErrorMessagePlaceBid}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Interview Accepted") {
      const time = moment(
        BidDetailData?.interview?.ApprovedScheduledTiming?.StartTime
      ).format("dddd , MMMM DD , YYYY");

      setAboutUI(
        <ScreeningAndSchedule
          screeningdisplay="True"
          uppertext="Interview Scheduled On"
          lowerText={time}
          disabledAccepted="disabledAccepted"
          disableState
          dimensions={dimensions}
          firstButton="Accepted"
          secondButton="Decline"
          ThirdButton="Request to reschedule"
          Reschedule
          iconCal
          stagename="Interview Accepted"
          CalendraModal={() => setModalVisibleBidCalender(true)}
          OpenModalBoxStages={OpenModalBoxStages}
        />
      );
      setActionButtonMobile(
        <EditRetractMobile
          message1="Interview Scheduled On"
          screeningdisplay
          message2={time}
          yesText="Accepted"
          noText="Decline"
          disabledAccepted
          CalendraModal={() => setModalVisibleBidCalender(true)}
          stagename="Interview Accepted"
          OpenModalBoxStages={OpenModalBoxStages}
          RescheduledAccepted
          iconcal
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Interview Declined" || BidDetailData?.ranking?.BidStatusName === "Interview Cancelled") {
      setAboutUI(
        <ScreeningAndSchedule
          dimensions={dimensions}
          uppertext="Interview Declined by You / Employer"
          lowerText={getDeclineReason(
            "Interview",
            BidDetailData?.interview?.DeclineReasonId
          )}
          firstButton="Accept"
          secondButton="Declined"
          ThirdButton="Request to reschedule"
          screeningdisplay="True"
          stagename="Interview Declined"
          OpenModalBoxStages={OpenModalBoxStages}
          Reschedule
          disableState
          disableStateScreening
        />
      );
      setActionButtonMobile(
        <EditRetractMobile
          message1="Interview Declined by You / Employer"
          lowerText={getDeclineReason("Interview", BidDetailData?.interview?.DeclineReasonId)}
          screeningdisplay
          yesText="Accept"
          noText="Declined"
          disabledyellow
          Reschedule
          disabled
          MobileDisable={"MobileDisable"}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Offer") {
      setAboutUI(
        <ScreeningAndSchedule
          dimensions={dimensions}
          uppertext="View Offer"
          // lowerText="OfferLetter.pdf"
          isPlacedBidsStatus={true}
          lowerText={BidDetailData?.offers?.Attachments[0]?.FileName}
          firstButton="Accept"
          secondButton="Decline"
          screeningdisplay="True"
          stagename="Offer"
          OpenModalBoxStages={OpenModalBoxStages}
          AcceptButton={AcceptButton}
          disableStateScreening
          icon
        />
      );
      setActionButtonMobile(
        <EditRetractMobile
          message1="View Offer"
          lowerText={BidDetailData?.offers?.Attachments[0]?.FileName}
          screeningdisplay
          yesText="Accept"
          noText="Decline"
          icon
          disabledyellow
          stagename="Offer"
          OpenModalBoxStages={OpenModalBoxStages}
          AcceptButton={AcceptButton}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Offer Accepted") {
      setAboutUI(
        <ScreeningAndSchedule
          dimensions={dimensions}
          isPlacedBidsStatus={true}
          uppertext="Veiw Offer"
          lowerText={BidDetailData?.offers?.Attachments[0]?.FileName}
          firstButton="Accepted"
          secondButton="Decline"
          screeningdisplay="True"
          disableStateScreening
          disableState
          icon
        />
      );
      setActionButtonMobile(
        <EditRetractMobile
          message1="View Offer"
          lowerText={BidDetailData?.offers?.Attachments[0]?.FileName}
          screeningdisplay
          yesText="Accepted"
          noText="Decline"
          icon
          disabledyellow
          disabled
        />
      );
      setExtraSection(<CompleteCredentialing BidDetailData={BidDetailData} />);
    }

    if (BidDetailData?.ranking?.BidStatusName === "Offer Declined") {
      setAboutUI(
        <ScreeningAndSchedule
          dimensions={dimensions}
          uppertext="Offer Declined"
          // lowerText="Accepted Another Offer"
          lowerText={BidDetailData?.offers?.DeclineReason}
          firstButton="Accept"
          secondButton="Declined"
          screeningdisplay="True"
          disableStateScreening
          disableState
        />
      );
      setActionButtonMobile(
        <EditRetractMobile
          message1="Offer Declined"
          // message2="Accepted another offer"
          lowerText={BidDetailData?.offers?.DeclineReason}
          screeningdisplay
          yesText="Accept"
          noText="Declined"
          disabledyellow
          disabled
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Profile Submitted") {
      setAboutUI(
        <ScreeningAndSchedule
          uppertext="Profile Submitted On"
          dimensions={dimensions}
          screeningDate={BidDetailData?.interview?.ApprovedScheduledTiming}
          firstButton="Edit Bid"
          secondButton="Retract Bid"
          disableState
          screeningdisplay="True"
          disableStateScreening
        />
      );
      setActionButtonMobile(
        <EditRetractMobile
          message1="Profile Submitted On"
          message2={BidDetailData?.AcceptedDate}
          screeningdisplay
          disabledyellow
          yesText="Edit Bid"
          noText="Retract Bid"
          disabled
          iconcal
        />
      );
    }
    if (BidDetailData?.ranking?.BidStatusName === "Hired") {
      setAboutUI(
        <OfferLetter isPlacedBidsStatus={true} disableState="False" />
      );
      setActionButtonMobile(<OfferLetter isPlacedBidsStatus={true} />);
      // setActionButtonMobile(<ChatButtonMobile />);
    }
    // if (BidDetailData?.ranking?.BidStatusName === "Credentialing") {
    //   setAboutUI(<OfferLetter isPlacedBidsStatus={true} />);
    //   setExtraSection(<CompleteCredentialing />);
    // }
    if (BidDetailData?.ranking?.BidStatusName === "On Billing") {
      setAboutUI(<OfferLetter isPlacedBidsStatus={true} />);
      setActionButtonMobile(<OfferLetter isPlacedBidsStatus={true} />);
      setExtraSection(
        <WelcomeOnboard
          image={upperIconDesktop}
          message1="On Billing"
          message2="Congrats!"
          message3="You’re moved to “on billing” for this gig."
          // Duration="MM/DD/YYYY - MM/DD/YYYY"
          Duration={`${moment(BidDetailData?.offers?.FromDate).format(
            "MMM DD , YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMM DD , YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }
    if (BidDetailData?.ranking?.BidStatusName === "Off Billing") {
      setAboutUI(<OfferLetter isPlacedBidsStatus={true} />);
      setActionButtonMobile(<OfferLetter isPlacedBidsStatus={true} />);
      setExtraSection(
        <WelcomeOnboard
          image={DownIcon}
          message1="Off Billing"
          message2="Great job completing the gig"
          message3="You’re now moved to “off billing"
          // Duration="MM/DD/YYYY - MM/DD/YYYY"
          Duration={`${moment(BidDetailData?.offers?.FromDate).format(
            "MMM DD , YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMM DD , YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }
    if (BidDetailData?.ranking?.BidStatusName === "Billing Extend") {
      setAboutUI(<OfferLetter isPlacedBidsStatus={true} />);
      setActionButtonMobile(<OfferLetter isPlacedBidsStatus={true} />);
      setExtraSection(
        <WelcomeOnboard
          image={upperIconDesktop}
          message1="Billing Extended"
          message2="Congrats!"
          message3="The employer extended your billing"
          // Duration="MM/DD/YYYY - MM/DD/YYYY"
          Duration={`${moment(BidDetailData?.offers?.FromDate).format(
            "MMM DD , YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMM DD , YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }
    if (BidDetailData?.ranking?.BidStatusName === "Canceled") {
      setAboutUI(<OfferLetter isPlacedBidsStatus={true} />);
      setActionButtonMobile(<OfferLetter isPlacedBidsStatus={true} />);
      setExtraSection(
        <DeclineStatus
          message1="Canceled"
          message2="Sorry!"
          message3="Your employer moved you to canceled"
          // Duration="MM/DD/YYYY - MM/DD/YYYY"
          Duration={`${moment(BidDetailData?.offers?.FromDate).format(
            "MMM DD , YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMM DD , YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }
    if (BidDetailData?.ranking?.BidStatusName === "Terminated") {
      setAboutUI(<OfferLetter isPlacedBidsStatus={true} />);
      setActionButtonMobile(<OfferLetter isPlacedBidsStatus={true} />);
      setExtraSection(
        <DeclineStatus
          message1="Terminated"
          message2="Sorry!"
          message3="Your employer moved you to Terminated"
          Duration={`${moment(BidDetailData?.offers?.FromDate).format(
            "MMM DD , YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMM DD , YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }

    return () => {
      setAboutUI(null);
      setActionButtonMobile(null);
      setExtraSection(null);
    };
  }, [BidDetailData, ErrorMessagePlaceBid]);

  useUpdateLogger("BidDetail", BidDetailData);
  const RescheduleInterviewPlacedBid = () => {
    let startTime = DateValueStage ? DateValueStage : getRescheduleModalDateValues(0);
    let startTime1 = DateValueStage1 ? DateValueStage1 : getRescheduleModalDateValues(1);
    let startTime2 = DateValueStage2 ? DateValueStage2 : getRescheduleModalDateValues(2);
    let endTime = Endtime ? Endtime : moment(startTime).add(1,"hours");
    let endTime1 = Endtime1 ? Endtime1 : moment(startTime1).add(1,"hours");
    let endTime2 = Endtime2 ? Endtime2 : moment(startTime2).add(1,"hours");

    dispatch(
      InterviewScheduleAcceptedInit({
        id: BidDetailData?.InterviewId,
        idSearch: bidId,
        reschedule: true,
        body: {
          ReScheduledTimings: [
            {
              StartTime: startTime,
              EndTime: endTime,
            },
            {
              StartTime: startTime1,
              EndTime: endTime1,
            },
            {
              StartTime: startTime2,
              EndTime: endTime2,
            },
          ],
          InterviewStatus: "Rescheduled",
          Message: "You have Rescheduled the interview",
        },
      })
    );
    setModalVisibleBidCalender(false);
  };
  function AcceptInterview() {
    // console.log("submit1", BidDetailData);

    if (declinedReason) {
      dispatch(
        InterviewScheduleAcceptedInit({
          id: BidDetailData?.InterviewId
            ? BidDetailData?.InterviewId
            : BidDetailData?.ProfileId,
          idSearch: bidId,

          reschedule: true,
          body: {
            InterviewStatus: "Rejected",
            Message: "You have Rejected the interview",
            ReasonId: declinedReason,
            DeclineReason: ReasonToDecline,
          },
        })
      );
    } else {
      setInterviewSendRequest(true);
    }
    setModalVisibleBidDeclinedPlacedBid(false);
    // setTimeout(() => {
    //   SendRequestDetailPlacedBid();
    // }, 1000);
  }

  function AcceptOfferBid() {
    // // console.log("submit011");

    if (declinedReason) {
      dispatch(
        acceptOffer({
          id: BidDetailData?.ProposalId,
          Status: "Decline",
          BidType: "Proposal",
          DeclineReason: ReasonToDecline,
          ReasonId: declinedReason,
        })
      );
    } else {
      setInterviewSendRequest(true);
    }
    setModalVisibleBidDeclinedPlacedBid(false);
    setTimeout(() => {
      SendRequestDetailPlacedBid();
    }, 1000);
  }

  function submitDeclinedReason() {
    // console.log("submit2");
    if (!declinedReason) {
      dispatch(
        updateDeclinedReasonsReceivedBids({
          id: BidDetailData.InterviewId,
          body: {
            Message: " Bid by talent",
            BidStatusName: "Accepted",
          },
        })
      );
    } else {
      dispatch(
        updateDeclinedReasonsReceivedBids({
          id: BidDetailData.InterviewId,
          body: {
            Message: "Update Bid by talent",
            BidStatusName: "Declined",
            DeclineReason: declinedReason,
          },
        })
      );
    }
    setModalVisibleBidDeclinedPlacedBid(false);
    setTimeout(() => {
      SendRequestDetailPlacedBid();
    }, 3000);
  }
  // console.log("BidDetailData", BidDetailData);
  const getRescheduleModalDateValues = (index) => {
    let retunData;
    if(BidDetailData?.interview?.ReScheduledTimings && BidDetailData?.interview?.ReScheduledTimings.length){
      let rescheduledTimings = BidDetailData?.interview?.ReScheduledTimings;
      retunData = rescheduledTimings[rescheduledTimings.length-1]?.length ? 
        moment(rescheduledTimings[rescheduledTimings.length-1][index]?.StartTime)  : null;
    }
    else {
      let interviewScheduledTimings = BidDetailData?.interview?.Interviews_ScheduledTimings;
      retunData = interviewScheduledTimings && interviewScheduledTimings.length ? 
      moment(interviewScheduledTimings[index]?.StartTime) : null;
    }
    return retunData;
  }
  return (
    <div>
      {loading || interviewloader ? (
        <Loader height="40vh" />
      ) : (
        BidDetailData.ProposalId && (
          <BidDetail
            StageAboutUI={aboutUI}
            ActionButtonMobile={actionButtonMobile}
            ExtraSection={extraSection}
            bidData={BidDetailData}
            gigData={BidDetailData.gigs}
            stageRanking={BidDetailData.ranking}
            isPlacedBids={true}
          />
        )
      )}
      <Modal
        bodyStyle={{ padding: 0 }}
        width="21.25rem"
        visible={isModalVisibleBidDeclinedPlacedBid}
        closable={true}
        footer={null}
        // style={{ top: 45, padding: 0 }}
        onCancel={modalCancelPlacedBid}
        maskClosable={true}
        centered={true}
        className="declinedBidsModal"
      >
        {talentstagemodal === "New" ? (
          <div className="declineThisBid">Retract your Bid?</div>
        ) : (
          <div className="declineThisBid">
            {AcceptButtonModal === "Offer"
              ? "Accept Offer"
              : `Decline this ${Modaltitle}?`}
          </div>
        )}
        <div className="declinedThisdBidContent">
          {talentstagemodal === "Offers" ? (
            <div className="reasonForDeclined">
              Let us know the reason why you're declining
            </div>
          ) : null}
          <div className="reasonbox-list">
            {talentstagemodal === "offers" ? (
              <>
                {declinedReasonsBid.length > 0 &&
                  declinedReasonsBid.map((item) => (
                    <div
                      className={
                        item.ReasonId === declinedReason
                          ? "reasonbox reasonbox-selected"
                          : "reasonbox"
                      }
                      onClick={() => setDeclinedReason(item.ReasonId)}
                    >
                      {item.Reason}
                    </div>
                  ))}
                <div className="submitReason" onClick={submitDeclinedReason}>
                  Submit Reason
                </div>
              </>
            ) : null}
            {talentstagemodal === "Interview" ||
            talentstagemodal === "Interview Accepted" ||
            talentstagemodal === "Offer" ? (
              <>
                <div className="bid-decline-headline">
                  Please select a reason for declining this {talentstagemodal}
                </div>
                {declinedReasonsBid.length > 0 &&
                  declinedReasonsBid.map((item) => (
                    <div
                      className={
                        item.ReasonId === declinedReason
                          ? "reasonbox reasonbox-selected"
                          : "reasonbox"
                      }
                      onClick={() => {
                        setDeclinedReason(item.ReasonId);
                        setReasonToDecline(item.Reason);
                      }}
                    >
                      {item.Reason}
                    </div>
                  ))}
                <div
                  className="bid-decline-button talent-stages-button"
                  onClick={() => {
                    talentstagemodal === "Offer"
                      ? AcceptOfferBid()
                      : AcceptInterview();
                  }}
                >
                  Submit reason
                </div>
              </>
            ) : null}
            {AcceptButtonModal === "Offer" ? (
              <UploadFile
                setModal={setModalVisibleBidDeclinedPlacedBid}
                BidDetailData={BidDetailData}
              />
            ) : null}
            {talentstagemodal === "New" ? (
              <RetractedModal
                props={BidDetailData?._id}
                setModalVisibleBidDeclinedPlacedBid={
                  setModalVisibleBidDeclinedPlacedBid
                }
                setPlacedBidSendRequest={setPlacedBidSendRequest}
              />
            ) : null}
          </div>
        </div>
      </Modal>
      <Modal
        bodyStyle={{ padding: 0 }}
        width="22.5rem"
        visible={isModalVisibleBidCalender}
        closable={true}
        footer={null}
        title="Rechedule Interview"
        onCancel={modalCancelCalender}
        maskClosable={true}
        centered={true}
        className="Talent-Stage-DetailPage-Modal"
      >
        <div className="Bid-decline-model">
          <div className="Manage-bid-decline-headline bid-decline-headline-rechedule-talent-stages">
            Let's find a better time to set up your interview.
          </div>
          <div className="Manage-bid-decline-headline bid-decline-headline-rechedule-talent-stages2">
            Propose up to 3 of your available interview times that suit you.
            <div className="bid-decline-headline-text-center"></div>
          </div>
          <div className="bid-decline-options">
            <DatePicker
              placeholder="Choose a date and time"
              // format="dddd, MMMM d, YYYY HH:mm A"
              disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
              suffixIcon={<img src={CalendarIcon} alt="Calender" />}
              style={{ width: "100%", marginBottom: "0.635rem" }}
              showTime={{
                defaultValue: moment("00:00:00", "HH:mm:ss"),
              }}
              onOk={(e) => dateHandlerStages(e)}
              value = {
                DateValueStage ? moment(DateValueStage) :
                getRescheduleModalDateValues(0)
              }
            />
          </div>
          <div className="bid-decline-options">
            <DatePicker
              placeholder="Choose a date and time"
              // format="dddd, MMMM d, YYYY HH:mm A"
              disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
              suffixIcon={<img src={CalendarIcon} alt="Calender" />}
              style={{ width: "100%", marginBottom: "0.635rem" }}
              showTime={{
                defaultValue: moment("00:00:00", "HH:mm:ss"),
              }}
              onOk={(e) => dateHandlerStages1(e)}
              value = {
                DateValueStage1 ? moment(DateValueStage1) :
                getRescheduleModalDateValues(1)
              }
            />
          </div>
          <div className="bid-decline-options">
            <DatePicker
              placeholder="Choose a date and time"
              // format="dddd, MMMM d, YYYY HH:mm A"
              disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
              suffixIcon={<img src={CalendarIcon} alt="Calender" />}
              style={{ width: "100%", marginBottom: "0.635rem" }}
              showTime={{
                defaultValue: moment("00:00:00", "HH:mm:ss"),
              }}
              onOk={(e) => dateHandlerStages2(e)}
              value = {
                DateValueStage2 ? moment(DateValueStage2) :
                getRescheduleModalDateValues(2)
              }
            />
          </div>
          <div
            className="bid-decline-button bid-rechedule-button bid-decline-button-talent-stages"
            onClick={RescheduleInterviewPlacedBid}
          >
            Send rechedule request
          </div>
        </div>
      </Modal>
    </div>
  );
};
// }

export default PlacedBidsDetailPage;
