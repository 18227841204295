import React, { useState, useEffect } from "react";
import "./SkillListingPanel.css";
import { useDispatch, useSelector } from "react-redux";

import ManageSkillListingTwo from "../../Manage_SkillListing/ManageSkillListingTwo";
import Loader from "../../../components/Loader/Loader";

import plus from "../../../assets/icons/createSkillPlus.svg";

import { fetchUserSkillListing } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import ProfileRating from "./RatingReviews/ProfileRating";
import ToolTips, { ToolTipsAlert } from "../../../components/ToolTip/ToolTips";
import { Alert } from "antd";

const SkillListingPanel = ({ redirect = null }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const skilllistings = useSelector(
    (state) => state.skillListingReducer.skillListings.data
  );
  const EditData = useSelector(
    (state) => state.skillListingReducer.currentSkillListing
  );
  const loadingSkillListing = useSelector(
    (state) => state.skillListingReducer.loadingSkillListing
  );

  const [dimensions, setDimensions] = useState(window.innerWidth);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);
  
  
  const page = useSelector((state) => state.skillListingReducer.page);
  const perPage = useSelector((state) => state.skillListingReducer.perPage);

  useEffect(()=>{
    dispatch(fetchUserSkillListing("Published",page,perPage))
  },[])

  useEffect(()=>{
    dispatch(fetchUserSkillListing("Published",page,perPage))
  },[EditData])

  return (
    <div className="skilllisting-panel-page ">
      {dimensions < 768 && <div className="divider-line-skill-listing"></div>}
      <section className="skilllisting-title-counts">
        <div>
          <span className="title">Skill listings</span>
          <span className="count">{skilllistings?.length}</span>
        </div>
        {dimensions < 768 && (
          <div
            className="viewAll-p"
            onClick={() => history.push("/manageskilllisting")}
          >
            View all
          </div>
        )}
      </section>
      <section className="skilllisting-panel">
        {loadingSkillListing && <Loader size="2.5rem" height="15vh" />}
        {!loadingSkillListing && (
          <>
            {skilllistings?.length > 0 &&
              skilllistings.map((data, i) => {
                if (dimensions < 768 && i > 1) return;
                return (
                  <ManageSkillListingTwo
                    skillListings={data}
                    skilllength={skilllistings.length}
                    publishedData={skilllistings}
                    deletedData={skilllistings}
                    key={data._id}
                  />
                );
              })}
            <div
              className="create-skilllisting-tab"
              onClick={() =>
                history.push({
                  pathname: "/create-skilllisting",
                  state: redirect,
                })
              }
            >
              <img src={plus} alt="add" />
              <span>Create a new skill listing</span>
            </div>
          </>
        )}
      </section>
      <div className="ProfileTooltipsSection" ><ToolTipsAlert title= "Create a skill listing to land the exact gig you want. List your skills and sell your expertise. Create multiple skill listings to start choosing from a pool of bids placed by employers that want to hire you." /></div>
      {/* <ProfileRating/> */}
    </div>
  );
};

export default SkillListingPanel;
