import { types } from "../action-types";
const initialState ="";

const onClearFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_FILTER_INIT:
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export default onClearFilterReducer;
