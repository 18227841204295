import React, { useEffect, useState, useRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchChatsHistory, updateBid } from "../../../../../../redux/actions";
import { RiCheckDoubleFill } from "react-icons/ri";
import MediaQuery from "react-responsive";
import RejectedIcon from "../../../../../../assets/icons/Group 19971.png";
import acceptedIcon from "../../../../../../assets/icons/Group 19971.svg";
import "./bidAccepted.css";

const BidAcceptedCard = (props) => {
  const [ranking, setRanking] = useState(null);
  const [isPlaceBid, setIsPlaceBid] = useState(null);
  const [reason, setReason] = useState([]);
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const bidStatus = useSelector(
    (state) => state.getChatHistoryReducer.bidStatus
  );
  const userProfile = useSelector((state) => state.profileReducer.user);
  const loading = useSelector(
    (state) => state.getChatHistoryReducer.historyLoading
  );

  const declineReasons = [
    {
      id: 1,
      Reason: "Accepted another candidate",
    },
    {
      id: 2,
      Reason: "Not interested in the candidate",
    },
    {
      id: 3,
      Reason: "Not interested in this Gig",
    },
    {
      id: 4,
      Reason: "Asking payrate is too high",
    },
  ];

  const declineReasons2 = [
    {
      id: 5,
      Reason: "Skills not a match",
    },
    {
      id: 2,
      Reason: "On Assignment",
    },
    {
      id: 3,
      Reason: "Not interested in the location",
    },
    {
      id: 4,
      Reason: "Estimated pay rate is too low",
    },
  ];

  useEffect(() => {
    setIsPlaceBid(props?.data?.SourceTypeId === 1 ? true : false);
  }, [props?.data]);

  const submitProfile = () => {
    dispatch(
      updateBid({
        id: props.bid_id,
        BidStatusName: "Profile Submitted",
        SkillListingId: props?.SkillListing_id ?? null,
      })
    );
    // fetchChat(props.data.ConversationId);
  };

  useEffect(() => {
    let test;
    let id =
      // props?.data?.SenderUser?.AccountId === userProfile?.profile?.AccountId
      // ?
      props?.data?.BidCard?.DeclineReasonId
        ? props?.data?.BidCard?.DeclineReasonId
        : props?.data?.ProposalCard?.DeclineReasonId
        ? props?.data?.ProposalCard?.DeclineReasonId
        : props?.data?.OfferCard?.DeclineReasonId;
    // if (props?.data?.SenderUser?.AccountId === userProfile?.profile?.AccountId) {
    test = declineReasons?.filter((d) => d?.id == id);
    if (
      props?.data?.SenderUser?.AccountId === userProfile?.profile?.AccountId
    ) {
      test = declineReasons2?.filter((d) => d?.id == id);
    }
    // } else if (props?.data?.SenderUser?.AccountId !== userProfile?.profile?.AccountId) {
    // test = declineReasons2?.filter((d) => d?.id == id);
    // }
    setReason(test);
  }, [props?.data]);

  function fetchChat(params) {
    setTimeout(() => {
      dispatch(fetchChatsHistory(params));
    }, 800);
  }

  return (
    <div>
      {props?.status === "Accepted Bid" && (
        <>
          <div
            className={`chat-container1 mt15px ${
              isPlaceBid && "chat-container-receivedbid"
            }`}
            ref={messagesEndRef}
          >
            <div className="sender-threat"></div>
            <div className="user-threat">
              <MediaQuery maxWidth={768}>
                <div className="Accepted_Icon">
                  <img src={acceptedIcon} alt="" />
                </div>
                <div className="Accepted">Accepted</div>
              </MediaQuery>
              <div className="congratulation">
                {userProfile?.profile?.ProfessionCode != "HC" && isPlaceBid
                  ? "The employer is notified of your interest on this gig."
                  : userProfile?.profile?.ProfessionCode != "HC" && !isPlaceBid
                  ? "Great News! The employer is interested."
                  : "Congrats!"}
              </div>
              <div className="acceptContainer">
                {userProfile?.profile?.ProfessionCode == "HC"
                  ? isPlaceBid
                    ? "You accepted the bid."
                    : "Congrats! The employer accepted your bid. We’ll notify you once we submit your profile to the facility."
                  : isPlaceBid
                  ? <div>Your 3D Resume is only <span style={{fontWeight: 'bold'}}>60% complete</span>. While we wait to hear from them, lets get it to 100%. Don't miss because of an incomplete profile.</div>
                  : <div>Your 3D Resume is only <span style={{fontWeight: 'bold'}}>60% complete</span>. Don't miss because of an incomplete profile.</div>}
                {/* {isPlaceBid
                  ? "You accepted the bid."
                  : "Congrats! The employer accepted your bid. We’ll notify you once we submit your profile to the facility."} */}
              </div>
              <div className="time-stamp">
                {/* <div className="time-text-employer">1:48 pm</div> */}
                {/* <div className="tick-icon">
                  <RiCheckDoubleFill />
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}

      {props?.status === "Declined Bid" && (
        <>
          <div className="chat-profile-submit" ref={messagesEndRef}>
            <div className="user-threat">
              <MediaQuery maxWidth={768}>
                <div className="Accepted_Icon">
                  <img src={RejectedIcon} alt="" />
                </div>
                <div className="Accepted Terminated">
                  {isPlaceBid ? "Offer Declined" : "Bid Declined"}
                </div>
              </MediaQuery>
              <div className="congratulation">
                Declined {window.innerWidth > 768 && "!"}
              </div>
              <div className="accept">
                {bidStatus.includes("Accepted Bid") ||
                bidStatus.includes("Profile Submitted")
                  ? "Unfortunately, the employer declined your bid."
                  : props?.data?.SenderUser?.AccountId ===
                    userProfile?.profile?.AccountId
                  ? "Bid has been declined by you"
                  : "Your bid has been declined by the Employer"}
              </div>
              <div className="reason-container">
                <div className="date">Reason</div>
                <div className="time">
                  {/* {props.data?.BidCard?.DeclineReason} */}

                  {reason.length > 0 && reason[0].Reason}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {props.status == "Terminated" && (
        <div>
          <div>
            <strong>Sorry!</strong> <br />
            {props.MessageText}
          </div>
          <div
            style={{
              background: "#fff",
              padding: "2.5%",
              marginTop: "2%",
              borderRadius: "5px",
            }}
          >
            <Row>
              <div>Start date</div>
              <div>dd/mm/yyyy</div>
            </Row>
            <Row>
              <div>End date</div>
              <div>dd/mm/yyyy</div>
            </Row>
            <Row>
              <div>Estimated pay package</div>
              <div>USD 73/hr</div>
            </Row>
            <Row>
              <div>Contract duration</div>
              <div>13 months</div>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};
export default BidAcceptedCard;
