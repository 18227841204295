import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchInboxDataAPI } from "../../../API/API";
import {
  fetchInboxData,
  postFavoriteGigCard,
  removeFavoriteGigCard,
} from "../../../redux/actions";
import "./MessageBox.css";
import moment from "moment";
import { Badge, Avatar, Rate } from "antd";
import { Link, useHistory } from "react-router-dom";
import StarIcon from "../../../assets/icons/StarIcon.svg";
import { IoIosStar } from "react-icons/io";
import { BsStar } from "react-icons/bs";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";

const MessageBox = (
  {
    data,
    search,
    setMessages,
    setClicked,
    setreciepentName,
    setRecipientUserPictureUrl,
    hideFavIcon = false,
  },
  props
) => {
  // const messagePusher = () => {
  //   history.push("/");
  const history = useHistory();

  const { bid } = props;
  // console.log("dataa", data);
  const dispatch = useDispatch();

  const receivedBids = useSelector((state) => state.messagesReducer.inboxData);
  const receivedBids2 = useSelector(
    (state) => state.messagesReducer.inboxDataParameters
  );
  // console.log("hello3", receivedBids2);
  const [favorite, setFavorite] = useState(false);
  const [bidType, setBidType] = useState(null);

  const addFavorite = () => {
    dispatch(postFavoriteGigCard(data?.SecondarySourceId, bidType));
  };

  // console.log("hello", data?.SecondarySourceTypeName);

  const removeFavorite = () => {
    dispatch(removeFavoriteGigCard(data?.SecondarySourceId));
  };
  useEffect(() => {
    // console.log("data2", data?.IsFavorite);
    setFavorite(data?.IsFavorite);
  }, [data?.IsFavorite]);

  // const addRemoveFavorite = () => {
  //   if (!data?.IsFavorite) {
  //     dispatch(postFavoriteGigCard(data?._id));
  //   } else {
  //     dispatch(removeFavoriteGigCard(data?._id));
  //   }
  // };

  useEffect(() => {
    if (data?.SecondarySourceTypeName === "Bid") {
      setBidType("Bid");
    } else {
      setBidType("Proposal");
    }
  }, [data]);

  // console.log("props2", receivedBids);

  // useUpdateLogger("datatttt", data);

  return (
    // <Link to={`/messages`}>
    <div
      className={
        search?.length > 0
          ? "Message-box-container-search"
          : "Message-box-container"
      }
      onClick={() =>
        history.push({
          pathname: `/messages`,
          state: {
            openConvo: true,
            conversationId: data._id,
          },
        })
      }
    >
      <div
        className={
          setMessages?.length > 0
            ? `Message-box-container-message ${
                hideFavIcon && "searchMobiledropdown"
              }`
            : "Message-box-container-box"
        }
        onClick={() => {
          //e.stopPropagation();
          setreciepentName(data?.SenderUserName);
          setRecipientUserPictureUrl(data?.SenderUserPictureUrl);
          setClicked(true);
        }}
      >
        <div className="Message-box-profff">
          {setMessages?.length > 0 && !hideFavIcon ? (
            <div
              onClick={(e) => {
                e.preventDefault();
                setFavorite(!favorite);
                if (!favorite) {
                  e.stopPropagation();

                  addFavorite();
                } else {
                  e.stopPropagation();

                  removeFavorite();
                }
                // addFavorite();
              }}
            >
              {favorite ? (
                <AiFillStar
                  style={{
                    marginTop: "11.5px",
                    color: "#ffba08",
                    width: "23px",
                    height: "22px",
                    marginLeft: "1px",
                  }}
                />
              ) : (
                <AiOutlineStar
                  style={{
                    marginTop: "11.5px",
                    fontSize: "15px",
                    color: "#aaa",
                    width: "23px",
                    height: "22px",
                    marginLeft: "1px",
                  }}
                />
              )}
            </div>
          ) : null}

          <div className="Message-box-profile ">
            <div
              className={
                setMessages?.length > 0
                  ? "profile-img-candidate12"
                  : "profile-img-candidate"
              }
            >
              {data?.SenderUserPictureUrl ? (
                <img
                  className={
                    setMessages?.length > 0
                      ? "profile-img-inner-pic12"
                      : "profile-img-inner-pic"
                  }
                  src={`${process.env.REACT_APP_S3_URL}${
                    data?.SenderUserPictureUrl?.charAt(0) !== "/" ? "/" : ""
                  }${data?.SenderUserPictureUrl}`}
                />
              ) : (
                <div
                  className={
                    setMessages?.length > 0
                      ? "profile-img-inner-name12"
                      : "profile-img-inner-name"
                  }
                >
                  {data?.SenderUserName?.charAt(0)}
                </div>
              )}
            </div>
            <div
              className={
                setMessages?.length > 0
                  ? "Ellipse-message-dot12"
                  : "Ellipse-message-dot"
              }
            ></div>
          </div>
          <div
            className={
              setMessages?.length > 0
                ? "candidate-profile-data12"
                : "candidate-profile-data"
            }
          >
            {search?.length > 0 ? (
              <div className="candidate-profile-name">{data?.JobTitle}</div>
            ) : (
              <div className="candidate-profile-name">{data?.JobTitle}</div>
            )}
            <div
              className={
                setMessages?.length > 0
                  ? "candidate-message-txt12"
                  : "candidate-message-txt"
              }
            >
              {search?.length > 0 ? (
                <span className="last-seen">
                  Last seen {moment(data.Created).fromNow()}
                </span>
              ) : (
                <div className="set-content-txt set-data-timing12">
                  {data?.MessageText}{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="messageMeta">
          <div>
            {search?.length > 0 ? null : (
              <div className="set-data-timing-txt">
                {moment(data.Created).fromNow().replace(" ago", "")}
              </div>
            )}
          </div>
          {search?.length > 0 ? null : (
            <div>
              {data?.countUnRead > 0 ? (
                <span className="countPending">{data?.countUnRead}</span>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    </div>
    // </Link>
  );
};

export default MessageBox;
