import React, { useState, useEffect } from "react";
import "./EmailVerification.css";
import warningAlertIcon from "../../assets/icons/ci_error.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailVerification } from "../../redux/actions";

const EmailVerification = () => {
  const dispatch = useDispatch();

  const [reFlag, setReFlag] = useState(false);

  let userProfile = useSelector((state) => state.profileReducer.user);
  const firstName = userProfile?.profile?.FirstName
    ? userProfile?.profile?.FirstName
    : "Talent";
  const lastName = userProfile?.profile?.LastName
    ? userProfile?.profile?.LastName
    : "Name";
  const Name =
    firstName?.charAt(0).toUpperCase() +
    firstName?.slice(1).toLowerCase() +
    " " +
    lastName?.charAt(0).toUpperCase() +
    lastName?.slice(1).toLowerCase();
  // let authToken = useSelector((state) => state.authReducer.user.authToken);

  const sendMail = () => {
    if (reFlag) return;

    setReFlag(true);
    dispatch(sendEmailVerification({ type: "Resend" }));
    setTimeout(() => setReFlag(false), 30000);
  };

  return (
    <>
      <div className="EVContainer">
        <div className="EVInfo">
          <img src={warningAlertIcon} alt="" />
          <p>
            Hi {Name}, We sent an email to
            <span class="EmailInfo"> {userProfile.email} </span> Please Check
            your mail and click on activate to activate your account
          </p>
        </div>
        <div
          className={`EVRButton ${reFlag && "SentEV"} ${
            reFlag ? "click-disable" : "clickable"
          }`}
          onClick={sendMail}
        >
          {reFlag ? "Email Sent" : "Resent Email"}
          {/* {reFlag ? "Activation email sent" : "Resend activation email"} */}
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
