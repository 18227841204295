import React from "react";
import { useParams } from "react-router-dom";
import ChatWithRecruitor from "../../../assets/icons/ChatWithRecruitor.svg";
import ChatRecruitorWhite from "../../../assets/icons/chatRecruitorWhite.svg";
import { useHistory } from "react-router-dom";
import { BiCalendar } from "react-icons/bi";
import moment from "moment";
import { FiPaperclip } from "react-icons/fi";

const AcceptedPlacedBid = ({ secondReason, disableState, yesText, noText, Reason, stage, ExtraBar, SecondStage }) => {
    const history = useHistory();
    const { bidId } = useParams();
    // console.log(bidId, "bidId")

    return (
        <>
            {ExtraBar ? <section className="offerLetter-bid-details placebid-talentStage">
                <div className="viewOffer">{Reason}</div>
                {SecondStage ? <div className="offerletter placebid-offerletter">
                    {/* <FiPaperclip /> */}
                    {secondReason}
                </div> : null}
            </section> : null}
           
            <section
                className={
                    disableState
                        ? "interestedSection-about-job-disable"
                        : "interestedSection-about-job"
                }
            >
                <div
                    className={`interested-about-job ${disableState === true ? "click-disable" : "clickable"
                        }`}
                // onClick={editBidRoute}
                >
                    {yesText}
                </div>
                <div
                    className={`notInterested-about-job ${disableState === true ? "click-disable" : "clickable"
                        }`}
                // onClick={() => { OpenModalBoxStages(stagename) }}
                >
                    {noText}
                </div>
            </section>
        </>
    );
}

export default AcceptedPlacedBid;
