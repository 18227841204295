import React from "react";
import { useState } from "react";
import "./SkillListingTabs.css";

const SkillListingTabs = (props) => {
  const { tab, setTab, ProfessionCode } = props;
  const handlingFilterMenuItem = (val) => {
    setTab(val);
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  };

  return (
    <div className="SkillListingTabs-css">
      {props.bids ? (
        <section
          className={`section-title-sl ${tab === 0 && "selectedd"}`}
          onClick={() => handlingFilterMenuItem(0)}
        >
          <div className="bar-section-sl"></div>
          <div>Bid history</div>
        </section>
      ) : null}
      <section
        className={`section-title-sl ${tab === 1 && "selectedd"}`}
        onClick={() => handlingFilterMenuItem(1)}
      >
        <div className="bar-section-sl"></div>
        <div>Skill listing</div>
      </section>
      <section
        className={`section-title-sl ${tab === 2 && "selectedd"}`}
        onClick={() => handlingFilterMenuItem(2)}
      >
        <div className="bar-section-sl"></div>
        <div>Work history</div>
      </section>
      {ProfessionCode === "HC" && (
        <section
          className={`section-title-sl ${tab === 3 && "selectedd"}`}
          onClick={() => handlingFilterMenuItem(3)}
        >
          <div className="bar-section-sl"></div>
          <div>My credentialing</div>
        </section>
      )}
      <section
        className={`section-title-sl ${tab === 4 && "selectedd"}`}
        onClick={() => handlingFilterMenuItem(4)}
      >
        <div className="bar-section-sl"></div>
        <div>Education History</div>
      </section>
      {ProfessionCode === "IT" && (
        <section
          className={`section-title-sl ${tab === 5 && "selectedd"}`}
          onClick={() => handlingFilterMenuItem(5)}
        >
          <div className="bar-section-sl"></div>
          <div>Certifications & Awards</div>
        </section>
      )}
    </div>
  );
};

export default SkillListingTabs;
