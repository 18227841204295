import { types } from "../action-types";

const initialState = {
    loading: false,
    bids: [],
    error: {},
};

const proposalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_PROPOSALS_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.FETCH_PROPOSALS_SUCCESS:
            return {
                ...state,
                loading: false,
                bids: action.payload,
                error: {},
            };
        case types.FETCH_PROPOSALS_FAILURE:
            return {
                ...state,
                loading: false,
                bids: [],
                error: action.error,
            };
        case types.RETRACT_BID_SUCCESS:
            const totalbids = state.bids;
            const updatedbids = totalbids.filter(bid => bid._id !== action.payload._id);
            return {
                ...state,
                loading: false,
                bids: updatedbids,
                error: action.error,
            };
        default:
            return {...state,loading:false};
    }
};

export default proposalsReducer;
