import { RightOutlined } from '@ant-design/icons'
import { Avatar, Divider } from 'antd'
import React, { useState } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import "./RatingFormTwo.css"
const RatingformTwo = () => {
  const [dimensions, setDimensions] = useState(window.innerWidth);
  return (
    <> 
    <div className="g-details-header3">
               <div className='message-back'>
                <div   className="header-textss">
                  Messages
                </div>
                <div className="g-details-arrow2">
                  <RightOutlined className="arrow" />
                </div>
                <div  className="header-texts">
                  Rate
                </div>
                </div>
        <div className='tick_green'>
       <div style={{ color: "#fff" }}><BsFillCheckCircleFill style={{ color: "#118936" }} /></div> 
        </div>  
             
<div className='thank_tagline'>Thank you !</div>
<div className='thank_tag_descrip'>We are processing your review. Your reviews will appear  within 14 days, so we appreciate <br/> your patience. We will notify you when this is complete.</div>
   <div className='Review-other-assignmentss'>Review other assignments</div>          

 {dimensions > 768 ? (
 <div className='card_main_rating'>
<div className='rating_status_card'>
    <div className='rating_informations'>
    <div><Avatar size={50} style={{backgroundImage: "linear-gradient(242deg, #e45d5a 96%, #a13aad 11%)"}}> U </Avatar></div>
        <div className='talent_infos'>
        <div className='job_ttitles'>Gig title</div>
            <div className='employyer_name'>Employer name</div>
        </div>
    </div>

    <div className='rate_div_9'>
     <div className='rate_div_7' > 
     <div className='job_ttitle' >Ended on</div>  
      <div className='employyer_name'>11/08/2022</div>  
     </div>
      <div className='rate_div_4'>
        <div className='rate_div_2'>
        <div className='job_ttitle'>Estimated earnings</div> 
         <div className='employyer_name'>$7000</div> 
        </div>

        <div className='rate_div_3'>
        <div className='job_ttitle'>Status</div> 
         <div className='job_statuss'>Terminated</div> 
        </div>
         

              
        </div> 
    </div>
</div>
</div>
) : (
<div className='card_main_rating'>
<div className='rating_status_cards'>
    <div className='rating_informations'>
    <div><Avatar size={50} style={{backgroundImage: "linear-gradient(242deg, #e45d5a 96%, #a13aad 11%)"}}> U </Avatar></div>
        <div className='talent_infos'>
        <div className='job_ttitles'>Gig title</div>
            <div className='employyer_name'>Employer name</div>
           <div className='job_employer_main'>
            <div className='job_ttitle'> Total earnings</div> 
         <div className='employyer_name'>$7000</div> 
         </div>
        </div>
    </div>
    {dimensions < 768 ? (<hr className='liner_rating'/>) : null }

    <div className='rate_div_9'>
      <div className='job_ttitle_div'> 
     <div className='job_ttitle' >Ended On</div>  
      <div className='employyer_name'>11/08/2022</div>
      </div>
      <div className='job_statuss'>Terminated</div> 
        </div>
</div>
</div>
)}
  


                    </div>  
    </>
  )
}

export default RatingformTwo