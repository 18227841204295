import { types } from "../action-types";

const initialState = {
    loading: false,
    success: false,
    error: false,
};

const updateBidReducers = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_BID_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.UPDATE_BID_SUCCESS:
            return {
                ...state,
                loading: false,
                success:true,
                error: false,
            };
        case types.UPDATE_BID_FAILURE:
            return {
                ...state,
                loading: false,
                success:false,
                error: true,
            };
        case types.UPDATE_BID_RESET:
            return {
                ...state,
                loading: false,
                success:false,
                error: false,
            };
        default:
            return state;
    }
};
export default updateBidReducers;
