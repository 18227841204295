import React from 'react';
import './GigJobDetailsITUser.css'
import { getText } from '../../../../../shared/helpers';

const GigJobDetailsITUser = ({ Description }) => {
    
    // function containsHTMLEntities(htmlCode) {
    //     return /&[a-zA-Z0-9#]+;/?.test(htmlCode);
    // }

    var cache = {},
        character,
        e = document.createElement("div");

    const msgtxt = (html) => {
        return html?.replace(/([&][^&; ]+[;])/g, function (entity) {
            character = cache[entity];
            if (!character) {
                e.innerHTML = entity;
                if (e.childNodes[0])
                    character = cache[entity] = e.childNodes[0].nodeValue;
                else character = "";
            }
            return character;
        });
    }

    return (
        <div className='GigJobDetailsITUser-Cantainer' >
            <div className="Skill-PieChart-Component" >
                <div className='GigJobDetailsITUser-title'>Gig details</div>
                <div
                    className='GigJobDetailsITUser-Cantainer-Para'
                    dangerouslySetInnerHTML={{
                        __html: msgtxt(Description),
                    }}
                >
                </div>
            </div>
        </div>
    );
};

export default GigJobDetailsITUser;