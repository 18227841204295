import { types } from "../action-types";
const initialState = {
  loading: false,
  success: [],
  error: {},
  ScheduleInterview: [],
};

const interviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INTERVIEW_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: {},
      };

    case types.FETCH_INTERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        success: [],
        error: action.error,
      };

    case types.INTERVIEW_ACCEPTED_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.INTERVIEW_ACCEPTED_SUCCESS:
      return {
        ...state,
        loading: false,
        ScheduleInterview: action.payload,
      };
    case types.INTERVIEW_ACCEPTED_FAILURE:
      return {
        ...state,
        loading: false,
        ScheduleInterview: [],
      };

    default:
      return state;
  }
};

export default interviewReducer;
