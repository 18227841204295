import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import MediaQuery from "react-responsive";
import clipBoardIcon from "../../../../../../assets/icons/Group 19971 (1).svg";
import styles from "./style.module.css";
const ProfileSubmittedCard = ({ data }) => {
  const messagesEndRef = useRef(null);
  return (
    <div>
      {data?.Status === "Profile Submitted" && (
        <>
          <div className="chat-profile-submit" ref={messagesEndRef}>
            <div className="user-threat">
              <MediaQuery maxWidth={768}>
                <div className="Clip_Board_Icon">
                  <img src={clipBoardIcon} alt="" />
                </div>
                <div className="Profile_Submitted">Profile submitted</div>
              </MediaQuery>
              {/* <div className="congratulation">Congratulations!</div> */}
              <div className="accept">
                Good news! We submitted your profile to the employer at
              </div>
              <div className="reason-container">
                <div style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {moment(data?.Created).format("dddd , MMMM DD , YYYY")}
                </div>
                <div className={styles["time"]}>
                  {moment(data?.Created).format("hh:mm A")}
                </div>
              </div>
              <div className="accept" style={{ marginTop: "2%" }}>
                You'll set up an interview next. We'll notify you of proposed
                interview times.
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ProfileSubmittedCard;
