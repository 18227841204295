import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const history = useHistory();
  const { pathname } = useLocation();
  const [locationKeys, setLocationKeys] = useState([]);
  const [scroll, setScroll] = useState();

  const scrollEffect = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });

  useEffect(() => {
    scrollEffect();
    return () => setScroll(true);
  }, [pathname, scroll]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          setScroll(false);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          setScroll(false);
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    window.addEventListener("beforeunload", scrollEffect);
    return () => {
      window.removeEventListener("beforeunload", scrollEffect);
    };
  }, []);
  return null;
}
