import React from "react";
import { BsPlusLg } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import ToolTips from "../../../../components/ToolTip/ToolTips";
import "./CreateSkilllistingButton.css";

function CreateSkilllistingButton(props) {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="add-skill-button-container">
        <div
          className="add-skill-button"
          onClick={() => history.push("/create-skilllisting")}
        >
          <BsPlusLg
            // color="#118936"
            className="skilllisting-add-button"
          // style={{
          //   marginTop: "2.5px",
          //   marginLeft: "10px",
          //   height: "12px",
          //   width: "12px",
          // }}
          />
          <div className="Create-a-Skill-Listing3">Create a skill listing</div>
        </div>
        <div className="TooltipDashboard" >
          <ToolTips
            placement="bottomRight"
            title="Create a skill listing to land the exact gig you want. List your skills and sell your expertise. Create multiple skill listings to start choosing from a pool of bids placed by employers that want to hire you."
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateSkilllistingButton;
