import React from "react";
import { useHistory } from "react-router";
import "./EffectiveProfileInfo.css";
import icon_1 from "../../assets/icons/Group (2).svg";
import icon_2 from "../../assets/icons/UserPlus.svg";
import image from "../../assets/img/image 430.png";
import imageIT from "../../assets/img/image 435.png";
import icon_3 from "../../assets/img/icon-3.png";
import icon_4 from "../../assets/img/marketplace-icon-4.png";
import icon_5 from "../../assets/img/marketplace-icon-5.png";
import icon_6 from "../../assets/icons/mail.svg";
import { useSelector } from "react-redux";
import { BaseUrl } from "../../components/Footer";
import NursePicThree from "../../assets/img/Healthcare/NursePicThree.svg";
import NursePicThreeMob from "../../assets/img/Healthcare/NursePicThreeMob.svg";
import ItPicThree from "../../assets/img/Healthcare/ItPicThree.svg";
import ItPicThreeMob from "../../assets/img/Healthcare/ItPicThreeMob.svg";
export const EffectiveProfileInfo = () => {
  const history = useHistory();
  const user = useSelector((state) => state.profileReducer.user);

  return (
    <>
      <div className="effective-profile-info-main">
        <div className="effective-profile-info-first">
          {user?.profile?.ProfessionCode == "HC" ? (
            <img src={NursePicThree} alt="" style={{ borderRadius: "8px" }} />
          ) : (
            <img src={ItPicThree} alt="" style={{ borderRadius: "8px" }} />
          )}
        </div>
        <div className="effective-profile-info-second">
          <div className="effective-profile-info-heading">
            <div className="info-heading">
              Things to avoid when building a successful and effective profile
              on SkillGigs
            </div>
            <div className="info-sub-heading">
              Your success is our focus. Here are a couple of things to keep in
              mind
            </div>
          </div>

          {window.innerWidth < 768 && (
            <div className="effective-profile-info-img-mobile mobile-second-page">
              {user?.profile?.ProfessionCode == "HC" ? (
                <img
                  className="effective-profile-info-img-mobile"
                  style={{ borderRadius: "8px" }}
                  src={NursePicThreeMob}
                  alt=""
                />
              ) : (
                <img
                  className="effective-profile-info-img-mobile"
                  style={{ borderRadius: "8px" }}
                  src={ItPicThreeMob}
                  alt=""
                />
              )}
            </div>
          )}

          <div className="effective-profile-info-content effective-profile-info-content-first">
            <div className="effective-profile-info-content-div">
              <div className="effective-profile-icon">
                <img src={icon_1} alt="" />
              </div>
              <div className="effective-profile-info-content-text">
                Avoid inaccurate or misleading information.
              </div>
            </div>
            <div className="effective-profile-info-content-div">
              <div className="effective-profile-icon">
                <img src={icon_2} alt="" />
              </div>
              <div className="effective-profile-info-content-text">
                No need to create duplicate accounts — just add more skill
                listings. Duplicate accounts confuse employers.
              </div>
            </div>
            <div className="effective-profile-info-content-div">
              <div className="effective-profile-icon">
                <img src={icon_3} alt="" />
              </div>
              <div className="effective-profile-info-content-text">
                Bid only on gigs that you are actually interested in. First
                impressions are everything with potential employers. 
              </div>
            </div>
          </div>
          <div className="effective-profile-info-content">
            <div className="effective-profile-info-content-div">
              <div className="effective-profile-icon">
                <img src={icon_4} alt="" />
              </div>
              <div className="effective-profile-info-content-text">
                Respond to bids as soon as you can. Keeping bids in a pending
                state will result in expiration and missed opportunities.
              </div>
            </div>
            <div className="effective-profile-info-content-div">
              <div className="effective-profile-icon">
                <img src={icon_5} alt="" />
              </div>
              <div className="effective-profile-info-content-text">
                SkillGigs{" "}
                <a
                target="_blank"
                  style={{ color: "#4054B2" }}
                  //   className="subTerm"
                  onClick={() => {
                    window.open(`${BaseUrl}/terms-of-service`);
                  }}
                >
                  <span className="underlineWithGap">
                    {" "}
                    terms and conditions
                  </span>
                </a>{" "}
                require all marketplace users to keep all communication and
                payments within the system until hired on contract or permanent
                status.
              </div>
            </div>
            <div className="effective-profile-info-content-div">
              <div className="effective-profile-icon">
                <img src={icon_6} alt="" />
              </div>
              <div className="effective-profile-info-content-text">
                Being a part of an ecommerce-style marketplace means you will be
                notified of profile activity. Make sure to list{" "}
                <span style={{ color: "#4054B2" }}>
                  hello@alerts-skillgigs.com
                </span>{" "}
                to prevent critical alerts from going to spam.
              </div>
            </div>
          </div>
          <div className="effective-profile-info-btn-senctin">
            <div
              onClick={() => history.push("/role-experience")}
              className="btn-senctin-continue"
            >
              Continue
            </div>
            <div
              onClick={() => history.push("/profile-instruction")}
              className="btn-senctin-back"
            >
              Back
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EffectiveProfileInfo;
