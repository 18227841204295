import React, { useRef, useEffect } from "react";
import { FacebookFilled, LinkedinFilled } from "@ant-design/icons";
import Credentialing2 from "../../pages/Credentialing/Credentialing2";
import viewresumeicon from "../../assets/icons/ant-design_file-word-outlined.svg";
import listingIcon from "../../assets/icons/Group.svg";
import inviteIcon from "../../assets/icons/fa-solid_coffee.svg";
import publicIcon from "../../assets/icons/icomoon-free_eye.svg";
import manageCredentialIcon from "../../assets/icons/manageCredentialIcon.svg";
import AchievementIcon from "../../assets/img/AchievementIcon.png";
import EducationHistoryIcon from "../../assets/img/EducationHistoryIcon.png";
import arrowLeftIcon from "../../assets/icons/arrowLeftIcon.svg";
import arrowRightIcon from "../../assets/icons/arrowRightIcon.svg";
import careerHistoryProgressBarIcon from "../../assets/icons/Career_History_Progress_Bar.svg";
import careerHistoryProgressBarIconMobileView from "../../assets/icons/careerHistoryProgressBarIconMobileView.svg";
import LineIcon from "../../assets/icons/LineIcon.svg";
import zigzagLineIcon from "../../assets/icons/zigzagLineIcon.svg";
import purpleTriangleIcon from "../../assets/icons/purpleTriangleIcon.svg";
import redTriangleIcon from "../../assets/icons/redTriangleIcon.svg";
import greenLineIcon from "../../assets/icons/greenLineIcon.svg";
import yellowArcIcon from "../../assets/icons/yellowArcIcon.svg";
import Frame from "../../assets/icons/Frame.svg";
import "./_3D_Resume.css";
import { Carousel } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setShowPublicView } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { Progress } from "antd";

import {
  fetchResumeGetfollowersInit,
  fetchResumeGetviewInit,
  fetchResumeDetailInit,
} from "../../redux/actions";
import moment from "moment";
import SkillBubbleChart from "./BubbleChart/SkillBubbleChart";
import ToolTips from "../../components/ToolTip/ToolTips";

// Candidate_Profile_Container Start
const Candidate_Profile_Container = () => {
  const history = useHistory();
  const showPublicView = useSelector((state) => state.showPublicViewReducer);
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;
  // const defaultProfilePic =
  //   "https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659652_960_720.png";
  // const srcLink = profile?.PictureUrl
  //   ? `${process.env.REACT_APP_S3_URL}/${profile?.PictureUrl}`
  //   : defaultProfilePic;

  // useEffect(() => {
  //   // console.log("profile:nana", profile);
  // }, [profile]);

  return (
    <>
      <div className="candidate-profile-container">
        <div className="candidate-info">
          <div className="resume-candidate-avatar">
            {/* <img src={srcLink} className="Candidate_Avtar_Img" /> */}
            <div className="candidate-avtar-img">
              {profile?.PictureUrl ? (
                <img
                  src={`${process.env.REACT_APP_S3_URL}${
                    profile?.PictureUrl?.charAt(0) !== "/" ? "/" : ""
                  }${profile?.PictureUrl}`}
                  className="avatar-img-main-section"
                />
              ) : (
                <div
                  className="resume-image-avatar"
                  style={{ fontSize: "1rem" }}
                >
                  {profile?.FirstName?.charAt(0)?.toUpperCase()}
                </div>
              )}
            </div>
            <img src={Frame} alt="" className="candidate-avatar-ratting" />
          </div>
          <div className="candidate-name-and-address">
            <h3>
              {profile?.FirstName && profile?.LastName
                ? profile?.FirstName.charAt(0).toUpperCase() +
                  profile?.FirstName.slice(1).toLowerCase() +
                  " " +
                  profile?.LastName.charAt(0).toUpperCase() +
                  profile?.LastName.slice(1).toLowerCase()
                : "NA"}
            </h3>
            <p>
              {/* {profile?.Location?.LocationWithCountry
                ? profile?.Location?.LocationWithCountry
                : "NA"} */}
              {profile?.Cities?.CityName && profile?.Cities?.CityName + ", "}
              {profile?.Regions?.Code && profile?.Regions?.Code + ", "}{" "}
              {profile?.Countries?.CountryName}
            </p>
            {/* <p>{profile?.Discipline ? "I’m a " + profile?.Discipline : "NA"}</p> */}
          </div>
          <div className="candidate-social">
            {profile?.Facebook && showPublicView ? (
              <FacebookFilled onClick={() => window.open(profile?.Facebook)} />
            ) : (
              <FacebookFilled />
            )}
            {profile?.LinkedIn && showPublicView ? (
              <LinkedinFilled onClick={() => window.open(profile?.LinkedIn)} />
            ) : (
              <LinkedinFilled />
            )}
          </div>
        </div>
        <div className="candidate-divider-main-section">
          <img src={LineIcon} className="divider-line-resume-page" />
          <div className="Divider-line-resume-page-inner"></div>
        </div>
        <div className="candidate-resume-info">
          <div className="candidate-resume-box">
            <div
              className="candidate-resume-box-content"
              onClick={() =>
                showPublicView && history.push("/manageskilllisting")
              }
            >
              <p>Skill listings</p>
              <h3>{profile?.Skilllistings ? profile?.Skilllistings : 0}</h3>
            </div>
            <div
              className="candidate-resume-box-content"
              onClick={() => showPublicView && history.push("/dummy")}
            >
              <p>Followers</p>
              <h3>{profile?.Followers ? profile?.Followers : 0}</h3>
            </div>
            <div
              className="candidate-resume-box-content"
              onClick={() => showPublicView && history.push("/view-listing")}
            >
              <p>Views</p>
              <h3>{profile?.Views ? profile?.Views : 0}</h3>
            </div>
          </div>
          <div
            className={
              showPublicView
                ? "resume-completion-container"
                : "show-public-view"
            }
          >
            <div className="resume-completion-in-percentage">
              <Progress
                width={60}
                type="circle"
                strokeColor="#4ec0b2"
                strokeWidth={5}
                percent={
                  Math.floor(profile?.ResumeCompletion)
                    ? Math.floor(profile?.ResumeCompletion)
                    : 0
                }
              />
              <div>
                <p>Resume completion</p>
              </div>
            </div>
            <div className="resume-completion-button">
              <div onClick={() => history.push("/profile")}>
                Complete 3d resume
              </div>
            </div>
          </div>
        </div>
        {window.innerWidth > 768 && (
          <>
            <img className="zigzag-line-icon" src={zigzagLineIcon} alt="" />
            <img
              className="purple-triangle-icon"
              src={purpleTriangleIcon}
              alt=""
            />
            <img
              className="red-triangle-icon-one"
              src={redTriangleIcon}
              alt=""
            />
            <img
              className="red-triangle-icon-two"
              src={redTriangleIcon}
              alt=""
            />
            <img className="green-line-icon" src={greenLineIcon} alt="" />
            <img className="yellow-arc-icon-one" src={yellowArcIcon} alt="" />
            <img className="yellow-arc-icon-two" src={yellowArcIcon} alt="" />
          </>
        )}
      </div>
    </>
  );
};
// Candidate_Profile_Container End

// Career_Summary start
const Candidate_View_Box = ({ ProfessionCode }) => {
  const history = useHistory();
  const showPublicView = useSelector((state) => state.showPublicViewReducer);
  const dispatch = useDispatch();
  const handlePublicView = (e) => {
    dispatch(setShowPublicView());
    if (ProfessionCode === "HC" && window.innerWidth > 768) {
      e.target.style.maxWidth = "175.2px";
      if (window.innerWidth > 1280) e.target.style.maxWidth = "200px";
    }

    if (ProfessionCode === "HC" && window.innerWidth < 768) {
      e.target.style.maxWidth = "100%";
    }
  };
  useEffect(() => {}, [showPublicView]);

  return (
    <>
      {/* <div
        className={
          showPublicView ? "Candidate_View_Content" : "show-public-view"
        }
        // onClick={() => history.push("/dummy")}
      >
        <img src={viewresumeicon} alt="" />
        <p>View my resume</p>
      </div> */}
      <div
        className={
          showPublicView ? "Candidate_View_Content" : "show-public-view"
        }
        onClick={() => history.push("/manageskilllisting")}
      >
        <img src={listingIcon} alt="" />
        <p>View all listing</p>
      </div>
      {/* <div
        className={
          showPublicView ? "Candidate_View_Content" : "show-public-view"
        }
        // onClick={() => history.push("/dummy")}
      >
        <img src={inviteIcon} alt="" />
        <p>Invite a friend</p>
      </div> */}
      <div
        className={
          showPublicView
            ? "Candidate_View_Content"
            : "Candidate_View_Content Public_View_In_Mobile"
        }
        onClick={(e) => handlePublicView(e)}
      >
        <img src={publicIcon} alt="" />
        <p>{showPublicView ? "Public view" : "Private view"}</p>
      </div>
      {ProfessionCode === "HC" && (
        <a
          href="#Credentialing_Container"
          className={
            showPublicView ? "Candidate_View_Content_Link" : "show-public-view"
          }
        >
          <div
            className={
              showPublicView
                ? "Candidate_View_Content manageCredentialIcon"
                : "show-public-view"
            }
          >
            <img src={manageCredentialIcon} alt="" />
            <p>Manage credentialing</p>
          </div>
        </a>
      )}
    </>
  );
};

const Career_Summary = ({ profile }) => {
  const showPublicView = useSelector((state) => state.showPublicViewReducer);
  const history = useHistory();
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const Description =
    Detail && Detail?.user && Detail?.user?.profile?.Description;
  const {
    profile: { ProfessionCode, FirstName, LastName },
  } = profile;

  const firstName = FirstName
    ? FirstName.charAt(0).toUpperCase() + FirstName.slice(1).toLowerCase()
    : "";
  const lastName = LastName
    ? LastName.charAt(0).toUpperCase() + LastName.slice(1).toLowerCase()
    : "";
  const fullName = firstName + " " + lastName;

  return (
    <>
      <div className="Career_Summary_Content">
        <div className={showPublicView ? "Edit_Button" : "show-public-view"}>
          <a onClick={() => history.push("/profile")}>Edit</a>
        </div>
        <div className="Candidate_View">
          <div
            className={
              showPublicView ? "Candidate_View_Info" : "show-public-view"
            }
          >
            <h3> Hello,{fullName ? fullName : "Candidate Name"}!</h3>
            <p> Here are some quick bites about me</p>
          </div>
          <div className="Candidate_View_Box">
            <Candidate_View_Box ProfessionCode={ProfessionCode} />
          </div>
        </div>
        <div className="Career_Summary">
          <h3>Career summary</h3>
          <p>
            {Description
              ? Description
              : "Please tell about your career summary."}
          </p>
        </div>
      </div>
    </>
  );
};
// Career_Summary End

// _3DResume Start
const _3DResume = () => {
  const inputProgressBar = useRef(null);
  const history = useHistory();
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const ResumeCompletion =
    Detail && Detail?.user && Detail?.user?.profile?.ResumeCompletion;
  const progress_status = Math.floor(ResumeCompletion);

  useEffect(() => {
    if (progress_status && progress_status === 100) {
      inputProgressBar.current.style.width = "100%";
      inputProgressBar.current.style.borderRadius = "10px";
    } else {
      inputProgressBar.current.style.width = `${progress_status}%`;
    }
  }, [progress_status]);

  return (
    <>
      <div className="_3DResume">
        <h3>3D resume</h3>
        <p>
          Complete your 3D resume by clicking{" "}
          <a onClick={() => history.push("/profile")}>here</a>
        </p>
        <div className="Progress">
          <div className="Progress_Bar" ref={inputProgressBar}>
            {progress_status}%
          </div>
        </div>
      </div>
    </>
  );
};
// _3DResume End

// Skill_Index Start
const Skill_Index = () => {
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const skillIndex =
    Detail && Detail?.user && Detail?.user?.profile?.skillIndex;
  const skillIndexData = skillIndex?.sort((a, b) => b.value - a.value);
  const skillIndexDataTen =
    skillIndexData?.length > 9 ? skillIndexData?.slice(0, 10) : skillIndexData;
  const NoData = [
    { name: "No Data", value: "" },
    { name: "No Data", value: "" },
    { name: "No Data", value: "" },
  ];
  return (
    <>
      <div className="Skill_Index">
        <h3 className="DResume-Tooltips">
          <span>Skill index</span>{" "}
          <span>
            <ToolTips
              placement="bottomLeft"
              title="Your skill index is a visual collection of the total skills you have in your work history. The graph of your skill index reflects the calculated percentage of each skill."
            />
          </span>{" "}
        </h3>
        <div className="Skill_Index_Content">
          {/* <SkillIndex data={skillIndex} /> */}
          {skillIndexData?.length > 0 ? (
            <SkillBubbleChart data={skillIndexDataTen} />
          ) : (
            <SkillBubbleChart data={NoData} />
          )}

          {/* <BubbleChart
            graph={{
              zoom: window.innerWidth > 768 ? 0.4 : 0.8,
              offsetX: window.innerWidth > 768 ? 0.3 : 0.05,
              offsetY: window.innerWidth > 768 ? 0.05 : 0.2,
            }}
            width={
              window.innerWidth > 768
                ? window.innerWidth - 240 - 40
                : window.innerWidth - 30
            }
            height={window.innerWidth > 768 ? 550 : 400}
            padding={-20} // optional value, number that set the padding between bubbles
            showLegend={false} // optional value, pass false to disable the legend.
            legendPercentage={20} // number that represent the % of with that legend going to use.
            legendFont={{
              family: "Arial",
              size: 12,
              color: "#000",
              weight: "bold",
            }}
            valueFont={{
              family: "Arial",
              size: 12,
              color: "#fff",
              weight: "bold",
            }}
            labelFont={{
              family: "Arial",
              size: 16,
              color: "#fff",
              weight: "bold",
            }}
            //Custom bubble/legend click functions such as searching using the label, redirecting to other page
            // bubbleClickFunc={this.bubbleClick}
            // legendClickFun={this.legendClick}
            data={data}
          /> */}
        </div>
      </div>
    </>
  );
};
// Skill_Index End

// Career_History Start
const Education_Year = ({ currentYear, nextYear, firstLastYearGap }) => {
  const inputEducationYear = useRef(null);

  useEffect(() => {
    let flex;
    if (currentYear === nextYear) {
      flex = 100 / firstLastYearGap;
    } else {
      flex = ((currentYear - nextYear) * 100) / firstLastYearGap;
    }
    inputEducationYear.current.style.flex = flex;
  }, [inputEducationYear]);
  return (
    <>
      <span ref={inputEducationYear}>{currentYear}</span>
    </>
  );
};

const Career_History_Progress_Bar = () => {
  const inputImgProgressBar = useRef();
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const CareerHistory =
    Detail && Detail?.user && Detail?.user?.profile?.CareerHistory;
  const WorkHistory =
    Detail && Detail?.user && Detail?.user?.profile?.WorkHistory;
  const lastDate = WorkHistory?.map((elem) =>
    elem.EndDate ? elem.EndDate : elem.StartDate
  );

  // console.log("CareerHistory", CareerHistory);
  const WorkYear =
    CareerHistory?.length === 1
      ? CareerHistory.push(moment(...lastDate).format("YYYY"))
      : CareerHistory;

  // // console.log("workHistory:Detail", Detail);
  // // console.log("workHistory:History", lastDate);
  // // console.log("workHistory:lastDate", lastDate);
  // // console.log("workHistory:workYear", WorkYear);

  const year =
    WorkYear?.length > 0 ? WorkYear?.sort((a, b) => (b > a ? 1 : -1)) : [];

  const firstLastYearGap = year[0] - year[year.length - 1] + 1;
  let progressBarIcon = careerHistoryProgressBarIcon;

  if (window.innerWidth < 768) {
    progressBarIcon = careerHistoryProgressBarIconMobileView;
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      progressBarIcon = careerHistoryProgressBarIconMobileView;
    }

    // window.addEventListener("resize", (e) => {
    //   if (window.innerWidth < 768) {
    //     progressBarIcon = careerHistoryProgressBarIconMobileView;
    //     inputImgProgressBar.current.src =
    //       careerHistoryProgressBarIconMobileView;
    //   } else {
    //     inputImgProgressBar.current.src = careerHistoryProgressBarIcon;
    //   }
    // });
  }, [progressBarIcon]);

  return (
    <>
      {CareerHistory?.length > 0 ? (
        <div className="Career_History_Progress_Year">
          {year.map((currentYear, index) => {
            return (
              <Education_Year
                currentYear={currentYear}
                nextYear={
                  year[
                    year[year.length - 1] === currentYear ? index : index + 1
                  ]
                }
                firstLastYearGap={firstLastYearGap}
                key={index}
              />
            );
          })}
        </div>
      ) : null}
      <div className="Career_History_Progress_Bar">
        <img src={progressBarIcon} alt="" ref={inputImgProgressBar} />
      </div>
    </>
  );
};

const Work_History = ({ workHistory }) => {
  const { Title, StartDate, EndDate, CurrentJob, Responsibilities, Skills } =
    workHistory;

  return (
    <>
      <div className="Experience_Container">
        <div className="Work_Position_Info">
          <h3>{Title}</h3>
          <p>
            {StartDate ? moment(StartDate).format("MMM DD , YYYY") : "NA"} -{" "}
            {EndDate
              ? moment(EndDate).format("MMM DD , YYYY")
              : CurrentJob
              ? "Current"
              : "NA"}
          </p>
        </div>
        <div className="Responsibilities_And_Skills_Content">
          <div className="Responsibilities_Content">
            <h3>Responsibilities</h3>
            <p>
              {Responsibilities
                ? Responsibilities
                : workHistory.Consultants_CareerRecords_Responsibilities?.map(
                    (resp) => `${resp.Description} `
                  )}
            </p>
          </div>
          <div className="Skills_Content">
            <h3>Skills</h3>
            <div className="Skill_Button">
              {Skills &&
                Skills.map((Skill, i) => (
                  <span key={i}>
                    {Skill?.SkillName} ({Skill?.SkillPercent}%)
                  </span>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Career_History = () => {
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const WorkHistory =
    Detail &&
    Detail?.user &&
    Detail?.user?.profile?.WorkHistory?.filter(({ Deleted }) => !Deleted);

  // // console.log("WorkHistory:Detail", Detail);
  // // console.log("WorkHistory:WorkHistory", WorkHistory);

  return (
    <>
      <div className="Career_History">
        <h3>Career history</h3>
        <div className="Career_History_Progress_Container">
          <Career_History_Progress_Bar />
        </div>
        {WorkHistory && WorkHistory.length ? (
          WorkHistory.sort((a, b) => (a.StartDate < b.StartDate ? 1 : -1)).map(
            (workHistory, i) => (
              <Work_History workHistory={workHistory} key={i} />
            )
          )
        ) : (
          <No_Data />
        )}
      </div>
    </>
  );
};
// Career_History End

// Industry_Density Start
const Industry_Density = () => {
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = useSelector((state) => state.profileReducer.user);
  const {
    profile: { ProfessionCode },
  } = profile;
  const IndustryDensity =
    Detail && Detail?.user && Detail?.user?.profile?.IndustryDensity;
  const showPublicView = useSelector((state) => state.showPublicViewReducer);
  const history = useHistory();
  const bgColor = ["#f4cb5b", "#8166ad", "#eb6c99", "#81d065", "#26ad85"];
  const borderColor = ["#e7ad0e", "#5b2ea5", "#c03768", "#5cb63c", "#1b8a69"];

  // // console.log("Industry_Density:", IndustryDensity);

  return (
    <>
      <div className="Industry_Density">
        <h3 className="DResume-Tooltips">
          <span>Industry density</span>{" "}
          {ProfessionCode === "IT" && (
            <span>
              <ToolTips
                placement="bottomLeft"
                title="Your industry density calculates the percentage of each industry you’ve worked in."
              />
            </span>
          )}
        </h3>
        <p>Talent’s top 5 industry experience, expressed in percentages</p>
        {showPublicView && (
          <a onClick={() => history.push("/profile")}>
            Click here to add an industry you have worked in
          </a>
        )}
        {!IndustryDensity?.length && !showPublicView ? (
          <No_Data />
        ) : !IndustryDensity?.length ? null : (
          IndustryDensity.sort((a, b) =>
            a.Percentage < b.Percentage ? 1 : -1
          ).map(({ IndustryName, Percentage }, i) => {
            return (
              <>
                {Percentage > 0 ? (
                  <React.Fragment key={i}>
                    <div
                      className="Industry_Density_Percentage"
                      style={{
                        "--bgColor": bgColor[i],
                        "--borderColor": borderColor[i],
                      }}
                    >
                      <span>{IndustryName}</span>
                      <span>{Percentage}%</span>
                    </div>
                  </React.Fragment>
                ) : null}
              </>
            );
          })
        )}
      </div>
    </>
  );
};
// Industry_Density End

// Education_History Start
const Education_History = () => {
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const EducationHistory =
    Detail &&
    Detail?.user &&
    Detail?.user?.profile?.EducationHistory?.filter(({ Deleted }) => !Deleted);

  // console.log("Edhhhhhhhhhhh", EducationHistory);

  return (
    <>
      <div className="Education_History">
        <h3>Education</h3>
        {EducationHistory && EducationHistory.length ? (
          <>
            <div className="Education_History_Carousel_Container">
              {EducationHistory?.length > 1 ? (
                <div className="Carousel_Left_Arrow">
                  <img src={arrowLeftIcon} alt="" />
                </div>
              ) : null}
              <div className="Education_History_Carousel">
                <Carousel autoplay arrows dotPosition="bottom" dots="true">
                  {EducationHistory &&
                    EducationHistory.sort((a, b) =>
                      a.YearCompleted < b.YearCompleted ? 1 : -1
                    ).map((educationHistory, i) => {
                      const {
                        YearCompleted,
                        CourseType,
                        CourseName,
                        SchoolName,
                      } = educationHistory;
                      return (
                        <React.Fragment key={i}>
                          <div className="Education_History_Carousel_Box">
                            <div className="Education_History_Content">
                              <div className="Education_History_Box">
                                <img src={EducationHistoryIcon} alt="" />
                                <div className="Education_detail_Info">
                                  <p>{YearCompleted}</p>
                                  <p>{SchoolName}</p>
                                </div>
                              </div>
                              <div className="Education_History_Info">
                                <p>{CourseName}</p>
                                <h3>{CourseType}</h3>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </Carousel>
              </div>
              {EducationHistory?.length > 1 ? (
                <div className="Carousel_Right_Arrow">
                  <img src={arrowRightIcon} alt="" />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <No_Data />
        )}
      </div>
    </>
  );
};
// Education_History End

// Certificates_And_Awards Start
const Certificates_And_Awards = () => {
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const Certificate =
    Detail && Detail?.user && Detail?.user?.profile?.Certificate;

  return (
    <>
      <div className="Certificates_And_Awards">
        <h3>Certificates & awards</h3>
        {Certificate && Certificate.length ? (
          <div className="Achievement_Box">
            {Certificate.map(({ Description }, i) => {
              return (
                <React.Fragment key={i}>
                  <div className="Achievement">
                    <img src={AchievementIcon} alt="" />
                    <p>{Description}</p>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <No_Data />
        )}
      </div>
    </>
  );
};
// Certificates_And_Awards End

// Credentialing Start
const Credentialing = () => {
  const showPublicView = useSelector((state) => state.showPublicViewReducer);
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const user_doc_list =
    Detail && Detail?.user && Detail?.user?.profile?.user_doc_list;
  const total_doc_list =
    Detail && Detail?.user && Detail?.user?.profile?.total_doc_list;

  useEffect(() => {}, [Detail]);

  return (
    <>
      {!showPublicView && user_doc_list === 0 ? (
        <>
          <div
            className="Public_View_Credentialing_Container"
            id="Credentialing_Container"
          >
            <h3>Credentialing</h3>
            <p>
              Uploaded credentials ({user_doc_list}/{total_doc_list})
            </p>
            <No_Data />
          </div>
        </>
      ) : (
        <>
          <div className="Credentialing_Container" id="Credentialing_Container">
            <Credentialing2 />
          </div>
        </>
      )}
    </>
  );
};
// Credentialing End

const No_Data = () => {
  return (
    <>
      <div className="No_Data">
        <h3>No data</h3>
      </div>
    </>
  );
};

const _3D_Resume = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileReducer.user);
  const {
    profile: { ProfessionCode },
  } = profile;
  const showPublicView = useSelector((state) => state.showPublicViewReducer);
  const getFollowers = useSelector(
    (state) => state.resumeGetfollowersReducer.success
  );
  const getviews = useSelector((state) => state.resumeGetviewReducer.success);
  const Detail = useSelector((state) => state.resumeDetailReducer.success);

  useEffect(() => {
    dispatch(fetchResumeGetfollowersInit(1, 5));
  }, []);

  useEffect(() => {
    dispatch(fetchResumeGetviewInit(1, 5));
  }, []);

  useEffect(() => {
    dispatch(fetchResumeDetailInit());
  }, []);

  // console.log(" Get-followers", getFollowers);
  // console.log(" Get-Views", getviews);
  // console.log(" Get-Detail", Detail);
  return (
    <>
      <Candidate_Profile_Container />
      <div className="Candidate_Profile_Info_Container">
        <div className="Candidate_Profile_Info_Inside">
          <Career_Summary profile={profile} />
          {showPublicView && <_3DResume />}
          <Skill_Index />
          <Career_History />
          <Industry_Density />
          <Education_History />
          {ProfessionCode === "IT" && <Certificates_And_Awards />}
          {ProfessionCode === "HC" && <Credentialing />}
        </div>
      </div>
    </>
  );
};

export default _3D_Resume;
