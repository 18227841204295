import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ManageSkillListingtwo from "../Manage_SkillListing/ManageSkillListingTwo";
import {
  formSubmitSkillListingOne,
  createSkillListing,
  fetchKeySearchDiscipline,
  saveSearchDiscipline,
  updateSkillListing,
  setPubfilterSkillListing,
  setPageSkillListing,
} from "../../redux/actions";
import { useSelector } from "react-redux";
import "./ManageSkillListing.css";
import { Dropdown, Menu, Select, Drawer, Pagination } from "antd";
import { Link } from "react-router-dom";
import { fetchUserSkillListing } from "../../redux/actions";

// icons
import { BsPlusLg } from "react-icons/bs";
import { IoOptionsOutline } from "react-icons/io5";
import plussign from "../../assets/img/bi-plus-lg.png";
import filtersign from "../../assets/img/filter.png";
import Loader from "../../components/Loader/Loader";
import NoDataView from "./NoSkillListingView";
import ToolTips from "../../components/ToolTip/ToolTips";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
const SkillListingOne = (props) => {
  const history = useHistory();

  const skilllistings = props.formSkills?.skillListings?.data;

  // const skillListingCount = props?.formSkills?.skillListings?.count?.totalCount;

  const skillListingProfile = useSelector(
    (state) => state.profileReducer?.talentApiData?.Profiles?.Skilllistings
  );

  const skillListingCount = skillListingProfile ? skillListingProfile : 0;

  // useUpdateLogger("skillListingCount", skillListCount);

  const paginationCount = props.formSkills?.skillListings?.count;

  const { Option } = Select;

  const { page, perPage, pubfilter, currentSkillListing } = props.formSkills;
  const { setPubFilter, setPage } = props;
  // const [page, setPage] = useState(1);
  // const perPage = window.innerWidth > 768 ? 24 : 20;
  // const [pubfilter, setPubFilter] = useState("Published");

  const [publisheddata, setPublishedData] = useState([]);
  const [deleteddata, setdeleteddata] = useState([]);
  const [filter, setFilter] = useState([]);
  const [skillFiller, setSkillFiller] = useState({});
  // drop down view
  const [noDataView, setNoDataView] = useState("");

  const [discipline, setDiscipline] = useState("");

  const [dropdownVisbilty, setDropdownVisbilty] = useState(false);

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const checkSize = () => {
    setScreenSize(window.innerWidth);
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdownVisbilty(false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    // setScreenSize(window.innerWidth);
    window.addEventListener("resize", checkSize);
    // // console.log(screenSize);

    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, [window.innerWidth]);

  // Drawer
  const [filterDrawer, setFilterDrawer] = useState(false);

  const handleFilterDrawer = (state) => {
    setFilterDrawer(state);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  useEffect(() => {
    // fetch Skill Listing
    // // console.log("CurrentSkill:", currentSkillListing);
    // // console.log("Props", props);
    props.getSkillListing(pubfilter, page, perPage);
  }, [page, pubfilter, currentSkillListing]);

  useEffect(() => {
    if (skilllistings) {
      const skills = skilllistings;
      // console.log(skills);
      const skill = skills[skills.length - 1];
      // console.log(skill);
      setSkillFiller(skill);
    }
  }, [props.formSkills]);

  // useEffect(() => {
  //   // console.log("data", skillListingCount);
  // }, [skillListingCount]);

  const btnCreateSkillListing = (
    <div
      className="Frame-19924"
      onClick={() => history.push("/create-skilllisting")}
    >
      <BsPlusLg color="white" style={{ marginTop: "2.5px" }} />
      {/* <img src={plussign} alt="plus" className="plussign2" /> */}
      <div className="Create-a-Skill-Listing2">Create a skill listing</div>
    </div>
  );

  useEffect(() => {
    // setPubFilter("Published");
    // setFilter(publisheddata);
    return () => {
      setPubFilter("Published");
      setPage(1);
    };
  }, []);

  function handleMenuClick(e) {
    // console.log(" ear", e);
    setFilter(!filter);
    if (e.key === "Published") {
      setPubFilter("Published");
      setPage(1);
      // setFilter(publisheddata);
    } else {
      setPubFilter("Deleted");
      setPage(1);
      // setFilter(deleteddata);
    }
    closeFilterDrawer();
    setDropdownVisbilty(false);
  }

  const filtermenu = (
    <Menu className="filterMenuPUBD">
      <Menu.Item key="Published" onClick={(e) => handleMenuClick(e)}>
        <span
          className={pubfilter === "Published" ? "greenTextSkillListing" : ""}
        >
          Published
        </span>
      </Menu.Item>
      <Menu.Item key="Deleted" onClick={(e) => handleMenuClick(e)}>
        <span
          className={pubfilter === "Deleted" ? "greenTextSkillListing" : ""}
        >
          Deleted
        </span>
      </Menu.Item>
    </Menu>
  );

  // useEffect(() => {
  //   setPubFilter("Published");
  //   if (skilllistings && skilllistings.length > 0) {
  //     const published = skilllistings.filter(
  //       (data) => data.SkillListingStatus === "Published"
  //     );
  //     const deleted = skilllistings.filter(
  //       (data) => data.SkillListingStatus === "Deleted"
  //     );
  //     setPublishedData(published);
  //     setdeleteddata(deleted);
  //     // console.log("data", publisheddata.length);
  //   }
  // }, [skilllistings]);

  // useUpdateLogger("listSkill", skilllistings);

  const paginationHandle = (e) => {
    // console.log("Pagination", e);
    setPage(e);
  };

  return (
    <div>
      <div className="outerbox">
        <div
          className={
            skillListingCount === 0 ? "skilllistfont0" : "skilllistfont"
          }
        >
          <div className="collide">
            <div className="countSkillCombo">
              <span className="Skill-Listing">Skill listings</span>
              <span className="itemsfont">
                {props.loadingSkillListing
                  ? ""
                  : skilllistings
                  ? skillListingCount
                  : 0}
                {props.loadingSkillListing
                  ? ""
                  : skilllistings && skillListingCount > 1
                  ? " items"
                  : " item"}
              </span>
            </div>
            {!props.loadingSkillListing && (
              <>
                <div className="createAndFilter">
                  <div className="pubcreate">
                    <div className="pubcreate12">
                      <Dropdown
                        trigger={["click"]}
                        placement="bottomRight"
                        overlay={filtermenu}
                        overlayClassName="dropdownClasskilllisting"
                        // visible={
                        //   window.innerWidth < 786 ? false : dropdownVisbilty
                        // }
                        // onClick={() => setDropdownVisbilty(!dropdownVisbilty)}
                      >
                        <div
                          className="filterPubDelete"
                          onClick={() => {
                            if (window.innerWidth < 786) {
                              setFilterDrawer(true);
                            }
                          }}
                        >
                          <div defaultValue="published">{pubfilter}</div>
                          {/* <img
                          src={filtersign}
                          alt="filter"
                          className="filterpublished"
                        /> */}
                          <span className="filterIconSkillListing">
                            <IoOptionsOutline />
                          </span>
                        </div>
                      </Dropdown>
                    </div>
                    <Drawer
                      title={<div className="filterDrawerTitle">Filter by</div>}
                      placement="right"
                      width="100%"
                      onClose={closeFilterDrawer}
                      visible={filterDrawer}
                      className="filterDrawer"
                    >
                      {filtermenu}
                    </Drawer>
                  </div>
                  {screenSize > 768 &&
                    skilllistings &&
                    skilllistings?.length > 0 &&
                    btnCreateSkillListing}
                  {screenSize > 768 &&
                    skilllistings &&
                    skilllistings?.length > 0 && (
                      <ToolTips
                        placement="bottomLeft"
                        title="Create a skill listing to land the exact gig you want. List your skills and sell your expertise. Create multiple skill listings to start choosing from a pool of bids placed by employers that want to hire you."
                      />
                    )}
                </div>
              </>
            )}
          </div>
          <div className="Skilllisting-tooltip-div">
            {skilllistings &&
              skilllistings?.length > 0 &&
              screenSize < 768 &&
              btnCreateSkillListing}
            {skilllistings && skilllistings?.length > 0 && screenSize < 768 && (
              <span className="Skilllisting-tooltip-span">
                <ToolTips
                  placement="bottom"
                  title="Create a skill listing to land the exact gig you want. List your skills and sell your expertise. Create multiple skill listings to start choosing from a pool of bids placed by employers that want to hire you."
                />
              </span>
            )}
          </div>
        </div>
        {props.loadingSkillListing ? (
          <Loader size="2.5rem" height="15vh" />
        ) : (
          <>
            {skilllistings && skilllistings?.length === 0 ? (
              <NoDataView />
            ) : (
              <div className="skillistingContainerHold">
                {skilllistings?.length > 0 &&
                  skilllistings.map((data) => {
                    return (
                      <ManageSkillListingtwo
                        skillListings={data}
                        skilllength={skilllistings.length}
                        publishedData={skilllistings}
                        deletedData={skilllistings}
                        filterPubDel={pubfilter}
                        perPage={perPage}
                        page={page}
                        key={skilllistings._id}
                      />
                    );
                  })}
              </div>
            )}
          </>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "3rem 0 3rem",
        }}
      >
        {skilllistings?.length > 0 && (
          <Pagination
            // simple={true}
            hideOnSinglePage={true}
            total={paginationCount.totalCount}
            pageSize={perPage}
            onChange={(e) => paginationHandle(e)}
            current={page}
            defaultCurrent={1}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataForm: state.credentialingReducer.step3,
    formSkills: state.skillListingReducer,
    searchedDiscipline: state.skillListingReducer.searchedDiscipline,
    disciplineLoader: state.skillListingReducer.disciplineLoader,
    loadingSkillListing: state.skillListingReducer.loadingSkillListing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSkillListing: (...params) => {
      dispatch(fetchUserSkillListing(...params));
    },
    setPubFilter: (params) => {
      dispatch(setPubfilterSkillListing(params));
    },
    setPage: (params) => {
      dispatch(setPageSkillListing(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillListingOne);
