import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { sendBidStatusURL } from "../../../API/API";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";

const BidStatus = () => {
  const history = useHistory();
  const location = useLocation().search;
  const bidId = new URLSearchParams(location).get("bidId");
  const message = new URLSearchParams(location).get("message");
  const jobId = new URLSearchParams(location).get("gidId");
  const [response, setResponse] = useState({});
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendBidStatusApi = async (params) => {
    try {
      setLoading(true);
      const data = await axios.get(sendBidStatusURL + params);
      if (data.status === 200) {
        setResponse(data?.data);
        setLoading(false);
        setStatus(data.status);
      }
    } catch (error) {
      if(error.response && error.response.status && error.response.status === 403){
        setStatus(403)
        setResponse(error.response.data);
      }else{
        setResponse({});
        setStatus(502);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!bidId || !message) {
      history.push("/");
    } else {
      const params = `${
        message === "Bid Accepted" ? "Accepted" : "Declined"
      }/${bidId}`;
      sendBidStatusApi(params);
    }
    return () => {};
  }, []);

  const navigateToChat = () => {
    history.push({
      pathname: `/messages`,
      state: {
        openConvo: true,
        conversationId: response.conversationId,
      },
    })
  }
  if (loading) return <Loader />;
  return (
    <div className="bidStatus-container">
      {status === 200 ? (
        <>
          <FaRegCheckCircle
            fontSize={45}
            color={message === "Bid Accepted" ? "green" : "red"}
          />
          {message === "Bid Accepted" ? 
            <p className="confirmation-text-container">Thank you for showing interest in this gig.<br/>
            <a onClick={() => history.push(`/details/${jobId}`)}>View gig details</a> &emsp; <a onClick={navigateToChat}>Chat with the employer</a><br/>
            The employer has been notified of your interest and will get back to you shortly.</p> :
            <p className="confirmation-text-container">Uh ho! Looks like this gig doesn't match your expectation.<br/>
            <a onClick={() => history.push(`/details/${jobId}`)}>View gig details</a> &emsp; <a onClick={navigateToChat}>Chat with the employer</a><br/>
            Search for gigs to bid on and increase your chance of landing your next gig!</p>}
        </>
      ) : status === 403 ? (
        <>
          <FaRegCheckCircle fontSize={45} color={ response?.type === "Declined" ? "red" : "green"}/>
          <p className="confirmation-text-container">You’ve already actioned this bid as ‘{response?.type === "Declined" ? 'Not interested' : "Interested"}’ on {response.reactedDate ? moment(response.reactedDate).format("MMM DD, YYYY"):''}. {jobId ? <a onClick={() => history.push(`/details/${jobId}`)}>View gig details</a> : ""}
          <br/>Not sure of this bid? <a onClick={navigateToChat}>Chat with the employer</a> to discuss this opportunity further.</p>
        </>
      ) : 
      <>
          <FaRegCheckCircle fontSize={45} color={"red"} />
          <h1>Something went wrong!</h1>
        </>
      }
    </div>
  );
};

export default BidStatus;