import React, { useState, useRef, useCallback, useEffect } from "react";

import { useHistory, Link, useLocation } from "react-router-dom";

import {
  Form,
  Button,
  Card,
  Col,
  Typography,
  Alert,
  Divider,
  message,
  notification,
} from "antd";
import {
  deleteResume,
  formSubmitStepOne,
  getTalentInfo,
} from "../../redux/actions";
import FileIcon from "../../assets/icons/file.svg";
import { connect, useDispatch, useSelector } from "react-redux";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import {
  updateProfile,
  uploadProfilePic,
  uploadResume,
} from "../../redux/actions";
import "./ResumeUpload.css";
import {
  ConsoleSqlOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { alertNotification } from "../../components/notifications/alertNotification";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import { getFileFormat } from "../../components/fileInformation";
import Loader from "../../components/Loader/Loader";

const acceptedFileTypes = "application/pdf";

const { Text, Title } = Typography;
// write a jsx component as a card centered in middle with back buton text on the top left
function ResumeUpload(props) {
  const history = useHistory();
  const path = useLocation().pathname;

  const dispatch = useDispatch();

  const resumeInputRef = useRef();
  // console.log(props);
  const triggerInputResume = () => resumeInputRef.current.click();

  const [fileType, setFileType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [upload, setUpload] = useState(null);
  const [file, setFile] = useState(null);
  //use state for alertmessage
  const [alertMessage, setAlertMessage] = useState("");
  const [numPages, setNumPages] = useState(1);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const user = useSelector((state) => state.profileReducer.user);
  const ResumeFilename = user?.profile?.ResumeFilename;
  // const docID = useSelector(
  //   (state) => state.profileReducer?.user?.profile?.ResumeDocID?._id
  // );
  const docID = useSelector(
    (state) => state.profileReducer?.talentInfo?.ResumeDocID?._id
  );

  const authToken = useSelector((state) => state.authReducer.user.authToken);

  const { loadingSkillListing } =
    useSelector((state) => state.skillListingReducer) || "";

  // useUpdateLogger("payloaddd:docId", docID);
  // useUpdateLogger("payloaddd:talentInfo", talentInfo);
  useEffect(() => {
    dispatch(getTalentInfo(authToken));
  }, [authToken]);

  useEffect(() => {
    const str1 = user?.profile?.FirstName;
    const str2 = user?.profile?.LastName;
    setFirstName(str1?.charAt(0)?.toUpperCase() + str1?.slice(1));
    setLastName(str2?.charAt(0)?.toUpperCase() + str2?.slice(1));
  }, [user?.profile?.FirstName, user?.profile?.LastName]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const openNotification = (message) => {
    return (
      <>
        {notification.open({
          // message: message,
          description: message,
          duration: 4,
          getContainer: () => document.getElementById("notification-container"),
          icon: <ExclamationCircleOutlined style={{ color: "white" }} />,
        })}
      </>
    );
  };
  const fileHandler = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (
        file?.type !== "application/pdf" &&
        file?.type !== "image/jpeg" &&
        file?.type !== "image/png" &&
        file?.type !== "image/jpg"
      ) {
        // setAlertMessage("Please upload a valid pdf file");
        alertNotification("warning", "File format not supported");
        // openNotification("File format not supported");
        resumeInputRef.current.value = "";
        return;
      }
      // 10485760 - 10 mb, below is 10.5mb
      if (file?.size >= 10590617) {
        alertNotification("warning", "File size bigger than 10 MB");

        // openNotification("File size is larger than 10 MB");

        resumeInputRef.current.value = "";
        return;
      }
      // setToast(false);
      // // empty variable
      // setAlertSize(null);
      if (
        e.target.name === "resume" &&
        (file?.type === "application/pdf" ||
          file?.type === "image/jpeg" ||
          file?.type === "image/png" ||
          file?.type === "image/jpg")
      ) {
        setFileType(file.type);
        setUpload(e.target.files[0].name);
        const payload = {
          file,
          fileName: e.target.files[0].name,
          fileSize: file.size.toString(),
          userId: user?.id,
          documentId: docID,
          type: "credentialingDoc",
        };
        // // console.log("payloaddd:", payload);
        props.uploadResume(payload);
      }

      const reader = new FileReader(); // javaScript api
      reader.onload = () => {
        // EMPTY = 0; LOADING = 1; DONE = 2;
        // if (reader.readyState === 1) {
        // } else
        if (reader.readyState === 2 && e.target.name === "profilePicture") {
          // setImage(reader.result);
        } else if (e.target.name === "resume") {
          setUpload(e.target.files[0].name);
          setFile(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);

      // dispatcher
      props.sendFormData(e);
      // props.dispatch({
      //   type: "CHANGE",
      //   payload: e.target.files[0],
      //   key: e.target.name, //
      //   stage: "step1",
      // });
    }
  };
  const deleteResume = () => {
    setUpload(false);
    props.deleteResume();
    // props.dispatch({
    //   type: "CHANGE",
    //   payload: null,
    //   key: "resume",
    //   stage: "step1",
    // });
  };

  const signupEditSkilllisting = () => {
    history.push({
      pathname: `/create-skilllisting-signup`,
    });
  };

  useEffect(() => {
    if (ResumeFilename) {
      const fileType =
        getFileFormat(ResumeFilename) === "pdf" ? "application/pdf" : null;
      setFile(
        `${process.env.REACT_APP_S3_URL}${
          ResumeFilename?.charAt(0) !== "/" ? "/" : ""
        }${ResumeFilename}`
      );

      setFileType(fileType);
    }
  }, [ResumeFilename]);

  // const trackingID = process.env.REACT_APP_ADWORDS_ID;

  // const gtag_report_conversion_continue_btn = () => {
  //   try {
  //     ReactGA.event({
  //       category: "conversion",
  //       action: "send_to",
  //       label: `${trackingID}/7fxoCPjtxJIYENu60M8D`,
  //     });
  //     return false;
  //   } catch (e) {
  //     console.log("Resume Upload Page", e.message);
  //   }
  // };

  return (
    <>
      {alertMessage ? (
        <Alert
          message={alertMessage}
          className="alert-banner"
          banner
          closable
        />
      ) : null}
      <div id="notification-container"></div>
      <div
        className={
          path === "/resume-upload"
            ? "resume-page-layout resume-page-margin"
            : "resume-page-layout"
        }
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Col lg={9} md={10} sm={24} xs={24}>
            <Card className="resume-card">
              <Form>
                <div
                  onClick={() => {
                    if (!loadingSkillListing) signupEditSkilllisting();
                  }}
                  className={`resume-card-BackButton ${
                    loadingSkillListing ? "click-disable disable-back" : ""
                  }`}
                >
                  Back
                </div>
                {/* <div className="text-margin">
                  <Text>
                    Hi {firstName} {lastName}!
                  </Text>
                </div> */}
                {/* <div className="text-margin"> */}
                <Title className="text-margin heading-resume" level={4}>
                  Resume upload
                </Title>
                {/* </div> */}
                <Divider className="Resume_divider" />

                <Text className="text-margin lorem-text">
                  Upload your resume for employers to get you know better.
                  we'll help build your 3D resume later.
                </Text>
                {/* {file && numPages && resumeInputRef.current.value ? ( */}
                {props.resumeloading ? (
                  <Loader />
                ) : file && numPages ? (
                  fileType === "application/pdf" ? (
                    <Document
                      file={file}
                      // data= {base64PdfData}
                      className="resume-pdf-container"
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <div className="preview-card">
                        <Page pageNumber={1} height={150} />
                      </div>
                    </Document>
                  ) : (
                    <Card className="preview-card resume-pdf-container">
                      <img
                        src={file}
                        alt="preview"
                        className="preview-image"
                        height="150px"
                      />
                    </Card>
                  )
                ) : (
                  <div className="attachment">
                    <img src={FileIcon} alt="Upload file" />
                  </div>
                )}
                <div className="resume">
                  <label htmlFor="fileInput" className="image-upload">
                    <input
                      type="file"
                      name="resume"
                      style={{ display: "none" }}
                      accept=".pdf,.jpeg,.png,.jpg"
                      ref={resumeInputRef}
                      onChange={fileHandler}
                    />
                  </label>

                  <Button
                    className="upload-button"
                    onClick={triggerInputResume}
                    onChange={fileHandler}
                    block
                  >
                    {file && numPages ? "Update resume" : "Upload resume"}
                  </Button>
                </div>
                <div>
                  <p className="resume-info">Your resume should</p>
                  <ul className="bullets">
                    <li>JPEG, JPG, PNG, and PDF formats </li>
                    <li>PDF file formats up to 10 MB </li>
                  </ul>
                </div>

                <Form.Item>
                  <Button
                    className="continue-btn"
                    htmlType="submit"
                    onClick={() => {
                      // gtag_report_conversion_continue_btn();
                      history.push("/basic-profile-success");
                      props.updateProfileWizard({ WizardFlow: 5 });
                    }}
                    block
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.profileReducer.loading,
    resumeloading: state.profileReducer.resumeloading,
    //   user: state.profileReducer.user,
    //   profile: state.profileReducer.user.profile,
    //   error: state.profileReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadResume: (params) => {
      dispatch(uploadResume(params));
    },
    sendFormData: (e) => {
      dispatch(formSubmitStepOne(e));
    },

    deleteResume: () => {
      dispatch(deleteResume());
    },
    updateProfileWizard: (value) => {
      dispatch(updateProfile(value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResumeUpload);
