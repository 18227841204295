import store from "../redux/store";
import axios from "axios";
const BackendApiBaseUrl = process.env.REACT_APP_API_URL; 


export const downloadAttachments = async (s3Key) => {
    var response = ''
    if (s3Key) {
        const talentToken = store.getState().authReducer.user.authToken;
        const headers = { "Content-Type": "application/json", Authorization: talentToken };
        const results = await axios.get(
            `${BackendApiBaseUrl}/message/getattachment/${s3Key}`,
            { headers }
        ).then(result => {
            // console.log('promise result', result);
            response = result.data.downloadURL;
        })
        return response
    }
}

export const getImage = async (s3key) => {
    if (s3key) {
        const talentToken = store.getState().authReducer.user.authToken;
        const headers = { "Content-Type": "application/json", Authorization: talentToken };
        const response = await axios.post(
            `${BackendApiBaseUrl}/upload/get`,
            {
                s3key: s3key,
            },
            { headers }
        );
        return response.data;
    }
};