import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchUserSkillListing } from "../../redux/actions";
import TipToGettingStart from "../WelcomeWizard/TipToGettingStart";
import "./SkillListing.css";
import SkillistingForm from "./SkillListingForm";

const SkillListingPage = () => {
  const path = useLocation().pathname;
  const dispatch = useDispatch();
  const profile =
    useSelector((state) => state.profileReducer.user?.profile) || "";
  const skillListings = useSelector(
    (state) => state.skillListingReducer.skillListings1
  );
  const oneSkillId = useSelector(
    (state) => state.skillListingReducer.oneSkillId
  );

  const idSkill = useSelector(
    (state) => state.skillListingReducer?.currentSkillListing?.SkillListingId
  );

  useEffect(() => {
    (profile?.SkillListings?.length || oneSkillId) &&
      dispatch(fetchUserSkillListing("Published", 1, 9));
  }, [profile?.SkillListings?.length, oneSkillId]);

  return (
    <div
      className={`skillListingOnePage ${
        path === "/create-skilllisting-signup"
          ? "skillListingPagePadding skillListingMargin"
          : null
      }`}
    >
      {skillListings && path === "/create-skilllisting-signup" ? (
        <SkillistingForm updateId={skillListings} idSkill={idSkill} />
      ) : (
        <SkillistingForm />
      )}
      {path === "/create-skilllisting-signup" ? <TipToGettingStart /> : null}
    </div>
  );
};

export default SkillListingPage;
