import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Radio, Space } from "antd";
import moment from "moment";
import ToolTips from "../../../components/ToolTip/ToolTips";
import { useEffect } from "react";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";

const RadioButtonInterview = ({ onChangeRadio, BidDetailData }) => {
  const AccountId = useSelector(
    (state) => state.profileReducer.user.profile.AccountId
  );
  const [RescheduleLength, setRescheduleLength] = useState(
    BidDetailData?.interview?.ReScheduledTimings?.length
  );

  const [timing, setTimings] = useState(
    BidDetailData?.interview?.Interviews_ScheduledTimings
  );

  useEffect(() => {
    if (
      BidDetailData?.interview?.ModifiedBy !== AccountId &&
      RescheduleLength
    ) {
      setTimings(
        BidDetailData?.interview?.ReScheduledTimings[RescheduleLength - 1]
      );
      return;
    }
    setTimings(BidDetailData?.interview?.Interviews_ScheduledTimings);
  }, [BidDetailData, AccountId]);

  // useUpdateLogger(
  //   "timing:",
  //   BidDetailData?.interview?.Interviews_ScheduledTimings
  // );

  // useUpdateLogger("timing2:", timing);

  return (
    <>
      {BidDetailData?.interview?.ModifiedBy !== AccountId ||
      BidDetailData?.interview?.InterviewStatuses?.InterviewStatusName ===
        "Pending" ? (
        <div>
          <div className="Talent-Stage-InterviewSelecter">
            Available interview times{" "}
            <ToolTips
              placement="bottomRight"
              title="Available interview times are the employer’s available time slots to schedule the interview."
            />{" "}
          </div>
          <Radio.Group
            className="TalentStages-RadioButton-interview"
            onChange={onChangeRadio}
          >
            <Space direction="vertical">
              {timing?.map((data) => {
                // console.log("datedataa:", data);
                return (
                  <>
                    {data?.StartTime && (
                      <Radio
                        className="TalentStages-RadioButton-interview-section resc"
                        value={data}
                      >
                        <div className="TalentStage-mobile-span-text-value">
                          <span>
                            {moment(data?.StartTime).format(
                              "dddd , MMMM DD , YYYY"
                            )}
                          </span>
                          <div>
                            {moment(data?.StartTime)
                              .local()
                              .format("hh:mm:ss A")}
                          </div>
                        </div>
                      </Radio>
                    )}
                  </>
                );
              })}
            </Space>
          </Radio.Group>
        </div>
      ) : (
        <div>
          <div className="Talent-Stage-InterviewSelecter">
            Available interview times
          </div>
          <Radio.Group
            className="TalentStages-RadioButton-interview remove-check-Button-TalentStages"
            onChange={onChangeRadio}
          >
            <Space direction="vertical">
              {RescheduleLength &&
                BidDetailData?.interview?.ReScheduledTimings[
                  RescheduleLength - 1
                ]?.map((data) => {
                  return (
                    <>
                      {data?.StartTime && (
                        <Radio
                          className="TalentStages-RadioButton-interview-section remove-check-Button-ExtraSpace"
                          value={data}
                        >
                          <div className="TalentStage-mobile-span-text-value">
                            <span>
                              {/* {moment(data?.StartTime).format(
                              "dddd, Do MMMM, YYYY"
                            )} */}
                              {moment(data?.StartTime).format(
                                "dddd , MMMM DD , YYYY"
                              )}
                            </span>
                            <div>
                              {/* {moment(timeStamp[4], "HH:mm:ss").format(
                              "hh:mm:ss A"
                            )} */}
                              {moment(data?.StartTime)
                                .local()
                                .format("hh:mm:ss A")}
                            </div>
                          </div>
                        </Radio>
                      )}
                    </>
                  );
                })}
            </Space>
          </Radio.Group>
        </div>
      )}
    </>
  );
};

export default RadioButtonInterview;
