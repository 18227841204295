import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import {
  fetchDataFailure,
  fetchDataSuccess,
  uploadDocSuccess,
  uploadDocFailure,
  fetchData,
  removeDocumentSuccess,
  removeDocumentFailure,
  catchError,
} from "../actions";
import { fetchDocsList, uploadFileApi, removeDocApi } from "../../API/API";
import { alertNotification } from "../../components/notifications/alertNotification";

function* docSaga(params) {
  try {
    const response = yield call(() => fetchDocsList(params)); // API Backend
    // console.log(response.data.data.body.data);
    if (response.data.status === true) {
      yield put(fetchDataSuccess(response.data.data.body.data));
    } else {
      yield put(fetchDataFailure(response.data.status));
    }
  } catch (error) {
    // console.log(error);
    yield put(fetchDataFailure(error));
    yield put(catchError(error));
  }
}

function* uploadDocSaga(params) {
  // console.log("Docs in SAGA:", params);
  try {
    const response = yield call(uploadFileApi, params); // API Backend
    // console.log("Upload success response:", response);
    // yield put(fetchData());
    if (response.data.status === "success") {
      const data = {
        key: response.data.payload,
        CredentialDocumentId: params.params.documentId,
        fileName: params.params.fileName,
        fileSize: params.params.fileSize,
      };
      yield put(uploadDocSuccess(data));
      alertNotification("success", "File Uploaded Successfully");
      // setTimeout(yield put(fetchData()), 3000);
    } else {
      yield put(uploadDocFailure(response.errorMessage));
    }
  } catch (error) {
    // console.log(error);
    yield put(uploadDocFailure(error));
  }
}

function* removeDocSaga(params) {
  try {
    const response = yield call(removeDocApi, params); // API Backend
    // console.log("DELETE DOC RESPONSE:", response);
    if (response.status == 200) {
      // yield put(removeDocumentSuccess(params.params));
      yield put(fetchData());
      alertNotification("success", "File Deleted Successfully");
    } else {
      yield put(removeDocumentFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(removeDocumentFailure(error));
  }
}

export function* watchDocSaga() {
  yield takeEvery(types.SEND_REQUEST_INIT, docSaga);
}

export function* watchUploadDocSaga() {
  yield takeEvery(types.UPLOAD_DOCUMENT_INIT, uploadDocSaga);
}

export function* watchRemoveDocSaga() {
  yield takeEvery(types.REMOVE_DOCUMENT_INIT, removeDocSaga);
}
