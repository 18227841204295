import { Modal } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../redux/actions";
import { getCountryPhoneCode } from "../../WelcomeWizard/helper";
import "./UpdateContactNumber.css";

const UpdateContactNumber = ({
  isNumberUpdate,
  setIsNumberUpdate,
  contactNumber,
  flagcode,
  Countrycode,
}) => {
  const dispatch = useDispatch();
  const [isUpdateActive, setIsUpdateActive] = useState(false);
  const [isTenNumber, setIsTenNumber] = useState(false);
  const [MobilePhone, setMobilePhone] = useState(
    contactNumber?.replace(/[- )(]/g, "")?.slice(-10)
  );
  const handleContactNumber = () => {
    dispatch(
      updateProfile({
        Profiles: { MobilePhone: Number(Countrycode + MobilePhone) },
      })
    );
    // console.log(MobilePhone);
    setIsNumberUpdate(false);
  };

  const onChangeInput = (e) => {
    const val = e.target.value;
    // console.log("maniram2", val);
    const reg = /^\d+\d*$/.test(val);
    if (val.length === 0 || (reg && val.length < 11)) setMobilePhone(val);
  };
  useEffect(() => {
    // if (MobilePhone === contactNumber) {
    //   setIsUpdateActive(false);
    // } else {
    //   setIsUpdateActive(true);
    // }
    if (MobilePhone?.length === 10) setIsTenNumber(true);
    else setIsTenNumber(false);
  }, [MobilePhone, isUpdateActive]);
  return (
    <>
      <Modal
        visible={isNumberUpdate}
        className="ucn-container"
        bodyStyle={{ padding: 0 }}
        width="340px"
        title="Edit Contact"
        footer={null}
        centered={true}
        onCancel={() => setIsNumberUpdate(false)}
      >
        <div className="ucn-box">
          <div className={`ucn-body ${!isTenNumber ? "redBorderError" : ""}`}>
            <span>
              <img
                src={`https://flagcdn.com/${flagcode ? flagcode : "us"}.svg`}
                width="20px"
                height="15px"
                alt=""
              />
            </span>
            <input
              type="text"
              value={MobilePhone}
              onChange={(e) => onChangeInput(e)}
            />
          </div>
          {!isTenNumber && (
            <>
              <div className="isTenNumber">Please enter valid phone number</div>
            </>
          )}
          <div className="ucn-footer">
            <button
              onClick={() => {
                setIsNumberUpdate(false);
              }}
            >
              Cancel
            </button>
            <button
              // className={!isUpdateActive ? "contactUpdateDisabled" : ""}
              onClick={() => {
                // if (isUpdateActive) handleContactNumber();
                if (isTenNumber) handleContactNumber();
              }}
            >
              Add
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateContactNumber;
