import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import "./Profession.css";

import healthCareIcon from "../../assets/icons/healthCareICON.svg";
import IT_icon from "../../assets/icons/IT_ICON.svg";
import freelancer from "../../assets/icons/IT_Freelancer_ICON.svg";
import { updateProfile } from "../../redux/actions";

const Profession = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.profileReducer.user);

  const updateProfession = (ProfessionCode) => {
    dispatch(
      updateProfile({
        userId: user.id,
        Professions: { ProfessionCode: ProfessionCode },
        WizardFlow: 2,
      })
    );
    history.push("/read-rules");
    // history.push("/role-experience");
  };

  // const trackingID = process.env.REACT_APP_ADWORDS_ID;

  // const gtag_report_conversion_hc = async () => {
  //   try {
  //     ReactGA.event({
  //       category: "conversion",
  //       action: "send_to",
  //       label: `${trackingID}/KXQCCPvtxJIYENu60M8D`,
  //     });
  //     return false;
  //   } catch (e) {
  //     console.log("HC Profession Error", e.message);
  //   }
  // };

  // const gtag_report_conversion_it = async () => {
  //   try {
  //     ReactGA.event({
  //       category: "conversion",
  //       action: "send_to",
  //       label: `${trackingID}/c1HRCO_txJIYENu60M8D`,
  //     });
  //     return false;
  //   } catch (e) {
  //     console.log("IT Profession Error", e.message);
  //   }
  // };

  useEffect(() => {
    localStorage.removeItem("utm_source");
    localStorage.removeItem("utm_campaign");
    localStorage.removeItem("utm_medium");
    localStorage.removeItem("utm_term");
  }, []);

  return (
    <div className="ProfessionPage">
      <div className="profession">
        <div className="professionHeader">
          <div style={{ width: "19.1rem" }}>
            <div className="preferenceHeader">Choose your preferences</div>
            <div className="preferenceSub">
              Start your SkillGigs experience in one of our professional
              marketplaces.
            </div>
          </div>
        </div>
        {/* <section className="alignProfession"> */}
        <div className="chooseProfession">
          <div
            className={`healthCarePro ${user?.profile?.ProfessionCode === "HC" && "SelectedProfessionCode"}`}
            onClick={(e) => {
              e.stopPropagation();
              // gtag_report_conversion_hc();
              updateProfession("HC");
            }}
          >
            <div>
              {" "}
              <img src={healthCareIcon} alt="healthCareIcon" />{" "}
            </div>
            <span className="professionDivider"></span>
            <div className="professiontxt">
              {" "}
              <span className="professionText">Healthcare</span>
              <div className="professionSubText">
                Our healthcare professionals range <br />
                from registered nurses and travel <br />
                nurses to therpaists.{" "}
              </div>{" "}
            </div>
          </div>

          <div
            className={`InfoTPro ${user?.profile?.ProfessionCode === "IT" && "SelectedProfessionCode"}`}
            onClick={() => {
              // gtag_report_conversion_it();
              updateProfession("IT");
            }}
          >
            <div>
              {" "}
              <img src={IT_icon} alt="IT_icon" />{" "}
            </div>
            <span className="professionDivider"></span>
            <div className="professiontxt">
              {" "}
              <span className="professionText">IT</span>
              <div className="professionSubText">
                {" "}
                Our IT professionals range from <br />
                developers and designers to project <br />
                managers.
              </div>{" "}
            </div>
          </div>
          {/* <div
            className="ITFreelancerPro"
            onClick={() => updateProfession("FL")}
          >
            <img src={freelancer} alt="freelancer" />
            <span className="professionDivider"></span>
            <span className="professionText">IT Freelancer</span>
          </div> */}
        </div>
        {/* </section> */}
      </div>
    </div>
  );
};

export default Profession;
