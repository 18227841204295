import React, { useState } from "react";
import NoViewHistorySkill from "./NoViewHistorySkill";

import EducationHistory from "../../../Profile/components/EducationHistory/EducationHistory";
import { useSelector } from "react-redux";

const EducationHistorySkillListing = () => {
  const [addEditEd, setAddEditEd] = useState(false);

  const loading = useSelector((state) => state.profileReducer.loadingED);

  const historyLength = useSelector(
    (state) => state.profileReducer.talentInfo.EducationHistory
  )
    ?.filter((obj) => obj.Deleted === false)
    .sort((a, b) => a.YearCompleted - b.YearCompleted).length;

  return (
    <div>
      {(loading || addEditEd || historyLength > 0) && (
        <div className="educationHistorySkillListing">
          <EducationHistory
            skillListingDetailPage={true}
            addEditEd={addEditEd}
            setAddEditEd={setAddEditEd}
          />
        </div>
      )}
      {!loading && !addEditEd && !historyLength > 0 && (
        <NoViewHistorySkill type={"Education History"} func={setAddEditEd} />
      )}
    </div>
  );
};

export default EducationHistorySkillListing;
