import React from "react";
import CircleProgressBar from "../../../pages/NewDashboard/Components/ProfileSectionV2.2/ProgressBar/CircleProgressbar";
import { useSelector } from "react-redux";
import ProfileAvatar from "../../../pages/NewDashboard/Components/ProfileAvatar/ProfileAvatar";
import "./ProfileAvatarMenu.css";
import { Rate } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ProfileAvatarMenu() {
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;
  const user = useSelector((state) => state.profileReducer.user);
  const userRating = (rating, isResumeCompletion) => {
    rating = isResumeCompletion ? Number(rating) + 1 : rating;
    return rating >= 5 ? 5 : rating;
  };
  const history = useHistory();
  return (
    <div className="profile-avatar-menu-container">
      <div
        className="circular-progress-bar-menu"
        onClick={() => history.push("/profile")}
      >
        <div className="circular-progress-bar-circle">
          <CircleProgressBar
            progress={
              Math.floor(profile?.ResumeCompletion)
                ? Math.floor(profile?.ResumeCompletion)
                : 0
            }
            size={40}
            isMenu={true}
          />
        </div>
        <ProfileAvatar
          name={user?.profile?.FirstName}
          dot={false}
          size={35}
          src={`${process.env.REACT_APP_S3_URL}/${user.profile.PictureUrl}`}
          isAvatarBgColor={true}
        />
      </div>
      <div className="user-info-menu">
        <span className="user-name-menu">
          {" "}
          {user?.profile?.FirstName.charAt(0).toUpperCase() +
            user?.profile?.FirstName.slice(1) +
            " " +
            user?.profile?.LastName.charAt(0).toUpperCase() +
            user?.profile?.LastName.slice(1)}
        </span>
        <div className="use-rating-menu">
          <span className="professional-code">
            {" "}
            {user?.profile?.ProfessionCode}
          </span>
          <div className="DashBoard-Profile-user-rating-section-menu">
            <div className="DashBoard-Profile-user-rating-star-menu">
              <Rate count={1} size="small" disabled defaultValue={1} />
            </div>
            <div className="DashBoard-Profile-user-rating-number-menu">
              {userRating(
                user?.profile?.Rating ? user?.profile?.Rating : 3.5,
                Math.floor(profile?.ResumeCompletion) === 100
              )}{" "}
              out of 5
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileAvatarMenu;
