import React, { useEffect, useState, useRef } from "react";
import { Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { getFileIcon } from "../../../../../../components/fileInformation/index";
import MediaQuery from "react-responsive";
import offerIcon from "../../../../../../assets/icons/Group 19971 (3).svg";
import RejectedIcon from "../../../../../../assets/icons/Group 19971.png";
import acceptedIcon from "../../../../../../assets/icons/Group 19971.svg";
import { AiOutlineUpload } from "react-icons/ai";
import { RiCheckDoubleFill } from "react-icons/ri";
import { Divider, Modal, Button } from "antd";
import "./styles.css";
import { downloadAttachments } from "../../../../../../shared/downloadAttachments";
import {
  acceptOffer,
  fetchDeclinedReasonsBids,
} from "./../../../../../../redux/actions";
import { AiFillCloseCircle } from "react-icons/ai";
import downloadChatFilesApi from "./../../../../../../shared/downloadFiles";
import { fetchToken } from "../../../../../../API/API";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";

export const OfferCards = ({ status, OfferCards, items, index }) => {
  const { id } = useParams();
  const [reason, setReason] = useState([]);
  const chats = useSelector((state) => state.getChatHistoryReducer.chats);
  const profile = useSelector((state) => state.profileReducer.user);
  const bidStatus = useSelector(
    (state) => state.getChatHistoryReducer.bidStatus
  );
  const userProfile = useSelector((state) => state.profileReducer.user);
  const { PreferredCurrency, ProfessionCode } = useSelector(
    (state) => state.profileReducer.user.profile
  );
  const { loading } = useSelector((state) => state.offerReducer);

  useEffect(() => {
    // console.log('OfferCard Profile>>',items?.SenderUser?.AccountId)
    dispatch(fetchDeclinedReasonsBids("bid"));
  }, []);


  const declineReasons = useSelector(
    (state) => state.BidsReducer.declinedReasonsBid
  );
  const { declinedReasonOffer } = useSelector((state) => state.BidsReducer);

  const dispatch = useDispatch();

  const [retract, setRetract] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [offerInterested, setOfferInterested] = useState(false);
  const [declinedReason, setDeclinedReason] = useState();
  const [decline, setDecline] = useState(false);
  const [interviewTime, setInterviewTime] = useState(null);
  const [attachement, setAttachment] = useState([]);
  const [isRetract, setIsRetract] = useState(false);
  const [ranking, setRanking] = useState(null);
  const [isPlaceBid, setIsPlaceBid] = useState(null);
  const [skillListing, setSkillListing] = useState();
  const [interview, setInterview] = useState();
  const [declineFlag, setDeclineFlag] = useState(false);
  const [rescheduleFlag, setRescheduleFlag] = useState(false);
  const [interviewCard, setInterviewCard] = useState(null);
  const [proposal, setProposal] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDocId, setCurrentDocId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [interested, setInterested] = useState(false);
  const [conversations, setConversations] = useState();
  const [reschedule, setReschedule] = useState(false);
  const [disablebtn, setDisableBtn] = useState(false);
  const [payload, setPayload] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [error, setError] = useState(false);
  const [UploadDocLoading, setUploadDocLoading] = useState(false);

  const handleReasonSubmit = () => {
    if (declinedReason) {
      setDisableBtn(true);
      dispatch(
        acceptOffer({
          id: OfferCards?.BidId,
          Status: "Decline",
          BidType: items?.SourceTypeId === 1 ? "Bid" : "Proposal",
          DeclineReason: declinedReason,
        })
      );
      setIsModalVisible(false);
    }
  };

  // useEffect(() => {
  //   let id =
  //     items?.SenderUser?.AccountId !== userProfile?.profile?.AccountId
  //       ? items?.BidCard
  //         ? items?.BidCard.DeclineReasonId
  //         : items?.ProposalCard?.DeclineReasonId
  //       : OfferCards?.DeclineReasonId;
  //   // setReason(test);
  // }, [declineReasons]);

  const onRemoveAttachment = (fileName, e) => {
    setAttachment(() => {
      return attachement.filter((file) => {
        return file.file.FileName !== fileName;
      });
    });
    var filterKey = attachement.filter((file) => {
      return file.file.FileName === fileName;
    });
    // console.log("attachment in offer card", attachement);
    if (attachement?.length <= 1) {
      setPayload(false);
    }
    // setFileKey(() => {
    //   return fileKey.filter((file) => {
    //     return file.file !== filterKey[0].FileInfo?.FilePath;
    //   });
    // });
  };

  const offerReasons = [
    {
      id: 1,
      Reason: "Accepted another candidate",
    },
    {
      id: 2,
      Reason: "Not interested in the candidate",
    },
    {
      id: 3,
      Reason: "Not interested in the location",
    },
    {
      id: 4,
      Reason: "Asking payrate is too high",
    },
  ];

  useEffect(() => {
    let test;
    let id =
      // props?.data?.SenderUser?.AccountId === userProfile?.profile?.AccountId
      // ?
      items?.BidCard?.DeclineReasonId
        ? items?.BidCard?.DeclineReasonId
        : items?.ProposalCard?.DeclineReasonId
          ? items?.ProposalCard?.DeclineReasonId
          : items?.OfferCard?.DeclineReasonId;
    if (items?.SenderUser?.AccountId === userProfile?.profile?.AccountId) {
      test = declineReasons?.filter((d) => d?.ReasonId == id);
    } else if (
      items?.SenderUser?.AccountId !== userProfile?.profile?.AccountId
    ) {
      test = offerReasons?.filter((d) => d?.id == id);
    }
    // console.log("bid accepted in web app", id, test);
    setReason(test);
  }, [items]);

  const declineReasonHandler = (e) => {
    setIsSelected(!isSelected);
    setDeclinedReason(e.target.innerText);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOffer = () => {
    setModalTitle("Accept offer");
    setOfferInterested(true);
    setDecline("Accept-offer");
    showModal();
  };

  async function uploadToBucket() { }

  const acceptOfferHandler = async () => {
    // error && setPayload(false);
    // console.log("payload handler", payload, error);
    // setError(true)
    if (payload && !error && !UploadDocLoading) {
      setError(false);
      setDisableBtn(true);
      setIsModalVisible(false);
      dispatch(
        acceptOffer({
          id: OfferCards?.BidId,
          CandidateAttachments: [payload],
          BidType: OfferCards?.BidType,
          Status: "Accepted",
        })
      );
      await axios.put(payload.fileUrl, attachement[0].file);
    } else {
      setError(true);
    }
  };

  const handleDownload = async (filePath) => {
    await downloadChatFilesApi(filePath);
  };

  const onFileChange = async ({ target: { files } }) => {
    setAttachment([{ file: files[0] }]);

    setError(false);
    const uploadpayload = {
      type: "msgAttachment",
      contentType: files[0].type,
    };

    const headers = await fetchToken();
    const getUploadUrl = `${process.env.REACT_APP_API_URL}/upload`;
    setUploadDocLoading(true);
    const data = await axios.post(getUploadUrl, uploadpayload, { headers });
    const response = data.data;

    if (response) setUploadDocLoading(false);

    const payload = {
      fileName: files[0].name,
      fileSize: files[0].size.toString(),
      userId: items.SenderUser._id,
      documentId: currentDocId,
      type: files[0].type,
      filePath: response?.Key,
      fileUrl: response.uploadURL,
    };

    setPayload(payload);

    // setTimeout(()=>{
    //   setUploadDocLoading(false)
    //   setError(false)
    // },1500)
    // setAttachment([...attachement, { file: files[0] }]);
    // dispatch(
    //   sendMessage_Attachment({
    //     type: "msgattachment",
    //     contentType: files[0].type,
    //     folder: "userDocs",
    //   })
    // );
  };

  useEffect(() => {
    setRetract(false);
    setOfferInterested(false);
    setDecline(false);
    setReschedule(false);
    setDeclinedReason(false);

    setIsRetract(false);
    setDeclineFlag(false);
    setRescheduleFlag(false);
    setInterviewCard(null);
    chats?.data?.body?.gigCard
      ? setGig(chats?.data?.body?.gigCard?.gig)
      : setGig(chats?.data?.body?.placedBidCard?.gig);
    chats?.data?.body?.gigCard
      ? setRanking(chats?.data?.body?.gigCard?.ranking)
      : setRanking(chats?.data?.body?.placedBidCard?.ranking);
    chats?.data?.body?.gigCard ? setIsPlaceBid(false) : setIsPlaceBid(true);
    chats?.data?.body?.interviewCard?.interview &&
      setInterviewCard(chats?.data?.body?.interviewCard?.interview);
    setBid(chats?.data?.body?.gigCard?.bid);
    setProposal(chats?.data?.body?.placedBidCard?.proposal);
    setSkillListing(chats?.data?.body?.gigCard?.skillListing);
    setInterview(chats?.data?.body?.interviewCard?.interview);
    setConversations(chats?.data?.body?.conversations);
    !interviewTime &&
      setInterviewTime(
        chats?.data?.body?.interviewCard?.interview?.ScheduledTimings[1]
      );
  }, [
    chats?.data?.body?.gigCard?.gig,
    chats?.data?.body?.placedBidCard?.proposal,
    chats?.data?.body?.gigCard?.skillListing,
    chats?.data?.body?.placedBidCard?.interviewCard,
    chats?.data?.body?.conversations,
    interviewCard,
    ranking,
  ]);
  const messagesEndRef = useRef(null);
  const [gig, setGig] = useState();
  const [bid, setBid] = useState();

  const getFileFormat = (fileName) => {
    const behideDot = fileName?.lastIndexOf(".") + 1;
    const fileLength = fileName?.length;
    const fileFormat = fileName?.slice(behideDot, fileLength);
    return fileFormat;
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (OfferCards?.CandidateAttachments?.length > 0) {
      if (OfferCards?.CandidateAttachments[0]?.FileName) {
        setFileName(OfferCards?.CandidateAttachments[0]?.FileName);
        setFileSize(OfferCards?.CandidateAttachments[0]?.FileSize);
        setFilePath(OfferCards?.CandidateAttachments[0].FilePath);
      } else {
        setFileName(OfferCards?.CandidateAttachments[0]?.fileName);
        setFileSize(OfferCards?.CandidateAttachments[0]?.fileSize);
        setFilePath(OfferCards?.CandidateAttachments[0].filePath);
      }
    }
  }, [OfferCards]);
  const handleCancel = () => {
    setIsModalVisible(false);
    setError(false);
  };

  const disableHandler = () => {
    let array = bidStatus;
    let filteredArray = array.filter(value => value !== null && value !== undefined);
    // let nullCount = array.length - filteredArray.length;
    // console.log(bidStatus.length -props.index-1, bidStatus.length-1, bidStatus ,filteredArray , filteredArray.length,filteredArray.length - props.index,"asfwafafs Index");
    console.log(filteredArray.length - index,filteredArray.length -1, index ,"asfwafafs Index");
    if (bidStatus.includes("Offer Declined") ||
    bidStatus.includes("Offer Accepted")) {
      return true;
    } 
      //prop.index is the inverted index of the current msg and bidStatus.length is the total length of the messages
      else if(filteredArray.length - index == filteredArray.length -1 ){
        return false;
      } else {
        return true;
      }
  };

  const DeclineBid = () => {
    return (
      <>
        <div className="chat-profile-submit user-sender" ref={messagesEndRef}>
          <div className="user-threat retract-bid-card">
            <MediaQuery maxWidth={768}>
              <div className="Accepted_Icon">
                <img src={RejectedIcon} alt="" />
              </div>
              <div className="Accepted Terminated">Offer declined</div>
            </MediaQuery>
            <div className="top-container">
              <div className="congratulation">
                {items?.SenderUser?.AccountId ===
                  userProfile?.profile?.AccountId
                  ? "Declined"
                  : "Rejected"}{" "}
                {window.innerWidth > 768 && "!"}
              </div>
              <div className="accept">
                {items?.SenderUser?.AccountId ===
                  userProfile?.profile?.AccountId
                  ? "You declined the offer"
                  : "Employer rejected you"}
              </div>
            </div>
            <div className="offer-date">
              <div className="date">Reason</div>
              <div className="time">
                {/* {
                  reason[0].Reason
                } */}
                {OfferCards?.DeclineReason
                  ? OfferCards?.DeclineReason
                  : reason?.length > 0 &&
                    (reason[0].reason != "Estimated pay rate is too low"
                      ? reason[0].Reason
                      : reason[0].Reason == "Estimated pay rate is too low" &&
                      items?.SenderUser?.AccountId ===
                      userProfile?.profile?.AccountId)
                    ? reason[0].Reason
                    : "Estimated pay rate is too High"}
              </div>
            </div>
            {/* <div className="time-stamp">
              <div className="time-text">1:48 pm</div>
              <div className="tick-icon">
                <RiCheckDoubleFill />
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  };


  const OfferPneding = () => {
    return (
      <>
        <div className="Card-container" ref={messagesEndRef}>
          <div className="">
            <MediaQuery maxWidth={768}>
              <div className="Accepted_Icon">
                <img src={offerIcon} alt="" />
              </div>
              <div className="Accepted">Offer letter</div>
            </MediaQuery>
            <div className="congratulation">Congrats!</div>
            <div className="accept">The employer sent you an offer</div>
            <div className="content-container-mobile">
              <div className="ractangle-right ractangle-right-employer-offer ractangle_right_mobile">
                <div className="details offer-details">
                  <div>Start date</div>
                  <div className="values">
                    {moment(OfferCards?.FromDate).format("MMM DD , YYYY")}
                  </div>
                </div>
                <div className="details offer-details">
                  <div>End date</div>
                  <div className="values">
                    <div>{moment(OfferCards?.ToDate).format("MMM DD , YYYY")}</div>
                  </div>
                </div>
                {ProfessionCode === "HC" ? (
                  <div className="details offer-details">
                    <div>Pay package</div>
                    <div className="values">
                      ${OfferCards?.Rate?.toLocaleString('en-US') ?? "NA"}/
                      {OfferCards?.BudgetType ?? "WK"}
                    </div>
                  </div>
                ) : null}
                {OfferCards?.Message &&
                OfferCards?.Message !== "<p><br></p>" ? (
                  <>
                    <div className="offer-details">Offer letter</div>
                    <div
                      className="about-job-text offer-message-text offer_msg_txt_mobile"
                      dangerouslySetInnerHTML={{ __html: OfferCards?.Message }}
                    ></div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="open-document">
              Open the document to digitally sign it then upload your signed offer to accept:
            </div>
            {OfferCards?.Attachments?.map((file, index) => (
              <div
                className="offercard-document"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_S3_URL}/${file.FilePath}`
                  )
                }
              >
                <img
                  src={getFileIcon(getFileFormat(file.FileName ?? "pdf"))}
                  className="fileIcon"
                />
                <div className="document-details">
                  <div className="file-name">{file?.FileName}</div>
                  <div className="file-size">
                    {file?.FileSize
                      ? file?.FileSize >= 1000000
                        ? Math.round(file?.FileSize / 1000000)
                        : Math.round(file?.FileSize / 1000)
                      : null}{" "}
                    {file?.FileSize >= 1000000 ? "MB" : "KB"}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div style={{ color: "red", width: "2px" }}>
            <Divider
              className="divider-mobile"
              style={{ backgroundColor: "red", width: "2px" }}
            />
          </div> */}
          <div className="offer-time-stamp">
            <div className="time-text">
              {moment(items.Created).format("hh:mm A")}
            </div>
            {items?.SenderUser?.AccountId === userProfile?.AccountId ? <div className="tick-icon">
              <RiCheckDoubleFill />
            </div> : null}
          </div>
        </div>
        <div className="buttons">
          <Button
            className={
              interested || decline == "Retracted Bid"
                ? "Retracted-btn"
                : "btncard"
            }
            onClick={() => {
              setDecline("decline-offer");
              setModalTitle("Decline this Offer?");
              setReschedule(false);
              showModal();
            }}
            disabled={
              // bidStatus.includes("Offer Declined") ||
              // bidStatus.includes("Offer Accepted") ||
              // // disablebtn ||
              // status == "Offer Accepted" ||
              // status == "Decline" ||
              // loading && decline === "decline-offer"
              disableHandler()
            } //|| interested
          >
            {/* Decline */}
            {loading && decline === "decline-offer" ? (
              <LoadingOutlined />
            ) : (
              "Decline"
            )}
          </Button>
          <Button
            className={
              interested || decline == "Retracted Bid"
                ? "Retracted-btn"
                : "btncard"
            }
            onClick={() => {
              handleOffer();
            }}
            disabled={
              // bidStatus.includes("Offer Declined") ||
              // bidStatus.includes("Offer Accepted")
              disableHandler()
              // || disablebtn
            } //|| status.include('Accept'))//decline === "decline-offer" || interested
          >
            {loading && decline !== "decline-offer" ? (
              <LoadingOutlined />
            ) : (
              "Accept"
            )}
          </Button>
        </div>
        <Modal
          bodyStyle={{ padding: 0 }}
          width={window.innerWidth < 768 ? "20.625rem" : "22.5rem"}
          title={modalTitle}
          className="message-details-model"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          centered={true}
          transitionName=""
          mask={false}
        // closable={clicked === "retract-bid" ? false : true}
        >
          {/* Accept-offer */}
          <div>
            {decline == "Accept-offer" && (
              <div style={{ padding: "1.25rem 1.25rem" }}>
                {" "}
                {error && (
                  <p
                    style={{
                      color: "red",
                      margin: "0 5%",
                      textAlign: "center",
                    }}
                  >
                    Please Select a file
                  </p>
                )}
                <div>
                  {offerInterested ? (
                    <>
                      {UploadDocLoading ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Spin />
                        </div>
                      ) : attachement.length > 0 ? (
                        attachement.map((res) => {
                          return (
                            <div className="offer-modal-attachments">
                              <div
                                className="signature-attachment-container"
                                style={{ display: "flex" }}
                              >
                                <img
                                  src={
                                    getFileIcon(
                                      getFileFormat(
                                        res.file.FileName
                                          ? res.file.FileName
                                          : res.file.name
                                      )
                                    )
                                    // res.file.FileName
                                    //   ? res.file.FileName
                                    //   : res.file.name
                                  }
                                />
                                <div style={{ marginLeft: "2%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "div",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "16rem",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {res.file.FileSize
                                        ? res.file.FileName
                                        : res.file.name}
                                    </div>{" "}
                                    {/* <AiFillCloseCircle
                                        className="remove-attachment-icon"
                                        onClick={() =>
                                          onRemoveAttachment(res.file.FileName)
                                        }
                                      /> */}
                                  </div>
                                  <span>
                                    {res.file.FileSize
                                      ? res.file.FileSize >= 1000000
                                        ? Math.round(
                                          res.file.FileSize / 1000000
                                        )
                                        : Math.round(res.file.FileSize / 1000)
                                      : res.file.size
                                        ? res.file.size >= 1000000
                                          ? Math.round(res.file.size / 1000000)
                                          : Math.round(res.file.size / 1000)
                                        : null}{" "}
                                    {res.file.FileSize >= 1000000 ||
                                      res.file.size >= 1000000
                                      ? "MB"
                                      : "KB"}{" "}
                                    {getFileFormat(res.file.name)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : null}
                      <br />
                      <div className="offer-modal-browse-files">
                        <label
                          className="label bid-file-upload-label"
                          htmlFor="offerFile"
                        >
                          <div className="bid-file-upload-icon">
                            <AiOutlineUpload />
                          </div>
                          <div className="bid-file-upload-text">
                            {attachement.length > 0
                              ? "Reupload Document"
                              : "Upload digital signature offer"}
                          </div>
                        </label>
                        <input
                          type={"file"}
                          id="offerFile"
                          style={{ display: "none" }}
                          onChange={(e) => onFileChange(e)}
                        />
                      </div>
                      {/* <div
                        className="bid-decline-button-offer"
                        onClick={() => acceptOfferHandler()}
                      > */}
                      <Button className="bid-decline-button-offer"
                        onClick={() => acceptOfferHandler()}
                        disabled={UploadDocLoading}
                      >Accept offer
                      </Button>
                      {/* </div> */}
                    </>
                  ) : null}
                </div>
              </div>
            )}
            {decline == "decline-offer" && declinedReasonOffer ? (
              <div style={{ padding: "4%" }}>
                <div className="bid-decline-headline">
                  Please select a reason for not interested
                </div>
                {declinedReasonOffer?.map((data) => (
                  <div
                    className={
                      data.Reason === declinedReason
                        ? "bid-decline-option-selected"
                        : "bid-decline-option"
                    }
                    onClick={(e) => declineReasonHandler(e)}
                  >
                    {data.Reason}
                  </div>
                ))}
                <div
                  className={declinedReason ? "bid-decline-button" : "bid-decline-button_disable"}
                  onClick={() => handleReasonSubmit()}
                >
                  Submit Reason
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
      </>
    );
  };
  const AcceptedOffer = () => {
    return (
      <>
        <div className="chat-profile-submit user-sender" ref={messagesEndRef}>
          <div className="user-threat">
            <MediaQuery maxWidth={768}>
              <div className="Accepted_Icon">
                <img src={acceptedIcon} alt="" />
              </div>
              <div className="Accepted">Offer accepted</div>
            </MediaQuery>
            {/* <div className="congratulation">Congratulations!</div> */}
            <div className="accept">
              Congrats on accepting the employer’s offer on:{" "}
            </div>
            <div className="offer-date offer_accepted_datepicker">
              <div className="date">
                {moment(OfferCards?.Created).format("dddd , MMMM DD , YYYY")}
              </div>
              <div className="time">
                {moment(OfferCards?.Created).format("h:mm A")}
              </div>
            </div>
          </div>
          <div className="bottom-container">
            <div>Here’s a copy of your signed offer document:</div>
            <div className="offercard-document">
              {fileName && (
                <img
                  src={getFileIcon(getFileFormat(fileName))}
                  // alt={fileName}
                  className="fileIcon"
                />
              )}
              <div
                className="document-details"
                onClick={() => {
                  window.open(`${process.env.REACT_APP_S3_URL}/${filePath}`);
                }}
              >
                <div className="file-name">{fileName}</div>
                <div className="file-size">
                  {fileSize
                    ? fileSize >= 1000000
                      ? Math.round(fileSize / 1000000)
                      : Math.round(fileSize / 1000)
                    : null}{" "}
                  {fileSize >= 1000000 ? "MB" : "KB"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SimpleCongratulations = () => {
    return (
      <>
        <div className="chat-container1 employer-sender" ref={messagesEndRef}>
          <div className="sender-threat"></div>
          <div className="user-threat">
            <MediaQuery maxWidth={768}>
              <div className="Accepted_Icon">
                <img src={acceptedIcon} alt="" />
              </div>
              <div className="Accepted"> You're Hired!</div>
            </MediaQuery>
            {/* <div className="congratulation">Congrats!</div> */}
            <div className="accept-offer-text">
              You're successfully hired by the employer. Congrats on landing the
              new gig!
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {status === "Offer Pending" ? (
        <OfferPneding />
      ) : status === "Offer Declined" ? (
        <DeclineBid />
      ) : status === "Offer Accepted" ? (
        <AcceptedOffer />
      ) : (
        status == "Hired" && <SimpleCongratulations />
      )}
    </div>
  );
};
