import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearAll, getCookie, setCookie } from "../../../shared/helpers";
import { Auth } from "../../../config/aws-exports";
import {
  updateUserInfo,
  getExistingListItem,
  userAssignment,
  resetStore,
  fetchInterview,
} from "../../../redux/actions";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { Col, Row, Menu, Modal } from "antd";
import Timer from "../../Timer";
import logo from "../../../assets/img/logo.png";
import "./ProfileHeader.css";
import ManageBidsMenu from "./ManageBidsMenu";
import CurrencyMenu from "./CurrencyMenu";
import rupeeCurrencyIcon from "../../../assets/icons/carbon_currency-rupee.svg";
import dollarCurrencyIcon from "../../../assets/icons/clarity_dollar-line.svg";
import pesoCurrencyIcon from "../../../assets/icons/clarity_peso-line.svg";
import MediaQuery from "react-responsive";
import ToolTips from "../../ToolTip/ToolTips";

const ProfileHeader = (props) => {
  const history = useHistory();
  const initialPath = useLocation();
  const { PreferredCurrency, ProfessionCode } = useSelector(
    (state) => state.profileReducer.user.profile
  );
  const [showBid, setshowBid] = useState(false);
  const [showCurrency, setshowCurrency] = useState(false);
  const [isCurrencyModelVisible, setIsCurrencyModelVisible] = useState(false);
  const [currencyType, setCurrencyType] = useState(
    PreferredCurrency ? PreferredCurrency : "USD"
  );
  const currencyList = [
    {
      name: "United States Dollar",
      code: "USD",
      symbol: dollarCurrencyIcon,
    },
    {
      name: "Indian Rupee",
      code: "INR",
      symbol: rupeeCurrencyIcon,
    },
    {
      name: " Canadian Dollar",
      code: "CAD",
      symbol: dollarCurrencyIcon,
    },
    {
      name: " Mexican Peso",
      code: "PESO",
      symbol: pesoCurrencyIcon,
    },
  ];
  const showCurrencyModal = () => {
    setIsCurrencyModelVisible(true);
  };
  const closeCurrencyModal = () => {
    setIsCurrencyModelVisible(false);
  };

  const { symbol } = currencyList.filter(
    ({ code }) => code === currencyType
  )[0];

  const Logout = async (redirect) => {
    // debugger;
    try {
      // console.log("clicked");
      // setCookie("talentToken", "", -1);
      // setCookie("impersonationid", "", -1);
      // setCookie("RememberMe", "false");
      // props.hide();
      clearAll();
      props.sendAuthToken(null);
      // props.changeStage("");
      await Auth.signOut();
      redirect();
    } catch (error) {
      // console.log("error signing out: ", error);
    }
  };

  const onLogOut = async () => {
    if (initialPath.pathname === "/"){
      props.sendAuthToken(null);
      // setCookie("talentToken", "", -1);
      // setCookie("impersonationid", "", -1);
      clearAll();
      await Auth.signOut();
    }
    await Logout(() => window.location.replace("/login"));
    // if (window.innerWidth < 1000) {
    //   // history.push("/login");
    //   window.location.replace("/login");
    // } else {
    //   // history.push("/");
    //   window.location.replace("/login");
    //   // window.location.replace("/");
    // }
    // window.location.reload(false);
  };

  useEffect(() => {
    props.getExistingListItem();
    if (props.userassignment.length) {
      window.timesheetId = props.userassignment[0]._id;
    }
  }, [props.userassignment.length]);

  useEffect(() => {
    props.userAssignment();
  }, []);

  // for interview count
  const totalInterviewCount = useSelector(
    (state) => state.interviewReducer.success
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInterview());
  }, []);

  // console.log(`totalInterviewCount : ${totalInterviewCount.length}`);

  const searchedDeta = useSelector(
    (state) => state.onClearSearchReducer.Searchinfo
  );
  // console.log("serch", searchedDeta);

  const [search, setSearch] = searchedDeta;

  // console.log("serch23", searchedDeta, search);

  const searchedValueDeta = useSelector(
    (state) => state.onClearSearchReducer.searchValueInfo
  );
  // console.log("serchtwice", searchedValueDeta);

  const [isOpened, setisOpened] = searchedValueDeta;
  // console.log("serchab", searchedValueDeta[0], isOpened);

  const searchedCloseIconDeta = useSelector(
    (state) => state.onClearSearchReducer.searchCloseIconInfo
  );
  // console.log("visibleclose", searchedCloseIconDeta);

  const [isVisibleCloseIcon, setIsVisibleCloseIcon] = searchedCloseIconDeta;
  // console.log("closeIcon", searchedCloseIconDeta[0], isVisibleCloseIcon);

  useEffect(() => {}, [search, isOpened, isVisibleCloseIcon]);
  const clearSearchdata = () => {
    setSearch("");
    setisOpened(false);
    setIsVisibleCloseIcon(false);
    history.push("/");
  };

  return (
    // <div className="main-scroll">
    //   <section className="header-dropdown-profile-text">
    //    { userassignment.length && userassignment[0].Status == 'idle' ? <Timer /> :null}
    //     <div className="scroll" style={{width:'500px',height:userassignment.length && userassignment[0].Status == 'idle' ?'550px':'700px',overflowY:'auto'}}>
    //     <div className="dropdown-profile-main" >

    <Menu>
      <section
        className="header-dropdown-profile-text"
        onClick={() => props.hide(props.visible)}
      >
        {window.innerWidth > 1000 && <Timer />}
        <div className="dropdown-profile-main">
          {/* <h4>Manage</h4> */}
          {window.innerWidth > 1000 ? (
            <div onClick={() => history.push("/profile")}>Profile</div>
          ) : (
            <>
              <div onClick={clearSearchdata}>Home</div>
              <div onClick={() => history.push("/notifications")}>Inbox</div>
            </>
          )}
          <div
            className="Dropdown-interview-text"
            onClick={() => history.push("/interview")}
          >
            <span>Upcoming interviews</span>
            <span className="dropdown-interview-counts">{totalInterviewCount.length}</span>
          </div>
          <MediaQuery maxWidth={768}>
            <div onClick={() => history.push("/manageskilllisting")}>
              Skill listings
            </div>
          </MediaQuery>
          <div onClick={() => history.push("/myfavorites")}>My favorites</div>
          {/* <div className="resume-burger">3D Resume</div>
          <div className="credentialing">Credentialing (My Documents)</div>
        </div>
   
        <h4 >My Profile</h4>
        <div className="dropdown-profile-divider"></div>
        <div className="dropdown-my-dashboard-text" >
          <div onClick={() => history.push("/dashboard")}> My Dashboard</div>
          <div>Manage Assignments</div> */}
          {/* <Row >
            <Col span={21} >
              <div>Manage Bids</div>
            </Col>
            <Col >
              {
                showBid ?
                  <DownOutlined onClick={() => setshowBid(false)} />
                  :
                  <RightOutlined onClick={() => setshowBid(true)} />
              }
            </Col>
          </Row> */}
          <div className="resume-credentialing">
            <span
              onClick={(e) => {
                history.push("/3d_resume");
              }}
            >
              3D resume
            </span>
            <span>
              <ToolTips
                placement="bottomRight"
                title="Your 3D resume is a visual profile of your skill density. We calculate your professional experience and skills to generate accurate data for your 3D resume."
              />
            </span>
          </div>
          {ProfessionCode === "HC" && (
            <div
              className="resume-credentialing"
              onClick={() => history.push("/credentialing")}
            >
              Credentialing (my documents)
            </div>
          )}
        </div>

        <div className="dropdown-my-profile">
          <h4>My profile</h4>
          <div className="dropdown-profile-divider profileheader-divider"></div>
          <div className="dropdown-my-dashboard-text">
            <div onClick={() => history.push("/dashboard")}> My dashboard</div>
            {/* <div>Manage Assignments</div> */}
            {window.innerWidth < 1000 && (
              <Row
                onClick={(e) => {
                  e.stopPropagation();
                  setshowBid(!showBid);
                }}
              >
                <Col span={21}>
                  <div>Manage bids</div>
                </Col>
                <Col>
                  {showBid ? (
                    <span className="arrow-manage-bids">
                      <UpOutlined />
                    </span>
                  ) : (
                    <span className="arrow-manage-bids">
                      <DownOutlined />
                    </span>
                  )}
                </Col>
              </Row>
            )}
            {showBid ? (
              <ManageBidsMenu />
            ) : // <Row style={{ marginTop: "-10px" }}>
            //   <Col className="bidDetail">
            //     <div onClick={() => history.push("/receivedBids")}>
            //       Received Bids
            //     </div>
            //     <div onClick={() => history.push("/proposals")}>
            //       Placed Bids{" "}
            //     </div>
            //     <div onClick={() => history.push("/proposals")}>
            //       Retracted Bids
            //     </div>
            //   </Col>
            // </Row>
            null}
          </div>
        </div>
        {/* <div className="myEarningsDropdown">
          <h4>My Earnings</h4>
          <div className="dropdown-profile-divider profileheader-divider"></div>
          <div className="profileDropThird">
            <div onClick={() => history.push("/timesheet")}>Timesheet</div>
            <div>Manage Earnings</div>
            <div className="resume-credentialing">Refer & Earn More</div>
          </div>
        </div> */}
        <div className="dropdown-general-text">
          <h4>General</h4>
          <div className="dropdown-profile-divider profileheader-divider"></div>
          <div className="dropdown-logout-text">
            <div onClick={onLogOut}>Logout</div>
            {/* {window.innerWidth > 1000 ? (
              <>
                <div
                  onClick={showCurrencyModal}
                  className="Currency_Type_And_Symbol"
                >
                  <img src={symbol} alt="" />
                  <span>{currencyType}</span>
                </div>
                <Modal
                  visible={isCurrencyModelVisible}
                  bodyStyle={{ padding: 0 }}
                  width="25rem"
                  closable={false}
                  footer={null}
                  maskClosable={true}
                  centered={true}
                  onCancel={closeCurrencyModal}
                >
                  <CurrencyMenu
                    currencyType={currencyType}
                    setCurrencyType={setCurrencyType}
                    closeCurrencyModal={closeCurrencyModal}
                    currencyList={currencyList}
                  />
                </Modal>
              </>
            ) : (
              <>
                <div className="Currency_Dropdown_Mobile_View">
                  <Row
                    onClick={(e) => {
                      e.stopPropagation();
                      setshowCurrency(!showCurrency);
                    }}
                  >
                    <Col span={21}>
                      <div className="Currency_Type_And_Symbol">
                        <img src={symbol} alt="" />
                        <span>{currencyType}</span>
                      </div>
                    </Col>
                    <Col>
                      {showCurrency ? (
                        <span className="Arrow_Currency_Change">
                          <UpOutlined />
                        </span>
                      ) : (
                        <span className="Arrow_Currency_Change">
                          <DownOutlined />
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
                {showCurrency ? (
                  <CurrencyMenu
                    currencyType={currencyType}
                    setCurrencyType={setCurrencyType}
                    closeCurrencyModal={closeCurrencyModal}
                    currencyList={currencyList}
                    setshowCurrency={setshowCurrency}
                  />
                ) : null}
              </>
            )} */}
          </div>
        </div>
        {/* </div> */}
      </section>
    </Menu>
  );
};
const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.user.name,
    error: state.profileReducer.error,
    userassignment: state.timeSheetListReducer.userassignment,
    userAssignments: state.UserAssignmentReducer.success,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
      dispatch(resetStore());
    },
    getExistingListItem: () => dispatch(getExistingListItem()),
    userAssignment: () => dispatch(userAssignment()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader);
