import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import CertificationsAndAwards from "../../../Profile/components/CertificationsAndAwards";
import NoViewHistorySkill from "./NoViewHistorySkill";

const CertsAwardsSkillListing = () => {
  const [addNew, setAddNew] = useState(false);

  const historyLength = useSelector(
    (state) => state.profileReducer.talentInfo.Certificate
  )?.filter((obj) => obj.Deleted === false).length;

  const loading = useSelector((state) => state.profileReducer.loadingCA);

  return (
    <div>
      {(loading || addNew || historyLength > 0) && (
        <div className="certsAndAwardsSkillListing">
          <CertificationsAndAwards
            skillListingDetailPage={true}
            addNew={addNew}
            setAddNew={setAddNew}
          />
        </div>
      )}
      {!loading && !addNew && !historyLength > 0 && (
        <NoViewHistorySkill type={"Certifications & Awards"} func={setAddNew} />
      )}
    </div>
  );
};

export default CertsAwardsSkillListing;
