import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./PlaceBid.css";

import {
  Radio,
  Dropdown,
  Menu,
  Space,
  Pagination,
  Drawer,
  Divider,
} from "antd";

import Loader from "../../../components/Loader/Loader";

import FilterIcon from "../../../assets/icons/filtering.svg";
import sortIcon from "../../../assets/icons/sortIcon.svg";
import BidCard from "../../Bid/BidCard/BidCard";

import { useDispatch, useSelector } from "react-redux";

import {
  fetchReceivedBids,
  fetchReceivedBidsStages,
  placeBidFetch,
  talentStagesFilterRetain,
} from "../../../redux/actions";

import NoDataView from "../ReceivedBids/NoReceivedBidsView";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";
import ToolTips from "../../../components/ToolTip/ToolTips";

const data = [
  {
    _id: 1,
    Created: "Created",
    Title: "Title",
    FacilityName: "FacilityName",
    Location: "Location",
    BudgetMin: "BudgetMin",
    Review: "Review",
    BudgetMin: "999",
  },
  {
    _id: 2,
    Created: "Created",
    Title: "Title",
    FacilityName: "FacilityName",
    Location: "Location",
    BudgetMin: "BudgetMin",
    Review: "Review",
    BudgetMin: "999",
  },
  {
    _id: 3,
    Created: "Created",
    Title: "Title",
    FacilityName: "FacilityName",
    Location: "Location",
    BudgetMin: "BudgetMin",
    Review: "Review",
    BudgetMin: "999",
  },
  {
    _id: 4,
    Created: "Created",
    Title: "Title",
    FacilityName: "FacilityName",
    Location: "Location",
    BudgetMin: "BudgetMin",
    Review: "Review",
    BudgetMin: "999",
  },
  {
    _id: 5,
    Created: "Created",
    Title: "Title",
    FacilityName: "FacilityName",
    Location: "Location",
    BudgetMin: "BudgetMin",
    Review: "Review",
    BudgetMin: "999",
  },
];

const PlaceBids = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(window.innerWidth > 1000 ? 24 : 20);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isLocation, setIsLocation] = useState(false);

  const [placedBidStage, setplacedBidStage] = useState();
  const [placedBidStageStageName, setplacedBidStageName] = useState("All");
  const [placedBidSort, setplacedBidSort] = useState("most_recent");

  const [filterDrawer, setFilterDrawer] = useState(false);
  const [sortDrawer, setSortDrawer] = useState(false);

  const receivedBids = useSelector((state) => state.BidsReducer?.bids);

  const placedBidsTotal = useSelector((state) => state.BidsReducer?.totalCount);

  // useUpdateLogger("bids:", receivedBids);

  const placedBidStatus = useSelector((state) => state.BidsReducer.bidStatus);
  const placedloading = useSelector(
    (state) => state.BidsReducer.loadingBidRequest
  );
  const { placeBidFilter, placeBidSort, placeBidPage } = useSelector(
    (state) => state.BidsReducer.Filter
  );
  const placedBidsCount = useSelector(
    (state) => state.BidsReducer?.ManageBidCountPlaceBid
  );

  // // console.log(placedBidsCount, "receivedBidsCount");
  // // console.log(receivedBids,"receivedBidsPlaceBid")

  // useUpdateLogger("placeBidFilter", placeBidFilter);

  // useEffect(() => {
  //   // console.log("heyyy");
  //   handleFilterStage(undefined, "All");
  // }, []);

  useEffect(() => {
    if (isLocation && navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        // console.log("Location Latitude is :", position.coords.latitude);
        // console.log("Location Longitude is :", position.coords.longitude);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setIsLocation(false);
      });
    }
  }, [isLocation, navigator.geolocation]);

  const handleFilterDrawer = (state) => {
    setFilterDrawer(state);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleSortDrawer = (state) => {
    setSortDrawer(state);
  };

  const closeSortDrawer = () => {
    setSortDrawer(false);
  };

  const filterOptions = [
    "New",
    "Interested",
    "Not Interested",
    "Screening Awaited",
    "Screening Scheduled",
    "Screening Cancelled",
    "Screening Result Awaited",
    "Profile Submitted",
    "Interview - Client",
    "Interview - Client's Cancelled",
    "Interview - Client's Result Awaited",
    "Hired",
    "Credentialing",
    "On Billing",
    "Billing Extended",
  ];

  useEffect(() => {
    dispatch(fetchReceivedBidsStages());
    dispatch(
      placeBidFetch(
        placeBidSort?.bidSort ? placeBidSort?.bidSort : "most_recent",
        latitude,
        longitude,
        page,
        perPage,
        placeBidFilter?.Id
      )
    );
    // console.log("perPage", page);
  }, [latitude, longitude, placeBidFilter, placeBidSort, page]);

  const handleFilterStage = (stage, stage1) => {
    // console.log(stage, stage1, "stage");
    setPage(1);
    dispatch(
      talentStagesFilterRetain({
        bidPage: 1,
        BidType: "placeBidPage",
      })
    );
    setplacedBidStage(stage);
    setplacedBidStageName(stage1);
    dispatch(
      talentStagesFilterRetain({
        Id: stage === 14 ? 23 : stage,
        name: stage1,
        BidType: "placeBidFilter",
      })
    );
  };

  const filterReceiveBids = (value) => {
    // console.log(`selected ${value}`);
  };

  // useUpdateLogger("placedBidStatus", placedBidSort);

  const filterMenu = (
    <Menu>
      <Menu.Item
        key={undefined}
        placement="bottomLeft"
        onClick={() => {
          handleFilterStage(undefined, undefined);
        }}
      >
        <div
          className={
            placeBidFilter?.name === undefined
              ? "greenTextRB ToolTipPlaceBid"
              : "ToolTipPlaceBid"
          }
        >
          {"All"}
        </div>
      </Menu.Item>
      {placedBidStatus.map((stage) => {
        return (
          <Menu.Item
            key={stage.BidStatusName}
            placement="bottomLeft"
            onClick={() => {
              handleFilterStage(stage.BidStatusId, stage.BidStatusName);
            }}
          >
            <div
              className={
                placeBidFilter?.name === stage.BidStatusName
                  ? "greenTextRB ToolTipPlaceBid"
                  : "ToolTipPlaceBid"
              }
            >
              {stage.BidStatusName}
              {stage.BidStatusName === "Profile Submitted" && (
                <ToolTips
                  placement="bottom"
                  title="After your bid gets accepted, we’ll submit your profile to the employer/ facility to enter the shortlist process of ensuring that the employer will decide to move forward to schedule an interview with you."
                />
              )}
            </div>
          </Menu.Item>
        );
      })}
      <Divider className="Divider-talentStages" />
      <Menu.Item>
        <span
          className="Bidstage-Favorites"
          onClick={() => handleFilterStage("Favorites", "Favorites")}
        >
          Favorites
          <ToolTips
            placement="topLeft"
            title="We’ll gather a collection of your favorite bids in “Favorites”. Choose the star to add the bid to your favorites."
          />
        </span>
      </Menu.Item>
    </Menu>
  );

  const sortArrayRB = [
    { "Most Recent": "most_recent" },
    { "Pay Package High to Low": "pay_hi_to_low" },
    { "Pay Package Low to High": "pay_low_to_hi" },
    { "Nearest to you": "nearby_location" },
  ];

  const handleSortRB = (sort) => {
    setPage(1);
    dispatch(
      talentStagesFilterRetain({
        bidPage: 1,
        BidType: "placeBidPage",
      })
    );
    handleSortDrawer(!sortDrawer);
    // console.log(sort);
    // console.log(isLocation);
    setplacedBidSort(sort);
    if (sort === "nearby_location") {
      dispatch(
        talentStagesFilterRetain({
          bidSort: sort,
          BidType: "placeBidSort",
        })
      );
      setIsLocation(true);
    } else {
      dispatch(
        talentStagesFilterRetain({
          bidSort: sort,
          BidType: "placeBidSort",
        })
      );
      setIsLocation(false);
    }
  };

  const sortMenu = (
    <Menu>
      {sortArrayRB.map((sort) => {
        // // console.log("placeBidSort", sortArrayRB);
        return (
          <Menu.Item onClick={() => handleSortRB(Object.values(sort)[0])}>
            <div className="sortItem-bids">
              <span
                className={
                  Object.values(sort)[0] !== placeBidSort?.bidSort
                    ? placeBidSort?.bidSort
                    : "Most Recent"
                    ? "greenTextRB"
                    : null
                }
              >
                {Object.keys(sort)[0]}
              </span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const onChangeSort = (e) => {
    // console.log(e.target.value);
    setPage(1);
    dispatch(
      talentStagesFilterRetain({
        bidPage: 1,
        BidType: "placeBidPage",
      })
    );

    setplacedBidSort(e.target.value);
    if (e.target.value === "nearby_location") {
      setIsLocation(true);
    } else setIsLocation(false);
  };

  const showItemsSort = () => {
    setPage(1);
    dispatch(
      talentStagesFilterRetain({
        bidPage: 1,
        BidType: "placeBidPage",
      })
    );
    dispatch(
      talentStagesFilterRetain({
        bidSort: placedBidSort,
        BidType: "placeBidSort",
      })
    );
    handleSortDrawer(!sortDrawer);
    // dispatch(
    //   fetchReceivedBids(
    //     16,
    //     0,
    //     "All",
    //     location.state.skillListingId,
    //     placedBidSort
    //   )
    // );
  };

  const paginationHandle = (e) => {
    // console.log(e);
    setPage(e);
    dispatch(
      talentStagesFilterRetain({
        bidPage: e,
        BidType: "placeBidPage",
      })
    );
  };

  // console.log(placedloading, "placedloading");

  return (
    <div className="receive-bid-page">
      <div className="received-bids-main-box">
        <section className="received-bids-filter-header">
          <div className="receive-bids-count">
            <span className="receive-bid-fonts">{placedBidsCount > 1 ? 'Placed bids' : 'Placed bid' }</span>
            <span className="receive-bid-font-item">
              {placedloading
                ? null
                : `${placedBidsTotal > 0 ? placedBidsCount : 0}${
                    placedBidsCount > 1 ? " items" : " item"
                  }`}
            </span>
          </div>
          {/* {!placedloading && ( */}
          <div className="bid-filter-buttons">
            <div>
              {/* <Space direction="vertical">
              <Space wrap> */}
              <Dropdown
                trigger="click"
                placement="bottomRight"
                overlay={filterMenu}
                // overlayClassName="dropdownClassRB"
                overlayClassName="dropdownClassRB"
                disabled={window.innerWidth < 768 ? true : false}
              >
                <div
                  className="filter-received-bids"
                  onClick={() => {
                    if (window.innerWidth < 768)
                      handleFilterDrawer(!filterDrawer);
                  }}
                >
                  <div className="received-bids-fontfilters">
                    {placeBidFilter?.name ? placeBidFilter?.name : "All"}
                  </div>
                  <Drawer
                    title="Filter By"
                    placement="top"
                    onClose={closeFilterDrawer}
                    visible={filterDrawer}
                    className="filterReceivedBidsDrawer"
                    height="40rem"
                    width="100%"
                  >
                    {filterMenu}
                  </Drawer>
                  <img src={FilterIcon} alt="filterIcon" />
                </div>
              </Dropdown>
            </div>
            <Dropdown
              trigger="click"
              placement="bottomRight"
              overlay={sortMenu}
              overlayClassName="dropdownSort"
              disabled={window.innerWidth < 768 ? true : false}
            >
              <div
                className="sort-received-bids"
                // onClick={() => {
                //   if (window.innerWidth < 768) {
                //     handleSortDrawer(false);
                //   }
                // }}
              >
                <img
                  src={sortIcon}
                  alt="sortIcon"
                  onClick={() => {
                    window.innerWidth < 768 && handleSortDrawer(!sortDrawer);
                  }}
                />
                <Drawer
                  title="Sort bids by"
                  placement="top"
                  onClose={closeSortDrawer}
                  visible={window.innerWidth < 768 ? sortDrawer : false}
                  className="sortReceivedBidsDrawer"
                  height="40rem"
                  width="100%"
                >
                  <Radio.Group
                    className="radioSortReceivedBidsGroup"
                    onChange={onChangeSort}
                    // value={placedBidSort}
                  >
                    <Space direction="vertical">
                      <Radio
                        className="radioSortReceivedBids"
                        value={"most_recent"}
                      >
                        Most recent
                      </Radio>
                      <Radio
                        className="radioSortReceivedBids"
                        value={"pay_hi_to_low"}
                      >
                        Pay package high to low
                      </Radio>
                      <Radio
                        className="radioSortReceivedBids"
                        value={"pay_low_to_hi"}
                      >
                        Pay package low to high
                      </Radio>
                      <Radio
                        className="radioSortReceivedBids"
                        value={"nearby_location"}
                      >
                        Nearest to you
                      </Radio>
                    </Space>
                  </Radio.Group>
                  <div className="radioSortShowItemsParent">
                    <div
                      className="radioSortShowItemsChild"
                      onClick={showItemsSort}
                    >
                      Show items
                    </div>
                  </div>
                </Drawer>
              </div>
            </Dropdown>
          </div>
          {/* )} */}
        </section>
        <section className="receive-bids-card-section">
          {placedloading ? (
            <Loader />
          ) : (
            <>
              {receivedBids && receivedBids.length > 0 ? (
                <>
                  {receivedBids.map((bid) => {
                    return (
                      <>
                        <BidCard
                          gig={bid.gig}
                          bid={bid}
                          key={bid._id}
                          BidStatuses={placedBidStatus}
                          isPlaceBids={true}
                          stage={placedBidStage}
                        />
                      </>
                    );
                  })}
                </>
              ) : (
                <NoDataView />
              )}
            </>
          )}
        </section>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5rem",
          }}
        >
          {placedBidsCount > perPage && !placedloading && (
            <Pagination
              hideOnSinglePage={true}
              defaultCurrent={placeBidPage?.bidPage ? placeBidPage?.bidPage : 1}
              total={placedBidsCount}
              // simple={true}
              defaultPageSize={24}
              onChange={(e) => paginationHandle(e)}
              showSizeChanger={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlaceBids;
