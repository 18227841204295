import axios from "axios";
import store from "../redux/store";
import { getCookie } from "../shared/helpers";
import { Auth } from "../config/aws-exports";
import { responsesAreSame } from "workbox-broadcast-update";
import { useDispatch, useSelector } from "react-redux";
import { receivedMessage } from "../redux/actions";

const BackendApiBaseUrl = process.env.REACT_APP_API_URL;

const fetchDataUrl = `${BackendApiBaseUrl}/`; //sample
const sendDataUrl = `${BackendApiBaseUrl}/`; //sample
const updateProfileUrl = `${BackendApiBaseUrl}/profile`; // need to append /profileId later
const fetchRoleDiscipline = `${BackendApiBaseUrl}/getDiscipline`;
const uploadFileUrl = `${BackendApiBaseUrl}/upload`; // need to append /profileId later
const uploadDPUrl = `${BackendApiBaseUrl}/upload`; // need to append /profileId later
const uploadResumeUrl = `${BackendApiBaseUrl}/uploadResume`; // need to append /profileId later
const fetchDocUrl = `${BackendApiBaseUrl}/credentialing/userdoc`;
const uploadDocUrl = `${BackendApiBaseUrl}/document/upload`;
const fetchProfileDetails = `${BackendApiBaseUrl}/profile/me`;
const fetchExistUserUrl = `${BackendApiBaseUrl}/auth/checkUser`;
const getSkillsUrl = `${BackendApiBaseUrl}/skill/getSkills`;
const skillListingUrl = `${BackendApiBaseUrl}/skill/listing`;
const fetchProfileExperience = `${BackendApiBaseUrl}/profile/experienceLevels`;
const addToFavouriteUrl = `${BackendApiBaseUrl}/empprofile/favorites/add`;
const removeFromFavouriteUrl = `${BackendApiBaseUrl}/empprofile/favorites/remove`;

const fetchProposalsUrl = `${BackendApiBaseUrl}/proposal/placedbids`;
const acceptOfferUrl = `${BackendApiBaseUrl}/interview/offer/update`;
// const acceptOfferUrl = `http://192.168.18.101:5000/production/interview/offer/update`;

const messagesCountUrl = `${BackendApiBaseUrl}/empprofile/messages/conversationsCounts`;
// const acceptOfferUrl = `http://192.168.18.149:5000/development/interview/offer/update`;
const createBidUrl = `${BackendApiBaseUrl}/proposal`;
// const createBidUrl = `http://localhost:3000/staging/proposal`;
const updateBidUrl = `${BackendApiBaseUrl}/proposal/placedbids/update`;
const fetchMessagesUrl = `${BackendApiBaseUrl}/message`;
const fetchBidDataUrl = `${BackendApiBaseUrl}/proposal`;

const getChatHistoryUrl = `${BackendApiBaseUrl}/empprofile/messages/get`;
// const getChatHistoryUrl = `http://192.168.18.149:3000/production/empprofile/messages/get`;
const sendMessageUrl = `${BackendApiBaseUrl}/message/send`;
const sendAttachments = `${BackendApiBaseUrl}/message/attachment`;
const receivedBidStatusUrl = `${BackendApiBaseUrl}/proposal/bidstatus`;
const fetchReceivedBidsUrl = `${BackendApiBaseUrl}/bids/receivedbids/`;
const fetchReceivedBidCountUrl = `${BackendApiBaseUrl}/bids/receivedbidcounts`;
const fetchReceivedBidsDetailUrl = `${BackendApiBaseUrl}/bids`;
const updateReceivedBidsUrl = `${BackendApiBaseUrl}/proposal/receivedbids/update`;

const getGraphDataUrl = `${BackendApiBaseUrl}/timesheet/getgraph`;
const fetchTimeListUrl = `${BackendApiBaseUrl}/timesheet/get`;
const createTimesheetItemsUrl = `${BackendApiBaseUrl}/timesheet`;
const upateTimeSheetUrl = `${BackendApiBaseUrl}/timesheet`;
const createTimeSheetCorrectionUrl = `${BackendApiBaseUrl}/timesheet/corrections`;
const userAssignmentUrl = `${BackendApiBaseUrl}/timesheet/userassignment`;
const getExistingListItem = `${BackendApiBaseUrl}/timesheet/get/exists`;

const fetchDashboardStats = `${BackendApiBaseUrl}/profile/dashboardStats`;

const getUserJobDetailsURL = `${BackendApiBaseUrl}/profile/userJobAssigned`;

const addGigCardFavorite = `${BackendApiBaseUrl}/favorite`;
const removeGigCardFavorites = `${BackendApiBaseUrl}/favorite`;

// const getGraphDataUrl=`http://192.168.18.49:3000/development/timesheet/getgraph`;
// const fetchTimeListUrl = `http://192.168.18.40:3000/development/timesheet/get`;
// const createTimesheetItemsUrl=`http://192.168.18.49:3000/development/timesheet`;
// const upateTimeSheetUrl =`http://192.168.18.49:3000/development/timesheet`;
// const createTimeSheetCorrectionUrl='http://192.168.18.49:3000/development/timesheet/corrections';
// const userAssignmentUrl=`http://192.168.18.49:3000/development/timesheet/userassignment`;
// const getExistingListItem=`http://192.168.18.49:3000/development/timesheet/get/exists`;

const deleteResumeURL = `${BackendApiBaseUrl}/profile/removeResume`;
const gigSearchURL = `${BackendApiBaseUrl}/query`;
const getMyFavoritesURL = `${BackendApiBaseUrl}/favorites`;
// const retractedBidStatusUrl = `${BackendApiBaseUrl}/types/sort`;
const fetchRetractedBidsUrl = `${BackendApiBaseUrl}/types/sort/`;

const fetchRetractedBidData = `${BackendApiBaseUrl}/proposal/all/retractedbids`;

const fetchPlaceBidData = `${BackendApiBaseUrl}/proposal/all/placedbids`;
const fetchInterviewCardUrl = `${BackendApiBaseUrl}/interview/get-interviews`;

const fetchInterviewURL = `${BackendApiBaseUrl}/interview/get-interviews`;
const updateInterviewURL = `${BackendApiBaseUrl}/interview/update`;
// const updateInterviewURL = `http://192.168.18.149:5000/production/interview/update`;
// const updateInterviewURL = `${BackendApiBaseUrl}/interview/offer/update`;
const updateReceivedBidURL = `${BackendApiBaseUrl}/bids/receivedbids/update`;
// const updateReceivedBidURL = `http://192.168.18.149:4000/production/bids/receivedbids/update`;

//*****  3D Resume APIs  start  *****//
const fetchResumeGetfollowersURL = `${BackendApiBaseUrl}/profile/getfollowers`;
const fetchResumeGetviewsURL = `${BackendApiBaseUrl}/profile/getviews`;
const fetchResumeDetailURL = `${BackendApiBaseUrl}/profile/detail`;
//*****  3D Resume APIs  end  *****//

//****Message Decline Reasons API ******/
const fetchDeclineReasonsURL = `${BackendApiBaseUrl}/bids/declinedstatus`;

const fetchHomePageDataURL = `${BackendApiBaseUrl}/home/jobs`;

// Notification API //
const fetchNotificationURL = `${BackendApiBaseUrl}/profile`;
const retractBidUpdate = `${BackendApiBaseUrl}/proposal/retractedbid/update`;
// const retractBidUpdate = `http://192.168.100.171:3000/staging/proposal/retractedbid/update`;
// const retractBidUpdate = `http://192.168.18.25:5000/production/proposal/retractedbid/update`;

// Rating API //
// const createRatingUrl = `http://192.168.18.149:3000/production/empprofile/ratings/create`;
const createRatingUrl = `${BackendApiBaseUrl}/empprofile/ratings/create`;
const updateRatingUrl = `${BackendApiBaseUrl}/empprofile/ratings/update`;
// const updateRatingUrl = `http://192.168.18.149:3000/production/empprofile/ratings/update`;
const deleteRatingUrl = `${BackendApiBaseUrl}/empprofile/rating/delete`;
// const deleteRatingUrl = `http://192.168.18.149:3000/production/empprofile/rating/delete`;
// download resume API
const downloadResumeURL = `${BackendApiBaseUrl}/message/getattachment`;

//Chats APIs//
const getInboxUrl = `${BackendApiBaseUrl}/empprofile/messages/all`;
// const getInboxUrl = `http://192.168.18.16:3000/production/empprofile/messages/all`;

//Notifications*********
const notifications = `${BackendApiBaseUrl}/empprofile/notifications/get`;
// const notifications = `http://192.168.18.101:4000/production/empprofile/notifications/get`;
const readNotificationUrl = `${BackendApiBaseUrl}/empprofile/notification/update`;
const checkUserName = `${BackendApiBaseUrl}/auth/check-username`;

const sendEmailVerifyStatusURL = `${BackendApiBaseUrl}/profile/send-verification-link`;

const getASkillListingURL = `${BackendApiBaseUrl}/skill/detail`;
const addUpdateUserDeviceDeatilsURL = `${BackendApiBaseUrl}/profile/user-device`;

//ViewListing API
const fetchViewListingURL = `${BackendApiBaseUrl}/skill/listing/viewers`;
//CompanyProfile API
const fetchCompanyProfileURL = `${BackendApiBaseUrl}/empprofile/getcompanyprofile`;
const fetchCompanyProfilejobcountsURL = `${BackendApiBaseUrl}/empprofile/getjobcounts`;
//addRemoveEmployerFollow API
const addRemoveEmployerFollowURL = `${BackendApiBaseUrl}/empprofile/follow`;
const fetchManageCountURL = `${BackendApiBaseUrl}/profile/manage-bids-count`;
export const sendBidStatusURL = `${BackendApiBaseUrl}/bids/receivedbid/update/`;

let talentToken = null;
let headers = null;
let ImpersonationId = null;

const fetchHeader = (talentToken, ImpersonationId) => {
  // // console.log("toeknTokn:", talentToken);
  const GetEmail = localStorage.getItem("Email");
  // console.log("GetEmailAPI", GetEmail);

  if (ImpersonationId) {
    headers = {
      "Content-Type": "application/json",
      Authorization: talentToken,
      ImpersonationId,
      // params: "61264b4304ff062ef86cec27",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: talentToken,
      // email: GetEmail,
    };
  }

  return headers;
};

export const fetchToken = async () => {
  talentToken = await store.getState().authReducer.user.authToken;
  ImpersonationId = await store.getState().authReducer.impersonationId;

  return fetchHeader(talentToken, ImpersonationId);
};

export const getToken = () => {
  // // console.log(getCookie("talentToken"));
  // // console.log(store.getState());
  // const session = await Auth.currentSession();
  // if (session) {
  //   talentToken = session.getIdToken().getJwtToken();
  //   headers = { "Content-Type": "application/json", Authorization: talentToken };
  //   return headers;
  // }
  talentToken = store.getState().authReducer.user.authToken;
  headers = { "Content-Type": "application/json", Authorization: talentToken };
  return headers;
};

// email verification API
export const sendEmailVerificationStatusAPI = async (params) => {
  // // console.log("fetchEmailVerificationStatusAPI", params);
  // // console.log("paramsEmail", params);

  const headers = await fetchToken();

  const response = await axios.post(`${sendEmailVerifyStatusURL}`, params, {
    headers,
  });
  return response;
};

// fetch Profile Experience
export const fetchProfileExperienceAPI = async () => {
  // console.log("Experience");

  const response = await axios.get(fetchProfileExperience);
  return response;
};

// Fetch Location API
export const fetchSearchLocationAPI = async (value) => {
  // console.log("PARAMS:", value.payload);

  const fetchLocationAPI = `${BackendApiBaseUrl}/skill/locations?location=${value.payload}`;
  const response = await axios.get(fetchLocationAPI);
  return response;
};

// Fetch Country API
export const fetchSearchCountryAPI = async (value) => {
  // console.log("PARAMS:", value.payload);

  const fetchCountryAPI = `${BackendApiBaseUrl}/skill/getcountries?country=${value.payload}`;
  const response = await axios.get(fetchCountryAPI);
  return response;
};

// Fetch Region API
export const fetchSearchRegionAPI = async (value) => {
  // console.log("PARAMS:", value.payload);

  const fetchRegionAPI = `${BackendApiBaseUrl}/skill/getregions?region=${value.payload}`;
  const response = await axios.get(fetchRegionAPI);
  return response;
};

// Fetch City API
export const fetchSearchCityAPI = async (value) => {
  // console.log("PARAMS:", value.payload);

  const fetchCityAPI = `${BackendApiBaseUrl}/skill/locations?location=${value.payload}`;
  const response = await axios.get(fetchCityAPI);
  return response;
};

// Fetch Experince Level API
export const fetchExperincLevelsAPI = async () => {
  const fetchExperinceLevelAPI = `${BackendApiBaseUrl}/skill/getexperincelevels`;
  const response = await axios.get(fetchExperinceLevelAPI);
  return response;
};

// Fetch User Skill Listings
export const fetchUserSkillListingAPI = async (params) => {
  const [type, page, perPage] = params;
  // // console.log("SkillListing", type, page, perPage);
  const headers = await fetchToken();

  const response = await axios.get(
    `${skillListingUrl}?type=${type}&page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

// fetch one skill Listing
export const fetchOneSkillListingAPI = async (id) => {
  const headers = await fetchToken();

  const response = await axios.get(`${getASkillListingURL}/${id}`, { headers });
  return response;
};

// Fetch Discipline API
export const fetchSearchDisciplineAPI = async (value) => {
  // console.log("PARAMS:", value.payload);
  const categoryId = value.payload.profCode === "HC" ? 3 : 1;
  const fetchDisciplineAPI = `${BackendApiBaseUrl}/skill/discipline?discipline=${value.payload.val}&categoryId=${categoryId}`;
  const response = await axios.get(fetchDisciplineAPI);
  return response;
};

// LinkedIN User
const fetchLinkedInToken = `${BackendApiBaseUrl}/auth/linkedInSignin`;

export const approveLinkedInUserAPI = async (talentToken) => {
  // console.log("PARAMS:", talentToken);
  // try {
  const response = await axios.post(
    fetchLinkedInToken,
    {
      code: talentToken,
      redirectURL: `${process.env.REACT_APP_MY_URL}/linkcheck`,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
  // } catch (err) {
  //   return err.response;
  // }
};

export const fetchDocsList = async () => {
  // // console.log("Fetching Doc List:", headers);
  const headers = await fetchToken();
  const response = await axios.get(fetchDocUrl, { headers });
  return response;
};

export const uploadFileApi = async (request, userToken) => {
  // console.log("PARAMS:", request);
  // // console.log(await fetchToken());
  const headers = await fetchToken();
  // // console.log(headers);
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: userToken,
  // };
  const { file } = request.params;
  const contentType = file.type;
  // const payload = {contentType, type  }
  request.params["contentType"] = contentType;
  const response = await axios.post(uploadFileUrl, request.params, {
    headers,
  });
  // console.log("UPLOAD FILE FIRST RESPONSE", response);
  const { uploadURL, Key } = response.data;
  const uploadResponse = await axios.put(uploadURL, file);
  // console.log("Upload File Response", uploadResponse);
  if (uploadResponse.status == 200) {
    // console.log("FILE UPLOAD SUCCESS");
    return { data: { status: "success", payload: Key } };
  } else {
    // console.log("FILE UPLOAD FAIL");
    return {
      data: {
        status: "error",
        error: { message: "File upload failed", code: uploadResponse.status },
      },
    };
  }
};

// deleting Resume from Profile
export const resumeDeleteAPI = async () => {
  // console.log("DELETE API");
  const headers = await fetchToken();

  const response = await axios.delete(deleteResumeURL, { headers });
  return response;
};

export const uploadDocApi = async (request) => {
  // console.log("PARAMS:", request);
  const headers = await fetchToken();

  const response = await axios.post(uploadDocUrl, request.params, {
    headers,
    // headers: { "Content-Type": "multipart/form-data", Authorization: talentToken },
  });
  // console.log("Upload Doc Response", response);
  return response;
};

export const removeDocApi = async (request) => {
  // console.log("REQUEST in REMOVE DOC:", request);
  const headers = await fetchToken();

  const response = await axios.delete(`${fetchDocUrl}/${request.params}`, {
    // headers: { Authorization: talentToken },
    headers,
  });
  // console.log("Remove Doc Response", response);
  return response;
};

// export const fetchProfileURL = async ({ talentToken, impersonationId = null }) => {
export const fetchProfileURL = async (params) => {
  // console.log("TOKEN IN API:", params);

  if (params?.impersonationId) {
    headers = fetchHeader(params.talentToken, params.impersonationId);
  } else {
    headers = await fetchToken();
  }

  const response = await axios.get(fetchProfileDetails, {
    // headers: { Authorization: talentToken ,impersonationId},
    headers,
  });

  return response;
};

export const fetchRoleDisciplineAPI = async (params) => {
  // // console.log("HALO", params.payload);

  const key = params.payload.key;
  const talentToken = params.payload.authToken;
  const profession = params.payload.pro;

  // console.log("HALO", profession);

  const headers = await fetchToken();
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: talentToken,
  // };

  const response = await axios.get(
    `${fetchRoleDiscipline}?search=${key ? key : ""}&profession=${profession}`,
    { headers }
  );

  return response;
};

export const updateProfileApi = async (request) => {
  // console.log("UPDATE PROFILE PAYLOAD:", request.params);
  // // console.log("Headers", headers);
  // if (headers == null) {
  const headers = await fetchToken();
  // }
  // console.log("Headers", headers);
  const response = await axios.put(`${updateProfileUrl}`, request.params, {
    headers,
  });
  // console.log("Update Profile Response", response);
  return response;
};

export const uploadProfilePicApi = async ({ params }) => {
  // console.log("PARAMS:", params);
  // console.log("TYPE OF PARAMS:", typeof params);

  await fetchToken();

  const response = await axios.put(`${uploadDPUrl}`, params, {
    // headers,
    headers: { "Content-Type": "multipart/form-data", Authorization: talentToken },
  });
  // console.log("Upload Doc Response", response);
  return response;
};

export const uploadResumeApi = async ({ params }) => {
  // console.log("PARAMS:", params);
  // console.log("TYPE OF PARAMS:", typeof params);
  await fetchToken();
  // const headers = await fetchToken();

  const response = await axios.put(`${uploadResumeUrl}`, params, {
    // headers,
    headers: { "Content-Type": "multipart/form-data", Authorization: talentToken },
  });
  // console.log("Upload Doc Response", response);
  return response;
};

export const fetchSkillsApi = async (request) => {
  // console.log("CREATE SKILL LISTING PAYLOAD:", request.params);
  console.log("amide", request?.params)
  let categoryId;
  if (request?.params?.profCode) {
    categoryId = request.params.profCode === "IT" ? 1 : 3;
  }
  const response = await axios.get(
    categoryId ?
      `${getSkillsUrl}?skillName=${request.params.val ? request.params.val : ""
      }&SkillCategoryId=${categoryId}`
      : `${getSkillsUrl}?skillName=${request.params.val ? request.params.val : "A"
      }`
  );
  // console.log("Fetch Skill Listing Response", response);
  return response;
};

export const createSkillListingApi = async (request) => {
  // console.log("CREATE SKILL LISTING PAYLOAD:", request.params);

  const headers = await fetchToken();

  const response = await axios.post(`${skillListingUrl}`, request.params, {
    headers,
  });
  // console.log("Create Skill Listing Response", response);
  return response;
};

export const updateSkillListingApi = async (request) => {
  // console.log("UPDATE SKILL LISTING PAYLOAD:", request.params);

  const headers = await fetchToken();

  const response = await axios.put(
    `${skillListingUrl}/${request.params._id}`,
    request.params,
    {
      headers,
    }
  );
  // console.log("Update Skill Listing Response", response);
  return response;
};

export const deleteSkillListingApi = async (request) => {
  const id = request.params;
  // console.log("DELETE SKILL LISTING PAYLOAD:", id);

  const headers = await fetchToken();

  const response = await axios.delete(`${skillListingUrl}/${id}`, {
    headers,
  });
  // console.log("Delete Skill Listing Response", response);
  return response;
};

export const fetchDataApi = async (params) => {
  return await axios.get(fetchDataUrl);
};

export const sendDataApi = async (params) => {
  const data = JSON.stringify(params);
  return await axios.post(sendDataUrl, data, { headers });
};

export const checkUserApi = async (request) => {
  // const data = JSON.stringify(params);
  // console.log(request);
  // // console.log(data);
  const headers = await fetchToken();

  const response = await axios.post(
    fetchExistUserUrl,
    { email: request.params },
    { headers }
  );
  // console.log("User Existance: ", response);
  return response;
};

// Bids
// Proposal
export const fetchProposalsApi = async (id, limit, skip, status) => {
  const headers = await fetchToken();
  return axios.get(`${fetchProposalsUrl}/${limit}/${skip}/${status}`, {
    headers,
  });
};

export const acceptOfferApi = async (request) => {
  // console.log("accept api offer ===========>", request?.payload);
  const headers = await fetchToken();
  const data = JSON.stringify(request.payload);
  return await axios.put(`${acceptOfferUrl}/${request?.payload?.id}`, data, {
    headers,
  });
};

export const createBidApi = async (request) => {
  const data = JSON.stringify(request.payload);
  // console.log(JSON.stringify(request));
  // console.log("proposalData", request.payload, "abc", request);
  const headers = await fetchToken();
  return await axios.post(createBidUrl, data, { headers });
};

export const updateBidApi = async (request) => {
  const data = JSON.stringify(request.payload);
  // // console.log(JSON.stringify(request));
  const headers = await fetchToken();
  return await axios.put(`${updateBidUrl}/${request.payload.id}`, data, {
    headers,
  });
};
//from chat
export const updateGigApi = async (request) => {
  // const data = JSON.stringify(request.payload);
  // // console.log(JSON.stringify(request));
  const headers = await fetchToken();
  return await axios.put(
    `${updateReceivedBidURL}/${request.payload.id}`,
    request.payload,
    {
      headers,
    }
  );
};

export const retractBidApi = async (request) => {
  const data = JSON.stringify(request.payload);
  // console.log(JSON.stringify(request));
  const headers = await fetchToken();
  return await axios.put(`${updateBidUrl}/${request.payload.id}`, data, {
    headers,
  });
};

export const fetchMessagesAPI = async (messageType, limit, skip) => {
  const headers = await fetchToken();
  return await axios.get(
    `${fetchMessagesUrl}/${messageType}/${limit}/${skip}`,
    { headers }
  );
};

export const fetchInboxDataAPI = async (
  limit,
  skip,
  type = null,
  bidType = null,
  bidStatus
) => {
  // console.log("fetched_Inbox_API", limit, skip);
  const headers = await fetchToken(); // authentication
  // // console.log("fetched_Inbox_API talentToken", headers)

  return await axios.get(
    `${fetchMessagesUrl}/all/${limit}/${skip}?type=${type}&bidType=${bidType}&bidStatus=${bidStatus}`, //data fetch
    { headers }
  );
};

export const fetchBidDataApi = async (id) => {
  // console.log("fetch bid data api hit");
  const headers = await fetchToken();
  return axios.get(`${fetchBidDataUrl}/${id}`, { headers });
};

export const SendMessageAPI = async (
  conversationId,
  name,
  recipientId,
  message,
  profPic,
  fileUploadResponse
) => {
  const headers = await fetchToken();
  headers["Content-Type"] = "multipart/form-data";
  const data = new FormData();

  data.append("ConversationId", conversationId);
  data.append("MessageText", message);
  for (let i = 0; i < fileUploadResponse.length; i++)
    data.append("Attachments[]", fileUploadResponse[i]);

  const req = await axios.post(`${sendMessageUrl}`, data, { headers });
};

export const fetchChatHistoryApi = async (id) => {
  const headers = await fetchToken();
  return axios.get(`${getChatHistoryUrl}/${id}`, { headers });
};

export const fetchReceivedBidsStatusAPI = async () => {
  // console.log("Status hit");

  const headers = await fetchToken();

  const response = await axios.get(receivedBidStatusUrl, { headers });
  // console.log("Status API hit", response);

  return response;
};

export const fetchReceivedBidsApi = async (
  BidStatusName,
  skillListingId,
  sortBy,
  lat,
  long,
  page,
  perPage
) => {
  const location = `latitude=${lat}&longitude=${long}&maxDistance=50&`;
  // console.log("API ID:", skillListingId, lat, long, page, perPage);
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchReceivedBidsUrl}${skillListingId ? skillListingId : null
    }/${sortBy}?${lat && long ? location : ""
    }page=${page}&perPage=${perPage}&status=${BidStatusName}
    `,
    {
      headers,
    }
  );

  const ManageBidCountRes = await axios.get(
    `${fetchManageCountURL}${BidStatusName !== "All" ? `?BidStatusId=${BidStatusName}` : ""
    }`,
    { headers }
  );
  return [response, ManageBidCountRes];
};
// export const downloadChatFilesApi=(FilePath)=>{
//   const headers=await fetchToken();
//   return axios.get(`${downloadFilesfromChat}/${FilePath}`,{headers});
// }

export const fetchReceivedBidCountApi = async (request) => {
  const headers = await fetchToken();
  return await axios.get(
    `${fetchReceivedBidCountUrl}/${request.skillListingId}`,
    { headers }
  );
};

export const updateReceivedBidsApi = async (request) => {
  const data = JSON.stringify(request.payload);
  // console.log(JSON.stringify("data: ", data));
  const headers = await fetchToken();
  return await axios.put(
    `${updateReceivedBidsUrl}/${request.payload.id}`,
    data,
    { headers }
  );
};

export const SearchGigsApi = async (request) => {
  // const data = JSON.stringify(params);
  // console.log(request);
  // // console.log(data);
  const response = await axios.post(gigSearchURL, request.params, { headers });
  // console.log("Search Gigs Result : ", response);
  return response;
};
export const getGraphDataApi = async (type, startdate, enddate) => {
  // const headers = await fetchToken();
  // return axios.get(`${getGraphDataUrl}/${type}/${startdate}/${enddate}`, {
  //   headers,
  // });
};

// export const fetchTimeSheetListApi=(limit, skip)=>{
//   const headers=await fetchToken();
//   // console.log("limit api",JSON.stringify(limit))
//   return axios.get(`${fetchTimeListUrl}/${limit}/${skip}`,{headers});
// }

export const fetchTimeSheetListApi = async (limit, skip, type, start, end) => {
  const headers = await fetchToken();
  // console.log("limit api", JSON.stringify(limit));
  return axios.get(
    `${fetchTimeListUrl}/${type}/${limit}/${skip}/${start}/${end}`,
    { headers }
  );
};

//TimeSheet Correction
//create
export const createTimeSheetCorrectionApi = async (request) => {
  const data = JSON.stringify(request.payload);
  // console.log(JSON.stringify(request));
  const headers = await fetchToken();
  return await axios.post(createTimeSheetCorrectionUrl, data, { headers });
};
export const createTimeSheetItemsApi = async (request) => {
  const data = JSON.stringify(request.payload);
  // console.log(JSON.stringify(request.payload));
  const headers = await fetchToken();
  return await axios.post(createTimesheetItemsUrl, data, { headers });
};
export const updateTimeSheetApi = async (request) => {
  // console.log(request.payload);
  const headers = await fetchToken();
  return await axios.put(
    `${upateTimeSheetUrl}/${window.timesheetId}`,
    request.payload,
    { headers }
  );
};

// userAssignmentUrl
export const userAssignmentApi = async () => {
  const headers = await fetchToken();
  return await axios.get(`${userAssignmentUrl}`, { headers });
};

export const getExistingListemItemAPi = async () => {
  // console.log("api called");
  const headers = await fetchToken();
  return await axios.get(`${getExistingListItem}`, { headers });
};

// SEARCHSUGGESTION
export const fetchSearchSuggestionsAPI = async (value) => {
  const headers = await fetchToken();
  // console.log("Search suggestion PARAMS:", value.payload);
  const response = await axios.get(
    `${BackendApiBaseUrl}/search?search=${value.payload}`,
    { headers }
  );
  //const fetchSuggestionAPI = `${BackendApiBaseUrl}/search?search=${value.payload}` ;
  //const response = await axios.get(fetchSuggestionAPI );
  return response;
};

// talent info API
export const talentInfoAPI = async (talentToken) => {
  // const headers = { "Content-Type": "application/json", Authorization: talentToken };
  const headers = await fetchToken();

  // console.log("headers", headers);
  return await axios.get(`${updateProfileUrl}/detail`, { headers });
};

// Analytic info API
export const AnalyticInfoAPI = async (talentToken) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // console.log("headers", headers);
  const response = await axios.get(`${fetchDashboardStats}`, { headers });
  return response;
};

export const fetchUserJobDetailsAPI = async (value) => {
  var payloadvalue = value.payload.slice(0, -9);
  var updatepayloadvalue;
  if (payloadvalue === "On Billing") {
    updatepayloadvalue = "Hired";
    // console.log("payloadvalue",payloadvalue);
  }
  else if (payloadvalue === "Completed") {
    updatepayloadvalue = "Off Billing";
    // console.log("payloadvalue",payloadvalue);
  }
  else {
    updatepayloadvalue = payloadvalue;
  }
  const headers = await fetchToken(payloadvalue, "payloadvalue");
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=${updatepayloadvalue}`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsCompletedAPI = async (value) => {
  // console.log("job details api hit", value.payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Off Billing`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsCanceledAPI = async (value) => {
  // console.log("job details api hit", value.payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Cancelled`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsTerminatedAPI = async (value) => {
  // console.log("job details api hit", value.payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Terminated`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsActiveAPI = async (value) => {
  // console.log("job details api hit", value.payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Hired`,
    { headers }
  );
  return response;
};

// myFavorites info API
export const myFavoritesInfoAPI = async (page, perPage) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // console.log("headers", headers);
  const response = await axios.get(
    `${getMyFavoritesURL}?&page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

export const addGigCardFavoriteAPI = async (value) => {
  const data = value.payload;
  const headers = await fetchToken();
  // console.log("favoriteAPI", value.payload);
  return await axios.post(
    `${addGigCardFavorite}/${data[0]}`,
    { SourceName: data[1] },
    {
      headers,
    }
  );
};

export const removefavoriteGigCardAPI = async (value) => {
  const data = value.payload;
  const headers = await fetchToken();
  // console.log("removefavoriteAPI", value.payload[1]);
  return await axios.delete(`${removeGigCardFavorites}/${data[0]}`, {
    headers,
  });
};

// profile Education History
export const getCollegeEducationHistoryAPI = async (key) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  const response = await axios.get(
    `${updateProfileUrl}/getCollegeMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const getCourseEducationHistoryAPI = async (key) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  const response = await axios.get(
    `${updateProfileUrl}/getCourseMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const getCourseTypeEducationHistoryAPI = async (key) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  const response = await axios.get(
    `${updateProfileUrl}/getCourseType?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const postEducationHistoryAPI = async (data) => {
  // console.log("Education:", data);
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // debugger;
  // console.log("Education:", headers);

  const response = await axios.post(
    `${updateProfileUrl}/educationHistory`,
    data,
    {
      headers,
    }
  );
  return response;
};

export const addToFavouriteApi = async (data) => {
  // console.log("Education in add to favourite:", data);
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // debugger;

  const response = await axios.post(`${addToFavouriteUrl}`, data.payload, {
    headers,
  });
  return response;
};

export const removeFromFavouriteApi = async (data) => {
  // console.log("Education in remove from favourite:", data?.payload?.SourceId);
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // debugger;

  const response = await axios.put(
    `${removeFromFavouriteUrl}/${data?.payload?.SourceId}`,
    data?.payload,
    {
      headers,
    }
  );
  return response;
};

export const updateEducationHistoryAPI = async (params) => {
  const headers = await fetchToken();

  const data = JSON.stringify(params[0]);

  const response = await axios.put(
    `${updateProfileUrl}/educationHistory/${params[1]}`,
    data,
    {
      headers,
    }
  );
  return response;
};

// export const fetchCompanyWorkHistoryAPI = async (key) => {
//   // console.log("Work:", key);
//   //const headers = {"Content-Type": "application/json", Authorization: talentToken};
//   const headers = await fetchToken();
//   // debugger;
//   // console.log("Work:", headers);

//   const response = await axios.post(
//     `${updateProfileUrl}/companyMaster?search=${key}`,
//     {
//       headers,
//     }
//   );
//   return response;
// };

export const fetchJobTitleWorkHistoryAPI = async (key) => {
  // console.log("jobTitleMaster:", key);
  const headers = await fetchToken();

  const response = await axios.get(
    `${updateProfileUrl}/jobTitleMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const fetchIndustryWorkHistoryAPI = async (key) => {
  // console.log("Industry:", key);
  const headers = await fetchToken();

  const response = await axios.get(
    `${updateProfileUrl}/industriesMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const fetchLocationWorkHistoryAPI = async (key) => {
  // console.log("Location:", key);
  // const headers = await fetchToken();

  const fetchLocationAPI = `${BackendApiBaseUrl}/skill/locations?location=${key}`;
  const response = await axios.get(fetchLocationAPI);
  return response;
};

export const postWorkHistoryAPI = async (data) => {
  // console.log("Work:", data);
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // debugger;
  // console.log("Work:", headers);

  const response = await axios.post(`${updateProfileUrl}/workHistory`, data, {
    headers,
  });
  return response;
};

export const postCertificatesAndAwardsAPI = async (data) => {
  // console.log("Work:", data);
  const headers = await fetchToken();

  const response = await axios.post(`${updateProfileUrl}/certificate`, data, {
    headers,
  });
  return response;
};

export const updateCertificatesAndAwardsAPI = async (data, id) => {
  // console.log("Work:", data);
  const headers = await fetchToken();

  const response = await axios.put(
    `${updateProfileUrl}/certificate/${id}`,
    data,
    {
      headers,
    }
  );
  return response;
};

export const updateWorkHistoryAPI = async (params) => {
  const headers = await fetchToken();

  const data = JSON.stringify(params[0]);

  const response = await axios.put(
    `${updateProfileUrl}/workHistory/${params[1]}`,
    data,
    {
      headers,
    }
  );
  return response;
};

// export const fetchRetractedBidsStatusAPI = async () => {
//   // console.log("Status hit");

//   const headers = await fetchToken();

//   const response = await axios.get(retractedBidStatusUrl, { headers });
//   // console.log("Status API hit", response);

//   return response;
// };

export const fetchRetractedBidsApi = async (
  perPage,
  page,
  sort,
  longitude,
  latitude,
  maxDistance
) => {
  const location = `latitude=${latitude}&longitude=${longitude}&maxDistance=50`;
  // console.log("API ID:", maxDistance, latitude, longitude, page, perPage);
  // // console.log(
  //   "RB URL:",
  //   `${fetchReceivedBidsUrl}${limit}/${skip}/${BidStatusName}/${
  //     skillListingId ? skillListingId : null
  //   }/${sortBy}/${lat && long && location}`
  // );
  const headers = await fetchToken();
  return axios.get(
    `${fetchRetractedBidsUrl}

    /${sort}?latitude=${latitude}&longitude=${longitude}&maxDistance=${maxDistance}
      latitude && longitude ? 50 : null
    }&page=${page}&perPage=${perPage}`,
    {
      headers,
    }
  );
};

export const fetchRetracteBidDataAPI = async (
  sort,
  lat,
  long,
  page,
  perPage
) => {
  // console.log("Retractebid api hit");
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchRetractedBidData}/${sort}?perPage=${perPage}&page=${page}&longitude=${long}&latitude=${lat}&maxDistance=${lat && long ? 50 : null
    }`,
    { headers }
  );
  return response;
};

export const fetchPlaceBidDataAPI = async (
  sort,
  lat,
  long,
  page,
  perPage,
  BidStatusName
) => {
  //  console.log(
  //   "placebid api hit",
  //   sort,
  //   lat,
  //   long,
  //   page,
  //   perPage,
  //   BidStatusName
  // );
  let urlplaceLat = "";
  let urlplace = "";
  if (lat) {
    urlplaceLat =
      urlplaceLat +
      `latitude=${lat}&longitude=${long}&maxDistance=${lat && long ? 50 : null
      }`;
  } else if (BidStatusName) {
    urlplace = urlplace + `&status=${BidStatusName}`;
  }
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchPlaceBidData}/${sort}?latitude=${lat}&longitude=${long}&maxDistance=${lat && long ? 50 : null
    }&perPage=${perPage}&page=${page}&status=${BidStatusName}`,
    { headers }
  );
  const ManageBidCountRes = await axios.get(
    `${fetchManageCountURL}${BidStatusName !== undefined ? `?BidStatusId=${BidStatusName}` : ""
    }`,
    { headers }
  );
  return [response, ManageBidCountRes];
};

export const fetchDetailPlaceBidDataAPI = async (id) => {
  // console.log("placebid detail api hit", id);
  const headers = await fetchToken();
  const response = await axios.get(`${fetchBidDataUrl}/${id}`, { headers });
  return response;
};

export const fetchDetailReceivedBidDataAPI = async (id) => {
  // console.log("received bid detail api hit", id);
  const headers = await fetchToken();
  const response = await axios.get(`${fetchReceivedBidsDetailUrl}/${id}`, {
    headers,
  });
  return response;
};

// InterviewCard info API
export const InterviewCardAPI = async (talentToken) => {
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // console.log("headers", headers);
  const response = await axios.get(`${fetchInterviewCardUrl}`, { headers });
  return response;
};

/********** Interview API Start **********/

export const fetchInterviewAPI = async () => {
  // console.log("interview api hit");
  const headers = await fetchToken();
  const response = await axios.get(`${fetchInterviewURL}`, {
    headers,
  });
  return response;
};

/********** Interview API End **********/

export const fetchDeclinedReasonsBidsAPI = async (payload) => {
  // // console.log("declined reasons api hit", payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchDeclineReasonsURL}/${payload}`,
    // `${fetchReceivedBidsDetailUrl}/declinedstatus/${payload}`,
    {
      headers,
    }
  );
  return response;
};

export const updateDeclinedReasonsReceivedBidsAPI = async (payload) => {
  // console.log("received bids api hit", payload);
  const data = JSON.stringify(payload.body);

  const headers = await fetchToken();
  const response = await axios.put(
    `${fetchReceivedBidsDetailUrl}/receivedbids/update/${payload.id}`,
    data,
    {
      headers,
    }
  );
  return response;
};

/********** Resume API End **********/

// Get Followers //
export const fetchResumeGetfollowersAPI = async (page, perPage) => {
  // console.log("ResumeGetFollowers API hit");
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchResumeGetfollowersURL}?page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

// Get Views //
export const fetchResumeGetviewsAPI = async (page, perPage) => {
  // console.log("getView API hit");
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchResumeGetviewsURL}?page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

//Get Detail //
export const fetchResumeDetailAPI = async () => {
  // console.log("Resume Detail API hit");
  const headers = await fetchToken();
  const response = await axios.get(`${fetchResumeDetailURL}`, { headers });
  return response;
};

/********** Resume API End **********/

export const fetchHomePageInfoAPI = async (params) => {
  const {
    continueBrowsing_perPage,
    Favorite_perPage,
    Suggested_perPage,
    Editor_perPage,
    Most_PopularMob,
    EditorPicks,
    Suggested,
    ContinueBrowsing,
    Favorites,
    Most_Popular,
  } = params.payload;
  // console.log(params.EditorPicks, "home payload");
  //const headers = {"Content-Type": "application/json", Authorization: talentToken};
  const headers = await fetchToken();
  // console.log("headers", headers);
  const response = await axios.get(
    `${fetchHomePageDataURL}?editorType=${EditorPicks}&editorPage=1&editorPerPage=${Editor_perPage}&suggestType=${Suggested}&suggestPerPage=${Suggested_perPage}&suggestPage=1
    &continueBType=${ContinueBrowsing}&continueBPage=1&continueBPerPage=${continueBrowsing_perPage}&favoriteType=${Favorites}&favoritePage=1&favoritePerPage=${Favorite_perPage}
    &mostPopularType=${Most_Popular}&mostPopularPerPage=${Most_PopularMob}&mostPopularPage=1`,
    { headers }
  );
  return response;
};

// Notification API //
export const UpdateNotificationAPI = async (currentToken) => {
  const data = {
    profile: {
      notificationToken: currentToken,
    },
  };
  // console.log("data", data);
  // console.log("Notification API hit", currentToken);
  // const data = JSON.stringify(payload.body);

  const headers = await fetchToken();
  const response = await axios.put(`${fetchNotificationURL}`, data, {
    headers,
  });
  return response;
};
// export const getToken = () => {

/********* Interview Update Start *********/
export const updateInterviewAPI = async (payload) => {
  // const data = JSON.stringify(payload.body);

  const headers = await fetchToken();
  // console.log("interview api hit", payload);

  if (payload.payload.reschedule) {
    return await axios.put(
      `${updateInterviewURL}/${payload.payload.id}`,
      payload.payload.body,
      { headers }
    );
  } else {
    return await axios.put(
      `${updateInterviewURL}/${payload.payload.id}`,
      payload.payload,
      { headers }
    );
  }
  // const response = await axios.put(
  //   `${updateInterviewURL}/${payload.payload.id}`,
  //   payload.payload,
  //   {
  //     headers,
  //   }
  // );
  // return response;/
};

/********* Talent Bid Update Start *********/
export const updateReceivedBidTalentAPI = async (payload) => {
  // console.log("updateRCBidsTalent api hit");
  const headers = await fetchToken();
  const response = await axios.put(`${updateReceivedBidURL}/${payload.id}`, {
    headers,
  });
  return response;
};

export const retractBidUpdateStatus = async (request) => {
  const data = JSON.stringify(request.payload);
  // console.log(request.payload);
  const headers = await fetchToken();
  return await axios.put(`${retractBidUpdate}/${request.payload}`, data, {
    headers,
  });
};

export const createRatingApi = async (request) => {
  const data = JSON.stringify(request.payload);
  // console.log(JSON.stringify(request));
  const headers = await fetchToken();
  return await axios.post(createRatingUrl, data, { headers });
};

export const updateRatingApi = async (request) => {
  // console.log("UPDATE RATING PAYLOAD:", request.payload);

  const headers = await fetchToken();

  const response = await axios.put(
    `${updateRatingUrl}/${request.payload?.BidId}`,
    request.payload,
    {
      headers,
    }
  );
  // console.log("Update Rating Response", response);
  return response;
};

export const deleteRatingAPI = async (request) => {
  // console.log("DELETE API payload", request.payload);
  const headers = await fetchToken();
  const response = await axios.put(
    `${deleteRatingUrl}/${request.payload.Ratings_id}`,
    request.payload,
    { headers }
  );
  return response;
};
export const downloadResumeAPI = async (payload) => {
  // console.log("download resume api hit");
  const headers = await fetchToken();
  const response = await axios.get(`${downloadResumeURL}/${payload}`, {
    headers,
  });
  return response;
};
export const MessageAttachmentAPi = async (payload) => {
  const headers = await fetchToken();
  return await axios.post(`${sendAttachments}`, payload, { headers });
};

//********Chats APIs**********//
export const getInboxMessagesAPi = async (payload) => {
  console.log('payload in get inbox messages', payload)
  const headers = await fetchToken();
  if(payload?.search) {
    return await axios.get(
      `${getInboxUrl}/${payload.conversationType}/${payload.bidType}/${
        payload.bidStatus
      }?type=${"tal"}&page=${payload.page ?? 1}/&perPage=${payload.perPage ?? 5}&search=${payload?.search ?? 'null'}`,
      { headers }
    );
  }
  return await axios.get(
    `${getInboxUrl}/${payload.conversationType}/${payload.bidType}/${payload.bidStatus
    }?type=${"tal"}&page=${payload.page ?? 1}/&perPage=${payload.perPage ?? 5}`,
    { headers }
  );
};
export const getDashboardInboxMessagesAPi = async (payload) => {
  const headers = await fetchToken();
  return await axios.get(
    `${getInboxUrl}/${payload.conversationType}/${payload.bidType}/${
      payload.bidStatus
    }?type=${"tal"}&page=${payload.page}/&perPage=${payload.perPage}`,
    { headers }
  );
};

export const getMsgsCountApi = async (params) => {
  const headers = await fetchToken();
  const response = await axios.get(
    messagesCountUrl,
    // `${messagesCountUrl}/${params ?? "all_bids"}?type=tal`,
    { headers }
  );
  // // const response = {};
  return response;
};

//*******Notifications*******//
// export const getNotificationsAPi = async () => {
//   const headers = await fetchToken();
//   return await axios.get(`${notifications}`, { headers });
// };
export const getNotificationsAPi = async (request) => {
  const headers = await fetchToken();
  return await axios.get(
    `${notifications}/?page=${request.payload == undefined ? 1 : request.payload.currentPage
    }&perPage=${request?.payload?.getAll ? request?.payload?.pageSize : 10}`,
    {
      headers,
    }
  );
};

export const getReadNotificationApi = async (id) => {
  const headers = await fetchToken();
  return await axios.put(
    `${readNotificationUrl}/${id}`,
    {},
    { headers }
  );
};

export const checkUserNameUnique = async (payload) => {
  // console.log("payloadpayload", payload);
  const response = await axios.post(`${checkUserName}`, payload);
  // console.log("ResponceResponce", response?.data?.valid);
  return response;
};

/********** ViewListing API Start **********/

export const fetchViewListingAPI = async (params) => {
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchViewListingURL}${params ? "/" + params : ""}`,
    {
      headers,
    }
  );
  return response;
};

/********** ViewListing API End **********/

/********** CompanyProfile API Start **********/

export const fetchCompanyProfileAPI = async (params) => {
  const headers = fetchHeader(params.talentToken, params.impersonationId);
  const response = await axios.get(`${fetchCompanyProfileURL}`, {
    headers,
  });
  const resp = await axios.get(`${fetchCompanyProfilejobcountsURL}`, {
    headers,
  });
  if (resp && response) {
    response.data.userGigs = resp?.data?.PublishedCounts;
  }
  return response;
};

export const addRemoveEmployerFollowAPI = async (payload) => {
  const { FollowerId, type, talentToken, impersonationId } = payload;
  const headers = fetchHeader(talentToken, impersonationId);
  if (type === "followed")
    return await axios.post(
      `${addRemoveEmployerFollowURL}/add`,
      { FollowerId },
      {
        headers,
      }
    );
  else if (type === "unfollowed")
    return await axios.delete(
      `${addRemoveEmployerFollowURL}/remove/${FollowerId}`,
      {
        headers,
      }
    );
};

/********** CompanyProfile API End **********/

/// add Update User Device Deatils
export const addUpdateUserDeviceDeatils = async (data) => {
  const headers = await fetchToken();
  await axios.put(`${addUpdateUserDeviceDeatilsURL}`, data, { headers });
};
