import { Row } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./styles.css";

export function OnBillingCard({ data, status }) {
  let userProfile = useSelector((state) => state.profileReducer.user);
  const [contractLength, setContractLength] = useState();

  const budgetOptions = [
    {
      BudgetTypeId: 3,
      BudgetTypeName: "Month",
      BudgetType: "mn",
    },
    {
      BudgetTypeId: 1,
      BudgetTypeName: "Hour",
      BudgetType: "hr",
    },
    {
      BudgetTypeId: 5,
      BudgetTypeName: "Daily",
      BudgetType: "daily",
    },
    {
      BudgetTypeId: 2,
      BudgetTypeName: "Week",
      BudgetType: "wk",
    },
    {
      BudgetTypeId: 4,
      BudgetTypeName: "Year",
      BudgetType: "yr",
    },
  ];

  // console.log("on billing data and status ===========>", data, status,userProfile?.profile?.ProfessionCode);
  const contrachLengthOptions = [
    {
      IdealContractLengthId: 1,
      IdealContractLengthName: "1 month",
      IdealContractLengthValue: 1,
    },
    {
      IdealContractLengthId: 2,
      IdealContractLengthName: "2 months",
      IdealContractLengthValue: 2,
    },
    {
      IdealContractLengthId: 3,
      IdealContractLengthName: "3 months",
      IdealContractLengthValue: 3,
    },
    {
      IdealContractLengthId: 4,
      IdealContractLengthName: "4 months",
      IdealContractLengthValue: 4,
    },
    {
      IdealContractLengthId: 5,
      IdealContractLengthName: "5 months",
      IdealContractLengthValue: 5,
    },
    {
      IdealContractLengthId: 6,
      IdealContractLengthName: "6 months",
      IdealContractLengthValue: 6,
    },
    {
      IdealContractLengthId: 7,
      IdealContractLengthName: "7 months",
      IdealContractLengthValue: 7,
    },
    {
      IdealContractLengthId: 8,
      IdealContractLengthName: "8 months",
      IdealContractLengthValue: 8,
    },
    {
      IdealContractLengthId: 9,
      IdealContractLengthName: "9 months",
      IdealContractLengthValue: 9,
    },
    {
      IdealContractLengthId: 10,
      IdealContractLengthName: "10 months",
      IdealContractLengthValue: 10,
    },
    {
      IdealContractLengthId: 11,
      IdealContractLengthName: "11 months",
      IdealContractLengthValue: 11,
    },
    {
      IdealContractLengthId: 12,
      IdealContractLengthName: "1 year",
      IdealContractLengthValue: 12,
    },
    {
      IdealContractLengthId: 13,
      IdealContractLengthName: "1+ years",
      IdealContractLengthValue: 13,
    },
    {
      IdealContractLengthId: 14,
      IdealContractLengthName: "Negotiable",
      IdealContractLengthValue: 14,
    },
    {
      IdealContractLengthId: 15,
      IdealContractLengthName: "13 weeks",
      IdealContractLengthValue: 15,
    },
    {
      IdealContractLengthId: 16,
      IdealContractLengthName: "1 Week",
      IdealContractLengthValue: 16,
    },
    {
      IdealContractLengthId: 17,
      IdealContractLengthName: "6 weeks",
      IdealContractLengthValue: 17,
    },
    {
      IdealContractLengthId: 18,
      IdealContractLengthName: "12 weeks",
      IdealContractLengthValue: 18,
    },
  ];

  useEffect(() => {
    const contractLenght = contrachLengthOptions.filter(
      (res) =>
        res.IdealContractLengthValue ==
        (data?.BidCard
          ? data?.BidCard?.IdealTerm
          : data?.ProposalCard?.IdealTerm)
    );
    setContractLength(contractLenght);
  }, [data]);

  return (
    <>
      {status == "On Billing" && (
        <>
          <div>
            <strong>Congratulations!</strong>
            <p>You are now hired and moved to on billing</p>
          </div>
          <div className="onBilling-card-info-div">
            <Row className="info-style">
              <strong>Start date</strong>
              <div>
                {moment(data?.OfferCard?.FromDate).format("MMM DD , YYYY")}
              </div>
            </Row>
            <Row className="info-style">
              <strong>End date</strong>
              <div>{moment(data?.OfferCard?.ToDate).format("MMM DD , YYYY")}</div>
            </Row>
            {userProfile?.profile?.ProfessionCode != "IT" ? (
              <Row className="info-style">
                <strong>Estimated pay package</strong>
                <div>
                  {`${data?.OfferCard?.Currency} ${data?.OfferCard?.Rate}/${data?.OfferCard?.BudgetType}`}{" "}
                </div>
              </Row>
            ) : null}

            <Row className="info-style">
              <strong>Contract duration</strong>
              {/* <div>{data?.OfferCard?.Duration}</div> */}
              <div>
                {contractLength?.length > 0 &&
                  contractLength[0].IdealContractLengthName}
              </div>
            </Row>
          </div>
        </>
      )}
      {status == "Off Billing" && (
        <div>
          <strong>Congratulations !</strong>
          <p>You successfully completed the gig</p>
          <div className="onBilling-card-info-div">
            <Row className="info-style">
              <strong>Start date</strong>
              <div>
                {moment(data?.OfferCard?.FromDate).format("MMM DD , YYYY")}
              </div>
            </Row>
            <Row className="info-style">
              <strong>End Date</strong>
              <div>{moment(data?.OfferCard?.ToDate).format("MMM DD , YYYY")}</div>
            </Row>
            {userProfile?.profile?.ProfessionCode != "IT" && (
              <Row className="info-style">
                <strong>Estimated pay package</strong>
                <div>
                  {`${data?.OfferCard?.Currency} ${data?.OfferCard?.Rate}/${data?.OfferCard?.BudgetType}`}{" "}
                </div>
              </Row>
            )}
            <Row className="info-style">
              <strong>Contract duration</strong>
              {/* <div>{data?.OfferCard?.Duration}</div> */}
              <div>
                {contractLength?.length > 0 &&
                  contractLength[0].IdealContractLengthName}
              </div>
            </Row>
          </div>
        </div>
      )}
      {status == "Billing Extended" && ( //ed
        <>
          <div>
            <strong>Congratulations!</strong>
            <p>Your Billing is Extended</p>
          </div>
          <div className="onBilling-card-info-div">
            <Row className="info-style">
              <strong>Accepted Date</strong>
              <div>
                {moment(
                  data?.BidCard
                    ? data?.BidCard?.AcceptedDate
                    : data?.ProposalCard?.AcceptedDate
                ).format("MMM DD , YYYY")}
              </div>
            </Row>
            <Row className="info-style">
              <strong>Comment</strong>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.BidCard
                    ? data?.BidCard?.Comments ?? data?.BidCard?.Message
                    : data?.ProposalCard?.Comments ??
                      data?.ProposalCard?.Message,
                }}
              ></div>
            </Row>
            <Row className="info-style">
              <strong>Ideal Contract Length</strong>
              <div>
              {data?.BidCard?.extendContract?.length > 0 || data?.ProposalCard?.extendContract?.length > 0 ? 
                data?.BidCard?.extendContract[data.extendContractIndex ?? 0].ContractDuration ?? data?.ProposalCard?.extendContract[data.extendContractIndex ?? 0].ContractDuration : ""}
              </div>
            </Row>
            <Row className="info-style">
              <strong>Rate</strong>
              <div>
              ${data?.BidCard?.extendContract?.length > 0 ? data?.BidCard?.extendContract[data.extendContractIndex ?? 0]?.Rate :
                data?.ProposalCard?.extendContract?.length > 0 ? data?.ProposalCard?.extendContract[data.extendContractIndex ?? 0]?.Rate :
                data?.BidCard ? parseInt(data?.BidCard?.Rate).toLocaleString('en-US') : parseInt(data?.ProposalCard?.Rate).toLocaleString('en-US')} 
              {" / "}
              {data?.ProposalCard?.extendContract?.length || data?.BidCard?.extendContract?.length ?
                data?.ProposalCard?.extendContract?.length > 0 ? data?.ProposalCard?.extendContract[data.extendContractIndex ?? 0]?.BudgetType === "Week" ? "Wk" :"Hr" : 
                data?.BidCard?.extendContract[data.extendContractIndex ?? 0]?.BudgetType === "Week" ? "Wk" :"Hr"
              : 'wK'}
              </div>
            </Row>  
          </div>
        </>
      )}
      {status == "Candidate Cancelled" && (
        <div>
          <strong>Congratulations !</strong>
          <p>Your candidate successfully completed the gig</p>
          <div className="onBilling-card-info-div">
            <Row className="info-style">
              <strong>Start date</strong>
              <div>
                {moment(data?.OfferCard?.FromDate).format("MMM DD , YYYY")}
              </div>
            </Row>
            <Row className="info-style">
              <strong>End date</strong>
              <div>{moment(data?.OfferCard?.ToDate).format("MMM DD , YYYY")}</div>
            </Row>
            {userProfile?.profile?.ProfessionCode != 'IT' && <Row className="info-style">
              <strong>Estimated pay package</strong>
              <div>
                {`${data?.OfferCard?.Currency} ${data?.OfferCard?.Rate}/${data?.OfferCard?.BudgetType}`}{" "}
              </div>
            </Row>}
            <Row className="info-style">
              <strong>Contract duration</strong>
              {/* <div>{data?.OfferCard?.Duration}</div> */}
              <div>
                {contractLength?.length > 0 &&
                  contractLength[0].IdealContractLengthName}
              </div>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
