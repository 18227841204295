import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchDetailPlaceBid, retractBid, RetractBidUpdateStageInit } from '../../../redux/actions';

const RetractedModal = (props) => {
    const dispatch = useDispatch();
    // console.log(props.setModalVisibleBidDeclinedPlacedBid,"props")
    function handleOkRetract() {
        dispatch(RetractBidUpdateStageInit(props.props));
        props.setModalVisibleBidDeclinedPlacedBid(false)
        props.setPlacedBidSendRequest("RetractedModal")
        // setTimeout(() => {
        //     props.setPlacedBidSendRequest("RetractedModal")
        //     dispatch(fetchDetailPlaceBid(props.props))
        // }, 1000)
    }
    function handleCancelRetract(){
        props.setModalVisibleBidDeclinedPlacedBid(false)
    }
    return (
        <div>
            <div className="bid-decline-headline retract-bid">
            Do you want to retract your bid?
            </div>
            <div className="bid-retract-container">
                <div 
                    className="bid-retract-no Bid-cursor-pointer" 
                    onClick={() => {handleCancelRetract()}}
                >
                    No, go back
                </div>
                <div
                    className="bid-retract-yes Bid-cursor-pointer"
                    onClick={() => handleOkRetract()}
                >
                    Yes
                </div>
            </div>
        </div>
    );
};

export default RetractedModal;