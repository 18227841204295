import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import "./TalentEmployer.css";

import Logo from "../../assets/icons/LogoBlack.svg";
import talent from "../../assets/img/Rectangle 17309.png";
import employer from "../../assets/img/Rectangle 17306.png";
import { fetchUserProfile, updateProfile } from "../../redux/actions";
import { getCookie } from "../../shared/helpers";
import Loader from "../../components/Loader/Loader";
import youngDocOne from "../../assets/img/Healthcare/YoungDocOne.svg";
import youngDocTwo from "../../assets/img/Healthcare/YoungDocTwo.svg";
import youngDocOneMob from "../../assets/img/Healthcare/YoungDocOneMob.svg";
import youngDocTwoMob from "../../assets/img/Healthcare/YoungDocTwoMob.svg";
const TalentEmployer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [TalentEmployerValue, setTalentEmployerValue] = useState();

  const user = useSelector((state) => state.profileReducer.user);
  const accountType = user.profile.AccountTypeCode;
  const { loading } = useSelector((state) => state.profileReducer);

  const talentEmpUpdate = (code, role) => {
    setTalentEmployerValue(code);
    const profile = {
      AccountTypeCode: code,
      Role: role,
      WizardFlow: 1,
    };
    dispatch(
      updateProfile({
        userId: user.id,
        AccountTypeCode: code,
        Role: role,
        WizardFlow: 1,
      })
    );
  };

  useEffect(() => {
    if (TalentEmployerValue === "TAL" && accountType === "TAL") {
      history.push("/profession");
    } else if (TalentEmployerValue === "EMP" && accountType === "EMP") {
      window.location.replace("/employer");
    }
  }, [accountType, TalentEmployerValue]);

  // utm code
  useEffect(() => {
    const utm_source = localStorage.getItem("utm_source");
    const utm_campaign = localStorage.getItem("utm_campaign");
    const utm_medium = localStorage.getItem("utm_medium");
    const utm_term = localStorage.getItem("utm_term");

    // console.log("utm_source", utm_source, utm_campaign, utm_medium, utm_term);

    dispatch(
      updateProfile({
        MCSource: utm_source,
        MCCampaign: utm_campaign,
        MCMedium: utm_medium,
        MCTerm: utm_term,
      })
    );
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {loading || TalentEmployerValue === "EMP" ? (
        <Loader size={"4rem"} scrolling />
      ) : (
        <div className="TalentEmployerPage">
          <div className="TalentEmployer">
            <div className="sectionTL">
              <div className="headerTL">
                {/* <div className="logoTL">
                  <img src={Logo} alt="skillgigs_black_logo" />
                </div> */}
                <div className="subHeaderTL">
                  <div className="subHeaderTLBold">
                    Welcome to{" "}
                    <span>
                      <img
                        src={Logo}
                        alt="skillgigs_black_logo"
                        className="logo-class"
                      />
                    </span>
                  </div>
                  <div className="subHeaderTLBold-heading">
                    Let’s start by creating your profile
                  </div>
                  <div
                    className="subHeaderTLBold-heading"
                    style={{ marginTop: "7px" }}
                  >
                    Choose your role:
                  </div>
                </div>
              </div>
              <div className="chooseTalentorNot">
                <div
                  className="becomeACandidateBOX"
                  onClick={(e) => {
                    e.stopPropagation();
                    talentEmpUpdate("TAL", ["CONS"]);
                  }}
                >
                  {windowWidth > 768 ? (
                    <img
                      src={youngDocOne}
                      alt="skillgigs_black_logo"
                      className="imgYoungDocs"
                    />
                  ) : (
                    <img
                      src={youngDocOneMob}
                      alt="skillgigs_black_logo"
                      className="imgYoungDocsTwo"
                    />
                  )}
                  <div className="becomeACandidate">
                    <div className="title_div">
                      <div className="title">Candidate</div>
                      <div className="sub_title">Find your next dream gig</div>
                    </div>
                  </div>
                </div>
                <div
                  className="findACandidateBOX"
                  onClick={(e) => {
                    e.stopPropagation();
                    talentEmpUpdate("EMP", ["CLIE"]);
                  }}
                >
                  {windowWidth > 768 ? (
                    <img
                      src={youngDocTwo}
                      alt="skillgigs_black_logo"
                      className="imgYoungDocs"
                    />
                  ) : (
                    <img
                      src={youngDocTwoMob}
                      alt="skillgigs_black_logo"
                      className="imgYoungDocsTwo"
                    />
                  )}
                  <div className="findACandidate">
                    <div className="title_div">
                      <div className="title">Employer</div>
                      <div className="sub_title">Find the best candidates</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TalentEmployer;
