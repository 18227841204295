import React, {useEffect, useState} from 'react';
import {Pagination, Typography} from "antd";
import './MessagesReceivedTab.css';
import {useSelector, useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";

// actions
import {fetchMessages} from "../../../../redux/actions";

import MessageItem from "../MessageItem/MessageItem";

const {Title} = Typography;
const pageLength = 6; // To be changed before production
const MessagesReceivedTab = props => {
    const dispatch = useDispatch();
    const history = useHistory();

    const receipentName=props.username;
    // console.log(receipentName);
    const data = useSelector(state => state.messagesReducer.inbox);
    const loading = useSelector(state => state.messagesReducer.inboxLoading);
    const error = useSelector(state => state.messagesReducer.inboxError);

    const [currentPage, setCurrentPage] = useState(1);
    // console.log("data is "+JSON.stringify(data))


    useEffect(() => {
        dispatch(fetchMessages('inbox', pageLength, currentPage === 1 ? 0 : (currentPage * pageLength) - pageLength));
    },[currentPage]);

    const onPageChange = (page) => {
        setCurrentPage(page);
    }

    const onItemClicked = (conversationId, recipientId, contextType, contextName, userName,receipentName) => {
        history.push({
            pathname: `/messages/${conversationId}`,
            state: {
                recipientId,
                contextType,
                contextName,
                userName,
                receipentName
            }
        });
    }

    return(
        <div>
            <Title level={5} className={'message-type-text'}>
                Messages received
            </Title>
            {
                loading
                    ? [1,2,3,4,5].map(()=>(<MessageItem loading/>))
                    : data.map((item)=>
                        <MessageItem
                            onClick={()=>onItemClicked(
                                item._id,
                                item.users[0]._id,
                                item.skill_listings.length ? 'Skill Listing' : 'Gig',
                                item.skill_listings.length ? item.skill_listings[0].Title : item.gigs[0].Title,
                                item.users[0].name,
                                receipentName
                            )}
                            recipientName={item.users[0].name}
                            gigName={item.skill_listings.length ? item.skill_listings[0].Title : item.gigs[0].Title}
                            totalMessages={item.totalmessages}
                            bidStatus={item.RecipientLabels}
                            lastMessageTimeStamp={item.Created}
                        />
                    )

            }
            <div className={'messages-pagination-bar'}>
                <Pagination
                    defaultCurrent={1}
                    pageSize={pageLength}
                    current={currentPage}
                    total={data.length ? data[0].totalconversations : 0}
                    onChange={onPageChange}
                />
            </div>
        </div>
    )
}

export default MessagesReceivedTab;
