import React from "react";
import "./cardRating.css";
const CardFour = () => {
  return (
    <>
      <div className="parentCard">
        <div class="Congratulations-Your-succesfully-completed-the-job">
          <div class="text-style-1"> Request submitted</div>
          Your request for delete employer name ratings has been successfully
          submitted to SkillGigs. Will notify you when this is complete.
        </div>
        <div className="card-insidebox">
          <div className="card_attribute_item44">Reason</div>
          <div className="card-attribute_item23">
            {" "}
            Lorem ipsum is simply dummy text of the printing and typesetting
            industry. Lorem ipsum has been the industry's standard dummy text
            ever since the 1500s
          </div>
        </div>
        <div className="duration-card">11:35pm</div>
      </div>
    </>
  );
};

export default CardFour;
