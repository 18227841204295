import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./DashboardProfileDetails.css";
import { Progress, Rate } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getTalentInfo } from "../../../redux/actions";
import ProfileAvatar from "../../../components/ProfileAvatar";
import ProfileDetailsSecond from "./ProfileDetailsComponent/ProfileDetailsSecond";

const DashboardProfileDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;
  //const analyticInfo = useSelector((state) => state.profileReducer.analyticInfo);
  //const authTokens = useSelector((state) => state.authReducer.analyticInfo.authTokens);
  // useEffect(() => {
  //   dispatch(getAnalyticInfo(authTokens));
  // }, [authTokens]);

  //useEffect(() => {
  // // console.log( "ticks" , analyticInfo)
  // // console.log("datta", authTokens);

  const user = useSelector((state) => state.profileReducer.user);
  //}, [analyticInfo]);
  const isProfileComplete = () => {
    let completeProfile = true;
    if (
      user?.profile?.PictureUrl === null ||
      user?.profile?.Location === null ||
      user?.profile?.ExperienceLevelName === null ||
      user?.profile?.Facebook === null ||
      user?.profile?.LinkedIn === null ||
      //  user?.profile?.CareerManagerId === null
      // user?.profile?.Discipline === null ||
      // user?.profile?.TagLine === null ||
      user?.profile?.WorkHistory === null ||
      user?.profile?.EducationHistory === null
    ) {
      completeProfile = false;
    }

    return completeProfile;
  };
  // console.log(isProfileComplete());

  const authToken = useSelector((state) => state.authReducer.user.authToken);

  const talentInfo = useSelector((state) => state.profileReducer.talentInfo);

  const [professionCode, setprofessionCode] = useState();
  const [profileRating, setprofileRating] = useState();
  const [ResponseRating, setResponseRating] = useState();

  useEffect(() => {
    dispatch(getTalentInfo(authToken));
  }, [authToken]);

  useEffect(() => {
    if (user?.profile?.Rating === null) {
      setprofileRating("N/A");
    }
  }, [user]);

  const toTitleCaseGreater = (str) => {
    return str
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const firstName = user?.profile?.FirstName ? user?.profile?.FirstName : "";
  const lastName = user?.profile?.LastName ? user?.profile?.LastName : "";
  const Name = firstName + " " + lastName;
  const resultCase = toTitleCaseGreater(Name);

  useEffect(() => {
    if (talentInfo?.ResponseRate === 0) {
      setResponseRating("N/A");
    }
  }, [talentInfo]);

  useEffect(() => {
    // console.log("talentInformation", talentInfo);
    // console.log("data_in_token", authToken);

    if (user?.profile?.ProfessionCode === "HC") {
      setprofessionCode("Healthcare");
    } else if (user?.profile?.ProfessionCode === "IT") {
      setprofessionCode("IT");
    } else if (user?.profile?.ProfessionCode === "UN") {
      setprofessionCode("Unknown");
    } else if (user?.profile?.ProfessionCode === "EN") {
      setprofessionCode("Engineering");
    } else if (user?.profile?.ProfessionCode === "MA") {
      setprofessionCode("Marketing");
    }
  }, [talentInfo]);

  useEffect(() => {
    // console.log("userData", user);
  }, [user]);

  return (
    <>
      <div className="Dashboard-Profile-Card">
        <div className="Dashboard-Profile-Card-main-container-ast"></div>

        <div className="Dashboard-Profile-Card-main-container-pjk">
          <div className="Dashboard-Profile-Card-main-container-pkh">
            <ProfileAvatar
              profilePic={user.profile.PictureUrl}
              name={user?.profile?.FirstName}
              fontSize="1.5rem"
              userImages="userImages"
            />
          </div>

          <div className="Dashboard-Profile-Card-main-container-234">
            <div className="Dashboard-Profile-Card-main-container-talent">
              <div className="Dashboard-Profile-Card-main-container-rhsj">
                <div className="Dashboard-Profile-Card-main-container-sjdj">
                  {resultCase}
                </div>
                {/* <div className="Dashboard-Profile-Card-main-container-jeks">
              {professionCode}
            </div> */}
              </div>

              <div className="dashboard-profileStar-142">
                <Rate count={1} size="small" disabled defaultValue={1} />
              </div>
              <div className="Dashboard-Profile-Card-main-container-qwjgdj">
                {user?.profile?.Rating ? user?.profile?.Rating : 3.5}
              </div>
            </div>

            <div className="Dashboard-Profile-Card-main-container-jeks">
              {professionCode}
            </div>
          </div>
        </div>

        <div
          className="Dashboard-Profile-Card-main-container-yako"
          onClick={() => history.push("/profile")}
        >
          {Math.floor(profile?.ResumeCompletion) !== 100 && (
            <span className="Dashboard-Profile-Card-main-container-jeko">
              Complete profile
            </span>
          )}
        </div>
      </div>
      {/* <ProfileDetailsSecond /> */}

      {/* <div
        className="Dashboard-Profile-Card-main-container-tunior"
        onClick={() => history.push("/dummy")}
      >
        <div className="Dashboard-Profile-Card-main-container-continor">
          <div className="Dashboard-Profile-Card-main-container-itemn1">
            Job completion
          </div>

          <div className="Dashboard-Profile-Card-main-container-itemn2">
            <div>
              <Progress
                percent={talentInfo?.JobCompletion}
                status="active"
                format={() => ""}
              />
            </div>
          </div>

          <div className="dashboard-profile-percentage">
            {talentInfo?.JobCompletion} %
          </div>
        </div>
        <div className="Dashboard-Profile-Card-main-container-continor2">
          <div className="Dashboard-Profile-Card-main-container-itemn4">
            Inbox Response time
          </div>
          <div className="Dashboard-Profile-Card-main-container-itemn3">
            <div>{talentInfo?.ResponseRate} min</div>
          </div>
        </div>
      </div>

      <div
        className="Dashboard-Profile-Card-main-container-rtyu"
        onClick={() => history.push("/dummy")}
      >
        <div className="Dashboard-Profile-Card-main-container-rhys">
          Earned in {talentInfo?.Earned?.month}
        </div>
        <div className="Dashboard-Profile-Card-main-container-kjys">
          $ {talentInfo?.Earned?.amount}
        </div>
      </div> */}
    </>
  );
};

export default DashboardProfileDetails;
