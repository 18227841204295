import React, { useEffect, useState } from "react";

import "./Home.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeDataInit } from "../../redux/actions";
import GigsCard from "../SearchGigs/GigsCard"
import { useLocation } from "react-router-dom";
import { Breadcrumb } from 'antd';
import { useHistory } from "react-router-dom";





const HomeSeeAllpage = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" })
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();
    const { ContinueBrowsing, Suggested, EditorPicks, MostPopular } = state;
    const [dimensions, setDimensions] = useState(window.innerWidth);
    const [Stagevalue, setStageValue] = useState("")
    useEffect(() => {
        if (ContinueBrowsing === "ContinueBrowsing") {
            setStageValue("Continue Browsing")
        }
        else if (Suggested === "Suggested") {
            setStageValue("Suggested Jobs")
        }
        else if (EditorPicks === "EditorPicks") {
            setStageValue("Editor’s Picks")
        }
        else if (MostPopular === "MostPopular")
        {
            setStageValue("Most Popular")
        }
    }, [])
    const [continueBrowsing_perPage, setcontinueBrowsing_perPage] = useState(24);
    const [Favorite_perPage, setFavorite_perPage] = useState(24);
    const [Suggested_perPage, setSuggested_perPage] = useState(10);
    const [Editor_perPage, setEditor_perPage] = useState(20); 
    const [Most_Popular, setMost_Popular] = useState(20)


    const [continueBrowsing_perPageMob, setcontinueBrowsing_perPageMob] = useState(15);
    const [Favorite_perPageMob, setFavorite_perPageMob] = useState(24);
    const [Suggested_perPageMob, setSuggested_perPageMob] = useState(10);
    const [Editor_perPageMob, setEditor_perPageMob] = useState(20);
    const [Most_PopularMob, setMost_PopularMob] = useState(20)

    useEffect(() => {
        {
            dimensions > 720 ? dispatch(fetchHomeDataInit(
                continueBrowsing_perPage,
                Favorite_perPage,
                Suggested_perPage,
                Editor_perPage,
                Most_Popular,
                "EditorPicks",
                "Suggested",
                "Continue Browsing",
                "Favorites",
                "Most Popular"
            )) : dispatch(fetchHomeDataInit(
                continueBrowsing_perPageMob,
                Favorite_perPageMob,
                Suggested_perPage,
                Editor_perPage,
                Most_PopularMob,
                "EditorPicks",
                "Suggested",
                "Continue Browsing",
                "Favorites",
                "Most Popular"
            ))
        }
    }, []);
    const homedataAll = useSelector((state) => state.homepageReducer.HomePageData);
    // console.log(homedataAll, "homedataAll")
    const HomePageRoute = () => {
        history.push("");
    }
    const checkDimensions = () => {
        setDimensions(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", checkDimensions);
    }, []);

    return (
        <>
            <div className="HomePage-SeeAll-page">
                <div>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="HomePage-Breadcrumb-home" onClick={HomePageRoute} >Home</Breadcrumb.Item>
                        <Breadcrumb.Item className="HomePage-Breadcrumb-CB">{Stagevalue}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="HomePage-Heading" >{Stagevalue}</div>
                <div className="HomePage-Sub-Heading" >Check out your recently viewed gigs</div>
                {ContinueBrowsing === "ContinueBrowsing" ?
                    <div className="gql-receivedGigs" >
                        {
                            homedataAll?.body?.ContinueBrowsing?.map((data) => {
                                return (
                                    <>
                                        <GigsCard data={data} Types="ContinueBrowsing" />
                                    </>
                                )
                            })
                        }
                    </div>
                    : Suggested === "Suggested" ?
                        <div className="gql-receivedGigs" >
                            {
                                homedataAll?.body?.Suggested?.map((data) => {
                                    return (
                                        <>
                                            <GigsCard data={data} Types="Suggested" />
                                        </>
                                    )
                                })
                            }
                        </div>
                        : EditorPicks === "EditorPicks" ?
                            <div className="gql-receivedGigs" >
                                {
                                    homedataAll?.body?.EditorPicks?.map((data) => {
                                        return (
                                            <>
                                                <GigsCard data={data} Types="EditorPicks" />
                                            </>
                                        )
                                    })
                                }
                            </div>
                            : MostPopular === "MostPopular" ?
                                <div className="gql-receivedGigs" >
                                    {
                                        homedataAll?.body?.MostPopular?.map((data) => {
                                            return (
                                                <>
                                                    <GigsCard data={data} Types="MostPopular" />
                                                </>
                                            )
                                        })
                                    }
                                </div>:
                            "no data to display"
                }
            </div>
        </>
    )
}
export default HomeSeeAllpage;
