import React, { useState, useEffect } from "react";
import "./StageBar.css";

const StageBar = ({ length, stage, status }) => {
  // // console.log("Caskjdfnk: ", length, stage);
  const stageArray = [];
  for (let i = 0; i < length; i++) {
    stageArray.push(i);
  }

  const [stageCount, setStageCount] = useState(stage);

  useEffect(() => {
    if (stage > length) {
      setStageCount(length);
    } else {
      setStageCount(stage);
    }
  }, [stage]);

  return (
    <div className="stages-main">
      {stageArray.map((span, i) => {
        return (
          <>
            <div className="stages-main-single-stagebar" key={i}>
              {stageCount === i + 1 && (
                <span
                  className={
                    stage > length - 5
                      ? "status-received-bids bids-Align-Right-side"
                      : "status-received-bids"
                  }
                >
                  {status}
                </span>
              )}
              <div
                className={
                  stage >= i + 1
                    ? "stages-receive--bids-active"
                    : "stages-receive-bids"
                }
              ></div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default StageBar;
