/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import "./CompanyProfile.css";
import {
  Row,
  Col,
  Rate,
  Input,
  Carousel,
  Empty,
  Button,
  Modal,
  Tooltip,
} from "antd";
import { CameraOutlined } from "@ant-design/icons";
import facebookicon from "../../assets/img/facebookicon.png";
import Googleicon from "../../assets/img/Googleicon.png";
import threedots from "../../assets/img/threedots.png";
import appleicon from "../../assets/img/appleicon.png";
import linkedinicon from "../../assets/img/linkedinicon.png";
import personicon from "../../assets/img/personicon.png";
import calendaricon from "../../assets/img/calendaricon.png";
import suitcaseicon from "../../assets/img/suitcaseicon.png";
import moment from "moment";
import candidate_img from "../../assets/img/becomeACandidate.png";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { Card } from "antd";
import {
  addRemoveEmployerFollowInt,
  fetchCompanyProfile,
  fetchUserProfile,
} from "../../redux/actions";
import { useParams } from "react-router-dom";

export default function CompanyProfile() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const talentToken = useSelector((state) => state.authReducer.user.authToken);
  const { companyInfo: resp, loadingCP: loading } =
    useSelector((state) => state.viewListingReducer) || [];
  const AccountId = useSelector(
    (state) => state.profileReducer.user.profile.AccountId
  );
  const [marquePicUrl, setmarquePicUrl] = useState("");
  const [profilePictureUrl, setprofilePictureUrl] = useState("");
  const [culturePicturesUrl, setculturePicturesUrl] = useState([]);
  const [ourpeoplePictureUrl, setourpeoplePictureUrl] = useState("");
  const [Dimensions, setDimensions] = useState(getWindowDimensions());
  const [linkedAccounts, setLinkedAccounts] = useState();
  const [isFollowed, setIsFollowed] = useState(false);
  const [addLinkedAccountValue, setAddLinkedAccountValue] = useState("");
  const [inputValue, setInputvalue] = useState("");

  const [linkKey, setLinkKey] = useState("");
  useEffect(() => {
    dispatch(fetchCompanyProfile({ talentToken, impersonationId: id }));
  }, []);
  const description = resp?.companyProfile?.CompanyDescription;
  useEffect(() => {
    let linkedAccounts = {
      Facebook: resp?.companyProfile?.Facebook,
      Google: resp?.companyProfile?.Google,
      Apple: resp?.companyProfile?.Apple,
      LinkedIn: resp?.companyProfile?.LinkedIn,
    };

    setLinkedAccounts(linkedAccounts);
    setIsFollowed(
      resp?.companyProfile?.followings?.filter(
        (following) => following?.FolloweeId === AccountId
      ).length
        ? true
        : false
    );
  }, [resp]);


  useEffect(() => {
    if (resp?.length !== 0) {
      getMarquePicture();
      getProfilePicture();
      getCulturePictures();
      getOutPeoplePicture();
    }
  }, [resp]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getMarquePicture = () => {
    var url = `${process.env.REACT_APP_S3_URL}/content/pics/albums/thumbs/${resp.companyProfile.MarqueePictureId}`;
    // var url = resp.companyProfile.Albums[0]?.AlbumTypes?.AlbumTypeCode=='MARQE' ? resp.companyProfile.Albums[0]?.Album_Pictures[0]?.Filename : null;
    if (url !== null) {
      setmarquePicUrl(url);
      // getImage(url)
      //   .then((result) => {
      //     // console.log("marquePicUrl>result:", result);
      //   })
      //   .catch(() => {
      //     // console.log("Error in loading image");
      //   });
    } else {
      // console.log("empty");
    }
  };
  // console.log("marquePicUrl>", marquePicUrl);

  const getProfilePicture = () => {
    var url = resp?.companyProfile?.PictureUrl;
    if (url !== null) {
      setprofilePictureUrl(url);
      // getImage(url)
      //   .then((result) => {
      //     // console.log("ProfilePictureURL result>>>>", result);
      //   })
      //   .catch(() => {
      //     // console.log("Error in loading image");
      //   });
    } else {
      // console.log("empty");
    }
  };

  const getCulturePictures = async () => {
    var culturePictures = [];
    if (
      resp?.companyCulturePhotos?.length > 0 &&
      resp?.companyCulturePhotos[0]?.Album_Pictures?.length > 0
    ) {
      for (
        let i = 0;
        i < resp?.companyCulturePhotos[0]?.Album_Pictures?.length;
        i++
      ) {
        var url = `content/pics/albums/thumbs/${resp.companyCulturePhotos[0].Album_Pictures[i].Filename}`;
        if (url !== null) {
          // const result = await getImage(url);
          culturePictures?.push(url);
        } else {
          // console.log("culturePictures with URL empty");
        }
      }
      setculturePicturesUrl(culturePictures);
    }
  };

  const getOutPeoplePicture = () => {
    if (
      resp?.ourPeoplePhotos?.length > 0 &&
      resp?.ourPeoplePhotos[0]?.Album_Pictures?.length > 0
    ) {
      var url = resp?.ourPeoplePhotos[0]?.Album_Pictures[0]?.Filename;
      if (url !== null) {
        setourpeoplePictureUrl(url);
        // getImage(url)
        //   .then((result) => {
        //   })
        //   .catch(() => {
        //     // console.log("Error in loading image");
        //   });
      } else {
        // console.log("empty");
      }
    }
  };
  const imageURLSplit = (url) => {
    var key = url?.split("/");
    return key[2];
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (key) => {
    // console.log("mujjjj ", key, linkedAccounts);
    setLinkKey(key);
    setIsModalVisible(true);
    // setInputvalue(" ");
    if (key == "facebook") {
      if (
        linkedAccounts?.Facebook == null ||
        linkedAccounts?.Facebook == undefined
      ) {
        setInputvalue("");
        setAddLinkedAccountValue("");
      } else {
        setAddLinkedAccountValue(linkedAccounts?.Facebook);

        setInputvalue(linkedAccounts?.Facebook);
      }
    } else if (key == "google") {
      if (
        linkedAccounts?.Google == null ||
        linkedAccounts?.Google == undefined
      ) {
        setInputvalue("");
        setAddLinkedAccountValue("");
      } else {
        setAddLinkedAccountValue(linkedAccounts?.Google);
        setInputvalue(linkedAccounts?.Google);
      }
    } else if (key == "apple") {
      if (linkedAccounts?.Apple == null || linkedAccounts?.Apple == undefined) {
        setInputvalue("");
        setAddLinkedAccountValue("");
      } else {
        setInputvalue(linkedAccounts?.Apple);
        setAddLinkedAccountValue(linkedAccounts?.Apple);
      }
    } else if (key == "linkedIn") {
      if (
        linkedAccounts?.LinkedIn == null ||
        linkedAccounts?.LinkedIn == undefined
      ) {
        setInputvalue("");
        setAddLinkedAccountValue("");
      } else {
        setInputvalue(linkedAccounts?.LinkedIn);
        setAddLinkedAccountValue(linkedAccounts?.LinkedIn);
      }
    }
  };

  const handleOk = () => {
    // console.log("Linked Accounts>>link key", linkKey, URL);
    //  console.log(
    //   "Linked Accounts>>addLinkedAccountValue",
    //   addLinkedAccountValue
    // );
    setIsModalVisible(false);
    let temp;

    if (linkKey == "facebook" && URL == true) {
      setLinkedAccounts({ ...linkedAccounts, Facebook: addLinkedAccountValue });
      temp = { ...linkedAccounts, Facebook: addLinkedAccountValue };
    } else if (linkKey == "google") {
      setLinkedAccounts({ ...linkedAccounts, Google: addLinkedAccountValue });
      temp = { ...linkedAccounts, Google: addLinkedAccountValue };
    } else if (linkKey == "apple") {
      setLinkedAccounts({ ...linkedAccounts, Apple: addLinkedAccountValue });
      temp = { ...linkedAccounts, Apple: addLinkedAccountValue };
    } else if (linkKey == "linkedIn") {
      setLinkedAccounts({ ...linkedAccounts, LinkedIn: addLinkedAccountValue });
      temp = { ...linkedAccounts, LinkedIn: addLinkedAccountValue };
    }

  };


  const [URL, setURL] = useState("");

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex?.test(URL);
  };

  useEffect(() => {
    // console.log("RESPONSE:", resp?.jobs);
  }, [resp?.jobs]);
  // console.log("RESPONSE:", resp);

  const changeUrl = (e) => {
    setInputvalue(e);
    const value = e;
    const isTrueVal = !value || urlPatternValidation(value);
    setURL(isTrueVal);

    isTrueVal == true
      ? setAddLinkedAccountValue(value)
      : setAddLinkedAccountValue("");
  };

  useEffect(() => {
    // console.log("JOBS:", resp?.jobs);
  }, [resp?.jobs]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setInputvalue("");
  };

  const handlingSocialOnClick = (socialLink) => {
    if (!socialLink) return;
    const element = document.createElement("a");
    element.href = socialLink;
    element.target = "_blank";
    element.click();
  };

  // const deleteLink = (key) => {
  //   // console.log("Linked Accounts>>delete:", key);
  //   let temp;
  //   if (key == "facebook") {
  //     setLinkedAccounts({ ...linkedAccounts, Facebook: null });
  //     temp = { ...linkedAccounts, Facebook: null };
  //   } else if (key == "google") {
  //     setLinkedAccounts({ ...linkedAccounts, Google: null });
  //     temp = { ...linkedAccounts, Google: null };
  //   } else if (key == "apple") {
  //     setLinkedAccounts({ ...linkedAccounts, Apple: null });
  //     temp = { ...linkedAccounts, Apple: null };
  //   } else if (key == "linkedIn") {
  //     setLinkedAccounts({ ...linkedAccounts, LinkedIn: null });
  //     temp = { ...linkedAccounts, LinkedIn: null };
  //   }

  //   // console.log("bilal delete temp ", temp);

  //   // dispatch(updateCompanyProfileLinkedAccounts(linkedAccounts));
  //   dispatch(updateCompanyProfileLinkedAccounts(temp));
  // };

  // console.log(
  //   ">>>>>>>>>>>>>Company Profile Resp>>>>>>>>>>>>>>>>>>>>>>>>>",
  //   resp?.ourPeoplePhotos
  // );
  // console.log("marquePicUrl", marquePicUrl);

  const hanldlingFollow = (e) => {
    e.stopPropagation();
    setIsFollowed(isFollowed ? false : true);
    dispatch(
      addRemoveEmployerFollowInt({
        FollowerId: AccountId,
        talentToken,
        impersonationId: id,
        type: isFollowed ? "unfollowed" : "followed",
      })
    );
  };

  if (!loading && resp?.length !== 0) {
    return (
      <div className="home-container">
        <Modal
          className="add-modal"
          title="Add link"
          visible={isModalVisible}
          onOk={handleOk}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={handleCancel}
          okText="Save"
        >
          <Input
            placeholder={inputValue ? null : "Add a link to your account..."}
            onChange={(e) => {
              changeUrl(e.target.value);
            }}
            value={inputValue}
          />
          {URL == false && inputValue?.length > 3 ? (
            <span style={{ color: "red", fontSize: "0.85rem" }}>
              Incorrect format
            </span>
          ) : null}
        </Modal>

        <Col xs={22} sm={24} md={24} lg={24}>
          <Row>
            <Col span={Dimensions.width > 768 ? 24 : 8}>
              {Dimensions.width > 768 && (
                <div
                  className="bannerdiv"
                  style={{
                    marginTop: Dimensions.width > 768 ? "-10px" : "0px",
                  }}
                >
                  {resp?.length !== 0 ? (
                    resp?.companyProfile?.MarqueePictureId !== null &&
                    // marquePicUrl !== "" & resp.companyProfile?.Albums[1]?.Album_Pictures.length>0 ? (
                    marquePicUrl !== "" ? (
                      <div
                        style={{
                          // position: "absolute",
                          width: "100%",
                          height: "320px",
                        }}
                      >
                        <img
                          src={`${marquePicUrl}`}
                          // src={`${process.env.REACT_APP_S3_URL}${marquePicUrl?.charAt(0) !== "/" ? '/' : ""}${marquePicUrl}`}
                          alt="Marque Picture"
                          className="_img MarquePic"
                          style={{
                            zIndex: "-1",
                          }}
                        />

                        {/* <div
                          style={{
                            display: "flex",
                            width: "120%",
                            // marginTop: "-180px",
                            marginTop: "80px",
                            // marginTop: "80px",
                            justifyContent: "end",
                            alignItems: "end",
                            position: 'absolute',
                            zIndex: 1,
                            right: '0%'
                          }}
                        >
                          <button
                            className="bannerbtn2"
                            style={{ marginTop: "6rem" }}
                            onClick={() => {
                              history.push("/marqueealbum")                    
                              dispatch(fetchUserProfile(talentToken));
                            }}
                          >
                            Edit banner
                          </button>
                        </div> */}
                      </div>
                    ) : // <div
                    //   style={{
                    //     display: "grid",
                    //     height: "100%",
                    //     justifyContent: "center",
                    //     alignItems: "center",
                    //     zIndex: "-1",
                    //   }}
                    // >
                    //   <div className="circle-backg"></div>
                    //   <CameraOutlined
                    //     style={{ fontSize: "40px", gridArea: "1/1" }}
                    //     onClick={() => history.push("/marqueealbum")}
                    //   />
                    //   {/* <button
                    //     className="uploadbtn bannerbtn "
                    //     onClick={() => history.push("/marqueealbum")}
                    //   >
                    //     + Upload Banner
                    //   </button> */}
                    // </div>
                    null
                  ) : null}
                </div>
              )}

              <div className="prof-circle">
                {resp?.length !== 0 ? (
                  resp?.companyProfile?.PictureUrl !== null ? (
                    <img
                      // src={profilePictureUrl}
                      src={`${process.env.REACT_APP_S3_URL}${
                        profilePictureUrl?.charAt(0) !== "/" ? "/" : ""
                      }${profilePictureUrl}`}
                      style={
                        {
                          // height: "auto",
                          // objectPosition: '50% 50%',
                        }
                      }
                      className={
                        Dimensions.width > 768 ? "profile_img" : "_img"
                      }
                    />
                  ) : (
                    <h1
                      style={{
                        color: "white",
                        display: "flex",
                        margin: "auto",
                      }}
                    >
                      {resp?.companyProfile?.CompanyName?.split("")[0]}
                    </h1>
                  )
                ) : null}
              </div>

              {/* <img
                src={profilePictureUrl ? editicon : cameraicon}
                alt="editicon"
                className="editIcon-sm profile-edit"
                style={{
                  position: Dimensions.width > 768 && "absolute",
                  marginTop: Dimensions.width > 768 && "30px",
                  marginLeft: Dimensions.width > 768 && "145px",
                  zIndex: Dimensions.width > 768 && "2",
                }}
                onClick={() => {
                  history.push({
                    pathname: "/CompanyLogo",
                    state: {
                      Key: resp.companyProfile.PictureUrl,
                    },
                  });
                }}
              /> */}
            </Col>
            <Col span={16}>
              {Dimensions.width < 769 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div className="comp-head">
                    {resp?.companyProfile?.CompanyName !== null
                      ? resp?.companyProfile?.CompanyName
                      : "Company Name"}
                  </div>
                  <div className="head-sub">
                    {
                      // resp.length === 0

                      //Migration Changes
                      // resp.companyProfile.Location &&
                      //   resp.companyProfile.Location.Location
                      //   ? resp.companyProfile.Location.Location
                      //   : "Location"
                      //Migration Changes

                      resp?.companyProfile?.LocationWithCountry &&
                      resp?.companyProfile?.LocationWithCountry
                        ? resp?.companyProfile?.LocationWithCountry
                        : "Location"
                    }
                  </div>
                  <Row
                    style={{
                      width: "60%",
                      display: "flex",
                      // justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Rate
                        className="comp-rate-cp"
                        disabled
                        allowHalf
                        value={Number(
                          resp?.companyProfile?.Rating
                            ? resp?.companyProfile?.Rating
                            : 3.5
                        )}
                      ></Rate>
                    </div>
                    <div className="comp-rating">
                      {resp?.companyProfile?.Rating
                        ? resp?.companyProfile?.Rating
                        : 3.5}
                    </div>
                    <div className="comp-rating comp-sub">
                      (
                      {resp?.CompanyProfile?.Review
                        ? resp?.CompanyProfile?.Review
                        : 0}
                      )
                    </div>
                    <button
                      className={`comp-head-button ${
                        isFollowed ? "userfollowing" : ""
                      }`}
                      onClick={(e) => hanldlingFollow(e)}
                    >
                      {isFollowed ? "following" : "follow"}
                    </button>
                  </Row>
                </div>
              )}
              {Dimensions.width > 768 && (
                <div className="comp-head">
                  <div className="comp-head-name">
                    <Tooltip
                      title={
                        resp?.companyProfile?.CompanyName !== null
                          ? resp?.companyProfile?.CompanyName
                          : "Company Name"
                      }
                    >
                      <span>
                        {resp?.companyProfile?.CompanyName !== null
                          ? resp?.companyProfile?.CompanyName
                          : "Company Name"}
                      </span>
                    </Tooltip>
                    <button
                      className={`comp-head-button ${
                        isFollowed ? "userfollowing" : ""
                      }`}
                      onClick={(e) => hanldlingFollow(e)}
                    >
                      {isFollowed ? "following" : "follow"}
                    </button>
                  </div>
                  <div className="head-location">
                    {
                      // resp.length === 0
                      //Migration Changes
                      // resp.companyProfile.Location &&
                      //   resp.companyProfile.Location.Location
                      //   ? resp.companyProfile.Location.Location
                      //   : "Location"
                      //Migration Changes

                      resp?.companyProfile?.LocationWithCountry &&
                      resp?.companyProfile?.LocationWithCountry
                        ? resp?.companyProfile?.LocationWithCountry
                        : "Location"
                    }
                  </div>
                  <div className="head-rating">
                    <Rate
                      className="comp-rate-cp"
                      disabled
                      allowHalf
                      value={Number(
                        resp?.companyProfile?.Rating
                          ? resp?.companyProfile?.Rating
                          : 3.5
                      )}
                    ></Rate>
                  </div>
                  <div className="comp-rating">
                    <span className="comp-rating comp-review">
                      {resp?.companyProfile?.Rating
                        ? resp?.companyProfile?.Rating
                        : 3.5}
                    </span>
                    <span className="comp-rating comp-sub">
                      (
                      {resp?.CompanyProfile?.Review
                        ? resp?.CompanyProfile?.Review
                        : 0}{" "}
                      {resp?.CompanyProfile?.Review == 1 ? "review" : "reviews"}
                      )
                    </span>
                  </div>
                  <div className="head-data">
                    <Row>
                      <Col span={8}>
                        <div
                          style={{ cursor: resp?.jobs !== 0 && "pointer" }}
                          className="head-data-item"
                          // onClick={() =>
                          //   history.push({
                          //     pathname: "/joblisting",
                          //     state: { param: resp?.jobs },
                          //   })
                          // }
                        >
                          <div className="head-data-item-number">
                            {resp?.length === 0
                              ? "0"
                              : resp?.userGigs
                              ? resp?.userGigs
                              : 0}
                          </div>
                          <div className="head-data-item-name">Jobs</div>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div
                          className="head-data-item"
                          style={{ cursor: resp?.followers !== 0 && "pointer" }}
                          // onClick={() => {
                          //   resp?.followers !== 0 && history.push("/followers");
                          // }}
                        >
                          <div className="head-data-item-number">
                            {resp?.length === 0
                              ? "0"
                              : resp?.companyProfile?.followers?.length
                              ? resp?.companyProfile?.followers?.length
                              : 0}
                          </div>
                          <div className="head-data-item-name">Followers</div>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div
                          className="head-data-item"
                          style={{ cursor: resp?.views !== 0 && "pointer" }}
                          // onClick={() => {
                          //   resp?.views !== 0 && history.push("/views");
                          // }}
                        >
                          <div className="head-data-item-number">
                            {resp?.length === 0
                              ? "0"
                              : resp?.views
                              ? resp?.views
                              : 0}
                          </div>
                          <div className="head-data-item-name">Views</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </Col>
          </Row>

          {Dimensions.width < 769 && (
            <>
              <div className="dropdown-profile-divider"></div>
              <Row>
                <Col span={8} style={{ cursor: resp?.jobs !== 0 && "pointer" }}>
                  <Row className="following-detail-num">
                    {resp?.length === 0
                      ? "0"
                      : resp?.userGigs
                      ? resp?.userGigs
                      : 0}
                  </Row>
                  <Row className="following-detail-txt">Jobs</Row>
                </Col>
                <Col span={8}>
                  <Row className="following-detail-num">
                    {resp?.length === 0
                      ? "0"
                      : resp?.followers
                      ? resp?.followers
                      : 0}
                  </Row>
                  <Row
                    className="following-detail-txt"
                    // onClick={() => {
                    //   resp?.followers !== 0 && history.push("/followers");
                    // }}
                  >
                    {" "}
                    Followers
                  </Row>
                </Col>
                <Col span={8}>
                  <Row className="following-detail-num">
                    {resp?.length === 0 ? "0" : resp?.views ? resp?.views : 0}
                  </Row>
                  <Row
                    className="following-detail-txt"
                    // onClick={() => {
                    //   resp?.views !== 0 && history.push("/views");
                    // }}
                  >
                    Views
                  </Row>
                </Col>
              </Row>
            </>
          )}

          {Dimensions.width < 769 && <div className="dropdown-profile-divider"></div>}
          <div className="comp-second-container">
            {Dimensions.width > 768 && (
              <div className="company-details-container">
                <Row>
                  <Col className="company-details-outter-div" span={12}>
                    <div className="comp-heading1">Company details</div>
                    <div className="dropdown-profile-divider"></div>
                    <div className="compdetails-div1">
                      <div className="company-det">Company type</div>
                      <div className="company-det">Company size</div>
                      <div className="company-det">Industry</div>
                      <div className="company-det">Location</div>
                      <div className="company-det">Year founded</div>
                    </div>
                  </Col>
                  <Col span={12} className="comp-main">
                    <div
                      className="comp-heading-2"
                      // onClick={() => {
                      //   history.push({
                      //     pathname: "/companyprofile/companydetails",
                      //     state: {
                      //       ProfileImageURL: profilePictureUrl,
                      //       CompanyName: resp.companyProfile?.CompanyName,
                      //       CompanyType: resp.companyProfile?.CompanyType,
                      //       CompanySize: resp.companyProfile?.CompanySize,
                      //       IndustryName:
                      //         resp.companyProfile?.Industries?.IndustryName,
                      //       YearFounded: resp.companyProfile?.YearFounded,
                      //       previous: "companyprofile",
                      //     },
                      //   });
                      // }}
                    >
                      {/* Edit */}
                    </div>

                    <div className="dropdown-profile-divider"></div>
                    <div className="compdetails-div2">
                      <div className="company-det2">
                        {resp?.companyProfile?.CompanyType !== null
                          ? resp?.companyProfile?.CompanyType
                          : "unavailable"}
                      </div>
                      <div className="company-det2">
                        {resp?.companyProfile?.CompanySize !== null
                          ? resp?.companyProfile?.CompanySize
                          : "unavailable"}
                      </div>
                      <div className="company-det2">
                        {resp?.companyProfile?.Industries?.IndustryName !== null
                          ? resp?.companyProfile?.Industries?.IndustryName
                          : "unavailable"}
                      </div>
                      <div className="company-det2">
                        {
                          // resp.length === 0
                          //Migration Changes
                          // resp.companyProfile.Location &&
                          //   resp.companyProfile.Location.Location
                          //   ? resp.companyProfile.Location.Location
                          //   : "Location"
                          //Migration Changes

                          resp?.companyProfile?.LocationWithCountry &&
                          resp?.companyProfile?.LocationWithCountry
                            ? resp?.companyProfile?.LocationWithCountry
                            : "unavailable"
                        }
                      </div>
                      <div className="company-det2">
                        {resp?.companyProfile?.YearFounded !== null
                          ? resp?.companyProfile?.YearFounded
                          : "unavailable"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            <div className="linked-Accdiv">
              <div className="linked-head"> Linked accounts</div>
              <div className="profileDivider2"></div>
              <Row style={{ height: "80%" }}>
                <Col
                  span={18}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-evenly",
                  }}
                >
                  {[null, undefined].includes(
                    linkedAccounts?.Facebook ||
                      linkedAccounts?.Google ||
                      linkedAccounts?.Apple ||
                      linkedAccounts?.LinkedIn
                  ) ? (
                    <Row className="linked-account-det">
                      <Col className="linked-details noDataFound">No Data</Col>
                    </Row>
                  ) : null}
                  {linkedAccounts?.Facebook != null ||
                  linkedAccounts?.Facebook != undefined ? (
                    <Row className="linked-account-det">
                      <Col
                        span={3}
                        className="linked-account-det-icon"
                        onClick={() =>
                          handlingSocialOnClick(linkedAccounts?.Facebook)
                        }
                      >
                        <img
                          src={facebookicon}
                          alt="facebookicon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </Col>
                      <Col
                        className="linked-details"
                        onClick={() =>
                          handlingSocialOnClick(linkedAccounts?.Facebook)
                        }
                      >
                        Facebook
                      </Col>
                    </Row>
                  ) : null}

                  {linkedAccounts?.Google != null ||
                  linkedAccounts?.Google != undefined ? (
                    <Row className="linked-account-det">
                      <Col
                        span={3}
                        className="linked-account-det-icon"
                        onClick={() =>
                          handlingSocialOnClick(linkedAccounts?.Google)
                        }
                      >
                        <img
                          src={Googleicon}
                          alt="googleicon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </Col>
                      <Col
                        className="linked-details"
                        onClick={() =>
                          handlingSocialOnClick(linkedAccounts?.Google)
                        }
                      >
                        Google
                      </Col>
                    </Row>
                  ) : null}

                  {linkedAccounts?.Apple != null ||
                  linkedAccounts?.Apple != undefined ? (
                    <Row className="linked-account-det">
                      <Col
                        span={3}
                        className="linked-account-det-icon"
                        onClick={() =>
                          handlingSocialOnClick(linkedAccounts?.Apple)
                        }
                      >
                        <img
                          src={appleicon}
                          alt="appleicon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </Col>
                      <Col
                        className="linked-details"
                        onClick={() =>
                          handlingSocialOnClick(linkedAccounts?.Apple)
                        }
                      >
                        <div>Apple</div>
                      </Col>
                    </Row>
                  ) : null}

                  {linkedAccounts?.LinkedIn != null ||
                  linkedAccounts?.LinkedIn != undefined ? (
                    <Row className="linked-account-det">
                      <Col
                        span={3}
                        className="linked-account-det-icon"
                        onClick={() =>
                          handlingSocialOnClick(linkedAccounts?.LinkedIn)
                        }
                      >
                        <img
                          src={linkedinicon}
                          alt="linkedinicon"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </Col>
                      <Col
                        className="linked-details"
                        onClick={() =>
                          handlingSocialOnClick(linkedAccounts?.LinkedIn)
                        }
                      >
                        <div>LinkedIn</div>
                      </Col>
                    </Row>
                  ) : null}
                </Col>

                {/* <Col
                  span={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  {linkedAccounts?.Facebook != null ||
                  linkedAccounts?.Facebook != undefined ? (
                    <Row className="iconsComtainer">
                      <Col
                        style={{ "margin-right": "5px" }}
                        className="linked-icons"
                      >
                        <img
                          src={editicon}
                          alt="editicon"
                          className="editIcon-sm"
                          onClick={() => showModal("facebook")}
                        />
                      </Col>
                      <Col className="deleteIcon-cont">
                        <img
                          src={deleteicon}
                          alt="deleteicon"
                          className="deleteIcon-sm"
                          onClick={() => deleteLink("facebook")}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  {linkedAccounts?.Google != null ||
                  linkedAccounts?.Google != undefined ? (
                    <Row className="iconsComtainer">
                      <Col
                        className="linked-icons"
                        style={{ "margin-right": "5px" }}
                      >
                        <img
                          src={editicon}
                          alt="editicon"
                          className="editIcon-sm"
                          onClick={() => showModal("google")}
                        />
                      </Col>
                      <Col className="deleteIcon-cont">
                        <img
                          src={deleteicon}
                          alt="deleteicon"
                          className="deleteIcon-sm"
                          onClick={() => deleteLink("google")}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  {linkedAccounts?.Apple != null ||
                  linkedAccounts?.Apple != undefined ? (
                    <Row className="iconsComtainer">
                      <Col
                        className="linked-icons"
                        style={{ "margin-right": "5px" }}
                      >
                        <img
                          src={editicon}
                          alt="editicon"
                          className="editIcon-sm"
                          onClick={() => showModal("apple")}
                        />
                      </Col>
                      <Col className="deleteIcon-cont">
                        <img
                          src={deleteicon}
                          alt="deleteicon"
                          className="deleteIcon-sm"
                          onClick={() => deleteLink("apple")}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  {linkedAccounts?.LinkedIn != null ||
                  linkedAccounts?.LinkedIn != undefined ? (
                    <Row className="iconsComtainer">
                      <Col
                        className="linked-icons"
                        style={{ "margin-right": "5px" }}
                      >
                        <img
                          src={editicon}
                          alt="editicon"
                          className="editIcon-sm"
                          onClick={() => showModal("linkedIn")}
                        />
                      </Col>
                      <Col className="deleteIcon-cont">
                        <img
                          src={deleteicon}
                          alt="deleteicon"
                          className="deleteIcon-sm"
                          onClick={() => deleteLink("linkedIn")}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Col> */}

                {/* {linkedAccounts?.Facebook == null ||
                linkedAccounts?.Facebook == undefined ? (
                  <Col
                    span={18}
                    className="linked-details-col"
                    onClick={() => showModal("facebook")}
                  >
                    <Row className="linked-account-det">
                      <Col className="linked-details-add-icon">+</Col>
                      <Col className="linked-details-add">Facebook</Col>
                    </Row>
                  </Col>
                ) : null} */}

                {/* {linkedAccounts?.Google == null ||
                linkedAccounts?.Google == undefined ? (
                  <Col
                    span={18}
                    className="linked-details-col"
                    onClick={() => showModal("google")}
                  >
                    <Row className="linked-account-det">
                      <Col className="linked-details-add-icon">+</Col>
                      <Col className="linked-details-add">Google</Col>
                    </Row>
                  </Col>
                ) : null} */}

                {/* {linkedAccounts?.Apple == null ||
                linkedAccounts?.Apple == undefined ? (
                  <Col
                    span={18}
                    className="linked-details-col"
                    onClick={() => showModal("apple")}
                  >
                    <Row className="linked-account-det">
                      <Col className="linked-details-add-icon">+</Col>
                      <Col className="linked-details-add">Apple</Col>
                    </Row>
                  </Col>
                ) : null} */}

                {/* {linkedAccounts?.LinkedIn == null ||
                linkedAccounts?.LinkedIn == undefined ? (
                  <Col
                    span={18}
                    className="linked-details-col"
                    onClick={() => showModal("linkedIn")}
                  >
                    <Row className="linked-account-det">
                      <Col className="linked-details-add-icon">+</Col>
                      <Col className="linked-details-add">LinkedIn</Col>
                    </Row>
                  </Col>
                ) : null} */}
              </Row>
            </div>
          </div>
          {Dimensions.width < 769 && (
            <div className="bannerdiv">
              {resp?.length !== 0 ? (
                resp.companyProfile.MarqueePictureId !== null &&
                marquePicUrl !== "" ? (
                  <div style={{ width: "100%", height: "100%" }}>
                    <img
                      src={marquePicUrl}
                      alt="Marque Picture"
                      className="_img MarquePic"
                      style={{ objectFit: "fill" }}
                    />

                    {/* <div
                      style={{
                        display: "flex",
                        width: "95%",
                        marginTop: "-7rem",
                        justifyContent: "end",
                        alignItems: "end",
                      }}
                    >
                      <button
                        className="bannerbtn2 "
                        onClick={() => {
                          history.push("/marqueealbum")
                          dispatch(fetchUserProfile(talentToken));
                        }}
                      >
                        Edit banner
                      </button>
                    </div> */}
                  </div>
                ) : // <div
                //   style={{
                //     display: "flex",
                //     height: "100%",
                //     justifyContent: "center",
                //     alignItems: "center",
                //   }}
                // >
                //   <button
                //     className="uploadbtn bannerbtn "
                //     onClick={() => history.push("/marqueealbum")}
                //   >
                //     + Upload banner
                //   </button>
                // </div>
                null
              ) : null}
            </div>
          )}
          {Dimensions.width < 769 && (
            <>
              <div>
                <Row style={{ marginTop: "7%" }}>
                  <Col className="company-details-outter-div" span={12}>
                    <div className="comp-heading1">Company details</div>
                    <div className="profileDivider2"></div>
                    <div className="compdetails-div1">
                      <div>Company type</div>
                      <div>Company size</div>
                      <div>Industry</div>
                      <div>Location</div>
                      <div>Year founded</div>
                    </div>
                  </Col>
                  <Col span={12} className="comp-main">
                    <div
                      className="comp-heading2"
                      // onClick={() => {
                      //   history.push({
                      //     pathname: "/companyprofile/companydetails",
                      //     state: {
                      //       ProfileImageURL: profilePictureUrl,
                      //       CompanyName: resp.companyProfile.CompanyName,
                      //       CompanyType: resp.companyProfile.CompanyType,
                      //       CompanySize: resp.companyProfile.CompanySize,
                      //       IndustryName: resp.companyProfile.IndustryName,
                      //       YearFounded: resp.companyProfile.YearFounded,
                      //     },
                      //   });
                      // }}
                    >
                      {/* Edit */}
                    </div>

                    <div className="compdetails-div2">
                      <div>
                        {resp?.companyProfile?.CompanyType !== null
                          ? resp?.companyProfile?.CompanyType
                          : "unavailable"}
                      </div>
                      <div>
                        {resp?.companyProfile?.CompanySize !== null
                          ? resp?.companyProfile?.CompanySize
                          : "unavailable"}
                      </div>
                      <div>
                        {resp?.companyProfile?.IndustryName !== null
                          ? resp?.companyProfile?.IndustryName
                          : "unavailable"}
                      </div>
                      <div>
                        {
                          // resp?.length === 0
                          resp?.companyProfile?.Location &&
                          resp?.companyProfile?.Location?.Location
                            ? resp?.companyProfile?.Location?.Location
                            : "unavailable"
                        }
                      </div>
                      <div>
                        {resp?.companyProfile?.YearFounded !== null
                          ? resp?.companyProfile?.YearFounded
                          : "unavailable"}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="dropdown-profile-divider"></div>
            </>
          )}
          <div className="culture-container">
            <Row>
              <Col span={12}>
                <div className="headings">Our culture</div>
              </Col>

              <Col span={12}>
                {culturePicturesUrl.length > 0 ? (
                  <div
                    className="comp-main comp-heading2"
                    // onClick={() => {
                    //   history.push("/companyprofile/companyculturephotos");
                    // }}
                  >
                    {/* Edit */}
                  </div>
                ) : (
                  <div className="comp-main comp-heading3"></div>
                )}
              </Col>
            </Row>
            <div className="profileDivider3"></div>
            {/* <div className="heading-txt">
              Highlight your company’s culture! Add pictures like your offices
              or past events.
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                overflowX: `${
                  culturePicturesUrl?.length > 0 ? "scroll" : "unset"
                }`,
                paddingLeft: "5px",
              }}
            >
              {/* <Carousel> */}
              {resp?.companyCulturePhotos?.length > 0 &&
              resp?.companyCulturePhotos[0]?.Album_Pictures?.length > 0 &&
              culturePicturesUrl?.length > 0 ? (
                culturePicturesUrl?.map((item) => {
                  return (
                    <Col
                      style={{
                        width: Dimensions.width > 768 ? "32%" : "140px",
                        height: Dimensions.width > 768 ? "210px" : "90px",
                        marginRight: Dimensions.width > 768 ? "15px" : "0px",
                        marginLeft: Dimensions.width > 768 ? "0px" : "15px",
                        objectFit: "cover",
                      }}
                      lg={8}
                      md={8}
                      sm={8}
                      xs={8}
                    >
                      <img
                        // src={item}
                        src={`${process.env.REACT_APP_S3_URL}${
                          item?.charAt(0) !== "/" ? "/" : ""
                        }${item}`}
                        alt="Culture Picture"
                        style={{
                          width: "100%",
                          height: Dimensions.width > 768 ? "210px" : "90px",
                          objectFit: "fill",
                        }}
                      />
                    </Col>
                  );
                })
              ) : (
                // <div className="button-container">
                //   <button
                //     className={
                //       Dimensions.width < 768
                //         ? "uploadbtn photosbtn"
                //         : "uploaddesbtn"
                //     }
                //     onClick={() =>
                //       history.push("/companyprofile/companyculturephotos")
                //     }
                //   >
                //     {Dimensions.width < 768 ? `+ Add Photos` : `+`}
                //   </button>
                //   {Dimensions.width > 768 && (
                //     <div className="add-heading">Add photos</div>
                //   )}
                // </div>
                <div className="heading-txt heading-txt_bold">coming soon</div>
              )}
              {/* </Carousel> */}
            </div>
          </div>
          {Dimensions.width < 768 && <div className="dropdown-profile-divider"></div>}
          <div className="whyweexist-container">
            <Row>
              <Col span={12}>
                <div className="headings">Why we do what we do</div>
              </Col>
              <Col span={12}>
                <div
                  className="comp-main comp-heading2"
                  // onClick={() => {
                  //   localStorage.setItem("AboutMessage", resp.companyProfile.CompanyDescription);
                  //   history.push({
                  //     pathname: "/companyprofile/aboutcompany",
                  //     state: {
                  //       CompanyDescription:
                  //         resp.companyProfile.CompanyDescription,
                  //       fromCompanyProfile:true
                  //     },
                  //   });
                  // }}
                >
                  {/* {resp.companyProfile.CompanyDescription?.length > 1
                    ? `Edit Description`
                    : `Add Description`} */}
                </div>
              </Col>
            </Row>

            <div className="profileDivider4"></div>
            <div className="heading-txt-margin"></div>
            {resp?.companyProfile?.CompanyDescription?.length > 1 ? (
              <div className="heading-txt heading-txt_bold">
                {/* {resp.companyProfile.CompanyDescription} */}
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                {/* <div dangerouslySetInnerHTML={{ __html: description.substring(0, 140) }}></div> */}
              </div>
            ) : (
              <div className="heading-txt heading-txt_bold">coming soon</div>
              // <div className="descriptionbtn">
              //   <button className="uploadbtn "
              //     onClick={() => {
              //       history.push({
              //         pathname: "/companyprofile/aboutcompany",
              //         state: {
              //           CompanyDescription:
              //             resp.companyProfile.CompanyDescription,
              //         },
              //       });
              //     }}
              //   >
              //     + Add description
              //   </button>
              // </div>
            )}
          </div>
          {Dimensions.width < 768 && <div className="dropdown-profile-divider"></div>}
          <div className="people-container">
            <Row>
              <Col span={12}>
                <div className="headings">Our people</div>
              </Col>
              <Col span={12}>
                {ourpeoplePictureUrl.length > 0 ? (
                  <div
                    className="comp-main comp-heading2"
                    // onClick={() => {
                    //   history.push("/companyprofile/addemployee");
                    // }}
                  >
                    {/* Edit */}
                  </div>
                ) : (
                  <div className="comp-main comp-heading3"></div>
                )}
              </Col>
            </Row>
            {Dimensions.width > 769 && <div className="profileDivider3"></div>}
            {/* <div className="heading-txt">
              Let your happy team members sell your company for you with the
              employee testimonials
            </div> */}
            {resp?.ourPeoplePhotos?.length > 0 &&
            resp?.ourPeoplePhotos[0]?.Album_Pictures?.length > 0 &&
            resp?.ourPeoplePhotos[0]?.Album_Pictures[0]?.Filename !== null ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "40px",
                }}
              >
                <Col span={5} className="people_img_cont">
                  <img
                    // src={ourpeoplePictureUrl}
                    src={`${process.env.REACT_APP_S3_URL}/content/pics/albums/${ourpeoplePictureUrl}`}
                    alt="people picture"
                    style={{ width: "100%", height: "100%" }}
                    // className="_img"
                  />
                </Col>
                <Col span={18}>
                  <div className="people-name">
                    {resp?.ourPeoplePhotos[0]?.Album_Pictures[0]?.Name},{" "}
                    {resp?.ourPeoplePhotos[0]?.Album_Pictures[0]?.WorkCulture}
                  </div>
                  <div className="people-test">
                    {resp?.ourPeoplePhotos[0]?.Album_Pictures[0]?.Description}
                  </div>
                  {/* <div className="people-test">
                    {resp?.ourPeoplePhotos[0]?.Album_Pictures[0]?.WorkCulture}
                  </div> */}
                </Col>
              </div>
            ) : (
              // <div className="button-container">
              //   <button
              //     className={
              //       Dimensions.width < 768
              //         ? "uploadbtn employeebtn"
              //         : "uploaddesbtn"
              //     }
              //     onClick={() => history.push("/companyprofile/addemployee")}
              //   >
              //     {Dimensions.width < 768 ? `+ Add Employee` : `+`}
              //   </button>
              //   {Dimensions.width > 768 && (
              //     <div className="add-heading">Add employee</div>
              //   )}
              // </div>

              <div className="heading-txt heading-txt_bold">coming soon</div>
            )}
          </div>

          {/* {Dimensions.width < 768 && <div className="dropdown-profile-divider"></div>}
          <div className="job-container">
            <Row className="jobsTileCont">
              <Col span={12}>
                <div className="job-headings">
                  Our Jobs
                  {Dimensions.width > 768 && (
                    <div className="job-length">{resp.jobs}</div>
                  )}
                </div>
              </Col>

              <Col span={12}>
                <div
                  className="comp-main comp-heading2"
                  onClick={() => history.push("/joblisting")}
                >
                  See all
                </div>
              </Col>
            </Row>
            {Dimensions.width > 768 && <div className="profileDivider3"></div>}
            {resp.userGigs?.length > 0 ? (
              Dimensions.width < 768 ? (
                <Col xs={24} lg={7}>
                  <JobCard
                    fromDashboard={true}
                    img={
                      // resp?.userGigs[0]?.Location?.RegionCode != null &&
                      // resp?.userGigs[0].Location?.CityName != null
                      //   ? getImageByStateCode(
                      //       resp?.userGigs[0]?.Location?.RegionCode,
                      //       resp?.userGigs[0].Location?.CityName
                      //     )

                      resp?.userGigs[0]?.Regions?.Code != null &&
                        resp?.userGigs[0].Cities?.CityName != null
                        ? getImageByStateCode(
                          resp?.userGigs[0]?.Regions?.Code,
                          resp?.userGigs[0].Cities?.CityName
                        )
                        : candidate_img
                    }
                    rate={"week"}
                    budget={resp?.userGigs[0]?.BudgetMin}
                    facilityname={
                      professionCode === "IT"
                        ? null
                        : resp?.userGigs[0]?.FacilityName
                    }
                    title={resp?.userGigs[0]?.Title}
                    matchedbids={
                      resp?.userGigs[0]?.status[2]?.count
                        ? resp?.userGigs[0]?.status[2]?.count
                        : 0
                    }
                    cardFooter={true}
                    placedbids={
                      resp?.userGigs[0]?.status[0]?.count
                        ? resp?.userGigs[0]?.status[0]?.count
                        : 0
                    }
                    receivedbids={
                      resp?.userGigs[0]?.status[1]?.count
                        ? resp?.userGigs[0]?.status[1]?.count
                        : 0
                    }
                  />
                </Col>
              ) : (
                <Row
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "35px",
                    justifyContent: "space-between",
                  }}
                >
                  {resp?.userGigs ? (
                    resp?.userGigs?.map((items, index) => {
                      return Dimensions.width < 786 && index > 1 ? null : (
                        <Col className="jobCard-div-company">
                          <JobCard
                            fromDashboard={true}
                            className="jobCard"
                            cardFooter={true}
                            // id={items._id}
                            id={items?.JobOrderId}
                            {...items}
                            title={items.Title}
                            receivedbids={
                              items.status[1]?.count
                                ? items.status[1]?.count
                                : 0
                            }
                            placedbids={
                              items.status[0]?.count
                                ? items.status[0]?.count
                                : 0
                            }
                            budget={items.BudgetMin}
                            facilityname={items.FacilityName}
                            matchedbids={
                              items.status[2]?.count
                                ? items.status[2]?.count
                                : 0
                            }
                            img={
                              // items.Location?.RegionCode !== null &&
                              // items.Location?.CityName !== null
                              //   ? getImageByStateCode(
                              //       items.Location?.RegionCode,
                              //       items.Location?.CityName
                              //     )


                              items?.Regions?.Code !== null &&
                                items?.Cities?.CityName !== null
                                ? getImageByStateCode(
                                  items?.Regions?.Code,
                                  items?.Cities?.CityName
                                )
                                : candidate_img
                            }
                          />
                        </Col>
                      );
                    })
                  ) : (
                    <Col xs={24}>
                      {" "}
                      <Empty />{" "}
                    </Col>
                  )}
                </Row>
              )
            ) : // <div className="button-container">
            //   <button
            //     className={
            //       Dimensions.width < 768
            //         ? "uploadbtn postjobbtn"
            //         : "uploaddesbtn"
            //     }
            //     onClick={() => history.push("/postjob")}
            //   >
            //     {Dimensions.width < 768 ? `+ Add gigs` : `+`}
            //   </button>
            //   {Dimensions.width > 768 && (
            //     <div className="add-heading">Add gigs</div>
            //   )}
            // </div>

            null}
          </div> */}

          {/* {Dimensions.width < 768 && <div className="dropdown-profile-divider"></div>} */}
        </Col>
      </div>
    );
  } else {
    return (
      <div>
        <Loader />
      </div>
    );
  }
}
