import { Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import copyIcon from "../../../assets/icons/bx_bx-copy.svg";
import "./InterviewCard.css";
import moment from "moment";

const InterviewCard = ({ interview }) => {
  const history = useHistory();
  const {
    employer: {
      name: employer_name,
      Profiles: { PictureUrl: employer_logo },
    },
    ApprovedScheduledTiming: {
      StartTime: interview_start_time,
      EndTime: interview_end_time,
    },
    InterviewType: is_active,
    Location: interview_location,
    MeetingURL: interview_url,
    gigs: { Title: job_title },
    conversation: { ConversationId },
  } = interview;

  const interview_is_active = is_active === "Online" ? true : false;

  // function for getTime AM & PM format

  const getTime = (t1) => {
    const t = new Date(t1).toLocaleTimeString();
    const time =
      t.slice(0, t.lastIndexOf(":")) +
      " " +
      t.slice(t.lastIndexOf(" ")).toUpperCase();
    return time;
  };

  const [copySuccess, setCopySuccess] = useState("");

  // function to copy here

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
    } catch (error) {
      setCopySuccess("Failed to copy!");
    }
  };

  // console.log(copySuccess);
  return (
    <>
      <div className="Interview_Details_GigsCard">
        <div
          className="Interview_Avatar"
          onClick={() =>
            history.push({
              pathname: `/messages`,
              state: {
                openConvo: true,
                conversationId: ConversationId,
              },
            })
          }
        >
          {employer_logo ? (
            <img
              src={`${process.env.REACT_APP_S3_URL}${
                employer_logo?.charAt(0) !== "/" ? "/" : ""
              }${employer_logo}`}
              alt="companyLogo"
              className="Img"
            />
          ) : (
            <div className="Img Without_Logo">
              {employer_name.slice(0, 1).toUpperCase()}
            </div>
          )}
          <div
            className={`Interview_Active_Bubble ${
              interview_is_active ? "Interview_Active" : ""
            }`}
          ></div>
        </div>
        <div className="Interview_Content">
          <div
            onClick={() =>
              history.push({
                pathname: `/messages`,
                state: {
                  openConvo: true,
                  conversationId: ConversationId,
                },
              })
            }
          >
            <p>{employer_name}</p>
            <p>{job_title}</p>
            <p>
              {moment(interview_start_time).format("LT")} -{" "}
              {moment(interview_end_time).format("LT")}
              {/* {getTime(interview_start_time)} - {getTime(interview_end_time)} */}
            </p>
          </div>
          {interview_is_active ? (
            <div className="Interview_Link">
              <span>{interview_url}</span>
              <Tooltip title={copySuccess} trigger={`click`} color={"#2db7f5"}>
                <img
                  src={copyIcon}
                  className="bxbx-copy"
                  onClick={() => copyToClipBoard(interview_url)}
                />
              </Tooltip>
            </div>
          ) : (
            <div
              className="Interview_Location"
              onClick={() =>
                history.push({
                  pathname: `/messages`,
                  state: {
                    openConvo: true,
                    conversationId: ConversationId,
                  },
                })
              }
            >
              {interview_location}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InterviewCard;
