import React, { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const Loader = ({ size, color, height, scrolling = true }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    const is3dResumePath = pathname === "/3d_resume";
    scrolling &&
      !is3dResumePath &&
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: size ? size : "2.5rem",
        margin: `${height ? height : "15vh"} auto`,
        color: color ? color : "#118936",
      }}
    >
      <LoadingOutlined />
    </div>
  );
};

export default Loader;
