import { Rate } from "antd";
import React from "react";
import "./cardRating.css";
const CardThree = () => {
  return (
    <>
      <div className="parentCard">
        <div class="Congratulations-Your-succesfully-completed-the-job">
          <div class="text-style-1">Thankyou!</div>
          You left employer Name 5 star review
        </div>
        <div className="card-insidebox">
          <div className="g-star-ratings">
            <Rate allowHalf defaultValue={5} disabled={true} />
          </div>
          <div className="card-insidebox3">
            <div className="card-attribute">
              <div className="card-attribute_item1"> Communication</div>
              <div className="card-attribute_item3">
                {" "}
                <Rate allowHalf defaultValue={5} disabled={true} />
              </div>
            </div>
            <div className="card-attribute">
              <div className="card-attribute_item1">Work knowledge</div>
              <div className="card-attribute_item3">
                {" "}
                <Rate allowHalf defaultValue={5} disabled={true} />
              </div>
            </div>
            <div className="card-attribute">
              <div className="card-attribute_item1">Would recommend</div>
              <div className="card-attribute_item3">
                {" "}
                <Rate allowHalf defaultValue={5} disabled={true} />
              </div>
            </div>
          </div>
          <div className="card-attribute_item33">
            Communication was great.He answered all my questions and explained
            the process throughout! Will definitely be utilizing his services
            again in the future!
          </div>
        </div>
        <div className="duration-card">11:35pm</div>
      </div>
      <br />
    </>
  );
};

export default CardThree;
