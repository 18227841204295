import React from "react";
import "./NoViewCard.css";
import NoViewCardIcon from "../../../assets/icons/Group (2).svg";

const NoViewCard = () => {
  return (
    <>
      <div className="NoViewCardContainer">
        <img src={NoViewCardIcon} alt="" className="NoViewCardIcon" />
        <span className="NoViewCardText">No data</span>
      </div>
    </>
  );
};

export default NoViewCard;
