import React from "react";
import "./Chat.css";
import {
    Row,
    Col,
    Typography,
    Input,
    Divider
} from "antd";
import {useParams, useLocation} from "react-router-dom"

import SendMessage from "./components/SendMessage";
import ChatHistory from "./components/ChatHistory";

const {Title} = Typography;
const responsiveCardSizes = {
    xs: {
        span: 22,
        offset: 1,
    },
    md: {
        span: 20,
        offset: 2,
    }
}

const Chat = props => {
    const {id} = useParams();
    const {state} = useLocation();
    // console.log("state:",state)
    return(
        <Row>
            <Col {...responsiveCardSizes} className={'chat-container'}>
                <Row>
                    <SendMessage
                        conversationId={id}
                        recipientId={state.recipientId}
                        contextType={state.contextType}
                        contextName={state.contextName}
                        userName={state.userName}
                        profileName={state.receipentName}
                        user={state.user}
                    />
                    <Col span={24}>
                        <Title level={5} className={'chat-history-panel'}>History</Title>
                        <Divider/>
                        <ChatHistory
                            conversationId={id}
                            recipientId={state.recipientId}
                            user={state.user}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Chat;
