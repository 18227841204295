import { types } from "../action-types";

const initialState = {
  myFavorites: [],
  Loading: false,
  Error: {},
  removeFavorites: [],
  myFavoritesTotal: [],
};

const myFavoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REMOVE_FAVORITE_GIG_CARD:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_FAVORITE_GIG_CARD_SUCCESS:
      return {
        ...state,
        myFavorites: state.myFavorites.filter(
          (item, i) => action.payload !== item.gig.JobOrderId
        ),
      };
    case types.FAVORITE_GIG_CARD:
      return {
        ...state,
        loading: true,
      };
    case types.FAVORITE_GIG_CARD_SUCCESS:
      const dublicateFavoriteGigs = state.myFavorites.filter(
        (favGig) => favGig.gig.JobOrderId === action.payload.JobOrderId
      );
      return dublicateFavoriteGigs.length
        ? { ...state }
        : {
            ...state,
            myFavorites: [
              { gig: { ...action.payload, IsFavorite: true } },
              ...state.myFavorites,
            ],
          };
    case types.GET_FAVORITES_INFO_REQUEST:
      return {
        ...state,
        Loading: true,
      };
    case types.GET_FAVORITES_INFO_SUCCESS:
      // console.log("GET_FAVORITES_INFO_SUCCESS", action.payload);
      return {
        ...state,
        Loading: false,
        myFavorites: action.payload.body.data,
        myFavoritesTotal: action.payload,
        Error: {},
      };
    case types.GET_FAVORITES_INFO_FAILURE:
      return {
        ...state,
        Loading: false,
        Error: action.error,
      };
    default:
      return state;
  }
};

export default myFavoritesReducer;
