import React, { useState, useEffect } from "react";
import "./TabSkillisting.css";

import moment from "moment";
import { useSelector } from "react-redux";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";

const TabSkillListing = ({ SkillListing, ProfessionCode }) => {
  const [bidType, setbidType] = useState("");
  const [availability, setAvailability] = useState();

  const profile = useSelector((state) => state.profileReducer?.user?.profile);

  useEffect(() => {
    if (SkillListing?.MinimumBidType === "Year") {
      setbidType("yr");
    } else {
      setbidType("hr");
    }

    setAvailability(SkillListing?.StartDate);
    // // console.log("SkillListingSkillListing", SkillListing);
  }, [SkillListing]);

  // useUpdateLogger("skillcheck", SkillListing?.SkillListings_Skills);

  return (
    <div className="tabSkillListing">
      <section className="careerSummary">
        <div className="title">Career summary</div>
        <div className="value">
          {profile.Description ? profile.Description : "N/A"}
        </div>
      </section>
      <section className="jobType">
        <div className="title">Gig type</div>
        <div className="value">
          {SkillListing?.JobOrderTypes?.JobOrderTypeName}
        </div>
      </section>
      <section className="jobType">
        <div className="title">Role</div>
        <div className="value">{SkillListing?.Title}</div>
      </section>
      <section className="skillsData">
        <div className="title">Skills</div>
        <div className="box">
          {SkillListing?.SkillListings_Skills?.map((data) => (
            <div className="databox">
              {data?.SkillName ? data.SkillName : "NA"}
            </div>
          ))}
        </div>
      </section>
      <section className="willingToRelocate">
        <div className="title">Willing to relocate</div>
        <div className="box">
          {SkillListing?.SkillListings_Cities?.length > 0 ? (
            <>
              {SkillListing?.SkillListings_Cities?.map((data) => (
                <div className="databox">
                  {data?.Cities?.CityName
                    ? `${data?.Cities?.CityName}, ${data.Cities?.Region?.Code}`
                    : "NA"}
                </div>
              ))}
            </>
          ) : (
            "NA"
          )}
        </div>
      </section>
      {SkillListing?.MinimumBid?.$numberDecimal ||
        (SkillListing?.MinimumBid && (
          <section className="desiredSalary">
            <div className="title">
              {ProfessionCode === "IT" && bidType === "yr"
                ? "Estimated Annual Salary"
                : ProfessionCode === "IT" && bidType === "hr"
                ? "Estimated Bill Rate"
                : "Desired salary"}
            </div>
            <div className="value">{`$${
              SkillListing?.MinimumBid?.$numberDecimal
                ? SkillListing?.MinimumBid?.$numberDecimal
                : SkillListing?.MinimumBid?.toLocaleString("en-US")
                ? SkillListing?.MinimumBid?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : 0
            }/${bidType}`}</div>
          </section>
        ))}
      <section className="availability">
        <div className="title">Availability</div>
        <div className="value">
          {availability ? moment(availability).format("ll") : "Not Available"}
        </div>
      </section>
    </div>
  );
};

export default TabSkillListing;
