import React from 'react';
import {Col, Row, Typography, Button} from 'antd'
import './HomeCategoryCard.css'

const { Title,Text } = Typography;
const HomeCategoryCard = props => {
    return(
        <Col
            lg={{
                span: 20,
                offset: 2,
            }}
            md={{
                span: 18,
                offset: 3,
            }}
            xs={{
                span: 22,
                offset: 1,
            }}
            className={'home-category-card-container'}
        >
            <Row>
                <Col span={24} className={'titleBar'}>
                    <Title level={4}>{props.title}<Text strong className="counter">{props.count}</Text></Title>
                    {
                        props.elementRight
                    }
                </Col>
            </Row>
            {props.children}
            <Row>
                {props.elementBottom}
            </Row>
        </Col>
    )
}

export default HomeCategoryCard;
