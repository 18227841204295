import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "./ManageSkillListing.css";
import moment from "moment";
import { Select, Menu, Dropdown, message, Modal } from "antd";

import { useSelector } from "react-redux";

import ModalDelete from "./ModalDelete";
import ModalEdit from "./ModalEdit";
// icons
import eyesign from "../../assets/img/eye.png";
import { deleteSkillListing, fetchUserSkillListing } from "../../redux/actions";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import { EyeOutlined } from "@ant-design/icons";
import { BsFillEyeFill } from "react-icons/bs";

const ManageSkillListingtwo = (props) => {
  const history = useHistory();
  const { Option } = Select;

  const { skillListings } = props;
  const [skillFiller, setSkillFiller] = useState({});
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [editModalState, setEditModalState] = useState(false);

  const professionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );

  console.log(props, "propsprops");

  // useUpdateLogger("skillListings", skillListings);

  useEffect(() => {
    if (props.formSkills) {
      const skills = props.formSkills.skillListings;
      // // console.log(skills);
      const skill = skills[skills.length - 1];
      // // console.log(skill);
      setSkillFiller(skill);
    }
  }, [props.formSkills]);

  const handleUpdateSkillListing = (state) => {
    setEditModalState(state);
  };

  const menu = (
    <Menu
      className="editDeleteDropdownSkill"
      // onClick={(e) => e.stopPropagation()}
    >
      <Menu.Item
        className="editDropdownSkill"
        key="1"
        onClick={(e) => {
          // e.stopPropagation();
          handleUpdateSkillListing(true);
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        className="deleteDropdownSkill"
        key="2"
        onClick={(e) => {
          // e.stopPropagation();
          setDeleteConfirmModal(true);
        }}
      >
        <span>Delete</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="skillisting-card-outer-box">
      {props.skillListings.SkillListingStatuses.SkillListingStatusName ===
      "Published" ? (
        <div
          className={`${"card-inner-box"} clickable`}
          onClick={() =>
            history.push({
              pathname: `/skill-listing-detail-page/${props.skillListings.SkillListingId}`,
              state: { SkillListing: props.skillListings },
            })
          }
        >
          <div className="dropdown-title-skilllisting">
            <span className="skillfont">
              {props.skillListings?.DisplayTitle
                ? props.skillListings?.DisplayTitle
                : props.skillListings.Title}
            </span>
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown.Button
                overlayClassName="dropdownClassSkillL"
                // onClick={(e) => e.stopPropagation()}
                overlay={menu}
                trigger={["click"]}
              >
                <ModalEdit
                  editModalState={editModalState}
                  setEditModalState={setEditModalState}
                  handleUpdateSkillListing={handleUpdateSkillListing}
                  id={props.skillListings._id}
                  // idSkill={props.skillListings.SkillListingId}
                />
                <ModalDelete
                  deleteSkillListing={props.deleteSkillListing}
                  deleteConfirmModal={deleteConfirmModal}
                  setDeleteConfirmModal={setDeleteConfirmModal}
                  id={props.skillListings._id}
                />
              </Dropdown.Button>
            </div>
          </div>
          <div className="locationfont">
            {props?.skillListings?.SkillListings_Cities?.length > 0 &&
              props?.skillListings?.SkillListings_Cities?.map((loc) => {
                // console.log("loc", loc);
                return (
                  <span>
                    {" "}
                    {loc?.Cities?.Location ? `${loc?.Cities?.Location}` : "N/A"}
                  </span>
                ); // space required
              })}
          </div>
          {props?.skillListings?.MinimumBid?.$numberDecimal ||
            (props?.skillListings?.MinimumBid && (
              <div className="salaryfont">
                {`${
                  professionCode === "IT" &&
                  props.skillListings.MinimumBidType === "Year"
                    ? "Estimated Annual Salary"
                    : professionCode === "IT" &&
                      props.skillListings.MinimumBidType === "Hour"
                    ? "Estimated Bill Rate"
                    : "Desired Salary"
                } $${
                  props?.skillListings?.MinimumBid?.$numberDecimal
                    ? props?.skillListings?.MinimumBid?.$numberDecimal
                    : props?.skillListings?.MinimumBid?.toLocaleString("en-US")
                    ? props?.skillListings?.MinimumBid?.toLocaleString("en-US")
                    : 0
                }${
                  props.skillListings.MinimumBidType !== "Year" ? "/HR" : "/YR"
                }`}
              </div>
            ))}
          <div className="contain">
            <div className="publishedfontframe">
              <span className="publishedfont">Published</span>
            </div>
            <span className="daysfont">
              {moment(props.skillListings.Created).fromNow()}
            </span>
          </div>
          <div className="manage-skillisting-status">
            <span
              className="eyeicon"
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  "/view-listing/" + props.skillListings?.SkillListingId
                );
              }}
            >
              {/* <BsEyeFill /> */}{" "}
              <BsFillEyeFill className="viewsManageSkillisting eyeIconStyle" />
            </span>
            <span
              className="eyeiconviews"
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  "/view-listing/" + props.skillListings?.SkillListingId
                );
              }}
            >
              {skillListings?.views?.length > 0
                ? skillListings?.views?.length
                : 0}
            </span>
            <span
              className="bidfont"
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: "/receivedBids",
                  state: {
                    skillListingId: props.skillListings.SkillListingId,
                  },
                });
              }}
            >
              {skillListings?.Bids?.length ? skillListings?.Bids?.length : 0}
              {skillListings?.Bids?.length > 0 ? " Bids" : " Bid"}
            </span>
          </div>
        </div>
      ) : (
        <div>
          <div className="skillisting-card-outer-box">
            <div
              className={`${
                // props.deletedData.length > 1
                // ?
                "Rectangle-17523deleted"
                // : "singleSkillListingDeleted"
              }`}
            >
              <div className="skillfontdeleted">
                {props.skillListings?.DisplayTitle
                  ? props.skillListings?.DisplayTitle
                  : props.skillListings.Title}
              </div>
              <div className="locationfontdeleted">
                {props?.skillListings?.SkillListings_Cities?.length > 0 &&
                  props?.skillListings?.SkillListings_Cities?.map((loc) => {
                    // // console.log("loc", loc);
                    return (
                      <span>
                        {" "}
                        {loc?.Cities?.Location
                          ? `${loc?.Cities?.Location}`
                          : "N/A"}
                      </span>
                    ); // space required
                  })}
              </div>
              <div className="salaryfontdeleted">
                {`Desired salary $${
                  props?.skillListings?.MinimumBid?.$numberDecimal
                    ? props?.skillListings?.MinimumBid?.$numberDecimal
                    : props?.skillListings?.MinimumBid
                    ? props?.skillListings?.MinimumBid
                    : 0
                }${
                  props.skillListings.MinimumBidType !== "Year" ? "/HR" : "/YR"
                }`}
              </div>
              <div className="containdeleted">
                <div className="publishedfontframedeleted">
                  <span className="publishedfontdeleted">Deleted</span>
                </div>
                <span className="daysfontdeleted">
                  {moment(props.skillListings.Created).fromNow()}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataForm: state.credentialingReducer.step3,
    formSkills: state.skillListingReducer,
    searchedDiscipline: state.skillListingReducer.searchedDiscipline,
    disciplineLoader: state.skillListingReducer.disciplineLoader,
    currentSkillListing: state.skillListingReducer.currentSkillListing,
    // loadingResume: state.docReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSkillListing: () => {
      dispatch(fetchUserSkillListing());
    },
    deleteSkillListing: (id) => {
      dispatch(deleteSkillListing(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSkillListingtwo);
