import { call, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { types } from "../action-types";
import {
  fetchPlaceBidDataAPI,
  fetchReceivedBidsApi,
  fetchReceivedBidsStatusAPI,
  updateReceivedBidsApi,
  fetchRetracteBidDataAPI,
  fetchDetailPlaceBidDataAPI,
  fetchDetailReceivedBidDataAPI,
  fetchDeclinedReasonsBidsAPI,
  updateDeclinedReasonsReceivedBidsAPI,
  fetchReceivedBidCountApi
} from "../../API/API";

import {
  fetchDeclinedReasonsBidsFailure,
  fetchDeclinedReasonsBidsSuccess,
  fetchDetailPlaceBidFailure,
  fetchDetailPlaceBidSuccess,
  fetchDetailReceivedFailure,
  fetchDetailReceivedSuccess,
  retractedBidFetchFailure,
  retractedBidFetchSuccess,
  updateDeclinedReasonsReceivedBidsSuccess,
  updateDeclinedReasonsReceivedBidsFailure,
  fetchmanageBidSuccess,
  fetchDetailReceivedBid,
  catchError,
} from "../actions";

import {
  placeBidFetch,
  placeBidFetchFailure,
  placeBidFetchSuccess,
} from "../actions";
function* statusReceivedBidsSaga() {
  // console.log("STATUS Saga HIT: ");
  try {
    const response = yield call(() => fetchReceivedBidsStatusAPI());
    // console.log("Receivedbids STATUS RESPONSE: ", response);
    yield put({
      type: types.FETCH_RECEIVEDBIDS_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // // console.log("receivedBids ERROR: ", error);
    yield put({ type: types.FETCH_RECEIVEDBIDS_FAILURE, error });
  }
}
function* fetchReceivedBidsSaga(action) {
  // console.log("Received bids saga: ", action);
  try {
    const { BidStatusName, SkillListingId, sortBy, lat, long, page, perPage } =
      action.payload;
    // console.log("Received bids saga location: ", lat, long);
    const [response, ManageBidCountRes] = yield call(() =>
      fetchReceivedBidsApi(
        BidStatusName,
        SkillListingId,
        sortBy,
        lat,
        long,
        page,
        perPage
      )
    );
    // const res = yield call(() => fetchmanageCountApi(BidStatusName));
    // console.log("Receivedbids RESPONSE: ", response);
    yield put({
      type: types.FETCH_RECEIVEDBIDS_SUCCESS,
      payload: response.data.data,
    });
    yield put(fetchmanageBidSuccess(ManageBidCountRes?.data?.body));
  } catch (error) {
    // // console.log("receivedBids ERROR: ", error);
    yield put({ type: types.FETCH_RECEIVEDBIDS_FAILURE, error });
    yield put(catchError(error));
  }
}
function* fetchReceivedBidCountSaga(action) {
  try {
    const response = yield call(() => fetchReceivedBidCountApi(action.payload));
    yield put({
      type: types.FETCH_RECEIVEDBID_COUNT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.FETCH_RECEIVEDBID_COUNT_FAILURE, error });
  }
}
function* updateReceivedBidsSaga(params) {
  // console.log("in update bid saga");
  // console.log("param:", JSON.stringify(params));
  try {
    const response = yield call(updateReceivedBidsApi, params);
    yield put({ type: types.FETCH_UPDATEBIDS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.FETCH_UPDATEBIDS_FAILURE, error });
  }
}

function* retractedBidDataSaga(action) {
  // console.log("favoriteGigCardSag");
  try {
    const { sort, lat, long, page, perPage } = action.payload;
    const response = yield call(() =>
      fetchRetracteBidDataAPI(sort, lat, long, page, perPage)
    ); // API Backend
    // console.log("retractedBidDataSaga:", response);
    if (response.status == 200) {
      yield put(retractedBidFetchSuccess(response?.data?.data));
    } else {
      yield put(retractedBidFetchFailure(response));
    }
  } catch (error) {
    yield put(retractedBidFetchFailure(error));
  }
}

function* placeBidDataSaga(action) {
  try {
    const { sort, lat, long, page, perPage, BidStatusName } = action.payload;
    const [response, ManageBidCountRes] = yield call(() =>
      fetchPlaceBidDataAPI(sort, lat, long, page, perPage, BidStatusName)
    ); // API Backend
    // console.log("placeBidDataSaga:", response);
    if (response.status == 200) {
      yield put(placeBidFetchSuccess(response.data.data));
      yield put(fetchmanageBidSuccess(ManageBidCountRes?.data?.body));
    } else {
      yield put(placeBidFetchFailure(response));
    }
  } catch (error) {
    yield put(placeBidFetchFailure(error));
    yield put(catchError(error));
  }
}

function* detailPlaceBidSaga(params) {
  // console.log("detailPB Saga", params);

  try {
    const response = yield call(() =>
      fetchDetailPlaceBidDataAPI(params.payload)
    ); // API Backend
    if (response.status == 200) {
      // console.log("detailPlaceBidDataSaga:", response);

      yield put(fetchDetailPlaceBidSuccess(response.data));
    } else {
      yield put(fetchDetailPlaceBidFailure(response));
    }
  } catch (error) {
    yield put(fetchDetailPlaceBidFailure(error));
    yield put(catchError(error));
  }
}

function* detailReceivedBidSaga(params) {
  // console.log("detailRB Saga", params);

  try {
    const response = yield call(() =>
      fetchDetailReceivedBidDataAPI(params.payload)
    ); // API Backend
    // console.log("detailReceivedBidDataSaga:", response);
    if (response.status == 200) {
      yield put(fetchDetailReceivedSuccess(response.data));
    } else {
      yield put(fetchDetailReceivedFailure(response));
    }
  } catch (error) {
    yield put(fetchDetailReceivedFailure(error));
    yield put(catchError(error));
  }
}

function* fetchDeclinedReasonsBidsSaga(params) {
  // // console.log("declinedReasons Saga", params);

  try {
    const response = yield call(() =>
      fetchDeclinedReasonsBidsAPI(params.payload)
    ); // API Backend
    // // console.log("declinedReasonsDataSaga:", response);
    if (response.status == 200) {
      yield put(fetchDeclinedReasonsBidsSuccess(response.data, {type:params.payload}));
    } else {
      yield put(fetchDeclinedReasonsBidsFailure(response));
    }
  } catch (error) {
    yield put(fetchDeclinedReasonsBidsFailure(error));
  }
}

function* updateDeclinedReasonsBidsSaga(params) {
  // console.log(params,"updateDeclinedReasonsReceivedBidsAPIParmas")
  try {
    const response = yield call(() =>
      updateDeclinedReasonsReceivedBidsAPI(params.payload)
    ); // API Backend
    // console.log("updateDeclinedReasonsDataSaga:", response);
    if (response.status == 200) {
      yield put(fetchDetailReceivedBid(params.payload.idSearch));
      yield put(
        updateDeclinedReasonsReceivedBidsSuccess({
          BidStatusName: "New",
          BidStatusId: 1,
          DeclineReason: response.data.DeclineReason,
        })
      );
    } else {
      yield put(updateDeclinedReasonsReceivedBidsSuccess(response));
    }
  } catch (error) {
    yield put(updateDeclinedReasonsReceivedBidsFailure(error));
  }
}
/********** UPDATE BID TALENT**********/
function* updateBidstTalentSaga(params) {
  // console.log("in update bid saga");
  // console.log("param:", JSON.stringify(params));
  try {
    const response = yield call(updateReceivedBidsApi, params);
    yield put({
      type: types.UPDATEBIDS_TALENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.UPDATEBIDS_TALENT_FAILURE, error });
  }
}

export function* watchStatusReceivedBidsSaga() {
  yield takeEvery(
    types.FETCH_RECEIVEDBIDS_STATUS_REQUESTED,
    statusReceivedBidsSaga
  );
}
export function* watchFetchReceivedBidsSaga() {
  yield takeEvery(types.FETCH_RECEIVEDBIDS_REQUESTED, fetchReceivedBidsSaga);
}
export function* watchFetchReceivedBidCountSaga() {
  yield takeEvery(types.FETCH_RECEIVEDBID_COUNT_REQUESTED, fetchReceivedBidCountSaga);
}
export function* watchUpdateReceivedBidsSaga() {
  yield takeEvery(types.FETCH_UPDATEBIDS_REQUESTED, updateReceivedBidsSaga);
}

export function* watchFetchPlaceBidsSaga() {
  yield takeEvery(types.FETCH_PLACE_BID_REQUESTED, placeBidDataSaga);
}

export function* watchFetchretractedBidsSaga() {
  yield takeEvery(types.FETCH_RETRACTED_BID_REQUESTED, retractedBidDataSaga);
}

export function* watchFetchDetailPlaceBidsSaga() {
  yield takeEvery(types.FETCH_DETAIL_PLACE_BID_INIT, detailPlaceBidSaga);
}

export function* watchFetchDetailReceivedBidsSaga() {
  yield takeEvery(types.FETCH_DETAIL_RECEIVED_BID_INIT, detailReceivedBidSaga);
}

export function* watchFetchDeclinedReasonsBids() {
  yield takeEvery(
    types.FETCH_DECLINED_REASONS_BIDS_INIT,
    fetchDeclinedReasonsBidsSaga
  );
}

export function* watchUpdateDeclinedReasonsBids() {
  yield takeEvery(
    types.UPDATE_DECLINED_REASONS_BIDS_INIT,
    updateDeclinedReasonsBidsSaga
  );
}
