import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./ViewCard.css";

const ViewCard = ({
  data: {
    Employer: {
      _id: EmployerId,
      viewcount,
      Profiles: { PictureUrl, CompanyName, Cities, Regions, Location },
    },
  },
}) => {
  const history = useHistory();
  const [placeholder, showPlaceholder] = useState(false);
  const location =
    Location && Location["Location"]
      ? Location["Location"]
      : Cities && Cities["CityName"]
      ? `${Cities["CityName"]}${
          Regions && Regions["RegionCode"] ? `, ${Regions["RegionCode"]}` : ""
        }`
      : "NA";
  return (
    <>
      <div
        className="ViewCardContainer"
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/company-profile/${EmployerId}`);
        }}
      >
        <div className="vcuserPicInfo">
          {PictureUrl && !placeholder ? (
            <img
              src={process.env.REACT_APP_S3_URL + `${PictureUrl?.charAt(0)!=='/' ? '/': ""}`+ PictureUrl}
              alt=""
              className="vcuserPic"
              onError={() => showPlaceholder(true)}
            />
          ) : (
            <span className="vcuserNameChar">
              {CompanyName ? CompanyName.slice(0, 1).toLocaleUpperCase() : "NA"}
            </span>
          )}
        </div>
        <div className="vcuserInfo">
          <div className="vcuserName">{CompanyName ? CompanyName : "NA"}</div>
          <div className="vcuserLocation">{location}</div>
          <div className="vcuserCount">
            Viewed {viewcount ? viewcount : 0} time{viewcount > 1 ? "s" : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCard;
