import { types } from "../action-types";

const initialState = {
    loading: false,
    gigs:[],
    error: false,
};

const getBidDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_BIDDATA_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.FETCH_BIDDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                gigs: action.payload,
                error: false,
            };
        case types.FETCH_BIDDATA_FAILURE:
            return {
                ...state,
                loading: false,
                gigs: [],
                error: true,
            };
        case types.FETCH_BIDDATA_RESET:
            return {
                ...state,
                loading: false,
                gigs: [],
                error: false,
            };
        default:
            return state;
    }
};

export default getBidDataReducer;
