import React, { useState, useEffect } from "react";
import "./Carousel.css";
import { Carousel } from "antd";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";




const Caraousel = () => {
  const history = useHistory();
  const locationURL = useLocation().pathname;
  const [dimensions, setDimensions] = useState(window.innerWidth);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);
  const ProfessionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  )

  // carousel-inbox-main-first HomePage-carousel-inbox-main-first
  return (
    <div>
      <Carousel autoplay dotPosition={locationURL === "/dashboard" ? "top" : "bottom"} dots="true" >
        {/* <div className="carousel-Image">
          {" "}
          <div onClick={() => history.push("/dummy")} className="carousel-inbox-main1">
            <div className={locationURL === "/dashboard" ? "carousel-inbox-main-first" : (dimensions > 720 ? "HomePage-carousel-inbox-main-first" : "carousel-inbox-main-first")}> Refer a friend</div>
            <div className={locationURL === "/dashboard" ? "carousel-inbox-main-second" : (dimensions > 720 ? "HomePage-carousel-inbox-main-second" : "carousel-inbox-main-second")}>
              {" "}
              What rewards to users get when they refer a friend?
            </div>
          </div>
        </div> */}
        <div onClick={() => { ProfessionCode === "HC" ? history.push("/credentialing") : history.push("/profile")}} className="carousel-Image-2">
          {" "}
          <div className="carousel-section">
            <div className={locationURL === "/dashboard" ? "carousel-inbox-main-first" : (dimensions > 720 ? "HomePage-carousel-inbox-main-first" : "carousel-inbox-main-first")}> Maintain your rating </div>
            <div className={locationURL === "/dashboard" ? "carousel-inbox-main-second" : (dimensions > 720 ? "HomePage-carousel-inbox-main-second" : "carousel-inbox-main-second")}>
              {" "}
              Show off your skills! Maintain your rating to give employers a look at how you work.
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Caraousel;
