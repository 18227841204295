import React, { useEffect } from "react";
import "./SuccessPage.css";
import likeIcon from "../../assets/icons/like.svg";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailVerification, updateProfile } from "../../redux/actions";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import { alertNotification } from "../../components/notifications/alertNotification";

const SuccessPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [percentage, setPercentage] = React.useState(false);

  const { ResumeFilename: resume, WizardFlow } = useSelector(
    (state) => state.profileReducer.user.profile
  );

  const username = useSelector((state) => state.profileReducer.user.profile);

  const profession = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );

  // const professionGig = JSON.parse(
  //   localStorage.getItem("bidData")
  // );

  const professionGig = localStorage.getItem("ProfessionsCode");

  //handle finish which checks if localstorage has redirectTo if not it redirects to dashboard
  const handleFinish = () => {
    dispatch(sendEmailVerification());
    dispatch(updateProfile({ WizardFlow: 6 }));

    // // console.log("redirectto", localStorage.getItem("redirectTo"));
    // if (localStorage.getItem("redirectTo") === "/create-bid") {
    if (localStorage.getItem("JobDetailpageUrl")?.slice(0, 7) === "/detail") {
      if (profession === professionGig) {
        // history.push("/create-bid");
        history.push(localStorage.getItem("JobDetailpageUrl"));
      } else {
        // localStorage.removeItem("bid");
        localStorage.removeItem("JobDetailpageUrl");
        localStorage.removeItem("ProfessionsCode");
        alertNotification(
          "warning",
          `Hi ${username.FirstName} ${username.LastName}, The selected gig is not applicable to your profile, please find relevant gigs`
        );
        history.push("/dashboard");
      }
      // } else if (localStorage.getItem("redirectTo")) {
      //   history.push(localStorage.getItem("redirectTo"));
      // }
    } else if (localStorage.getItem("JobDetailpageUrl")) {
      history.push(localStorage.getItem("JobDetailpageUrl"));
    } else {
      history.push("/dashboard");
    }
  };

  useEffect(() => {
    if (resume) {
      setPercentage(true);
    } else {
      setPercentage(false);
    }
    if (WizardFlow === 6) history.push("/");
  }, [resume, WizardFlow]);

  useEffect(() => {
    if (window.location.href.indexOf("/talent/basic-profile-success") != -1) {
      window.gtag("event", "conversion", {
        send_to: "AW-972299611/SK8lCNT0vpIYENu60M8D",
      });
    }
  }, []);

  return (
    <div className="successPageWrap">
      <div className="successPage">
        <section className="successHeader">
          <div className="allSetSuccess">Welcome to SkillGigs! Your adventure begins now.</div>
          <div className="subHeader">
            {/* Great! Your profile and resume are all set.
            <b> {percentage ? "100%" : "40%"} </b>
            Now you can start finding your next dream gig!
            <b> {percentage ? "100%" : "40%"} </b>
            You can always edit your profile later. */}
            Your profile is created.
          </div>
        </section>
        <section className="successFinish">
          <img src={likeIcon} alt="success" />
          <div className="finishButtton" onClick={() => handleFinish()}>
            Profile created
          </div>
          <div
            className="editProfile"
            onClick={() => {
              history.push("/profile");
              dispatch(updateProfile({ WizardFlow: 6 }));
            }}
          >
            Edit profile
          </div>
          <br/>
          <div className="subHeader">You can always edit your profile later</div>
        </section>
      </div>
    </div>
  );
};

export default SuccessPage;
