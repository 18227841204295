import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LadyGrowthChart from "../../../assets/icons/LadyGrowthChart.svg";
import ChatRecruitorWhite from "../../../assets/icons/chatRecruitorWhite.svg";
import BlueColorChatIcon from "../../../assets/icons/BlueColorChatIcon.png";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";

const CompleteCredentialing = ({ BidDetailData }) => {
  const history = useHistory();

  const name = useSelector((state) => state.profileReducer.user.name);
  const ProfessionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );

  return (
    <div className="completeCredentialing-bid">
      {/* <div className="congratesName">{`Congrats ${name ? name : ""}`}</div> */}
      <div className="congratesName">Congrats on accepting the offer!</div>
      <img
        src={LadyGrowthChart}
        alt="chartIcon"
        style={{ marginBottom: "0.938rem" }}
      />
      <div className="finalStepOnboard">
        {ProfessionCode === "IT"
          ? "You're hired! Chat with your employer about any final paperwork for you to complete."
          : "Your final step is to upload the requested documents to complete your credentialing."}
      </div>
      <div
        className="completeButton clickable"
        onClick={() =>
          history.push({
            pathname: `/messages`,
            state: {
              openConvo: true,
              conversationId: BidDetailData?.message?.ConversationId,
            },
          })
        }
      >
        <img src={BlueColorChatIcon} alt="chartIcon" />
        Chat with the employer
      </div>
    </div>
  );
};

export default CompleteCredentialing;
