import { types } from "../action-types";

const initialState = {
    loading: false,
    success:[],
    error: {},
};

const timeSheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_GRAPH_DATA_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.GET_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload,
                error: {},
            };
        case types.GET_GRAPH_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                success: [],
                error: action.error,
            };
        default:
            return {...state,loading:false};
    }
};

export default timeSheetReducer;
