import React from 'react';
import './TipToGettingStart.css'
import GettingStart from '../../assets/img/GettingStart.png'

const TipToGettingStart = () => {
    return (
        <>
            <div className='Tips-Getting-Started-Container' >
                <div className="Tips-Getting-Started-Cricle-arrow-left"></div>
                <span className='Tips-Getting-Started-Cricle' >
                    <img src={GettingStart} />
                </span>
                <div>
                    <div className='Tips-Getting-Started-Heading' >
                        Tips : getting started
                    </div>
                    <div className='Tips-Getting-Started-Sub-Heading-container' >
                        <div className='Tips-Getting-Started-Sub-Heading' >
                            Remember, you are describing the gig
                            you want.
                        </div>
                    </div>
                    <div className='Tips-Getting-Started-Important' >
                        Important
                    </div>
                    <div className='Tips-Getting-Started-points-container' >
                        <div className='Tips-Getting-Started-points' >
                            Employers will place bids on your skill listings to be thorough
                        </div>
                        <div className='Tips-Getting-Started-points' >
                            You can create more than one Skill
                            listing
                        </div>
                        <div className='Tips-Getting-Started-points' >
                            Give the title of the gig you want
                        </div>
                        <div className='Tips-Getting-Started-points' >
                            When it comes to salary
                            requirements,be fair but know your
                            worth and ask for it
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TipToGettingStart;